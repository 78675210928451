import { usePromoCodeBySlugLazyQuery } from '@/controllers/promoCode/generated/promoCodeBySlug.query.generated';
import { PromoCodeLandingFragment } from '@/controllers/promoCode/generated/promoCodeLanding.fragment.generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

interface CallbackOutput {
  promoCode: PromoCodeLandingFragment | null;
}

interface Output {
  fetchPromoCode: (slug: string) => Promise<CallbackOutput>;
  loading: boolean;
}

export const useLazyFindPromoCodeBySlug = (): Output => {
  const { language } = useSubDomainContext();
  const [fetchPromoCodeQuery, { loading }] = usePromoCodeBySlugLazyQuery();

  const fetchPromoCode = async (slug: string) => {
    const { data } = await fetchPromoCodeQuery({
      variables: {
        slug,
        lang: language,
      },
    });

    return {
      promoCode: data?.promoCodeBySlug || null,
    };
  };

  return {
    fetchPromoCode,
    loading,
  };
};
