import React, { FC } from 'react';
import { Button } from '@/components/ui/Button';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { socialMediaLinks } from './data';
import styles from '../ProfessionFooter.module.scss';

export const FooterSocialLinks: FC = () => {
  const { subDomain } = useSubDomainContext();

  const socialMedias = socialMediaLinks[subDomain]
    ?? socialMediaLinks.default;

  return (
    <div className='flex-container gap-24 small-gap-16'>
      {socialMedias && socialMedias.map((item) => (
        <Button
          key={item.id}
          title={item.title}
          href={item.link}
          target="_blank"
          rel="nofollow noreferrer noopener"
          LeftIcon={item.Icon}
          mode={Button.mode.TransparentDark}
          size={Button.size.Large}
          className={styles.socialButton}
        />
      ))}
    </div>
  );
};
