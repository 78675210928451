import React, { memo, PropsWithChildren } from 'react';
import { cn } from '@/lib/classNames';
import styles from './SectionBase.module.scss';

interface Props {
  className?: string;
}

export const ContentContainer = memo<PropsWithChildren<Props>>((props) => {
  const { children, className } = props;

  return (
    <div className={cn(styles.contentContainer, className)}>
      {children}
    </div>
  );
});
