import React, { FC } from 'react';
import { useGetPrivaciesDocs } from '@/controllers/landing/landing.helpers';
import {
  FooterLinksDesktopSinglePage,
} from '@/components/professionLanding/ProfessionFooter/components/FooterLinks/FooterLinksDesktopSinglePage';
import { FooterLinksMobileSinglePage } from './FooterLinksMobileSinglePage';

export const FooterLinksSinglePage: FC = () => {
  const {
    termsOfUse,
    privacyPolicy,
    cookiesPolicy,
  } = useGetPrivaciesDocs();

  const props = {
    termsOfUse,
    privacyPolicy,
    cookiesPolicy,
  };

  return (
    <>
      <FooterLinksDesktopSinglePage {...props} />

      <FooterLinksMobileSinglePage {...props} />
    </>
  );
};
