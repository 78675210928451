import React, { useMemo } from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { IconChartCircle } from '@/components/ui/icons/IconChartCircle';
import { IconDollarSend } from '@/components/ui/icons/IconDollarSend';
import {
  ComparisonTableData,
} from '@/components/professionLanding/ProfessionFormatComparison/components/ComparisonTable.typedefs';

export const useFormatComparisonRowsData = (): ComparisonTableData => {
  const { t } = useTranslation([
    I18N_CODES.home,
    I18N_CODES.course,
  ]);

  return useMemo(() => ({
    columns: [
      {
        id: 1,
        title: (
          <p>
            <IconChartCircle />

            {t(`${I18N_CODES.home}:home_comparison_fulltime_title`)}
          </p>
        ),
      },
      {
        id: 2,
        title: (
          <p>
            <IconDollarSend />

            {t(`${I18N_CODES.home}:home_comparison_flex_title`)}
          </p>
        ),
      },
    ],
    rows: [
      {
        id: 1,
        title: t(`${I18N_CODES.home}:home_comparison_row_1_title`),
        leftColumn: t(`${I18N_CODES.home}:home_comparison_row_1_fulltime_text`),
        rightColumn: t(`${I18N_CODES.home}:home_comparison_row_1_flex_text`),
      },
      {
        id: 2,
        title: t(`${I18N_CODES.home}:home_comparison_row_2_title`),
        leftColumn: t(`${I18N_CODES.home}:home_comparison_row_2_fulltime_text`),
        rightColumn: t(`${I18N_CODES.home}:home_comparison_row_2_flex_text`),
      },
      {
        id: 3,
        title: t(`${I18N_CODES.home}:home_comparison_row_3_title`),
        leftColumn: t(`${I18N_CODES.home}:home_comparison_row_3_fulltime_text`),
        rightColumn: t(`${I18N_CODES.home}:home_comparison_row_3_flex_text`),
      },
    ],
  }), [
    t,
  ]);
};
