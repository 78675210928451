import React, {
  DetailedHTMLProps, FC, InputHTMLAttributes, memo, useCallback, useMemo,
} from 'react';
import { useField } from 'react-final-form';
import PhoneInput from 'react-phone-input-2';
import { cn } from '@/lib/classNames';
import { makeValidator } from '@/controllers/forms/forms.validator';
import { composeValidators } from '@/controllers/forms/forms.helpers';
import 'react-phone-input-2/lib/style.css';
import { usePhoneValidation } from '@/components/ui/FormElements/FormInputs/InputPhoneNumber/hooks/usePhoneValidation';
import { useCountryCodeByIp } from '@/components/ui/FormElements/FormInputs/InputPhoneNumber/hooks/useCountryCodeByIp';
import styles from './InputPhoneNumber.module.scss';
import InputPhoneMasks from './InputPhoneMasks.json';

const defaultValidator = () => undefined;

interface Props extends DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
  > {
  name: string;
  ref?: any;
  config?: any;
  'data-qa'?: string;
  value?: string;
  onChange?: () => void;
  autoFormat?: boolean;
}

export const InputPhoneNumber: FC<Props> = memo((props) => {
  const {
    required = false,
    id,
    disabled,
    'data-qa': dataQa,
    config: propsConfig = {},
    className,
    ...rest
  } = props;

  const [countryCode, setCountryCode] = useCountryCodeByIp();
  const [
    validator,
    countryFormat,
    setCountryFormat,
  ] = usePhoneValidation(countryCode);
  const key = useMemo(
    () => countryCode + countryFormat,
    [countryCode, countryFormat],
  );

  const data = useField(props.name, {
    ...propsConfig,
    validate: composeValidators(
      propsConfig.validate || defaultValidator,
      makeValidator(validator, 'validate_phone'),
    ),
  });

  const isValid = useCallback((value: string, country: any) => {
    const format = country.format || '';

    if (format !== countryFormat) {
      setCountryFormat(format);
    }

    if (country.iso2 && country.iso2 !== countryCode) {
      setCountryCode(country.iso2);
    }

    return Boolean(format);
  }, [countryFormat, setCountryFormat, countryCode, setCountryCode]);

  return (
    <div
      className={styles.phone}
    >
      <PhoneInput
        {...data.input}
        {...rest}
        inputClass={cn(
          styles.input,
          className,
        )}
        isValid={isValid}
        key={key}
        inputProps={{
          required,
          'data-qa': dataQa,
          autoComplete: 'off',
          id,
          disabled,
        }}
        masks={InputPhoneMasks}
        preferredCountries={['ua', 'pl', 'gb', 'br', 'us']}
        excludeCountries={['ru', 'by']}
        country={countryCode}
        enableLongNumbers
        enableSearch
      />
    </div>
  );
});
