import React, { memo, useState } from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import {
  ProfessionCardRedesigned,
} from '@/components/coursesParttimeLanding/ProfessionCardRedesigned/ProfessionCardRedesigned';
import { ProfessionsListData } from '@/components/coursesParttimeLanding/CoursesParttime.typedefs';
import { useIsMinimalWidth } from '@/hooks/useIsMinimalWidth';
import { MINIMAL_DESKTOP_WIDTH } from '@/constants/breakpoints';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import { COURSE_BLOCKS } from '@/components/courseLanding/CoursePage/CoursePageContent.constants';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { ContentContainer } from '@/components/landing/BaseSectionWrappers/ContentContainer';
import {
  SectionBase,
  SectionBaseType,
} from '@/components/landing/BaseSectionWrappers/SectionBase';
import styles from './ProfessionsListSectionRedesigned.module.scss';

interface Props {
  professions: ProfessionsListData[];
}

export const ProfessionsListSectionRedesigned = memo<Props>((props) => {
  const { professions } = props;
  const { t } = useTranslation([I18N_CODES.course]);

  const isSmallScreen = useIsMinimalWidth(MINIMAL_DESKTOP_WIDTH);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const shouldHideProfessions = (
    isSmallScreen
    && professions.length > 4
    && !isButtonClicked
  );

  const visibleProfessions = shouldHideProfessions
    ? professions.slice(0, 4)
    : professions;

  return (
    <SectionBase
      id={COURSE_BLOCKS.allCourses}
      type={SectionBaseType.WideWithLightBackground}
    >
      <ContentContainer>
        <div className="mb-56 small-mb-32">
          <p className={cn(typography.landingSectionHeading, 'mb-16 small-mb-12 text-center')}>
            {t(`${I18N_CODES.course}:professions_list_heading`)}
          </p>

          <h2 className={cn(typography.newLandingH2, styles.title)}>
            {t(`${I18N_CODES.course}:professions_list_title`)}
          </h2>
        </div>

        <div className={styles.cardsWrapper}>
          {visibleProfessions.map(({ id, ...options }) => (
            <ProfessionCardRedesigned
              key={id}
              {...options}
            />
          ))}
        </div>

        {shouldHideProfessions && (
          <div className='mt-24 flex-container align-center'>
            <Button
              className={styles.button}
              mode={ButtonMode.Secondary}
              size={ButtonSize.Large}
              onClick={() => setIsButtonClicked(true)}
              text={t(`${I18N_CODES.course}:professions_list_show_more_button`)}
            />
          </div>
        )}
      </ContentContainer>
    </SectionBase>
  );
});
