import { useMemo } from 'react';
import { useOtherProfessions } from '@/components/courseLanding/OtherLearningTracksBlock/OtherLearningTracksBlock.hooks/useOtherProfessions.hook';
import { SubDomains } from '@/constants/subdomains';
import { useOtherCoursesList } from '@/controllers/courses/courses.hooks/useOtherCoursesList';
import { CourseType } from '@/controllers/graphql/generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

export const useOtherTracks = () => {
  const { subDomain } = useSubDomainContext();

  const isPlDomain = subDomain === SubDomains.pl;

  const professions = useOtherProfessions({
    skip: isPlDomain,
  });

  const courses = useOtherCoursesList({
    types: [CourseType.Prepaid],
    skip: !isPlDomain,
  });

  return useMemo(
    () => (isPlDomain
      ? courses
      : professions),
    [isPlDomain, courses, professions],
  );
};
