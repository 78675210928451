import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SectionBase, SectionBaseType } from '@/components/landing/BaseSectionWrappers/SectionBase';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { ARTICLES_ABOUT_US } from '@/components/homepageLanding/HomepageLanding.content';
import { ContentContainer } from '@/components/landing/BaseSectionWrappers/ContentContainer';
import { BaseSectionHeader } from '@/components/landing/BaseSectionWrappers/BaseSectionHeader';
import { AnchorElement } from '@/components/ui/Button/AnchorElement';
import { IconImage } from '@/components/ui/Image/IconImage';
import { IconArrowLeftLongRounded } from '@/components/ui/icons/IconArrowLeftLongRounded';
import styles from './MoreAboutUsSection.module.scss';

export const MoreAboutUsSection: FC = () => {
  const { t } = useTranslation([I18N_CODES.home]);
  const { subDomain } = useSubDomainContext();

  const articles = ARTICLES_ABOUT_US[subDomain];

  return (
    <SectionBase type={SectionBaseType.Default}>
      <ContentContainer>
        <div className="mb-56 medium-mb-32 small-mb-8">
          <BaseSectionHeader
            title={t(`${I18N_CODES.home}:more_about_us_title`)}
            heading={t(`${I18N_CODES.home}:more_about_us_heading`)}
          />
        </div>

        <Swiper
          className="overflow-visible width-100"
          spaceBetween={16}
          breakpoints={{
            1024: {
              slidesPerView: 3,
              grabCursor: false,
            },
          }}
          slidesPerView="auto"
          grabCursor
        >
          {articles && articles.map(({
            id,
            Icon,
            heading,
            text,
            link,
            logoImageData,
            logoSize,
          }) => (
            <SwiperSlide key={id} className={styles.slide}>
              <div className={styles.slideHeader}>
                <div className={styles.titleContainer}>
                  <div className={styles.headingContainer}>
                    <Icon />

                    {t(heading)}
                  </div>

                  <div className={styles.logo}>
                    <IconImage
                      imageData={logoImageData}
                      width={logoSize.width}
                      height={logoSize.height}
                    />
                  </div>
                </div>

                <AnchorElement
                  href={link}
                  target='_blank'
                  rel="nofollow noreferrer noopener"
                  className={styles.link}
                >
                  <IconArrowLeftLongRounded />
                </AnchorElement>
              </div>

              <p className={styles.slideContent}>
                {t(text)}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
      </ContentContainer>
    </SectionBase>
  );
};
