import React, {
  FC, useCallback,
} from 'react';
import { Modal, PopUpTypeMode } from '@/components/ui/Modal';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { typography } from '@/components/ui/typography';
import { TelegramForm } from '@/components/platform/RegistrationForm/TelegramPopup/TelegramForm';
import { analyticsSDK } from '@/controllers/analytics';
import { PopupAction } from '@/controllers/analytics/generated';
import styles from './TelegramPopupModal.module.scss';

interface Props {
  isOpen: boolean;
  closePopup: () => void;
}

export const TelegramPopupModal: FC<Props> = ({
  isOpen,
  closePopup,
}) => {
  const { t } = useTranslation([
    I18N_CODES.courseConsultation,
  ]);

  const handleClosingPopup = useCallback(() => {
    analyticsSDK.landing.sendTelegramPopupEvent({
      action: PopupAction.Closed,
    });

    closePopup();
  }, [closePopup]);

  return (
    <Modal
      isOpen={isOpen}
      type={PopUpTypeMode.Default}
      className={styles.popup}
      onRequestClose={handleClosingPopup}
      renderTitle={() => (
        <p className={typography.platformH2}>
          {t(`${I18N_CODES.courseConsultation}:wait_less`)}
        </p>
      )}
      titleClassNames={styles.modalTitle}
    >
      <TelegramForm
        closePopup={closePopup}
      />
    </Modal>
  );
};
