import React, { FC } from 'react';
import { HeaderCtaButtonContainer } from '@/components/landing/LandingHeader/components/HeaderCtaButtonContainer';
import { Seos } from '@/components/common/Seos';
import { LandingHeader } from '@/components/landing/LandingHeader';
import { HeaderProfileActions } from '@/components/landing/LandingHeader/components/HeaderProfileActions';
import { FlashMessageContainer } from '@/components/ui/flashMessage/FlashMessageContainer';
import { CTAContextProvider } from '@/controllers/cta/Cta.context';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { AnalyticsEvents } from '@/controllers/analytics/analytics.events';
import { HomePageContent } from '@/components/homepageLanding/HomePageContent';
import { LandingSeoTags } from '@/seo/LandingSeoTags';
import { PageType } from '@/components/landing/Promotions/promotions.typedefs';
import { LandingSupportButton } from '@/components/common/SupportButtons/LandingSupportButton';
import { ROUTES } from '@/controllers/router/router.contants';
import {
  useShouldRedirectToConsultationSurvey,
} from '@/components/user-survey/UserSurvey.hooks/useShouldRedirectToConsultationSurvey.hook';
import { UniversalPromoBanner } from '@/components/landing/Promotions/UniversalPromo/UniversalPromoBanner';
import { usePromoLandingElements } from '@/controllers/promoCode/promoCode.hooks/usePromoLandingElements';
import { LandingSupportButtonRedesigned } from '@/components/common/SupportButtons/LandingSupportButtonRedesigned';
import { HomePageContentRedesign } from '@/components/homepageLanding/HomePageContent/HomePageContentRedesign';
import { useIsNewYearThemeEnabled } from '@/components/landing/Promotions/components/PromoBanner/hooks/useIsNewYearThemeEnabled';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { SubDomains } from '@/constants/subdomains';

export const HomePageModule: FC = () => {
  const { t } = useTranslation([I18N_CODES.common]);

  const { promoBannerProps } = usePromoLandingElements();
  const promoCodeSlug = promoBannerProps?.slug || '';
  const features = useFeatures();
  const { subDomain } = useSubDomainContext();

  const shouldRedirectToConsultationSurvey = (
    useShouldRedirectToConsultationSurvey()
  );

  const shouldShowRedesignedVersion = (
    features.isEnabled(features.homePageRedesignUa)
    && subDomain === SubDomains.ua
  );

  const ctaLink = shouldRedirectToConsultationSurvey
    ? ROUTES.consultation.signUpSurvey({
      form_id_modifier: 'merged',
    })
    : `${ROUTES.consultation.index}`;

  const shouldShowNewYearCards = (
    useIsNewYearThemeEnabled(promoCodeSlug)
  );

  return (
    <>
      <Seos />
      <LandingSeoTags />

      <CTAContextProvider
        value={{
          text: t(`${I18N_CODES.common}:study_for_free`),
          href: ctaLink,
          callback: (data: Record<string, any> = {}) => {
            AnalyticsEvents.sendEvent(
              AnalyticsEvents.events.courseCTAClicked,
              data,
            );
          },
          pageType: PageType.HOME,
        }}
      >
        <LandingHeader>
          <HeaderCtaButtonContainer>
            <HeaderProfileActions />
          </HeaderCtaButtonContainer>
        </LandingHeader>

        {shouldShowRedesignedVersion
          ? <HomePageContentRedesign />
          : (
            <HomePageContent
              promoCodeSlug={promoCodeSlug}
              shouldShowNewYearCards={shouldShowNewYearCards}
            />
          )}

        {shouldShowRedesignedVersion
          ? <LandingSupportButtonRedesigned />
          : <LandingSupportButton />}

        {promoBannerProps && (
          <UniversalPromoBanner
            {...promoBannerProps}
            shouldShowRedesigned={shouldShowRedesignedVersion}
          />
        )}

        <FlashMessageContainer />
      </CTAContextProvider>
    </>
  );
};
