import React, {
  FC, useState, useCallback, CSSProperties, useRef, TouchEvent,
} from 'react';
import { cn } from '@/lib';
import styles from './LandingSwipeModal.module.scss';
import { SWIPE_PX_SHIFT_TO_CLOSE, TRANSITION_DURATION } from './LandingSwipeModal.constants';

interface Props {
  close: () => void;
}

export const LandingSwipeModal: FC<Props> = (props) => {
  const {
    close,
    children,
  } = props;

  const [touchStartY, setTouchStartY] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = useCallback(() => {
    setIsClosing(true);

    setTimeout(() => {
      close();
    }, TRANSITION_DURATION);
  }, [close]);

  const popupRef = useRef<HTMLDivElement>(null);

  const handleTouchStart = (e: TouchEvent) => {
    const touch = e.touches[0];

    if (!touch) {
      return;
    }

    setTouchStartY(touch.clientY);
  };

  const handleTouchMove = (e: TouchEvent) => {
    const touch = e.touches[0];

    if (!touch) {
      return;
    }

    const diffY = touch.clientY - touchStartY;

    if (diffY > 0) {
      setCurrentY(diffY);
    }
  };

  const handleTouchEnd = () => {
    if (currentY > SWIPE_PX_SHIFT_TO_CLOSE) {
      handleClose();
    } else {
      setCurrentY(0);
    }
  };

  return (
    <>
      <div
        className={cn(styles.overlay, {
          [styles.open]: !isClosing,
          [styles.close]: isClosing,
        })}
        onClick={handleClose}
        role="button"
        tabIndex={0}
        aria-label="close-modal"
        onKeyUp={(event) => {
          if (event.key === 'Escape') {
            handleClose();
          }
        }}
      />

      <div
        ref={popupRef}
        className={cn(styles.modal, {
          [styles.open]: !isClosing,
          [styles.close]: isClosing,
        })}
        style={{
          transform: `translateY(${currentY}px)`,
          '--transition-duration': `${TRANSITION_DURATION}ms`,
        } as CSSProperties}
      >
        <div
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          className={styles.touchBar}
        >
          <div className={styles.handle} />
        </div>

        <div className={styles.content}>
          {children}
        </div>
      </div>
    </>
  );
};
