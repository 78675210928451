import React from 'react';
import { Button, ButtonSize } from '@/components/ui/Button';
import { cn } from '@/lib';
import { IconLinkedIn } from '@/components/ui/icons/IconLinkedIn';
import { IconGoogle } from '@/components/ui/icons/IconGoogle';
import { IconDouColor } from '@/components/ui/icons/IconDouColor';
import { IconImage } from '@/components/ui/Image/IconImage';
import { ImageData } from '@/components/ui/Image/typedefs';
import styles from './ReviewCard.module.scss';

export enum ReviewButtonType {
  Dou = 'dou',
  LinkedIn = 'linkedin',
  Google = 'google',
  Image = 'image',
}

type RefType = HTMLButtonElement | HTMLAnchorElement;

interface Props {
  type: ReviewButtonType;
  link?: string;
  imageData?: ImageData;
}

const ReviewButtonTemplate = React.forwardRef<RefType, Props>((props, ref) => {
  const {
    link,
    type,
  } = props;

  const baseProps = {
    ref,
    size: ButtonSize.Large,
    target: '_blank',
    href: link,
    className: cn(styles.button, {
      [styles.noLink]: !link,
    }),
  };

  switch (type) {
    case ReviewButtonType.LinkedIn: {
      return (
        <Button
          {...baseProps}
          className={cn(baseProps.className, styles.linkedIn)}
          LeftIcon={IconLinkedIn}
        />
      );
    }

    case ReviewButtonType.Image: {
      return (
        <Button
          {...baseProps}
          className={cn(baseProps.className, styles.image)}
          renderLeftIcon={() => (
            <IconImage
              imageData={props.imageData!}
              height={48}
              width={48}
            />
          )}
        />
      );
    }

    case ReviewButtonType.Dou: {
      return (
        <Button
          {...baseProps}
          renderLeftIcon={() => (
            <div className={styles.dou}>
              <IconDouColor />
            </div>
          )}
        />
      );
    }

    case ReviewButtonType.Google:
    default: {
      return (
        <Button
          {...baseProps}
          className={cn(styles.button, styles.google)}
          LeftIcon={IconGoogle}
        />
      );
    }
  }
});

export const ReviewButton = Object.assign(
  ReviewButtonTemplate,
  {
    type: ReviewButtonType,
  },
);
