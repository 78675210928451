import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { isTodayDayOff } from '@/lib/helpers/isTodayDayOff';
import { useDefaultDomain } from '@/controllers/subDomain/subDomain.hooks/useDefaultDomain';
import {
  ConsultationFormMessageHarness,
} from '@/components/courseLanding/CourseConsultationForm/components/ConsultationFormMessage/ConsultationFormMessage.harness';

const harness = new ConsultationFormMessageHarness();

interface Output {
  title: string;
  subtitle: string;
  buttonText: string;
  dataQa: string;
}

interface Props {
  shouldShowTrialContent?: boolean;
}

export const useFormMessageContent = (props: Props): Output => {
  const {
    shouldShowTrialContent,
  } = props;

  const { t } = useTranslation([
    I18N_CODES.courseConsultation,
    I18N_CODES.course,
  ]);

  const isDefaultDomain = useDefaultDomain();
  const shouldShowDayOffMessage = (
    isTodayDayOff()
    && isDefaultDomain
  );

  const formSuccessTitle = t(`${I18N_CODES.courseConsultation}:consultation_form_success_request_title`);
  const formSuccessButtonText = t(`${I18N_CODES.courseConsultation}:consultation_form_success_request_button`);

  if (shouldShowTrialContent) {
    return {
      title: formSuccessTitle,
      subtitle: t(`${I18N_CODES.course}:trial_consultation_success_subtitle`),
      buttonText: t(`${I18N_CODES.course}:success_consultation_link`),
      dataQa: '',
    };
  }

  if (shouldShowDayOffMessage) {
    return {
      title: t(`${I18N_CODES.courseConsultation}:consultation_form_success_request_day_off_title`),
      subtitle: t(`${I18N_CODES.courseConsultation}:consultation_form_success_request_day_off_subtitle`),
      buttonText: formSuccessButtonText,
      dataQa: harness.dayOffMessageDataQa,
    };
  }

  return {
    title: formSuccessTitle,
    subtitle: t(`${I18N_CODES.courseConsultation}:consultation_form_success_request_subtitle`),
    buttonText: formSuccessButtonText,
    dataQa: 'consultation-form-submitted-title',
  };
};
