import interviewUa1 from '@/images/generated/professionLanding.interviews.interviewUa1.s3Key';
import interviewUa2 from '@/images/generated/professionLanding.interviews.interviewUa2.s3Key';
import interviewUa3 from '@/images/generated/professionLanding.interviews.interviewUa3.s3Key';
import interviewUa4 from '@/images/generated/professionLanding.interviews.interviewUa4.s3Key';
import interviewUa5 from '@/images/generated/professionLanding.interviews.interviewUa5.s3Key';
import interviewUa6 from '@/images/generated/professionLanding.interviews.interviewUa6.s3Key';
import interviewUa7 from '@/images/generated/professionLanding.interviews.interviewUa7.s3Key';
import interviewUa8 from '@/images/generated/professionLanding.interviews.interviewUa8.s3Key';
import interviewUa9 from '@/images/generated/professionLanding.interviews.interviewUa9.s3Key';
import interviewUa10 from '@/images/generated/professionLanding.interviews.interviewUa10.s3Key';
import interviewUa11 from '@/images/generated/professionLanding.interviews.interviewUa11.s3Key';
import interviewUa12 from '@/images/generated/professionLanding.interviews.interviewUa12.s3Key';
import interviewsPl1 from '@/images/generated/professionLanding.interviews.interviewPl1.s3Key';
import interviewsPl2 from '@/images/generated/professionLanding.interviews.interviewPl2.s3Key';
import interviewsPl3 from '@/images/generated/professionLanding.interviews.interviewPl3.s3Key';
import interviewsPl4 from '@/images/generated/professionLanding.interviews.interviewPl4.s3Key';
import { SubDomains } from '@/constants/subdomains';
import { Interview } from './GraduatesInterviewsSection.typedefs';

export const GRADUATES_INTERVIEWS_DATA: Record<string, Interview[]> = {
  [SubDomains.ua]: [
    {
      id: 0,
      image: interviewUa12,
      url: 'https://www.youtube.com/live/sSSzX4JRZLY',
      alt: 'Tech Check: Junior UX/UI Designer | Вʼячеслав Дрофа (OTOY) & Mate academy',
    },
    {
      id: 1,
      image: interviewUa11,
      url: 'https://www.youtube.com/live/A9pLRA4Ycrs',
      alt: 'Tech check: Junior Frontend Developer | Infopulse & Mate academy',
    },
    {
      id: 2,
      image: interviewUa10,
      url: 'https://www.youtube.com/live/0uZiexj-eiM',
      alt: 'Tech check: Junior Frontend Developer | Intellias & Mate academy',
    },
    {
      id: 3,
      image: interviewUa9,
      url: 'https://www.youtube.com/live/a5itDzz1Ukw',
      alt: 'Tech check: Junior UX/UI Designer | Universe & Mate academy',
    },
    {
      id: 4,
      image: interviewUa8,
      url: 'https://www.youtube.com/live/SCTOLqxiWzc',
      alt: 'Tech check: Junior UI/UX Designer | Headway & Mate academy',
    },
    {
      id: 5,
      image: interviewUa7,
      url: 'https://www.youtube.com/live/7od3dZgH6ic',
      alt: 'Tech check: Java Junior Developer | MEGOGO & Mate academy',
    },
    {
      id: 6,
      image: interviewUa6,
      url: 'https://www.youtube.com/live/S7eacVa2uPM',
      alt: 'Tech check: Junior Java Developer | Sigma Software & Mate academy',
    },
    {
      id: 7,
      image: interviewUa5,
      url: 'https://www.youtube.com/live/1EWtb3rWbzM',
      alt: 'Tech check: Junior Python Developer | N-iX & Mate academy',
    },
    {
      id: 8,
      image: interviewUa4,
      url: 'https://www.youtube.com/live/ux5KGV-aBag',
      alt: 'Tech check: Junior Python Developer | Brainstack_ & Mate academy',
    },
    {
      id: 9,
      image: interviewUa3,
      url: 'https://www.youtube.com/live/-w3NUu4wiXM',
      alt: 'Tech check: Junior QA Engineer | Jooble & Mate academy',
    },
    {
      id: 10,
      image: interviewUa2,
      url: 'https://www.youtube.com/live/0jLxa5miINc',
      alt: 'Tech check: Junior QA Engineer | Diia & Mate academy',
    },
    {
      id: 11,
      image: interviewUa1,
      url: 'https://www.youtube.com/live/VCC111bmvVQ',
      alt: 'Tech check: Junior Recruiter | Innovecs & Mate academy',
    },
  ],
  [SubDomains.pl]: [
    {
      id: 0,
      image: interviewsPl4,
      url: 'https://www.youtube.com/live/73G9m__UKSw',
      alt: 'Demo rozmowy technicznej na testera QA | Xperi & Mate academy',
    },
    {
      id: 1,
      image: interviewsPl3,
      url: 'https://www.youtube.com/live/C9LHb0LrXLI',
      alt: 'Demo rozmowy technicznej na Frontend Developera | Live tech-check',
    },
    {
      id: 2,
      image: interviewsPl1,
      url: 'https://www.youtube.com/live/iqTbJOUk1kw',
      alt: 'Demo rozmowy technicznej na Fullstack Developera | Live tech-check',
    },
    {
      id: 3,
      image: interviewsPl2,
      url: 'https://www.youtube.com/live/HYAYfIkpAMk',
      alt: 'Demo rozmowy technicznej na testera QA | Live tech-check',
    },
  ],
};
