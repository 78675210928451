import { ImageData } from '@/components/ui/Image/typedefs';
import { ReviewButtonType } from '@/components/professionLanding/ReviewsSection/components/ReviewButton';

export enum ProfessionSlugs {
  Qa = 'qa',
  DataAnalytics = 'data-analytics',
  Recruitment = 'recruitment',
  DigitalMarketing = 'digital-marketing',
  UiUx = 'ui-ux',
  Python = 'python',
  Java = 'java',
  Frontend = 'frontend',
  Fullstack = 'fullstack',
  DevOps = 'devops',
}

export interface BenefitCardProps {
  id: number;
  title: string;
  description: string;
  shouldShowStar: boolean;
  className?: string;
  image: ImageData;
}

export interface FaqItem {
  id: number;
  titleCode: string;
  contentCode: string;
  shouldShowCta?: boolean;
  ctaTextCode?: string;
}

interface BaseReview {
  id: number;
  text: string;
  author: string;
  reviewLink?: string;
  reviewType: ReviewButtonType;
}

interface ImageReview extends BaseReview {
  reviewType: ReviewButtonType.Image;
  imageData: ImageData;
}

interface NonImageReview extends BaseReview {
  reviewType: Exclude<ReviewButtonType, ReviewButtonType.Image>;
  imageData?: never;
}

export type Review = ImageReview | NonImageReview;
