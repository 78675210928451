import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconPodcastColor: FCIcon = (props) => (
  <BaseIcon
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33337 12C5.59859 12 5.85294 12.1054 6.04048 12.2929C6.22802 12.4804 6.33337 12.7348 6.33337 13V14.3333C6.33337 15.6028 6.58341 16.8598 7.06921 18.0326C7.555 19.2054 8.26704 20.2711 9.16468 21.1687C10.0623 22.0663 11.128 22.7784 12.3008 23.2642C13.4736 23.75 14.7306 24 16 24C17.2695 24 18.5265 23.75 19.6993 23.2642C20.8721 22.7784 21.9378 22.0663 22.8354 21.1687C23.733 20.2711 24.4451 19.2054 24.9309 18.0326C25.4167 16.8598 25.6667 15.6028 25.6667 14.3333V13C25.6667 12.7348 25.7721 12.4804 25.9596 12.2929C26.1471 12.1054 26.4015 12 26.6667 12C26.9319 12 27.1863 12.1054 27.3738 12.2929C27.5614 12.4804 27.6667 12.7348 27.6667 13V14.3333C27.6668 17.2545 26.571 20.0693 24.596 22.2216C22.621 24.3739 19.9104 25.707 17 25.9573V29C17 29.2652 16.8947 29.5196 16.7071 29.7071C16.5196 29.8946 16.2653 30 16 30C15.7348 30 15.4805 29.8946 15.2929 29.7071C15.1054 29.5196 15 29.2652 15 29V25.9573C12.0897 25.707 9.3791 24.3739 7.40409 22.2216C5.42907 20.0693 4.33331 17.2545 4.33337 14.3333V13C4.33337 12.7348 4.43873 12.4804 4.62627 12.2929C4.8138 12.1054 5.06816 12 5.33337 12Z"
      fill="#5955F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2.6665C13.9667 2.6665 12.0167 3.47424 10.5789 4.91202C9.14111 6.3498 8.33337 8.29984 8.33337 10.3332V14.3332C8.33337 16.3665 9.14111 18.3165 10.5789 19.7543C12.0167 21.1921 13.9667 21.9998 16 21.9998C18.0334 21.9998 19.9834 21.1921 21.4212 19.7543C22.859 18.3165 23.6667 16.3665 23.6667 14.3332V10.3332C23.6667 8.29984 22.859 6.3498 21.4212 4.91202C19.9834 3.47424 18.0334 2.6665 16 2.6665ZM18.6667 15.3332C18.9319 15.3332 19.1863 15.2278 19.3738 15.0403C19.5613 14.8527 19.6667 14.5984 19.6667 14.3332C19.6667 14.068 19.5613 13.8136 19.3738 13.6261C19.1863 13.4385 18.9319 13.3332 18.6667 13.3332H13.3334C13.0682 13.3332 12.8138 13.4385 12.6263 13.6261C12.4387 13.8136 12.3334 14.068 12.3334 14.3332C12.3334 14.5984 12.4387 14.8527 12.6263 15.0403C12.8138 15.2278 13.0682 15.3332 13.3334 15.3332H18.6667ZM18.3334 10.3332C18.3334 10.5984 18.228 10.8527 18.0405 11.0403C17.8529 11.2278 17.5986 11.3332 17.3334 11.3332H14.6667C14.4015 11.3332 14.1471 11.2278 13.9596 11.0403C13.7721 10.8527 13.6667 10.5984 13.6667 10.3332C13.6667 10.068 13.7721 9.8136 13.9596 9.62606C14.1471 9.43853 14.4015 9.33317 14.6667 9.33317H17.3334C17.5986 9.33317 17.8529 9.43853 18.0405 9.62606C18.228 9.8136 18.3334 10.068 18.3334 10.3332Z"
      fill="#5955F4"
    />

  </BaseIcon>
);
