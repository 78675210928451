// @ts-nocheck
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Upload: any;
  Binary: any;
  JSON: any;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateSoftDeadline: SoftDeadline;
  activateSubscription?: Maybe<PaymentResult>;
  activateUserStreak?: Maybe<UserStreak>;
  /** @deprecated Use sendAttachmentTaskLink mutation instead */
  addAttachmentTaskLink?: Maybe<AddAttachmentTaskLinkResult>;
  addChatParticipants: Array<Participant>;
  addChatsToSection: ChatSection;
  addCompany?: Maybe<Company>;
  addCourseModule: Array<CourseModule>;
  addExperiment: Experiment;
  addInvidToTopic: CourseTopicVideo;
  addReactionToStack: AddReactionResult;
  addTestTaskAttemptFiles: Array<AbstractTestTaskAttemptFile>;
  addUserToChats: AddUserToChatsResult;
  addUserToPTGroup: Scalars['Boolean'];
  addUserToPTGroupByProfession?: Maybe<CourseUser>;
  answerOpenQuestion: OpenQuestionAnswer;
  answerPoll: PollAnswer;
  archiveChat: Participant;
  askCourseSupport: Scalars['Boolean'];
  askQnAQuestion: EventQuestion;
  askTechCheckQuestion: UserTechCheckAnswer;
  assignReferral?: Maybe<Scalars['Boolean']>;
  attachAchievementToCourse: CourseAchievement;
  attachPromoCodeToUser: UsedPromoCode;
  attendEvent?: Maybe<Scalars['Boolean']>;
  authorizeUserCard: PaymentResult;
  autoReviewPullRequestByUrl?: Maybe<AutoReviewPullRequestByLinkResponse>;
  backfillConsultationUtmTags: Scalars['Boolean'];
  backfillFTApplicationUtmTags: Scalars['Boolean'];
  backfillRegistrationUtmTags: Scalars['Boolean'];
  backfillVimeoVideosDuration: Scalars['Boolean'];
  backfillWebinarUtmTags: Scalars['Boolean'];
  batchReportOfferStatus: Array<ChatConnection>;
  bulkCreateChatMessages: BulkCreateChatMessagesResult;
  bulkCreateInvidTopicVideo: Array<CourseTopicVideo>;
  bulkCreateOrUpdatePrivateEntrepreneurDetails?: Maybe<Scalars['Boolean']>;
  bulkUpdateCourseTopicLearnItems: Array<CourseTopicLearnItem>;
  bulkUpdateCourseTopicTasks: Scalars['Boolean'];
  bulkUpdateEconomicCodes?: Maybe<Scalars['Boolean']>;
  bulkUpdateTopics: Scalars['Boolean'];
  cancelEvent?: Maybe<MateEvent>;
  cancelUserEvent?: Maybe<UserEvent>;
  changeChatFavouriteState: Participant;
  changeChatParticipantVisibility: Participant;
  changeChatsFavoriteState: Array<Participant>;
  changeCourseUserApplicationStatus: CourseUserApplication;
  /** @deprecated Use processSubscriptionChanges instead */
  changeSubscriptionPlan: PaymentSubscription;
  changeUserEmail?: Maybe<User>;
  changeUserRole?: Maybe<User>;
  changeUserRoles?: Maybe<User>;
  checkCodeEditorTask?: Maybe<CodeEditorCheckResult>;
  checkCodeEditorTaskAsync?: Maybe<Scalars['Boolean']>;
  checkOutNewUserAchievement: UserAchievement;
  checkProofOfRevenueData: ProofOfRevenue;
  /** @deprecated Use gradeQuiz mutation instead */
  checkQuiz?: Maybe<CheckQuizResult>;
  checkTestTaskTemplateAsync: Scalars['Boolean'];
  confirmProductIssuing?: Maybe<ProcessApplicationData>;
  connectOAuthProvider: ConnectOAuthProviderResponse;
  correctWorkingTime: WorkingTime;
  createAiAssistantChat: AbstractChat;
  createAlfabankAutoOrder: Scalars['String'];
  createAlfabankOrder: Scalars['String'];
  createAllCourseTopicsScheduleEvents: Array<ScheduleEvent>;
  createAnonymousStripePaymentIntent: StripePaymentIntent;
  createAnonymousSubscriptionPayment: PaymentResult;
  createAssignmentTask: Array<AssignmentTask>;
  createAutoTechCheckFromQuestions: AutoTechCheck;
  createAutoTechCheckFromTemplate: AutoTechCheck;
  createBillingAddress: BillingAddress;
  createCalendarTemplate: CalendarTemplate;
  createCallFromBpm: Call;
  createCandidateProfile: CandidateProfile;
  createCertificate: Certificate;
  createChat: AbstractChat;
  createChatGuidelineWithParticipant: ChatGuideline;
  createChatMessage: AbstractMessage;
  createChatMessageTemplate: ChatMessageTemplate;
  createChatSection: ChatSection;
  createCompanyFromBpm: Company;
  createCourseMonthPaymentSkip: CourseMonthPayment;
  createCourseMonthPaymentSkipByUser: CourseMonthPayment;
  createCourseTopicTask: CourseTopicTask;
  createCourseTopicTheoryFile: CourseTopicTheoryFile;
  createCourseTopicVideos: Array<CourseTopicVideo>;
  createCourseUserFromBpm: CourseUser;
  createDailyTask: DailyTask;
  createEnglishEvent?: Maybe<CreateEnglishEventResult>;
  createEventLiveCoding: Array<TestTaskLink>;
  createGeneratedPurchaseLink?: Maybe<GeneratedPurchaseLink>;
  createGrantedSubscription?: Maybe<GrantedSubscription>;
  createGroupDeadline: GroupDeadline;
  createGroupTeamMember: GroupTeamMember;
  createGuidelinePoll: GuidelinePoll;
  createGuidelineQuestion: GuidelineOpenQuestion;
  createGuidelineText: GuidelinePlainText;
  createGuidelineThread: GuidelineThread;
  createInterview: Interview;
  createInterviewEvent: InterviewEvent;
  createInvidNote: InvidNote;
  createJobRating?: Maybe<JobRating>;
  /** @deprecated Deprecated use processKnowelySubscriptionPayment instead */
  createKnowelySubscriptionPayment: PaymentResult;
  createLearnMessageTemplate: LearnMessageTemplate;
  createLearnVideoVersion: LearnVideoVersion;
  createLiveCodingTask: Array<LiveCodingTask>;
  createLoginCode: Scalars['String'];
  createMarathonEvent: MarathonEvent;
  createMarketSalary: MarketSalary;
  createMessageReaction: MessageReaction;
  createModule?: Maybe<Course>;
  createModuleFromExisting: Course;
  createMonobankOrder: Scalars['String'];
  createMultipleScheduleEvents: Array<ScheduleEvent>;
  createOpenQuestion: OpenQuestion;
  createOrUpdateMorningTask: MorningTask;
  createOrUpdateUserLegalDocuments: UserLegalDocuments;
  createPartnershipRecommendedStudentFromBpm: PartnershipRecommendedStudent;
  createPartnershipVacancyFromBpm: PartnershipVacancy;
  createPartnershipVacancyStatusHistoryFromBpm: PartnershipVacancyStatusHistory;
  createPayUOrder: Scalars['String'];
  createPoll: Poll;
  createPracticeLoginCode?: Maybe<LoginCodeLink>;
  createPrimaryUserCard: UserCard;
  createPrivatbankOrder: Scalars['String'];
  createPrivateEntrepreneurDetails: PrivateEntrepreneurDetails;
  createProfession: Profession;
  createProfessionFromExisting: Profession;
  createQuestionKeyword?: Maybe<TechCheckQuestionsKeyword>;
  createQuizTaskImage?: Maybe<TestTaskQuizImage>;
  createRating?: Maybe<RatingFull>;
  createRecruiterProfile: RecruiterProfile;
  createScheduleEvent: ScheduleEvent;
  createScheduleEventSkeleton?: Maybe<Array<CourseEventSkeleton>>;
  createScheduledMessage: ScheduledMessage;
  createServiceToken: AccessToken;
  createServiceUser: User;
  createSessionTechCheckEvent?: Maybe<TechCheckEvent>;
  createSkill: Skill;
  createSpecialOffersLink: SpecialOffersLink;
  createStripeSetupIntent?: Maybe<StripeSetupIntent>;
  createStudentsGroup: StudentsGroup;
  createStudentsTask: StudentsTask;
  createSubscriptionPayment: PaymentResult;
  createTaskReviewWithoutAccess: Scalars['Boolean'];
  createTechCheckEvent?: Maybe<TechCheckEvent>;
  createTechCheckEventDeprecated?: Maybe<CreateTechCheckEventResult>;
  createTechCheckQuestion: TechCheckQuestion;
  createTechCheckQuestionCheckphrase: TechCheckQuestionCheckphrase;
  createTechCheckSession: TechCheckSession;
  createTechCheckSessionEvent: TechCheckSessionEvent;
  createTechCheckTemplate: TechCheckTemplate;
  createTechCheckTopic: TechCheckTopic;
  createTelegramUser: TelegramUser;
  createTestTask: TestTask;
  createTestTaskAttempt: CreateTestTaskAttemptResult;
  createTestTaskFiles: Array<AbstractTestTaskFile>;
  createTestTaskPreview: TestTasksPreview;
  createTextSandboxFile: SandboxFile;
  createTopic: CourseTopic;
  createTopicTheoryReviewRequest: CourseTopic;
  createTrainingJob: GptTrainingJob;
  createTypingSpeedTest: TypingSpeedTest;
  createUkrsibbankOrder: Scalars['String'];
  createUserAgreement?: Maybe<UserAgreement>;
  createUserDocument: UserDocument;
  createUserEvent?: Maybe<UserEvent>;
  createUserExperiment: UserExperiment;
  createUserJob: UserJob;
  createUserNotification: AbstractNotification;
  createUserOnboarding?: Maybe<UserOnboarding>;
  createUserSchedule: UserSchedule;
  createUserTopicWithTaskLink?: Maybe<CourseUserTopic>;
  createUserVacancy: UserVacancy;
  createUserVacancyContact: UserVacancyContact;
  createUserVacancyHistory: UserVacancyHistory;
  createVacancy: UserVacancy;
  createVacancyAutoApproveResults: Array<VacancyAutoApproveResult>;
  createWayForPayOrder: Scalars['String'];
  createWebinarEvent?: Maybe<WebinarEvent>;
  createWorkPlace: CandidateProfileWorkPlace;
  deactivateSoftDeadline: Scalars['Boolean'];
  default?: Maybe<Scalars['Boolean']>;
  deleteAccount?: Maybe<Scalars['Boolean']>;
  deleteAssignmentTask: Array<AssignmentTask>;
  deleteCalendarTemplate?: Maybe<Scalars['Boolean']>;
  deleteChatConnection?: Maybe<Scalars['Boolean']>;
  deleteChatGuideline: Scalars['Boolean'];
  deleteChatMessage: Scalars['Boolean'];
  deleteChatMessageTemplate: Scalars['Boolean'];
  deleteChatSection: Scalars['Boolean'];
  deleteChatsFromSections: Scalars['Boolean'];
  deleteCourseMonthPaymentSkip: Scalars['Boolean'];
  deleteCourseTopicTask: CourseTopicTask;
  deleteCourseTopicTheoryFile: Scalars['Boolean'];
  deleteCourseTopicVideo: CourseTopicVideo;
  deleteDailyTask: Scalars['Boolean'];
  deleteDraftChatMessage: Scalars['Boolean'];
  deleteDraftTestTask: TestTask;
  deleteEnglishLessonEventSkeleton?: Maybe<EnglishLessonEventSkeleton>;
  deleteGroupEvent?: Maybe<Scalars['Boolean']>;
  deleteGroupTeamMember: Scalars['Boolean'];
  deleteLearnItem: Scalars['Boolean'];
  deleteLiveCodingTask: Array<LiveCodingTask>;
  deleteMessageReaction: Scalars['Boolean'];
  deleteMorningTask: MorningTask;
  deleteMultipleCalendarEvents: Array<Scalars['Int']>;
  deleteQnAQuestion: Scalars['Boolean'];
  deleteQuizTaskImage?: Maybe<Scalars['Boolean']>;
  deleteReactionFromStack: DeleteReactionResult;
  deleteScheduleEventSkeleton?: Maybe<CourseEventSkeleton>;
  deleteScheduledMessage: Scalars['Boolean'];
  deleteStudentsTask: Scalars['Boolean'];
  deleteTechCheckEventSkeleton?: Maybe<TechCheckEventSkeleton>;
  deleteTechCheckQuestion: TechCheckQuestion;
  deleteTechCheckQuestionCheckphrase: TechCheckQuestionCheckphrase;
  deleteTechCheckSession: TechCheckSession;
  deleteTechCheckTemplate?: Maybe<TechCheckTemplate>;
  deleteTechCheckTopic: TechCheckTopic;
  deleteTestTaskAttemptFile: Scalars['Boolean'];
  deleteTestTaskAttemptFiles: Scalars['Boolean'];
  deleteTestTaskFiles: Array<AbstractTestTaskFile>;
  deleteTestTaskPreview: Scalars['Int'];
  deleteUserDocument?: Maybe<Scalars['Boolean']>;
  deleteUserSchedule: Scalars['Boolean'];
  deleteWorkPlace: Scalars['Boolean'];
  describeWorkingTime: Scalars['Boolean'];
  disconnectOAuthProvider: Scalars['Boolean'];
  distributeTechCheckSessionEventParticipants: Array<TechCheckEvent>;
  duplicateScheduleEvent: ScheduleEvent;
  duplicateScheduleEventSkeleton?: Maybe<CourseEventSkeleton>;
  editModule?: Maybe<Course>;
  ensureCourseTopicLearnItemAttempt: CourseTopicLearnItemAttempt;
  ensureCourseUsersStatusHistory: CourseUsersStatusHistory;
  ensureDirectChat: AbstractChat;
  ensureEventLocation: MateEvent;
  ensurePeopleforceEmployee: User;
  ensurePeopleforceEmployeeSalary?: Maybe<TeacherRate>;
  ensureUsersStudentStatusHistory: UsersStudentStatusHistory;
  expireReviews: Array<TaskReview>;
  fetchWorkPlaces?: Maybe<Array<CandidateProfileWorkPlace>>;
  fillEmploymentProfile: EmploymentProfile;
  findOrCreateTechCheckLiveCodingLink: TestTaskLink;
  findOrCreateUserDailyTasksLink: UserDailyTasksLink;
  finishAutoTechCheck: AutoTechCheck;
  finishTest: Scalars['Int'];
  finishTestTaskVersion: TestTaskVersion;
  finishWork?: Maybe<TestTaskLink>;
  forgotPassword: Scalars['Boolean'];
  generateCalendarEvents: Array<ScheduleEvent>;
  generateEnglishLessons: Scalars['Boolean'];
  /** Deprecated mutation, CLND-612 */
  generateScheduleEvents: Array<ScheduleEvent>;
  generateTechCheckEventForSkeleton?: Maybe<TechCheckEvent>;
  generateTechCheckEventsForFutureSessions: Array<TechCheckSessionEvent>;
  generateTechCheckEventsForSession: Array<TechCheckEvent>;
  generateTechCheckLink: TechCheckLink;
  generateTechChecks: Array<TechCheckEvent>;
  gradeQuiz: CheckQuizResult;
  grantZeroXpForTask: Scalars['Boolean'];
  hideIrrelevantVacancy?: Maybe<HideIrrelevantVacancyMutationResult>;
  initDailyMorningTasks: Scalars['Boolean'];
  isEventParticipant: Scalars['Boolean'];
  joinChat: Participant;
  joinEvent: UserEvent;
  joinScheduleEvent?: Maybe<UserEvent>;
  joinTeamProject: JoinTeamProjectResult;
  joinTechCheck: TechCheckParticipant;
  leaveChat: Participant;
  leaveTeamProject: Scalars['Boolean'];
  lintFiles: Array<LinterResult>;
  loanRefund: Scalars['String'];
  logOut: Scalars['Boolean'];
  logOutFromAllDevices: Scalars['Boolean'];
  mergeCompanies: Array<Company>;
  moderateAlfabankOrder: Payment;
  moderateReferralEvents: Scalars['Boolean'];
  moderateTechCheckAnswer: UserTechCheckAnswer;
  moveCourseTopicVideo: CourseTopic;
  moveLongWaitingPendingAIToPending: Array<TaskReview>;
  moveLongWaitingPendingPeerToPending: Array<TaskReview>;
  moveSandboxFile: SandboxFile;
  moveSandboxFilesUsingUUID: Scalars['Boolean'];
  moveTestTaskAttemptFiles: Array<AbstractTestTaskAttemptFile>;
  notifyAboutFinishedStreak: Scalars['Boolean'];
  notifyUsersFillOutInterviewFeedbackForm: Array<Scalars['Int']>;
  peerReviewTask: PeerReview;
  pinChatMessage: AbstractMessage;
  preSignIn: PreSignInStatus;
  prepareAttemptFilesForDownload: Scalars['String'];
  prepareSubscriptionCheckoutData: PrepareSubscriptionCheckoutData;
  /** @deprecated Use processKnowelySubscriptionFreeTrial instead */
  processAnonymousSubscriptionFreeTrial: SubsciptionFreeTrialSetupResult;
  processApplicationRequest: ProcessApplicationRequestResponse;
  processConsultationRequest: ProcessConsultationRequestResponse;
  processCourseMonthPayment?: Maybe<PaymentResult>;
  processEmploymentOverdueStudents: Array<User>;
  processExistingSubscription: Scalars['Boolean'];
  processExternalPayment: Payment;
  processFutureTechCheckSessionEvent: TechCheckSessionEvent;
  processKnowelySubscriptionFreeTrial: SubsciptionFreeTrialSetupResult;
  processKnowelySubscriptionPayment: AnonymousPaymentResult;
  processLearnItemOpenedAction: CourseUserTopic;
  processOpenTrialAccess?: Maybe<Scalars['Boolean']>;
  processPWLTransactionCharge?: Maybe<PaymentResult>;
  processPaymentTransactionCheckout: Payment;
  processProofOfRevenue?: Maybe<ProofOfRevenue>;
  processRefund: Payment;
  processRewardableAction: Scalars['Boolean'];
  processSubscriptionCancellationBonus: PaymentSubscription;
  processSubscriptionChanges: PaymentSubscription;
  /** @deprecated Use processKnowelySubscriptionFreeTrial instead */
  processSubscriptionFreeTrial: SubsciptionFreeTrialSetupResult;
  processSubscriptionFreeTrialResult: PaymentSubscription;
  processTechCheckSkeletonsForGeneration: Array<TechCheckEventSkeleton>;
  processUpdateEnglishLessonEventSkeleton?: Maybe<ProcessUpdateEnglishLessonEventSkeletonResult>;
  processVacancy: Vacancy;
  processVacancyCity: VacancyCity;
  processVacancyCountry: Vacancy;
  processWebinarRegistration: ProcessWebinarRegistrationResult;
  rateOpenQuestionAnswers: Array<OpenQuestionAnswer>;
  reParseVacancies: Scalars['Int'];
  readAllUserNotifications: Array<AbstractNotification>;
  readChatMessage: ReadChatMessageResult;
  /** @deprecated Use readChatMessage/readThreadReply instead */
  readMessage: Scalars['Boolean'];
  readThreadReply: ReadThreadReplyResult;
  reconnectOAuthProvider: Scalars['Boolean'];
  recordIdleTimestamp?: Maybe<ExamInsight>;
  recordPastedContent?: Maybe<ExamInsight>;
  recordSelectTextAttempt?: Maybe<ExamInsight>;
  recordTabBlurTimestamp?: Maybe<ExamInsight>;
  reduceWorkingTime: WorkingTime;
  regenerateCertificates: Scalars['Boolean'];
  rejectApplication?: Maybe<ProcessApplicationData>;
  remindStreakDeactivation: Scalars['Boolean'];
  remindStreakLoseFrozenDay: Scalars['Boolean'];
  remindTrialUpgrade: Scalars['Boolean'];
  removeCV: CandidateProfile;
  removeCard?: Maybe<Scalars['Boolean']>;
  removeCourseModule: Array<CourseModule>;
  removeLearnMessageTemplate: Scalars['Boolean'];
  removeMessageMedia: Scalars['Boolean'];
  removeParticipantFromChat: Participant;
  removeSandboxFile: Scalars['Boolean'];
  removeUserFromChats: Scalars['Int'];
  removeUserVacancyContact: Scalars['Boolean'];
  removeUserVacancyHistory: Scalars['Boolean'];
  renameTestTaskFiles: Array<AbstractTestTaskFile>;
  reorderChatSection: ChatSection;
  reorderTechCheckQuestions: Array<TechCheckQuestion>;
  reorderTechCheckTopics: Array<TechCheckTopic>;
  replaceTaskDescriptions: Scalars['Int'];
  reportOfferStatus: ChatConnection;
  requestAccountDeletion: DeleteAccountResult;
  requestEmailChange: EmailChangeResult;
  requestMentorReview?: Maybe<TaskReview>;
  requestSpeakingTestChanges: Scalars['Boolean'];
  resetCompanyRelation: Array<Company>;
  resetPassword: Scalars['Boolean'];
  resetTopicProgress: Scalars['Boolean'];
  resetTopicsProgress: Scalars['Boolean'];
  returnProduct?: Maybe<ReturnProductData>;
  revalidateVimeoVideoDuration: LearnVideo;
  revertLMSContentChangeById: Scalars['Boolean'];
  reviewChatConnectionRequest: ChatConnection;
  reviewTask?: Maybe<TaskReview>;
  reviewTestTaskAttemptWithAI: AiTestTaskAttemptReview;
  revokeServiceToken: Scalars['Boolean'];
  runSandbox: Sandbox;
  saveChatMessage: AbstractMessage;
  saveCodeEditorTask?: Maybe<Scalars['Boolean']>;
  saveDraftChatMessage: DraftMessage;
  saveInvidVersion: InvidVersion;
  seedUsersPermissionsInGpt: Scalars['Boolean'];
  sendAttachmentTaskLink: AddAttachmentTaskLinkResult;
  sendChatConnectionRequest?: Maybe<ChatConnection>;
  sendConfirmEmailLink: User;
  sendDesktopLink: Scalars['Boolean'];
  sendEmploymentDeadlineNotification: Scalars['Boolean'];
  sendEmploymentProfileAvailableNotification: Scalars['Boolean'];
  sendEventsRatingNotifications: SendEventsRatingNotificationsResult;
  sendGuidelineMessageToChat: Scalars['Boolean'];
  sendPossibleIdleTimeNotifications: Array<WorkingTime>;
  sendRatingTaskReviewNotification: Scalars['Boolean'];
  sendReviewTaskNotification: Scalars['Boolean'];
  sendSignInCodeLink: Scalars['Boolean'];
  sendUserScheduleNotification: Scalars['Boolean'];
  sendWeeklyNotificationsToActiveStudents: Scalars['Boolean'];
  sendWeeklyNotificationsToInactiveStudents: Scalars['Boolean'];
  sendWeeklyPaceNotifications: Scalars['Boolean'];
  sendWeeklyReport: Scalars['Boolean'];
  setChatConnectionStage: ChatConnection;
  setEmailAndPassword?: Maybe<User>;
  setEntry: Sandbox;
  setGroupsActiveCoach: Scalars['Boolean'];
  setGroupsCoaches: Scalars['Boolean'];
  setInitialInvidState: Invid;
  setPassword: Scalars['Boolean'];
  setPrimaryCard?: Maybe<UserCard>;
  setSandboxCTAShown: User;
  setUpgradeTestNextTryDate: CourseUser;
  shareCertificate: Scalars['String'];
  signIn: User;
  signInAsUser: User;
  signInWithCode: User;
  signOutFromUser: Scalars['Boolean'];
  signUp: User;
  socialSignUp: User;
  softDeleteTopic: CourseTopic;
  solveCourseTopicLearnItem: CourseTopicLearnItemAttempt;
  startAnsweringAutoTechCheckQuestion: UserTechCheckAnswer;
  startExam?: Maybe<CourseUserTopic>;
  startLearning: Scalars['Int'];
  startSubscription: PaymentSubscription;
  /** @deprecated Use createTestTaskAttempt instead */
  startTask: TestTaskAttempt;
  startTaskAIReview: Scalars['Boolean'];
  startTaskPeerReview: PeerReview;
  startTaskReview?: Maybe<TaskReview>;
  startTaskReviewById?: Maybe<TaskReview>;
  startTest: Scalars['Int'];
  startTestPreview: Scalars['Int'];
  startWork?: Maybe<TestTaskLink>;
  startWorkingTime: WorkingTime;
  stopAnsweringAutoTechCheckQuestion: UserTechCheckAnswer;
  stopAnsweringTechCheckQuestion: Scalars['Boolean'];
  stopWorkingTime: WorkingTime;
  studentsGroupInfo?: Maybe<StudentsGroup>;
  submitAppOnboarding: UserAppOnboarding;
  submitSurvey: Scalars['Int'];
  submitWorkingTimeActivityComment: WorkingTimeActivity;
  subscribeNotificationsDevice: UserNotificationsDevice;
  switchGroupsCoaches: Scalars['Boolean'];
  syncAiAssistantActionButtonRating: Scalars['Boolean'];
  syncProofOfRevenueFeedback: RatingFull;
  syncSpreadSheetGroupInfo: Scalars['Boolean'];
  syncTaskAttemptWithGithub?: Maybe<Scalars['Boolean']>;
  taskReadyForReview?: Maybe<TaskReview>;
  testSpeechRecognition?: Maybe<Transcription>;
  toggleChatParticipantBan: Participant;
  toggleEventsSidebar?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  toggleSidebar?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  toggleUserSavedCourse?: Maybe<Course>;
  toggleUserVacancySidebar?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  toggleVacancyEditor?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  transcribeTechCheckAnswer: Scalars['Boolean'];
  tryCourse?: Maybe<TryCourseData>;
  unUseLearnMessageTemplateForMornings: Scalars['Boolean'];
  unarchiveChat: Participant;
  unpinChatMessage: AbstractMessage;
  unsaveChatMessage: AbstractMessage;
  unsubscribe?: Maybe<PaymentSubscription>;
  unsubscribeNotificationsDevice: Scalars['Boolean'];
  updateAvatar: Scalars['Boolean'];
  updateCandidateProfile: CandidateProfile;
  updateChat: AbstractChat;
  updateChatConfig: AbstractChat;
  updateChatGuideline: ChatGuideline;
  updateChatGuidelineOrder: ChatGuideline;
  updateChatMessage: AbstractMessage;
  updateChatMessageTemplate: ChatMessageTemplate;
  updateChatSection: ChatSection;
  updateCity: City;
  updateCompany: Company;
  updateCountry: Country;
  updateCourseEventSkeleton?: Maybe<CourseEventSkeleton>;
  updateCourseMonthPayment: CourseMonthPayment;
  updateCourseTopicConfig: CourseTopic;
  updateCourseTopicTask: CourseTopicTask;
  updateCourseTopicTheoryFile: CourseTopicTheoryFile;
  updateCourseUser?: Maybe<CourseUser>;
  updateCourseUserApplication: CourseUserApplication;
  updateCourseUserTopic?: Maybe<CourseUserTopic>;
  updateCourseUserTopicStatus?: Maybe<CourseUserTopic>;
  updateCourseUserTopicsStatuses: Array<CourseUserTopic>;
  updateCourseUserTopicsStatusesByTopicSlug: Array<CourseUserTopic>;
  updateDailyTask: DailyTask;
  updateEconomicCodes?: Maybe<Scalars['Boolean']>;
  updateEnglishEvent?: Maybe<EnglishEvent>;
  updateEnglishLessonEventSkeleton?: Maybe<EnglishLessonEventSkeleton>;
  updateEvent?: Maybe<UpdateEventResult>;
  updateEventDetails?: Maybe<EventDetails>;
  updateGoogleMeetUserEvents: Scalars['Boolean'];
  updateGroupDeadline: GroupDeadline;
  updateGroupScheduleEvent?: Maybe<ScheduleEvent>;
  updateGroupTeamMember: GroupTeamMember;
  updateGuidelineMessage: AbstractGuidelineMessage;
  updateGuidelineMessageOrder: AbstractGuidelineMessage;
  updateInterview: Interview;
  updateInterviewEvent: InterviewEvent;
  updateInvidVersion: InvidVersion;
  updateJobRating: JobRating;
  updateLastActionTime?: Maybe<Participant>;
  updateLearnMessageTemplate: Scalars['Boolean'];
  updateLearnVideo: LearnVideo;
  updateLearnVideoVersion: LearnVideoVersion;
  updateLocale: Scalars['Boolean'];
  updateMorningTask: MorningTask;
  updateMultipleCalendarEvents: Array<EventAbstract>;
  updateParticipantNotificationSettings: Participant;
  updatePassword: Scalars['Boolean'];
  updatePermissions: Array<GroupedByParentPermissions>;
  updatePersonalChat: Scalars['Boolean'];
  updatePlatformSetting: UserPlatformSettingAbstract;
  updatePositionCourseModules: Scalars['Boolean'];
  updatePrivateEntrepreneurDetails: PrivateEntrepreneurDetails;
  updatePrivateEntrepreneurDetailsByUserId: PrivateEntrepreneurDetails;
  updateProfession: Scalars['Boolean'];
  updateProfile: User;
  updateProofOfRevenueStatus: ProofOfRevenue;
  updateQnAQuestionStatus: EventQuestion;
  updateQuestionKeyword?: Maybe<TechCheckQuestionsKeyword>;
  updateQuestionKeywords: Scalars['Boolean'];
  /** @deprecated Mutation name was not correct. Use upsertRating instead */
  updateRating?: Maybe<Rating>;
  updateRecruiterProfile: RecruiterProfile;
  updateReferralEvent: ReferralEvent;
  updateRegion: Region;
  updateSandboxFileContent: SandboxFile;
  updateScheduleEventSkeletonDates?: Maybe<Array<CourseEventSkeleton>>;
  updateSoftDeadline: SoftDeadline;
  updateStudentsGroup: StudentsGroup;
  updateStudentsTask: StudentsTask;
  updateSubscription: PaymentSubscription;
  updateTechCheckAnswer: UserTechCheckAnswer;
  updateTechCheckAnswerCheckphrase: TechCheckAnswerCheckphrase;
  updateTechCheckEvent?: Maybe<TechCheckEvent>;
  updateTechCheckEventSkeleton?: Maybe<TechCheckEventSkeleton>;
  updateTechCheckQuestion: TechCheckQuestion;
  updateTechCheckQuestionCheckphrase: TechCheckQuestionCheckphrase;
  updateTechCheckSessionEvent: TechCheckSessionEvent;
  updateTechCheckSessionParticipants: TechCheckSession;
  updateTechCheckSessionQuestions: TechCheckSession;
  updateTechCheckTemplate: TechCheckTemplate;
  updateTechCheckTopic: TechCheckTopic;
  updateTestTask: TestTask;
  updateTestTaskDescription: Array<ContentFile>;
  updateTestTaskFile: Array<AbstractTestTaskFile>;
  updateTestTaskLink: Array<TestTaskLink>;
  updateThreadSubscribed: Thread;
  updateTopic?: Maybe<CourseTopic>;
  updateTopicTheory: CourseTopic;
  updateUnprocessedLocation?: Maybe<UnprocessedLocation>;
  updateUser: User;
  updateUserAgreement?: Maybe<UserAgreement>;
  updateUserDocument: UserDocument;
  updateUserDomain: User;
  updateUserDomainAndLanguage: User;
  updateUserEvent: UserEvent;
  updateUserExperiment: UserExperiment;
  updateUserJob: UserJob;
  updateUserLanguage: User;
  updateUserLastActionTime: Scalars['Boolean'];
  updateUserLegalDocuments?: Maybe<UserLegalDocuments>;
  /** @deprecated Moving to config-based settings. Use 'updatePlatformSetting' instead */
  updateUserPlatformSetting: UserPlatformSetting;
  updateUserSchedule: UserSchedule;
  updateUserTestTaskLinkTasks?: Maybe<TestTaskLink>;
  updateUserTimeZone?: Maybe<UserPlatformSetting>;
  updateUserTopicStructure: CourseUserTopic;
  updateUserVacanciesFilter: UserVacanciesFilter;
  updateUserVacancy: UpdateUserVacancyResult;
  updateUserVacancyContact: UserVacancyContact;
  updateUserVacancyHistory: UserVacancyHistory;
  updateUsersSubscriptionHash?: Maybe<Scalars['Boolean']>;
  updateVacancies: Array<Vacancy>;
  updateVacancy: Vacancy;
  updateVideoOrders: Scalars['Boolean'];
  updateViolatedUserEvents: UpdateViolatedResult;
  updateWorkPlace: CandidateProfileWorkPlace;
  uploadCv: CandidateProfile;
  uploadMessageMedia: File;
  uploadTechCheckAnswer: UserTechCheckAnswer;
  uploadUserLegalDocuments: Scalars['Boolean'];
  upsertRating: RatingFull;
  useLearnMessageTemplateForMornings: Scalars['Boolean'];
  useSlackInvitationLink?: Maybe<SlackInvitationCount>;
  verifyOrder?: Maybe<ProcessApplicationData>;
  verifyResetPasswordToken?: Maybe<Scalars['Boolean']>;
  withdrawMoney: Scalars['String'];
};


export type MutationActivateSoftDeadlineArgs = {
  values: ActivateSoftDeadlineInput;
};


export type MutationActivateSubscriptionArgs = {
  id: Scalars['Int'];
};


export type MutationActivateUserStreakArgs = {
  userId: Scalars['Int'];
};


export type MutationAddAttachmentTaskLinkArgs = {
  userId: Scalars['Int'];
  slug: Scalars['String'];
  linkUUID: Scalars['String'];
  source: Scalars['String'];
};


export type MutationAddChatParticipantsArgs = {
  fields: AddChatParticipantsFields;
};


export type MutationAddChatsToSectionArgs = {
  id: Scalars['Int'];
  chatIds: Array<Scalars['Int']>;
};


export type MutationAddCompanyArgs = {
  name?: Maybe<Scalars['String']>;
};


export type MutationAddCourseModuleArgs = {
  professionSlug: Scalars['String'];
  childCourseId: Scalars['Int'];
};


export type MutationAddExperimentArgs = {
  optimizeExperimentId: Scalars['String'];
};


export type MutationAddInvidToTopicArgs = {
  courseTopicId: Scalars['Int'];
  invidId: Scalars['Int'];
};


export type MutationAddReactionToStackArgs = {
  messageId: Scalars['Int'];
  reactionShortcode: Scalars['String'];
};


export type MutationAddTestTaskAttemptFilesArgs = {
  attemptId: Scalars['Int'];
  filesToCreate: Array<AttemptFileToCreate>;
};


export type MutationAddUserToChatsArgs = {
  userId: Scalars['Int'];
  chatIds: Array<Scalars['Int']>;
};


export type MutationAddUserToPtGroupArgs = {
  userId: Scalars['Int'];
  professionId: Scalars['Int'];
};


export type MutationAddUserToPtGroupByProfessionArgs = {
  professionId: Scalars['Int'];
  domain?: Maybe<Scalars['String']>;
};


export type MutationAnswerOpenQuestionArgs = {
  fields: OpenQuestionAnswerFields;
};


export type MutationAnswerPollArgs = {
  fields: PollAnswerFields;
};


export type MutationArchiveChatArgs = {
  chatId: Scalars['Int'];
};


export type MutationAskCourseSupportArgs = {
  link: Scalars['String'];
  providerName: SupportProviderName;
};


export type MutationAskQnAQuestionArgs = {
  eventId: Scalars['Int'];
  text: Scalars['String'];
};


export type MutationAskTechCheckQuestionArgs = {
  userId: Scalars['Int'];
  techCheckLinkId: Scalars['Int'];
  questionId: Scalars['Int'];
};


export type MutationAssignReferralArgs = {
  referralCode: Scalars['String'];
};


export type MutationAttachAchievementToCourseArgs = {
  options: AttachAchievementOptions;
};


export type MutationAttachPromoCodeToUserArgs = {
  promoCodeSlug: Scalars['String'];
};


export type MutationAttendEventArgs = {
  eventId: Scalars['Int'];
};


export type MutationAutoReviewPullRequestByUrlArgs = {
  url: Scalars['String'];
};


export type MutationBackfillConsultationUtmTagsArgs = {
  values: Array<BackfillConsultationUtmTagsInput>;
};


export type MutationBackfillFtApplicationUtmTagsArgs = {
  values: Array<BackfillFtApplicationUtmTagsInput>;
};


export type MutationBackfillRegistrationUtmTagsArgs = {
  chunkSize?: Maybe<Scalars['Int']>;
  nightTimeChunkMultiplier?: Maybe<Scalars['Int']>;
};


export type MutationBackfillVimeoVideosDurationArgs = {
  chunkSize: Scalars['Int'];
};


export type MutationBackfillWebinarUtmTagsArgs = {
  values: Array<BackfillWebinarUtmTagsInput>;
};


export type MutationBatchReportOfferStatusArgs = {
  options: Array<BatchReportOfferStatusOptions>;
};


export type MutationBulkCreateChatMessagesArgs = {
  fields: BulkCreateChatMessagesFields;
};


export type MutationBulkCreateInvidTopicVideoArgs = {
  courseTopicId: Scalars['Int'];
  values: Array<CreateInvidValues>;
};


export type MutationBulkCreateOrUpdatePrivateEntrepreneurDetailsArgs = {
  details: Array<Maybe<CreatePrivateEntrepreneurDetailsValues>>;
};


export type MutationBulkUpdateCourseTopicLearnItemsArgs = {
  items: Array<UpdateCourseTopicLearnItemInput>;
};


export type MutationBulkUpdateCourseTopicTasksArgs = {
  updatedCourseTopicTasks: Array<UpdatedCourseTopicTasksInput>;
};


export type MutationBulkUpdateEconomicCodesArgs = {
  options: Array<Maybe<BulkUpdateOptions>>;
};


export type MutationBulkUpdateTopicsArgs = {
  updatedCourseTopics: Array<UpdateCourseTopicInput>;
};


export type MutationCancelEventArgs = {
  eventId: Scalars['Int'];
};


export type MutationCancelUserEventArgs = {
  userId: Scalars['Int'];
  eventId: Scalars['Int'];
  status?: Maybe<UserEventStatuses>;
};


export type MutationChangeChatFavouriteStateArgs = {
  chatId: Scalars['Int'];
};


export type MutationChangeChatParticipantVisibilityArgs = {
  participantId: Scalars['Int'];
  visibilityType: ChatParticipantVisibilityType;
};


export type MutationChangeChatsFavoriteStateArgs = {
  chatIds: Array<Scalars['Int']>;
  isFavorite: Scalars['Boolean'];
};


export type MutationChangeCourseUserApplicationStatusArgs = {
  courseUserId: Scalars['Int'];
  application?: Maybe<ApplicationStatusName>;
  status?: Maybe<CourseUserApplicationStatuses>;
  lang?: Maybe<Scalars['String']>;
  documentType?: Maybe<DocumentTypes>;
};


export type MutationChangeSubscriptionPlanArgs = {
  subscriptionId: Scalars['Int'];
  subscriptionPlanId: Scalars['Int'];
  changeReason: SubscriptionPlanChangeReason;
  comment?: Maybe<Scalars['String']>;
};


export type MutationChangeUserEmailArgs = {
  userId: Scalars['Int'];
  email: Scalars['String'];
};


export type MutationChangeUserRoleArgs = {
  userId: Scalars['Int'];
  role: UserRole;
};


export type MutationChangeUserRolesArgs = {
  userId: Scalars['Int'];
  roles: Array<Scalars['String']>;
};


export type MutationCheckCodeEditorTaskArgs = {
  slug: Scalars['String'];
  linkUUID: Scalars['String'];
  files: Array<Maybe<BaseTestTaskfile>>;
  testTaskAttemptId?: Maybe<Scalars['Int']>;
  taskContext?: Maybe<TestTaskContext>;
};


export type MutationCheckCodeEditorTaskAsyncArgs = {
  slug: Scalars['String'];
  linkUUID: Scalars['String'];
  files: Array<Maybe<BaseTestTaskfile>>;
  testTaskAttemptId?: Maybe<Scalars['Int']>;
  taskContext: TestTaskContext;
  shouldRunTests?: Maybe<Scalars['Boolean']>;
};


export type MutationCheckOutNewUserAchievementArgs = {
  id: Scalars['Int'];
};


export type MutationCheckProofOfRevenueDataArgs = {
  proofOfRevenueId: Scalars['Int'];
};


export type MutationCheckQuizArgs = {
  slug: Scalars['String'];
  linkUUID: Scalars['String'];
  selectedOptions: Array<Maybe<SelectedOption>>;
  taskContext: TestTaskContext;
};


export type MutationCheckTestTaskTemplateAsyncArgs = {
  name: Scalars['String'];
  files: Array<Maybe<TestTaskFileToUpdate>>;
  programmingLanguage: ProgrammingLanguage;
};


export type MutationConfirmProductIssuingArgs = {
  providerId: Scalars['Int'];
  orderId: Scalars['String'];
};


export type MutationConnectOAuthProviderArgs = {
  provider: OAuthProviders;
  token: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCorrectWorkingTimeArgs = {
  teacherId: Scalars['Int'];
  start: Scalars['Date'];
  end: Scalars['Date'];
  newPeriodAmount: Scalars['Int'];
};


export type MutationCreateAiAssistantChatArgs = {
  testTaskAttemptId: Scalars['Int'];
};


export type MutationCreateAlfabankAutoOrderArgs = {
  subscriptionPlanId: Scalars['Int'];
  phone: Scalars['String'];
  cardNumberEnd: Scalars['String'];
  orderTerm: Scalars['Int'];
  subscriptionId?: Maybe<Scalars['Int']>;
  purchaseLinkId?: Maybe<Scalars['Int']>;
};


export type MutationCreateAlfabankOrderArgs = {
  subscriptionPlanId: Scalars['Int'];
  loanProviderId: Scalars['Int'];
  subscriptionId?: Maybe<Scalars['Int']>;
  purchaseLinkId?: Maybe<Scalars['Int']>;
};


export type MutationCreateAllCourseTopicsScheduleEventsArgs = {
  values?: Maybe<CreateAllCourseTopicsScheduleEventsArgs>;
};


export type MutationCreateAnonymousStripePaymentIntentArgs = {
  analyticsData: PaymentAnalyticsData;
  subscriptionPlanId: Scalars['Int'];
  pricingOptionId?: Maybe<Scalars['Int']>;
};


export type MutationCreateAnonymousSubscriptionPaymentArgs = {
  planId: Scalars['Int'];
  successUrl?: Maybe<Scalars['String']>;
  billingDetails?: Maybe<BillingDetails>;
  isInternalCheckout?: Maybe<Scalars['Boolean']>;
  userOptions?: Maybe<UserOptions>;
};


export type MutationCreateAssignmentTaskArgs = {
  values: CreateAssignmentTaskInput;
};


export type MutationCreateAutoTechCheckFromQuestionsArgs = {
  values: CreateAutoTechCheckFromQuestionsValues;
};


export type MutationCreateAutoTechCheckFromTemplateArgs = {
  values: CreateAutoTechCheckFromTemplateValues;
};


export type MutationCreateBillingAddressArgs = {
  values: BillingDetails;
  userId?: Maybe<Scalars['Int']>;
};


export type MutationCreateCalendarTemplateArgs = {
  fields: CreateCalendarTemplateFields;
  existingTemplateId?: Maybe<Scalars['Int']>;
};


export type MutationCreateCallFromBpmArgs = {
  values: CreateCallFromBpmValues;
};


export type MutationCreateCandidateProfileArgs = {
  options: CreateCandidateProfileOptions;
};


export type MutationCreateCertificateArgs = {
  courseId: Scalars['Int'];
};


export type MutationCreateChatArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};


export type MutationCreateChatGuidelineWithParticipantArgs = {
  fields: CreateGuidelineFields;
};


export type MutationCreateChatMessageArgs = {
  fields: CreateMessageFields;
};


export type MutationCreateChatMessageTemplateArgs = {
  fields: CreateMessageTemplateFields;
};


export type MutationCreateChatSectionArgs = {
  name: Scalars['String'];
};


export type MutationCreateCompanyFromBpmArgs = {
  values: CreateCompanyFromBpmValues;
};


export type MutationCreateCourseMonthPaymentSkipArgs = {
  userId: Scalars['Int'];
  skipReason?: Maybe<Scalars['String']>;
  monthPaidOffDate: Scalars['Date'];
};


export type MutationCreateCourseMonthPaymentSkipByUserArgs = {
  skipReason: Scalars['String'];
};


export type MutationCreateCourseTopicTaskArgs = {
  values: CreateCourseTopicTasksInput;
};


export type MutationCreateCourseTopicTheoryFileArgs = {
  input: CreateCourseTopicTheoryFileInput;
};


export type MutationCreateCourseTopicVideosArgs = {
  courseTopicSlug?: Maybe<Scalars['String']>;
  courseTopicId?: Maybe<Scalars['Int']>;
  values: Array<CreateCourseTopicVideoValues>;
};


export type MutationCreateCourseUserFromBpmArgs = {
  values: CreateCourseUserFromBpmValues;
};


export type MutationCreateDailyTaskArgs = {
  values: CreateDailyTaskInput;
};


export type MutationCreateEnglishEventArgs = {
  values?: Maybe<CreateEnglishEventArgs>;
};


export type MutationCreateEventLiveCodingArgs = {
  eventId: Scalars['Int'];
  userIds: Array<Scalars['Int']>;
  testTaskIds: Array<Scalars['Int']>;
  hourMultiplier: Scalars['Float'];
};


export type MutationCreateGeneratedPurchaseLinkArgs = {
  userEmail: Scalars['String'];
  subscriptionPlanId: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  installmentsEnabled?: Maybe<Scalars['Boolean']>;
  paymentProviderId?: Maybe<Scalars['Int']>;
  contractedAmount: Scalars['Float'];
  contractedCurrencyId: Scalars['Int'];
  partialAmount?: Maybe<Scalars['Float']>;
  partialCurrencyId?: Maybe<Scalars['Int']>;
  discountReason: DiscountReasons;
  isExternalPayment?: Maybe<Scalars['Boolean']>;
  instalmentData?: Maybe<InstalmentData>;
  bonusSubscriptionPlanIds?: Maybe<Array<Scalars['Int']>>;
  secondPaymentDeadline?: Maybe<Scalars['Date']>;
  billingData?: Maybe<PurchaseLinkBillingData>;
  offerId?: Maybe<Scalars['String']>;
  upfrontAmount?: Maybe<Scalars['Int']>;
  promoCodeId?: Maybe<Scalars['Int']>;
};


export type MutationCreateGrantedSubscriptionArgs = {
  userEmail: Scalars['String'];
  subscriptionPlanId: Scalars['Int'];
  price: Scalars['Float'];
  comment: Scalars['String'];
  receipt?: Maybe<Receipt>;
  subscriptionId?: Maybe<Scalars['Int']>;
  paymentProviderId?: Maybe<Scalars['Int']>;
  currencyId?: Maybe<Scalars['Int']>;
  loanSubscriptionValues?: Maybe<LoanSubscriptionValues>;
};


export type MutationCreateGroupDeadlineArgs = {
  fields: GroupDeadlineCreationOptions;
};


export type MutationCreateGroupTeamMemberArgs = {
  fields: GroupTeamMemberCreationOptions;
};


export type MutationCreateGuidelinePollArgs = {
  fields: CreateGuidelinePollFields;
};


export type MutationCreateGuidelineQuestionArgs = {
  fields: CreateGuidelineQuestionFields;
};


export type MutationCreateGuidelineTextArgs = {
  fields: CreateGuidelineMessageFields;
};


export type MutationCreateGuidelineThreadArgs = {
  fields: CreateGuidelineMessageFields;
};


export type MutationCreateInterviewArgs = {
  values: CreateInterviewValues;
};


export type MutationCreateInterviewEventArgs = {
  args: CreateInterviewEventArgs;
};


export type MutationCreateInvidNoteArgs = {
  invidVersionId: Scalars['Int'];
  sandboxId: Scalars['Int'];
  timestamp: Scalars['Int'];
  files: Array<CopySandboxFileValues>;
};


export type MutationCreateJobRatingArgs = {
  userId: Scalars['Int'];
  paymentId?: Maybe<Scalars['Int']>;
  rating: Scalars['Int'];
};


export type MutationCreateKnowelySubscriptionPaymentArgs = {
  planId: Scalars['Int'];
  pricingOptionId?: Maybe<Scalars['Int']>;
  successUrl?: Maybe<Scalars['String']>;
  billingDetails?: Maybe<BillingDetails>;
  isInternalCheckout?: Maybe<Scalars['Boolean']>;
  userOptions?: Maybe<UserOptions>;
};


export type MutationCreateLearnMessageTemplateArgs = {
  text: Scalars['String'];
  useForMornings?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateLearnVideoVersionArgs = {
  fields: CreateLearnVideoVersion;
};


export type MutationCreateLiveCodingTaskArgs = {
  values: CreateLiveCodingTaskInput;
};


export type MutationCreateLoginCodeArgs = {
  userId: Scalars['Int'];
};


export type MutationCreateMarathonEventArgs = {
  values: CreateMarathonEventArgs;
};


export type MutationCreateMarketSalaryArgs = {
  options: CreateMarketSalaryOptions;
};


export type MutationCreateMessageReactionArgs = {
  messageId: Scalars['Int'];
  reactionShortcode: Scalars['String'];
};


export type MutationCreateModuleArgs = {
  moduleValues: CreateModuleValues;
  lang?: Maybe<Scalars['String']>;
  langs?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationCreateModuleFromExistingArgs = {
  professionSlug: Scalars['String'];
  moduleTemplate: TemplateCreationItem;
  courseTopicTemplates: Array<TemplateCreationItem>;
};


export type MutationCreateMonobankOrderArgs = {
  subscriptionPlanId: Scalars['Int'];
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  purchaseLinkId?: Maybe<Scalars['Int']>;
  partsCount?: Maybe<Scalars['Int']>;
};


export type MutationCreateMultipleScheduleEventsArgs = {
  values?: Maybe<CreateMultipleScheduleEventsArgs>;
};


export type MutationCreateOpenQuestionArgs = {
  fields: OpenQuestionFields;
};


export type MutationCreateOrUpdateMorningTaskArgs = {
  values: CreateOrUpdateMorningTaskInput;
};


export type MutationCreateOrUpdateUserLegalDocumentsArgs = {
  values: CreateOrUpdateUserLegalDocumentValues;
};


export type MutationCreatePartnershipRecommendedStudentFromBpmArgs = {
  values: CreatePartnershipRecommendedStudentFromBpmValues;
};


export type MutationCreatePartnershipVacancyFromBpmArgs = {
  values: CreatePartnershipVacancyFromBpmValues;
};


export type MutationCreatePartnershipVacancyStatusHistoryFromBpmArgs = {
  values: CreatePartnershipVacancyStatusHistoryFromBpmValues;
};


export type MutationCreatePayUOrderArgs = {
  subscriptionPlanId: Scalars['Int'];
  subscriptionId?: Maybe<Scalars['Int']>;
  purchaseLinkId?: Maybe<Scalars['Int']>;
};


export type MutationCreatePollArgs = {
  fields: PollFields;
};


export type MutationCreatePracticeLoginCodeArgs = {
  notificationEmail?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
};


export type MutationCreatePrimaryUserCardArgs = {
  token: Scalars['String'];
  cardProvider: Scalars['String'];
  cardTailNumbers: Scalars['String'];
  cardExpYear: Scalars['String'];
  cardExpMonth: Scalars['String'];
  paymentProviderSlug: PaymentProviders;
};


export type MutationCreatePrivatbankOrderArgs = {
  subscriptionPlanId: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  purchaseLinkId?: Maybe<Scalars['Int']>;
  partsCount?: Maybe<Scalars['Int']>;
};


export type MutationCreatePrivateEntrepreneurDetailsArgs = {
  values: CreatePrivateEntrepreneurDetailsValues;
};


export type MutationCreateProfessionArgs = {
  courseFields: CourseFields;
};


export type MutationCreateProfessionFromExistingArgs = {
  professionTemplate: TemplateCreationItem;
  languageId: Scalars['Int'];
};


export type MutationCreateQuestionKeywordArgs = {
  values?: Maybe<CreateTechCheckQuestionKeywordValues>;
};


export type MutationCreateQuizTaskImageArgs = {
  values: CreateQuizTaskImageInput;
};


export type MutationCreateRatingArgs = {
  userId: Scalars['Int'];
  relatedId: Scalars['Int'];
  relatedType?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  relatedChildId?: Maybe<Scalars['Int']>;
  relatedChildType?: Maybe<Scalars['String']>;
  relatedChildTitle?: Maybe<Scalars['String']>;
  relatedParentId?: Maybe<Scalars['Int']>;
  relatedParentType?: Maybe<RelatedParentType>;
  relatedRootId?: Maybe<Scalars['Int']>;
  relatedRootType?: Maybe<RelatedRootType>;
  feedbackType?: Maybe<RatingsFeedbackType>;
  workspace?: Maybe<RatingWorkspace>;
};


export type MutationCreateRecruiterProfileArgs = {
  userId: Scalars['Int'];
  position: Scalars['String'];
  companyId: Scalars['Int'];
};


export type MutationCreateScheduleEventArgs = {
  values?: Maybe<CreateScheduleEventArgs>;
};


export type MutationCreateScheduleEventSkeletonArgs = {
  fields?: Maybe<ScheduleEventSkeletonFields>;
};


export type MutationCreateScheduledMessageArgs = {
  fields: CreateScheduledMessageFields;
};


export type MutationCreateServiceTokenArgs = {
  userId: Scalars['Int'];
  tokenName: Scalars['String'];
};


export type MutationCreateServiceUserArgs = {
  name: Scalars['String'];
};


export type MutationCreateSessionTechCheckEventArgs = {
  values?: Maybe<CreateSessionTechCheckEventArgs>;
};


export type MutationCreateSkillArgs = {
  name: Scalars['String'];
};


export type MutationCreateSpecialOffersLinkArgs = {
  eventId: Scalars['Int'];
  subscriptionPlanIds: Array<Scalars['Int']>;
  deadlineDate: Scalars['Date'];
  title: Scalars['String'];
  timerTitle?: Maybe<Scalars['String']>;
  timerDescription?: Maybe<Scalars['String']>;
  placesLeftTitle?: Maybe<Scalars['String']>;
  placesLeftDescription?: Maybe<Scalars['String']>;
  supportLink?: Maybe<Scalars['String']>;
};


export type MutationCreateStudentsGroupArgs = {
  values?: Maybe<UpdateStudentsGroupValues>;
};


export type MutationCreateStudentsTaskArgs = {
  args: CreateStudentsTaskArgs;
};


export type MutationCreateSubscriptionPaymentArgs = {
  planId: Scalars['Int'];
  pricingOptionId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  purchaseLinkId?: Maybe<Scalars['Int']>;
  billingDetails?: Maybe<BillingDetails>;
  successUrl?: Maybe<Scalars['String']>;
  internalCheckout?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateTaskReviewWithoutAccessArgs = {
  testTaskId: Scalars['Int'];
};


export type MutationCreateTechCheckEventArgs = {
  values?: Maybe<CreateTechCheckEventArgs>;
};


export type MutationCreateTechCheckEventDeprecatedArgs = {
  values?: Maybe<CreateTechCheckEventDeprecatedArgs>;
};


export type MutationCreateTechCheckQuestionArgs = {
  values: CreateTechCheckQuestionArgs;
};


export type MutationCreateTechCheckQuestionCheckphraseArgs = {
  values: CreateTechCheckQuestionCheckphraseArgs;
};


export type MutationCreateTechCheckSessionArgs = {
  values: CreateTechCheckSessionArgs;
};


export type MutationCreateTechCheckSessionEventArgs = {
  values: CreateTechCheckSessionEventArgs;
};


export type MutationCreateTechCheckTemplateArgs = {
  values: CreateTechCheckTemplateArgs;
};


export type MutationCreateTechCheckTopicArgs = {
  values: CreateTechCheckTopicArgs;
};


export type MutationCreateTelegramUserArgs = {
  values?: Maybe<CreateTelegramUserValues>;
};


export type MutationCreateTestTaskArgs = {
  values: CreateTestTaskValuesInput;
};


export type MutationCreateTestTaskAttemptArgs = {
  testTaskLinkId: Scalars['Int'];
  testTaskId: Scalars['Int'];
};


export type MutationCreateTestTaskFilesArgs = {
  testTaskId: Scalars['Int'];
  filesToCreate: Array<TestTaskFileToUpload>;
};


export type MutationCreateTestTaskPreviewArgs = {
  values: CreateTestTaskPreviewValues;
};


export type MutationCreateTextSandboxFileArgs = {
  sandboxId: Scalars['Int'];
  path: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};


export type MutationCreateTopicArgs = {
  values: CreateCourseTopicValues;
};


export type MutationCreateTopicTheoryReviewRequestArgs = {
  courseTopicId: Scalars['Int'];
  topicTheoryFiles: Array<TopicTheoryFile>;
  requestedReviewersIds: Array<Scalars['Int']>;
};


export type MutationCreateTrainingJobArgs = {
  gptModelId: Scalars['Int'];
  baseModelName: Scalars['String'];
  status: Status;
  trainedOnType: TrainedOnType;
  trainedOnIds: Array<Scalars['Int']>;
  trainingFileId: Scalars['String'];
};


export type MutationCreateTypingSpeedTestArgs = {
  options: CreateTypingSpeedTestOptions;
};


export type MutationCreateUkrsibbankOrderArgs = {
  subscriptionPlanId: Scalars['Int'];
  phone: Scalars['String'];
  cardEnd: Scalars['String'];
  partsCount: Scalars['Int'];
  subscriptionId?: Maybe<Scalars['Int']>;
  purchaseLinkId?: Maybe<Scalars['Int']>;
};


export type MutationCreateUserAgreementArgs = {
  values: CreateAgreementValues;
};


export type MutationCreateUserDocumentArgs = {
  values: CreateUserDocumentValues;
};


export type MutationCreateUserEventArgs = {
  eventId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  utmTags?: Maybe<UtmTagsInput>;
};


export type MutationCreateUserExperimentArgs = {
  userId?: Maybe<Scalars['Int']>;
  optimizeExperimentId: Scalars['String'];
  experimentVariant: Scalars['String'];
};


export type MutationCreateUserJobArgs = {
  values: CreateUserJobValues;
  previousJob?: Maybe<PreviousUserJobValues>;
};


export type MutationCreateUserNotificationArgs = {
  fields: CreateUserNotificationFields;
};


export type MutationCreateUserOnboardingArgs = {
  page: Onboardings;
};


export type MutationCreateUserScheduleArgs = {
  userId: Scalars['Int'];
  daysOfWeek: UserScheduleDaysOfWeekInput;
};


export type MutationCreateUserTopicWithTaskLinkArgs = {
  userId: Scalars['Int'];
  courseTopicSlug: Scalars['String'];
  courseSlug: Scalars['String'];
  parentCourseSlug?: Maybe<Scalars['String']>;
};


export type MutationCreateUserVacancyArgs = {
  args: CreateUserVacancyArgs;
};


export type MutationCreateUserVacancyContactArgs = {
  args: CreateUserVacancyContactArgs;
};


export type MutationCreateUserVacancyHistoryArgs = {
  args?: Maybe<CreateUserVacancyHistoryArgs>;
};


export type MutationCreateVacancyArgs = {
  options: CreateVacancyOptions;
};


export type MutationCreateVacancyAutoApproveResultsArgs = {
  args: Array<CreateVacancyAutoApproveResultsArgs>;
};


export type MutationCreateWayForPayOrderArgs = {
  subscriptionPlanId: Scalars['Int'];
  subscriptionId?: Maybe<Scalars['Int']>;
  purchaseLinkId?: Maybe<Scalars['Int']>;
};


export type MutationCreateWebinarEventArgs = {
  values?: Maybe<CreateWebinarEventArgs>;
};


export type MutationCreateWorkPlaceArgs = {
  candidateProfileId: Scalars['Int'];
  values: CreateCandidateProfileWorkPlaceInput;
};


export type MutationDeactivateSoftDeadlineArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteAccountArgs = {
  password?: Maybe<Scalars['String']>;
  deletionReason: AccountDeletionReason;
  isDangerouslyConfirmedWithoutPassword?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteAssignmentTaskArgs = {
  id: Scalars['Int'];
  professionSlug: Scalars['String'];
};


export type MutationDeleteCalendarTemplateArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteChatConnectionArgs = {
  chatId: Scalars['Int'];
};


export type MutationDeleteChatGuidelineArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteChatMessageArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteChatMessageTemplateArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteChatSectionArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteChatsFromSectionsArgs = {
  chatIds: Array<Scalars['Int']>;
};


export type MutationDeleteCourseMonthPaymentSkipArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCourseTopicTaskArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCourseTopicTheoryFileArgs = {
  courseTopicTheoryFileId: Scalars['Int'];
};


export type MutationDeleteCourseTopicVideoArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDailyTaskArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDraftChatMessageArgs = {
  fields: DeleteDraftChatMessageFields;
};


export type MutationDeleteDraftTestTaskArgs = {
  taskId: Scalars['Int'];
};


export type MutationDeleteEnglishLessonEventSkeletonArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteGroupEventArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteGroupTeamMemberArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteLearnItemArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteLiveCodingTaskArgs = {
  id: Scalars['Int'];
  professionSlug: Scalars['String'];
};


export type MutationDeleteMessageReactionArgs = {
  reactionId: Scalars['Int'];
};


export type MutationDeleteMorningTaskArgs = {
  id: Scalars['Int'];
  professionSlug: Scalars['String'];
};


export type MutationDeleteMultipleCalendarEventsArgs = {
  eventId: Scalars['Int'];
  onlyNextEvents?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteQnAQuestionArgs = {
  questionId: Scalars['Int'];
};


export type MutationDeleteQuizTaskImageArgs = {
  uploadFileId: Scalars['Int'];
};


export type MutationDeleteReactionFromStackArgs = {
  messageId: Scalars['Int'];
  reactionShortcode: Scalars['String'];
};


export type MutationDeleteScheduleEventSkeletonArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteScheduledMessageArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteStudentsTaskArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTechCheckEventSkeletonArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTechCheckQuestionArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTechCheckQuestionCheckphraseArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTechCheckSessionArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTechCheckTemplateArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTechCheckTopicArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTestTaskAttemptFileArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTestTaskAttemptFilesArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteTestTaskFilesArgs = {
  testTaskId: Scalars['Int'];
  filesToDelete: Array<TestTaskFileToDelete>;
};


export type MutationDeleteTestTaskPreviewArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUserDocumentArgs = {
  documentType: DocumentTypes;
};


export type MutationDeleteUserScheduleArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteWorkPlaceArgs = {
  id: Scalars['Int'];
  candidateProfileId: Scalars['Int'];
};


export type MutationDescribeWorkingTimeArgs = {
  describeWorkingTimeInput: DescribeWorkingTimeInput;
};


export type MutationDisconnectOAuthProviderArgs = {
  provider: OAuthProviders;
};


export type MutationDistributeTechCheckSessionEventParticipantsArgs = {
  techCheckSessionEventId: Scalars['Int'];
};


export type MutationDuplicateScheduleEventArgs = {
  id: Scalars['Int'];
};


export type MutationDuplicateScheduleEventSkeletonArgs = {
  id: Scalars['Int'];
};


export type MutationEditModuleArgs = {
  moduleValues: CreateModuleValues;
  lang?: Maybe<Scalars['String']>;
  langs?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationEnsureCourseTopicLearnItemAttemptArgs = {
  courseTopicLearnItemId: Scalars['Int'];
};


export type MutationEnsureCourseUsersStatusHistoryArgs = {
  input: EnsureCourseUsersStatusHistoryInput;
};


export type MutationEnsureDirectChatArgs = {
  userIds: Array<Scalars['Int']>;
};


export type MutationEnsureEventLocationArgs = {
  eventId: Scalars['Int'];
};


export type MutationEnsurePeopleforceEmployeeArgs = {
  fields: PeopleforceEmployeeProfile;
};


export type MutationEnsurePeopleforceEmployeeSalaryArgs = {
  fields: PeopleforceEmployeeSalary;
};


export type MutationEnsureUsersStudentStatusHistoryArgs = {
  input: EnsureUsersStudentStatusHistoryInput;
};


export type MutationExpireReviewsArgs = {
  searchedReviewStatus: TaskReviewStatus;
};


export type MutationFetchWorkPlacesArgs = {
  linkedinUrl: Scalars['String'];
  candidateProfileId: Scalars['Int'];
  liveScrape?: Maybe<Scalars['Boolean']>;
};


export type MutationFillEmploymentProfileArgs = {
  fields: FillEmploymentProfileFields;
};


export type MutationFindOrCreateTechCheckLiveCodingLinkArgs = {
  eventId: Scalars['Int'];
};


export type MutationFindOrCreateUserDailyTasksLinkArgs = {
  parentCourseId: Scalars['Int'];
  courseId: Scalars['Int'];
};


export type MutationFinishAutoTechCheckArgs = {
  id: Scalars['Int'];
};


export type MutationFinishTestArgs = {
  id: Scalars['Int'];
  solution?: Maybe<Scalars['String']>;
  resultsUrl?: Maybe<Scalars['String']>;
};


export type MutationFinishTestTaskVersionArgs = {
  testTaskId: Scalars['Int'];
};


export type MutationFinishWorkArgs = {
  linkUUID: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
  generateMobileAppLink?: Maybe<Scalars['Boolean']>;
};


export type MutationGenerateCalendarEventsArgs = {
  studentsGroupId: Scalars['Int'];
  calendarTemplateId: Scalars['Int'];
};


export type MutationGenerateScheduleEventsArgs = {
  studentsGroupId: Scalars['Int'];
};


export type MutationGenerateTechCheckEventForSkeletonArgs = {
  skeletonId: Scalars['Int'];
  initiatingUserId?: Maybe<Scalars['Int']>;
};


export type MutationGenerateTechCheckEventsForSessionArgs = {
  techCheckSessionEventId: Scalars['Int'];
  singleEventCapacity: Scalars['Int'];
};


export type MutationGenerateTechCheckLinkArgs = {
  techCheckQuestionsIds: Array<Scalars['Int']>;
};


export type MutationGradeQuizArgs = {
  selectedOptions: Array<Maybe<SelectedOption>>;
  testTaskAttemptId: Scalars['Int'];
};


export type MutationGrantZeroXpForTaskArgs = {
  testTaskId: Scalars['Int'];
};


export type MutationHideIrrelevantVacancyArgs = {
  options?: Maybe<HideIrrelevantVacancyMutationOptions>;
};


export type MutationIsEventParticipantArgs = {
  eventId: Scalars['Int'];
  email: Scalars['String'];
};


export type MutationJoinChatArgs = {
  chatId: Scalars['Int'];
};


export type MutationJoinEventArgs = {
  eventId: Scalars['Int'];
};


export type MutationJoinScheduleEventArgs = {
  eventId: Scalars['Int'];
};


export type MutationJoinTeamProjectArgs = {
  courseSlug: Scalars['String'];
  courseTopicSlug: Scalars['String'];
};


export type MutationJoinTechCheckArgs = {
  techCheckLinkId: Scalars['Int'];
  role?: Maybe<TechCheckParticipantRole>;
};


export type MutationLeaveChatArgs = {
  chatId: Scalars['Int'];
};


export type MutationLintFilesArgs = {
  filesToLint: Array<FileToLint>;
  testTaskId?: Maybe<Scalars['Int']>;
  testTaskVersionId?: Maybe<Scalars['Int']>;
};


export type MutationLoanRefundArgs = {
  subscriptionId: Scalars['Int'];
  shouldReturnMoney: Scalars['Boolean'];
  returnedMoneyAmount?: Maybe<Scalars['Float']>;
};


export type MutationLogOutFromAllDevicesArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type MutationMergeCompaniesArgs = {
  args: MergeCompanyArgs;
};


export type MutationModerateAlfabankOrderArgs = {
  uuid: Scalars['String'];
  status: PaymentStatus;
};


export type MutationModerateTechCheckAnswerArgs = {
  id: Scalars['Int'];
};


export type MutationMoveCourseTopicVideoArgs = {
  params?: Maybe<MoveTopicVideoParams>;
};


export type MutationMoveSandboxFileArgs = {
  sandboxFileId: Scalars['Int'];
  path: Scalars['String'];
};


export type MutationMoveTestTaskAttemptFilesArgs = {
  attemptId: Scalars['Int'];
  filesToMove: Array<BaseTestTaskfile>;
};


export type MutationNotifyAboutFinishedStreakArgs = {
  amountHoursBeforeRemind?: Maybe<Scalars['Int']>;
};


export type MutationPeerReviewTaskArgs = {
  testTaskAttemptId: Scalars['Int'];
  resolution: ReviewResolution;
  comment: Scalars['String'];
  reviewerTestTaskAttemptId: Scalars['Int'];
};


export type MutationPinChatMessageArgs = {
  id: Scalars['Int'];
};


export type MutationPreSignInArgs = {
  email: Scalars['String'];
};


export type MutationPrepareAttemptFilesForDownloadArgs = {
  attemptId: Scalars['Int'];
  files: Array<Maybe<BaseTestTaskfile>>;
};


export type MutationPrepareSubscriptionCheckoutDataArgs = {
  pricingOptionUuid: Scalars['String'];
  analyticsData: PaymentAnalyticsData;
};


export type MutationProcessAnonymousSubscriptionFreeTrialArgs = {
  planId: Scalars['Int'];
  pricingOptionId?: Maybe<Scalars['Int']>;
  successUrl?: Maybe<Scalars['String']>;
  userOptions?: Maybe<UserOptions>;
};


export type MutationProcessApplicationRequestArgs = {
  id?: Maybe<Scalars['Int']>;
  courseId: Scalars['Int'];
  workCityId?: Maybe<Scalars['Int']>;
  englishLevel?: Maybe<CourseUserEnglishLevel>;
  techLevel?: Maybe<Scalars['String']>;
  commitmentPerWeek?: Maybe<Scalars['String']>;
  leadScore?: Maybe<Scalars['Int']>;
  promoCode?: Maybe<Scalars['String']>;
  bpmLeadId?: Maybe<Scalars['String']>;
  surveyStatus?: Maybe<CourseUserSurveyStatus>;
  distractions?: Maybe<Scalars['String']>;
  moneySource?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  fvType?: Maybe<Scalars['String']>;
  fvSource?: Maybe<Scalars['String']>;
  fvMedium?: Maybe<Scalars['String']>;
  fvCampaign?: Maybe<Scalars['String']>;
  fvContent?: Maybe<Scalars['String']>;
  fvTerm?: Maybe<Scalars['String']>;
  fvPage?: Maybe<Scalars['String']>;
  lvType?: Maybe<Scalars['String']>;
  lvSource?: Maybe<Scalars['String']>;
  lvMedium?: Maybe<Scalars['String']>;
  lvCampaign?: Maybe<Scalars['String']>;
  lvContent?: Maybe<Scalars['String']>;
  lvTerm?: Maybe<Scalars['String']>;
  lvAccountId?: Maybe<Scalars['String']>;
  lvCampaignId?: Maybe<Scalars['String']>;
  lvAdGroupId?: Maybe<Scalars['String']>;
  lvAdId?: Maybe<Scalars['String']>;
  lvTermId?: Maybe<Scalars['String']>;
  utmDevicetype?: Maybe<Scalars['String']>;
  utmPlacement?: Maybe<Scalars['String']>;
};


export type MutationProcessConsultationRequestArgs = {
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone: Scalars['String'];
  courseId: Scalars['Int'];
  birthDate?: Maybe<Scalars['Date']>;
  fvType?: Maybe<Scalars['String']>;
  fvSource?: Maybe<Scalars['String']>;
  fvMedium?: Maybe<Scalars['String']>;
  fvCampaign?: Maybe<Scalars['String']>;
  fvContent?: Maybe<Scalars['String']>;
  fvTerm?: Maybe<Scalars['String']>;
  fvPage?: Maybe<Scalars['String']>;
  lvType?: Maybe<Scalars['String']>;
  lvSource?: Maybe<Scalars['String']>;
  lvMedium?: Maybe<Scalars['String']>;
  lvCampaign?: Maybe<Scalars['String']>;
  lvContent?: Maybe<Scalars['String']>;
  lvTerm?: Maybe<Scalars['String']>;
  lvAccountId?: Maybe<Scalars['String']>;
  lvCampaignId?: Maybe<Scalars['String']>;
  lvAdGroupId?: Maybe<Scalars['String']>;
  lvAdId?: Maybe<Scalars['String']>;
  lvTermId?: Maybe<Scalars['String']>;
  cvType?: Maybe<Scalars['String']>;
  cvSource?: Maybe<Scalars['String']>;
  cvMedium?: Maybe<Scalars['String']>;
  cvCampaign?: Maybe<Scalars['String']>;
  cvContent?: Maybe<Scalars['String']>;
  cvTerm?: Maybe<Scalars['String']>;
  gclid?: Maybe<Scalars['String']>;
  gClientid?: Maybe<Scalars['String']>;
  gIp?: Maybe<Scalars['String']>;
  gAgent?: Maybe<Scalars['String']>;
  utmDevicetype?: Maybe<Scalars['String']>;
  utmPlacement?: Maybe<Scalars['String']>;
  fbp?: Maybe<Scalars['String']>;
  fbc?: Maybe<Scalars['String']>;
  fbclid?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  newslettersSubscription?: Maybe<Scalars['Boolean']>;
  studentSource?: Maybe<Scalars['String']>;
  promoCodeSlug?: Maybe<Scalars['String']>;
};


export type MutationProcessCourseMonthPaymentArgs = {
  salary: Scalars['Float'];
  salaryCurrencyId: Scalars['Int'];
  amount: Scalars['Float'];
  amountCurrencyId?: Maybe<Scalars['Int']>;
  salaryDate: Scalars['Date'];
  currenciesRateId?: Maybe<Scalars['Int']>;
  bank?: Maybe<Scalars['String']>;
};


export type MutationProcessExistingSubscriptionArgs = {
  subscriptionId: Scalars['Int'];
};


export type MutationProcessExternalPaymentArgs = {
  paymentId: Scalars['Int'];
  receipt: Receipt;
  paymentDate?: Maybe<Scalars['Date']>;
  providerId?: Maybe<Scalars['Int']>;
};


export type MutationProcessFutureTechCheckSessionEventArgs = {
  techCheckSessionEventId: Scalars['Int'];
  initiatingUserId: Scalars['Int'];
};


export type MutationProcessKnowelySubscriptionFreeTrialArgs = {
  planId: Scalars['Int'];
  pricingOptionId?: Maybe<Scalars['Int']>;
  successUrl?: Maybe<Scalars['String']>;
  userOptions?: Maybe<UserOptions>;
};


export type MutationProcessKnowelySubscriptionPaymentArgs = {
  planId: Scalars['Int'];
  pricingOptionId?: Maybe<Scalars['Int']>;
  successUrl?: Maybe<Scalars['String']>;
  billingDetails?: Maybe<BillingDetails>;
  isInternalCheckout?: Maybe<Scalars['Boolean']>;
  userOptions?: Maybe<UserOptions>;
};


export type MutationProcessLearnItemOpenedActionArgs = {
  courseUserTopicId: Scalars['Int'];
  learnItemId: Scalars['Int'];
  professionSlug?: Maybe<Scalars['String']>;
};


export type MutationProcessOpenTrialAccessArgs = {
  domain: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['Int']>;
  courseSlug?: Maybe<Scalars['String']>;
};


export type MutationProcessPwlTransactionChargeArgs = {
  uuid: Scalars['String'];
  cardToken?: Maybe<PwlCardToken>;
  cardId?: Maybe<Scalars['Int']>;
  storeCard?: Maybe<Scalars['Boolean']>;
  failRedirectUrl?: Maybe<Scalars['String']>;
  successRedirectUrl?: Maybe<Scalars['String']>;
};


export type MutationProcessPaymentTransactionCheckoutArgs = {
  uuid: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationProcessProofOfRevenueArgs = {
  documents: Array<ProofOfRevenueDocument>;
  employmentTypeName: EmploymentTypeName;
  comment?: Maybe<Scalars['String']>;
};


export type MutationProcessRefundArgs = {
  paymentId: Scalars['Int'];
  refundDate?: Maybe<Scalars['Date']>;
  partialAmount?: Maybe<Scalars['Float']>;
};


export type MutationProcessRewardableActionArgs = {
  action: RewardableAction;
  userId: Scalars['Int'];
  value?: Maybe<Scalars['Float']>;
  fields?: Maybe<ProcessRewardableActionFieldsInput>;
};


export type MutationProcessSubscriptionCancellationBonusArgs = {
  subscriptionId: Scalars['Int'];
};


export type MutationProcessSubscriptionChangesArgs = {
  changeType: SubscriptionChangeType;
  subscriptionId: Scalars['Int'];
  userChanges?: Maybe<UserChanges>;
  subscriptionPlanChanges?: Maybe<SubscriptionPlanChanges>;
  deactivationChanges?: Maybe<DeactivationChanges>;
  comment?: Maybe<Scalars['String']>;
};


export type MutationProcessSubscriptionFreeTrialArgs = {
  planId: Scalars['Int'];
  pricingOptionId?: Maybe<Scalars['Int']>;
  successUrl?: Maybe<Scalars['String']>;
  internalCheckout?: Maybe<Scalars['Boolean']>;
};


export type MutationProcessSubscriptionFreeTrialResultArgs = {
  subscriptionId: Scalars['Int'];
};


export type MutationProcessUpdateEnglishLessonEventSkeletonArgs = {
  id: Scalars['Int'];
  eventSkeleton?: Maybe<EventSkeletonFields>;
  englishEventSkeleton?: Maybe<EnglishEventSkeletonFields>;
  updateEvents?: Maybe<Scalars['Boolean']>;
  eventDetails?: Maybe<EventDetailsFields>;
};


export type MutationProcessVacancyArgs = {
  providerId: Scalars['String'];
  company: Scalars['String'];
  title: Scalars['String'];
  link: Scalars['String'];
  publishDate: Scalars['String'];
  location: Array<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  course: Scalars['String'];
  isInternational: Scalars['Boolean'];
  country: Scalars['String'];
};


export type MutationProcessVacancyCityArgs = {
  cityName: Scalars['String'];
  vacancyId: Scalars['Int'];
  courseSlug?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};


export type MutationProcessVacancyCountryArgs = {
  countryName: Scalars['String'];
  vacancyId: Scalars['Int'];
};


export type MutationProcessWebinarRegistrationArgs = {
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone: Scalars['String'];
  eventId: Scalars['Int'];
  fvType?: Maybe<Scalars['String']>;
  fvSource?: Maybe<Scalars['String']>;
  fvMedium?: Maybe<Scalars['String']>;
  fvCampaign?: Maybe<Scalars['String']>;
  fvContent?: Maybe<Scalars['String']>;
  fvTerm?: Maybe<Scalars['String']>;
  fvPage?: Maybe<Scalars['String']>;
  lvType?: Maybe<Scalars['String']>;
  lvSource?: Maybe<Scalars['String']>;
  lvMedium?: Maybe<Scalars['String']>;
  lvCampaign?: Maybe<Scalars['String']>;
  lvContent?: Maybe<Scalars['String']>;
  lvTerm?: Maybe<Scalars['String']>;
  lvAccountId?: Maybe<Scalars['String']>;
  lvCampaignId?: Maybe<Scalars['String']>;
  lvAdGroupId?: Maybe<Scalars['String']>;
  lvAdId?: Maybe<Scalars['String']>;
  lvTermId?: Maybe<Scalars['String']>;
  cvType?: Maybe<Scalars['String']>;
  cvSource?: Maybe<Scalars['String']>;
  cvMedium?: Maybe<Scalars['String']>;
  cvCampaign?: Maybe<Scalars['String']>;
  cvContent?: Maybe<Scalars['String']>;
  cvTerm?: Maybe<Scalars['String']>;
  gclid?: Maybe<Scalars['String']>;
  gClientid?: Maybe<Scalars['String']>;
  gIp?: Maybe<Scalars['String']>;
  gAgent?: Maybe<Scalars['String']>;
  utmDevicetype?: Maybe<Scalars['String']>;
  utmPlacement?: Maybe<Scalars['String']>;
  fbp?: Maybe<Scalars['String']>;
  fbc?: Maybe<Scalars['String']>;
  fbclid?: Maybe<Scalars['String']>;
  rdtcid?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};


export type MutationRateOpenQuestionAnswersArgs = {
  ids: Array<Scalars['Int']>;
  isCorrect: Scalars['Boolean'];
};


export type MutationReParseVacanciesArgs = {
  vacanciesTrackSlug?: Maybe<Scalars['String']>;
};


export type MutationReadChatMessageArgs = {
  id: Scalars['Int'];
};


export type MutationReadMessageArgs = {
  id: Scalars['Int'];
};


export type MutationReadThreadReplyArgs = {
  id: Scalars['Int'];
};


export type MutationReconnectOAuthProviderArgs = {
  provider: OAuthProviders;
  token: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRecordIdleTimestampArgs = {
  courseUserTopicId: Scalars['Int'];
  testTaskAttemptId: Scalars['Int'];
};


export type MutationRecordPastedContentArgs = {
  courseUserTopicId: Scalars['Int'];
  testTaskAttemptId: Scalars['Int'];
  content: Scalars['String'];
};


export type MutationRecordSelectTextAttemptArgs = {
  courseUserTopicId: Scalars['Int'];
  testTaskAttemptId: Scalars['Int'];
};


export type MutationRecordTabBlurTimestampArgs = {
  courseUserTopicId: Scalars['Int'];
  testTaskAttemptId: Scalars['Int'];
};


export type MutationReduceWorkingTimeArgs = {
  id: Scalars['Int'];
  newDuration: Scalars['Int'];
};


export type MutationRegenerateCertificatesArgs = {
  userId: Scalars['Int'];
};


export type MutationRejectApplicationArgs = {
  providerId: Scalars['Int'];
  orderId: Scalars['String'];
};


export type MutationRemindStreakDeactivationArgs = {
  amountHoursBeforeRemind?: Maybe<Scalars['Int']>;
};


export type MutationRemindStreakLoseFrozenDayArgs = {
  amountHoursBeforeRemind?: Maybe<Scalars['Int']>;
};


export type MutationRemindTrialUpgradeArgs = {
  userId: Scalars['Int'];
  subscriptionId: Scalars['Int'];
};


export type MutationRemoveCardArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveCourseModuleArgs = {
  professionSlug: Scalars['String'];
  childCourseId: Scalars['Int'];
};


export type MutationRemoveLearnMessageTemplateArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveMessageMediaArgs = {
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  uploadFileId: Scalars['Int'];
};


export type MutationRemoveParticipantFromChatArgs = {
  participantId: Scalars['Int'];
};


export type MutationRemoveSandboxFileArgs = {
  sandboxFileId: Scalars['Int'];
};


export type MutationRemoveUserFromChatsArgs = {
  userId: Scalars['Int'];
};


export type MutationRemoveUserVacancyContactArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveUserVacancyHistoryArgs = {
  id: Scalars['Int'];
};


export type MutationRenameTestTaskFilesArgs = {
  testTaskId: Scalars['Int'];
  filesToRename: Array<TestTaskFileToRename>;
};


export type MutationReorderChatSectionArgs = {
  id: Scalars['Int'];
  destination: SectionReorderDestination;
};


export type MutationReorderTechCheckQuestionsArgs = {
  records: Array<ReorderTechCheckQuestionsRecord>;
};


export type MutationReorderTechCheckTopicsArgs = {
  records: Array<ReorderTechCheckTopicRecord>;
};


export type MutationReplaceTaskDescriptionsArgs = {
  chunkSize: Scalars['Int'];
};


export type MutationReportOfferStatusArgs = {
  id: Scalars['Int'];
  status: OfferStatus;
};


export type MutationRequestAccountDeletionArgs = {
  forMobile?: Maybe<Scalars['Boolean']>;
};


export type MutationRequestEmailChangeArgs = {
  newEmail: Scalars['String'];
};


export type MutationRequestMentorReviewArgs = {
  testTaskAttemptId: Scalars['Int'];
};


export type MutationRequestSpeakingTestChangesArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type MutationResetCompanyRelationArgs = {
  id: Scalars['Int'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResetTopicProgressArgs = {
  courseTopicSlug: Scalars['String'];
  courseUserId: Scalars['Int'];
};


export type MutationResetTopicsProgressArgs = {
  courseUserTopicIds: Array<Scalars['Int']>;
};


export type MutationReturnProductArgs = {
  providerId: Scalars['Int'];
  orderId: Scalars['String'];
};


export type MutationRevalidateVimeoVideoDurationArgs = {
  learnVideoId: Scalars['Int'];
};


export type MutationRevertLmsContentChangeByIdArgs = {
  id: Scalars['Int'];
};


export type MutationReviewChatConnectionRequestArgs = {
  id: Scalars['Int'];
  status: ChatConnectionStatus;
};


export type MutationReviewTaskArgs = {
  testTaskAttemptId: Scalars['Int'];
  status: TaskReviewStatus;
  comment?: Maybe<Scalars['String']>;
};


export type MutationReviewTestTaskAttemptWithAiArgs = {
  testTaskAttemptId: Scalars['Int'];
  reviewContext: AiReviewContextInput;
};


export type MutationRevokeServiceTokenArgs = {
  userId: Scalars['Int'];
  tokenId: Scalars['Int'];
};


export type MutationRunSandboxArgs = {
  sandboxId: Scalars['Int'];
  files: Array<RunCodeFile>;
};


export type MutationSaveChatMessageArgs = {
  messageId: Scalars['Int'];
};


export type MutationSaveCodeEditorTaskArgs = {
  slug: Scalars['String'];
  linkUUID: Scalars['String'];
  files: Array<BaseTestTaskfile>;
  testTaskAttemptId?: Maybe<Scalars['Int']>;
};


export type MutationSaveDraftChatMessageArgs = {
  fields: SaveDraftChatMessageFields;
};


export type MutationSaveInvidVersionArgs = {
  id: Scalars['Int'];
  audio: Scalars['Upload'];
  duration: Scalars['Float'];
  events: Array<InvidEventInput>;
};


export type MutationSeedUsersPermissionsInGptArgs = {
  chunkSize: Scalars['Int'];
};


export type MutationSendAttachmentTaskLinkArgs = {
  testTaskAttemptId: Scalars['Int'];
  source: Scalars['String'];
};


export type MutationSendChatConnectionRequestArgs = {
  candidateUserId: Scalars['Int'];
  recruiterUserId: Scalars['Int'];
  message: Scalars['String'];
};


export type MutationSendDesktopLinkArgs = {
  location: Scalars['String'];
};


export type MutationSendEmploymentDeadlineNotificationArgs = {
  users: Array<EmploymentDeadlineNotificationData>;
};


export type MutationSendEmploymentProfileAvailableNotificationArgs = {
  userId: Scalars['Int'];
  status: StudentStatus;
};


export type MutationSendGuidelineMessageToChatArgs = {
  fields: SendGuidelineMessageToChatFields;
};


export type MutationSendPossibleIdleTimeNotificationsArgs = {
  options: Array<SendPossibleIdleTimeNotificationInput>;
};


export type MutationSendRatingTaskReviewNotificationArgs = {
  id: Scalars['Int'];
  testTaskAttemptId: Scalars['Int'];
  testTaskId: Scalars['Int'];
  status: TaskReviewStatus;
  userId: Scalars['Int'];
  reviewerId?: Maybe<Scalars['Int']>;
};


export type MutationSendReviewTaskNotificationArgs = {
  testTaskId: Scalars['Int'];
  status: TaskReviewStatus;
  userId: Scalars['Int'];
  reviewerId: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationSendSignInCodeLinkArgs = {
  email: Scalars['String'];
};


export type MutationSendUserScheduleNotificationArgs = {
  userScheduleId: Scalars['Int'];
};


export type MutationSendWeeklyNotificationsToActiveStudentsArgs = {
  usersIds: Array<Scalars['Int']>;
};


export type MutationSendWeeklyNotificationsToInactiveStudentsArgs = {
  usersIds: Array<Scalars['Int']>;
};


export type MutationSendWeeklyReportArgs = {
  progressRating: Scalars['Int'];
  motivationRating: Scalars['Int'];
  weeklyReport: Scalars['String'];
  motivationReport?: Maybe<Scalars['String']>;
};


export type MutationSetChatConnectionStageArgs = {
  id: Scalars['Int'];
  stage: ChatConnectionStages;
};


export type MutationSetEmailAndPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSetEntryArgs = {
  sandboxId: Scalars['Int'];
  sandboxFileId: Scalars['Int'];
};


export type MutationSetGroupsActiveCoachArgs = {
  id: Scalars['Int'];
  coachId?: Maybe<Scalars['Int']>;
};


export type MutationSetGroupsCoachesArgs = {
  id: Scalars['Int'];
  coachesIds: Array<Scalars['Int']>;
};


export type MutationSetInitialInvidStateArgs = {
  invidId: Scalars['Int'];
  initialState: InvidInitialStateValues;
};


export type MutationSetPasswordArgs = {
  newPassword: Scalars['String'];
};


export type MutationSetPrimaryCardArgs = {
  id: Scalars['Int'];
};


export type MutationSetSandboxCtaShownArgs = {
  linkOpened?: Maybe<Scalars['Boolean']>;
};


export type MutationSetUpgradeTestNextTryDateArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type MutationShareCertificateArgs = {
  certificateId: Scalars['Int'];
};


export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSignInAsUserArgs = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};


export type MutationSignInWithCodeArgs = {
  loginCode: Scalars['String'];
};


export type MutationSignUpArgs = {
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  fvType?: Maybe<Scalars['String']>;
  fvSource?: Maybe<Scalars['String']>;
  fvMedium?: Maybe<Scalars['String']>;
  fvCampaign?: Maybe<Scalars['String']>;
  fvContent?: Maybe<Scalars['String']>;
  fvTerm?: Maybe<Scalars['String']>;
  fvPage?: Maybe<Scalars['String']>;
  lvType?: Maybe<Scalars['String']>;
  lvSource?: Maybe<Scalars['String']>;
  lvMedium?: Maybe<Scalars['String']>;
  lvCampaign?: Maybe<Scalars['String']>;
  lvContent?: Maybe<Scalars['String']>;
  lvTerm?: Maybe<Scalars['String']>;
  lvAccountId?: Maybe<Scalars['String']>;
  lvCampaignId?: Maybe<Scalars['String']>;
  lvAdGroupId?: Maybe<Scalars['String']>;
  lvAdId?: Maybe<Scalars['String']>;
  lvTermId?: Maybe<Scalars['String']>;
  cvType?: Maybe<Scalars['String']>;
  cvSource?: Maybe<Scalars['String']>;
  cvMedium?: Maybe<Scalars['String']>;
  cvCampaign?: Maybe<Scalars['String']>;
  cvContent?: Maybe<Scalars['String']>;
  cvTerm?: Maybe<Scalars['String']>;
  gClientid?: Maybe<Scalars['String']>;
  gIp?: Maybe<Scalars['String']>;
  gAgent?: Maybe<Scalars['String']>;
  gclid?: Maybe<Scalars['String']>;
  utmDevicetype?: Maybe<Scalars['String']>;
  utmPlacement?: Maybe<Scalars['String']>;
  fbp?: Maybe<Scalars['String']>;
  fbc?: Maybe<Scalars['String']>;
  fbclid?: Maybe<Scalars['String']>;
  rdtcid?: Maybe<Scalars['String']>;
  newslettersSubscription?: Maybe<Scalars['Boolean']>;
  domain?: Maybe<Scalars['String']>;
  userRole?: Maybe<UserRole>;
  source?: Maybe<Scalars['String']>;
  studentStatus?: Maybe<StudentStatus>;
  withTemporaryCredentials?: Maybe<Scalars['Boolean']>;
};


export type MutationSocialSignUpArgs = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  fvType?: Maybe<Scalars['String']>;
  fvSource?: Maybe<Scalars['String']>;
  fvMedium?: Maybe<Scalars['String']>;
  fvCampaign?: Maybe<Scalars['String']>;
  fvContent?: Maybe<Scalars['String']>;
  fvTerm?: Maybe<Scalars['String']>;
  fvPage?: Maybe<Scalars['String']>;
  lvType?: Maybe<Scalars['String']>;
  lvSource?: Maybe<Scalars['String']>;
  lvMedium?: Maybe<Scalars['String']>;
  lvCampaign?: Maybe<Scalars['String']>;
  lvContent?: Maybe<Scalars['String']>;
  lvTerm?: Maybe<Scalars['String']>;
  lvAccountId?: Maybe<Scalars['String']>;
  lvCampaignId?: Maybe<Scalars['String']>;
  lvAdGroupId?: Maybe<Scalars['String']>;
  lvAdId?: Maybe<Scalars['String']>;
  lvTermId?: Maybe<Scalars['String']>;
  cvType?: Maybe<Scalars['String']>;
  cvSource?: Maybe<Scalars['String']>;
  cvMedium?: Maybe<Scalars['String']>;
  cvCampaign?: Maybe<Scalars['String']>;
  cvContent?: Maybe<Scalars['String']>;
  cvTerm?: Maybe<Scalars['String']>;
  gClientid?: Maybe<Scalars['String']>;
  gIp?: Maybe<Scalars['String']>;
  gAgent?: Maybe<Scalars['String']>;
  gclid?: Maybe<Scalars['String']>;
  utmDevicetype?: Maybe<Scalars['String']>;
  utmPlacement?: Maybe<Scalars['String']>;
  fbp?: Maybe<Scalars['String']>;
  fbc?: Maybe<Scalars['String']>;
  fbclid?: Maybe<Scalars['String']>;
  rdtcid?: Maybe<Scalars['String']>;
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  token?: Maybe<Scalars['String']>;
  newslettersSubscription?: Maybe<Scalars['Boolean']>;
  domain?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  userRole?: Maybe<UserRole>;
};


export type MutationSoftDeleteTopicArgs = {
  id: Scalars['Int'];
};


export type MutationSolveCourseTopicLearnItemArgs = {
  courseTopicLearnItemId: Scalars['Int'];
};


export type MutationStartAnsweringAutoTechCheckQuestionArgs = {
  techCheckLinkId: Scalars['Int'];
  techCheckQuestionId: Scalars['Int'];
};


export type MutationStartExamArgs = {
  courseUserTopicId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationStartLearningArgs = {
  id: Scalars['Int'];
};


export type MutationStartSubscriptionArgs = {
  id: Scalars['Int'];
};


export type MutationStartTaskArgs = {
  testTaskId: Scalars['Int'];
  linkUUID: Scalars['String'];
};


export type MutationStartTaskAiReviewArgs = {
  pendingReviewId: Scalars['Int'];
};


export type MutationStartTaskPeerReviewArgs = {
  reviewerTestTaskAttemptId: Scalars['Int'];
};


export type MutationStartTaskReviewArgs = {
  studentsGroupNames: Array<Scalars['String']>;
  reviewType?: Maybe<TaskReviewType>;
};


export type MutationStartTaskReviewByIdArgs = {
  taskReviewId: Scalars['Int'];
};


export type MutationStartTestArgs = {
  id: Scalars['Int'];
  isLightFunnel?: Maybe<Scalars['Boolean']>;
};


export type MutationStartTestPreviewArgs = {
  courseUserId: Scalars['Int'];
};


export type MutationStartWorkArgs = {
  linkUUID: Scalars['String'];
};


export type MutationStopAnsweringAutoTechCheckQuestionArgs = {
  values: UserAutoTechCheckAnswerValues;
};


export type MutationStopAnsweringTechCheckQuestionArgs = {
  answerId: Scalars['Int'];
};


export type MutationStudentsGroupInfoArgs = {
  name: Scalars['String'];
  courseIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type MutationSubmitAppOnboardingArgs = {
  values: UserAppOnboardingValues;
};


export type MutationSubmitSurveyArgs = {
  id?: Maybe<Scalars['Int']>;
  courseId: Scalars['Int'];
  workCityId?: Maybe<Scalars['Int']>;
  englishLevel?: Maybe<CourseUserEnglishLevel>;
  techLevel?: Maybe<Scalars['String']>;
  commitmentPerWeek?: Maybe<Scalars['String']>;
  leadScore?: Maybe<Scalars['Int']>;
  promoCode?: Maybe<Scalars['String']>;
  bpmLeadId?: Maybe<Scalars['String']>;
  surveyStatus?: Maybe<CourseUserSurveyStatus>;
  distractions?: Maybe<Scalars['String']>;
  moneySource?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  fvType?: Maybe<Scalars['String']>;
  fvSource?: Maybe<Scalars['String']>;
  fvMedium?: Maybe<Scalars['String']>;
  fvCampaign?: Maybe<Scalars['String']>;
  fvContent?: Maybe<Scalars['String']>;
  fvTerm?: Maybe<Scalars['String']>;
  fvPage?: Maybe<Scalars['String']>;
  lvType?: Maybe<Scalars['String']>;
  lvSource?: Maybe<Scalars['String']>;
  lvMedium?: Maybe<Scalars['String']>;
  lvCampaign?: Maybe<Scalars['String']>;
  lvContent?: Maybe<Scalars['String']>;
  lvTerm?: Maybe<Scalars['String']>;
  lvAccountId?: Maybe<Scalars['String']>;
  lvCampaignId?: Maybe<Scalars['String']>;
  lvAdGroupId?: Maybe<Scalars['String']>;
  lvAdId?: Maybe<Scalars['String']>;
  lvTermId?: Maybe<Scalars['String']>;
  utmDevicetype?: Maybe<Scalars['String']>;
  utmPlacement?: Maybe<Scalars['String']>;
};


export type MutationSubmitWorkingTimeActivityCommentArgs = {
  id: Scalars['Int'];
  comment: Scalars['String'];
};


export type MutationSubscribeNotificationsDeviceArgs = {
  name: Scalars['String'];
  os: Scalars['String'];
  pushToken: Scalars['String'];
};


export type MutationSwitchGroupsCoachesArgs = {
  groupsActiveByDate?: Maybe<Scalars['Date']>;
};


export type MutationSyncAiAssistantActionButtonRatingArgs = {
  testTaskId: Scalars['Int'];
  actionButtonCode: Scalars['String'];
  rating: Scalars['Int'];
};


export type MutationSyncProofOfRevenueFeedbackArgs = {
  rating: Scalars['Int'];
  issues: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};


export type MutationSyncSpreadSheetGroupInfoArgs = {
  id: Scalars['Int'];
  values: SyncSpreadSheetGroupInfoValues;
};


export type MutationSyncTaskAttemptWithGithubArgs = {
  testTaskAttemptId: Scalars['Int'];
  testTaskVersionId: Scalars['Int'];
};


export type MutationTaskReadyForReviewArgs = {
  testTaskAttemptId: Scalars['Int'];
};


export type MutationTestSpeechRecognitionArgs = {
  file: Scalars['Upload'];
  language: Scalars['String'];
};


export type MutationToggleChatParticipantBanArgs = {
  participantId: Scalars['Int'];
};


export type MutationToggleEventsSidebarArgs = {
  open: Scalars['Boolean'];
};


export type MutationToggleSidebarArgs = {
  value: Scalars['Boolean'];
};


export type MutationToggleUserSavedCourseArgs = {
  courseId: Scalars['Int'];
};


export type MutationToggleUserVacancySidebarArgs = {
  open: Scalars['Boolean'];
};


export type MutationToggleVacancyEditorArgs = {
  open: Scalars['Boolean'];
  vacancyId: Scalars['Int'];
};


export type MutationTranscribeTechCheckAnswerArgs = {
  id: Scalars['Int'];
};


export type MutationTryCourseArgs = {
  courseId: Scalars['Int'];
  isPreparation?: Maybe<Scalars['Boolean']>;
};


export type MutationUnUseLearnMessageTemplateForMorningsArgs = {
  learnMessageTemplateId: Scalars['Int'];
};


export type MutationUnarchiveChatArgs = {
  chatId: Scalars['Int'];
};


export type MutationUnpinChatMessageArgs = {
  id: Scalars['Int'];
};


export type MutationUnsaveChatMessageArgs = {
  messageId: Scalars['Int'];
};


export type MutationUnsubscribeArgs = {
  subscriptionId: Scalars['Int'];
  reason?: Maybe<SubscriptionDeactivationRequestReason>;
  reasonDescription?: Maybe<Scalars['String']>;
};


export type MutationUnsubscribeNotificationsDeviceArgs = {
  os: Scalars['String'];
  pushToken: Scalars['String'];
};


export type MutationUpdateAvatarArgs = {
  file: Scalars['Upload'];
  size?: Maybe<Scalars['Int']>;
};


export type MutationUpdateCandidateProfileArgs = {
  options: UpdateCandidateProfileOptions;
};


export type MutationUpdateChatArgs = {
  fields: UpdateChatFields;
};


export type MutationUpdateChatConfigArgs = {
  id: Scalars['Int'];
  config: ChatConfigInput;
};


export type MutationUpdateChatGuidelineArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationUpdateChatGuidelineOrderArgs = {
  fields: UpdateGuidelineOrderFields;
};


export type MutationUpdateChatMessageArgs = {
  fields: UpdateMessageFields;
};


export type MutationUpdateChatMessageTemplateArgs = {
  fields: UpdateMessageTemplateFields;
};


export type MutationUpdateChatSectionArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationUpdateCityArgs = {
  id: Scalars['Int'];
  values: UpdateCityValues;
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['Int'];
  values: UpdateCompanyValues;
};


export type MutationUpdateCountryArgs = {
  id: Scalars['Int'];
  values?: Maybe<UpdateCountryValues>;
};


export type MutationUpdateCourseEventSkeletonArgs = {
  id: Scalars['Int'];
  fields?: Maybe<UpdateCourseEventSkeletonFields>;
};


export type MutationUpdateCourseMonthPaymentArgs = {
  id: Scalars['Int'];
  values: UpdateCourseMonthPaymentValues;
};


export type MutationUpdateCourseTopicConfigArgs = {
  courseTopicId: Scalars['Int'];
  config: TestTaskConfigInput;
};


export type MutationUpdateCourseTopicTaskArgs = {
  id: Scalars['Int'];
  values: UpdateCourseTopicTaskInput;
};


export type MutationUpdateCourseTopicTheoryFileArgs = {
  id: Scalars['Int'];
  nameTranslatePack?: Maybe<TranslatesPackInput>;
};


export type MutationUpdateCourseUserArgs = {
  id: Scalars['Int'];
  values: CourseUserValues;
};


export type MutationUpdateCourseUserApplicationArgs = {
  id: Scalars['Int'];
  values: CourseUserApplicationValues;
};


export type MutationUpdateCourseUserTopicArgs = {
  id: Scalars['Int'];
  fields: CourseUserTopicFields;
};


export type MutationUpdateCourseUserTopicStatusArgs = {
  courseUserTopicId: Scalars['Int'];
  status?: Maybe<TopicStatus>;
};


export type MutationUpdateCourseUserTopicsStatusesArgs = {
  userIds: Array<Scalars['Int']>;
  courseTopicSlug: Scalars['String'];
  status?: Maybe<TopicStatus>;
};


export type MutationUpdateCourseUserTopicsStatusesByTopicSlugArgs = {
  courseTopicSlug: Scalars['String'];
  topicStatus: TopicStatus;
};


export type MutationUpdateDailyTaskArgs = {
  id: Scalars['Int'];
  values: UpdateDailyTaskInput;
};


export type MutationUpdateEconomicCodesArgs = {
  codes: Array<Maybe<Scalars['String']>>;
  userId: Scalars['Int'];
};


export type MutationUpdateEnglishEventArgs = {
  id: Scalars['Int'];
  event?: Maybe<UpdateEventOptions>;
  eventDetails?: Maybe<UpdateEventDetailsOptions>;
};


export type MutationUpdateEnglishLessonEventSkeletonArgs = {
  id: Scalars['Int'];
  eventSkeleton?: Maybe<EventSkeletonFields>;
  englishEventSkeleton?: Maybe<EnglishEventSkeletonFields>;
};


export type MutationUpdateEventArgs = {
  event?: Maybe<UpdateEventOptions>;
  eventDetails?: Maybe<UpdateEventDetailsOptions>;
};


export type MutationUpdateEventDetailsArgs = {
  eventDetailsId: Scalars['Int'];
  homeworkLink?: Maybe<Scalars['String']>;
  materialsLink?: Maybe<Scalars['String']>;
  level?: Maybe<EventLevels>;
  comment?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  isCorporate?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateGoogleMeetUserEventsArgs = {
  eventIds: Array<Scalars['Int']>;
};


export type MutationUpdateGroupDeadlineArgs = {
  fields: GroupDeadlineUpdateOptions;
};


export type MutationUpdateGroupScheduleEventArgs = {
  id: Scalars['Int'];
  values?: Maybe<UpdateGroupScheduleEventFields>;
};


export type MutationUpdateGroupTeamMemberArgs = {
  id: Scalars['Int'];
  fields: GroupTeamMemberUpdateOptions;
};


export type MutationUpdateGuidelineMessageArgs = {
  id: Scalars['Int'];
  text: Scalars['String'];
};


export type MutationUpdateGuidelineMessageOrderArgs = {
  fields: UpdateGuidelineMessageOrderFields;
};


export type MutationUpdateInterviewArgs = {
  id: Scalars['Int'];
  values: UpdateInterviewValues;
};


export type MutationUpdateInterviewEventArgs = {
  args: UpdateInterviewEventArgs;
};


export type MutationUpdateInvidVersionArgs = {
  id: Scalars['Int'];
  values: UpdateInvidVersionValues;
};


export type MutationUpdateJobRatingArgs = {
  id: Scalars['Int'];
  values: UpdateJobRatingValues;
  userId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateLastActionTimeArgs = {
  chatId: Scalars['Int'];
  time?: Maybe<Scalars['Date']>;
};


export type MutationUpdateLearnMessageTemplateArgs = {
  id: Scalars['Int'];
  text: Scalars['String'];
};


export type MutationUpdateLearnVideoArgs = {
  id: Scalars['Int'];
  values?: Maybe<UpdateLearnVideoOptions>;
};


export type MutationUpdateLearnVideoVersionArgs = {
  id: Scalars['Int'];
  fields: UpdateLearnVideoVersion;
};


export type MutationUpdateLocaleArgs = {
  locale: Scalars['String'];
};


export type MutationUpdateMorningTaskArgs = {
  id: Scalars['Int'];
  values: UpdateMorningTaskInput;
};


export type MutationUpdateMultipleCalendarEventsArgs = {
  values: UpdateMultipleCalendarEventsInput;
};


export type MutationUpdateParticipantNotificationSettingsArgs = {
  participantId: Scalars['Int'];
  setting?: Maybe<ChatNotificationSettingsInput>;
};


export type MutationUpdatePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationUpdatePermissionsArgs = {
  entityType: PermissionEntityType;
  entityId: Scalars['Int'];
  permissions: Array<PermissionWithResources>;
};


export type MutationUpdatePersonalChatArgs = {
  fields: UpdatePersonalChat;
};


export type MutationUpdatePlatformSettingArgs = {
  userId: Scalars['Int'];
  setting: UpdatePlatformSettingInput;
};


export type MutationUpdatePositionCourseModulesArgs = {
  courseModulePositions: Array<CourseModulePosition>;
  parentModuleSlug: Scalars['String'];
};


export type MutationUpdatePrivateEntrepreneurDetailsArgs = {
  id: Scalars['Int'];
  values: UpdatePrivateEntrepreneurDetailsValues;
};


export type MutationUpdatePrivateEntrepreneurDetailsByUserIdArgs = {
  userId?: Maybe<Scalars['Int']>;
  values: UpdatePrivateEntrepreneurDetailsValues;
};


export type MutationUpdateProfessionArgs = {
  courseFields: CourseFields;
};


export type MutationUpdateProfileArgs = {
  values?: Maybe<UpdateProfileValues>;
};


export type MutationUpdateProofOfRevenueStatusArgs = {
  id: Scalars['Int'];
  values: UpdateProofOfRevenueStatusInput;
};


export type MutationUpdateQnAQuestionStatusArgs = {
  questionId: Scalars['Int'];
  isSolved: Scalars['Boolean'];
};


export type MutationUpdateQuestionKeywordArgs = {
  id: Scalars['Int'];
  values?: Maybe<UpdateTechCheckQuestionKeywordValues>;
};


export type MutationUpdateQuestionKeywordsArgs = {
  techCheckQuestionId: Scalars['Int'];
  records: Array<TechCheckQuestionKeywordInput>;
};


export type MutationUpdateRatingArgs = {
  userId: Scalars['Int'];
  relatedId: Scalars['Int'];
  relatedType?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  workspace?: Maybe<RatingWorkspace>;
};


export type MutationUpdateRecruiterProfileArgs = {
  values: UpdateRecruiterProfileValues;
};


export type MutationUpdateReferralEventArgs = {
  id: Scalars['Int'];
  status?: Maybe<ReferralEventStatus>;
};


export type MutationUpdateRegionArgs = {
  id: Scalars['Int'];
  values: UpdateRegionValues;
};


export type MutationUpdateSandboxFileContentArgs = {
  sandboxFileId: Scalars['Int'];
  content: Scalars['String'];
};


export type MutationUpdateScheduleEventSkeletonDatesArgs = {
  id: Scalars['Int'];
  updatedDuration: Scalars['Int'];
  updatedOffset: OffsetFields;
  onlyNextSkeletons?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateSoftDeadlineArgs = {
  id: Scalars['Int'];
  values: UpdateSoftDeadlineInput;
};


export type MutationUpdateStudentsGroupArgs = {
  id: Scalars['Int'];
  values?: Maybe<UpdateStudentsGroupValues>;
};


export type MutationUpdateStudentsTaskArgs = {
  fields: UpdateStudentsTaskInput;
};


export type MutationUpdateSubscriptionArgs = {
  id: Scalars['Int'];
  values: UpdateSubscriptionValues;
  userId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateTechCheckAnswerArgs = {
  id: Scalars['Int'];
  values: UpdateTechCheckAnswerValues;
};


export type MutationUpdateTechCheckAnswerCheckphraseArgs = {
  id: Scalars['Int'];
  values: UpdateTechCheckAnswerCheckphraseValues;
};


export type MutationUpdateTechCheckEventArgs = {
  id: Scalars['Int'];
  values: UpdateTechCheckEventArgs;
};


export type MutationUpdateTechCheckEventSkeletonArgs = {
  id: Scalars['Int'];
  fields?: Maybe<UpdateTechCheckEventSkeletonFields>;
};


export type MutationUpdateTechCheckQuestionArgs = {
  id: Scalars['Int'];
  values: UpdateTechCheckQuestionArgs;
};


export type MutationUpdateTechCheckQuestionCheckphraseArgs = {
  id: Scalars['Int'];
  values: UpdateTechCheckQuestionCheckphraseArgs;
};


export type MutationUpdateTechCheckSessionEventArgs = {
  id: Scalars['Int'];
  values: UpdateTechCheckSessionEventArgs;
};


export type MutationUpdateTechCheckSessionParticipantsArgs = {
  id: Scalars['Int'];
  updatedParticipants: Array<TechCheckSessionParticipantInfo>;
};


export type MutationUpdateTechCheckSessionQuestionsArgs = {
  id: Scalars['Int'];
  questionsIds: Array<Scalars['Int']>;
};


export type MutationUpdateTechCheckTemplateArgs = {
  values: UpdateTechCheckTemplateArgs;
};


export type MutationUpdateTechCheckTopicArgs = {
  id: Scalars['Int'];
  values: UpdateTechCheckTopicArgs;
};


export type MutationUpdateTestTaskArgs = {
  values: UpdateTestTaskValuesInput;
  id: Scalars['Int'];
};


export type MutationUpdateTestTaskDescriptionArgs = {
  testTaskId: Scalars['Int'];
  descriptionFiles: Array<DescriptionFile>;
};


export type MutationUpdateTestTaskFileArgs = {
  id: Scalars['Int'];
  values: TestTaskFileUpdateOptions;
};


export type MutationUpdateTestTaskLinkArgs = {
  ids: Array<Scalars['Int']>;
  fields?: Maybe<TestTaskLinkPatch>;
};


export type MutationUpdateThreadSubscribedArgs = {
  threadId: Scalars['Int'];
  isSubscribed: Scalars['Boolean'];
};


export type MutationUpdateTopicArgs = {
  courseTopicId: Scalars['Int'];
  values: UpdateCourseTopicValues;
};


export type MutationUpdateTopicTheoryArgs = {
  courseTopicId: Scalars['Int'];
  topicTheoryFiles: Array<TopicTheoryFile>;
};


export type MutationUpdateUnprocessedLocationArgs = {
  args: UpdateUnprocessedLocationMutationArgs;
};


export type MutationUpdateUserArgs = {
  id: Scalars['Int'];
  values: UserValues;
};


export type MutationUpdateUserAgreementArgs = {
  id: Scalars['Int'];
  values: UpdateAgreementValues;
};


export type MutationUpdateUserDocumentArgs = {
  documentId: Scalars['String'];
  values: UpdateUserDocumentValues;
};


export type MutationUpdateUserDomainArgs = {
  domainSlug: Scalars['String'];
};


export type MutationUpdateUserDomainAndLanguageArgs = {
  domainSlug: Scalars['String'];
  languageName: LanguageName;
  timezone?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserEventArgs = {
  id: Scalars['Int'];
  values: UpdateUserEventValues;
};


export type MutationUpdateUserExperimentArgs = {
  id: Scalars['Int'];
  values: UpdateUserExperimentValues;
};


export type MutationUpdateUserJobArgs = {
  id: Scalars['Int'];
  values: UpdateUserJobValues;
  userId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateUserLanguageArgs = {
  language: LanguageName;
};


export type MutationUpdateUserLastActionTimeArgs = {
  id: Scalars['Int'];
  lastActionTime: Scalars['Date'];
};


export type MutationUpdateUserLegalDocumentsArgs = {
  id: Scalars['Int'];
  values: UpdateUserLegalDocumentValues;
};


export type MutationUpdateUserPlatformSettingArgs = {
  input: UpdateUserPlatformSettingInput;
};


export type MutationUpdateUserScheduleArgs = {
  id: Scalars['Int'];
  daysOfWeek: UserScheduleDaysOfWeekInput;
};


export type MutationUpdateUserTestTaskLinkTasksArgs = {
  testTaskLinkId: Scalars['Int'];
};


export type MutationUpdateUserTimeZoneArgs = {
  timezone: Scalars['String'];
};


export type MutationUpdateUserTopicStructureArgs = {
  courseUserTopicId: Scalars['Int'];
};


export type MutationUpdateUserVacanciesFilterArgs = {
  options: UpdateUserVacanciesFilterOptions;
};


export type MutationUpdateUserVacancyArgs = {
  args: UpdateUserVacancyArgs;
};


export type MutationUpdateUserVacancyContactArgs = {
  id: Scalars['Int'];
  fields: UpdateUserVacancyContactArgs;
};


export type MutationUpdateUserVacancyHistoryArgs = {
  args: UpdateUserVacancyHistoryArgs;
};


export type MutationUpdateUsersSubscriptionHashArgs = {
  users: Array<Maybe<UserSubscriptionHash>>;
};


export type MutationUpdateVacanciesArgs = {
  vacancyIds: Array<Scalars['Int']>;
  values: UpdateManyVacanciesOptions;
};


export type MutationUpdateVacancyArgs = {
  options: UpdateVacancyOptions;
};


export type MutationUpdateVideoOrdersArgs = {
  courseVideoOrders: Array<CourseVideoOrder>;
};


export type MutationUpdateWorkPlaceArgs = {
  id: Scalars['Int'];
  candidateProfileId: Scalars['Int'];
  values: UpdateCandidateProfileWorkPlaceInput;
};


export type MutationUploadCvArgs = {
  file: Scalars['Upload'];
  size?: Maybe<Scalars['Int']>;
};


export type MutationUploadMessageMediaArgs = {
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  file: Scalars['Upload'];
};


export type MutationUploadTechCheckAnswerArgs = {
  file: Scalars['Upload'];
  language: Scalars['String'];
  answerId: Scalars['Int'];
};


export type MutationUploadUserLegalDocumentsArgs = {
  documents: Array<LegalDocument>;
};


export type MutationUpsertRatingArgs = {
  userId: Scalars['Int'];
  relatedId: Scalars['Int'];
  relatedType: RatingItemType;
  rating: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  workspace?: Maybe<RatingWorkspace>;
  relatedParentId?: Maybe<Scalars['Int']>;
  relatedParentType?: Maybe<RelatedParentType>;
  relatedRootId?: Maybe<Scalars['Int']>;
  relatedRootType?: Maybe<RelatedRootType>;
  feedbackType?: Maybe<RatingsFeedbackType>;
};


export type MutationUseLearnMessageTemplateForMorningsArgs = {
  learnMessageTemplateId: Scalars['Int'];
};


export type MutationUseSlackInvitationLinkArgs = {
  link: Scalars['String'];
};


export type MutationVerifyOrderArgs = {
  providerId: Scalars['Int'];
  orderId: Scalars['String'];
};


export type MutationVerifyResetPasswordTokenArgs = {
  token: Scalars['String'];
};


export type MutationWithdrawMoneyArgs = {
  card: Scalars['String'];
  holder: Scalars['String'];
};

export type CourseTopicLearnItemAttempt = {
  __typename?: 'CourseTopicLearnItemAttempt';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  courseTopicLearnItemId: Scalars['Int'];
  solvedAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type GptModel = {
  __typename?: 'GPTModel';
  id: Scalars['Int'];
  vendor: Vendor;
  name: Scalars['String'];
  relatedType: RelatedType;
  relatedId: Scalars['Int'];
  promptTemplatePath?: Maybe<Scalars['String']>;
  isTrainingEnabled: Scalars['Boolean'];
  costs?: Maybe<Costs>;
  completionConfig?: Maybe<CompletionConfig>;
  trainingConfig?: Maybe<TrainingConfig>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
};

export enum Vendor {
  OpenAi = 'OPEN_AI'
}

export enum RelatedType {
  TestTask = 'TEST_TASK'
}

export type Costs = {
  __typename?: 'Costs';
  prompt?: Maybe<Scalars['Int']>;
  completion?: Maybe<Scalars['Int']>;
  train?: Maybe<Scalars['Int']>;
};

export type CompletionConfig = {
  __typename?: 'CompletionConfig';
  maxTokens?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  topP?: Maybe<Scalars['Float']>;
  frequencyPenalty?: Maybe<Scalars['Float']>;
  presencePenalty?: Maybe<Scalars['Float']>;
  responseFormat?: Maybe<ResponseFormat>;
};

export type ResponseFormat = {
  __typename?: 'ResponseFormat';
  type?: Maybe<Scalars['String']>;
};

export type TrainingConfig = {
  __typename?: 'TrainingConfig';
  nEpochs?: Maybe<Scalars['Int']>;
  limitTokens?: Maybe<Scalars['Int']>;
};

export type GptTrainingJob = {
  __typename?: 'GPTTrainingJob';
  id: Scalars['Int'];
  gptModelId: Scalars['Int'];
  baseModelName: Scalars['String'];
  trainedModelName?: Maybe<Scalars['String']>;
  trainingFileId?: Maybe<Scalars['String']>;
  status: Status;
  trainedOnType: TrainedOnType;
  trainedOnIds: Array<Scalars['Int']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
};

export type Query = {
  __typename?: 'Query';
  achievements: Array<Achievement>;
  activeBanks?: Maybe<Array<Bank>>;
  activeLandingPromosByDomain: Array<PromoCode>;
  activeUserStreak?: Maybe<UserStreak>;
  actualInterviews: Array<ActualInterviewsResult>;
  actualVacanciesCount: Scalars['Int'];
  adminTools: AdminTools;
  adminUser?: Maybe<User>;
  aiAssistantChat?: Maybe<AbstractChat>;
  aiCompletion: AiCompletion;
  aiHelpSuggestionCodes: Array<Translate>;
  allChanges: LmsContentChangesForPagination;
  allCompanies: Array<Company>;
  allFeatures: Array<Feature>;
  allLanguages: Array<Language>;
  allPaymentsStatistic?: Maybe<Array<PaymentsStatistic>>;
  allPrivateEntrepreneurDetails: Array<Maybe<PrivateEntrepreneurDetails>>;
  allStaticFiles: Array<StaticFile>;
  archivedEvents: Array<ArchivedEvent>;
  archivedVacancies: Array<Vacancy>;
  assignmentLinkUUID?: Maybe<Scalars['String']>;
  assignmentTasks: Array<AssignmentTask>;
  attemptById: TestTaskAttempt;
  attemptedTasksByName: Array<TestTask>;
  attemptsByUserIds: Array<TestTaskAttempt>;
  attendedEnglishLessonsCount: AttendedEnglishLessonsCountResult;
  authUser?: Maybe<User>;
  autoTechCheckById: AutoTechCheck;
  autoTechCheckByLinkUuid: AutoTechCheck;
  autoTechCheckSelectionOptions: AutoTechCheckSelectionOptions;
  autoTechChecksByFilters: Array<AutoTechCheck>;
  availableApplicationCourses: Array<Course>;
  averageReviewsIterationsCount: Array<AverageTaskReviewIterations>;
  bestUserStreak?: Maybe<UserStreak>;
  calculateGroupAchievements: Scalars['Boolean'];
  calendarEventSkeletons?: Maybe<Array<CourseEventSkeleton>>;
  calendarEvents: Array<EventAbstract>;
  calendarTemplate: CalendarTemplate;
  calendarTemplates: Array<CalendarTemplate>;
  canJoinScheduleEvent: Scalars['Boolean'];
  candidatePositions: Array<CandidatePosition>;
  candidateProfileBySlug?: Maybe<CandidateProfile>;
  certificateByCourseId: Certificate;
  chat: AbstractChat;
  chatConnection?: Maybe<ChatConnection>;
  chatConnectionByUserIds?: Maybe<ChatConnection>;
  chatGuideline: ChatGuideline;
  chatGuidelines: Array<ChatGuideline>;
  chatMentionCounters: Array<MentionCounter>;
  chatMessageById: AbstractMessage;
  chatMessageTemplates: Array<ChatMessageTemplate>;
  chatMessages: ChatMessagesQueryResult;
  chatMessagesByTestTaskId: Array<Maybe<AbstractMessage>>;
  chatParticipant?: Maybe<Participant>;
  chatParticipants: Array<Participant>;
  chatParticipantsByUserId: Array<Participant>;
  chatSections: Array<ChatSection>;
  /** @deprecated Use chatMessages instead */
  chatThreads: Array<AbstractMessage>;
  chats: Array<AbstractChat>;
  /** @deprecated Use chats instead */
  chatsByFilter: Array<AbstractChat>;
  chatsByIds: Array<AbstractChat>;
  checkIfUserCanBeDeleted: Scalars['Boolean'];
  checkTestTaskTemplate: TaskResults;
  cities?: Maybe<Array<Maybe<City>>>;
  citiesByGoogleIds?: Maybe<Array<PredictedCity>>;
  city?: Maybe<City>;
  cityById: City;
  cmpUnpaidUsers: Array<CmpUnpaidUser>;
  commitGroupAchievements?: Maybe<Array<UserAchievement>>;
  companies: Array<Company>;
  companiesBySearchQuery: Array<Company>;
  companiesForReview: CompaniesForReviewResult;
  company: Company;
  companyById: Company;
  countries: Array<Country>;
  countryById: Country;
  course?: Maybe<Course>;
  courseAgreementByCourseId?: Maybe<CourseAgreements>;
  courseById?: Maybe<Course>;
  courseBySlug?: Maybe<Course>;
  courseEventSkeleton?: Maybe<CourseEventSkeleton>;
  /** Deprecated, will not be used after complete switch to templates */
  courseEventSkeletons?: Maybe<Array<CourseEventSkeleton>>;
  courseModule: CourseModule;
  courseModulesByProfessionSlug: Array<CourseModule>;
  courseMonthPayment: CourseMonthPayment;
  courseMonthPaymentsWithFee: Array<CourseMonthPayment>;
  courseReviews: Array<CourseReview>;
  courseTopic?: Maybe<CourseTopic>;
  courseTopicByPK?: Maybe<CourseTopic>;
  courseTopicTask: CourseTopicTask;
  courseTopicTasksAndVideosDuration: TasksAndVideosDuration;
  courseTopicTheoryFile?: Maybe<CourseTopicTheoryFile>;
  courseTopics: Array<CourseTopic>;
  courseUser: CourseUser;
  courseUserApplicationById: CourseUserApplication;
  courseUserExam?: Maybe<CourseUserExam>;
  courseUserTopic?: Maybe<CourseUserTopic>;
  coursesByIds: Array<Course>;
  coursesByModuleId: Array<Course>;
  coursesByPreparationModuleId: Array<Maybe<Course>>;
  coursesList?: Maybe<Array<Course>>;
  /** @deprecated Use courseStartInfo instead */
  coursesPlacesLeft?: Maybe<Array<PlacesLeft>>;
  coursesWithTestInfo?: Maybe<Array<Course>>;
  currencies?: Maybe<Array<Currency>>;
  currenciesRate?: Maybe<CurrenciesRate>;
  currentAppVersion: Scalars['String'];
  dailyTask: DailyTask;
  dailyTasks: Array<DailyTask>;
  default?: Maybe<Scalars['Boolean']>;
  documentTemplateByName?: Maybe<DocumentTemplate>;
  domains: Array<Domain>;
  downloadTechCheckAvgScoreCSV: CsvContent;
  draftMessages: Array<DraftMessage>;
  employmentBackLogInfo: Array<EmploymentBackLogInfo>;
  employmentProfile?: Maybe<EmploymentProfile>;
  englishDashboardUsers: Array<User>;
  englishLessonEventSkeleton?: Maybe<EnglishLessonEventSkeleton>;
  englishLessonEventSkeletons: Array<EnglishLessonEventSkeleton>;
  englishLessons: Array<EnglishEvent>;
  event?: Maybe<Event>;
  eventById?: Maybe<MateEvent>;
  eventHosts: Array<EventHost>;
  eventInfo?: Maybe<EventInfoResult>;
  eventLiveCodingTestTaskLinks: Array<TestTaskLink>;
  eventsSidebarState: EventsSidebar;
  examCourseTopics: Array<CourseTopic>;
  experiment?: Maybe<Experiment>;
  fastAnswerQuestionsByPages: Array<FastAnswerQuestion>;
  feature?: Maybe<Feature>;
  feedback: Feedback;
  findActivityByPeriod: CheckInActivity;
  findCitiesByName: Array<City>;
  findCurrentStreakActiveDaysCount: Scalars['Int'];
  findPaymentProviderCourseOffers: FindPaymentProviderCourseOffersResult;
  findPermissionResources: PermissionResourcesSearchResult;
  findPermissions: Array<PermissionWithConfig>;
  findTechCheckEventDetailsById?: Maybe<TechCheckEventDetails>;
  findUncommentedIdleActivities: Array<WorkingTimeActivity>;
  flexTechCheckResults: FlexTechCheckResults;
  generatedPurchaseLinkByUuid?: Maybe<GeneratedPurchaseLink>;
  getApplicationStatuses: Array<ApplicationStatus>;
  getAverageTypingSpeedByUserId?: Maybe<TypingSpeedTest>;
  getBigCities?: Maybe<Array<Maybe<BigCity>>>;
  getCandidateProfile?: Maybe<CandidateProfile>;
  getCannySSOToken: Scalars['String'];
  getChatOnboardingData?: Maybe<ChatOnboardingData>;
  getCombinedWeeklyStats: CombinedWeeklyStats;
  getCompanies: Array<Company>;
  getCourseByProfession?: Maybe<Course>;
  getCurrentWorkingTime?: Maybe<WorkingTime>;
  getCurrentlyWorkingContractors: Array<WorkingTime>;
  getDataForTrainingJob?: Maybe<TrainingJobData>;
  getDomainBySlug: Domain;
  getExamInsight?: Maybe<ExamInsight>;
  getGroupExamsInsights: Array<Maybe<ExamInsight>>;
  getHoursLeftToStudyPerWeek?: Maybe<Scalars['Int']>;
  getIndustriesOfProfessions?: Maybe<Array<Maybe<Scalars['String']>>>;
  getInterviewByCourseUserId: Interview;
  getInterviewById: Interview;
  getLastWorkingTime?: Maybe<WorkingTime>;
  getLatestAchievements: Array<Achievement>;
  getLatestUserAchievements?: Maybe<Array<UserAchievement>>;
  getRecruiterChatConnections: RecruiterChatConnectionsResult;
  getRecruiterProfile?: Maybe<RecruiterProfile>;
  getStripePaymentIntentUserData: StripePaymentIntentUserData;
  getSubscriptionsToProcess: Array<PaymentSubscription>;
  getSupportSocials: Array<SupportSocial>;
  getTaskVersionFiles: Array<AbstractTestTaskFile>;
  getTeacherWorkingTimes?: Maybe<TeacherWorkingTimesResult>;
  getTeacherWorkingTimesByPeriod: Array<WorkingTime>;
  getTranslateByCodeAndPage?: Maybe<Translate>;
  getTrialSubscriptionsToUpgradeReminder: Array<PaymentSubscription>;
  getTypingSpeedTests?: Maybe<Array<TypingSpeedTest>>;
  getUniversities?: Maybe<Array<Maybe<Scalars['String']>>>;
  getUserAgreement?: Maybe<UserAgreement>;
  getUserDocument: UserDocument;
  getUserEvent?: Maybe<UserEvent>;
  getUserEvents?: Maybe<Array<Maybe<UserEvent>>>;
  getUserLeadType?: Maybe<LeadType>;
  getUserLegalDocuments?: Maybe<UserLegalDocuments>;
  getUserNotification: AbstractNotification;
  getUserOnboardings: Array<Maybe<UserOnboarding>>;
  getUserProfile?: Maybe<UserProfile>;
  getUserSchedule?: Maybe<UserSchedule>;
  getUserStreaksForWeeklyNotification: UserStreaksForWeeklyNotification;
  getWorkingTimeActivity: WorkingTimeActivity;
  gitHubFile?: Maybe<Scalars['String']>;
  googleMeetEvents: Array<Maybe<MateEvent>>;
  googleMeetOAuthUrl?: Maybe<Scalars['String']>;
  groupEvents: GroupEventsQueryResult;
  groupedGrantedPermissions: Array<GroupedByParentPermissions>;
  guidelineMessages: Array<AbstractGuidelineMessage>;
  hasAuthUserPermissions: Scalars['Boolean'];
  hasDailyTaskByTestTaskId: Scalars['Boolean'];
  hasDailyTasksLinks: Scalars['Boolean'];
  hasUnreadChatMessages: Scalars['Boolean'];
  haveUserCompletedIntroductionTopic: Scalars['Boolean'];
  hiringsUsersWithUnreadMessages?: Maybe<Array<HiringsUserWithUnreadMessages>>;
  invid: Invid;
  invidNoteById: InvidNote;
  invidVersion: InvidVersion;
  invidsByTitle: Array<Maybe<Invid>>;
  jobExperiences?: Maybe<Array<JobExperience>>;
  jobRating: JobRating;
  languages: Array<Language>;
  lastActiveExam?: Maybe<CourseUserExam>;
  lastChangesByUser: Array<LmsContentChanges>;
  lastRWithdrawRequest?: Maybe<ReferralEvent>;
  latestTaskReviewsReviewTime: Array<TaskReviewTime>;
  latestThreads: Array<AbstractMessage>;
  leaderboardUser?: Maybe<LeaderboardUser>;
  leaderboardUserXpPerLastNDays: Array<XpPerDayData>;
  leaderboardUsers: Array<LeaderboardUser>;
  leaderboardUsersWithPagination: LeaderboardUsersWithPagination;
  learnAndPracticeEvents: Array<ScheduleEvent>;
  learnCourses?: Maybe<Array<Course>>;
  learnVideo?: Maybe<LearnVideo>;
  learnVideoVersion?: Maybe<LearnVideoVersion>;
  learnVideos: Array<LearnVideo>;
  learnVideosByIds: Array<LearnVideo>;
  liveCodingTasks: Array<LiveCodingTask>;
  lmsSearch: SearchResult;
  loanProviders: Array<LoanProvider>;
  loanTransactions?: Maybe<Array<Payment>>;
  marathons: Array<MarathonEvent>;
  moduleCourseUsers: Array<CourseUser>;
  morningTask: MorningTask;
  morningTasks: Array<MorningTask>;
  mostRecentModule?: Maybe<Course>;
  multipleTranslations: Array<TranslationResult>;
  nextMonthPaymentStatus?: Maybe<NextMonthPaymentStatus>;
  nextProofOfRevenueStatus: NextProofOfRevenueStatus;
  /** @deprecated not used */
  nextTryDate?: Maybe<Scalars['Date']>;
  oAuthProfile?: Maybe<OAuthProfile>;
  oAuthProviderInfo?: Maybe<OAuthToken>;
  participantsByChatIds: Array<Participant>;
  participantsByIds: Array<Participant>;
  paymentPingbacks: Array<PaymentPingback>;
  paymentProviders: Array<PaymentProvider>;
  pendingReviewsWithFilters: TaskReviewsForPagination;
  peopleImages?: Maybe<PeopleImages>;
  /** @deprecated Use findPermissions query instead */
  permissions: Array<Scalars['String']>;
  personalAchievementsByCategories: Array<PersonalAchievementsByCategory>;
  pinnedMessages: Array<AbstractMessage>;
  platformSetting?: Maybe<UserPlatformSettingAbstract>;
  postPaidCourseTodos: Array<PostPaidCourseTodo>;
  preparationCourseUser?: Maybe<CourseUser>;
  privateEntrepreneurDetailsById: PrivateEntrepreneurDetails;
  privateEntrepreneurDetailsByUserId: PrivateEntrepreneurDetails;
  profession: Profession;
  professionById?: Maybe<Profession>;
  professionBySlug?: Maybe<Profession>;
  professionTags: Array<Scalars['String']>;
  professionUsers: Array<ProfessionUser>;
  professions: Array<Profession>;
  professionsListWithCourses: Array<Profession>;
  promoCodeBySlug?: Maybe<PromoCode>;
  promoCodes: Array<PromoCode>;
  promoCodesBySlugs: Array<PromoCode>;
  publicCandidateProfiles: PublicCandidateProfilesResult;
  publishingsReviews: Array<PublishingReview>;
  publishingsReviewsByDomain: Array<PublishingReview>;
  reactedParticipants: Array<Participant>;
  recruiterProfileBySlug?: Maybe<RecruiterProfile>;
  referralEvents?: Maybe<Array<Maybe<ReferralEvent>>>;
  regionById?: Maybe<Region>;
  regions?: Maybe<Array<Maybe<Region>>>;
  rejectedCourseUserNextTryDate?: Maybe<RejectedCourseUserNextTryDate>;
  review?: Maybe<Review>;
  reviews: Array<Review>;
  /** @deprecated Load files via note and sandbox */
  sandboxFiles: Array<SandboxFile>;
  savedMessages: Array<AbstractMessage>;
  savedMessagesCount: Scalars['Int'];
  scheduledMessage: ScheduledMessage;
  scheduledMessages: Array<ScheduledMessage>;
  searchByQuery: GlobalSearchResult;
  searchChatGuidelines: Array<ChatGuideline>;
  /** @deprecated Use searchUserChats instead */
  searchChats: Array<AbstractChat>;
  searchParticipants: Array<Participant>;
  searchUserChats: Array<AbstractChat>;
  sidebarState?: Maybe<SidebarState>;
  skills?: Maybe<Array<Skill>>;
  skillsByNames?: Maybe<Array<Skill>>;
  slackInvitationCount: SlackInvitationCount;
  slackInvitationLink?: Maybe<Scalars['String']>;
  specialOffersLink: SpecialOffersLink;
  specialOffersLinks: Array<SpecialOffersLink>;
  staticFile?: Maybe<StaticFile>;
  studentCalendarEvents: Array<EventAbstract>;
  studentExamInsights: Array<Maybe<ExamInsight>>;
  studentsGroup?: Maybe<StudentsGroup>;
  studentsGroupById: StudentsGroup;
  studentsGroups: Array<StudentsGroup>;
  studentsTaskProgressHistory: Array<StudentsTaskProgressHistory>;
  studentsTasks: Array<StudentsTask>;
  subscribedThreads: Array<Thread>;
  subscription: PaymentSubscription;
  subscriptionChangesHistory: Array<SubscriptionChangesHistory>;
  subscriptionPlan?: Maybe<PaymentSubscriptionPlan>;
  subscriptionPlanByUuid: PaymentSubscriptionPlan;
  subscriptionPlanPricingOptionByUUID: SubscriptionPlanPricingOption;
  subscriptionPlanPricingOptionsByUUIDs: Array<SubscriptionPlanPricingOption>;
  subscriptionPlans: Array<PaymentSubscriptionPlan>;
  targetProofOfRevenuePeriod?: Maybe<ProofOfRevenuePeriod>;
  taskNamesByPartOfSlugOrName: Array<Maybe<TestTask>>;
  taskReposFromPartOfName: Array<Maybe<TaskRepo>>;
  taskReviewByAttemptId?: Maybe<TaskReview>;
  taskReviewById?: Maybe<TaskReview>;
  taskReviews: Array<TaskReview>;
  taskReviewsBySelectedGroups?: Maybe<TaskReviewsForPagination>;
  teacher?: Maybe<Teacher>;
  teachers: Array<Teacher>;
  teachersWithPagination: TeachersForPagination;
  teamMembers: Array<TeamMember>;
  teamProjectByUserId?: Maybe<TeamProject>;
  techCheckAnswer: UserTechCheckAnswer;
  techCheckAnswersByLink: Array<UserTechCheckAnswer>;
  techCheckAnswersForModeration: Array<UserTechCheckAnswer>;
  techCheckEvent?: Maybe<TechCheckEvent>;
  techCheckEventSkeleton?: Maybe<TechCheckEventSkeleton>;
  techCheckEventSkeletonDetailsById?: Maybe<TechCheckEventSkeletonDetails>;
  techCheckEventSkeletons: Array<TechCheckEventSkeleton>;
  techCheckEvents: Array<TechCheckEvent>;
  techCheckEventsBySessionId: Array<TechCheckEvent>;
  techCheckLinkById: TechCheckLink;
  techCheckLinkByUuid?: Maybe<TechCheckLink>;
  techCheckQuestionById?: Maybe<TechCheckQuestion>;
  techCheckQuestionCheckphraseById?: Maybe<TechCheckQuestionCheckphrase>;
  techCheckQuestions: Array<TechCheckQuestion>;
  techCheckQuestionsBySearchQuery: Array<TechCheckQuestion>;
  techCheckQuestionsByTopicId: Array<TechCheckQuestion>;
  techCheckSessionById: TechCheckSession;
  techCheckSessionEventDetailsById: TechCheckSessionEventDetails;
  techCheckSessions: Array<TechCheckSession>;
  techCheckTemplateById?: Maybe<TechCheckTemplate>;
  techCheckTemplatesByType: Array<TechCheckTemplate>;
  techCheckTopicById?: Maybe<TechCheckTopic>;
  techCheckTopics: Array<TechCheckTopic>;
  telegramUserById: TelegramUser;
  telegramUserByTelegramId: TelegramUser;
  testQuery?: Maybe<Scalars['String']>;
  testTaskActivity: TestTaskActivity;
  testTaskById: TestTask;
  testTaskByRepoName?: Maybe<TestTask>;
  testTaskConfig: TestTaskConfigWithInheritance;
  testTaskLink?: Maybe<TestTaskLink>;
  testTaskPreviewLinkUUID?: Maybe<TestTaskPreviewLinkUuid>;
  testTaskPreviews: Array<TestTasksPreview>;
  testTaskQuizInfo: QuizInfoToEdit;
  testTasks: Array<TestTask>;
  testTasksByLatestAttempts: Array<TestTask>;
  threadReplies: Array<Message>;
  tooltipTexts?: Maybe<Array<Maybe<TooltipTexts>>>;
  topSkills?: Maybe<Array<Skill>>;
  translatesCollection: TranslatesCollection;
  translation: TranslationResult;
  typeformAnswers: Array<TypeformAnswer>;
  unprocessedLocations: Array<UnprocessedLocation>;
  unreadChatMentionsCount: Scalars['Int'];
  unreadChatMessagesCount: Scalars['Int'];
  unreadThreadRepliesCount: Scalars['Int'];
  usedPromoCodeById: UsedPromoCode;
  user: User;
  userAchievementById?: Maybe<UserAchievement>;
  userAchievements?: Maybe<Array<UserAchievement>>;
  userByAnalyticsId?: Maybe<User>;
  userCards?: Maybe<Array<UserCard>>;
  userChats: Array<GroupChat>;
  userCountryByIp: Scalars['String'];
  userCourseMonthPayments: Array<CourseMonthPayment>;
  /** @deprecated Use child resolver user => courseUsers instead */
  userCourseUsers?: Maybe<Array<Maybe<CourseUser>>>;
  userDocument?: Maybe<UserDocument>;
  userEnglishCourseUsers: Array<CourseUser>;
  userEvent: UserEvent;
  userEvents: Array<UserEvent>;
  userEventsByFilters: Array<UserEvent>;
  userExperiment: UserExperiment;
  userExternalPayments: Array<Payment>;
  userJob: UserJob;
  userJobs?: Maybe<Array<UserJob>>;
  userLearnMessageTemplates: Array<LearnMessageTemplate>;
  userLegalDocumentsByUserId?: Maybe<UserLegalDocuments>;
  userMentions: Array<ChatMention>;
  userOrGroupBySearchQuery: FoundUserOrGroupEntries;
  userPayment?: Maybe<Payment>;
  userPayments: Array<Payment>;
  userPaymentsStatistic?: Maybe<Array<UserPaymentsStatistic>>;
  userPaymentsWithPagination: UserPaymentsWithPagination;
  /** @deprecated Moving to config-based settings. Use 'platformSetting' instead */
  userPlatformSetting?: Maybe<UserPlatformSetting>;
  userProofOfRevenue: Array<ProofOfRevenue>;
  userReferralBonuses?: Maybe<Scalars['Float']>;
  userReferralCode: ReferralCode;
  userRoles: Array<UserRoleEntity>;
  userSubscriptions: Array<PaymentSubscription>;
  userSubscriptionsOfTypes?: Maybe<Array<Maybe<PaymentSubscription>>>;
  userVacancies: Array<UserVacancy>;
  userVacanciesBySearchQuery: Array<UserVacancy>;
  userVacanciesByUserIds: Array<UserVacancy>;
  userVacanciesFilter: UserVacanciesFilter;
  userVacancy: UserVacancy;
  userVacancyHistory?: Maybe<Array<Maybe<UserVacancyHistory>>>;
  userVacancySidebarState: UserVacancySidebar;
  users: Array<User>;
  usersHavePermissions: Array<UserHasPermission>;
  usersStudentsTracker: Array<User>;
  usersWithActiveDayConfig: Array<UserScheduleConfigData>;
  usersWithoutTaxInfo?: Maybe<Array<Maybe<User>>>;
  vacancies: VacanciesListResult;
  vacanciesByFilters: Array<Vacancy>;
  vacanciesCities?: Maybe<Array<Maybe<City>>>;
  vacanciesTracks: Array<VacanciesTrack>;
  vacancy: Vacancy;
  vacancyCitiesMapByCityName?: Maybe<VacancyCitiesMap>;
  vacancyCountriesMapByCountryName?: Maybe<VacancyCountriesMap>;
  vacancyDescription?: Maybe<Scalars['String']>;
  vacancyEditor: VacancyEditor;
  violatedUserEvents: Array<UserEvent>;
  webinar?: Maybe<WebinarEvent>;
  webinarById?: Maybe<WebinarEvent>;
  webinarLink?: Maybe<WebinarLink>;
  webinarLinks: Array<WebinarLink>;
  webinarStarsVisitor?: Maybe<WebinarStarsVisitor>;
  webinars: Array<WebinarEvent>;
  weeklyAutoTechChecksCount: Scalars['Int'];
};


export type QueryAchievementsArgs = {
  type?: Maybe<AchievementType>;
  categories?: Maybe<Array<PersonalAchievementCategory>>;
};


export type QueryActiveLandingPromosByDomainArgs = {
  domainSlug: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
};


export type QueryActualInterviewsArgs = {
  spreadsheetId: Scalars['String'];
};


export type QueryActualVacanciesCountArgs = {
  filters?: Maybe<VacancyFilters>;
};


export type QueryAiAssistantChatArgs = {
  testTaskAttemptId: Scalars['Int'];
};


export type QueryAiCompletionArgs = {
  values: AiCompletionInput;
};


export type QueryAllChangesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<ContentChangesFilter>;
};


export type QueryAllCompaniesArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type QueryAllPaymentsStatisticArgs = {
  courseId?: Maybe<Scalars['Int']>;
};


export type QueryArchivedEventsArgs = {
  offset?: Maybe<Scalars['Int']>;
};


export type QueryArchivedVacanciesArgs = {
  skipIds: Array<Maybe<Scalars['Int']>>;
};


export type QueryAssignmentLinkUuidArgs = {
  courseUserId: Scalars['Int'];
};


export type QueryAssignmentTasksArgs = {
  courseId: Scalars['Int'];
};


export type QueryAttemptByIdArgs = {
  id: Scalars['Int'];
};


export type QueryAttemptedTasksByNameArgs = {
  name: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryAttemptsByUserIdsArgs = {
  options: AttemptsByUserIdsPayload;
};


export type QueryAutoTechCheckByIdArgs = {
  id: Scalars['Int'];
};


export type QueryAutoTechCheckByLinkUuidArgs = {
  uuid: Scalars['String'];
};


export type QueryAutoTechCheckSelectionOptionsArgs = {
  courseId?: Maybe<Scalars['Int']>;
};


export type QueryAutoTechChecksByFiltersArgs = {
  filters?: Maybe<AutoTechChecksByFiltersInput>;
};


export type QueryAvailableApplicationCoursesArgs = {
  lang?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};


export type QueryBestUserStreakArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type QueryCalculateGroupAchievementsArgs = {
  achievementName: AchievementNames;
  groupId: Scalars['Int'];
};


export type QueryCalendarEventSkeletonsArgs = {
  calendarTemplateId: Scalars['Int'];
};


export type QueryCalendarEventsArgs = {
  groupId: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryCalendarTemplateArgs = {
  id: Scalars['Int'];
};


export type QueryCanJoinScheduleEventArgs = {
  eventId: Scalars['Int'];
};


export type QueryCandidateProfileBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryCertificateByCourseIdArgs = {
  courseId: Scalars['Int'];
};


export type QueryChatArgs = {
  chatId: Scalars['Int'];
};


export type QueryChatConnectionArgs = {
  id: Scalars['Int'];
};


export type QueryChatConnectionByUserIdsArgs = {
  candidateUserId: Scalars['Int'];
  recruiterUserId: Scalars['Int'];
};


export type QueryChatGuidelineArgs = {
  id: Scalars['Int'];
};


export type QueryChatGuidelinesArgs = {
  courseId: Scalars['Int'];
};


export type QueryChatMessageByIdArgs = {
  id: Scalars['Int'];
};


export type QueryChatMessagesArgs = {
  chatId: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  leftCursor?: Maybe<Scalars['Int']>;
  middleCursor?: Maybe<Scalars['Int']>;
  rightCursor?: Maybe<Scalars['Int']>;
};


export type QueryChatMessagesByTestTaskIdArgs = {
  testTaskId: Scalars['Int'];
  chatId: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryChatParticipantArgs = {
  chatId: Scalars['Int'];
};


export type QueryChatParticipantsArgs = {
  chatId: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryChatParticipantsByUserIdArgs = {
  userId: Scalars['Int'];
  filters?: Maybe<ParticipantFilters>;
};


export type QueryChatThreadsArgs = {
  chatId: Scalars['Int'];
  leftCursor?: Maybe<Scalars['Int']>;
  rightCursor?: Maybe<Scalars['Int']>;
};


export type QueryChatsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filters?: Maybe<ChatFilters>;
};


export type QueryChatsByFilterArgs = {
  filter?: Maybe<ChatFilter>;
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryChatsByIdsArgs = {
  chatIds: Array<Scalars['Int']>;
};


export type QueryCheckTestTaskTemplateArgs = {
  name: Scalars['String'];
  programmingLanguage: ProgrammingLanguage;
  files: Array<Maybe<BaseTestTaskfileWithType>>;
  config: TaskConfigInput;
};


export type QueryCitiesArgs = {
  countryId?: Maybe<Scalars['Int']>;
  regionId?: Maybe<Scalars['Int']>;
};


export type QueryCitiesByGoogleIdsArgs = {
  ids?: Maybe<Array<Scalars['String']>>;
};


export type QueryCityArgs = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryCityByIdArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryCmpUnpaidUsersArgs = {
  paidOffDate?: Maybe<Scalars['Date']>;
};


export type QueryCommitGroupAchievementsArgs = {
  groupId: Scalars['Int'];
};


export type QueryCompaniesArgs = {
  limit?: Maybe<Scalars['Int']>;
  filters?: Maybe<CompanyFilters>;
};


export type QueryCompaniesBySearchQueryArgs = {
  query?: Maybe<Scalars['String']>;
};


export type QueryCompaniesForReviewArgs = {
  cursor?: Maybe<Scalars['Int']>;
};


export type QueryCompanyArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryCompanyByIdArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryCountryByIdArgs = {
  id: Scalars['Int'];
};


export type QueryCourseArgs = {
  lang?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  courseTopicSlug?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  langs?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryCourseAgreementByCourseIdArgs = {
  courseId: Scalars['Int'];
};


export type QueryCourseByIdArgs = {
  lang?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};


export type QueryCourseBySlugArgs = {
  slug: Scalars['String'];
  type?: Maybe<CourseType>;
};


export type QueryCourseEventSkeletonArgs = {
  id: Scalars['Int'];
};


export type QueryCourseEventSkeletonsArgs = {
  courseId: Scalars['Int'];
};


export type QueryCourseModuleArgs = {
  id: Scalars['Int'];
};


export type QueryCourseModulesByProfessionSlugArgs = {
  professionSlug: Scalars['String'];
  courseModuleSlug?: Maybe<Scalars['String']>;
};


export type QueryCourseMonthPaymentArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryCourseReviewsArgs = {
  courseId?: Maybe<Scalars['Int']>;
  language: Scalars['String'];
  domain?: Maybe<Scalars['String']>;
  type?: Maybe<ReviewType>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCourseTopicArgs = {
  slug: Scalars['String'];
};


export type QueryCourseTopicByPkArgs = {
  id: Scalars['Int'];
};


export type QueryCourseTopicTaskArgs = {
  id: Scalars['Int'];
};


export type QueryCourseTopicTasksAndVideosDurationArgs = {
  moduleId: Scalars['Int'];
  includeOptionalTasks?: Maybe<Scalars['Boolean']>;
};


export type QueryCourseTopicTheoryFileArgs = {
  id: Scalars['Int'];
};


export type QueryCourseTopicsArgs = {
  slug?: Maybe<Scalars['String']>;
  slugs?: Maybe<Array<Scalars['String']>>;
  partOfSlug?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['Int']>>;
};


export type QueryCourseUserArgs = {
  id: Scalars['Int'];
  lang?: Maybe<Scalars['String']>;
};


export type QueryCourseUserApplicationByIdArgs = {
  id: Scalars['Int'];
};


export type QueryCourseUserExamArgs = {
  courseUserExamId: Scalars['Int'];
};


export type QueryCourseUserTopicArgs = {
  courseTopicSlug: Scalars['String'];
  courseSlug: Scalars['String'];
};


export type QueryCoursesByIdsArgs = {
  courseIds?: Maybe<Array<Scalars['Int']>>;
};


export type QueryCoursesByModuleIdArgs = {
  id: Scalars['Int'];
};


export type QueryCoursesByPreparationModuleIdArgs = {
  id: Scalars['Int'];
};


export type QueryCoursesListArgs = {
  status?: Maybe<CourseStatus>;
  statuses?: Maybe<Array<Maybe<CourseStatus>>>;
  lang?: Maybe<Scalars['String']>;
  type?: Maybe<CourseType>;
  types?: Maybe<Array<Maybe<CourseType>>>;
  domain?: Maybe<Scalars['String']>;
  partOfName?: Maybe<Scalars['String']>;
  professionTypes?: Maybe<Array<Maybe<ProfessionType>>>;
};


export type QueryCoursesWithTestInfoArgs = {
  lang?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  professionTypes?: Maybe<Array<ProfessionType>>;
};


export type QueryCurrenciesArgs = {
  domain?: Maybe<Scalars['String']>;
  codes?: Maybe<Array<Currencies>>;
};


export type QueryCurrenciesRateArgs = {
  currencyId: Scalars['Int'];
  toCurrencyId: Scalars['Int'];
  date: Scalars['Date'];
};


export type QueryCurrentAppVersionArgs = {
  product?: Maybe<ProductVariant>;
};


export type QueryDailyTaskArgs = {
  id: Scalars['Int'];
};


export type QueryDailyTasksArgs = {
  options: DailyTasksInput;
};


export type QueryDocumentTemplateByNameArgs = {
  templateName: Scalars['String'];
};


export type QueryDownloadTechCheckAvgScoreCsvArgs = {
  userIds: Array<Scalars['Int']>;
};


export type QueryEmploymentBackLogInfoArgs = {
  spreadsheetId: Scalars['String'];
};


export type QueryEnglishDashboardUsersArgs = {
  filters: EnglishDashboardUsersFilters;
};


export type QueryEnglishLessonEventSkeletonArgs = {
  id: Scalars['Int'];
};


export type QueryEnglishLessonsArgs = {
  filters?: Maybe<EnglishLessonsFilters>;
};


export type QueryEventArgs = {
  id: Scalars['Int'];
};


export type QueryEventByIdArgs = {
  id: Scalars['Int'];
};


export type QueryEventHostsArgs = {
  filter: EventHostFilters;
};


export type QueryEventInfoArgs = {
  id: Scalars['Int'];
};


export type QueryEventLiveCodingTestTaskLinksArgs = {
  eventId: Scalars['Int'];
};


export type QueryExperimentArgs = {
  id: Scalars['Int'];
};


export type QueryFastAnswerQuestionsByPagesArgs = {
  lang?: Maybe<Scalars['String']>;
  pages: Array<Scalars['String']>;
};


export type QueryFeatureArgs = {
  name: Scalars['String'];
};


export type QueryFeedbackArgs = {
  id: Scalars['Int'];
};


export type QueryFindActivityByPeriodArgs = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryFindCitiesByNameArgs = {
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryFindCurrentStreakActiveDaysCountArgs = {
  userId: Scalars['Int'];
};


export type QueryFindPaymentProviderCourseOffersArgs = {
  providerSlug: PaymentProviders;
  subscriptionPlanId: Scalars['Int'];
};


export type QueryFindPermissionResourcesArgs = {
  permissionName: Scalars['String'];
  searchString: Scalars['String'];
};


export type QueryFindPermissionsArgs = {
  searchString: Scalars['String'];
};


export type QueryFindTechCheckEventDetailsByIdArgs = {
  id: Scalars['Int'];
};


export type QueryFlexTechCheckResultsArgs = {
  userIds: Array<Scalars['Int']>;
};


export type QueryGeneratedPurchaseLinkByUuidArgs = {
  uuid: Scalars['String'];
};


export type QueryGetApplicationStatusesArgs = {
  lang?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  funnelType?: Maybe<FunnelType>;
};


export type QueryGetAverageTypingSpeedByUserIdArgs = {
  userId: Scalars['Int'];
};


export type QueryGetChatOnboardingDataArgs = {
  userId: Scalars['Int'];
  chatId: Scalars['Int'];
};


export type QueryGetCombinedWeeklyStatsArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
};


export type QueryGetCompaniesArgs = {
  query?: Maybe<Scalars['String']>;
  userCompanyId?: Maybe<Scalars['Int']>;
};


export type QueryGetCourseByProfessionArgs = {
  professionSlug: Scalars['String'];
  courseType?: Maybe<CourseType>;
  domain?: Maybe<Scalars['String']>;
};


export type QueryGetDataForTrainingJobArgs = {
  gptModelRelatedType: RelatedType;
  gptModelRelatedId: Scalars['Int'];
};


export type QueryGetDomainBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryGetExamInsightArgs = {
  courseUserTopicId: Scalars['Int'];
  testTaskAttemptId: Scalars['Int'];
};


export type QueryGetGroupExamsInsightsArgs = {
  groupName: Scalars['String'];
  topicSlug: Scalars['String'];
};


export type QueryGetHoursLeftToStudyPerWeekArgs = {
  userId: Scalars['Int'];
};


export type QueryGetIndustriesOfProfessionsArgs = {
  name: Scalars['String'];
};


export type QueryGetInterviewByCourseUserIdArgs = {
  courseUserId: Scalars['Int'];
};


export type QueryGetInterviewByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetLatestUserAchievementsArgs = {
  userId: Scalars['Int'];
  limit: Scalars['Int'];
  categories?: Maybe<Array<PersonalAchievementCategory>>;
};


export type QueryGetRecruiterChatConnectionsArgs = {
  recruiterUserId: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<RecruiterChatConnectionsFilter>;
};


export type QueryGetStripePaymentIntentUserDataArgs = {
  paymentIntentId: Scalars['String'];
};


export type QueryGetSupportSocialsArgs = {
  domain?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['Int']>;
};


export type QueryGetTaskVersionFilesArgs = {
  testTaskId: Scalars['Int'];
  versionNumber: Scalars['Int'];
};


export type QueryGetTeacherWorkingTimesByPeriodArgs = {
  teacherId: Scalars['Int'];
  start: Scalars['Date'];
  end: Scalars['Date'];
};


export type QueryGetTranslateByCodeAndPageArgs = {
  code: Scalars['String'];
  page: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
};


export type QueryGetUniversitiesArgs = {
  name: Scalars['String'];
};


export type QueryGetUserAgreementArgs = {
  id: Scalars['Int'];
};


export type QueryGetUserDocumentArgs = {
  documentType: DocumentTypes;
};


export type QueryGetUserEventArgs = {
  eventId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
};


export type QueryGetUserLegalDocumentsArgs = {
  id: Scalars['Int'];
};


export type QueryGetUserNotificationArgs = {
  id: Scalars['Int'];
};


export type QueryGetUserOnboardingsArgs = {
  pages: Array<Onboardings>;
};


export type QueryGetUserProfileArgs = {
  id: Scalars['Int'];
};


export type QueryGetUserScheduleArgs = {
  userId: Scalars['Int'];
};


export type QueryGetWorkingTimeActivityArgs = {
  id: Scalars['Int'];
};


export type QueryGitHubFileArgs = {
  repo?: Maybe<Scalars['String']>;
  path: Scalars['String'];
};


export type QueryGroupEventsArgs = {
  args?: Maybe<GroupEventsQueryArgs>;
};


export type QueryGroupedGrantedPermissionsArgs = {
  filter: GroupedPermissionsFilter;
};


export type QueryGuidelineMessagesArgs = {
  chatId: Scalars['Int'];
};


export type QueryHasAuthUserPermissionsArgs = {
  permissions: Array<Scalars['String']>;
};


export type QueryHasDailyTaskByTestTaskIdArgs = {
  testTaskId: Scalars['Int'];
};


export type QueryInvidArgs = {
  id: Scalars['Int'];
};


export type QueryInvidNoteByIdArgs = {
  invidNoteId: Scalars['Int'];
};


export type QueryInvidVersionArgs = {
  id: Scalars['Int'];
};


export type QueryInvidsByTitleArgs = {
  title: Scalars['String'];
};


export type QueryJobRatingArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryLanguagesArgs = {
  domain?: Maybe<Scalars['String']>;
};


export type QueryLastChangesByUserArgs = {
  userId: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryLatestTaskReviewsReviewTimeArgs = {
  reviewsCount: Scalars['Int'];
};


export type QueryLatestThreadsArgs = {
  chatId: Scalars['Int'];
};


export type QueryLeaderboardUserArgs = {
  userId: Scalars['Int'];
};


export type QueryLeaderboardUserXpPerLastNDaysArgs = {
  userId: Scalars['Int'];
  daysCount?: Maybe<Scalars['Int']>;
};


export type QueryLeaderboardUsersArgs = {
  divisionId: Scalars['Int'];
};


export type QueryLeaderboardUsersWithPaginationArgs = {
  divisionId: Scalars['Int'];
  leaderboardUserId?: Maybe<Scalars['Int']>;
  leftCursor?: Maybe<Scalars['Int']>;
  rightCursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryLearnAndPracticeEventsArgs = {
  filters: LearnAndPracticeEventsFilters;
};


export type QueryLearnCoursesArgs = {
  lang?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};


export type QueryLearnVideoArgs = {
  id: Scalars['Int'];
};


export type QueryLearnVideoVersionArgs = {
  id: Scalars['Int'];
};


export type QueryLearnVideosArgs = {
  vimeoUrl?: Maybe<Scalars['String']>;
};


export type QueryLearnVideosByIdsArgs = {
  ids: Array<Scalars['Int']>;
};


export type QueryLiveCodingTasksArgs = {
  courseId: Scalars['Int'];
};


export type QueryLmsSearchArgs = {
  query: Scalars['String'];
  types?: Maybe<Array<SearchEntity>>;
  lang?: Maybe<Scalars['String']>;
  config?: Maybe<LmsSearchConfig>;
};


export type QueryLoanProvidersArgs = {
  providers?: Maybe<Array<Maybe<LoanProviders>>>;
  domain?: Maybe<Scalars['String']>;
};


export type QueryLoanTransactionsArgs = {
  provider: LoanProviders;
  statuses: Array<Maybe<PaymentStatus>>;
};


export type QueryModuleCourseUsersArgs = {
  userId: Scalars['Int'];
};


export type QueryMorningTaskArgs = {
  id: Scalars['Int'];
};


export type QueryMorningTasksArgs = {
  name: Scalars['String'];
  courseId: Scalars['Int'];
};


export type QueryMostRecentModuleArgs = {
  moduleTypes?: Maybe<Array<ModuleType>>;
};


export type QueryMultipleTranslationsArgs = {
  text: Scalars['String'];
  targetLanguages: Array<LanguageName>;
};


export type QueryNextProofOfRevenueStatusArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type QueryNextTryDateArgs = {
  slug: Scalars['String'];
};


export type QueryOAuthProfileArgs = {
  provider: OAuthProviders;
};


export type QueryOAuthProviderInfoArgs = {
  provider: OAuthProviders;
};


export type QueryParticipantsByChatIdsArgs = {
  chatIds: Array<Scalars['Int']>;
};


export type QueryParticipantsByIdsArgs = {
  ids: Array<Scalars['Int']>;
};


export type QueryPaymentPingbacksArgs = {
  paymentId: Scalars['Int'];
};


export type QueryPaymentProvidersArgs = {
  providers?: Maybe<Array<Maybe<PaymentProviders>>>;
};


export type QueryPendingReviewsWithFiltersArgs = {
  studentsGroupNames: Array<Scalars['String']>;
  courseIds?: Maybe<Array<Scalars['Int']>>;
  courseTopicIds?: Maybe<Array<Scalars['Int']>>;
  userIds?: Maybe<Array<Scalars['Int']>>;
  reviewType?: Maybe<Array<TaskReviewType>>;
  statuses: Array<TaskReviewStatus>;
  order?: Maybe<TasksReviewsOrder>;
  offset: Scalars['Int'];
};


export type QueryPeopleImagesArgs = {
  domain: Scalars['String'];
};


export type QueryPermissionsArgs = {
  partOfName?: Maybe<Scalars['String']>;
};


export type QueryPersonalAchievementsByCategoriesArgs = {
  userId?: Maybe<Scalars['Int']>;
  categories?: Maybe<Array<PersonalAchievementCategory>>;
};


export type QueryPinnedMessagesArgs = {
  chatId: Scalars['Int'];
};


export type QueryPlatformSettingArgs = {
  name: UserPlatformSettingNames;
};


export type QueryPreparationCourseUserArgs = {
  id: Scalars['Int'];
};


export type QueryPrivateEntrepreneurDetailsByIdArgs = {
  id: Scalars['Int'];
};


export type QueryPrivateEntrepreneurDetailsByUserIdArgs = {
  userId: Scalars['Int'];
};


export type QueryProfessionArgs = {
  slug: Scalars['String'];
};


export type QueryProfessionByIdArgs = {
  id: Scalars['Int'];
};


export type QueryProfessionBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryProfessionTagsArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryProfessionUsersArgs = {
  userId: Scalars['Int'];
};


export type QueryProfessionsArgs = {
  isActive?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ProfessionStatus>;
  professionTypes?: Maybe<Array<ProfessionType>>;
  domain?: Maybe<Scalars['String']>;
};


export type QueryProfessionsListWithCoursesArgs = {
  lang: Scalars['String'];
  domain: Scalars['String'];
  courseTypes: Array<CourseType>;
};


export type QueryPromoCodeBySlugArgs = {
  slug: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
};


export type QueryPromoCodesArgs = {
  domainSlug?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};


export type QueryPromoCodesBySlugsArgs = {
  slugs: Array<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
};


export type QueryPublicCandidateProfilesArgs = {
  where?: Maybe<PublicProfilesParameters>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryPublishingsReviewsByDomainArgs = {
  domain?: Maybe<Scalars['String']>;
};


export type QueryReactedParticipantsArgs = {
  messageId: Scalars['Int'];
  reactionShortcode: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryRecruiterProfileBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryReferralEventsArgs = {
  types?: Maybe<Array<Maybe<ReferralEventType>>>;
  statuses?: Maybe<Array<Maybe<ReferralEventStatus>>>;
};


export type QueryRegionByIdArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryRegionsArgs = {
  countryId?: Maybe<Scalars['Int']>;
};


export type QueryRejectedCourseUserNextTryDateArgs = {
  courseId: Scalars['Int'];
};


export type QueryReviewArgs = {
  id: Scalars['Int'];
};


export type QueryReviewsArgs = {
  courseId?: Maybe<Scalars['Int']>;
  language: Scalars['String'];
  domain?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySandboxFilesArgs = {
  sandboxId: Scalars['Int'];
};


export type QuerySavedMessagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryScheduledMessageArgs = {
  id: Scalars['Int'];
};


export type QueryScheduledMessagesArgs = {
  chatIds?: Maybe<Array<Scalars['Int']>>;
  senderUserIds?: Maybe<Array<Scalars['Int']>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QuerySearchByQueryArgs = {
  query: Scalars['String'];
  types?: Maybe<Array<SearchableEntityType>>;
  filters?: Maybe<GlobalSearchFilters>;
  sortBy?: Maybe<GlobalSearchSortOption>;
  offset?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
};


export type QuerySearchChatGuidelinesArgs = {
  name: Scalars['String'];
  courseId?: Maybe<Scalars['Int']>;
};


export type QuerySearchChatsArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QuerySearchParticipantsArgs = {
  query: Scalars['String'];
  chatId: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySearchUserChatsArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QuerySkillsArgs = {
  query?: Maybe<Scalars['String']>;
  requiredSkillsIds?: Maybe<Array<Scalars['Int']>>;
};


export type QuerySkillsByNamesArgs = {
  names: Array<Scalars['String']>;
};


export type QuerySlackInvitationCountArgs = {
  domain?: Maybe<Scalars['String']>;
};


export type QuerySlackInvitationLinkArgs = {
  domain?: Maybe<Scalars['String']>;
};


export type QuerySpecialOffersLinkArgs = {
  uuid: Scalars['String'];
};


export type QuerySpecialOffersLinksArgs = {
  eventId: Scalars['Int'];
};


export type QueryStaticFileArgs = {
  name: Scalars['String'];
};


export type QueryStudentCalendarEventsArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryStudentExamInsightsArgs = {
  courseUserTopicId: Scalars['Int'];
};


export type QueryStudentsGroupArgs = {
  name: Scalars['String'];
};


export type QueryStudentsGroupByIdArgs = {
  id: Scalars['Int'];
};


export type QueryStudentsGroupsArgs = {
  filters?: Maybe<StudentsGroupsFilters>;
  limit?: Maybe<Scalars['Int']>;
  recent?: Maybe<Scalars['Boolean']>;
};


export type QueryStudentsTaskProgressHistoryArgs = {
  studentTaskId: Scalars['Int'];
};


export type QueryStudentsTasksArgs = {
  args: GetStudentsTasksArgs;
};


export type QuerySubscribedThreadsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  excludeThreadIds?: Maybe<Array<Scalars['Int']>>;
  unreadOnly?: Maybe<Scalars['Boolean']>;
};


export type QuerySubscriptionArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QuerySubscriptionChangesHistoryArgs = {
  subscriptionId: Scalars['Int'];
};


export type QuerySubscriptionPlanArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QuerySubscriptionPlanByUuidArgs = {
  uuid?: Maybe<Scalars['String']>;
};


export type QuerySubscriptionPlanPricingOptionByUuidArgs = {
  uuid: Scalars['String'];
};


export type QuerySubscriptionPlanPricingOptionsByUuiDsArgs = {
  uuids: Array<Scalars['String']>;
};


export type QuerySubscriptionPlansArgs = {
  domain: Scalars['String'];
  filter?: Maybe<SubscriptionPlanFilters>;
};


export type QueryTargetProofOfRevenuePeriodArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type QueryTaskNamesByPartOfSlugOrNameArgs = {
  partOfSlugOrName: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryTaskReposFromPartOfNameArgs = {
  partOfName: Scalars['String'];
};


export type QueryTaskReviewByAttemptIdArgs = {
  testTaskAttemptId: Scalars['Int'];
};


export type QueryTaskReviewByIdArgs = {
  id: Scalars['Int'];
};


export type QueryTaskReviewsArgs = {
  language?: Maybe<Scalars['String']>;
  config?: Maybe<TaskReviewConfig>;
};


export type QueryTaskReviewsBySelectedGroupsArgs = {
  studentsGroupNames: Array<Scalars['String']>;
  config?: Maybe<TaskReviewConfig>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryTeacherArgs = {
  id: Scalars['Int'];
};


export type QueryTeachersArgs = {
  type?: Maybe<TeacherTypes>;
  types?: Maybe<Array<TeacherTypes>>;
  courseId?: Maybe<Scalars['Int']>;
};


export type QueryTeachersWithPaginationArgs = {
  types?: Maybe<Array<TeacherTypes>>;
  courseId?: Maybe<Scalars['Int']>;
  order?: Maybe<TeachersOrder>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryTeamMembersArgs = {
  domain?: Maybe<Scalars['String']>;
};


export type QueryTeamProjectByUserIdArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type QueryTechCheckAnswerArgs = {
  id: Scalars['Int'];
};


export type QueryTechCheckAnswersByLinkArgs = {
  techCheckLinkId: Scalars['Int'];
};


export type QueryTechCheckEventArgs = {
  id: Scalars['Int'];
};


export type QueryTechCheckEventSkeletonArgs = {
  id: Scalars['Int'];
};


export type QueryTechCheckEventSkeletonDetailsByIdArgs = {
  id: Scalars['Int'];
};


export type QueryTechCheckEventsArgs = {
  filters: TechCheckEventsFilters;
};


export type QueryTechCheckEventsBySessionIdArgs = {
  sessionId: Scalars['Int'];
};


export type QueryTechCheckLinkByIdArgs = {
  id: Scalars['Int'];
};


export type QueryTechCheckLinkByUuidArgs = {
  uuid: Scalars['String'];
};


export type QueryTechCheckQuestionByIdArgs = {
  id: Scalars['Int'];
};


export type QueryTechCheckQuestionCheckphraseByIdArgs = {
  id: Scalars['Int'];
};


export type QueryTechCheckQuestionsBySearchQueryArgs = {
  filters: TechCheckQuestionsBySearchQueryFilters;
};


export type QueryTechCheckQuestionsByTopicIdArgs = {
  topicId: Scalars['Int'];
};


export type QueryTechCheckSessionByIdArgs = {
  id: Scalars['Int'];
};


export type QueryTechCheckSessionEventDetailsByIdArgs = {
  id: Scalars['Int'];
};


export type QueryTechCheckSessionsArgs = {
  studentsGroupId?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  createdAtLessThan?: Maybe<Scalars['Date']>;
};


export type QueryTechCheckTemplateByIdArgs = {
  id: Scalars['Int'];
};


export type QueryTechCheckTemplatesByTypeArgs = {
  type: TechCheckTemplateType;
  courseId?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<TechCheckTemplatesOrder>;
  nextCursorId?: Maybe<Scalars['Int']>;
};


export type QueryTechCheckTopicByIdArgs = {
  id: Scalars['Int'];
};


export type QueryTechCheckTopicsArgs = {
  courseId?: Maybe<Scalars['Int']>;
};


export type QueryTelegramUserByIdArgs = {
  id: Scalars['Int'];
};


export type QueryTelegramUserByTelegramIdArgs = {
  telegramId: Scalars['String'];
};


export type QueryTestQueryArgs = {
  content: Scalars['String'];
};


export type QueryTestTaskActivityArgs = {
  testTaskId: Scalars['Int'];
  attemptId: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryTestTaskByIdArgs = {
  testTaskId: Scalars['Int'];
};


export type QueryTestTaskByRepoNameArgs = {
  repoName: Scalars['String'];
};


export type QueryTestTaskConfigArgs = {
  moduleSlug?: Maybe<Scalars['String']>;
  topicSlug?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['Int']>;
};


export type QueryTestTaskLinkArgs = {
  linkUUID: Scalars['String'];
  courseTopicSlug?: Maybe<Scalars['String']>;
  studentsGroupId?: Maybe<Scalars['Int']>;
};


export type QueryTestTaskPreviewLinkUuidArgs = {
  courseUserId: Scalars['Int'];
};


export type QueryTestTaskPreviewsArgs = {
  professionSlug: Scalars['String'];
};


export type QueryTestTaskQuizInfoArgs = {
  versionId: Scalars['Int'];
};


export type QueryTestTasksArgs = {
  ids?: Maybe<Array<Scalars['Int']>>;
  slugs?: Maybe<Array<Scalars['String']>>;
  slug?: Maybe<Scalars['String']>;
  partOfName?: Maybe<Scalars['String']>;
  programmingLanguage?: Maybe<ProgrammingLanguage>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryTestTasksByLatestAttemptsArgs = {
  userId: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryThreadRepliesArgs = {
  threadId: Scalars['Int'];
};


export type QueryTopSkillsArgs = {
  positionId: Scalars['Int'];
};


export type QueryTranslatesCollectionArgs = {
  englishTranslation: Scalars['String'];
};


export type QueryTranslationArgs = {
  text: Scalars['String'];
  targetLanguage: LanguageName;
};


export type QueryTypeformAnswersArgs = {
  formId: Scalars['String'];
  responseId: Scalars['String'];
};


export type QueryUnprocessedLocationsArgs = {
  args?: Maybe<UnprocessedLocationsQueryArgs>;
};


export type QueryUsedPromoCodeByIdArgs = {
  id: Scalars['Int'];
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryUserAchievementByIdArgs = {
  id: Scalars['Int'];
};


export type QueryUserAchievementsArgs = {
  userId: Scalars['Int'];
};


export type QueryUserByAnalyticsIdArgs = {
  analyticsId: Scalars['String'];
};


export type QueryUserCardsArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type QueryUserChatsArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type QueryUserCourseMonthPaymentsArgs = {
  userId?: Maybe<Scalars['Int']>;
  options?: Maybe<UserCourseMonthPaymentsOptions>;
};


export type QueryUserDocumentArgs = {
  documentType: DocumentTypes;
};


export type QueryUserEnglishCourseUsersArgs = {
  userId: Scalars['Int'];
  lang?: Maybe<Scalars['String']>;
};


export type QueryUserEventArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryUserEventsArgs = {
  eventId?: Maybe<Scalars['Int']>;
};


export type QueryUserEventsByFiltersArgs = {
  filters?: Maybe<UserEventsByFiltersInput>;
};


export type QueryUserExperimentArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryUserExternalPaymentsArgs = {
  userId: Scalars['Int'];
  paymentStatuses?: Maybe<Array<PaymentStatus>>;
};


export type QueryUserJobArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryUserLearnMessageTemplatesArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type QueryUserLegalDocumentsByUserIdArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type QueryUserOrGroupBySearchQueryArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryUserPaymentArgs = {
  uuid: Scalars['String'];
  isRefund?: Maybe<Scalars['Boolean']>;
};


export type QueryUserPaymentsArgs = {
  userId: Scalars['Int'];
  paymentStatuses?: Maybe<Array<PaymentStatus>>;
  onlyExternalPayments?: Maybe<Scalars['Boolean']>;
};


export type QueryUserPaymentsWithPaginationArgs = {
  userId: Scalars['Int'];
  paymentStatuses?: Maybe<Array<PaymentStatus>>;
  onlyExternalPayments?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderOptions?: Maybe<PaymentsOrderOptions>;
};


export type QueryUserPlatformSettingArgs = {
  name: UserPlatformSettingNames;
};


export type QueryUserProofOfRevenueArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type QueryUserSubscriptionsArgs = {
  filter?: Maybe<SubscriptionsFilters>;
  userId?: Maybe<Scalars['Int']>;
};


export type QueryUserSubscriptionsOfTypesArgs = {
  types: Array<Maybe<SubscriptionType>>;
};


export type QueryUserVacanciesArgs = {
  pagination?: Maybe<UserVacancyPaginationArgs>;
};


export type QueryUserVacanciesBySearchQueryArgs = {
  searchQuery: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryUserVacanciesByUserIdsArgs = {
  userIds: Array<Scalars['Int']>;
  dateFilter?: Maybe<UserVacanciesDateFilter>;
  userVacancyHistoryStatuses?: Maybe<Array<UserVacancyHistoryStatus>>;
};


export type QueryUserVacancyArgs = {
  id: Scalars['Int'];
};


export type QueryUserVacancyHistoryArgs = {
  userVacancyId: Scalars['Int'];
};


export type QueryUsersArgs = {
  filter: UsersFilter;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryUsersHavePermissionsArgs = {
  usersIds: Array<Scalars['Int']>;
  permissions: Array<Scalars['String']>;
};


export type QueryUsersStudentsTrackerArgs = {
  filter?: Maybe<StudentsTrackerFilter>;
};


export type QueryUsersWithActiveDayConfigArgs = {
  day: UserScheduleDay;
};


export type QueryVacanciesArgs = {
  filter?: Maybe<VacanciesListFilters>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
};


export type QueryVacanciesByFiltersArgs = {
  filters?: Maybe<VacancyFilters>;
};


export type QueryVacancyArgs = {
  id: Scalars['Int'];
};


export type QueryVacancyCitiesMapByCityNameArgs = {
  cityName: Scalars['String'];
};


export type QueryVacancyCountriesMapByCountryNameArgs = {
  countryName: Scalars['String'];
};


export type QueryVacancyDescriptionArgs = {
  vacancyId: Scalars['Int'];
};


export type QueryViolatedUserEventsArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryWebinarArgs = {
  slug?: Maybe<Scalars['String']>;
};


export type QueryWebinarByIdArgs = {
  id: Scalars['Int'];
};


export type QueryWebinarLinkArgs = {
  link: Scalars['String'];
};


export type QueryWebinarStarsVisitorArgs = {
  userId: Scalars['Int'];
  eventId: Scalars['Int'];
};

export type TrainingJobData = {
  __typename?: 'TrainingJobData';
  gptModel: GptModel;
  sources: Array<Scalars['String']>;
  trainedOnIds: Array<Scalars['Int']>;
};

export enum Status {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Failed = 'FAILED'
}

export enum TrainedOnType {
  TaskReview = 'TASK_REVIEW'
}

export type Achievement = {
  __typename?: 'Achievement';
  id: Scalars['Int'];
  name: Scalars['String'];
  category?: Maybe<PersonalAchievementCategory>;
  type: AchievementType;
  rarity: AchievementRarity;
  unlockAmount?: Maybe<Scalars['Int']>;
  processingType?: Maybe<AchievementProcessingType>;
  userAchievement?: Maybe<UserAchievement>;
  iconUnlocked?: Maybe<File>;
  iconLockedDark?: Maybe<File>;
  iconLocked?: Maybe<File>;
};

export type PersonalAchievementsByCategory = {
  __typename?: 'PersonalAchievementsByCategory';
  category: PersonalAchievementCategory;
  blockedReason?: Maybe<CategoryBlockedReason>;
  achievements: Array<Achievement>;
};

export type ProcessRewardableActionFieldsInput = {
  testTask?: Maybe<TestTaskInput>;
  event?: Maybe<EventInput>;
  englishLevelPrev?: Maybe<EnglishLevelInput>;
  englishLevelCurrent?: Maybe<EnglishLevelInput>;
  chatId?: Maybe<Scalars['Int']>;
  senderId?: Maybe<Scalars['Int']>;
  threadId?: Maybe<Scalars['Int']>;
};

export type TestTaskInput = {
  id: Scalars['Int'];
};

export type EventInput = {
  type: EventTypes;
  status: EventStatuses;
  startAt: Scalars['Date'];
};

export type EnglishLevelInput = {
  level: UserEnglishLevel;
};

export enum AchievementProcessingType {
  ActionsCount = 'ACTIONS_COUNT',
  ActionValue = 'ACTION_VALUE',
  ActionValueStrict = 'ACTION_VALUE_STRICT'
}

export enum AchievementRarity {
  Common = 'COMMON',
  Rare = 'RARE'
}

export enum AchievementNames {
  TechCheck = 'TECH_CHECK',
  SelfEducation = 'SELF_EDUCATION',
  TypingSpeed = 'TYPING_SPEED',
  Punctual = 'PUNCTUAL',
  TeamPlayer = 'TEAM_PLAYER',
  MorningTasks = 'MORNING_TASKS',
  HardSkills = 'HARD_SKILLS'
}

export enum RewardableAction {
  TaskSolved = 'TASK_SOLVED',
  TypingSpeedTestCompleted = 'TYPING_SPEED_TEST_COMPLETED',
  AvatarUpdated = 'AVATAR_UPDATED',
  SandboxJoined = 'SANDBOX_JOINED',
  TopicOpened = 'TOPIC_OPENED',
  BecomeStudent = 'BECOME_STUDENT',
  CourseAddedToFavourites = 'COURSE_ADDED_TO_FAVOURITES',
  VideoWatched = 'VIDEO_WATCHED',
  AchievementSharedSocial = 'ACHIEVEMENT_SHARED_SOCIAL',
  EventAttended = 'EVENT_ATTENDED',
  AutoTechCheckCompleted = 'AUTO_TECH_CHECK_COMPLETED',
  LeaderboardBronzeRunCompleted = 'LEADERBOARD_BRONZE_RUN_COMPLETED',
  LeaderboardSilverRunCompleted = 'LEADERBOARD_SILVER_RUN_COMPLETED',
  LeaderboardGoldRunCompleted = 'LEADERBOARD_GOLD_RUN_COMPLETED',
  LeaderboardPlatinumRunCompleted = 'LEADERBOARD_PLATINUM_RUN_COMPLETED',
  CvSubmitted = 'CV_SUBMITTED',
  ForumQuestionAsked = 'FORUM_QUESTION_ASKED',
  ForumQuestionAnswered = 'FORUM_QUESTION_ANSWERED',
  RatingGiven = 'RATING_GIVEN',
  PeerReviewPerformed = 'PEER_REVIEW_PERFORMED',
  StreakDayActivated = 'STREAK_DAY_ACTIVATED',
  EnglishLevelUpdated = 'ENGLISH_LEVEL_UPDATED',
  ChatThreadAnswered = 'CHAT_THREAD_ANSWERED'
}

export enum PersonalAchievementNames {
  LookingGood = 'LOOKING_GOOD',
  SubscriberLookingGood = 'SUBSCRIBER_LOOKING_GOOD',
  PowerfulStart_1 = 'POWERFUL_START_1',
  PowerfulStart_3 = 'POWERFUL_START_3',
  PowerfulStart_5 = 'POWERFUL_START_5',
  JoinedSandbox = 'JOINED_SANDBOX',
  TopicOpened = 'TOPIC_OPENED',
  SubscriberTopicOpened = 'SUBSCRIBER_TOPIC_OPENED',
  WeekendTaskSolved = 'WEEKEND_TASK_SOLVED',
  MateStudent = 'MATE_STUDENT',
  SubscriberMateStudent = 'SUBSCRIBER_MATE_STUDENT',
  FirstTypingSpeed = 'FIRST_TYPING_SPEED',
  TypingSpeed_30Wpm = 'TYPING_SPEED_30_WPM',
  TypingSpeed_50Wpm = 'TYPING_SPEED_50_WPM',
  WebinarViewed = 'WEBINAR_VIEWED',
  CourseAddedToFavourites = 'COURSE_ADDED_TO_FAVOURITES',
  VideosWatched_5 = 'VIDEOS_WATCHED_5',
  MediaStar = 'MEDIA_STAR',
  DailyTasks_10 = 'DAILY_TASKS_10',
  DailyTasks_20 = 'DAILY_TASKS_20',
  DailyTasks_30 = 'DAILY_TASKS_30',
  EventsQna_1 = 'EVENTS_QNA_1',
  EventsQna_5 = 'EVENTS_QNA_5',
  EventsQna_10 = 'EVENTS_QNA_10',
  EventsTechChecks_3 = 'EVENTS_TECH_CHECKS_3',
  EventsTechChecks_6 = 'EVENTS_TECH_CHECKS_6',
  EventsTechChecks_9 = 'EVENTS_TECH_CHECKS_9',
  AutoTechChecks_5 = 'AUTO_TECH_CHECKS_5',
  AutoTechChecks_10 = 'AUTO_TECH_CHECKS_10',
  AutoTechChecks_15 = 'AUTO_TECH_CHECKS_15',
  LeaderboardBronze_1 = 'LEADERBOARD_BRONZE_1',
  LeaderboardBronze_2 = 'LEADERBOARD_BRONZE_2',
  LeaderboardBronze_3 = 'LEADERBOARD_BRONZE_3',
  LeaderboardSilver_1 = 'LEADERBOARD_SILVER_1',
  LeaderboardSilver_2 = 'LEADERBOARD_SILVER_2',
  LeaderboardSilver_3 = 'LEADERBOARD_SILVER_3',
  LeaderboardGold_1 = 'LEADERBOARD_GOLD_1',
  LeaderboardGold_2 = 'LEADERBOARD_GOLD_2',
  LeaderboardGold_3 = 'LEADERBOARD_GOLD_3',
  LeaderboardPlatinum_1 = 'LEADERBOARD_PLATINUM_1',
  LeaderboardPlatinum_2 = 'LEADERBOARD_PLATINUM_2',
  LeaderboardPlatinum_3 = 'LEADERBOARD_PLATINUM_3',
  CvMaster_5 = 'CV_MASTER_5',
  CvMaster_15 = 'CV_MASTER_15',
  CvMaster_25 = 'CV_MASTER_25',
  Punctuality_1 = 'PUNCTUALITY_1',
  Punctuality_5 = 'PUNCTUALITY_5',
  Punctuality_10 = 'PUNCTUALITY_10',
  InterviewNinja_3 = 'INTERVIEW_NINJA_3',
  InterviewNinja_6 = 'INTERVIEW_NINJA_6',
  InterviewNinja_9 = 'INTERVIEW_NINJA_9',
  ForumGuruQuestion_1 = 'FORUM_GURU_QUESTION_1',
  ForumGuruQuestion_5 = 'FORUM_GURU_QUESTION_5',
  ForumGuruQuestion_10 = 'FORUM_GURU_QUESTION_10',
  ForumGuruAnswer_1 = 'FORUM_GURU_ANSWER_1',
  ForumGuruAnswer_5 = 'FORUM_GURU_ANSWER_5',
  ForumGuruAnswer_10 = 'FORUM_GURU_ANSWER_10',
  QualityExpert_1 = 'QUALITY_EXPERT_1',
  QualityExpert_10 = 'QUALITY_EXPERT_10',
  QualityExpert_20 = 'QUALITY_EXPERT_20',
  QualityExpert_30 = 'QUALITY_EXPERT_30',
  QualityExpert_50 = 'QUALITY_EXPERT_50',
  QualityExpert_100 = 'QUALITY_EXPERT_100',
  MorningTasks_10 = 'MORNING_TASKS_10',
  MorningTasks_20 = 'MORNING_TASKS_20',
  MorningTasks_30 = 'MORNING_TASKS_30',
  PeerReview_1 = 'PEER_REVIEW_1',
  PeerReview_5 = 'PEER_REVIEW_5',
  PeerReview_10 = 'PEER_REVIEW_10',
  DailyStreak_7 = 'DAILY_STREAK_7',
  DailyStreak_14 = 'DAILY_STREAK_14',
  DailyStreak_30 = 'DAILY_STREAK_30',
  DailyStreak_60 = 'DAILY_STREAK_60',
  DailyStreak_120 = 'DAILY_STREAK_120',
  DailyStreak_180 = 'DAILY_STREAK_180',
  DailyStreak_365 = 'DAILY_STREAK_365',
  EnglishLessons_10 = 'ENGLISH_LESSONS_10',
  EnglishLessons_30 = 'ENGLISH_LESSONS_30',
  EnglishLessons_50 = 'ENGLISH_LESSONS_50',
  EnglishGrammar = 'ENGLISH_GRAMMAR',
  EnglishSpeaking = 'ENGLISH_SPEAKING',
  EnglishWorkshop = 'ENGLISH_WORKSHOP',
  EnglishIt = 'ENGLISH_IT',
  EnglishWork = 'ENGLISH_WORK',
  EnglishInterview = 'ENGLISH_INTERVIEW',
  PlacementTest = 'PLACEMENT_TEST',
  UpgradeTest = 'UPGRADE_TEST',
  ProgressTest = 'PROGRESS_TEST',
  ThreadAnswer_1 = 'THREAD_ANSWER_1',
  ThreadAnswer_10 = 'THREAD_ANSWER_10',
  ThreadAnswer_30 = 'THREAD_ANSWER_30'
}

export enum PersonalAchievementCategory {
  Welcome = 'WELCOME',
  SubscriberWelcome = 'SUBSCRIBER_WELCOME',
  PowerfulStart = 'POWERFUL_START',
  TypingSpeed = 'TYPING_SPEED',
  Leaderboard = 'LEADERBOARD',
  DailyTasks = 'DAILY_TASKS',
  TechChecks = 'TECH_CHECKS',
  Events = 'EVENTS',
  LeaderboardBronze = 'LEADERBOARD_BRONZE',
  LeaderboardSilver = 'LEADERBOARD_SILVER',
  LeaderboardGold = 'LEADERBOARD_GOLD',
  LeaderboardPlatinum = 'LEADERBOARD_PLATINUM',
  AutoTechChecks = 'AUTO_TECH_CHECKS',
  CvMaster = 'CV_MASTER',
  Punctuality = 'PUNCTUALITY',
  InterviewNinja = 'INTERVIEW_NINJA',
  ForumGuru = 'FORUM_GURU',
  QualityExpert = 'QUALITY_EXPERT',
  MorningTasks = 'MORNING_TASKS',
  TeamPlayer = 'TEAM_PLAYER',
  DailyStreak = 'DAILY_STREAK',
  EnglishCourse = 'ENGLISH_COURSE',
  EnglishClub = 'ENGLISH_CLUB',
  EnglishUpgrade = 'ENGLISH_UPGRADE',
  ChatGuru = 'CHAT_GURU'
}

export enum AchievementType {
  Personal = 'PERSONAL',
  Group = 'GROUP'
}

export enum CategoryBlockedReason {
  OnlyMateStudents = 'ONLY_MATE_STUDENTS',
  OnlyFxStudents = 'ONLY_FX_STUDENTS',
  OnlyFtStudents = 'ONLY_FT_STUDENTS',
  UnderDevelopment = 'UNDER_DEVELOPMENT'
}

export type Subscription = {
  __typename?: 'Subscription';
  achievementUnlocked?: Maybe<UserAchievement>;
  aiChatMessageProcessed?: Maybe<AiChatMessageProcessedResponse>;
  approvedConnection: Participant;
  askedTechCheckQuestion: UserTechCheckAnswer;
  attachmentTaskReviewRequested?: Maybe<TaskReview>;
  attachmentTaskReviewed?: Maybe<TaskReview>;
  attemptBuildUpdate: TestTaskAttemptBuildResults;
  attemptTestResultsUpdate: CodeEditorCheckResult;
  attemptsChangesByLink: AttemptUpdate;
  chatCreated: AbstractChat;
  chatMentionCreated: ChatMention;
  chatMentionCreatedWS: ChatMentionCreatedPayload;
  chatMentionDeletedWS: ChatMentionDeletedPayload;
  /** @deprecated Use the subscriptions with WS suffix instead */
  chatMessageCreated: AbstractMessage;
  chatMessageCreatedWS: ChatMessageCreatedResult;
  /** @deprecated Use the subscriptions with WS suffix instead */
  chatMessageDeleted: AbstractMessage;
  chatMessageDeletedWS: ChatMessageDeletedResult;
  chatMessagePinned: AbstractMessage;
  chatMessageRead: ReadChatMessageResult;
  chatMessageReadWS: ChatMessageReadResult;
  chatMessageSaved: AbstractMessage;
  /** @deprecated Use the subscriptions with WS suffix instead */
  chatMessageUnpinned: AbstractMessage;
  chatMessageUnpinnedWS: ChatMessageUnpinnedPayload;
  /** @deprecated Use the subscriptions with WS suffix instead */
  chatMessageUnsaved: AbstractMessage;
  chatMessageUnsavedWS: ChatMessageUnsavedPayload;
  /** @deprecated Use the subscriptions with WS suffix instead */
  chatMessageUpdated: Array<AbstractMessage>;
  chatMessageUpdatedWS: ChatMessageUpdatedResult;
  chatMessageWithTestTaskCreatedWS: AbstractMessageWs;
  chatParticipantEntered: Participant;
  chatParticipantStatusChanged: Participant;
  chatThreadReplyReadWS: ChatThreadReplyReadResult;
  chatUpdated: AbstractChat;
  courseUserTopicsChanges: CourseUserTopic;
  default?: Maybe<Scalars['Boolean']>;
  documentSessionCreated: UserDocument;
  eventLiveCodingStarted: EventLiveCodingResult;
  eventUpdated?: Maybe<Event>;
  gitHubCodeReviewProgress?: Maybe<GitHubCodeReviewProgress>;
  gitHubTaskStatusChange?: Maybe<GitHubAttempt>;
  googleEventHostUpdated?: Maybe<GoogleEventHost>;
  interviewCreated: Interview;
  interviewTimeBooked: CourseUserApplication;
  leaderboardUserLeaderbordUpdates: LeaderboardUser;
  leaderboardUserXPHistoryUpdates: LeaderboardUserXpHistoryUpdates;
  /** @deprecated Use leaderboardUserXPHistoryUpdates instead */
  leaderboardUserXPUpdates: LeaderboardUser;
  lmsContentChangesUpdates?: Maybe<Scalars['Boolean']>;
  messageReactionCreated: MessageReaction;
  messageReactionDeleted: MessageReaction;
  newNotification: AbstractNotification;
  notificationsUpdated: UserNotificationsForPagination;
  notifyUserReceivedOnboarding: NotifyUserReceivedOnboardingPayload;
  openQuestionAnswerRatedWS: OpenQuestionAnswerRatedResult;
  participantJoinedTechCheck: TechCheckParticipant;
  paymentUpdated?: Maybe<Payment>;
  peerReviewUpdated: PeerReview;
  reactionAddedToStack: MessageReactionStackPayload;
  reactionDeletedFromStack: MessageReactionStackPayload;
  reactionStackCreated: MessageReactionStackPayload;
  reactionStackDeleted: MessageReactionStackPayload;
  requestedStopAnsweringTechCheckQuestion: UserTechCheckAnswer;
  sandboxRunCodeFailed: SandboxRunCodeFailedResult;
  sandboxRunCodeSuccess: SandboxRunCodeSuccessResult;
  scheduleEventsAdded?: Maybe<Array<ScheduleEvent>>;
  scheduleEventsCreated: Array<EventAbstract>;
  scheduleEventsDeleted: Array<EventAbstract>;
  scheduleEventsUpdated: Array<EventAbstract>;
  soundNotificationSent: Scalars['Boolean'];
  studentsGroupHomeworkOverdueChanged: Array<HomeworkOverdue>;
  subscribedThreadUpdated: AbstractMessage;
  taskReviewCreated?: Maybe<TaskReview>;
  taskReviewExpired?: Maybe<Array<Maybe<TaskReview>>>;
  taskReviewRequested?: Maybe<TaskReview>;
  taskReviewed?: Maybe<TaskReview>;
  techCheckAnswerUpdated: UserTechCheckAnswer;
  testTaskAttemptsStatusChange: TestTaskAttempt;
  testTaskGroupDeadlineChange: GroupDeadline;
  testTaskLinksChanges: TestTaskLink;
  testTaskTemplateResultUpdate?: Maybe<TestTaskTemplateUpdateResult>;
  testTasksStatusChange?: Maybe<TaskStatusData>;
  threadReplyMentionCreatedWS: ThreadReplyMentionCreatedPayload;
  threadReplyMentionDeletedWS: ThreadReplyMentionDeletedPayload;
  threadReplyRead: ReadThreadReplyResult;
  typingSpeedTestSubmitted: TypingSpeedTest;
  unreadChatMessageDeletedWS: UnreadChatMessageDeletedResult;
  unreadThreadReplyDeletedWS: UnreadChatMessageDeletedResult;
  userApplyingStatisticChanged: ApplyingStatistics;
  userAskedQnAQuestion: EventQuestion;
  userDeletedQnAQuestion: EventQuestion;
  userEmailConfirmed?: Maybe<User>;
  userEventAttended?: Maybe<UserEvent>;
  userEventCanceled?: Maybe<UserEvent>;
  userEventCreated?: Maybe<UserEvent>;
  userHomeworkStatisticChanged: HomeworkStatistics;
  userJoinedScheduleEvent: UserEvent;
  userMorningTasksStatisticChanged: LearnStatistics;
  userOnline?: Maybe<Scalars['ID']>;
  userStreakUpdated: UserStreak;
  userStreaksUpdated: Array<UserStreak>;
  userTestTaskAttemptStatusUpdate: TestTaskAttempt;
  userUpdatedQnAQuestionStatus: EventQuestion;
  userVacancyChanged: UserVacancy;
};


export type SubscriptionAchievementUnlockedArgs = {
  userId: Scalars['Int'];
};


export type SubscriptionApprovedConnectionArgs = {
  id: Scalars['Int'];
};


export type SubscriptionAskedTechCheckQuestionArgs = {
  techCheckLinkId: Scalars['Int'];
};


export type SubscriptionAttachmentTaskReviewRequestedArgs = {
  testTaskAttemptId: Scalars['Int'];
};


export type SubscriptionAttachmentTaskReviewedArgs = {
  testTaskAttemptId: Scalars['Int'];
};


export type SubscriptionAttemptBuildUpdateArgs = {
  id: Scalars['Int'];
};


export type SubscriptionAttemptTestResultsUpdateArgs = {
  id: Scalars['Int'];
};


export type SubscriptionAttemptsChangesByLinkArgs = {
  testTaskLinkIds: Array<Scalars['Int']>;
};


export type SubscriptionChatMessageCreatedArgs = {
  chatIds?: Maybe<Array<Scalars['Int']>>;
};


export type SubscriptionChatMessageDeletedArgs = {
  chatIds?: Maybe<Array<Scalars['Int']>>;
};


export type SubscriptionChatMessageUpdatedArgs = {
  chatIds?: Maybe<Array<Scalars['Int']>>;
};


export type SubscriptionChatMessageWithTestTaskCreatedWsArgs = {
  testTaskId: Scalars['Int'];
};


export type SubscriptionChatParticipantEnteredArgs = {
  chatId?: Maybe<Scalars['Int']>;
};


export type SubscriptionChatParticipantStatusChangedArgs = {
  chatId?: Maybe<Scalars['Int']>;
};


export type SubscriptionCourseUserTopicsChangesArgs = {
  courseUserTopicIds: Array<Scalars['Int']>;
};


export type SubscriptionDocumentSessionCreatedArgs = {
  documentType: DocumentTypes;
};


export type SubscriptionEventLiveCodingStartedArgs = {
  eventId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type SubscriptionEventUpdatedArgs = {
  level?: Maybe<EventLevels>;
  type?: Maybe<EventTypes>;
};


export type SubscriptionGitHubCodeReviewProgressArgs = {
  prId: Scalars['String'];
  reviewId: Scalars['String'];
};


export type SubscriptionGitHubTaskStatusChangeArgs = {
  id: Scalars['Int'];
};


export type SubscriptionGoogleEventHostUpdatedArgs = {
  courseIds: Array<Scalars['Int']>;
};


export type SubscriptionInterviewCreatedArgs = {
  courseUserId: Scalars['Int'];
};


export type SubscriptionInterviewTimeBookedArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type SubscriptionLeaderboardUserLeaderbordUpdatesArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type SubscriptionLeaderboardUserXpHistoryUpdatesArgs = {
  userId?: Maybe<Scalars['Int']>;
  divisionId?: Maybe<Scalars['Int']>;
};


export type SubscriptionLeaderboardUserXpUpdatesArgs = {
  userId?: Maybe<Scalars['Int']>;
  divisionId?: Maybe<Scalars['Int']>;
};


export type SubscriptionLmsContentChangesUpdatesArgs = {
  instanceId?: Maybe<Scalars['Int']>;
  typeOfInstance?: Maybe<LmsContentChangesInstances>;
};


export type SubscriptionNewNotificationArgs = {
  userId: Scalars['Int'];
};


export type SubscriptionNotificationsUpdatedArgs = {
  userId: Scalars['Int'];
};


export type SubscriptionNotifyUserReceivedOnboardingArgs = {
  userId: Scalars['Int'];
};


export type SubscriptionParticipantJoinedTechCheckArgs = {
  techCheckLinkId: Scalars['Int'];
};


export type SubscriptionPaymentUpdatedArgs = {
  uuid?: Maybe<Scalars['String']>;
};


export type SubscriptionPeerReviewUpdatedArgs = {
  testTaskAttemptId: Scalars['Int'];
};


export type SubscriptionRequestedStopAnsweringTechCheckQuestionArgs = {
  answerId: Scalars['Int'];
};


export type SubscriptionSandboxRunCodeFailedArgs = {
  sandboxId: Scalars['Int'];
  runId?: Maybe<Scalars['String']>;
};


export type SubscriptionSandboxRunCodeSuccessArgs = {
  sandboxId: Scalars['Int'];
  runId?: Maybe<Scalars['String']>;
};


export type SubscriptionScheduleEventsAddedArgs = {
  studentsGroupId: Scalars['Int'];
};


export type SubscriptionScheduleEventsCreatedArgs = {
  groupId: Scalars['Int'];
};


export type SubscriptionScheduleEventsDeletedArgs = {
  groupId: Scalars['Int'];
};


export type SubscriptionScheduleEventsUpdatedArgs = {
  groupId: Scalars['Int'];
};


export type SubscriptionStudentsGroupHomeworkOverdueChangedArgs = {
  studentsGroupId: Scalars['Int'];
};


export type SubscriptionTaskReviewCreatedArgs = {
  testTaskAttemptId: Scalars['Int'];
};


export type SubscriptionTaskReviewExpiredArgs = {
  id: Scalars['Int'];
};


export type SubscriptionTaskReviewRequestedArgs = {
  userIds: Array<Scalars['Int']>;
};


export type SubscriptionTaskReviewedArgs = {
  userIds: Array<Scalars['Int']>;
};


export type SubscriptionTechCheckAnswerUpdatedArgs = {
  techCheckLinkId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};


export type SubscriptionTestTaskAttemptsStatusChangeArgs = {
  testTaskAttemptIds: Array<Scalars['Int']>;
};


export type SubscriptionTestTaskGroupDeadlineChangeArgs = {
  studentsGroupId: Scalars['Int'];
  testTaskIds: Array<Scalars['Int']>;
};


export type SubscriptionTestTaskLinksChangesArgs = {
  testTaskLinkIds: Array<Scalars['Int']>;
};


export type SubscriptionTestTaskTemplateResultUpdateArgs = {
  testTaskId: Scalars['Int'];
};


export type SubscriptionTestTasksStatusChangeArgs = {
  testTaskLinkId: Scalars['Int'];
};


export type SubscriptionTypingSpeedTestSubmittedArgs = {
  userIds: Array<Scalars['Int']>;
};


export type SubscriptionUserApplyingStatisticChangedArgs = {
  userIds: Array<Scalars['Int']>;
};


export type SubscriptionUserAskedQnAQuestionArgs = {
  eventId: Scalars['Int'];
};


export type SubscriptionUserDeletedQnAQuestionArgs = {
  eventId: Scalars['Int'];
};


export type SubscriptionUserEmailConfirmedArgs = {
  id: Scalars['Int'];
};


export type SubscriptionUserEventAttendedArgs = {
  eventType?: Maybe<EventTypes>;
  usersIds?: Maybe<Array<Scalars['Int']>>;
  eventsIds?: Maybe<Array<Scalars['Int']>>;
};


export type SubscriptionUserEventCanceledArgs = {
  eventType?: Maybe<EventTypes>;
  usersIds?: Maybe<Array<Scalars['Int']>>;
  eventsIds?: Maybe<Array<Scalars['Int']>>;
};


export type SubscriptionUserEventCreatedArgs = {
  eventType?: Maybe<EventTypes>;
  usersIds?: Maybe<Array<Scalars['Int']>>;
  eventsIds?: Maybe<Array<Scalars['Int']>>;
};


export type SubscriptionUserHomeworkStatisticChangedArgs = {
  userIds: Array<Scalars['Int']>;
};


export type SubscriptionUserJoinedScheduleEventArgs = {
  eventId: Scalars['Int'];
};


export type SubscriptionUserMorningTasksStatisticChangedArgs = {
  userIds: Array<Scalars['Int']>;
};


export type SubscriptionUserStreakUpdatedArgs = {
  userId: Scalars['Int'];
};


export type SubscriptionUserStreaksUpdatedArgs = {
  userId: Scalars['Int'];
};


export type SubscriptionUserTestTaskAttemptStatusUpdateArgs = {
  userIds: Array<Scalars['Int']>;
  testTaskIds: Array<Scalars['Int']>;
};


export type SubscriptionUserUpdatedQnAQuestionStatusArgs = {
  eventId: Scalars['Int'];
};


export type SubscriptionUserVacancyChangedArgs = {
  userIds: Array<Scalars['Int']>;
};

export type AiChatMessageProcessedResponse = {
  __typename?: 'AiChatMessageProcessedResponse';
  wasAiMessageProcessed?: Maybe<Scalars['Boolean']>;
};

export type AiCompletion = {
  __typename?: 'AICompletion';
  id: Scalars['String'];
  completion: Scalars['String'];
  usage?: Maybe<AiUsageStatistics>;
};

export type AiUsageStatistics = {
  __typename?: 'AIUsageStatistics';
  requestLength?: Maybe<Scalars['Int']>;
  responseLength?: Maybe<Scalars['Int']>;
  totalLength?: Maybe<Scalars['Int']>;
};

export type AiCompletionInput = {
  prompt: Scalars['String'];
  maxResponseLength?: Maybe<Scalars['Int']>;
  randomnessFactor?: Maybe<Scalars['Float']>;
};

export type ApplicationStatus = {
  __typename?: 'ApplicationStatus';
  id: Scalars['Int'];
  name: ApplicationStatusName;
  available: Scalars['Boolean'];
  order: Scalars['Int'];
  active: Scalars['Boolean'];
  deadline?: Maybe<Scalars['Date']>;
  statusName?: Maybe<Scalars['String']>;
};


export type ApplicationStatusStatusNameArgs = {
  lang?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};

export enum ApplicationStatusName {
  New = 'NEW',
  Introduction = 'INTRODUCTION',
  LearningResources = 'LEARNING_RESOURCES',
  DoingTestTask = 'DOING_TEST_TASK',
  Interview = 'INTERVIEW',
  SigningAgreement = 'SIGNING_AGREEMENT',
  BecameStudent = 'BECAME_STUDENT',
  Rejected = 'REJECTED',
  NotInterested = 'NOT_INTERESTED',
  SigningCourseAct = 'SIGNING_COURSE_ACT',
  AdditionalInfo = 'ADDITIONAL_INFO'
}

export enum FunnelType {
  Default = 'DEFAULT',
  LightFunnel = 'LIGHT_FUNNEL'
}

export type AssignmentTask = {
  __typename?: 'AssignmentTask';
  id: Scalars['Int'];
  testTaskId: Scalars['Int'];
  courseId: Scalars['Int'];
  complexity: Scalars['Int'];
  testTask?: Maybe<TestTask>;
};

export type CreateAssignmentTaskInput = {
  testTaskId: Scalars['Int'];
  professionSlug: Scalars['String'];
  complexity: Scalars['Int'];
};

export type AutoTechCheck = {
  __typename?: 'AutoTechCheck';
  id: Scalars['Int'];
  studentId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  creationMethod?: Maybe<AutoTechCheckCreationMethod>;
  techCheckLink: TechCheckLink;
  courses: Array<Course>;
  coveredTopics: Array<TechCheckTopic>;
  questionPacks: Array<AutoTechCheckQuestionPack>;
  completedQuestionsCount: Scalars['Int'];
  totalQuestionsCount: Scalars['Int'];
  isFinished: Scalars['Boolean'];
  averageMark?: Maybe<Scalars['Float']>;
  languageId?: Maybe<Scalars['Int']>;
  language?: Maybe<Language>;
  template?: Maybe<TechCheckTemplate>;
  startedAt?: Maybe<Scalars['Date']>;
  finishedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type AutoTechCheckQuestionPack = {
  __typename?: 'AutoTechCheckQuestionPack';
  id: Scalars['Int'];
  question: TechCheckQuestion;
  answer?: Maybe<UserTechCheckAnswer>;
};

export type AutoTechCheckSelectionOptions = {
  __typename?: 'AutoTechCheckSelectionOptions';
  id: Scalars['String'];
  templates: Array<TechCheckTemplate>;
  unfinishedAutoTechChecks: Array<AutoTechCheck>;
};


export type AutoTechCheckSelectionOptionsTemplatesArgs = {
  courseId?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
};


export type AutoTechCheckSelectionOptionsUnfinishedAutoTechChecksArgs = {
  courseId?: Maybe<Scalars['Int']>;
};

export type CreateAutoTechCheckFromQuestionsValues = {
  questionsIds: Array<Scalars['Int']>;
  studentId?: Maybe<Scalars['Int']>;
};

export type CreateAutoTechCheckFromTemplateValues = {
  templateId: Scalars['Int'];
  studentId?: Maybe<Scalars['Int']>;
  languageName?: Maybe<Scalars['String']>;
};

export type AutoTechChecksByFiltersInput = {
  userIds?: Maybe<Array<Scalars['Int']>>;
  templateIds?: Maybe<Array<Scalars['Int']>>;
};

export enum AutoTechCheckCreationMethod {
  Student = 'STUDENT',
  Mentor = 'MENTOR',
  Link = 'LINK'
}

export type Bank = {
  __typename?: 'Bank';
  id: Scalars['Int'];
  slug: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
};

export type BankDetails = {
  __typename?: 'BankDetails';
  id: Scalars['Int'];
  slug: Scalars['String'];
  bankId?: Maybe<Scalars['Int']>;
  countryId?: Maybe<Scalars['Int']>;
};

export type BigCity = {
  __typename?: 'BigCity';
  id: Scalars['Int'];
  city?: Maybe<City>;
};

export type BillingDetails = {
  billingName: Scalars['String'];
  billingEmail: Scalars['String'];
  zipCode: Scalars['String'];
  cityName: Scalars['String'];
  countryName: Scalars['String'];
  mainAddress: Scalars['String'];
  googleCityId: Scalars['String'];
  additionalAddress?: Maybe<Scalars['String']>;
  identificationNumber?: Maybe<Scalars['String']>;
  entityNumber?: Maybe<Scalars['String']>;
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  zipCode: Scalars['String'];
  mainAddress: Scalars['String'];
  billingName?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['String']>;
  additionalAddress?: Maybe<Scalars['String']>;
  identificationNumber?: Maybe<Scalars['String']>;
  entityNumber?: Maybe<Scalars['String']>;
  city?: Maybe<City>;
  country?: Maybe<Country>;
};

export type CalendarTemplate = {
  __typename?: 'CalendarTemplate';
  id: Scalars['Int'];
  name: Scalars['String'];
  /** @deprecated Temporary fallback field, CLND-612 */
  courseId?: Maybe<Scalars['Int']>;
  /** @deprecated Temporary fallback field, CLND-612 */
  course?: Maybe<CalendarTemplateCourse>;
  creatorId: Scalars['Int'];
  creator: CalendarTemplateCreator;
};

export type CreateCalendarTemplateFields = {
  name: Scalars['String'];
  /** Deprecated temporary fallback option, CLND-612 */
  courseId?: Maybe<Scalars['Int']>;
};

/** Deprecated temporary fallback type, CLND-612 */
export type CalendarTemplateCourse = {
  __typename?: 'CalendarTemplateCourse';
  id: Scalars['Int'];
  nameShort?: Maybe<Scalars['String']>;
};

export type CalendarTemplateCreator = {
  __typename?: 'CalendarTemplateCreator';
  id: Scalars['Int'];
  fullName?: Maybe<Scalars['String']>;
};

export type Call = {
  __typename?: 'Call';
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  courseUserId?: Maybe<Scalars['Int']>;
  salesId?: Maybe<Scalars['Int']>;
  type: CallType;
  bpmCallId: Scalars['String'];
  startedAt: Scalars['Date'];
  endedAt: Scalars['Date'];
  duration: Scalars['Int'];
  conversationTime: Scalars['Int'];
  timeToConnect: Scalars['Int'];
};

export type CreateCallFromBpmValues = {
  bpmContactId?: Maybe<Scalars['String']>;
  bpmLeadId?: Maybe<Scalars['String']>;
  bpmSalesContactId?: Maybe<Scalars['String']>;
  bpmCallId: Scalars['String'];
  type: CallType;
  startedAt: Scalars['Date'];
  endedAt: Scalars['Date'];
  duration: Scalars['Int'];
  conversationTime: Scalars['Int'];
  timeToConnect: Scalars['Int'];
};

export enum CallType {
  Outgoing = 'OUTGOING',
  Incoming = 'INCOMING',
  NotDetermined = 'NOT_DETERMINED'
}

export type CandidatePosition = {
  __typename?: 'CandidatePosition';
  id: Scalars['Int'];
  slug: Scalars['String'];
};

export type PublicCandidateProfilesResult = {
  __typename?: 'PublicCandidateProfilesResult';
  rows: Array<CandidateProfile>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

export type UpdateCandidateProfileOptions = {
  positionId?: Maybe<Scalars['Int']>;
  status?: Maybe<ProfileStatus>;
  description?: Maybe<Scalars['String']>;
  expectations?: Maybe<Scalars['String']>;
  skillsIds?: Maybe<Array<Scalars['Int']>>;
  jobExperienceId?: Maybe<Scalars['Int']>;
  englishLevel?: Maybe<UserEnglishLevel>;
  cities?: Maybe<Array<CityInput>>;
  isRemoteWorker?: Maybe<Scalars['Boolean']>;
  instantStart?: Maybe<Scalars['Boolean']>;
  isOfficeWorker?: Maybe<Scalars['Boolean']>;
  readyToRelocate?: Maybe<Scalars['Boolean']>;
};

export type CityInput = {
  googleCityId: Scalars['String'];
  cityName: Scalars['String'];
};

export type CreateCandidateProfileOptions = {
  positionId: Scalars['Int'];
  description: Scalars['String'];
  expectations?: Maybe<Scalars['String']>;
  skillsIds: Array<Scalars['Int']>;
  jobExperienceId: Scalars['Int'];
  englishLevel: UserEnglishLevel;
  cities?: Maybe<Array<CityInput>>;
  isRemoteWorker?: Maybe<Scalars['Boolean']>;
  instantStart?: Maybe<Scalars['Boolean']>;
  isOfficeWorker?: Maybe<Scalars['Boolean']>;
  readyToRelocate?: Maybe<Scalars['Boolean']>;
};

export type CandidateProfile = {
  __typename?: 'CandidateProfile';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  status: ProfileStatus;
  positionId: Scalars['Int'];
  candidatePosition?: Maybe<CandidatePosition>;
  description: Scalars['String'];
  expectations?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Skill>>;
  englishLevel?: Maybe<CandidateProfileEnglishLevel>;
  jobExperienceId: Scalars['Int'];
  jobExperience?: Maybe<JobExperience>;
  user?: Maybe<User>;
  cities?: Maybe<Array<City>>;
  isRemoteWorker: Scalars['Boolean'];
  instantStart: Scalars['Boolean'];
  isOfficeWorker: Scalars['Boolean'];
  readyToRelocate: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  workPlaces?: Maybe<Array<CandidateProfileWorkPlace>>;
  cvUrl?: Maybe<Scalars['String']>;
  achievements?: Maybe<Array<UserAchievement>>;
};

export type PublicProfilesParameters = {
  citiesIds?: Maybe<Array<Scalars['String']>>;
  positionsIds?: Maybe<Array<Scalars['Int']>>;
  jobExperienceId?: Maybe<Scalars['Int']>;
  englishLevel?: Maybe<UserEnglishLevel>;
  skillsIds?: Maybe<Array<Scalars['Int']>>;
  isRemoteWorker?: Maybe<Scalars['Boolean']>;
  instantStart?: Maybe<Scalars['Boolean']>;
  isOfficeWorker?: Maybe<Scalars['Boolean']>;
  readyToRelocate?: Maybe<Scalars['Boolean']>;
};

export type CandidateProfileCity = {
  __typename?: 'CandidateProfileCity';
  id: Scalars['Int'];
  candidateProfileId: Scalars['Int'];
  cityId: Scalars['Int'];
};

export type CandidateProfileEnglishLevel = {
  __typename?: 'CandidateProfileEnglishLevel';
  id: Scalars['Int'];
  candidateProfileId: Scalars['Int'];
  value: UserEnglishLevel;
  level: Scalars['Int'];
};

export type CandidateProfileWorkPlace = {
  __typename?: 'CandidateProfileWorkPlace';
  id: Scalars['Int'];
  candidateProfileId: Scalars['Int'];
  companyName: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
};

export type CreateCandidateProfileWorkPlaceInput = {
  companyName: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
};

export type UpdateCandidateProfileWorkPlaceInput = {
  companyName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type Certificate = {
  __typename?: 'Certificate';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  courseId: Scalars['Int'];
  domainId: Scalars['Int'];
  relatedType: CertificateRelatedType;
  meta: CertificateMeta;
  PDFFile?: Maybe<File>;
  createdAt: Scalars['Date'];
};

export type CertificateMeta = {
  __typename?: 'CertificateMeta';
  solvedTasksCount?: Maybe<Scalars['Int']>;
};

export enum CertificateRelatedType {
  Module = 'MODULE',
  Skill = 'SKILL',
  Career = 'CAREER'
}

export type AbstractChat = {
  id: Scalars['Int'];
  name: Scalars['String'];
  kind: ChatKind;
  description?: Maybe<Scalars['String']>;
  lastActionTime?: Maybe<Scalars['Date']>;
  myParticipant?: Maybe<Participant>;
  chatBuddy?: Maybe<Participant>;
  participants: Array<Participant>;
  previewParticipants: Array<Participant>;
  participantsCount: Scalars['Int'];
  unreadMessagesCount?: Maybe<Scalars['Int']>;
  unreadMentionsCount: Scalars['Int'];
  lastSentMessage?: Maybe<AbstractMessage>;
  lastSentMessageId?: Maybe<Scalars['Int']>;
  accessType: ChatAccessType;
  config: ChatConfig;
  pinnedMessagesCount: Scalars['Int'];
  draft?: Maybe<DraftMessage>;
};

export type GroupChat = AbstractChat & {
  __typename?: 'GroupChat';
  id: Scalars['Int'];
  name: Scalars['String'];
  kind: ChatKind;
  description?: Maybe<Scalars['String']>;
  lastActionTime?: Maybe<Scalars['Date']>;
  myParticipant?: Maybe<Participant>;
  chatBuddy?: Maybe<Participant>;
  participants: Array<Participant>;
  previewParticipants: Array<Participant>;
  participantsCount: Scalars['Int'];
  unreadMessagesCount?: Maybe<Scalars['Int']>;
  unreadMentionsCount: Scalars['Int'];
  lastSentMessage?: Maybe<AbstractMessage>;
  lastSentMessageId?: Maybe<Scalars['Int']>;
  chatLesson?: Maybe<ScheduleEvent>;
  accessType: ChatAccessType;
  config: ChatConfig;
  pinnedMessagesCount: Scalars['Int'];
  draft?: Maybe<DraftMessage>;
};

export type PrivateChat = AbstractChat & {
  __typename?: 'PrivateChat';
  id: Scalars['Int'];
  name: Scalars['String'];
  kind: ChatKind;
  description?: Maybe<Scalars['String']>;
  lastActionTime?: Maybe<Scalars['Date']>;
  myParticipant?: Maybe<Participant>;
  chatBuddy?: Maybe<Participant>;
  participants: Array<Participant>;
  previewParticipants: Array<Participant>;
  participantsCount: Scalars['Int'];
  unreadMessagesCount?: Maybe<Scalars['Int']>;
  unreadMentionsCount: Scalars['Int'];
  lastSentMessage?: Maybe<AbstractMessage>;
  lastSentMessageId?: Maybe<Scalars['Int']>;
  chatConnection: ChatConnection;
  accessType: ChatAccessType;
  config: ChatConfig;
  pinnedMessagesCount: Scalars['Int'];
  draft?: Maybe<DraftMessage>;
};

export type ChatConfig = {
  __typename?: 'ChatConfig';
  isThreadsAvailable: Scalars['Boolean'];
  isGuidelinesTabAvailable: Scalars['Boolean'];
  isStatisticsTabAvailable: Scalars['Boolean'];
  allowedMessageTypes: Array<ChatConfigMessageType>;
  isCoachViewTogglingAvailable: Scalars['Boolean'];
  isInsertCodeButtonShown: Scalars['Boolean'];
  isNotAnsweredHintShown: Scalars['Boolean'];
  isHeaderShown: Scalars['Boolean'];
  shouldDisplayInputControls: Scalars['Boolean'];
  shouldDisplayMessageControls: Scalars['Boolean'];
  hasMentorSupport: Scalars['Boolean'];
  areGroupTagsDisabled: Scalars['Boolean'];
  shouldDisplayIsTyping: Scalars['Boolean'];
  shouldHideProfilePreview: Scalars['Boolean'];
  shouldDisplayQuickActions: Scalars['Boolean'];
  permissions: Array<ChatPermissionRoles>;
};

export type ChatPermissionRoles = {
  __typename?: 'ChatPermissionRoles';
  permission: Scalars['String'];
  roles: Array<ParticipantRole>;
};

export type FoundUserOrGroupEntries = {
  __typename?: 'FoundUserOrGroupEntries';
  users: Array<FoundUser>;
  groups: Array<FoundGroup>;
};

export type FoundUser = {
  __typename?: 'FoundUser';
  id: Scalars['Int'];
  fullName: Scalars['String'];
  avatar?: Maybe<File>;
  badgeType?: Maybe<UserAvatarBadgeType>;
};

export type FoundGroup = {
  __typename?: 'FoundGroup';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdateChatFields = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ChatPermissionRolesInput = {
  permission: Scalars['String'];
  roles: Array<ParticipantRole>;
};

export type ChatConfigInput = {
  hasMentorSupport?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<ChatPermissionRolesInput>>;
};

export enum ChatConfigMessageType {
  OpenQuestion = 'OPEN_QUESTION',
  Poll = 'POLL'
}

export enum ChatFilter {
  All = 'ALL',
  Unread = 'UNREAD',
  Favorite = 'FAVORITE',
  Archived = 'ARCHIVED'
}

export enum ChatType {
  PrivateChat = 'PrivateChat',
  GroupChat = 'GroupChat'
}

export enum ChatAccessType {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export enum ChatKind {
  Regular = 'REGULAR',
  Direct = 'DIRECT',
  Service = 'SERVICE',
  Lesson = 'LESSON'
}

export type ChatFilters = {
  isFavourite?: Maybe<Scalars['Boolean']>;
  kinds?: Maybe<Array<ChatKind>>;
};

export type RecruiterChatConnectionsResult = {
  __typename?: 'RecruiterChatConnectionsResult';
  rows: Array<ChatConnection>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

export type ChatConnection = {
  __typename?: 'ChatConnection';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  stage: ChatConnectionStages;
  status: ChatConnectionStatus;
  candidateReportedStatus?: Maybe<OfferStatus>;
  recruiterReportedStatus?: Maybe<OfferStatus>;
  candidateReportedAt?: Maybe<Scalars['Date']>;
  recruiterReportedAt?: Maybe<Scalars['Date']>;
  recruiterId: Scalars['Int'];
  recruiterUserId: Scalars['Int'];
  candidateId: Scalars['Int'];
  candidateUserId: Scalars['Int'];
  recruiterParticipant: Participant;
  candidateParticipant: Participant;
};

export enum ChatConnectionStages {
  Contact = 'CONTACT',
  HrInterview = 'HR_INTERVIEW',
  TestTask = 'TEST_TASK',
  TechInterview = 'TECH_INTERVIEW',
  FinalInterview = 'FINAL_INTERVIEW'
}

export type BatchReportOfferStatusOptions = {
  id: Scalars['Int'];
  status: OfferStatus;
};

export enum ChatConnectionStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export enum OfferStatus {
  Offer = 'OFFER',
  NoOffer = 'NO_OFFER'
}

export enum RecruiterChatConnectionsFilter {
  All = 'ALL',
  Active = 'ACTIVE',
  Hired = 'HIRED',
  Rejected = 'REJECTED',
  Favorite = 'FAVORITE'
}

export type ParticipantMeta = {
  __typename?: 'ParticipantMeta';
  id: Scalars['Int'];
  chatParticipantId: Scalars['Int'];
  archivedAt?: Maybe<Scalars['Date']>;
};

export type ChatGuideline = {
  __typename?: 'ChatGuideline';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  courseId?: Maybe<Scalars['Int']>;
  order: Scalars['Float'];
  name: Scalars['String'];
  participant?: Maybe<Participant>;
};

export type CreateGuidelineFields = {
  courseId: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdateGuidelineOrderFields = {
  id: Scalars['Int'];
  previousGuidelineId?: Maybe<Scalars['Int']>;
  nextGuidelineId?: Maybe<Scalars['Int']>;
};

export type AbstractGuidelineMessage = {
  id: Scalars['Int'];
  order: Scalars['Float'];
  chatId: Scalars['Int'];
  text: Scalars['String'];
  createdAt: Scalars['Date'];
};

export type GuidelineThread = AbstractGuidelineMessage & {
  __typename?: 'GuidelineThread';
  id: Scalars['Int'];
  order: Scalars['Float'];
  chatId: Scalars['Int'];
  text: Scalars['String'];
  createdAt: Scalars['Date'];
  media: Array<File>;
};

export type GuidelinePlainText = AbstractGuidelineMessage & {
  __typename?: 'GuidelinePlainText';
  id: Scalars['Int'];
  order: Scalars['Float'];
  chatId: Scalars['Int'];
  text: Scalars['String'];
  createdAt: Scalars['Date'];
  media: Array<File>;
};

export type GuidelineOpenQuestion = AbstractGuidelineMessage & {
  __typename?: 'GuidelineOpenQuestion';
  id: Scalars['Int'];
  order: Scalars['Float'];
  chatId: Scalars['Int'];
  text: Scalars['String'];
  showCorrectAnswers: Scalars['Boolean'];
  createdAt: Scalars['Date'];
};

export type GuidelinePoll = AbstractGuidelineMessage & {
  __typename?: 'GuidelinePoll';
  id: Scalars['Int'];
  order: Scalars['Float'];
  chatId: Scalars['Int'];
  text: Scalars['String'];
  options: Array<PollOption>;
  hasCorrect: Scalars['Boolean'];
  showAnswers: Scalars['Boolean'];
  createdAt: Scalars['Date'];
};

export type SendGuidelineMessageToChatFields = {
  messageId: Scalars['Int'];
  chatId: Scalars['Int'];
};

export type CreateGuidelineMessageFields = {
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  text: Scalars['String'];
  uploadFilesIds?: Maybe<Array<Scalars['Int']>>;
};

export type CreateGuidelineQuestionFields = {
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  text: Scalars['String'];
  showCorrectAnswers: Scalars['Boolean'];
};

export type CreateGuidelinePollFields = {
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  text: Scalars['String'];
  options: Array<PollOptionFields>;
};

export type UpdateGuidelineMessageOrderFields = {
  id: Scalars['Int'];
  previousMessageId?: Maybe<Scalars['Int']>;
  nextMessageId?: Maybe<Scalars['Int']>;
};

export type ChatMention = {
  __typename?: 'ChatMention';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  participantId?: Maybe<Scalars['Int']>;
  threadId?: Maybe<Scalars['Int']>;
  messageId: Scalars['Int'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
};

export type MentionCounter = {
  __typename?: 'MentionCounter';
  chatId: Scalars['Int'];
  count: Scalars['Int'];
};

export type ChatMentionCreatedPayload = {
  __typename?: 'ChatMentionCreatedPayload';
  chatId: Scalars['Int'];
  createdAt: Scalars['Date'];
};

export type ThreadReplyMentionCreatedPayload = {
  __typename?: 'ThreadReplyMentionCreatedPayload';
  chatId: Scalars['Int'];
  threadId: Scalars['Int'];
  createdAt: Scalars['Date'];
};

export type ChatMentionDeletedPayload = {
  __typename?: 'ChatMentionDeletedPayload';
  chatId: Scalars['Int'];
};

export type ThreadReplyMentionDeletedPayload = {
  __typename?: 'ThreadReplyMentionDeletedPayload';
  threadId: Scalars['Int'];
};

export type AbstractMessage = {
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  sender: Participant;
  text: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  chat: AbstractChat;
  isOwn: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
};

export type AbstractMessageWs = {
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  text: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isOwn: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
};

export type Thread = AbstractMessage & {
  __typename?: 'Thread';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  sender: Participant;
  text: Scalars['String'];
  messages: Array<Message>;
  repliesCount: Scalars['Int'];
  /** @deprecated Use lastReadTime instead */
  lastActionTime?: Maybe<Scalars['Date']>;
  lastReadTime?: Maybe<Scalars['Date']>;
  lastSentMessageTime?: Maybe<Scalars['Date']>;
  media: Array<File>;
  attachedTestTasks: Array<TestTask>;
  reactions: Array<MessageReaction>;
  reactionStacks: Array<MessageReactionStack>;
  chat: AbstractChat;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isOwn: Scalars['Boolean'];
  isUnread: Scalars['Boolean'];
  isSubscribed: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
  draft?: Maybe<DraftMessage>;
  sentStatus: ChatMessageSentStatus;
  receivedXp?: Maybe<Scalars['Int']>;
};

export type ThreadWs = AbstractMessageWs & {
  __typename?: 'ThreadWS';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  text: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isOwn: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
  repliesCount: Scalars['Int'];
  /** @deprecated Use lastReadTime instead */
  lastActionTime?: Maybe<Scalars['Date']>;
  lastReadTime?: Maybe<Scalars['Date']>;
  lastSentMessageTime?: Maybe<Scalars['Date']>;
  media: Array<File>;
  attachedTestTasks: Array<TestTask>;
  reactions: Array<MessageReaction>;
  reactionStacks: Array<MessageReactionStack>;
  draft?: Maybe<DraftMessageWs>;
};

export type Message = AbstractMessage & {
  __typename?: 'Message';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  threadId: Scalars['Int'];
  senderId: Scalars['Int'];
  sender: Participant;
  text: Scalars['String'];
  reactions: Array<MessageReaction>;
  reactionStacks: Array<MessageReactionStack>;
  chat: AbstractChat;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  systemMessageId?: Maybe<Scalars['Int']>;
  media: Array<File>;
  isOwn: Scalars['Boolean'];
  isUnread: Scalars['Boolean'];
  isSubscribed: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
  sentStatus: ChatMessageSentStatus;
};

export type MessageWs = AbstractMessageWs & {
  __typename?: 'MessageWS';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  threadId: Scalars['Int'];
  senderId: Scalars['Int'];
  text: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isOwn: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
  reactions: Array<MessageReaction>;
  reactionStacks: Array<MessageReactionStack>;
  media: Array<File>;
};

export type SystemMessage = AbstractMessage & {
  __typename?: 'SystemMessage';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  sender: Participant;
  text: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  systemMessageId: Scalars['Int'];
  systemMessage: ChatSystemMessage;
  chat: AbstractChat;
  isOwn: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
};

export type OpenQuestion = AbstractMessage & {
  __typename?: 'OpenQuestion';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  sender: Participant;
  text: Scalars['String'];
  answers: Array<OpenQuestionAnswer>;
  reactions: Array<MessageReaction>;
  reactionStacks: Array<MessageReactionStack>;
  /** @deprecated Use lastReadTime instead */
  lastActionTime?: Maybe<Scalars['Date']>;
  lastReadTime?: Maybe<Scalars['Date']>;
  showCorrectAnswers: Scalars['Boolean'];
  isPopular?: Maybe<Scalars['Boolean']>;
  chat: AbstractChat;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isOwn: Scalars['Boolean'];
  isUnread: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
};

export type OpenQuestionWs = AbstractMessageWs & {
  __typename?: 'OpenQuestionWS';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  text: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isOwn: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
  /** @deprecated Use lastReadTime instead */
  lastActionTime?: Maybe<Scalars['Date']>;
  lastReadTime?: Maybe<Scalars['Date']>;
  answers: Array<OpenQuestionAnswerWs>;
  reactions: Array<MessageReaction>;
  reactionStacks: Array<MessageReactionStack>;
  showCorrectAnswers: Scalars['Boolean'];
  isPopular?: Maybe<Scalars['Boolean']>;
};

export type OpenQuestionAnswer = AbstractMessage & {
  __typename?: 'OpenQuestionAnswer';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  threadId: Scalars['Int'];
  senderId: Scalars['Int'];
  sender: Participant;
  text: Scalars['String'];
  reactions: Array<MessageReaction>;
  reactionStacks: Array<MessageReactionStack>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  chat: AbstractChat;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isOwn: Scalars['Boolean'];
  isUnread: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
};

export type OpenQuestionAnswerWs = AbstractMessageWs & {
  __typename?: 'OpenQuestionAnswerWS';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  threadId: Scalars['Int'];
  senderId: Scalars['Int'];
  text: Scalars['String'];
  isCorrect?: Maybe<Scalars['Boolean']>;
  reactions: Array<MessageReaction>;
  reactionStacks: Array<MessageReactionStack>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isOwn: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
};

export type Poll = AbstractMessage & {
  __typename?: 'Poll';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  sender: Participant;
  text: Scalars['String'];
  options: Array<PollOption>;
  votes: Array<PollAnswer>;
  hasCorrect: Scalars['Boolean'];
  showAnswers: Scalars['Boolean'];
  reactions: Array<MessageReaction>;
  reactionStacks: Array<MessageReactionStack>;
  chat: AbstractChat;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isOwn: Scalars['Boolean'];
  isUnread: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
};

export type PollWs = AbstractMessageWs & {
  __typename?: 'PollWS';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  text: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isOwn: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
  options: Array<PollOption>;
  votes: Array<PollAnswerWs>;
  reactions: Array<MessageReaction>;
  reactionStacks: Array<MessageReactionStack>;
  hasCorrect: Scalars['Boolean'];
  showAnswers: Scalars['Boolean'];
};

export type PollAnswer = AbstractMessage & {
  __typename?: 'PollAnswer';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  threadId: Scalars['Int'];
  senderId: Scalars['Int'];
  sender: Participant;
  text: Scalars['String'];
  pollOptionId: Scalars['Int'];
  chat: AbstractChat;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isOwn: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
};

export type PollAnswerWs = AbstractMessageWs & {
  __typename?: 'PollAnswerWS';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  threadId: Scalars['Int'];
  pollOptionId: Scalars['Int'];
  text: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isOwn: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
};

export type DraftMessage = AbstractMessage & {
  __typename?: 'DraftMessage';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  threadId?: Maybe<Scalars['Int']>;
  senderId: Scalars['Int'];
  sender: Participant;
  text: Scalars['String'];
  sentStatus: ChatMessageSentStatus;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  chat: AbstractChat;
  isOwn: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
};

export type DraftMessageWs = AbstractMessageWs & {
  __typename?: 'DraftMessageWS';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  threadId?: Maybe<Scalars['Int']>;
  senderId: Scalars['Int'];
  text: Scalars['String'];
  sentStatus: ChatMessageSentStatus;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isOwn: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['Int']>;
  isSaved: Scalars['Boolean'];
};

export type PollOption = {
  __typename?: 'PollOption';
  id: Scalars['Int'];
  pollId: Scalars['Int'];
  text: Scalars['String'];
  isCorrect?: Maybe<Scalars['Boolean']>;
};

export type ChatMessageCreatedResult = {
  __typename?: 'ChatMessageCreatedResult';
  message: AbstractMessageWs;
  senderUserId: Scalars['Int'];
};

export type ChatMessageUpdatedFields = {
  __typename?: 'ChatMessageUpdatedFields';
  text: Scalars['String'];
};

export type ChatMessageUpdatedResult = {
  __typename?: 'ChatMessageUpdatedResult';
  messageId: Scalars['Int'];
  messageType: Scalars['String'];
  updatedFields: ChatMessageUpdatedFields;
  updatedAt: Scalars['Date'];
};

export type OpenQuestionAnswerRatedResult = {
  __typename?: 'OpenQuestionAnswerRatedResult';
  messageIds: Array<Scalars['Int']>;
  isCorrect: Scalars['Boolean'];
};

export type DeletedMessage = {
  __typename?: 'DeletedMessage';
  id: Scalars['Int'];
  type: Scalars['String'];
  chatId: Scalars['Int'];
  threadId?: Maybe<Scalars['Int']>;
};

export type ChatMessageDeletedResult = {
  __typename?: 'ChatMessageDeletedResult';
  deletedMessage: DeletedMessage;
  previousMessage?: Maybe<AbstractMessage>;
};

export type UnreadChatMessageDeletedResult = {
  __typename?: 'UnreadChatMessageDeletedResult';
  deletedMessage: DeletedMessage;
};

export type ReadChatMessageResult = {
  __typename?: 'ReadChatMessageResult';
  hasReadSuccessfully: Scalars['Boolean'];
  unreadChatMessagesCount: Scalars['Int'];
  hasUnreadChatMessages: Scalars['Boolean'];
  unreadChatMentionsCount: Scalars['Int'];
  message?: Maybe<AbstractMessage>;
  participantId?: Maybe<Scalars['Int']>;
};

export type ChatMessageReadResult = {
  __typename?: 'ChatMessageReadResult';
  participantId: Scalars['Int'];
  message: AbstractMessage;
  hasTotalUnreadMessages: Scalars['Boolean'];
  unreadTotalMentionsCount: Scalars['Int'];
  unreadChatMentionsCount: Scalars['Int'];
};

export type ReadThreadReplyResult = {
  __typename?: 'ReadThreadReplyResult';
  hasReadSuccessfully: Scalars['Boolean'];
  unreadThreadRepliesCount: Scalars['Int'];
  message?: Maybe<AbstractMessage>;
};

export type ChatThreadReplyReadResult = {
  __typename?: 'ChatThreadReplyReadResult';
  unreadThreadRepliesCount: Scalars['Int'];
  message: AbstractMessage;
};

export type ChatMessageUnpinnedPayload = {
  __typename?: 'ChatMessageUnpinnedPayload';
  messageId: Scalars['Int'];
  messageType: Scalars['String'];
  chatId: Scalars['Int'];
  unpinnedByUserId: Scalars['Int'];
};

export type ChatMessagesQueryResult = {
  __typename?: 'ChatMessagesQueryResult';
  messages: Array<AbstractMessage>;
  hasMoreOlderMessages: Scalars['Boolean'];
  hasMoreNewerMessages: Scalars['Boolean'];
};

export type BulkCreateChatMessagesResult = {
  __typename?: 'BulkCreateChatMessagesResult';
  chatIds: Array<Scalars['Int']>;
};

export type CreateMessageFields = {
  chatId: Scalars['Int'];
  threadId?: Maybe<Scalars['Int']>;
  senderId: Scalars['Int'];
  systemMessageId?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
  uploadFilesIds?: Maybe<Array<Scalars['Int']>>;
  attachedTestTasksIds?: Maybe<Array<Scalars['Int']>>;
};

export type BulkCreateChatMessagesFields = {
  text: Scalars['String'];
  chatIds: Array<Scalars['Int']>;
  uploadFilesIds?: Maybe<Array<Scalars['Int']>>;
  userId: Scalars['Int'];
};

export type OpenQuestionFields = {
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  text: Scalars['String'];
  showCorrectAnswers: Scalars['Boolean'];
};

export type OpenQuestionAnswerFields = {
  chatId: Scalars['Int'];
  threadId: Scalars['Int'];
  senderId: Scalars['Int'];
  text: Scalars['String'];
};

export type PollFields = {
  chatId: Scalars['Int'];
  senderId: Scalars['Int'];
  text: Scalars['String'];
  options: Array<PollOptionFields>;
  hasCorrect: Scalars['Boolean'];
  showAnswers: Scalars['Boolean'];
};

export type PollOptionFields = {
  text: Scalars['String'];
  isCorrect?: Maybe<Scalars['Boolean']>;
};

export type PollAnswerFields = {
  chatId: Scalars['Int'];
  threadId: Scalars['Int'];
  senderId: Scalars['Int'];
  pollOptionId: Scalars['Int'];
};

export type UpdateMessageFields = {
  id: Scalars['Int'];
  text: Scalars['String'];
};

export type SaveDraftChatMessageFields = {
  chatId: Scalars['Int'];
  threadId?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
};

export type DeleteDraftChatMessageFields = {
  chatId: Scalars['Int'];
  threadId?: Maybe<Scalars['Int']>;
};

export enum ChatMessageSentStatus {
  Sent = 'SENT',
  Draft = 'DRAFT',
  Scheduled = 'SCHEDULED'
}

export type MessageReaction = {
  __typename?: 'MessageReaction';
  id: Scalars['Int'];
  messageId: Scalars['Int'];
  senderId: Scalars['Int'];
  reactionStackId: Scalars['String'];
  /** @deprecated Affects performance, use authUserId from subscriptions instead. */
  sender: Participant;
  reactionShortcode: Scalars['String'];
  createdAt: Scalars['Date'];
};

export type MessageReactionStack = {
  __typename?: 'MessageReactionStack';
  id: Scalars['String'];
  count: Scalars['Int'];
  messageId: Scalars['Int'];
  reactionShortcode: Scalars['String'];
  authParticipantReacted: Scalars['Boolean'];
  createdAt: Scalars['Date'];
};

export type AddReactionResult = {
  __typename?: 'AddReactionResult';
  reactionStackId: Scalars['String'];
};

export type DeleteReactionResult = {
  __typename?: 'DeleteReactionResult';
  reactionStackId: Scalars['String'];
};

export type MessageReactionStackPayload = {
  __typename?: 'MessageReactionStackPayload';
  id: Scalars['String'];
  messageId: Scalars['Int'];
  reactionShortcode: Scalars['String'];
  senderUserId: Scalars['Int'];
  createdAt: Scalars['Date'];
};

export type ChatMessageTemplate = {
  __typename?: 'ChatMessageTemplate';
  id: Scalars['Int'];
  text: Scalars['String'];
  name: Scalars['String'];
};

export type CreateMessageTemplateFields = {
  text: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateMessageTemplateFields = {
  id: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Participant = {
  __typename?: 'Participant';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  userId: Scalars['Int'];
  lastActionTime?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  /** @deprecated Use username instead, cause it's protected with user setting */
  fullName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  avatar?: Maybe<File>;
  role: ParticipantRole;
  archivedAt?: Maybe<Scalars['Date']>;
  isFavourite: Scalars['Boolean'];
  profile?: Maybe<Profile>;
  status: ParticipantStatus;
  inactiveReason?: Maybe<ParticipantInactiveStatusReason>;
  activeStreakDaysCount: Scalars['Int'];
  leaderboardLeagueType?: Maybe<LeaderboardLeagueType>;
  notificationSettings?: Maybe<ChatNotificationSettings>;
  chat?: Maybe<AbstractChat>;
  visibilityType: ChatParticipantVisibilityType;
};

export type ParticipantMinimal = {
  __typename?: 'ParticipantMinimal';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  chatId: Scalars['Int'];
};

export type AddChatParticipantsFields = {
  chatId: Scalars['Int'];
  userIds?: Maybe<Array<Scalars['Int']>>;
  studentGroupIds?: Maybe<Array<Scalars['Int']>>;
  shouldAddToFavorites?: Maybe<Scalars['Boolean']>;
};

export type ChatNotificationSettingsInput = {
  desktop: ChatNotificationSettingValue;
  mobile?: Maybe<ChatNotificationSettingValue>;
};

export type ChatNotificationSettings = {
  __typename?: 'ChatNotificationSettings';
  desktop: ChatNotificationSettingValue;
  mobile?: Maybe<ChatNotificationSettingValue>;
};

export type AddUserToChatsResult = {
  __typename?: 'AddUserToChatsResult';
  newParticipantsCount: Scalars['Int'];
  userParticipants: Array<ParticipantMinimal>;
};

export type ParticipantFilters = {
  includeStatuses?: Maybe<Array<ParticipantStatus>>;
  chatAccessType?: Maybe<ChatAccessType>;
  withSettingsOnly?: Maybe<Scalars['Boolean']>;
};

export type Profile = CandidateProfile | RecruiterProfile;

export enum ParticipantRole {
  Admin = 'ADMIN',
  Student = 'STUDENT',
  Recruiter = 'RECRUITER'
}

export enum ParticipantStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Left = 'LEFT',
  Banned = 'BANNED'
}

export enum ParticipantInactiveStatusReason {
  Left = 'LEFT',
  Banned = 'BANNED',
  StoppedBeingStudent = 'STOPPED_BEING_STUDENT',
  TrialExpired = 'TRIAL_EXPIRED'
}

export enum ChatParticipantVisibilityType {
  Sidebar = 'SIDEBAR',
  Searchable = 'SEARCHABLE'
}

export type ChatSection = {
  __typename?: 'ChatSection';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  name: Scalars['String'];
  order: Scalars['Float'];
  type: ChatSectionType;
  chatIds: Array<Scalars['Int']>;
};

export enum ChatSectionType {
  Favorite = 'FAVORITE',
  Direct = 'DIRECT',
  Lesson = 'LESSON',
  All = 'ALL',
  Custom = 'CUSTOM'
}

export type SectionReorderDestination = {
  itemAboveId?: Maybe<Scalars['Int']>;
  itemBelowId?: Maybe<Scalars['Int']>;
};

export type ChatSystemMessage = {
  __typename?: 'ChatSystemMessage';
  id: Scalars['Int'];
  textCode: Scalars['String'];
  receiverTextCode?: Maybe<Scalars['String']>;
  type: ChatSystemMessageType;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export enum ChatSystemMessageType {
  Offer = 'OFFER',
  Reject = 'REJECT',
  Information = 'INFORMATION',
  SharedContacts = 'SHARED_CONTACTS',
  TeamProjectStarted = 'TEAM_PROJECT_STARTED'
}

export type UpdateCityValues = {
  bpmId?: Maybe<Scalars['String']>;
};

export type City = {
  __typename?: 'City';
  id: Scalars['Int'];
  regionId?: Maybe<Scalars['Int']>;
  countryId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  bpmId?: Maybe<Scalars['String']>;
  googleCityId?: Maybe<Scalars['String']>;
  googleName?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  country?: Maybe<Country>;
};

export type PredictedCity = {
  __typename?: 'PredictedCity';
  googleName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  googleCityId: Scalars['String'];
};

export type UpdateCompanyValues = {
  bpmCompanyId?: Maybe<Scalars['String']>;
  status?: Maybe<CompanyStatus>;
  relatedCompanyId?: Maybe<Scalars['Int']>;
  type?: Maybe<CompanyType>;
  banReasonPrivate?: Maybe<Scalars['String']>;
  banReasonPublic?: Maybe<Scalars['String']>;
  newVacancyNotificationType?: Maybe<NewVacancyNotificationType>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['Int'];
  name: Scalars['String'];
  logo?: Maybe<File>;
  status?: Maybe<CompanyStatus>;
  bpmCompanyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  relatedCompanyId?: Maybe<Scalars['Int']>;
  isProcessed?: Maybe<Scalars['Boolean']>;
  parentCompany?: Maybe<Company>;
  childCompanies: Array<Company>;
  similarCompanies: Array<Company>;
  type?: Maybe<CompanyType>;
  banReasonPrivate?: Maybe<Scalars['String']>;
  banReasonPublic?: Maybe<Scalars['String']>;
  limitedEmployeesMeta?: Maybe<LimitedEmployeesMeta>;
  newVacancyNotificationType?: Maybe<NewVacancyNotificationType>;
};


export type CompanyLimitedEmployeesMetaArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type LimitedEmployeesMeta = {
  __typename?: 'LimitedEmployeesMeta';
  rows: Array<EmployeeMeta>;
  count: Scalars['Int'];
};

export enum CompanyStatus {
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Approved = 'APPROVED'
}

export type CompaniesForReviewResult = {
  __typename?: 'CompaniesForReviewResult';
  companies: Array<Company>;
  cursor: Scalars['Int'];
  count: Scalars['Int'];
};

export enum CompanyType {
  Banned = 'BANNED',
  HiringPartner = 'HIRING_PARTNER',
  VacancyIgnored = 'VACANCY_IGNORED'
}

export type CompanyFilters = {
  status?: Maybe<CompanyStatus>;
  type?: Maybe<CompanyType>;
  name?: Maybe<Scalars['String']>;
  newVacancyNotificationType?: Maybe<NewVacancyNotificationType>;
};

export type MergeCompanyArgs = {
  newCompanyName?: Maybe<Scalars['String']>;
  parentCompanyId: Scalars['Int'];
  childCompanyId: Scalars['Int'];
};

export enum NewVacancyNotificationType {
  Disabled = 'DISABLED',
  HasMatesWorking = 'HAS_MATES_WORKING',
  Always = 'ALWAYS'
}

export type CreateCompanyFromBpmValues = {
  bpmCompanyId: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<CompanyStatus>;
  type?: Maybe<CompanyType>;
  relatedCompanyId?: Maybe<Scalars['Int']>;
};

export type ContentFile = {
  __typename?: 'ContentFile';
  id: Scalars['Int'];
  type: ContentFileType;
  path: Scalars['String'];
  lang: LanguageName;
  storageFile: StorageSimplifiedFile;
};

export enum ContentFileType {
  TestTaskDescription = 'TEST_TASK_DESCRIPTION',
  TopicUsefulLinks = 'TOPIC_USEFUL_LINKS',
  TopicTheory = 'TOPIC_THEORY'
}

export type StorageSimplifiedFile = {
  __typename?: 'StorageSimplifiedFile';
  content: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UpdateCountryValues = {
  bpmId?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['Int'];
  name: Scalars['String'];
  regions?: Maybe<Array<Maybe<Region>>>;
  cities?: Maybe<Array<Maybe<City>>>;
  bpmId?: Maybe<Scalars['String']>;
  googleName?: Maybe<Scalars['String']>;
  googleCountryId?: Maybe<Scalars['String']>;
};

export enum CourseStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum CourseType {
  Prepaid = 'PREPAID',
  Postpaid = 'POSTPAID',
  Module = 'MODULE',
  Marathon = 'MARATHON',
  Consultation = 'CONSULTATION'
}

export enum ModuleType {
  Regular = 'REGULAR',
  Employment = 'EMPLOYMENT',
  English = 'ENGLISH',
  TeamProject = 'TEAM_PROJECT'
}

export enum CourseAccessType {
  Free = 'FREE',
  Paid = 'PAID'
}

export enum SupportProviderName {
  Telegram = 'TELEGRAM',
  Slack = 'SLACK',
  Whatsapp = 'WHATSAPP',
  Facebook = 'FACEBOOK',
  PlatformChat = 'PLATFORM_CHAT'
}

export enum AssignmentFlow {
  Default = 'DEFAULT',
  LightFunnel = 'LIGHT_FUNNEL',
  GamifiedActivation = 'GAMIFIED_ACTIVATION'
}

export enum CourseComplexity {
  Beginner = 'BEGINNER',
  Medium = 'MEDIUM',
  Advanced = 'ADVANCED'
}

export enum CourseTag {
  Popular = 'POPULAR'
}

export type TemplateCreationItem = {
  existingItemId: Scalars['Int'];
  newItemSlug: Scalars['String'];
};

export type CreateModuleValues = {
  name?: Maybe<Scalars['String']>;
  ukranianModuleDescription?: Maybe<Scalars['String']>;
  russianModuleDescription?: Maybe<Scalars['String']>;
  englishModuleDescription?: Maybe<Scalars['String']>;
  polishModuleDescription?: Maybe<Scalars['String']>;
  description?: Maybe<TranslatesPackInput>;
  abbreviation?: Maybe<Scalars['String']>;
  topicsAllowedToSkip?: Maybe<Scalars['Int']>;
  config?: Maybe<TestTaskConfigInput>;
  slug: Scalars['String'];
  courseIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  moduleIcon?: Maybe<Scalars['Upload']>;
  languageId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  moduleType?: Maybe<ModuleType>;
  isHiddenByDefault?: Maybe<Scalars['Boolean']>;
  plannedReleaseDate?: Maybe<Scalars['Date']>;
  isPreviousModuleRequired?: Maybe<Scalars['Boolean']>;
  shouldSyncToCRM?: Maybe<Scalars['Boolean']>;
};

export type CourseModulePosition = {
  id: Scalars['Int'];
  position: Scalars['Int'];
};

export type CourseAccess = {
  __typename?: 'CourseAccess';
  id: Scalars['String'];
  isGranted: Scalars['Boolean'];
  blockedByDailyTasks: Scalars['Boolean'];
  blockedReason?: Maybe<CourseAccessBlockReason>;
};

export enum CourseAccessBlockReason {
  NotBought = 'NOT_BOUGHT',
  BlockedByDailyTasks = 'BLOCKED_BY_DAILY_TASKS',
  InsufficientProgress = 'INSUFFICIENT_PROGRESS',
  IsNotOnEmploymentStage = 'IS_NOT_ON_EMPLOYMENT_STAGE',
  LimitedAccessToBasicModules = 'LIMITED_ACCESS_TO_BASIC_MODULES'
}

export type DailyTasksCourseMeta = {
  __typename?: 'DailyTasksCourseMeta';
  requiredDailyTasksCount: Scalars['Int'];
  requiredDailyTasksCompleted: Scalars['Int'];
  optionalDailyTasksCount: Scalars['Int'];
  optionalDailyTasksCompleted: Scalars['Int'];
};

export type CourseMeta = {
  __typename?: 'CourseMeta';
  id: Scalars['String'];
  tasksCount?: Maybe<Scalars['Int']>;
  tasksCompleted?: Maybe<Scalars['Int']>;
  requiredTasksCount?: Maybe<Scalars['Int']>;
  requiredTasksCompleted?: Maybe<Scalars['Int']>;
  optionalTasksCount?: Maybe<Scalars['Int']>;
  optionalTasksCompleted?: Maybe<Scalars['Int']>;
  completionPercent?: Maybe<Scalars['Int']>;
  totalCompletionPercent?: Maybe<Scalars['Int']>;
  requiredCompletionPercent?: Maybe<Scalars['Int']>;
  optionalCompletionPercent?: Maybe<Scalars['Int']>;
  access: CourseAccess;
  isAllCompleted?: Maybe<Scalars['Boolean']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  dailyTasks?: Maybe<DailyTasksCourseMeta>;
  hasCertificate?: Maybe<Scalars['Boolean']>;
  certificatesCount?: Maybe<Scalars['Int']>;
};


export type CourseMetaAccessArgs = {
  parentCourseId?: Maybe<Scalars['Int']>;
};


export type CourseMetaDailyTasksArgs = {
  parentCourseId?: Maybe<Scalars['Int']>;
};

export type LearningPace = {
  __typename?: 'LearningPace';
  pace?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalTime?: Maybe<Scalars['Float']>;
};

export type Course = {
  __typename?: 'Course';
  id: Scalars['Int'];
  slug: Scalars['String'];
  relatedCourseSlug?: Maybe<Scalars['String']>;
  professionId?: Maybe<Scalars['Int']>;
  nameShort: Scalars['String'];
  nameFull?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  aboutCourse?: Maybe<Scalars['String']>;
  aboutCourseAllLanguages?: Maybe<Array<Maybe<Translate>>>;
  aboutProfessionHeadline: Scalars['String'];
  aboutProfessionText: Scalars['String'];
  coursePreparationDescription?: Maybe<Scalars['String']>;
  coursePreparationRequirements?: Maybe<Scalars['String']>;
  /** @deprecated not used, should be removed */
  schedule?: Maybe<Scalars['String']>;
  /** @deprecated use accessType instead */
  price?: Maybe<Scalars['Float']>;
  accessType: CourseAccessType;
  duration?: Maybe<Scalars['String']>;
  timeLeftToComplete?: Maybe<LearningPace>;
  learningPace?: Maybe<LearningPace>;
  /** @deprecated not used, should be removed */
  expectedSalary?: Maybe<Scalars['String']>;
  expectedAgreementIncome: Scalars['Int'];
  averageMonthlySalaryInTech: Array<Translate>;
  courseTeamMembers: Array<CourseTeamMember>;
  courseAuthor?: Maybe<CourseTeamMember>;
  courseAuthors: Array<CourseTeamMember>;
  bgImage?: Maybe<File>;
  /** @deprecated not used, should be removed */
  courseRequirements?: Maybe<Array<Maybe<CourseRequirement>>>;
  courseTechnologies: Array<CourseTechnology>;
  courseStudentsPortfolios: Array<CourseStudentsPortfolio>;
  courseTopics: Array<CourseTopic>;
  courseTopic?: Maybe<CourseTopic>;
  seo?: Maybe<CourseSeo>;
  promoVideoLink?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
  abbr?: Maybe<Scalars['String']>;
  type: CourseType;
  isActive?: Maybe<Scalars['Boolean']>;
  /** @deprecated not used, should be removed */
  currency?: Maybe<Currency>;
  tasksCount?: Maybe<Scalars['Int']>;
  /** @deprecated not used, should be removed */
  practiceHours?: Maybe<Scalars['Int']>;
  /** @deprecated not used, should be removed */
  mentorHours?: Maybe<Scalars['Int']>;
  /** @deprecated not used, should be removed */
  testAssignmentHours?: Maybe<Scalars['Int']>;
  /** @deprecated not used, should be removed */
  testAssignmentDurationInMinutes?: Maybe<Scalars['Int']>;
  preparationCourseId?: Maybe<Scalars['Int']>;
  preparationCourse?: Maybe<Course>;
  courseUser?: Maybe<CourseUser>;
  modules: Array<Course>;
  icon?: Maybe<File>;
  bpmCourseId?: Maybe<Scalars['String']>;
  meta: CourseMeta;
  sort?: Maybe<Scalars['Int']>;
  config?: Maybe<TestTaskConfig>;
  supportLink?: Maybe<Scalars['String']>;
  supportProviderName?: Maybe<SupportProviderName>;
  isStudying?: Maybe<Scalars['Boolean']>;
  support?: Maybe<Support>;
  releasedTopicCount?: Maybe<Scalars['Int']>;
  courseStartInfo?: Maybe<CourseStartInfo>;
  profession?: Maybe<Profession>;
  isRegistrationAvailable?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  language?: Maybe<Language>;
  courseAgreement?: Maybe<CourseAgreements>;
  minProgressForTechCheck?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  isSaved?: Maybe<Scalars['Boolean']>;
  domains?: Maybe<Array<Domain>>;
  status: CourseStatus;
  interceptionPopups?: Maybe<CourseInterceptionPopups>;
  parentCourses?: Maybe<Array<Course>>;
  allowedTopicsToSkipCount?: Maybe<Scalars['Int']>;
  achievements?: Maybe<Array<CourseAchievement>>;
  grantedPermissions: Array<GrantedPermission>;
  homeworkTasksCount: Scalars['Int'];
  assignmentTestConfig?: Maybe<CourseAssignmentTestConfig>;
  teamProjectUser?: Maybe<TeamProjectUser>;
  moduleType?: Maybe<ModuleType>;
  scheduleConfig?: Maybe<Scalars['JSON']>;
  isHiddenByDefault?: Maybe<Scalars['Boolean']>;
  plannedReleaseDate?: Maybe<Scalars['Date']>;
  isPreviousModuleRequired?: Maybe<Scalars['Boolean']>;
  assignmentFlow?: Maybe<AssignmentFlow>;
  shouldSyncToCRM?: Maybe<Scalars['Boolean']>;
  eventHost?: Maybe<GoogleEventHost>;
  parentCourseSlug?: Maybe<Scalars['String']>;
  tag?: Maybe<CourseTag>;
  complexity?: Maybe<CourseComplexity>;
  projectsCount?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Float']>;
  descriptionShort?: Maybe<Scalars['String']>;
  releasedModulesCount: Scalars['Int'];
  timeTotalToComplete: Scalars['String'];
};


export type CourseNameShortArgs = {
  lang?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};


export type CourseNameFullArgs = {
  lang?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};


export type CourseDescriptionArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseAboutCourseArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseAboutCourseAllLanguagesArgs = {
  langs?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type CourseAboutProfessionHeadlineArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseAboutProfessionTextArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseCoursePreparationDescriptionArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseCoursePreparationRequirementsArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseScheduleArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseDurationArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseExpectedAgreementIncomeArgs = {
  lang?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};


export type CourseAverageMonthlySalaryInTechArgs = {
  domain?: Maybe<Scalars['String']>;
};


export type CourseCourseAuthorArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseCourseAuthorsArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseCourseTopicsArgs = {
  areReleased?: Maybe<Scalars['Boolean']>;
  sortOrder?: Maybe<SortOrder>;
};


export type CourseCourseTopicArgs = {
  topicSlug: Scalars['String'];
};


export type CoursePreparationCourseArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseCourseUserArgs = {
  statuses?: Maybe<Array<CourseUserStatus>>;
};


export type CourseModulesArgs = {
  sortOrder?: Maybe<SortOrder>;
  isAllModules?: Maybe<Scalars['Boolean']>;
};


export type CourseIsStudyingArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type CourseSupportArgs = {
  domain?: Maybe<Scalars['String']>;
};


export type CoursePositionArgs = {
  parentCourseSlug?: Maybe<Scalars['String']>;
};


export type CourseIsSavedArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type CourseDescriptionShortArgs = {
  lang?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type CourseAssignmentTestConfigRecord = {
  __typename?: 'CourseAssignmentTestConfigRecord';
  complexity: Scalars['Int'];
  count: Scalars['Int'];
};

export type CourseAssignmentTestConfig = {
  __typename?: 'CourseAssignmentTestConfig';
  firstTry?: Maybe<Array<CourseAssignmentTestConfigRecord>>;
  retry?: Maybe<Array<CourseAssignmentTestConfigRecord>>;
};

export type Support = {
  __typename?: 'Support';
  id: Scalars['Int'];
  link: Scalars['String'];
  providerName: SupportProviderName;
};

export type PlacesLeft = {
  __typename?: 'PlacesLeft';
  id: Scalars['String'];
  courseName: Scalars['String'];
  placesLeft: Scalars['Int'];
};

export type CourseStartInfo = {
  __typename?: 'CourseStartInfo';
  id: Scalars['Int'];
  registrationCloseDate: Scalars['Date'];
  placesLeft: Scalars['Int'];
};

export type CourseAssignmentTestConfigRecordInput = {
  complexity: Scalars['Int'];
  count: Scalars['Int'];
};

export type CourseAssignmentTestConfigInput = {
  firstTry?: Maybe<Array<CourseAssignmentTestConfigRecordInput>>;
  retry?: Maybe<Array<CourseAssignmentTestConfigRecordInput>>;
};

export type AttachAchievementOptions = {
  courseId: Scalars['Int'];
  achievementId: Scalars['Int'];
  unlockAmount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type CourseAchievement = {
  __typename?: 'CourseAchievement';
  id: Scalars['Int'];
  courseId: Scalars['Int'];
  achievementId: Scalars['Int'];
  unlockAmount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type CourseAgreements = {
  __typename?: 'CourseAgreements';
  id: Scalars['Int'];
  courseId: Scalars['Int'];
  agreementPercentage?: Maybe<Scalars['Int']>;
  agreementMonths: Scalars['Int'];
  refundWeeks?: Maybe<Scalars['Int']>;
  agreementDoc?: Maybe<File>;
  paymentDueDate: Scalars['Int'];
  minimumIncome: Scalars['Int'];
  minimumIncomeCurrencyId: Scalars['Int'];
  minimumIncomeThreshold: Scalars['Int'];
};

export type CourseInterceptionPopups = {
  __typename?: 'CourseInterceptionPopups';
  id: Scalars['Int'];
  courseId: Scalars['Int'];
  requiredEnglishLevel: CourseUserEnglishLevel;
  groupAlmostRecruitedPopupEnabled?: Maybe<Scalars['Boolean']>;
  englishLevelPopupEnabled?: Maybe<Scalars['Boolean']>;
};

export type CourseModule = {
  __typename?: 'CourseModule';
  id: Scalars['Int'];
  parentCourseId?: Maybe<Scalars['Int']>;
  childCourseId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  course: Course;
};


export type CourseModuleCourseArgs = {
  lang: Scalars['String'];
};

export type UpdateCourseMonthPaymentValues = {
  bpmPaymentId?: Maybe<Scalars['String']>;
  monthPaidOffDate?: Maybe<Scalars['Date']>;
};

export type CmpUnpaidUser = {
  __typename?: 'CMPUnpaidUser';
  id: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  last_paid_date?: Maybe<Scalars['Date']>;
  payments_count: Scalars['Int'];
  agreement_months?: Maybe<Scalars['Int']>;
  uj_id?: Maybe<Scalars['Int']>;
  uj_job_title: Scalars['String'];
  uj_first_day: Scalars['Date'];
  uj_first_day_normalized: Scalars['Date'];
  uj_last_day?: Maybe<Scalars['Date']>;
  uj_last_day_normalized: Scalars['Date'];
  next_payment_date?: Maybe<Scalars['Date']>;
  agreement_payment_due_date?: Maybe<Scalars['Int']>;
  language: Scalars['String'];
};

export type CourseMonthPayment = {
  __typename?: 'CourseMonthPayment';
  id: Scalars['Int'];
  courseId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  amountCurrencyId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  amountInUsd?: Maybe<Scalars['Float']>;
  amountInEur?: Maybe<Scalars['Float']>;
  salaryCurrencyId?: Maybe<Scalars['Int']>;
  salary?: Maybe<Scalars['Float']>;
  salaryInUsd?: Maybe<Scalars['Float']>;
  salaryInEur?: Maybe<Scalars['Float']>;
  salaryDate?: Maybe<Scalars['Date']>;
  bpmPaymentId?: Maybe<Scalars['String']>;
  currenciesRateId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  skipped?: Maybe<Scalars['Boolean']>;
  skipReason?: Maybe<Scalars['String']>;
  monthPaidOffDate?: Maybe<Scalars['Date']>;
  orderItem?: Maybe<OrderItem>;
  salaryCurrency?: Maybe<Currency>;
  amountCurrency?: Maybe<Currency>;
  currencyRate?: Maybe<CurrenciesRate>;
  extraFeeAmount?: Maybe<Scalars['Float']>;
  extraFeeAmountInUsd?: Maybe<Scalars['Float']>;
  extraFeeAmountInEur?: Maybe<Scalars['Float']>;
  percentageFeeAmount?: Maybe<Scalars['Float']>;
  percentageFeeAmountInUsd?: Maybe<Scalars['Float']>;
  percentageFeeAmountInEur?: Maybe<Scalars['Float']>;
  feeDays?: Maybe<Scalars['Int']>;
  nbuDailyPolicyRate?: Maybe<Scalars['Float']>;
  user?: Maybe<User>;
  course?: Maybe<Course>;
};

export type NextMonthPaymentStatus = {
  __typename?: 'NextMonthPaymentStatus';
  percentageFeeAmount?: Maybe<Scalars['Float']>;
  nbuDailyPolicyRate?: Maybe<Scalars['Float']>;
  feeDays?: Maybe<Scalars['Int']>;
  extraFeeAmount?: Maybe<Scalars['Float']>;
  extraFeeCurrency?: Maybe<Currency>;
  nextMonthPaidOffDate?: Maybe<Scalars['Date']>;
  paymentDeadline?: Maybe<Scalars['Date']>;
  isSkipAvailable?: Maybe<Scalars['Boolean']>;
  canMakeNextPayment?: Maybe<Scalars['Boolean']>;
  makePaymentAvailableDate?: Maybe<Scalars['Date']>;
  minSalaryAmount?: Maybe<Scalars['Float']>;
  minSalaryCurrency?: Maybe<Currency>;
  isSalaryLimitSpecified?: Maybe<Scalars['Boolean']>;
  shouldSalaryBeLimited?: Maybe<Scalars['Boolean']>;
  shouldPayFee?: Maybe<Scalars['Boolean']>;
};

export type UserCourseMonthPaymentsOptions = {
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CourseRequirement = {
  __typename?: 'CourseRequirement';
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type CourseReview = {
  __typename?: 'CourseReview';
  id: Scalars['Int'];
  courseId?: Maybe<Scalars['Int']>;
  course?: Maybe<Course>;
  reviewId?: Maybe<Scalars['Int']>;
  review?: Maybe<Review>;
};

export type CourseSeo = {
  __typename?: 'CourseSeo';
  id: Scalars['Int'];
  isIndexable: Scalars['Boolean'];
  canonicalCourse?: Maybe<Course>;
};

export type CourseStudentsPortfolio = {
  __typename?: 'CourseStudentsPortfolio';
  id: Scalars['Int'];
  courseId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sourceLink: Scalars['String'];
  coverImage?: Maybe<File>;
  presentationImage?: Maybe<File>;
};

export type CourseSupport = {
  __typename?: 'CourseSupport';
  id: Scalars['Int'];
  course_id: Scalars['Int'];
  provider_name: Scalars['String'];
  link: Scalars['String'];
};

export type CourseTeamMember = {
  __typename?: 'CourseTeamMember';
  id: Scalars['Int'];
  courseId?: Maybe<Scalars['Int']>;
  teamMemberId?: Maybe<Scalars['Int']>;
  teamMember?: Maybe<TeamMember>;
  courseAuthorSlug?: Maybe<Scalars['String']>;
  authorOverHead?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  authorBio?: Maybe<Scalars['String']>;
  authorFacts: Array<Maybe<Scalars['String']>>;
};


export type CourseTeamMemberAuthorOverHeadArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseTeamMemberAuthorNameArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseTeamMemberAuthorBioArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseTeamMemberAuthorFactsArgs = {
  lang?: Maybe<Scalars['String']>;
};

export type CourseTechnology = {
  __typename?: 'CourseTechnology';
  id: Scalars['Int'];
  name: Scalars['String'];
  logo?: Maybe<File>;
  /** @deprecated use logo instead */
  logos: Array<File>;
};

export enum CourseTopicType {
  Free = 'FREE',
  Paid = 'PAID'
}

export enum CourseTopicMode {
  Regular = 'REGULAR',
  Exam = 'EXAM',
  Marathon = 'MARATHON',
  Introduction = 'INTRODUCTION'
}

export enum CourseTopicStatus {
  InProgress = 'IN_PROGRESS',
  OnReview = 'ON_REVIEW',
  Released = 'RELEASED'
}

export enum DeniedReason {
  NotBought = 'NOT_BOUGHT',
  NotReady = 'NOT_READY',
  NotCompletedPrevious = 'NOT_COMPLETED_PREVIOUS',
  BlockedByDailyTasks = 'BLOCKED_BY_DAILY_TASKS'
}

export enum CourseTopicContentType {
  Theory = 'theory',
  UsefulLinks = 'usefulLinks'
}

export type TopicAccess = {
  __typename?: 'TopicAccess';
  id: Scalars['String'];
  isGranted: Scalars['Boolean'];
  deniedReason?: Maybe<DeniedReason>;
};

export type TestTaskConfigInput = {
  isAIReviewEnabled?: Maybe<Scalars['Boolean']>;
  isSampleTestsVisible?: Maybe<Scalars['Boolean']>;
  isLinterEnabled?: Maybe<Scalars['Boolean']>;
  isLinterBlocker?: Maybe<Scalars['Boolean']>;
  hasTests?: Maybe<Scalars['Boolean']>;
  isOptional?: Maybe<Scalars['Boolean']>;
  timeToSolve?: Maybe<Scalars['Int']>;
  isAutocompleteEnabled?: Maybe<Scalars['Boolean']>;
  isOpenExam?: Maybe<Scalars['Boolean']>;
  notRequiresReview?: Maybe<Scalars['Boolean']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  isRefSolutionVisible?: Maybe<Scalars['Boolean']>;
  isFileTreeVisible?: Maybe<Scalars['Boolean']>;
  tests?: Maybe<Array<Maybe<TestTaskTestInput>>>;
  hasChecklist?: Maybe<Scalars['Boolean']>;
  maxReviewIterationsCount?: Maybe<Scalars['Int']>;
  isMentorReviewOptional?: Maybe<Scalars['Boolean']>;
  peerReviewAvailable?: Maybe<Scalars['Boolean']>;
};

export type TopicMeta = {
  __typename?: 'TopicMeta';
  id: Scalars['String'];
  tasksCount?: Maybe<Scalars['Int']>;
  tasksCompleted?: Maybe<Scalars['Int']>;
  requiredTasksCount?: Maybe<Scalars['Int']>;
  requiredTasksCompleted?: Maybe<Scalars['Int']>;
  optionalTasksCount?: Maybe<Scalars['Int']>;
  optionalTasksCompleted?: Maybe<Scalars['Int']>;
  completionPercent: Scalars['Int'];
  totalCompletionPercent: Scalars['Int'];
  requiredCompletionPercent: Scalars['Int'];
  requiredCompletionPercentInTheNewestVersion?: Maybe<Scalars['Int']>;
  optionalCompletionPercent: Scalars['Int'];
  isCompleted: Scalars['Boolean'];
  hasReward: Scalars['Boolean'];
  access: TopicAccess;
  completionRatio: Scalars['Float'];
};


export type TopicMetaAccessArgs = {
  parentCourseId?: Maybe<Scalars['Int']>;
};

export type CourseTopic = {
  __typename?: 'CourseTopic';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  courseUserTopic?: Maybe<CourseUserTopic>;
  type?: Maybe<CourseTopicType>;
  isFree?: Maybe<Scalars['Boolean']>;
  status?: Maybe<CourseTopicStatus>;
  rating: Rating;
  meta: TopicMeta;
  config?: Maybe<TestTaskConfig>;
  theory: Scalars['String'];
  usefulLinks: Scalars['String'];
  videos: Array<CourseTopicVideo>;
  lmsEditorVideos: Array<CourseTopicVideo>;
  courseTopicTasks: Array<CourseTopicTask>;
  mode: CourseTopicMode;
  order: Scalars['Int'];
  course: Course;
  timeToSolve?: Maybe<Scalars['Int']>;
  durationOfTasks: Scalars['Int'];
  durationOfVideos: Scalars['Int'];
  learnItems: Array<CourseTopicLearnItem>;
  theoryFiles: Array<CourseTopicTheoryFile>;
};


export type CourseTopicNameArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseTopicDescriptionArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseTopicTheoryArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseTopicUsefulLinksArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type CourseTopicCourseTopicTasksArgs = {
  sortOrder?: Maybe<SortOrder>;
};


export type CourseTopicCourseArgs = {
  lang?: Maybe<Scalars['String']>;
};

export type UpdateCourseTopicInput = {
  id: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  isFree?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  timeToSolve?: Maybe<Scalars['Int']>;
};

export type CreateCourseTopicValues = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  mode: CourseTopicMode;
  timeToSolve?: Maybe<Scalars['Int']>;
  topicTheoryFiles?: Maybe<Array<TopicTheoryFile>>;
  config?: Maybe<TestTaskConfigInput>;
  slug: Scalars['String'];
  courseId: Scalars['Int'];
};

export type UpdateCourseTopicValues = {
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CourseTopicStatus>;
  courseId?: Maybe<Scalars['Int']>;
  mode?: Maybe<CourseTopicMode>;
  timeToSolve?: Maybe<Scalars['Int']>;
  config?: Maybe<TestTaskConfigInput>;
};

export type TopicTheoryFile = {
  path: Scalars['String'];
  content: Scalars['String'];
  sha?: Maybe<Scalars['String']>;
  type: CourseTopicContentType;
  lang: LanguageName;
  courseTopicTheoryFileId?: Maybe<Scalars['Int']>;
};

export type TopicDuration = {
  __typename?: 'TopicDuration';
  topicId: Scalars['Int'];
  duration: Scalars['Int'];
};

export type TasksAndVideosDuration = {
  __typename?: 'TasksAndVideosDuration';
  id: Scalars['String'];
  tasksDurations: Scalars['Int'];
  videosDurations: Scalars['Int'];
};

export type LearnItem = CourseTopicTask | CourseTopicVideo | CourseTopicTheoryFile;

export type CourseTopicLearnItem = {
  id: Scalars['Int'];
  order: Scalars['Int'];
  item: LearnItem;
};

export type CourseTopicTaskLearnItem = CourseTopicLearnItem & {
  __typename?: 'CourseTopicTaskLearnItem';
  id: Scalars['Int'];
  order: Scalars['Int'];
  item: CourseTopicTask;
};

export type CourseTopicVideoLearnItem = CourseTopicLearnItem & {
  __typename?: 'CourseTopicVideoLearnItem';
  id: Scalars['Int'];
  order: Scalars['Int'];
  item: CourseTopicVideo;
  userAttempt?: Maybe<CourseTopicLearnItemAttempt>;
};

export type CourseTopicTheoryLearnItem = CourseTopicLearnItem & {
  __typename?: 'CourseTopicTheoryLearnItem';
  id: Scalars['Int'];
  order: Scalars['Int'];
  item: CourseTopicTheoryFile;
  userAttempt?: Maybe<CourseTopicLearnItemAttempt>;
};

export type UpdateCourseTopicLearnItemInput = {
  id: Scalars['Int'];
  order?: Maybe<Scalars['Int']>;
};

export type CourseTopicTask = {
  __typename?: 'CourseTopicTask';
  id: Scalars['Int'];
  courseTopicId: Scalars['Int'];
  testTaskId: Scalars['Int'];
  testTask: TestTask;
  order?: Maybe<Scalars['Int']>;
  config?: Maybe<TestTaskConfig>;
  deadline?: Maybe<Scalars['Date']>;
};


export type CourseTopicTaskDeadlineArgs = {
  studentsGroupId?: Maybe<Scalars['Int']>;
};

export type UpdatedCourseTopicTasksInput = {
  id: Scalars['Int'];
  order: Scalars['Int'];
};

export type CreateCourseTopicTasksInput = {
  courseTopicSlug: Scalars['String'];
  testTaskId: Scalars['Int'];
  config?: Maybe<TaskConfigInput>;
};

export type UpdateCourseTopicTaskInput = {
  config?: Maybe<TaskConfigInput>;
};

export type CreateCourseTopicTheoryFileInput = {
  courseTopicId: Scalars['Int'];
  fileName: Scalars['String'];
  filaNameTranslation?: Maybe<Scalars['String']>;
};

export type CourseTopicTheoryFile = {
  __typename?: 'CourseTopicTheoryFile';
  id: Scalars['Int'];
  content: Scalars['String'];
  fileName: Scalars['String'];
  readableName: Scalars['String'];
  /** @deprecated Use readableName instead */
  nameCode?: Maybe<Scalars['String']>;
  contentFiles: Array<ContentFile>;
  courseTopic?: Maybe<CourseTopic>;
};


export type CourseTopicTheoryFileContentArgs = {
  lang?: Maybe<Scalars['String']>;
};

export type CourseTopicVideo = {
  __typename?: 'CourseTopicVideo';
  id: Scalars['Int'];
  courseTopicId: Scalars['Int'];
  learnVideoId?: Maybe<Scalars['Int']>;
  invidId?: Maybe<Scalars['Int']>;
  order: Scalars['Int'];
  learnVideo?: Maybe<LearnVideo>;
  lmsEditorLearnVideo?: Maybe<LearnVideo>;
  invid?: Maybe<Invid>;
};

export type CourseVideoOrder = {
  id: Scalars['Int'];
  order: Scalars['Int'];
};

export type CreateCourseTopicVideoValues = {
  title: Scalars['String'];
  vimeoUrl?: Maybe<Scalars['String']>;
};

export type MoveTopicVideoParams = {
  oldTopicId: Scalars['Int'];
  newTopicId: Scalars['Int'];
  courseTopicVideoId: Scalars['Int'];
};

export enum CourseUserStatus {
  New = 'new',
  Introduction = 'introduction',
  LearningResources = 'learningResources',
  DoingTestTask = 'doingTestTask',
  Interview = 'interview',
  SigningAgreement = 'signingAgreement',
  BecameStudent = 'becameStudent',
  Rejected = 'rejected',
  NotInterested = 'notInterested',
  Lost = 'lost',
  Presented = 'presented',
  Proforientation = 'proforientation',
  TryingToContact = 'tryingToContact',
  Payment = 'payment',
  Won = 'won',
  Disqualification = 'disqualification'
}

export enum CourseUserDisqualificationReason {
  EnglishLevel = 'englishLevel',
  NoAnswer = 'noAnswer',
  DidntFinishResources = 'didntFinishResources',
  DidntCompleteTestAssignment = 'didntCompleteTestAssignment',
  Other = 'other',
  TookOtherCourses = 'tookOtherCourses',
  Underage = 'underage',
  IncorrectData = 'incorrectData',
  FailedTestAssignment = 'failedTestAssignment',
  FailedLiveCoding = 'failedLiveCoding',
  CantFullTime = 'cantFullTime',
  NoOkWithOurModel = 'noOkWithOurModel',
  SoftSkills = 'softSkills',
  BlackList = 'blackList',
  OutsideOfKyiv = 'outsideOfKyiv',
  HasntFilledProfile = 'hasntFilledProfile',
  EnglishInterview = 'englishInterview',
  BecameParttimeStudent = 'becameParttimeStudent',
  NiIncorrectNumber = 'NIIncorrectNumber',
  NiLanguageBarrier = 'NILanguageBarrier',
  DuplicateContact = 'duplicateContact',
  NiftLead = 'NIFTLead',
  NifxLead = 'NIFXLead',
  NiSponsorship = 'NISponsorship',
  NiSchoolmate = 'NISchoolmate',
  NiUnreachable = 'NIUnreachable',
  AccidentalRequest = 'accidentalRequest',
  Expat = 'expat',
  NotABeginner = 'notABeginner',
  PtCurrentStudent = 'PTCurrentStudent',
  BaseDiscrepancy = 'baseDiscrepancy',
  FxWarmUp = 'FXWarmUp',
  FxOtherGeo = 'FXOtherGeo',
  FxSubscriptionUser = 'FXSubscriptionUser',
  FxAccidentalRequest = 'FXAccidentalRequest',
  FxIncorrectRequest = 'FXIncorrectRequest',
  FxUnreachableAfterProforientation = 'FXUnreachableAfterProforientation'
}

export enum CourseUserRejectionReason {
  NiNoBudget = 'NI_NO_BUDGET',
  NiCompetitor = 'NI_COMPETITOR',
  NiPotential = 'NI_POTENTIAL',
  NiDisappeared = 'NI_DISAPPEARED',
  NiNoToIt = 'NI_NO_TO_IT',
  NiSelfstudy = 'NI_SELFSTUDY',
  NiExpensive = 'NI_EXPENSIVE',
  NiMilitary = 'NI_MILITARY'
}

export enum CourseUserSurveyStatus {
  New = 'new',
  Info = 'info',
  Skills = 'skills',
  Complete = 'complete'
}

export enum CourseUserEnglishLevel {
  EnglishZero = 'english_zero',
  EnglishBeginner = 'english_beginner',
  EnglishIntermediate = 'english_intermediate',
  EnglishAdvanced = 'english_advanced'
}

export enum CourseUserNeedMaturity {
  NoContact = 'NO_CONTACT',
  NoAnswer = 'NO_ANSWER',
  IncorrectNumber = 'INCORRECT_NUMBER',
  CallBack = 'CALL_BACK',
  Presented = 'PRESENTED',
  Qualified = 'QUALIFIED',
  SuperInterested = 'SUPER_INTERESTED',
  PriceLinkSent = 'PRICE_LINK_SENT',
  Bought = 'BOUGHT',
  NotInterested = 'NOT_INTERESTED',
  PartialPayment = 'PARTIAL_PAYMENT',
  Unqualified = 'UNQUALIFIED',
  LanguageBarrier = 'LANGUAGE_BARRIER',
  NiExpensive = 'NI_EXPENSIVE',
  NiNoBudget = 'NI_NO_BUDGET',
  NiCompetitor = 'NI_COMPETITOR',
  NiSelfstudy = 'NI_SELFSTUDY',
  NiLanguageBarrier = 'NI_LANGUAGE_BARRIER',
  NiPotential = 'NI_POTENTIAL',
  NiFtLead = 'NI_FT_LEAD',
  NiDisappeared = 'NI_DISAPPEARED',
  NiUnreachable = 'NI_UNREACHABLE',
  NiNoToIt = 'NI_NO_TO_IT',
  NiSponsorship = 'NI_SPONSORSHIP',
  NiIncorrectNumber = 'NI_INCORRECT_NUMBER',
  NiUnqualified = 'NI_UNQUALIFIED',
  DuplicateContact = 'DUPLICATE_CONTACT',
  NiSchoolmate = 'NI_SCHOOLMATE',
  Refund = 'REFUND'
}

export enum StudentSource {
  MaStudentReferral = 'MA_STUDENT_REFERRAL',
  NonStudentReferral = 'NON_STUDENT_REFERRAL',
  Affiliate = 'AFFILIATE',
  GoogleSearch = 'GOOGLE_SEARCH',
  OnlineBanner = 'ONLINE_BANNER',
  Tiktok = 'TIKTOK',
  Dou = 'DOU',
  FacebookInstagram = 'FACEBOOK_INSTAGRAM',
  PublicRelations = 'PUBLIC_RELATIONS',
  Other = 'OTHER',
  DontRemember = 'DONT_REMEMBER',
  Webinar = 'WEBINAR',
  FromFt = 'FROM_FT',
  FromFx = 'FROM_FX',
  Trial = 'TRIAL',
  Whatsapp = 'WHATSAPP',
  ManyChat = 'MANY_CHAT',
  Call = 'CALL',
  SeoCourseProgress = 'SEO_COURSE_PROGRESS',
  Youtube = 'YOUTUBE'
}

export enum RegistrationMethod {
  CreatedAutomatically = 'CREATED_AUTOMATICALLY',
  IncomingCallOrEmail = 'INCOMING_CALL_OR_EMAIL',
  LandingPage = 'LANDING_PAGE',
  AddedManually = 'ADDED_MANUALLY',
  Case = 'CASE',
  AddedFromEvent = 'ADDED_FROM_EVENT',
  Cusbo = 'CUSBO',
  FromBase = 'FROM_BASE'
}

export enum LeadScoreType {
  Hot = 'HOT',
  Warm = 'WARM',
  Blank = 'BLANK'
}

export type ProcessConsultationRequestResponse = {
  __typename?: 'ProcessConsultationRequestResponse';
  user?: Maybe<User>;
  courseUser?: Maybe<CourseUser>;
  conversionValue?: Maybe<Scalars['Float']>;
};

export type ProcessApplicationRequestResponse = {
  __typename?: 'ProcessApplicationRequestResponse';
  courseUser?: Maybe<CourseUser>;
  conversionValue?: Maybe<Scalars['Float']>;
};

export type CourseUser = {
  __typename?: 'CourseUser';
  id: Scalars['Int'];
  courseId: Scalars['Int'];
  userId: Scalars['Int'];
  workCityId?: Maybe<Scalars['Int']>;
  status?: Maybe<CourseUserStatus>;
  interviewCalendlyEventId?: Maybe<Scalars['String']>;
  testTaskPreviewLinkId: Scalars['Int'];
  englishLevel?: Maybe<CourseUserEnglishLevel>;
  techLevel?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  bpmLeadId?: Maybe<Scalars['String']>;
  commitmentPerWeek?: Maybe<Scalars['String']>;
  distractions?: Maybe<Scalars['String']>;
  moneySource?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  course: Course;
  workCity?: Maybe<City>;
  /** @deprecated it is not used anymore */
  surveyStatus?: Maybe<CourseUserSurveyStatus>;
  learningStartedAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  testStartedAt?: Maybe<Scalars['Date']>;
  testFinishedAt?: Maybe<Scalars['Date']>;
  testDeadline?: Maybe<Scalars['Date']>;
  testSolution?: Maybe<Scalars['String']>;
  testCompletionRate?: Maybe<Scalars['Float']>;
  rejectedDate?: Maybe<Scalars['Date']>;
  hasTestAssignment?: Maybe<Scalars['Boolean']>;
  disqualificationReason?: Maybe<CourseUserDisqualificationReason>;
  rejectionReason?: Maybe<CourseUserRejectionReason>;
  needMaturity?: Maybe<CourseUserNeedMaturity>;
  studentsSource?: Maybe<StudentSource>;
  registrationMethod?: Maybe<RegistrationMethod>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPreparation?: Maybe<Scalars['Boolean']>;
  isPreparationBlockVisible: Scalars['Boolean'];
  isApplicationBlockVisible?: Maybe<Scalars['Boolean']>;
  isItGeneration?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<CourseMeta>;
  leadScore?: Maybe<Scalars['Int']>;
  courseUserTopic?: Maybe<CourseUserTopic>;
  courseUserTopics: Array<CourseUserTopic>;
  consultationRequestTime?: Maybe<Scalars['Date']>;
  nextTryDate?: Maybe<Scalars['Date']>;
  consultationRequestSource?: Maybe<Scalars['String']>;
  isReadyToEmployment: Scalars['Boolean'];
  courseUserApplication?: Maybe<CourseUserApplication>;
  courseUserOnboardings?: Maybe<Array<Maybe<UserOnboarding>>>;
  isLightFunnel?: Maybe<Scalars['Boolean']>;
  usedPromoCodeInfo?: Maybe<PromoCode>;
  /** @deprecated use leadScoreType instead */
  analyticsLeadScore?: Maybe<LeadScoreType>;
  leadScoreType?: Maybe<LeadScoreType>;
  utmTags?: Maybe<UtmTags>;
  interviewDate?: Maybe<Scalars['Date']>;
  interviewerId?: Maybe<Scalars['Int']>;
};


export type CourseUserCourseUserTopicArgs = {
  courseTopicSlug?: Maybe<Scalars['String']>;
};

export type TryCourseData = {
  __typename?: 'TryCourseData';
  courseUserId?: Maybe<Scalars['Int']>;
  freeTopicSlug?: Maybe<Scalars['String']>;
  courseSlug: Scalars['String'];
};

export type CourseUserValues = {
  bpmLeadId?: Maybe<Scalars['String']>;
  status?: Maybe<CourseUserStatus>;
  disqualificationReason?: Maybe<CourseUserDisqualificationReason>;
  rejectionReason?: Maybe<CourseUserRejectionReason>;
  needMaturity?: Maybe<CourseUserNeedMaturity>;
  studentsSource?: Maybe<StudentSource>;
  nextTryDate?: Maybe<Scalars['Date']>;
  registrationMethod?: Maybe<RegistrationMethod>;
  interviewDate?: Maybe<Scalars['Date']>;
  interviewerId?: Maybe<Scalars['Int']>;
};

export type CreateCourseUserFromBpmValues = {
  bpmContactId: Scalars['String'];
  bpmCourseId: Scalars['String'];
  bpmLeadId?: Maybe<Scalars['String']>;
  status?: Maybe<CourseUserStatus>;
  disqualificationReason?: Maybe<CourseUserDisqualificationReason>;
  rejectionReason?: Maybe<CourseUserRejectionReason>;
  needMaturity?: Maybe<CourseUserNeedMaturity>;
  studentsSource?: Maybe<StudentSource>;
  nextTryDate?: Maybe<Scalars['Date']>;
  registrationMethod?: Maybe<RegistrationMethod>;
  interviewDate?: Maybe<Scalars['Date']>;
  interviewerId?: Maybe<Scalars['Int']>;
};

export type RejectedCourseUserNextTryDate = {
  __typename?: 'RejectedCourseUserNextTryDate';
  nextTryDate?: Maybe<Scalars['Date']>;
  isLastCourseUserRejected: Scalars['Boolean'];
  rejectReason?: Maybe<CourseUserDisqualificationReason>;
};

export enum CourseUserApplicationStatuses {
  Booked = 'BOOKED',
  OnReview = 'ON_REVIEW',
  WaitingForResults = 'WAITING_FOR_RESULTS',
  Reject = 'REJECT',
  Completed = 'COMPLETED',
  WaitingForInterview = 'WAITING_FOR_INTERVIEW',
  UpdateInfo = 'UPDATE_INFO',
  SignDocument = 'SIGN_DOCUMENT',
  CreatingDocument = 'CREATING_DOCUMENT',
  New = 'NEW'
}

export type CourseUserApplicationValues = {
  bpmApplicationStatusId?: Maybe<Scalars['String']>;
};

export type CourseUserApplication = {
  __typename?: 'CourseUserApplication';
  id: Scalars['Int'];
  courseUserId: Scalars['Int'];
  applicationStatusId: Scalars['Int'];
  status?: Maybe<CourseUserApplicationStatuses>;
  deadlineDate?: Maybe<Scalars['Date']>;
  finishedAt?: Maybe<Scalars['Date']>;
  bpmApplicationStatusId?: Maybe<Scalars['String']>;
  applicationStatus?: Maybe<ApplicationStatus>;
  /** @deprecated not used anymore */
  applicationActionRequired?: Maybe<Scalars['Boolean']>;
  statusName?: Maybe<Scalars['String']>;
};


export type CourseUserApplicationStatusNameArgs = {
  lang?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};

export type CourseUserExam = {
  __typename?: 'CourseUserExam';
  id?: Maybe<Scalars['Int']>;
  courseUserId?: Maybe<Scalars['Int']>;
  examId?: Maybe<Scalars['Int']>;
  testTaskLinkId?: Maybe<Scalars['Int']>;
  testTaskLink?: Maybe<TestTaskLink>;
  exam?: Maybe<Exam>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type Exam = {
  __typename?: 'Exam';
  id: Scalars['Int'];
  slug: Scalars['String'];
  programmingLanguage: Scalars['String'];
  courseId?: Maybe<Scalars['Int']>;
  config?: Maybe<TestTaskConfig>;
};

export type CourseUserTopicFields = {
  currentSection?: Maybe<TopicSections>;
  completed?: Maybe<Scalars['Boolean']>;
  completionRate?: Maybe<Scalars['Float']>;
  lastOpenedLearnItemId?: Maybe<Scalars['Int']>;
  lastOpenedParentCourseSlug?: Maybe<Scalars['String']>;
};

export type CourseUserTopic = {
  __typename?: 'CourseUserTopic';
  id: Scalars['Int'];
  courseTopicSlug: Scalars['String'];
  courseTopic: CourseTopic;
  currentSection?: Maybe<TopicSections>;
  testTaskLink: TestTaskLink;
  completed?: Maybe<Scalars['Boolean']>;
  existing?: Maybe<Scalars['Boolean']>;
  completionRate?: Maybe<Scalars['Float']>;
  status?: Maybe<TopicStatus>;
  updates?: Maybe<CourseUserTopicUpdates>;
  learnItems: Array<CourseTopicLearnItem>;
  lastOpenedLearnItemId?: Maybe<Scalars['Int']>;
  lastOpenedParentCourseSlug?: Maybe<Scalars['String']>;
};


export type CourseUserTopicTestTaskLinkArgs = {
  shouldForceUpdate?: Maybe<Scalars['Boolean']>;
};

export type TopicMaterials = {
  __typename?: 'TopicMaterials';
  videos?: Maybe<Array<Maybe<TopicVideo>>>;
  theory?: Maybe<Scalars['String']>;
};

export type TopicVideo = {
  __typename?: 'TopicVideo';
  link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CourseProgress = {
  __typename?: 'CourseProgress';
  progress?: Maybe<Scalars['Float']>;
};

export type CourseUserTopicUpdates = {
  __typename?: 'CourseUserTopicUpdates';
  id: Scalars['Int'];
  actualAttempts: Array<TestTaskAttempt>;
  outdatedAttempts: Array<TestTaskAttempt>;
  newTasks: Array<TestTask>;
  /** @deprecated Use 'removedTasks' instead */
  removedAttempts: Array<TestTaskAttempt>;
  removedTasks: Array<TestTask>;
};

export enum TopicSections {
  Theory = 'THEORY',
  Practice = 'PRACTICE',
  Video = 'VIDEO'
}

export enum TopicStatus {
  Available = 'AVAILABLE',
  Blocked = 'BLOCKED'
}

export type EnsureCourseUsersStatusHistoryInput = {
  id?: Maybe<Scalars['Int']>;
  courseUserId: Scalars['Int'];
  status: CourseUserStatus;
  start: Scalars['Date'];
  due?: Maybe<Scalars['Date']>;
  bpmStatusId: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type CourseUsersStatusHistory = {
  __typename?: 'CourseUsersStatusHistory';
  id: Scalars['Int'];
  courseUserId: Scalars['Int'];
  status: CourseUserStatus;
  start: Scalars['Date'];
  due?: Maybe<Scalars['Date']>;
  bpmStatusId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CurrenciesRate = {
  __typename?: 'CurrenciesRate';
  id?: Maybe<Scalars['Int']>;
  date: Scalars['Date'];
  exchangeRate: Scalars['Float'];
  createdAt?: Maybe<Scalars['Date']>;
  currencyId: Scalars['Int'];
  toCurrencyId: Scalars['Int'];
};

export type Currency = {
  __typename?: 'Currency';
  id: Scalars['Int'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export enum Currencies {
  Usd = 'USD',
  Uah = 'UAH',
  Eur = 'EUR',
  Pln = 'PLN',
  Brl = 'BRL'
}

export type DailyTask = {
  __typename?: 'DailyTask';
  id: Scalars['Int'];
  testTaskId: Scalars['Int'];
  courseModuleId: Scalars['Int'];
  courseModule: CourseModule;
  complexity: Scalars['Int'];
  testTask: TestTask;
  isRequired: Scalars['Boolean'];
};

export type CreateDailyTaskInput = {
  testTaskId: Scalars['Int'];
  courseModuleId: Scalars['Int'];
  complexity: Scalars['Int'];
  isRequired?: Maybe<Scalars['Boolean']>;
};

export type UpdateDailyTaskInput = {
  complexity?: Maybe<Scalars['Int']>;
  isRequired?: Maybe<Scalars['Boolean']>;
};

export type DailyTasksInput = {
  professionSlug: Scalars['String'];
  childCourseSlug: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};




export enum DeleteAccountResult {
  RequestSuccess = 'request_success',
  Success = 'success',
  InvalidToken = 'invalid_token',
  UnableToDelete = 'unable_to_delete',
  UnableToDeleteContractor = 'unable_to_delete_contractor'
}

export type DocumentTemplate = {
  __typename?: 'DocumentTemplate';
  id: Scalars['Int'];
  templateName: Scalars['String'];
  templateId: Scalars['String'];
  esignFolderId?: Maybe<Scalars['String']>;
};

export type Domain = {
  __typename?: 'Domain';
  id: Scalars['Int'];
  slug: Scalars['String'];
  countryName?: Maybe<Scalars['String']>;
  bpmDomainId?: Maybe<Scalars['String']>;
  /** @deprecated not used anymore */
  oneSignalAppId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
};

export enum MilitaryDefermentStatus {
  NotSubject = 'NOT_SUBJECT',
  Yes = 'YES',
  No = 'NO'
}

export enum EducationLevel {
  Higher = 'HIGHER',
  Student = 'STUDENT',
  SpecializedSecondary = 'SPECIALIZED_SECONDARY',
  Secondary = 'SECONDARY'
}

export enum ExcludeIndustries {
  Gamedev = 'GAMEDEV',
  BlockchainCrypto = 'BLOCKCHAIN_CRYPTO',
  Adult = 'ADULT',
  Gambling = 'GAMBLING',
  Dating = 'DATING'
}

export enum JobUrgency {
  Urgent = 'URGENT',
  NotUrgent = 'NOT_URGENT',
  AnyTime = 'ANY_TIME'
}

export enum BooleanChoice {
  Yes = 'YES',
  No = 'NO'
}

export type EmploymentProfile = {
  __typename?: 'EmploymentProfile';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  workEmail?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  jobUrgency?: Maybe<JobUrgency>;
  militaryDefermentStatus?: Maybe<MilitaryDefermentStatus>;
  defermentReason?: Maybe<Scalars['String']>;
  determinedDueDate?: Maybe<Scalars['Date']>;
  isOnSiteLocation?: Maybe<Scalars['Boolean']>;
  onSiteLocation?: Maybe<Scalars['String']>;
  isRemoteLocation?: Maybe<Scalars['Boolean']>;
  isOpenToRelocation?: Maybe<Scalars['Boolean']>;
  relocateLocations?: Maybe<Array<Maybe<Scalars['String']>>>;
  educationLevel?: Maybe<EducationLevel>;
  educationSpecialty?: Maybe<Scalars['String']>;
  isEducationRelatedToTrack?: Maybe<BooleanChoice>;
  hasExperienceRelatedToTrack?: Maybe<BooleanChoice>;
  relatedExperienceRole?: Maybe<Scalars['String']>;
  relatedExperienceYears?: Maybe<Scalars['Int']>;
  otherCommercialExperience?: Maybe<Scalars['String']>;
  cvLink?: Maybe<Scalars['String']>;
  excludeIndustries?: Maybe<Array<Maybe<ExcludeIndustries>>>;
  submittedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
};

export type FillEmploymentProfileFields = {
  workEmail?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  jobUrgency?: Maybe<JobUrgency>;
  militaryDefermentStatus?: Maybe<MilitaryDefermentStatus>;
  defermentReason?: Maybe<Scalars['String']>;
  determinedDueDate?: Maybe<Scalars['Date']>;
  isOnSiteLocation?: Maybe<Scalars['Boolean']>;
  onSiteLocation?: Maybe<Scalars['String']>;
  isRemoteLocation?: Maybe<Scalars['Boolean']>;
  isOpenToRelocation?: Maybe<Scalars['Boolean']>;
  relocateLocations?: Maybe<Array<Maybe<Scalars['String']>>>;
  educationLevel?: Maybe<EducationLevel>;
  educationSpecialty?: Maybe<Scalars['String']>;
  isEducationRelatedToTrack?: Maybe<BooleanChoice>;
  hasExperienceRelatedToTrack?: Maybe<BooleanChoice>;
  relatedExperienceRole?: Maybe<Scalars['String']>;
  relatedExperienceYears?: Maybe<Scalars['Int']>;
  otherCommercialExperience?: Maybe<Scalars['String']>;
  cvLink?: Maybe<Scalars['String']>;
  excludeIndustries?: Maybe<Array<Maybe<ExcludeIndustries>>>;
  submittedAt?: Maybe<Scalars['Date']>;
};

export enum EmploymentTypeName {
  Fop_3Group = 'FOP_3_GROUP',
  Fop_3Group_2Percent = 'FOP_3_GROUP_2_PERCENT',
  Other = 'OTHER'
}

export enum EventLevels {
  Noob = 'NOOB',
  Beginner = 'BEGINNER',
  Intermediate = 'INTERMEDIATE',
  Advanced = 'ADVANCED',
  AllLevels = 'ALL_LEVELS'
}

export enum EventCategories {
  Grammar = 'GRAMMAR',
  Speaking = 'SPEAKING',
  EnglishForWork = 'ENGLISH_FOR_WORK',
  EnglishForTech = 'ENGLISH_FOR_TECH',
  EnglishForIt = 'ENGLISH_FOR_IT',
  Workshop = 'WORKSHOP',
  JobInterview = 'JOB_INTERVIEW',
  FluencyBooster = 'FLUENCY_BOOSTER',
  Pronunciation = 'PRONUNCIATION',
  VocabTrainer = 'VOCAB_TRAINER',
  Start = 'START'
}

export type EventDetails = {
  __typename?: 'EventDetails';
  id: Scalars['Int'];
  homeworkLink?: Maybe<Scalars['String']>;
  materialsLink?: Maybe<Scalars['String']>;
  level?: Maybe<EventLevels>;
  category?: Maybe<EventCategories>;
  comment?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  isCorporate?: Maybe<Scalars['Boolean']>;
};

export type UpdateEventDetailsOptions = {
  id: Scalars['Int'];
  topic?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  homeworkLink?: Maybe<Scalars['String']>;
  materialsLink?: Maybe<Scalars['String']>;
  level?: Maybe<EventLevels>;
  category?: Maybe<EventCategories>;
  isCorporate?: Maybe<Scalars['Boolean']>;
};


export type Details = WebinarDetails | EventDetails | GroupEventDetails | InterviewDetails | MarathonEventDetails;

export type MateEvent = EnglishEvent | WebinarEvent | TechCheckEvent | ScheduleEvent | InterviewEvent | TechCheckSessionEvent | MarathonEvent;

export type ArchivedEvent = EnglishEvent | WebinarEvent | TechCheckEvent;

export type Event = {
  __typename?: 'Event';
  id: Scalars['Int'];
  eventSkeletonId?: Maybe<Scalars['Int']>;
  creatorId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  type: EventTypes;
  locationType: EventLocationTypes;
  location: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  status: EventStatuses;
  creator?: Maybe<User>;
  eventDetails?: Maybe<EventDetails>;
  webinarDetails?: Maybe<WebinarDetails>;
  userEvent?: Maybe<UserEvent>;
  participants: Array<UserEvent>;
  meta?: Maybe<EventMeta>;
  details?: Maybe<Details>;
  bpmEventId?: Maybe<Scalars['String']>;
  sendGridListId?: Maybe<Scalars['String']>;
  isCorporate?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  recordingLinks?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type EventUserEventArgs = {
  userId?: Maybe<Scalars['Int']>;
};

export type EventAbstract = {
  id: Scalars['Int'];
  eventSkeletonId?: Maybe<Scalars['Int']>;
  creatorId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  type: EventTypes;
  locationType: EventLocationTypes;
  location: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  status: EventStatuses;
  creator?: Maybe<User>;
  isCorporate?: Maybe<Scalars['Boolean']>;
};

export type EnglishEvent = EventAbstract & {
  __typename?: 'EnglishEvent';
  id: Scalars['Int'];
  eventSkeletonId?: Maybe<Scalars['Int']>;
  creatorId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  type: EventTypes;
  locationType: EventLocationTypes;
  location: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  status: EventStatuses;
  creator?: Maybe<User>;
  host?: Maybe<EventHost>;
  participants: Array<UserEvent>;
  meta?: Maybe<EventMeta>;
  isCorporate?: Maybe<Scalars['Boolean']>;
  details?: Maybe<EventDetails>;
  userEvent?: Maybe<UserEvent>;
};


export type EnglishEventUserEventArgs = {
  userId?: Maybe<Scalars['Int']>;
};

export type ScheduleEvent = EventAbstract & {
  __typename?: 'ScheduleEvent';
  id: Scalars['Int'];
  eventSkeletonId?: Maybe<Scalars['Int']>;
  creatorId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  type: EventTypes;
  locationType: EventLocationTypes;
  location: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  participants: Array<UserEvent>;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  status: EventStatuses;
  creator?: Maybe<User>;
  isCorporate?: Maybe<Scalars['Boolean']>;
  details?: Maybe<GroupEventDetails>;
  hostId?: Maybe<Scalars['Int']>;
  host?: Maybe<EventHost>;
  eventHostId?: Maybe<Scalars['Int']>;
  recordingLinks?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TechCheckEvent = EventAbstract & {
  __typename?: 'TechCheckEvent';
  id: Scalars['Int'];
  creatorId?: Maybe<Scalars['Int']>;
  hostId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: EventTypes;
  locationType: EventLocationTypes;
  location: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  status: EventStatuses;
  creator?: Maybe<User>;
  details?: Maybe<TechCheckEventDetails>;
  eventSkeletonId?: Maybe<Scalars['Int']>;
  eventSkeleton?: Maybe<TechCheckEventSkeleton>;
  participants: Array<UserEvent>;
  meta?: Maybe<EventMeta>;
  isCorporate?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  host?: Maybe<EventHost>;
  eventHostId?: Maybe<Scalars['Int']>;
  recordingLinks?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TechCheckSessionEvent = EventAbstract & {
  __typename?: 'TechCheckSessionEvent';
  id: Scalars['Int'];
  eventSkeletonId?: Maybe<Scalars['Int']>;
  creatorId?: Maybe<Scalars['Int']>;
  hostId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  type: EventTypes;
  locationType: EventLocationTypes;
  location: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  status: EventStatuses;
  creator?: Maybe<User>;
  details?: Maybe<TechCheckSessionEventDetails>;
  isCorporate?: Maybe<Scalars['Boolean']>;
  eventHostId?: Maybe<Scalars['Int']>;
};

export type WebinarEvent = EventAbstract & {
  __typename?: 'WebinarEvent';
  id: Scalars['Int'];
  eventSkeletonId?: Maybe<Scalars['Int']>;
  creatorId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  type: EventTypes;
  locationType: EventLocationTypes;
  location: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  status: EventStatuses;
  creator?: Maybe<User>;
  details?: Maybe<WebinarDetails>;
  participants: Array<UserEvent>;
  meta?: Maybe<EventMeta>;
  bpmEventId?: Maybe<Scalars['String']>;
  sendGridListId?: Maybe<Scalars['String']>;
  isCorporate?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  host?: Maybe<EventHost>;
};

export type MarathonEvent = EventAbstract & {
  __typename?: 'MarathonEvent';
  id: Scalars['Int'];
  eventSkeletonId?: Maybe<Scalars['Int']>;
  creatorId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  type: EventTypes;
  locationType: EventLocationTypes;
  location: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  status: EventStatuses;
  creator?: Maybe<User>;
  isCorporate?: Maybe<Scalars['Boolean']>;
  details?: Maybe<MarathonEventDetails>;
  participants: Array<UserEvent>;
  meta?: Maybe<EventMeta>;
  bpmEventId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type InterviewEvent = EventAbstract & {
  __typename?: 'InterviewEvent';
  id: Scalars['Int'];
  eventSkeletonId?: Maybe<Scalars['Int']>;
  creatorId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  type: EventTypes;
  locationType: EventLocationTypes;
  location: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  status: EventStatuses;
  creator?: Maybe<User>;
  isCorporate?: Maybe<Scalars['Boolean']>;
  details?: Maybe<InterviewDetails>;
  participants: Array<UserEvent>;
};

export type UpdateEventResult = {
  __typename?: 'UpdateEventResult';
  event?: Maybe<Event>;
  eventDetails?: Maybe<EventDetails>;
};

export type EventInfoResult = {
  __typename?: 'EventInfoResult';
  id: Scalars['Int'];
  type: EventTypes;
  endAt: Scalars['Date'];
  isCorporate?: Maybe<Scalars['Boolean']>;
};

export type EventQuestion = {
  __typename?: 'EventQuestion';
  id: Scalars['Int'];
  eventId: Scalars['Int'];
  userId: Scalars['Int'];
  text: Scalars['String'];
  isSolved?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Date'];
};

export type EventRepeating = {
  days: Array<Scalars['Int']>;
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  startTime?: Maybe<Scalars['Date']>;
  everyNWeeks?: Maybe<Scalars['Int']>;
};

export type EventHost = {
  __typename?: 'EventHost';
  id: Scalars['Int'];
  fullName: Scalars['String'];
  avatar?: Maybe<File>;
};

export type CreateGroupScheduleEventFields = {
  type: EventTypes;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  studentsGroupId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['Int']>;
  courseTopicId?: Maybe<Scalars['Int']>;
  eventData?: Maybe<GroupEventDetailsData>;
  repeating?: Maybe<EventRepeating>;
  shouldNotify?: Maybe<Scalars['Boolean']>;
};

export type UpdateGroupScheduleEventFields = {
  title?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  type?: Maybe<EventTypes>;
  startAt?: Maybe<Scalars['Date']>;
  endAt?: Maybe<Scalars['Date']>;
  studentsGroupId?: Maybe<Scalars['Int']>;
  courseTopicId?: Maybe<Scalars['Int']>;
  chatGuidelineId?: Maybe<Scalars['Int']>;
  eventData?: Maybe<GroupEventDetailsData>;
  shouldNotify?: Maybe<Scalars['Boolean']>;
  resourceLink?: Maybe<Scalars['String']>;
  lessonRecordingLink?: Maybe<Scalars['String']>;
  hostId?: Maybe<Scalars['Int']>;
  courseTopicIds?: Maybe<Array<Scalars['Int']>>;
  courseTopicTaskIds?: Maybe<Array<Scalars['Int']>>;
  testTaskIds?: Maybe<Array<Scalars['Int']>>;
};

export type CreateScheduleEventArgs = {
  type: EventTypes;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  studentsGroupId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['Int']>;
  courseTopicId?: Maybe<Scalars['Int']>;
  chatGuidelineId?: Maybe<Scalars['Int']>;
  morningTasksIds?: Maybe<Array<Scalars['Int']>>;
  shouldNotify?: Maybe<Scalars['Boolean']>;
  resourceLink?: Maybe<Scalars['String']>;
  hostId?: Maybe<Scalars['Int']>;
  courseTopicIds?: Maybe<Array<Scalars['Int']>>;
  courseTopicTaskIds?: Maybe<Array<Scalars['Int']>>;
  testTaskIds?: Maybe<Array<Scalars['Int']>>;
};

export type CreateMultipleScheduleEventsArgs = {
  type: EventTypes;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  repeating: EventRepeating;
  studentsGroupId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  shouldNotify?: Maybe<Scalars['Boolean']>;
  hostId?: Maybe<Scalars['Int']>;
};

export type CreateAllCourseTopicsScheduleEventsArgs = {
  type: EventTypes;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  studentsGroupId: Scalars['Int'];
  courseId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  shouldNotify?: Maybe<Scalars['Boolean']>;
};

export type CreateEnglishEventArgs = {
  creatorId: Scalars['Int'];
  title: Scalars['String'];
  capacity: Scalars['Int'];
  locationType: EventLocationTypes;
  location: Scalars['String'];
  duration: Scalars['Int'];
  startAt: Scalars['Date'];
  level: EventLevels;
  category?: Maybe<EventCategories>;
  repeatEvent?: Maybe<Scalars['Boolean']>;
  isCorporate?: Maybe<Scalars['Boolean']>;
};

export type CreateWebinarEventArgs = {
  title: Scalars['String'];
  location: Scalars['String'];
  startAt: Scalars['Float'];
  speaker: Scalars['String'];
  speakerPosition: Scalars['String'];
  speakerPhoto?: Maybe<SpeakerPhoto>;
  redirectTo?: Maybe<Scalars['String']>;
  webinarStarsEventId: Scalars['Int'];
  giftText?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  consultationTitle?: Maybe<Scalars['String']>;
  consultationBonus?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['Int']>;
  webinarLinkId?: Maybe<Scalars['Int']>;
};

export type CreateMarathonEventArgs = {
  title: Scalars['String'];
  location: Scalars['String'];
  startAt: Scalars['Float'];
  endAt?: Maybe<Scalars['Float']>;
};

export type SpeakerPhoto = {
  file: Scalars['Upload'];
  size?: Maybe<Scalars['Int']>;
};

export type CreateTechCheckEventDeprecatedArgs = {
  hostId: Scalars['Int'];
  title: Scalars['String'];
  capacity: Scalars['Int'];
  locationType: EventLocationTypes;
  location?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  startAt: Scalars['Date'];
  studentsGroupId: Scalars['Int'];
  courseId: Scalars['Int'];
  repeatEvent?: Maybe<Scalars['Boolean']>;
};

export type CreateTechCheckEventArgs = {
  startAt: Scalars['Date'];
  endAt?: Maybe<Scalars['Date']>;
  repeating?: Maybe<EventRepeating>;
  location?: Maybe<Scalars['String']>;
  hostId?: Maybe<Scalars['Int']>;
  sessionId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Int']>;
  studentsGroupId?: Maybe<Scalars['Int']>;
  studentsGroupIds?: Maybe<Array<Scalars['Int']>>;
  courseId?: Maybe<Scalars['Int']>;
  techCheckTemplateId?: Maybe<Scalars['Int']>;
  liveCodingTemplateId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type CreateSessionTechCheckEventArgs = {
  hostId: Scalars['Int'];
  title: Scalars['String'];
  capacity: Scalars['Int'];
  location?: Maybe<Scalars['String']>;
  startAt: Scalars['Date'];
  sessionId: Scalars['Int'];
};

export type CreateTechCheckSessionEventArgs = {
  title: Scalars['String'];
  capacity: Scalars['Int'];
  startAt: Scalars['Date'];
  eventsGenerationMode: TechCheckSessionEventsGenerationMode;
  studentsGroupId: Scalars['Int'];
  techCheckTemplateId?: Maybe<Scalars['Int']>;
  liveCodingTemplateId?: Maybe<Scalars['Int']>;
};

export type UpdateTechCheckSessionEventArgs = {
  title?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['Date']>;
  eventsGenerationMode?: Maybe<TechCheckSessionEventsGenerationMode>;
  techCheckTemplateId?: Maybe<Scalars['Int']>;
  liveCodingTemplateId?: Maybe<Scalars['Int']>;
};

export type UpdateTechCheckEventArgs = {
  hostId?: Maybe<Scalars['Int']>;
  capacity?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['Date']>;
  endAt?: Maybe<Scalars['Date']>;
  studentsGroupId?: Maybe<Scalars['Int']>;
  studentsGroupIds?: Maybe<Array<Scalars['Int']>>;
  courseId?: Maybe<Scalars['Int']>;
  roomLink?: Maybe<Scalars['String']>;
  techCheckTemplateId?: Maybe<Scalars['Int']>;
  liveCodingTemplateId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type CreateEnglishEventResult = {
  __typename?: 'CreateEnglishEventResult';
  events?: Maybe<Array<Maybe<EnglishEvent>>>;
  eventSkeleton?: Maybe<EnglishLessonEventSkeleton>;
};

export type CreateTechCheckEventResult = {
  __typename?: 'CreateTechCheckEventResult';
  events?: Maybe<Array<Maybe<TechCheckEvent>>>;
  eventSkeleton?: Maybe<TechCheckEventSkeleton>;
};

export type UpdateEventOptions = {
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['Date']>;
  endAt?: Maybe<Scalars['Date']>;
  bpmEventId?: Maybe<Scalars['String']>;
  sendGridListId?: Maybe<Scalars['String']>;
  recordingLink?: Maybe<Scalars['String']>;
};

export type EnglishLessonsFilters = {
  level?: Maybe<EventLevels>;
  category?: Maybe<EventCategories>;
  ownFilter?: Maybe<Scalars['Boolean']>;
  actual?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type TechCheckEventsFilters = {
  ownFilter?: Maybe<Scalars['Boolean']>;
  actual?: Maybe<Scalars['Boolean']>;
  studentsGroupId?: Maybe<Scalars['Int']>;
  courseId?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum EventTypes {
  Webinar = 'WEBINAR',
  Marathon = 'MARATHON',
  EnglishLesson = 'ENGLISH_LESSON',
  Optional = 'OPTIONAL',
  Lesson = 'LESSON',
  MorningTasks = 'MORNING_TASKS',
  TechCheck = 'TECH_CHECK',
  TechCheckSession = 'TECH_CHECK_SESSION',
  QnA = 'QnA',
  Interview = 'INTERVIEW',
  LearnAndPractice = 'LEARN_AND_PRACTICE'
}

export enum EventLocationTypes {
  Offline = 'OFFLINE',
  Online = 'ONLINE'
}

export enum EventStatuses {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Pending = 'PENDING'
}

export enum EventMetaStatus {
  Banned = 'BANNED',
  Participant = 'PARTICIPANT',
  InQueue = 'IN_QUEUE'
}

export enum EventMetaBanReason {
  WrongStudentsGroup = 'WRONG_STUDENTS_GROUP',
  AlreadySessionParticipant = 'ALREADY_SESSION_PARTICIPANT',
  InsufficientCourseProgress = 'INSUFFICIENT_COURSE_PROGRESS',
  NoPermission = 'NO_PERMISSION'
}

export type EventMeta = {
  __typename?: 'EventMeta';
  status?: Maybe<EventMetaStatus>;
  banReason?: Maybe<EventMetaBanReason>;
  placeInQueue?: Maybe<Scalars['Int']>;
};

export type GroupEventDetailsData = {
  morningTasksIds: Array<Scalars['Int']>;
};

export type WebinarDetails = {
  __typename?: 'WebinarDetails';
  id: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  eventId: Scalars['Int'];
  speaker?: Maybe<Scalars['String']>;
  speakerPosition?: Maybe<Scalars['String']>;
  speakerPhoto?: Maybe<File>;
  redirectTo?: Maybe<Scalars['String']>;
  /** @deprecated not used, should be removed */
  data?: Maybe<Scalars['JSON']>;
  content: Scalars['String'];
  webinarStarsEventId?: Maybe<Scalars['Int']>;
  giftText?: Maybe<Scalars['String']>;
  consultationTitle?: Maybe<Scalars['String']>;
  consultationBonus?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['Int']>;
  webinarLink?: Maybe<WebinarLink>;
};

export type UpdateMultipleCalendarEventsInput = {
  eventId: Scalars['Int'];
  onlyNextEvents?: Maybe<Scalars['Boolean']>;
  allTypes?: Maybe<Scalars['Boolean']>;
  updatedStartAt?: Maybe<Scalars['Date']>;
  updatedEndAt?: Maybe<Scalars['Date']>;
  updatedLocation?: Maybe<Scalars['String']>;
  studentsGroupId?: Maybe<Scalars['Int']>;
};

export type CreateInterviewEventArgs = {
  location: Scalars['String'];
  startAt: Scalars['Date'];
  userVacancyId: Scalars['Int'];
  interviewType?: Maybe<InterviewType>;
};

export type UpdateInterviewEventArgs = {
  id: Scalars['Int'];
  location?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['Date']>;
  interviewType?: Maybe<InterviewType>;
  status?: Maybe<EventStatuses>;
  isFeedbackFormFilled?: Maybe<Scalars['Boolean']>;
};

export type EventLiveCodingResult = {
  __typename?: 'EventLiveCodingResult';
  userId: Scalars['Int'];
  eventId: Scalars['Int'];
  linkUUID: Scalars['String'];
};

export type GroupEventsQueryArgs = {
  eventTypes: Array<EventTypes>;
  studentGroupId: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  startAtCursor?: Maybe<Scalars['Date']>;
  status?: Maybe<EventStatuses>;
};

export type GroupEventsQueryResult = {
  __typename?: 'GroupEventsQueryResult';
  rows: Array<ScheduleEvent>;
  nextStartAtCursor?: Maybe<Scalars['Date']>;
  count: Scalars['Int'];
};

export type LearnAndPracticeEventsFilters = {
  studentsGroupId: Scalars['Int'];
  courseTopicTaskIds?: Maybe<Array<Scalars['Int']>>;
};

export type GoogleEventHost = {
  __typename?: 'GoogleEventHost';
  id: Scalars['Int'];
  courseId: Scalars['Int'];
  email: Scalars['String'];
};

export type EventSkeleton = {
  id: Scalars['Int'];
  creatorId: Scalars['Int'];
  title: Scalars['String'];
  type: EventTypes;
  locationType: EventLocationTypes;
  location: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  duration: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  /** paused Would be removed from this schema */
  paused: Scalars['Boolean'];
  /** level Would be removed from this schema */
  level?: Maybe<EventLevels>;
  /** category Would be removed from this schema */
  category?: Maybe<EventCategories>;
  repeatInterval?: Maybe<Scalars['String']>;
  updateAt: Scalars['Date'];
  createdAt: Scalars['Date'];
  /** isCorporate Would be removed from this schema */
  isCorporate?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<User>;
  generation?: Maybe<EventSkeletonGeneration>;
};

export type CourseEventSkeleton = EventSkeleton & {
  __typename?: 'CourseEventSkeleton';
  id: Scalars['Int'];
  creatorId: Scalars['Int'];
  title: Scalars['String'];
  type: EventTypes;
  locationType: EventLocationTypes;
  location: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  duration: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  paused: Scalars['Boolean'];
  level?: Maybe<EventLevels>;
  category?: Maybe<EventCategories>;
  repeatInterval?: Maybe<Scalars['String']>;
  updateAt: Scalars['Date'];
  createdAt: Scalars['Date'];
  isCorporate?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<User>;
  details?: Maybe<CourseEventSkeletonDetails>;
  generation?: Maybe<EventSkeletonGeneration>;
};

export type EnglishLessonEventSkeleton = EventSkeleton & {
  __typename?: 'EnglishLessonEventSkeleton';
  id: Scalars['Int'];
  creatorId: Scalars['Int'];
  title: Scalars['String'];
  type: EventTypes;
  locationType: EventLocationTypes;
  location: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  duration: Scalars['Int'];
  startDate: Scalars['Date'];
  paused: Scalars['Boolean'];
  level?: Maybe<EventLevels>;
  category?: Maybe<EventCategories>;
  repeatInterval: Scalars['String'];
  updateAt: Scalars['Date'];
  createdAt: Scalars['Date'];
  isCorporate?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<User>;
  generation?: Maybe<EventSkeletonGeneration>;
  details?: Maybe<EnglishLessonEventSkeletonDetails>;
  nextDate?: Maybe<Scalars['Date']>;
};

export type TechCheckEventSkeleton = EventSkeleton & {
  __typename?: 'TechCheckEventSkeleton';
  id: Scalars['Int'];
  creatorId: Scalars['Int'];
  hostId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: EventTypes;
  locationType: EventLocationTypes;
  location: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  duration: Scalars['Int'];
  startDate: Scalars['Date'];
  paused: Scalars['Boolean'];
  repeatInterval: Scalars['String'];
  updateAt: Scalars['Date'];
  level?: Maybe<EventLevels>;
  category?: Maybe<EventCategories>;
  isCorporate?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Date'];
  creator?: Maybe<User>;
  generation?: Maybe<EventSkeletonGeneration>;
  details?: Maybe<TechCheckEventSkeletonDetails>;
  events: Array<TechCheckEvent>;
  nextDate?: Maybe<Scalars['Date']>;
};

export type ProcessUpdateEnglishLessonEventSkeletonResult = {
  __typename?: 'ProcessUpdateEnglishLessonEventSkeletonResult';
  skeleton?: Maybe<EnglishLessonEventSkeleton>;
  events?: Maybe<Array<Maybe<EnglishEvent>>>;
};

export type EventSkeletonGeneration = {
  __typename?: 'EventSkeletonGeneration';
  kind: SkeletonKind;
  cron?: Maybe<Cron>;
  offset?: Maybe<Offset>;
};

export type Cron = {
  __typename?: 'Cron';
  cronString: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  everyNWeeks?: Maybe<Scalars['Int']>;
};

export type Offset = {
  __typename?: 'Offset';
  startTime?: Maybe<Scalars['Date']>;
  offsetInDays?: Maybe<Scalars['Int']>;
};

export enum SkeletonKind {
  Offset = 'offset',
  Cron = 'cron'
}

export type CourseEventSkeletonDetails = {
  __typename?: 'CourseEventSkeletonDetails';
  id: Scalars['Int'];
  /** @deprecated Temporary fallback field, CLND-612 */
  courseId?: Maybe<Scalars['Int']>;
  courseTopicId?: Maybe<Scalars['Int']>;
  courseTopic?: Maybe<CourseTopic>;
  morningTasksIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** @deprecated Should be totally replaced by courseTopicTasks (https://app.clickup.com/t/24383048/LMS-8493 */
  testTasksIds?: Maybe<Array<Scalars['Int']>>;
  courseTopics?: Maybe<Array<CourseTopic>>;
  courseTopicTasks?: Maybe<Array<CourseTopicTask>>;
  /** @deprecated Should be totally replaced by courseTopicTasks (https://app.clickup.com/t/24383048/LMS-8493 */
  testTasks?: Maybe<Array<TestTask>>;
  morningTasks?: Maybe<Array<MorningTask>>;
  resourceLink?: Maybe<Scalars['String']>;
  chatGuidelineId?: Maybe<Scalars['Int']>;
  chatGuideline?: Maybe<ChatGuideline>;
  calendarTemplateId?: Maybe<Scalars['Int']>;
  /** TechCheckSessionSkeleton specific fields */
  techCheckTemplateId?: Maybe<Scalars['Int']>;
  liveCodingTemplateId?: Maybe<Scalars['Int']>;
  techCheckTemplate?: Maybe<TechCheckTemplate>;
  liveCodingTemplate?: Maybe<TechCheckTemplate>;
  eventsGenerationMode?: Maybe<TechCheckSessionEventsGenerationMode>;
};

export type EnglishLessonEventSkeletonDetails = {
  __typename?: 'EnglishLessonEventSkeletonDetails';
  id: Scalars['Int'];
  isCorporate?: Maybe<Scalars['Boolean']>;
  category?: Maybe<EventCategories>;
  level?: Maybe<EventLevels>;
  paused: Scalars['Boolean'];
};

export type ScheduleEventSkeletonFields = {
  type: EventTypes;
  title?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  /** Deprecated temporary fallback option, CLND-612 */
  courseId?: Maybe<Scalars['Int']>;
  moduleCourseId?: Maybe<Scalars['Int']>;
  courseTopicId?: Maybe<Scalars['Int']>;
  morningTasksIds?: Maybe<Array<Scalars['Int']>>;
  courseTopicIds?: Maybe<Array<Scalars['Int']>>;
  testTasksIds?: Maybe<Array<Scalars['Int']>>;
  courseTopicTaskIds?: Maybe<Array<Scalars['Int']>>;
  offset: OffsetFields;
  resourceLink?: Maybe<Scalars['String']>;
  chatGuidelineId?: Maybe<Scalars['Int']>;
  calendarTemplateId?: Maybe<Scalars['Int']>;
  capacity?: Maybe<Scalars['Int']>;
  techCheckTemplateId?: Maybe<Scalars['Int']>;
  liveCodingTemplateId?: Maybe<Scalars['Int']>;
  eventsGenerationMode?: Maybe<TechCheckSessionEventsGenerationMode>;
};

export type EventSkeletonRepeating = {
  days: Array<Scalars['Int']>;
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  startTime: Scalars['Date'];
};

export type UpdateCourseEventSkeletonFields = {
  type?: Maybe<EventTypes>;
  title?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  courseTopicId?: Maybe<Scalars['Int']>;
  morningTasksIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  testTasksIds?: Maybe<Array<Scalars['Int']>>;
  courseTopicTaskIds?: Maybe<Array<Scalars['Int']>>;
  courseTopicIds?: Maybe<Array<Scalars['Int']>>;
  resourceLink?: Maybe<Scalars['String']>;
  offset?: Maybe<OffsetFields>;
  chatGuidelineId?: Maybe<Scalars['Int']>;
  techCheckTemplateId?: Maybe<Scalars['Int']>;
  liveCodingTemplateId?: Maybe<Scalars['Int']>;
  eventsGenerationMode?: Maybe<TechCheckSessionEventsGenerationMode>;
};

export type UpdateTechCheckEventSkeletonFields = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hostId?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  everyNWeeks?: Maybe<Scalars['Int']>;
  courseId?: Maybe<Scalars['Int']>;
  studentsGroupId?: Maybe<Scalars['Int']>;
  studentsGroupIds?: Maybe<Array<Scalars['Int']>>;
  paused?: Maybe<Scalars['Boolean']>;
  techCheckTemplateId?: Maybe<Scalars['Int']>;
  liveCodingTemplateId?: Maybe<Scalars['Int']>;
};

export type EventSkeletonFields = {
  creatorId?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  generation?: Maybe<EventSkeletonGenerationFields>;
  generationDate?: Maybe<Scalars['Date']>;
};

export type EventSkeletonGenerationFields = {
  kind?: Maybe<SkeletonKind>;
  cron?: Maybe<CronFields>;
  offset?: Maybe<OffsetFields>;
};

export type CronFields = {
  cronString?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type OffsetFields = {
  startTime: Scalars['Date'];
  offsetInDays: Scalars['Int'];
};

export type EnglishEventSkeletonFields = {
  isCorporate?: Maybe<Scalars['Boolean']>;
  category?: Maybe<EventCategories>;
  level?: Maybe<EventLevels>;
  paused?: Maybe<Scalars['Boolean']>;
};

export type EventDetailsFields = {
  comment?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
};

export type Interval = {
  __typename?: 'Interval';
  start: Scalars['Date'];
  end?: Maybe<Scalars['Date']>;
};

export type ExamInsight = {
  __typename?: 'ExamInsight';
  id: Scalars['Int'];
  courseUserTopicId: Scalars['Int'];
  testTaskAttemptId: Scalars['Int'];
  selectTextAttempt: Scalars['Boolean'];
  userPastedContent: Array<Scalars['String']>;
  tabBlurIntervals: Array<Interval>;
  keyboardIdleIntervals: Array<Interval>;
  hasOpenIdleInterval: Scalars['Boolean'];
  hasOpenBlurInterval: Scalars['Boolean'];
  userId: Scalars['Int'];
  taskName?: Maybe<Scalars['String']>;
};

export type Experiment = {
  __typename?: 'Experiment';
  id: Scalars['Int'];
  optimizeExperimentId: Scalars['String'];
  bpmExperimentId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type FastAnswerQuestion = {
  __typename?: 'FastAnswerQuestion';
  id: Scalars['Int'];
  question: Scalars['String'];
  answer: Scalars['String'];
  question_code: Scalars['String'];
  image?: Maybe<File>;
};


export type FastAnswerQuestionQuestionArgs = {
  lang?: Maybe<Scalars['String']>;
  modifier?: Maybe<Scalars['String']>;
};


export type FastAnswerQuestionAnswerArgs = {
  lang?: Maybe<Scalars['String']>;
  modifier?: Maybe<Scalars['String']>;
};

export type Feature = {
  __typename?: 'Feature';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type CsvContent = {
  __typename?: 'CSVContent';
  id: Scalars['String'];
  headers: Array<Scalars['String']>;
  rows: Array<Array<Scalars['String']>>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['Int'];
  name: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  mime?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type FileMorph = {
  __typename?: 'FileMorph';
  id: Scalars['Int'];
  uploadFileId?: Maybe<Scalars['Int']>;
  relatedId?: Maybe<Scalars['Int']>;
  relatedType?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  file?: Maybe<File>;
};

export type InstalmentData = {
  initialPaymentAmount: Scalars['Float'];
  instalmentPaymentAmount: Scalars['Float'];
  instalmentPartsCount: Scalars['Int'];
};

export type PurchaseLinkBillingData = {
  cpf?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type GeneratedPurchaseLink = {
  __typename?: 'GeneratedPurchaseLink';
  id: Scalars['Int'];
  subscription?: Maybe<PaymentSubscription>;
  order?: Maybe<Order>;
  admin: User;
  user?: Maybe<User>;
  uuid: Scalars['String'];
  initialPrice?: Maybe<Scalars['Float']>;
  purchasePrice: Scalars['Float'];
  currency: Currency;
  ttl?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  payments: Array<Payment>;
  createdAt: Scalars['Date'];
  expired: Scalars['Boolean'];
  installmentsEnabled: Scalars['Boolean'];
  paymentProvider?: Maybe<PaymentProvider>;
  discountReason: DiscountReasons;
  bonusSubscriptionIds?: Maybe<Array<Scalars['Int']>>;
  bonusSubscriptions?: Maybe<Array<PaymentSubscription>>;
  secondPaymentDeadline?: Maybe<Scalars['Date']>;
  externalUrl?: Maybe<Scalars['String']>;
  creditCheckStatus?: Maybe<PrincipiaCreditCheckStatus>;
  promoCodeId?: Maybe<Scalars['Int']>;
};

export enum DiscountReasons {
  Fomo = 'FOMO',
  Military = 'MILITARY',
  Alumnus = 'ALUMNUS',
  FriendsFamily = 'FRIENDS_FAMILY',
  Other = 'OTHER',
  NoDiscount = 'NO_DISCOUNT'
}

export enum GitHubCodeReviewProgressStatus {
  Loading = 'loading',
  Success = 'success',
  Error = 'error'
}

export type GitHubCodeReviewProgress = {
  __typename?: 'GitHubCodeReviewProgress';
  id: Scalars['String'];
  log: Scalars['String'];
  status: GitHubCodeReviewProgressStatus;
};

export type AutoReviewPullRequestByLinkResponse = {
  __typename?: 'AutoReviewPullRequestByLinkResponse';
  prId: Scalars['String'];
  reviewId: Scalars['String'];
  url: Scalars['String'];
  title: Scalars['String'];
  body: Scalars['String'];
  repo: Scalars['String'];
  owner: Scalars['String'];
  prNumber: Scalars['Int'];
};

export enum PullRequestState {
  Open = 'OPEN',
  Closed = 'CLOSED',
  Merged = 'MERGED'
}

export enum PullRequestReviewDecision {
  ChangesRequested = 'CHANGES_REQUESTED',
  Approved = 'APPROVED',
  ReviewRequired = 'REVIEW_REQUIRED'
}

export enum CheckRunConclusion {
  Success = 'success',
  Failure = 'failure',
  Cancelled = 'cancelled',
  ActionRequired = 'action_required',
  Neutral = 'neutral',
  Skipped = 'skipped',
  Stale = 'stale',
  TimedOut = 'timed_out'
}

export enum CheckRunStatus {
  Queued = 'queued',
  Completed = 'completed',
  InProgress = 'in_progress'
}

export type CheckRun = {
  __typename?: 'CheckRun';
  id?: Maybe<Scalars['String']>;
  detailsUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CheckRunStatus>;
  conclusion?: Maybe<CheckRunConclusion>;
  summary?: Maybe<Scalars['String']>;
};

export type Repository = {
  __typename?: 'Repository';
  id?: Maybe<Scalars['String']>;
  nameWithOwner?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PullRequest = {
  __typename?: 'PullRequest';
  id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  state?: Maybe<PullRequestState>;
  baseRepository?: Maybe<Repository>;
  headRepository?: Maybe<Repository>;
  checksUrl?: Maybe<Scalars['String']>;
  reviewDecision?: Maybe<PullRequestReviewDecision>;
  checkRuns?: Maybe<Array<CheckRun>>;
  synched?: Maybe<Scalars['Boolean']>;
};

export enum SearchableEntityType {
  Topic = 'TOPIC',
  Theory = 'THEORY',
  Task = 'TASK',
  Video = 'VIDEO',
  Chat = 'CHAT',
  ChatMessage = 'CHAT_MESSAGE',
  User = 'USER'
}

export enum GlobalSearchSortOption {
  MostRelevant = 'MOST_RELEVANT',
  Newest = 'NEWEST',
  Oldest = 'OLDEST'
}

export type GlobalSearchFilters = {
  chatId?: Maybe<Scalars['Int']>;
  senderUserId?: Maybe<Scalars['Int']>;
};

export type GlobalSearchResult = {
  __typename?: 'GlobalSearchResult';
  totalMatches: Scalars['Int'];
  offset: Scalars['Int'];
  matches: Array<GlobalSearchMatch>;
};

export type GlobalSearchMatch = {
  __typename?: 'GlobalSearchMatch';
  id: Scalars['Int'];
  type: SearchableEntityType;
  entity: SearchableEntity;
  weight: Scalars['Float'];
  highlights: SearchableEntityHighlights;
};

export type SearchableEntityHighlights = {
  __typename?: 'SearchableEntityHighlights';
  title?: Maybe<Array<Scalars['String']>>;
  body?: Maybe<Array<Scalars['String']>>;
};

export type SearchableEntity = SearchableTopic | SearchableTheory | SearchableTask | SearchableVideo | SearchableChat | SearchableMessage | SearchableUser;

export type SearchableTopic = {
  __typename?: 'SearchableTopic';
  id: Scalars['Int'];
  type: SearchableEntityType;
  topic?: Maybe<CourseTopic>;
};

export type SearchableTheory = {
  __typename?: 'SearchableTheory';
  id: Scalars['Int'];
  type: SearchableEntityType;
  theory?: Maybe<CourseTopicTheoryFile>;
};

export type SearchableTask = {
  __typename?: 'SearchableTask';
  id: Scalars['Int'];
  type: SearchableEntityType;
  task?: Maybe<TestTask>;
};

export type SearchableVideo = {
  __typename?: 'SearchableVideo';
  id: Scalars['Int'];
  type: SearchableEntityType;
  video?: Maybe<LearnVideo>;
};

export type SearchableMessage = {
  __typename?: 'SearchableMessage';
  id: Scalars['Int'];
  type: SearchableEntityType;
  message: AbstractMessage;
};

export type SearchableChat = {
  __typename?: 'SearchableChat';
  id: Scalars['Int'];
  type: SearchableEntityType;
  chat: AbstractChat;
};

export type SearchableUser = {
  __typename?: 'SearchableUser';
  id: Scalars['Int'];
  type: SearchableEntityType;
  user: UserProfile;
};

export type GrantedSubscription = {
  __typename?: 'GrantedSubscription';
  id: Scalars['Int'];
  admin: User;
  subscription?: Maybe<PaymentSubscription>;
  price: Scalars['Float'];
  comment: Scalars['String'];
};

export type Receipt = {
  file: Scalars['Upload'];
  size?: Maybe<Scalars['Int']>;
};

export type LoanSubscriptionValues = {
  loanOrderId: Scalars['String'];
  loanProviderId: Scalars['Int'];
};

export type GroupDeadlineCreationOptions = {
  testTaskId?: Maybe<Scalars['Int']>;
  studentsGroupId: Scalars['Int'];
  courseTopicTaskId?: Maybe<Scalars['Int']>;
  expiresAt: Scalars['Date'];
};

export type GroupDeadlineUpdateOptions = {
  groupDeadlineId: Scalars['Int'];
  expiresAt: Scalars['Date'];
};

export type GroupDeadline = {
  __typename?: 'GroupDeadline';
  id: Scalars['Int'];
  testTaskId?: Maybe<Scalars['Int']>;
  courseTopicTaskId?: Maybe<Scalars['Int']>;
  studentsGroupId: Scalars['Int'];
  expiresAt: Scalars['Date'];
};

export type GroupEventDetails = {
  __typename?: 'GroupEventDetails';
  id: Scalars['Int'];
  eventId: Scalars['Int'];
  eventData?: Maybe<GroupEventData>;
  studentsGroupId: Scalars['Int'];
  studentsGroup: StudentsGroup;
  courseTopicId?: Maybe<Scalars['Int']>;
  courseTopic?: Maybe<CourseTopic>;
  morningTasks?: Maybe<Array<MorningTask>>;
  /** @deprecated Should be totally replaced by courseTopicTasks (https://app.clickup.com/t/24383048/LMS-8493 */
  testTasks?: Maybe<Array<TestTask>>;
  courseTopicTasks?: Maybe<Array<CourseTopicTask>>;
  courseTopics?: Maybe<Array<CourseTopic>>;
  questions?: Maybe<Array<EventQuestion>>;
  chatParticipant?: Maybe<Participant>;
  chatId?: Maybe<Scalars['Int']>;
  chatGuidelineId?: Maybe<Scalars['Int']>;
  chatGuideline?: Maybe<ChatGuideline>;
  shouldNotify?: Maybe<Scalars['Boolean']>;
  resourceLink?: Maybe<Scalars['String']>;
  lessonRecordingLink?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['Int']>;
  eventsGenerationMode?: Maybe<TechCheckSessionEventsGenerationMode>;
};

export type GroupEventData = {
  __typename?: 'GroupEventData';
  morningTasksIds?: Maybe<Array<Scalars['Int']>>;
};

export type GroupTeamMember = {
  __typename?: 'GroupTeamMember';
  id: Scalars['Int'];
  bpmGroupTeamMemberId: Scalars['String'];
  groupId: Scalars['Int'];
  bpmGroupId: Scalars['String'];
  teamMemberId: Scalars['Int'];
  bpmTeamMemberId: Scalars['String'];
  teamMemberRole: TeamMemberRoles;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
};

export type GroupTeamMemberCreationOptions = {
  bpmGroupTeamMemberId: Scalars['String'];
  groupId: Scalars['Int'];
  teamMemberId: Scalars['Int'];
  teamMemberRole: TeamMemberRoles;
};

export type GroupTeamMemberUpdateOptions = {
  teamMemberId?: Maybe<Scalars['Int']>;
  teamMemberRole?: Maybe<TeamMemberRoles>;
};

export enum TeamMemberRoles {
  Coordinator = 'COORDINATOR',
  Mentor = 'MENTOR',
  Coach = 'COACH',
  CoordinatorPm = 'COORDINATOR_PM',
  CareerSupport = 'CAREER_SUPPORT',
  EmploymentCoordinator = 'EMPLOYMENT_COORDINATOR',
  EducationalPm = 'EDUCATIONAL_PM',
  EducationalSupport = 'EDUCATIONAL_SUPPORT',
  EmploymentSupport = 'EMPLOYMENT_SUPPORT',
  Other = 'OTHER',
  Smm = 'SMM'
}

export type UpdateInterviewValues = {
  interviewerUserId: Scalars['Int'];
};

export type CreateInterviewValues = {
  courseUserId: Scalars['Int'];
  interviewerId: Scalars['Int'];
  bpmInterviewId?: Maybe<Scalars['String']>;
};

export type Interview = {
  __typename?: 'Interview';
  id: Scalars['Int'];
  courseUserId: Scalars['Int'];
  interviewerId: Scalars['Int'];
  bpmInterviewId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  interviewer?: Maybe<Interviewer>;
  scheduleLink?: Maybe<Scalars['String']>;
};

export type InterviewDetails = {
  __typename?: 'InterviewDetails';
  id: Scalars['Int'];
  eventId: Scalars['Int'];
  userVacancyId: Scalars['Int'];
  interviewType?: Maybe<InterviewType>;
  isFeedbackFormFilled: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export enum InterviewType {
  Tech = 'TECH',
  Screen = 'SCREEN',
  SoftSkills = 'SOFT_SKILLS',
  HrOrScreen = 'HR_OR_SCREEN',
  Final = 'FINAL'
}

export type Interviewer = {
  __typename?: 'Interviewer';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  interviewerName: Scalars['String'];
};

export type Invid = {
  __typename?: 'Invid';
  id: Scalars['Int'];
  title: Scalars['String'];
  createdBy: Scalars['Int'];
  sandboxId: Scalars['Int'];
  technology: Technology;
  initialState?: Maybe<InvidInitialState>;
  settings: InvidSettings;
  sandbox: Sandbox;
  versions: Array<InvidVersion>;
  updatedAt: Scalars['Date'];
  latestPublishedVersion?: Maybe<InvidVersion>;
};


export type InvidVersionsArgs = {
  orderBy?: Maybe<InvidVersionOrderBy>;
  direction?: Maybe<InvidVersionOrderDirection>;
};

export enum InvidVersionOrderBy {
  Id = 'ID'
}

export enum InvidVersionOrderDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type InvidInitialState = {
  __typename?: 'InvidInitialState';
  isFileManagerOpened: Scalars['Boolean'];
  mainWidthInPixels: Scalars['Int'];
  mainHeightInPixels: Scalars['Int'];
  fileManagerWidthInPixels: Scalars['Int'];
  consoleWidthInPixels: Scalars['Int'];
};

export type InvidInitialStateValues = {
  isFileManagerOpened: Scalars['Boolean'];
  mainWidthInPixels: Scalars['Int'];
  mainHeightInPixels: Scalars['Int'];
  fileManagerWidthInPixels: Scalars['Int'];
  consoleWidthInPixels: Scalars['Int'];
};

export type InvidSettings = {
  __typename?: 'InvidSettings';
  isFileManagerEnabled: Scalars['Boolean'];
};

export type CreateInvidValues = {
  title: Scalars['String'];
  fallbackUrl?: Maybe<Scalars['String']>;
  settings: CreateInvidSettings;
  technology: Technology;
  mainEntity?: Maybe<Scalars['String']>;
};

export type CreateInvidSettings = {
  isFileManagerEnabled: Scalars['Boolean'];
};

export enum EventKind {
  ToggleSection = 'ToggleSection',
  ResizeSection = 'ResizeSection',
  ToggleFile = 'ToggleFile',
  ToggleFolder = 'ToggleFolder',
  CreateFile = 'CreateFile',
  RemoveFile = 'RemoveFile',
  MoveFile = 'MoveFile',
  CreateFolder = 'CreateFolder',
  StartAddFile = 'StartAddFile',
  StartAddFolder = 'StartAddFolder',
  StopAddItem = 'StopAddItem',
  StartRenameItem = 'StartRenameItem',
  StopRenameItem = 'StopRenameItem',
  SetAddItemValue = 'SetAddItemValue',
  SetRenameItemValue = 'SetRenameItemValue',
  ChangeFileContent = 'ChangeFileContent',
  ChangeFileSelection = 'ChangeFileSelection',
  StartRunCode = 'StartRunCode',
  RunCodeSucceeded = 'RunCodeSucceeded',
  RunCodeFailed = 'RunCodeFailed',
  StartScrollSection = 'StartScrollSection'
}

export type InvidEvent = ToggleSectionEvent | ResizeSectionEvent | ToggleFileEvent | CreateFileEvent | RemoveFileEvent | MoveFileEvent | ToggleFolderEvent | CreateFolderEvent | StartAddFileEvent | StartAddFolderEvent | StopAddItemEvent | StartRenameItemEvent | StopRenameItemEvent | SetAddItemValueEvent | SetRenameItemValueEvent | ChangeFileContentEvent | ChangeFileSelectionEvent | StartRunCodeEvent | RunCodeSucceededEvent | RunCodeFailedEvent | StartScrollSectionEvent;

export type AbstractInvidEvent = {
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
};

export enum InvidSection {
  FileManager = 'FileManager',
  Console = 'Console',
  Editor = 'Editor'
}

export enum InvidFileManagerItemType {
  File = 'File',
  Folder = 'Folder'
}

export type ToggleSectionEvent = AbstractInvidEvent & {
  __typename?: 'ToggleSectionEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: ToggleSectionEventPayload;
};

export type ToggleSectionEventPayload = {
  __typename?: 'ToggleSectionEventPayload';
  section: InvidSection;
  state: Scalars['Boolean'];
};

export type ResizeSectionEvent = AbstractInvidEvent & {
  __typename?: 'ResizeSectionEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: ResizeSectionEventPayload;
};

export type ResizeSectionEventPayload = {
  __typename?: 'ResizeSectionEventPayload';
  section: InvidSection;
  widthInPixels: Scalars['Int'];
};

export type StartScrollSectionEvent = AbstractInvidEvent & {
  __typename?: 'StartScrollSectionEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: StartScrollSectionEventPayload;
};

export type StartScrollSectionEventPayload = {
  __typename?: 'StartScrollSectionEventPayload';
  section: InvidSection;
  scrollTopInPixels: Scalars['Int'];
  scrollLeftInPixels: Scalars['Int'];
};

export type ToggleFileEvent = AbstractInvidEvent & {
  __typename?: 'ToggleFileEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: ToggleFileEventPayload;
};

export type ToggleFileEventPayload = {
  __typename?: 'ToggleFileEventPayload';
  path: Scalars['String'];
  state: Scalars['Boolean'];
};

export type CreateFileEvent = AbstractInvidEvent & {
  __typename?: 'CreateFileEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: CreateFileEventPayload;
};

export type CreateFileEventPayload = {
  __typename?: 'CreateFileEventPayload';
  path: Scalars['String'];
};

export type RemoveFileEvent = AbstractInvidEvent & {
  __typename?: 'RemoveFileEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: RemoveFileEventPayload;
};

export type RemoveFileEventPayload = {
  __typename?: 'RemoveFileEventPayload';
  path: Scalars['String'];
};

export type MoveFileEvent = AbstractInvidEvent & {
  __typename?: 'MoveFileEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: MoveFileEventPayload;
};

export type MoveFileEventPayload = {
  __typename?: 'MoveFileEventPayload';
  fromPath: Scalars['String'];
  toPath: Scalars['String'];
};

export type ToggleFolderEvent = AbstractInvidEvent & {
  __typename?: 'ToggleFolderEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: ToggleFolderEventPayload;
};

export type ToggleFolderEventPayload = {
  __typename?: 'ToggleFolderEventPayload';
  path: Scalars['String'];
  state: Scalars['Boolean'];
};

export type CreateFolderEvent = AbstractInvidEvent & {
  __typename?: 'CreateFolderEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: CreateFolderEventPayload;
};

export type CreateFolderEventPayload = {
  __typename?: 'CreateFolderEventPayload';
  path: Scalars['String'];
};

export type StartAddFileEvent = AbstractInvidEvent & {
  __typename?: 'StartAddFileEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
};

export type StartAddFolderEvent = AbstractInvidEvent & {
  __typename?: 'StartAddFolderEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
};

export type StopAddItemEvent = AbstractInvidEvent & {
  __typename?: 'StopAddItemEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
};

export type StartRunCodeEvent = AbstractInvidEvent & {
  __typename?: 'StartRunCodeEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
};

export type RunCodeSucceededEvent = AbstractInvidEvent & {
  __typename?: 'RunCodeSucceededEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: RunCodeSuccessEventPayload;
};

export type RunCodeSuccessEventPayload = {
  __typename?: 'RunCodeSuccessEventPayload';
  runId: Scalars['String'];
  exitCode: Scalars['Int'];
  logs: Array<SandboxRunCodeLog>;
};

export type RunCodeFailedEvent = AbstractInvidEvent & {
  __typename?: 'RunCodeFailedEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: RunCodeFailedEventPayload;
};

export type RunCodeFailedEventPayload = {
  __typename?: 'RunCodeFailedEventPayload';
  runId: Scalars['String'];
  message: Scalars['String'];
};

export type StartRenameItemEvent = AbstractInvidEvent & {
  __typename?: 'StartRenameItemEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: StartRenameItemEventPayload;
};

export type StartRenameItemEventPayload = {
  __typename?: 'StartRenameItemEventPayload';
  path: Scalars['String'];
  type: InvidFileManagerItemType;
};

export type StopRenameItemEvent = AbstractInvidEvent & {
  __typename?: 'StopRenameItemEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
};

export type SetAddItemValueEvent = AbstractInvidEvent & {
  __typename?: 'SetAddItemValueEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: SetAddItemValueEventPayload;
};

export type SetAddItemValueEventPayload = {
  __typename?: 'SetAddItemValueEventPayload';
  value: Scalars['String'];
};

export type SetRenameItemValueEvent = AbstractInvidEvent & {
  __typename?: 'SetRenameItemValueEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: SetRenameItemValueEventPayload;
};

export type SetRenameItemValueEventPayload = {
  __typename?: 'SetRenameItemValueEventPayload';
  value: Scalars['String'];
};

export type ChangeFileContentEvent = AbstractInvidEvent & {
  __typename?: 'ChangeFileContentEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: ChangeFileContentEventPayload;
};

export type ChangeFileContentEventPayload = {
  __typename?: 'ChangeFileContentEventPayload';
  path: Scalars['String'];
  changes: Array<FileContentChange>;
};

export type FileContentChange = {
  __typename?: 'FileContentChange';
  range: FileContentRange;
  rangeLength: Scalars['Int'];
  rangeOffset: Scalars['Int'];
  text: Scalars['String'];
};

export type FileContentRange = {
  __typename?: 'FileContentRange';
  endColumn: Scalars['Int'];
  endLineNumber: Scalars['Int'];
  startColumn: Scalars['Int'];
  startLineNumber: Scalars['Int'];
};

export type ChangeFileSelectionEvent = AbstractInvidEvent & {
  __typename?: 'ChangeFileSelectionEvent';
  id: Scalars['Int'];
  kind: EventKind;
  timestamp: Scalars['Int'];
  payload: ChangeFileSelectionEventPayload;
};

export type ChangeFileSelectionEventPayload = {
  __typename?: 'ChangeFileSelectionEventPayload';
  path: Scalars['String'];
  selectionChange: InvidCodeEditorFileSelectionState;
};

export type InvidCodeEditorFilePosition = {
  __typename?: 'InvidCodeEditorFilePosition';
  lineNumber: Scalars['Int'];
  column: Scalars['Int'];
};

export type InvidCodeEditorFileSelection = {
  __typename?: 'InvidCodeEditorFileSelection';
  start: InvidCodeEditorFilePosition;
  end: InvidCodeEditorFilePosition;
};

export type InvidCodeEditorFileSelectionState = {
  __typename?: 'InvidCodeEditorFileSelectionState';
  main?: Maybe<InvidCodeEditorFileSelection>;
  secondary: Array<InvidCodeEditorFileSelection>;
};

export type InvidEventInput = {
  kind: EventKind;
  timestamp: Scalars['Int'];
  toggleSectionPayload?: Maybe<ToggleSectionEventInput>;
  resizeSectionPayload?: Maybe<ResizeSectionEventInput>;
  toggleFilePayload?: Maybe<ToggleFileEventInput>;
  createFilePayload?: Maybe<CreateFileEventInput>;
  removeFilePayload?: Maybe<RemoveFileEventInput>;
  moveFilePayload?: Maybe<MoveFileEventInput>;
  toggleFolderPayload?: Maybe<ToggleFolderEventInput>;
  createFolderPayload?: Maybe<CreateFolderEventInput>;
  startRenameItemPayload?: Maybe<StartRenameItemEventInput>;
  setAddItemValuePayload?: Maybe<SetAddItemValueEventInput>;
  setRenameItemValuePayload?: Maybe<SetRenameItemValueEventInput>;
  changeFileContentPayload?: Maybe<ChangeFileContentEventInput>;
  changeFileSelectionPayload?: Maybe<ChangeFileSelectionEventInput>;
  runCodeSucceededPayload?: Maybe<RunCodeSucceededEventInput>;
  runCodeFailedPayload?: Maybe<RunCodeFailedEventInput>;
  startScrollSectionPayload?: Maybe<StartScrollSectionEventInput>;
};

export type ToggleSectionEventInput = {
  section: InvidSection;
  state: Scalars['Boolean'];
};

export type ResizeSectionEventInput = {
  section: InvidSection;
  widthInPixels: Scalars['Int'];
};

export type StartScrollSectionEventInput = {
  section: InvidSection;
  scrollTopInPixels: Scalars['Int'];
  scrollLeftInPixels: Scalars['Int'];
};

export type ToggleFileEventInput = {
  path: Scalars['String'];
  state: Scalars['Boolean'];
};

export type CreateFileEventInput = {
  path: Scalars['String'];
};

export type RemoveFileEventInput = {
  path: Scalars['String'];
};

export type MoveFileEventInput = {
  fromPath: Scalars['String'];
  toPath: Scalars['String'];
};

export type ToggleFolderEventInput = {
  path: Scalars['String'];
  state: Scalars['Boolean'];
};

export type CreateFolderEventInput = {
  path: Scalars['String'];
};

export type StartRenameItemEventInput = {
  path: Scalars['String'];
  type: InvidFileManagerItemType;
};

export type SetAddItemValueEventInput = {
  value: Scalars['String'];
};

export type SetRenameItemValueEventInput = {
  value: Scalars['String'];
};

export type FileContentRangeInput = {
  endColumn: Scalars['Int'];
  endLineNumber: Scalars['Int'];
  startColumn: Scalars['Int'];
  startLineNumber: Scalars['Int'];
};

export type FileContentChangeInput = {
  range: FileContentRangeInput;
  rangeLength: Scalars['Int'];
  rangeOffset: Scalars['Int'];
  text: Scalars['String'];
};

export type ChangeFileContentEventInput = {
  path: Scalars['String'];
  changes: Array<FileContentChangeInput>;
};

export type ChangeFileSelectionEventInput = {
  path: Scalars['String'];
  selectionChange: FileSelectionStateInput;
};

export type FileSelectionStateInput = {
  main?: Maybe<FileSelectionInput>;
  secondary: Array<FileSelectionInput>;
};

export type FileSelectionInput = {
  start: FilePositionInput;
  end: FilePositionInput;
};

export type FilePositionInput = {
  lineNumber: Scalars['Int'];
  column: Scalars['Int'];
};

export type RunCodeFileInput = {
  path: Scalars['String'];
  content: Scalars['String'];
};

export type RunCodeSucceededEventInput = {
  runId: Scalars['String'];
  exitCode: Scalars['Int'];
  logs: Array<SandboxRunCodeLogInput>;
};

export type SandboxRunCodeLogInput = {
  timestamp: Scalars['Date'];
  message?: Maybe<Scalars['String']>;
  stream: SandboxRunCodeLogStream;
};

export type RunCodeFailedEventInput = {
  runId: Scalars['String'];
  message: Scalars['String'];
};

export type InvidNote = {
  __typename?: 'InvidNote';
  id: Scalars['Int'];
  invidVersionId: Scalars['Int'];
  userId: Scalars['Int'];
  sandboxId: Scalars['Int'];
  timestamp: Scalars['Int'];
  title: Scalars['String'];
  sandbox: Sandbox;
};

export type CopySandboxFileValues = {
  path: Scalars['String'];
  kind: SandboxFileKind;
  content: Scalars['String'];
  isEntry: Scalars['Boolean'];
  isReadonly: Scalars['Boolean'];
};

export type InvidVersion = {
  __typename?: 'InvidVersion';
  id: Scalars['Int'];
  invidId: Scalars['Int'];
  audioFileId?: Maybe<Scalars['Int']>;
  createdBy: Scalars['Int'];
  status: InvidVersionStatus;
  duration: Scalars['Float'];
  fallbackUrl?: Maybe<Scalars['String']>;
  revisionNumber: Scalars['Int'];
  versionNumber: Scalars['Int'];
  invid: Invid;
  audioFile?: Maybe<Scalars['Binary']>;
  audioFileUrl?: Maybe<Scalars['String']>;
  events: Array<AbstractInvidEvent>;
  updatedAt: Scalars['Date'];
  userNotes: Array<InvidNote>;
};


export type InvidVersionAudioFileArgs = {
  revisionNumber?: Maybe<Scalars['Int']>;
};


export type InvidVersionAudioFileUrlArgs = {
  revisionNumber?: Maybe<Scalars['Int']>;
};


export type InvidVersionEventsArgs = {
  revisionNumber?: Maybe<Scalars['Int']>;
};


export type InvidVersionUserNotesArgs = {
  userId: Scalars['Int'];
};

export enum InvidVersionStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type UpdateInvidVersionValues = {
  fallbackUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<InvidVersionStatus>;
};

export type JobExperience = {
  __typename?: 'JobExperience';
  id: Scalars['Int'];
  slug: Scalars['String'];
  order: Scalars['Int'];
};

export type UpdateJobRatingValues = {
  bpmJobSatisfactionId?: Maybe<Scalars['String']>;
};

export type JobRating = {
  __typename?: 'JobRating';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  paymentId?: Maybe<Scalars['Int']>;
  rating: Scalars['Int'];
  bpmJobSatisfactionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
};

export enum LanguageName {
  Ru = 'ru',
  Uk = 'uk',
  En = 'en',
  Pl = 'pl',
  Tr = 'tr',
  Ro = 'ro',
  Pt = 'pt'
}

export type Language = {
  __typename?: 'Language';
  id: Scalars['Int'];
  name: LanguageName;
  isActive: Scalars['Boolean'];
};

export type StringWithLang = {
  value?: Maybe<Scalars['String']>;
  lang: LanguageName;
};

export type Leaderboard = {
  __typename?: 'Leaderboard';
  id: Scalars['Int'];
  courseId?: Maybe<Scalars['Int']>;
  type: LeaderboardType;
  runId?: Maybe<Scalars['Int']>;
  leagueTypesWithEntryThreshold?: Maybe<Array<LeagueTypesWithEntryThreshold>>;
  createdAt: Scalars['Date'];
  groupName?: Maybe<Scalars['String']>;
};

export enum LeaderboardType {
  Main = 'MAIN',
  Newbie = 'NEWBIE',
  Lead = 'LEAD',
  FullTime = 'FULL_TIME'
}

export type LeaderboardDivision = {
  __typename?: 'LeaderboardDivision';
  id: Scalars['Int'];
  isBlocked: Scalars['Boolean'];
  leagueId: Scalars['Int'];
  createdAt: Scalars['Date'];
  divisionUsers: Array<LeaderboardUser>;
};

export enum LeaderboardLeagueType {
  Bronze = 'bronze',
  Silver = 'silver',
  Gold = 'gold',
  Platinum = 'platinum',
  Newbie = 'newbie',
  Lead = 'lead',
  FullTime = 'full_time'
}

export type LeaderboardLeague = {
  __typename?: 'LeaderboardLeague';
  id: Scalars['Int'];
  leaderboardId: Scalars['Int'];
  type: LeaderboardLeagueType;
};

export type LeagueTypesWithEntryThreshold = {
  __typename?: 'LeagueTypesWithEntryThreshold';
  leagueType: LeaderboardLeagueType;
  entryThreshold: Scalars['Int'];
};

export type XpPerDayData = {
  __typename?: 'XpPerDayData';
  dayDateISOString: Scalars['String'];
  collectedXp: Scalars['Int'];
  leaderboardUserId: Scalars['Int'];
};

export type LeaderboardParticipant = {
  __typename?: 'LeaderboardParticipant';
  id: Scalars['Int'];
  username?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  avatar?: Maybe<File>;
  languageId?: Maybe<Scalars['Int']>;
  activeStreakDaysCount: Scalars['Int'];
};

export type LeaderboardUser = {
  __typename?: 'LeaderboardUser';
  id: Scalars['Int'];
  divisionId: Scalars['Int'];
  leaderboardId: Scalars['Int'];
  leagueId: Scalars['Int'];
  xp: Scalars['Int'];
  userId: Scalars['Int'];
  startedAt: Scalars['Date'];
  leaderboard: Leaderboard;
  league: LeaderboardLeague;
  division: LeaderboardDivision;
  leaderboardParticipant: LeaderboardParticipant;
  position?: Maybe<Scalars['Int']>;
  divisionParticipants: LeaderboardUsersWithPagination;
  meta: LeaderboardUserMeta;
  currentStreakActiveDaysCount: Scalars['Int'];
  totalXP: Scalars['Int'];
  todayXP: Scalars['Int'];
  neighboursParticipants: Array<LeaderboardUser>;
  updatedAt: Scalars['Date'];
};


export type LeaderboardUserNeighboursParticipantsArgs = {
  neighboursCount?: Maybe<Scalars['Int']>;
};

export enum LeaderboardUserXpActivity {
  TaskCompleted = 'TASK_COMPLETED',
  TechCheckAttended = 'TECH_CHECK_ATTENDED',
  QnaAttended = 'QNA_ATTENDED',
  EnglishLessonAttended = 'ENGLISH_LESSON_ATTENDED',
  Distribution = 'DISTRIBUTION',
  StreakXpReceived = 'STREAK_XP_RECEIVED',
  ChatQuestionAnswered = 'CHAT_QUESTION_ANSWERED'
}

export type LeaderboardUserXpHistory = {
  __typename?: 'LeaderboardUserXpHistory';
  userId: Scalars['Int'];
  leaderboardUserId: Scalars['Int'];
  activityType: LeaderboardUserXpActivity;
  estimatedTimeSpent: Scalars['Int'];
  xp: Scalars['Int'];
  testTaskId?: Maybe<Scalars['Int']>;
  eventId?: Maybe<Scalars['Int']>;
  threadId?: Maybe<Scalars['Int']>;
};

export type LeaderboardUserMeta = {
  __typename?: 'LeaderboardUserMeta';
  prevLeaderboard?: Maybe<Leaderboard>;
  lastXpHistoryEvent?: Maybe<LeaderboardUserXpHistory>;
};

export type LeaderboardUsersWithPagination = {
  __typename?: 'LeaderboardUsersWithPagination';
  participants: Array<LeaderboardUser>;
  topPerformers: Array<LeaderboardUser>;
};

export type LeaderboardUserXpHistoryUpdates = {
  __typename?: 'LeaderboardUserXPHistoryUpdates';
  leaderboardUser: LeaderboardUser;
  leaderboardUserXpHistory: LeaderboardUserXpHistory;
};

export type MorningTemplate = {
  __typename?: 'MorningTemplate';
  id: Scalars['Int'];
};

export type LearnMessageTemplate = {
  __typename?: 'LearnMessageTemplate';
  id: Scalars['Int'];
  text: Scalars['String'];
  morningTemplate?: Maybe<MorningTemplate>;
};

export type UpdateLearnVideoOptions = {
  title?: Maybe<Scalars['String']>;
  vimeoUrl?: Maybe<Scalars['String']>;
};

export type LearnVideo = {
  __typename?: 'LearnVideo';
  id: Scalars['Int'];
  vimeoUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  createdAt: Scalars['Date'];
  url?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
  /** @deprecated Use userRating instead */
  rating?: Maybe<Rating>;
  userRating: Scalars['Int'];
  courseTopics: Array<CourseTopic>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use latestReleasedVersion instead */
  latestPublishedVersion?: Maybe<LearnVideoVersion>;
  latestReleasedVersion?: Maybe<LearnVideoVersion>;
  versions: Array<LearnVideoVersion>;
  vimeoDuration?: Maybe<Scalars['Int']>;
};


export type LearnVideoUserRatingArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type LearnVideoVersionsArgs = {
  orderBy?: Maybe<LearnVideoVersionOrderBy>;
  direction?: Maybe<LearnVideoVersionOrderDirection>;
};

export type LearnVideoVersion = {
  __typename?: 'LearnVideoVersion';
  id: Scalars['Int'];
  learnVideoId: Scalars['Int'];
  title: Scalars['String'];
  status: LearnVideoVersionStatus;
  versionNumber: Scalars['Int'];
  youtubeUrl?: Maybe<Scalars['String']>;
  vimeoUrl?: Maybe<Scalars['String']>;
  vimeoDuration?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export enum LearnVideoVersionStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  OnReview = 'ON_REVIEW'
}

export enum LearnVideoVersionOrderBy {
  Id = 'ID'
}

export enum LearnVideoVersionOrderDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type CreateLearnVideoVersion = {
  learnVideoId: Scalars['Int'];
  title: Scalars['String'];
  status: LearnVideoVersionStatus;
  vimeoUrl?: Maybe<Scalars['String']>;
};

export type UpdateLearnVideoVersion = {
  title?: Maybe<Scalars['String']>;
  status?: Maybe<LearnVideoVersionStatus>;
};

export type LiveCodingTask = {
  __typename?: 'LiveCodingTask';
  id: Scalars['Int'];
  testTaskId: Scalars['Int'];
  courseId: Scalars['Int'];
  complexity: Scalars['Int'];
  testTask?: Maybe<TestTask>;
};

export type CreateLiveCodingTaskInput = {
  testTaskId: Scalars['Int'];
  professionSlug: Scalars['String'];
  complexity: Scalars['Int'];
};

export type LmsContentChanges = {
  __typename?: 'LmsContentChanges';
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
  body: Scalars['JSON'];
  instanceNames: Array<Maybe<Scalars['String']>>;
  additionalInfo?: Maybe<ChangesDetails>;
  contentChangeMeta: LmsContentChangesMeta;
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  deletedBy?: Maybe<Scalars['Int']>;
  revertedBy?: Maybe<User>;
  user: User;
  parentName: Array<Maybe<Scalars['String']>>;
};

export type LmsContentChangesForPagination = {
  __typename?: 'LmsContentChangesForPagination';
  totalCount: Scalars['Int'];
  parentNameForTitle: Scalars['String'];
  logs: Array<LmsContentChanges>;
};

export type ChangesDetails = {
  __typename?: 'ChangesDetails';
  isVersionChanged?: Maybe<Scalars['Boolean']>;
  generalInfo?: Maybe<Array<ChangeItem>>;
  config?: Maybe<Array<ChangeItem>>;
  files?: Maybe<Array<FileChangeItem>>;
  parentListChanges?: Maybe<ListChanges>;
  childListChanges?: Maybe<ListChanges>;
  reorderedItems?: Maybe<Array<ReorderItem>>;
  quizChanges?: Maybe<QuizChanges>;
  permissionsChanges?: Maybe<PermissionsEntityChangeInfo>;
};

export type PermissionsEntityChangeInfo = {
  __typename?: 'PermissionsEntityChangeInfo';
  parentEntityType: PermissionEntityType;
  added?: Maybe<Array<PermissionChangeInfo>>;
  deleted?: Maybe<Array<PermissionChangeInfo>>;
  updated?: Maybe<Array<PermissionChangeInfo>>;
};

export type PermissionChangeInfo = {
  __typename?: 'PermissionChangeInfo';
  permission: Scalars['String'];
  resources: ChangeItem;
};

export type QuizChanges = {
  __typename?: 'QuizChanges';
  mainChanges: Array<ChangeItem>;
  questions: Array<QuizQuestionInfo>;
};

export type QuizQuestionInfo = {
  __typename?: 'QuizQuestionInfo';
  id: Scalars['Int'];
  title: Scalars['String'];
  questionInfo: Array<ChangeItem>;
  questionTitle: Array<ChangeItem>;
  questionExplanation: Array<ChangeItem>;
  answers: Array<QuizAnswerInfo>;
};

export type QuizAnswerInfo = {
  __typename?: 'QuizAnswerInfo';
  id: Scalars['Int'];
  answerMainInfo: Array<ChangeItem>;
  valuesForAnswer: Array<ChangeItem>;
};

export enum ChangeEntityType {
  File = 'file',
  Version = 'version'
}

export type ChangeItem = {
  __typename?: 'ChangeItem';
  fieldName: Scalars['String'];
  prevValue?: Maybe<Scalars['String']>;
  nextValue?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChangeEntityType>;
};

export type FileChangeItem = {
  __typename?: 'FileChangeItem';
  nextFileName?: Maybe<Scalars['String']>;
  prevFileName?: Maybe<Scalars['String']>;
  prevContent?: Maybe<Scalars['String']>;
  nextContent?: Maybe<Scalars['String']>;
  fileType: LmsFileType;
};

export type ReorderItem = {
  __typename?: 'ReorderItem';
  name?: Maybe<Scalars['String']>;
  prevOrder: Scalars['Int'];
  nextOrder: Scalars['Int'];
};

export type ListChanges = {
  __typename?: 'ListChanges';
  namesOfRemoved: Array<Maybe<Scalars['String']>>;
  namesOfAdded: Array<Maybe<Scalars['String']>>;
  listItemType?: Maybe<Scalars['String']>;
};

export type LmsContentChangesMeta = {
  __typename?: 'LmsContentChangesMeta';
  actionType: LmsContentChangesActions;
  instanceType: LmsContentChangesInstances;
};

export enum LmsContentChangesActions {
  Adding = 'Adding',
  CreateFromExisting = 'CreateFromExisting',
  Deleting = 'Deleting',
  ChangingTheory = 'ChangingTheory',
  ChangingFields = 'ChangingFields',
  ReorderList = 'ReorderList',
  Moving = 'Moving'
}

export enum LmsContentChangesInstances {
  Profession = 'Profession',
  CourseModule = 'CourseModule',
  Module = 'Module',
  CourseTopic = 'CourseTopic',
  Topic = 'Topic',
  Theory = 'Theory',
  Video = 'Video',
  CourseTopicTask = 'CourseTopicTask',
  Task = 'Task',
  MorningTask = 'MorningTask',
  DailyTask = 'DailyTask',
  LiveCodingTask = 'LiveCodingTask',
  AssignmentTask = 'AssignmentTask',
  Permission = 'Permission',
  TestTaskPreview = 'TestTaskPreview'
}

export enum LmsFileType {
  Description = 'Description',
  Theory = 'Theory',
  Code = 'Code',
  Test = 'Test',
  Reference = 'Reference',
  Template = 'Template'
}

export type ContentChangesFilter = {
  typeOfInstance: LmsContentChangesInstances;
  instanceId: Scalars['Int'];
  permissionsEntityType?: Maybe<PermissionEntityType>;
};

export type LmsSearchConfig = {
  onlyExactMatches?: Maybe<Scalars['Boolean']>;
};

export enum SearchEntity {
  Topic = 'Topic',
  Task = 'Task',
  Video = 'Video'
}

export type SearchResult = {
  __typename?: 'SearchResult';
  topics: Array<Maybe<CourseTopicSearchResult>>;
  tasks: Array<Maybe<TestTaskSearchResult>>;
  videos: Array<Maybe<LearnVideoSearchResult>>;
};

export type CourseTopicSearchResult = {
  __typename?: 'CourseTopicSearchResult';
  id: Scalars['Int'];
  topic?: Maybe<CourseTopic>;
  keywords: Array<Scalars['String']>;
  highlights: Array<Scalars['String']>;
};


export type CourseTopicSearchResultTopicArgs = {
  lang?: Maybe<Scalars['String']>;
};

export type TestTaskSearchResult = {
  __typename?: 'TestTaskSearchResult';
  id: Scalars['Int'];
  task: TestTask;
  keywords: Array<Scalars['String']>;
  highlights: Array<Scalars['String']>;
};

export type LearnVideoSearchResult = {
  __typename?: 'LearnVideoSearchResult';
  id: Scalars['Int'];
  video: LearnVideo;
  keywords: Array<Scalars['String']>;
};

export enum LoanPlanProvider {
  MonoBank = 'MONO_BANK',
  PrivatBank = 'PRIVAT_BANK'
}

export enum LoanPlayMerchantType {
  Ii = 'II',
  Pp = 'PP',
  Pb = 'PB',
  Ia = 'IA'
}

export type ProcessApplicationData = {
  __typename?: 'ProcessApplicationData';
  message?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subState?: Maybe<Scalars['String']>;
};

export type ProcessLoanData = {
  __typename?: 'ProcessLoanData';
  redirectUrl: Scalars['String'];
};

export type ReturnProductData = {
  __typename?: 'ReturnProductData';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type LoanPlan = {
  __typename?: 'LoanPlan';
  id: Scalars['Int'];
  store: LoanPlanProvider;
  partsCount: Scalars['Int'];
  merchantType: LoanPlayMerchantType;
  tokenUrl: Scalars['String'];
  redirectUrl: Scalars['String'];
  responseUrl: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  active?: Maybe<Scalars['Boolean']>;
};

export enum LoanProviders {
  Privatbank = 'privatbank',
  Monobank = 'monobank',
  Alfabank = 'alfabank',
  AlfabankAuto = 'alfabank_auto',
  Knoma = 'knoma',
  PayU = 'pay_u',
  Ukrsibbank = 'ukrsibbank',
  WayForPay = 'way_for_pay'
}

export type LoanProvider = {
  __typename?: 'LoanProvider';
  id: Scalars['Int'];
  slug: LoanProviders;
  publicKey?: Maybe<Scalars['String']>;
  partsCount: Scalars['Int'];
  active: Scalars['Boolean'];
  color: Scalars['String'];
  /** @deprecated not used, icons are mapped on UI */
  icons?: Maybe<Array<File>>;
};

export type MarathonEventDetails = {
  __typename?: 'MarathonEventDetails';
  id: Scalars['Int'];
  eventId: Scalars['Int'];
  slug: Scalars['String'];
  costUsd: Scalars['Float'];
};

export type MarketSalary = {
  __typename?: 'MarketSalary';
  id: Scalars['Int'];
  countryId: Scalars['Int'];
  provider: Scalars['String'];
  dateParsed: Scalars['Date'];
  salaryAmount: Scalars['Int'];
  salaryCurrencyId: Scalars['Int'];
  experienceInMonths: Scalars['Int'];
  professionId: Scalars['Int'];
};

export type CreateMarketSalaryOptions = {
  countryName: Scalars['String'];
  provider: Scalars['String'];
  dateParsed: Scalars['Date'];
  salaryAmount: Scalars['Int'];
  salaryCurrencyCode: Scalars['String'];
  experienceInMonths: Scalars['Int'];
  professionSlug: Scalars['String'];
};

export type MorningTask = {
  __typename?: 'MorningTask';
  id: Scalars['Int'];
  testTaskId: Scalars['Int'];
  coursesIds: Array<Scalars['Int']>;
  courseTopicId?: Maybe<Scalars['Int']>;
  courseTopic?: Maybe<CourseTopic>;
  complexity: Scalars['Int'];
  testTask?: Maybe<TestTask>;
  isOptional: Scalars['Boolean'];
};

export type CreateOrUpdateMorningTaskInput = {
  testTaskId: Scalars['Int'];
  professionSlug: Scalars['String'];
  complexity: Scalars['Int'];
  courseTopicId?: Maybe<Scalars['Int']>;
  isOptional?: Maybe<Scalars['Boolean']>;
};

export type UpdateMorningTaskInput = {
  testTaskId?: Maybe<Scalars['Int']>;
  coursesIds?: Maybe<Array<Scalars['Int']>>;
  courseTopicId?: Maybe<Scalars['Int']>;
  complexity?: Maybe<Scalars['Int']>;
  isOptional?: Maybe<Scalars['Boolean']>;
};

export type CreateMorningTaskInput = {
  testTaskId: Scalars['Int'];
  courseId: Scalars['Int'];
  complexity: Scalars['Int'];
  courseTopicId?: Maybe<Scalars['Int']>;
  isOptional?: Maybe<Scalars['Boolean']>;
};

export enum OAuthProviders {
  Github = 'github',
  Google = 'google',
  Facebook = 'facebook',
  Linkedin = 'linkedin',
  Apple = 'apple'
}

export type ConnectOAuthProviderResponse = {
  __typename?: 'ConnectOAuthProviderResponse';
  alreadyConnected: Scalars['Boolean'];
};

export type OAuthProfile = {
  __typename?: 'OAuthProfile';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['String']>;
};

export type OAuthToken = {
  __typename?: 'OAuthToken';
  id: Scalars['Int'];
  providerName: Scalars['String'];
  providerId: Scalars['String'];
  token: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  id?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  uuid?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  payment?: Maybe<Payment>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id?: Maybe<Scalars['Int']>;
  orderId: Scalars['Int'];
  relatedId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  currencyId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  order?: Maybe<Order>;
};

export type PartnershipRecommendedStudent = {
  __typename?: 'PartnershipRecommendedStudent';
  id: Scalars['Int'];
  recommendationDate?: Maybe<Scalars['Date']>;
  coordinatorUserId?: Maybe<Scalars['Int']>;
  studentUserId: Scalars['Int'];
  partnershipVacancyId: Scalars['Int'];
  status: RecommendedStudentStatus;
  bpmRecommendedStudentId: Scalars['String'];
  offerDate?: Maybe<Scalars['Date']>;
};

export type CreatePartnershipRecommendedStudentFromBpmValues = {
  recommendationDate?: Maybe<Scalars['Date']>;
  bpmCoordinatorUserId?: Maybe<Scalars['String']>;
  bpmStudentUserId: Scalars['String'];
  bpmPartnershipVacancyId: Scalars['String'];
  status: RecommendedStudentStatus;
  bpmRecommendedStudentId: Scalars['String'];
  offerDate?: Maybe<Scalars['Date']>;
};

export enum RecommendedStudentStatus {
  OnReview = 'ON_REVIEW',
  Hired = 'HIRED',
  RejectedByCompany = 'REJECTED_BY_COMPANY',
  RejectedByCandidate = 'REJECTED_BY_CANDIDATE',
  GotAnotherOffer = 'GOT_ANOTHER_OFFER',
  WasNotProcessed = 'WAS_NOT_PROCESSED'
}

export type PartnershipVacancy = {
  __typename?: 'PartnershipVacancy';
  id: Scalars['Int'];
  name: Scalars['String'];
  englishLevelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  cityId?: Maybe<Scalars['Int']>;
  status: PartnershipVacancyStatus;
  entryLevel?: Maybe<PartnershipVacancyEntryLevel>;
  track?: Maybe<PartnershipVacancyTrack>;
  partnershipManagerId?: Maybe<Scalars['Int']>;
  source?: Maybe<PartnershipVacancySource>;
  terminationBasis?: Maybe<PartnershipVacancyTerminationBasis>;
  bpmPartnershipVacancyId: Scalars['String'];
};

export type CreatePartnershipVacancyFromBpmValues = {
  name: Scalars['String'];
  englishLevel?: Maybe<Scalars['String']>;
  bpmCompanyId?: Maybe<Scalars['String']>;
  bpmCityId?: Maybe<Scalars['String']>;
  status: PartnershipVacancyStatus;
  entryLevel?: Maybe<PartnershipVacancyEntryLevel>;
  track?: Maybe<PartnershipVacancyTrack>;
  bpmPartnershipManagerId?: Maybe<Scalars['String']>;
  source?: Maybe<PartnershipVacancySource>;
  terminationBasis?: Maybe<PartnershipVacancyTerminationBasis>;
  bpmPartnershipVacancyId: Scalars['String'];
};

export enum PartnershipVacancyStatus {
  Draft = 'DRAFT',
  ToContact = 'TO_CONTACT',
  Contacted = 'CONTACTED',
  Recommended = 'RECOMMENDED',
  Offer = 'OFFER',
  NoOffer = 'NO_OFFER',
  Cancelled = 'CANCELLED'
}

export enum PartnershipVacancyEntryLevel {
  UnpaidInternship = 'UNPAID_INTERNSHIP',
  Junior = 'JUNIOR',
  Middle = 'MIDDLE',
  Senior = 'SENIOR',
  PaidInternship = 'PAID_INTERNSHIP'
}

export enum PartnershipVacancyTrack {
  Backend = 'BACKEND',
  Design = 'DESIGN',
  Devops = 'DEVOPS',
  Frontend = 'FRONTEND',
  Java = 'JAVA',
  Marketing = 'MARKETING',
  Other = 'OTHER',
  Python = 'PYTHON',
  Qa = 'QA',
  Recruitment = 'RECRUITMENT'
}

export enum PartnershipVacancySource {
  Partnership = 'PARTNERSHIP',
  Referral = 'REFERRAL',
  Reinforcement = 'REINFORCEMENT'
}

export enum PartnershipVacancyTerminationBasis {
  NoCandidates = 'NO_CANDIDATES',
  Ignore = 'IGNORE',
  DirectResponse = 'DIRECT_RESPONSE'
}

export type PartnershipVacancyStatusHistory = {
  __typename?: 'PartnershipVacancyStatusHistory';
  id: Scalars['Int'];
  partnershipVacancyId: Scalars['Int'];
  status: PartnershipVacancyStatusHistoryStatus;
  start: Scalars['Date'];
  due?: Maybe<Scalars['Date']>;
  bpmVacancyStatusId: Scalars['String'];
};

export type CreatePartnershipVacancyStatusHistoryFromBpmValues = {
  bpmPartnershipVacancyId: Scalars['String'];
  status: PartnershipVacancyStatusHistoryStatus;
  start: Scalars['Date'];
  due?: Maybe<Scalars['Date']>;
  bpmVacancyStatusId: Scalars['String'];
};

export enum PartnershipVacancyStatusHistoryStatus {
  Draft = 'DRAFT',
  ToContact = 'TO_CONTACT',
  Contacted = 'CONTACTED',
  Recommended = 'RECOMMENDED',
  Offer = 'OFFER',
  NoOffer = 'NO_OFFER',
  Cancelled = 'CANCELLED'
}

export enum PaymentTypes {
  Hold = 'HOLD',
  MonthPayment = 'MONTH_PAYMENT',
  RegularPayment = 'REGULAR_PAYMENT',
  LoanPayment = 'LOAN_PAYMENT'
}

export enum TransactionTypes {
  Charge = 'CHARGE',
  Auth = 'AUTH',
  Refund = 'REFUND'
}

export enum PaymentStatus {
  New = 'new',
  Accepted = 'accepted',
  OnReview = 'on_review',
  Under_3dSecure = 'under_3d_secure',
  Declined = 'declined',
  Failed = 'failed'
}

export enum PaymentInitiators {
  Manual = 'MANUAL',
  Auto = 'AUTO'
}

export enum PaymentsOrderBy {
  CreatedAt = 'createdAt'
}

export enum PaymentsOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type UserPaymentsWithPagination = {
  __typename?: 'UserPaymentsWithPagination';
  edges: Array<Payment>;
  totalCount: Scalars['Int'];
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['Int'];
  uuid: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  amountCurrency?: Maybe<Currency>;
  status?: Maybe<PaymentStatus>;
  createdAt?: Maybe<Scalars['Date']>;
  errorMessage?: Maybe<Scalars['String']>;
  /** @deprecated Job rating is not related to payment anymore */
  jobRating?: Maybe<JobRating>;
  user?: Maybe<User>;
  type?: Maybe<PaymentTypes>;
  transactionType?: Maybe<TransactionTypes>;
  checkoutFormUrl?: Maybe<Scalars['String']>;
  checkoutMerchantData?: Maybe<PaymentMerchantData>;
  description?: Maybe<Scalars['String']>;
  paymentProvider?: Maybe<PaymentProvider>;
  loanProvider?: Maybe<LoanProvider>;
  receipt?: Maybe<Scalars['String']>;
  purchaseLinkId?: Maybe<Scalars['Int']>;
  convertedCurrency?: Maybe<Currency>;
  convertedAmount?: Maybe<Scalars['Float']>;
  userCardId?: Maybe<Scalars['Int']>;
  loanOrderId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  bankDetailsId?: Maybe<Scalars['Int']>;
  refundDate?: Maybe<Scalars['Date']>;
  billingAddressId?: Maybe<Scalars['Int']>;
  paymentInitiator?: Maybe<PaymentInitiators>;
  generatedPurchaseLink?: Maybe<GeneratedPurchaseLink>;
  subscription?: Maybe<PaymentSubscription>;
};

export type PaymentResult = {
  __typename?: 'PaymentResult';
  status: Scalars['String'];
  redirect?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
};

export type AnonymousPaymentResult = {
  __typename?: 'AnonymousPaymentResult';
  status: Scalars['String'];
  redirect?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  userAnalyticsData: UserBaseAnalyticsInfo;
};

export type PaymentsStatistic = {
  __typename?: 'PaymentsStatistic';
  percentile_25: Scalars['Float'];
  percentile_50: Scalars['Float'];
  percentile_75: Scalars['Float'];
  payment_number: Scalars['Int'];
};

export type UserPaymentsStatistic = {
  __typename?: 'userPaymentsStatistic';
  salary_usd: Scalars['Float'];
};

export type PaymentMerchantData = {
  __typename?: 'PaymentMerchantData';
  id: Scalars['Int'];
  merchant: Scalars['String'];
  signature: Scalars['String'];
  paymentIntent: Scalars['String'];
};

export type PaymentsOrderOptions = {
  by: PaymentsOrderBy;
  direction: PaymentsOrderDirection;
};

export enum PaymentProviders {
  Solidgate = 'solidgate',
  SolidgateKnowely = 'solidgate_knowely',
  Paymentwall = 'paymentwall',
  PaymentwallCheckout = 'paymentwall_checkout',
  FopMax = 'fop_max',
  Llc = 'llc',
  PayU = 'pay_u',
  Monobank = 'monobank',
  Stripe = 'stripe',
  LlcPl = 'llc_pl',
  Liqpay = 'liqpay',
  AliorBank = 'alior_bank',
  PagarMe = 'pagar_me',
  Provi = 'provi',
  Lia = 'lia',
  Santander = 'santander',
  ProviDinamico = 'provi_dinamico',
  LlcCy = 'llc_cy',
  WayForPay = 'way_for_pay',
  StripeKnowely = 'stripe_knowely',
  LlcBr = 'llc_br'
}

export enum PrincipiaCreditCheckStatus {
  Approved = 'approved',
  Denied = 'denied',
  Error = 'error',
  Timeout = 'timeout',
  InvalidZipCode = 'invalidZipCode'
}

export enum StripePaymentIntentStatus {
  Canceled = 'canceled',
  Processing = 'processing',
  RequiresAction = 'requires_action',
  RequiresCapture = 'requires_capture',
  RequiresConfirmation = 'requires_confirmation',
  RequiresPaymentMethod = 'requires_payment_method',
  Succeeded = 'succeeded'
}

export type PwlCardToken = {
  token: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
};

export type PaymentAnalyticsData = {
  fvType?: Maybe<Scalars['String']>;
  fvSource?: Maybe<Scalars['String']>;
  fvMedium?: Maybe<Scalars['String']>;
  fvCampaign?: Maybe<Scalars['String']>;
  fvContent?: Maybe<Scalars['String']>;
  fvTerm?: Maybe<Scalars['String']>;
  fvPage?: Maybe<Scalars['String']>;
  lvType?: Maybe<Scalars['String']>;
  lvSource?: Maybe<Scalars['String']>;
  lvMedium?: Maybe<Scalars['String']>;
  lvCampaign?: Maybe<Scalars['String']>;
  lvContent?: Maybe<Scalars['String']>;
  lvTerm?: Maybe<Scalars['String']>;
  lvAccountId?: Maybe<Scalars['String']>;
  lvCampaignId?: Maybe<Scalars['String']>;
  lvAdGroupId?: Maybe<Scalars['String']>;
  lvAdId?: Maybe<Scalars['String']>;
  lvTermId?: Maybe<Scalars['String']>;
  gClientid?: Maybe<Scalars['String']>;
  gAgent?: Maybe<Scalars['String']>;
  gclid?: Maybe<Scalars['String']>;
  utmDevicetype?: Maybe<Scalars['String']>;
  utmPlacement?: Maybe<Scalars['String']>;
  fbp?: Maybe<Scalars['String']>;
  fbc?: Maybe<Scalars['String']>;
  fbclid?: Maybe<Scalars['String']>;
  rdtcid?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
  analyticsUserId: Scalars['String'];
};

export type PaymentProvider = {
  __typename?: 'PaymentProvider';
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  slug: PaymentProviders;
  name: Scalars['String'];
  publicKey?: Maybe<Scalars['String']>;
  isConversionEnabled: Scalars['Boolean'];
};

export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  id: Scalars['String'];
  /** @deprecated Don't send this to FE, it's not needed for payment method update */
  object: Scalars['String'];
  client_secret: Scalars['String'];
};

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  id: Scalars['String'];
  client_secret: Scalars['String'];
};

export type CreatePrincipiaExclusiveSaleResult = {
  __typename?: 'CreatePrincipiaExclusiveSaleResult';
  creditCheckStatus: PrincipiaCreditCheckStatus;
};

export type FindPaymentProviderCourseOffersResult = {
  __typename?: 'FindPaymentProviderCourseOffersResult';
  principiaOffers?: Maybe<Array<Maybe<PrincipiaCourseClass>>>;
  liaOffers?: Maybe<Array<Maybe<LiaOffer>>>;
};

export type PrincipiaCourseClass = {
  __typename?: 'PrincipiaCourseClass';
  id: Scalars['Int'];
  name: Scalars['String'];
  priceInCents: Scalars['Int'];
};

export type LiaOffer = {
  __typename?: 'LiaOffer';
  id: Scalars['String'];
  name: Scalars['String'];
  amount_cents: Scalars['Int'];
};

export type StripePaymentIntentUserData = {
  __typename?: 'StripePaymentIntentUserData';
  email?: Maybe<Scalars['String']>;
  status: PaymentStatus;
  planOrOptionUUID?: Maybe<Scalars['String']>;
};

export type PrepareSubscriptionCheckoutData = {
  __typename?: 'PrepareSubscriptionCheckoutData';
  paymentMerchantData: PaymentMerchantData;
  orderId: Scalars['String'];
  pricingOption: SubscriptionPlanPricingOption;
};

export type PaymentPingback = {
  __typename?: 'PaymentPingback';
  id: Scalars['Int'];
  paymentStatus: PaymentStatus;
  providerStatus: Scalars['String'];
  paymentTransactionId: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type PeopleImages = {
  __typename?: 'PeopleImages';
  id: Scalars['Int'];
  slug: Scalars['String'];
  singleImage?: Maybe<File>;
  gridImages: Array<File>;
};

export type PermissionResourcesSearchResult = {
  __typename?: 'PermissionResourcesSearchResult';
  ids?: Maybe<Array<Scalars['Int']>>;
  slugs?: Maybe<Array<Scalars['String']>>;
};

export type UserHasPermission = {
  __typename?: 'UserHasPermission';
  userId: Scalars['Int'];
  hasPermission: Scalars['Boolean'];
};

export type PermissionWithConfig = {
  __typename?: 'PermissionWithConfig';
  name: Scalars['String'];
  resourcesConfig?: Maybe<ResourceConfig>;
};

export type ResourceConfig = {
  __typename?: 'ResourceConfig';
  resourceType: Scalars['String'];
  entityName: Scalars['String'];
};

export type GroupedByParentPermissions = {
  __typename?: 'GroupedByParentPermissions';
  id: Scalars['String'];
  parent: GrantedPermissionsParent;
  permissions: Array<GrantedPermission>;
};

export type UserRoleEntity = {
  __typename?: 'UserRoleEntity';
  id: Scalars['Int'];
  role: Scalars['String'];
};

export type SubscriptionTypeEntity = {
  __typename?: 'SubscriptionTypeEntity';
  id: Scalars['Int'];
  type: Scalars['String'];
};

export type EnglishLevelEntity = {
  __typename?: 'EnglishLevelEntity';
  id: Scalars['Int'];
  level: Scalars['String'];
};

export type GrantedPermissionResources = {
  __typename?: 'GrantedPermissionResources';
  id?: Maybe<Array<Scalars['Int']>>;
  slug?: Maybe<Array<Scalars['String']>>;
  any?: Maybe<Scalars['Boolean']>;
};

export type GrantedPermission = {
  __typename?: 'GrantedPermission';
  id: Scalars['Int'];
  permission: Scalars['String'];
  resources: GrantedPermissionResources;
};

export type GrantedPermissionsParent = Course | SubscriptionGrant | EnglishLevelEntity | UserRoleEntity | User | Domain;

export enum PermissionEntityType {
  Courses = 'courses',
  Roles = 'roles',
  EnglishLevel = 'englishLevel',
  Users = 'users',
  SubscriptionGrants = 'subscriptionGrants',
  Domains = 'domains'
}

export type AdminTools = {
  __typename?: 'AdminTools';
  id: Scalars['String'];
  tools: Array<AdminTool>;
};

export type AdminTool = {
  __typename?: 'AdminTool';
  id: AdminToolType;
  isAvailable: Scalars['Boolean'];
};

export enum AdminToolType {
  EnglishManagement = 'ENGLISH_MANAGEMENT',
  LmsEditor = 'LMS_EDITOR',
  TechCheckManagement = 'TECH_CHECK_MANAGEMENT',
  PermissionManagement = 'PERMISSION_MANAGEMENT',
  LessonGuidelinesEditor = 'LESSON_GUIDELINES_EDITOR',
  HomeworkReviewsPlugin = 'HOMEWORK_REVIEWS_PLUGIN',
  ContractorsManagement = 'CONTRACTORS_MANAGEMENT',
  ExamsManagement = 'EXAMS_MANAGEMENT',
  SubscriptionsManagement = 'SUBSCRIPTIONS_MANAGEMENT'
}

export type GroupedPermissionsFilter = {
  entityType: PermissionEntityType;
  entityId?: Maybe<Scalars['Int']>;
};

export type PermissionResources = {
  slug?: Maybe<Array<Scalars['String']>>;
  any?: Maybe<Scalars['Boolean']>;
};

export type PermissionWithResources = {
  permission: Scalars['String'];
  resources: PermissionResources;
};

export type CreatePrivateEntrepreneurDetailsValues = {
  status: Scalars['String'];
  hasItCodes: Scalars['Boolean'];
  registrationDate?: Maybe<Scalars['Date']>;
  terminationDate?: Maybe<Scalars['Date']>;
  userId: Scalars['Int'];
};

export type UpdatePrivateEntrepreneurDetailsValues = {
  status?: Maybe<Scalars['String']>;
  hasItCodes?: Maybe<Scalars['Boolean']>;
  registrationDate?: Maybe<Scalars['Date']>;
  terminationDate?: Maybe<Scalars['Date']>;
  percentageTaxRate?: Maybe<Scalars['Int']>;
};

export type PrivateEntrepreneurDetails = {
  __typename?: 'PrivateEntrepreneurDetails';
  id: Scalars['Int'];
  status: Scalars['String'];
  hasItCodes: Scalars['Boolean'];
  registrationDate?: Maybe<Scalars['Date']>;
  terminationDate?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  user: User;
  percentageTaxRate?: Maybe<Scalars['Int']>;
};

export enum ProductVariant {
  Knowely = 'knowely',
  Mate = 'mate'
}

export enum ProfessionStatus {
  All = 'ALL',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum ProfessionType {
  CareerPath = 'CAREER_PATH',
  SkillPath = 'SKILL_PATH'
}

export type CourseFields = {
  slug: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  courseLanguageId?: Maybe<Scalars['Int']>;
  preparationModuleId?: Maybe<Scalars['Int']>;
  supportProvider?: Maybe<SupportProviderName>;
  supportLink?: Maybe<Scalars['String']>;
  domainIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  courseIcon?: Maybe<Scalars['Upload']>;
  courseIconSize?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  grantedPermissions?: Maybe<Array<PermissionWithResources>>;
  assignmentTestConfig?: Maybe<CourseAssignmentTestConfigInput>;
  tag?: Maybe<Scalars['String']>;
  vacanciesTracksIds?: Maybe<Array<Scalars['Int']>>;
  assignmentFlow?: Maybe<AssignmentFlow>;
  marketingTag?: Maybe<CourseTag>;
  professionType?: Maybe<ProfessionType>;
  complexity?: Maybe<CourseComplexity>;
  projectsCount?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Float']>;
  descriptions?: Maybe<Array<StringWithLang>>;
};

export type Profession = {
  __typename?: 'Profession';
  id: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  type: ProfessionType;
  description?: Maybe<Scalars['String']>;
  courses: Array<Course>;
  modules: Array<Course>;
  morningTasks: MorningTasksForPagination;
  assignmentTasks: AssignmentTasksForPagination;
  liveCodingTasks: LiveCodingTasksForPagination;
  tag?: Maybe<Scalars['String']>;
  platformDemoCodeEditorImage?: Maybe<File>;
  platformDemoBasicModuleImage?: Maybe<File>;
  mobileDemoAndroidImage?: Maybe<File>;
  mobileDemoIosImage?: Maybe<File>;
  backgroundImage?: Maybe<File>;
  subscriptionPlans: Array<PaymentSubscriptionPlan>;
  order?: Maybe<Scalars['Int']>;
  accentColor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  minimalCourseDuration?: Maybe<Scalars['Int']>;
  vacanciesTracks: Array<VacanciesTrack>;
  studentsCount: Scalars['Int'];
  userCourse?: Maybe<Course>;
  professionUser?: Maybe<ProfessionUser>;
};


export type ProfessionNameArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type ProfessionDescriptionArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type ProfessionCoursesArgs = {
  types?: Maybe<Array<CourseType>>;
  withInactive?: Maybe<Scalars['Boolean']>;
};


export type ProfessionModulesArgs = {
  isAllModules?: Maybe<Scalars['Boolean']>;
};


export type ProfessionMorningTasksArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
};


export type ProfessionAssignmentTasksArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type ProfessionLiveCodingTasksArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type ProfessionSubscriptionPlansArgs = {
  domain: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
};


export type ProfessionUserCourseArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type ProfessionProfessionUserArgs = {
  userId?: Maybe<Scalars['Int']>;
};

export type ProfessionUser = {
  __typename?: 'ProfessionUser';
  id: Scalars['Int'];
  professionId: Scalars['Int'];
  userId: Scalars['Int'];
  createdAt: Scalars['Date'];
  activeAt?: Maybe<Scalars['Date']>;
  profession: Profession;
};

export type MorningTasksForPagination = {
  __typename?: 'MorningTasksForPagination';
  totalCount: Scalars['Int'];
  edges: Array<MorningTask>;
};

export type LiveCodingTasksForPagination = {
  __typename?: 'LiveCodingTasksForPagination';
  totalCount: Scalars['Int'];
  edges: Array<LiveCodingTask>;
};

export type AssignmentTasksForPagination = {
  __typename?: 'AssignmentTasksForPagination';
  totalCount: Scalars['Int'];
  edges: Array<AssignmentTask>;
};

export enum PromoCodeStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Expired = 'EXPIRED'
}

export enum PromoCodeType {
  Internal = 'INTERNAL',
  Partner = 'PARTNER'
}

export type PromoCodeTimerConfig = {
  __typename?: 'PromoCodeTimerConfig';
  repeatDaysSteps?: Maybe<Array<Scalars['Int']>>;
  hideDays?: Maybe<Array<Scalars['Int']>>;
};

export type PromoCode = {
  __typename?: 'PromoCode';
  id: Scalars['Int'];
  slug: Scalars['String'];
  offer: Scalars['String'];
  domainId?: Maybe<Scalars['Int']>;
  isActive: Scalars['Boolean'];
  status: PromoCodeStatus;
  type: PromoCodeType;
  startedAt: Scalars['String'];
  expiredAt?: Maybe<Scalars['String']>;
  pagePattern?: Maybe<Scalars['String']>;
  timerConfig?: Maybe<PromoCodeTimerConfig>;
  pageElements: Array<PromoCodePageElement>;
  bannerTitleText: Scalars['String'];
  bannerButtonText: Scalars['String'];
  pageSectionTitleText: Scalars['String'];
  pageSectionDescriptionText: Scalars['String'];
  pageSectionButtonText: Scalars['String'];
};


export type PromoCodeOfferArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type PromoCodeBannerTitleTextArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type PromoCodeBannerButtonTextArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type PromoCodePageSectionTitleTextArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type PromoCodePageSectionDescriptionTextArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type PromoCodePageSectionButtonTextArgs = {
  lang?: Maybe<Scalars['String']>;
};

export enum PromoCodePageElementType {
  Banner = 'BANNER',
  PageSection = 'PAGE_SECTION'
}

export type PromoCodePageElement = {
  __typename?: 'PromoCodePageElement';
  id: Scalars['Int'];
  promoCodeId: Scalars['Int'];
  elementType: PromoCodePageElementType;
  isActive: Scalars['Boolean'];
};

export enum ProofOfRevenueStatus {
  Uploaded = 'UPLOADED',
  OnReview = 'ON_REVIEW',
  Accepted = 'ACCEPTED',
  Failed = 'FAILED',
  Declined = 'DECLINED'
}

export type ProofOfRevenue = {
  __typename?: 'ProofOfRevenue';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  employmentTypeId: Scalars['Int'];
  proofOfRevenuePeriodId: Scalars['Int'];
  salary?: Maybe<Scalars['Float']>;
  salaryCurrencyId?: Maybe<Scalars['Int']>;
  status: ProofOfRevenueStatus;
  comment?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  documents: Array<ProofOfRevenueFile>;
  proofOfRevenuePeriodData: ProofOfRevenuePeriodData;
  createdAt: Scalars['Date'];
  notes?: Maybe<Scalars['String']>;
};

export type ProofOfRevenueFile = {
  __typename?: 'ProofOfRevenueFile';
  type: ProofOfDocumentType;
  file: File;
};

export type ProofOfRevenueDocument = {
  type: ProofOfDocumentType;
  file: Scalars['Upload'];
  size?: Maybe<Scalars['Int']>;
};

export type NextProofOfRevenueStatus = {
  __typename?: 'NextProofOfRevenueStatus';
  shouldUploadProofOfRevenue: Scalars['Boolean'];
  proofOfRevenuePeriodData?: Maybe<ProofOfRevenuePeriodData>;
  messageCode: Scalars['String'];
  shouldSetTaxRate: Scalars['Boolean'];
  isMakePaymentAvailable: Scalars['Boolean'];
  paymentErrorCode: Scalars['String'];
};

export type UpdateProofOfRevenueStatusInput = {
  status: ProofOfRevenueStatus;
  errorCode?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
};

export enum ProofOfDocumentType {
  Declaration = 'declaration',
  DeclarationAcceptanceReference = 'declaration_acceptance_reference',
  CertificateOfIncome = 'certificate_of_income'
}

export type ProofOfRevenuePeriod = {
  __typename?: 'ProofOfRevenuePeriod';
  id: Scalars['Int'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  collectionStartDate: Scalars['Date'];
  deadlineDate: Scalars['Date'];
  active: Scalars['Boolean'];
  required: Scalars['Boolean'];
  employmentTypeId: Scalars['Int'];
};

export type ProofOfRevenuePeriodData = {
  __typename?: 'ProofOfRevenuePeriodData';
  periodName: Scalars['String'];
  periodYear: Scalars['Int'];
  periodNumber: Scalars['String'];
  deadlineDate: Scalars['Date'];
  collectionStartDate: Scalars['Date'];
};

export type PublishingReview = {
  __typename?: 'PublishingReview';
  id: Scalars['Int'];
  publishingName: Scalars['String'];
  link: Scalars['String'];
  logo?: Maybe<File>;
  description?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export enum RatingItemType {
  TestTasks = 'test_tasks',
  TestTaskAiSuggestions = 'test_task_ai_suggestions',
  AiCodeReviews = 'ai_code_reviews',
  CourseTopics = 'course_topics',
  LearnVideos = 'learn_videos',
  Vacancies = 'vacancies',
  TestTaskLoading = 'test_task_loading',
  CourseTopicLoading = 'course_topic_loading',
  Hirings = 'hirings',
  HiringsBadCandidate = 'hirings_bad_candidate',
  ProofOfRevenueProcessing = 'proof_of_revenue_processing',
  TasksReviews = 'tasks_reviews',
  TechChecks = 'tech_checks',
  Qna = 'qna',
  AiAssistantQuickActions = 'ai_assistant_quick_actions',
  EnglishLessons = 'english_lessons',
  Module = 'module'
}

export enum RatingWorkspace {
  Lms = 'LMS',
  Hirings = 'HIRINGS',
  Editor = 'EDITOR',
  ProofOfRevenue = 'PROOF_OF_REVENUE',
  Notifications = 'NOTIFICATIONS'
}

export enum RelatedParentType {
  Topic = 'TOPIC'
}

export enum RatingsFeedbackType {
  Content = 'CONTENT',
  Performance = 'PERFORMANCE',
  Functionality = 'FUNCTIONALITY',
  TaskSolving = 'TASK_SOLVING',
  AiAssistant = 'AI_ASSISTANT',
  NotSelected = 'NOT_SELECTED',
  Module = 'MODULE',
  MobileApp = 'MOBILE_APP'
}

export enum RelatedRootType {
  Course = 'COURSE'
}

export type RatedItem = TestTask | LearnVideo | CourseTopic;

export type RatedItemChild = QuizQuestion | TestTask;

export type RatingFull = {
  __typename?: 'RatingFull';
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  relatedId?: Maybe<Scalars['Int']>;
  relatedType?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  positiveRating?: Maybe<Scalars['Int']>;
  negativeRating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  relatedChildId?: Maybe<Scalars['Int']>;
  relatedChildType?: Maybe<Scalars['String']>;
  relatedChildTitle?: Maybe<Scalars['String']>;
  relatedParentId?: Maybe<Scalars['Int']>;
  relatedParentType?: Maybe<RelatedParentType>;
  relatedRootId?: Maybe<Scalars['Int']>;
  relatedRootType?: Maybe<RelatedRootType>;
  feedbackType?: Maybe<RatingsFeedbackType>;
  workspace?: Maybe<RatingWorkspace>;
};

export type Feedback = {
  __typename?: 'Feedback';
  id: Scalars['Int'];
  relatedType: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  workspace?: Maybe<RatingWorkspace>;
  rating: Scalars['Int'];
  createdAt: Scalars['Date'];
  related?: Maybe<RatedItem>;
  relatedChild?: Maybe<RatedItemChild>;
  relatedParent?: Maybe<CourseTopic>;
  relatedRoot?: Maybe<Course>;
  feedbackType?: Maybe<RatingsFeedbackType>;
  user: User;
};

export type Rating = {
  __typename?: 'Rating';
  userRating?: Maybe<Scalars['Int']>;
  positiveRating?: Maybe<Scalars['Int']>;
  negativeRating?: Maybe<Scalars['Int']>;
};

export enum ProfileStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type UpdateRecruiterProfileValues = {
  status?: Maybe<ProfileStatus>;
  position?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  companyWebsite?: Maybe<Scalars['String']>;
  companyDouLink?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  hasAcceptedTermsOfUse?: Maybe<Scalars['Boolean']>;
};

export type RecruiterProfile = {
  __typename?: 'RecruiterProfile';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  status: ProfileStatus;
  position: Scalars['String'];
  companyId: Scalars['Int'];
  companyWebsite?: Maybe<Scalars['String']>;
  companyDouLink?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  company?: Maybe<Company>;
  hasAcceptedTermsOfUse: Scalars['Boolean'];
};

export type ReferralCode = {
  __typename?: 'ReferralCode';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  code: Scalars['String'];
};

export enum ReferralEventStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Pending = 'PENDING'
}

export enum ReferralEventType {
  ReferralCreated = 'REFERRAL_CREATED',
  ReferralBecameStudent = 'REFERRAL_BECAME_STUDENT',
  ReferralPaymentProcessed = 'REFERRAL_PAYMENT_PROCESSED',
  ReferrerMoneyWithdraw = 'REFERRER_MONEY_WITHDRAW'
}

export type ReferralEvent = {
  __typename?: 'ReferralEvent';
  id: Scalars['Int'];
  status: ReferralEventStatus;
  type: ReferralEventType;
  amount: Scalars['Float'];
  referralUserId?: Maybe<Scalars['Int']>;
  referrerUserId?: Maybe<Scalars['Int']>;
  referral?: Maybe<User>;
  referrer?: Maybe<User>;
  card?: Maybe<Scalars['String']>;
  holder?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UpdateRegionValues = {
  bpmId?: Maybe<Scalars['String']>;
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['Int'];
  name: Scalars['String'];
  cities?: Maybe<Array<Maybe<City>>>;
  bpmId?: Maybe<Scalars['String']>;
  googleName?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
  country?: Maybe<Country>;
};

export type Review = {
  __typename?: 'Review';
  id: Scalars['Int'];
  comment: Scalars['String'];
  photo?: Maybe<File>;
  authorAvatar?: Maybe<File>;
  authorLinkedin?: Maybe<Scalars['String']>;
  authorJobTitle?: Maybe<Scalars['String']>;
  authorUsername: Scalars['String'];
  sourceLink?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type ReviewCommentArgs = {
  lang?: Maybe<Scalars['String']>;
};

export enum ReviewType {
  Text = 'text',
  Video = 'video',
  All = 'all'
}

export type RunCodeFile = {
  path: Scalars['String'];
  content: Scalars['String'];
};

export type Sandbox = {
  __typename?: 'Sandbox';
  id: Scalars['Int'];
  createdBy: Scalars['Int'];
  technology: Technology;
  mainEntity?: Maybe<Scalars['String']>;
  state: SandboxState;
  lastRunId?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  files: Array<SandboxFile>;
};

export type SandboxFile = {
  __typename?: 'SandboxFile';
  id: Scalars['Int'];
  path: Scalars['String'];
  kind: SandboxFileKind;
  content: Scalars['String'];
  isReadonly: Scalars['Boolean'];
  isEntry: Scalars['Boolean'];
};

export enum SandboxFileKind {
  Binary = 'BINARY',
  Text = 'TEXT'
}

export enum SandboxState {
  Default = 'DEFAULT',
  Running = 'RUNNING'
}

export enum Technology {
  Javascript = 'JAVASCRIPT'
}

export enum SandboxRunCodeLogStream {
  Stdout = 'stdout',
  Stderr = 'stderr'
}

export type SandboxRunCodeLog = {
  __typename?: 'SandboxRunCodeLog';
  timestamp: Scalars['Date'];
  message?: Maybe<Scalars['String']>;
  stream: SandboxRunCodeLogStream;
};

export type SandboxRunCodeSuccessResult = {
  __typename?: 'SandboxRunCodeSuccessResult';
  runId: Scalars['String'];
  sandboxId: Scalars['Int'];
  exitCode: Scalars['Int'];
  logs: Array<SandboxRunCodeLog>;
};

export type SandboxRunCodeFailedResult = {
  __typename?: 'SandboxRunCodeFailedResult';
  runId: Scalars['String'];
  sandboxId: Scalars['Int'];
  message: Scalars['String'];
};

export type ChatMessageUnsavedPayload = {
  __typename?: 'ChatMessageUnsavedPayload';
  messageId: Scalars['Int'];
};

export type CreateScheduledMessageFields = {
  chatIds?: Maybe<Array<Scalars['Int']>>;
  userId: Scalars['Int'];
  text: Scalars['String'];
  timeSettings: ScheduledMessageTimeSettingsInput;
};

export type ScheduledMessage = {
  __typename?: 'ScheduledMessage';
  id: Scalars['Int'];
  recipientChatIds: Array<Scalars['Int']>;
  chats: Array<AbstractChat>;
  senderUserId: Scalars['Int'];
  sender: ScheduledMessageSender;
  recurringPattern: Scalars['String'];
  timeSettings: ScheduledMessageTimeSettings;
  messageId: Scalars['Int'];
  text: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type ScheduledMessageSender = {
  __typename?: 'ScheduledMessageSender';
  id: Scalars['Int'];
  username?: Maybe<Scalars['String']>;
  avatar?: Maybe<File>;
};

export type ScheduledMessageTimeSettingsInput = {
  hours: Scalars['Int'];
  minutes: Scalars['Int'];
  weekdays: Array<WeekDay>;
};

export type ScheduledMessageTimeSettings = {
  __typename?: 'ScheduledMessageTimeSettings';
  id: Scalars['String'];
  hours: Scalars['Int'];
  minutes: Scalars['Int'];
  weekdays: Array<WeekDay>;
};

export enum WeekDay {
  Mon = 'MON',
  Tue = 'TUE',
  Wed = 'WED',
  Thu = 'THU',
  Fri = 'FRI',
  Sat = 'SAT',
  Sun = 'SUN'
}

export type Skill = {
  __typename?: 'Skill';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type SlackInvitationCount = {
  __typename?: 'SlackInvitationCount';
  id: Scalars['Int'];
  inviteLink: Scalars['String'];
  invitationCount: Scalars['Int'];
};

export type SoftDeadline = {
  __typename?: 'SoftDeadline';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  courseId: Scalars['Int'];
  scalingCoefficient: Scalars['Float'];
  endsAt: Scalars['Date'];
  modulesDeadlines?: Maybe<Array<ModuleDeadline>>;
};

export type ModuleDeadline = {
  __typename?: 'ModuleDeadline';
  courseId: Scalars['Int'];
  endsAt: Scalars['Date'];
};

export type UpdateSoftDeadlineInput = {
  scalingCoefficient: Scalars['Float'];
  endsAt: Scalars['Date'];
};

export type ActivateSoftDeadlineInput = {
  courseId: Scalars['Int'];
  scalingCoefficient: Scalars['Float'];
  endsAt: Scalars['Date'];
};

export type SpecialOffersLink = {
  __typename?: 'SpecialOffersLink';
  id: Scalars['Int'];
  uuid: Scalars['String'];
  eventId: Scalars['Int'];
  subscriptionPlans: Array<PaymentSubscriptionPlan>;
  deadlineDate: Scalars['Date'];
  title: Scalars['String'];
  timerTitle: Scalars['String'];
  timerDescription: Scalars['String'];
  placesLeftTitle: Scalars['String'];
  placesLeftDescription: Scalars['String'];
  placesLeft: Scalars['Int'];
  supportLink?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type StaticFile = {
  __typename?: 'StaticFile';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  image?: Maybe<File>;
};

export type CheckInActivity = {
  __typename?: 'CheckInActivity';
  id: Scalars['String'];
  streakDays: Scalars['Int'];
  completedDailyTasks: Scalars['Int'];
  passedAutoTechChecks: Scalars['Int'];
  completedTasks: Scalars['Int'];
};

export type StudentsWithCountResult = {
  __typename?: 'StudentsWithCountResult';
  count?: Maybe<Scalars['Int']>;
  rows: Array<User>;
};

export type StudentsFilter = {
  excludeStudentStatuses?: Maybe<Array<StudentStatus>>;
  query?: Maybe<Scalars['String']>;
};

export type StudentInfo = {
  __typename?: 'StudentInfo';
  userId: Scalars['Int'];
  fullName: Scalars['String'];
  status: StudentStatus;
};

export type StudentsGroup = {
  __typename?: 'StudentsGroup';
  id: Scalars['Int'];
  bpmGroupId?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  spreadsheetId?: Maybe<Scalars['String']>;
  sheetId?: Maybe<Scalars['String']>;
  playlistId?: Maybe<Scalars['Int']>;
  playlist?: Maybe<Scalars['String']>;
  course?: Maybe<Course>;
  capacity?: Maybe<Scalars['Int']>;
  students: StudentsWithCountResult;
  studentsList: Array<StudentInfo>;
  supportLink?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  coaches: Array<User>;
  activeCoachId?: Maybe<Scalars['Int']>;
  lessonLink?: Maybe<Scalars['String']>;
  homeWorkSheetId?: Maybe<Scalars['Int']>;
  typingSheetId?: Maybe<Scalars['Int']>;
  morningTasksSheetId?: Maybe<Scalars['Int']>;
  applyingSheetId?: Maybe<Scalars['Int']>;
  linksSheetId?: Maybe<Scalars['Int']>;
  attendanceSheetId?: Maybe<Scalars['Int']>;
  ratingSheetId?: Maybe<Scalars['Int']>;
  lessonZeroStarted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  achievements?: Maybe<Array<Achievement>>;
  status?: Maybe<GroupStatus>;
  achievementsSaved: Scalars['Boolean'];
  timeToConnectAfterQnAStart: Scalars['Int'];
  esignGroupId?: Maybe<Scalars['String']>;
  onboardingCallLink?: Maybe<Scalars['String']>;
  totalMorningTaskCount: Scalars['Int'];
  eventsCount: Scalars['Int'];
  daysToSolveMorningTasks: Scalars['Int'];
  onboardingDocLink?: Maybe<Scalars['String']>;
  employmentStartDate?: Maybe<Scalars['Date']>;
};


export type StudentsGroupStudentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StudentsFilter>;
  filterReadyForEmployment?: Maybe<Scalars['Boolean']>;
};


export type StudentsGroupEventsCountArgs = {
  eventType: EventTypes;
};

export enum GroupStatus {
  Studying = 'STUDYING',
  Employment = 'EMPLOYMENT',
  Graduated = 'GRADUATED',
  PreCourse = 'PRE_COURSE',
  PreEmployment = 'PRE_EMPLOYMENT',
  Working = 'WORKING'
}

export type StudentsGroupsFilters = {
  name?: Maybe<Array<Scalars['String']>>;
  searchQuery?: Maybe<Scalars['String']>;
  courseId?: Maybe<Array<Scalars['Int']>>;
  status?: Maybe<Array<GroupStatus>>;
  groupCourseType?: Maybe<CourseType>;
};

export type UpdateStudentsGroupValues = {
  name: Scalars['String'];
  bpmGroupId: Scalars['String'];
  supportLink?: Maybe<Scalars['String']>;
  supportProviderName?: Maybe<Scalars['String']>;
  spreadsheetId?: Maybe<Scalars['String']>;
  sheetId?: Maybe<Scalars['String']>;
  youtubePlaylistId?: Maybe<Scalars['String']>;
  bpmCourseId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  lessonZeroStarted?: Maybe<Scalars['Boolean']>;
  slackId?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  status?: Maybe<GroupStatus>;
  capacity?: Maybe<Scalars['Int']>;
  esignGroupId?: Maybe<Scalars['String']>;
  onboardingCallLink?: Maybe<Scalars['String']>;
  onboardingDocLink?: Maybe<Scalars['String']>;
  employmentStartDate?: Maybe<Scalars['Date']>;
};

export type SyncSpreadSheetGroupInfoValues = {
  spreadsheetId: Scalars['String'];
  homeWorkSheetId: Scalars['String'];
  typingSheetId: Scalars['String'];
  morningTasksSheetId: Scalars['String'];
  linksSheetId: Scalars['String'];
  attendanceSheetId: Scalars['String'];
  ratingSheetId: Scalars['String'];
  employmentSheetId?: Maybe<Scalars['String']>;
  applyingSheetId?: Maybe<Scalars['String']>;
  engSheetId?: Maybe<Scalars['String']>;
  techCheckSheetId: Scalars['String'];
};

export type StudentsTask = {
  __typename?: 'StudentsTask';
  id: Scalars['Int'];
  creatorId: Scalars['Int'];
  assignedTo: Scalars['Int'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  progress: Scalars['Int'];
  deadline?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  updatedAt: Scalars['Date'];
  createdAt: Scalars['Date'];
  progressHistory: Array<StudentsTaskProgressHistory>;
};

export type CreateStudentsTaskArgs = {
  assignedTo?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Int']>;
  deadline?: Maybe<Scalars['Date']>;
};

export type UpdateStudentsTaskInput = {
  id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Int']>;
  deadline?: Maybe<Scalars['Date']>;
};

export type GetStudentsTasksArgs = {
  assignedTo: Scalars['Int'];
  progressFrom?: Maybe<Scalars['Int']>;
  progressTo?: Maybe<Scalars['Int']>;
};

export type StudentsTaskProgressHistory = {
  __typename?: 'StudentsTaskProgressHistory';
  id: Scalars['Int'];
  studentTaskId: Scalars['Int'];
  updatedBy: Scalars['Int'];
  progress: Scalars['Int'];
  updatedAt: Scalars['Date'];
  createdAt: Scalars['Date'];
};

export type UpdateSubscriptionValues = {
  bpmSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlanId?: Maybe<Scalars['Int']>;
  dateNext?: Maybe<Scalars['Date']>;
};

export type UserChanges = {
  userId: Scalars['Int'];
  changeReason: SubscriptionUserChangeReason;
};

export type SubscriptionPlanChanges = {
  subscriptionPlanId: Scalars['Int'];
  changeReason: SubscriptionPlanChangeReason;
};

export type DeactivationChanges = {
  changeReason: SubscriptionDeactivationReason;
};

export type UserOptions = {
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  fvType?: Maybe<Scalars['String']>;
  fvSource?: Maybe<Scalars['String']>;
  fvMedium?: Maybe<Scalars['String']>;
  fvCampaign?: Maybe<Scalars['String']>;
  fvContent?: Maybe<Scalars['String']>;
  fvTerm?: Maybe<Scalars['String']>;
  fvPage?: Maybe<Scalars['String']>;
  lvType?: Maybe<Scalars['String']>;
  lvSource?: Maybe<Scalars['String']>;
  lvMedium?: Maybe<Scalars['String']>;
  lvCampaign?: Maybe<Scalars['String']>;
  lvContent?: Maybe<Scalars['String']>;
  lvTerm?: Maybe<Scalars['String']>;
  lvAccountId?: Maybe<Scalars['String']>;
  lvCampaignId?: Maybe<Scalars['String']>;
  lvAdGroupId?: Maybe<Scalars['String']>;
  lvAdId?: Maybe<Scalars['String']>;
  lvTermId?: Maybe<Scalars['String']>;
  gClientid?: Maybe<Scalars['String']>;
  gIp?: Maybe<Scalars['String']>;
  gAgent?: Maybe<Scalars['String']>;
  gclid?: Maybe<Scalars['String']>;
  utmDevicetype?: Maybe<Scalars['String']>;
  utmPlacement?: Maybe<Scalars['String']>;
  fbp?: Maybe<Scalars['String']>;
  fbc?: Maybe<Scalars['String']>;
  fbclid?: Maybe<Scalars['String']>;
  rdtcid?: Maybe<Scalars['String']>;
  newslettersSubscription?: Maybe<Scalars['Boolean']>;
  domain?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  studentStatus?: Maybe<StudentStatus>;
  withTemporaryCredentials?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionsFilters = {
  expired?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  started?: Maybe<Scalars['Boolean']>;
};

export type PaymentSubscription = {
  __typename?: 'PaymentSubscription';
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  dateStarted?: Maybe<Scalars['Date']>;
  dateNext?: Maybe<Scalars['Date']>;
  expired?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  started?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  priceInUsd?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
  subscriptionPlan: PaymentSubscriptionPlan;
  pricingOption?: Maybe<SubscriptionPlanPricingOption>;
  payments: Array<Payment>;
  referralDiscount?: Maybe<Scalars['Float']>;
  bpmSubscriptionId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  paymentProvider?: Maybe<PaymentProvider>;
  startType?: Maybe<SubscriptionStartType>;
  createdAt?: Maybe<Scalars['Date']>;
  sales?: Maybe<User>;
  domain?: Maybe<Domain>;
  initialPaymentAmount?: Maybe<Scalars['Float']>;
  instalmentPaymentAmount?: Maybe<Scalars['Float']>;
  instalmentPartsCount?: Maybe<Scalars['Int']>;
  cancellationPromoUsedAt?: Maybe<Scalars['Date']>;
  providerSubscriptionId?: Maybe<Scalars['Int']>;
};

export type SubsciptionFreeTrialSetupResult = {
  __typename?: 'SubsciptionFreeTrialSetupResult';
  status: SubscriptionFreeTrialSetupStatus;
  clientSecret?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  userAnalyticsData: UserBaseAnalyticsInfo;
};

export enum SubscriptionFreeTrialSetupStatus {
  Succeded = 'succeded',
  Failed = 'failed'
}

export enum SubscriptionStartType {
  Auto = 'AUTO',
  Manual = 'MANUAL'
}

export enum SubscriptionChangeType {
  Deactivation = 'DEACTIVATION',
  SubscriptionPlan = 'SUBSCRIPTION_PLAN',
  User = 'USER'
}

export enum SubscriptionPlanChangeReason {
  WrongSubscriptionPaid = 'WRONG_SUBSCRIPTION_PAID',
  TrackChange = 'TRACK_CHANGE',
  ProfessionToUnlim = 'PROFESSION_TO_UNLIM',
  FopPaymentUpgrade = 'FOP_PAYMENT_UPGRADE',
  Other = 'OTHER'
}

export enum SubscriptionUserChangeReason {
  PurchaseToWrongEmail = 'PURCHASE_TO_WRONG_EMAIL',
  GiftToOtherPerson = 'GIFT_TO_OTHER_PERSON',
  Other = 'OTHER'
}

export enum SubscriptionDeactivationReason {
  StudentDoesntWantToPay = 'STUDENT_DOESNT_WANT_TO_PAY',
  StudentCantPay = 'STUDENT_CANT_PAY',
  UnprocessedRefund = 'UNPROCESSED_REFUND',
  SubscriptionWasIssuedByMistake = 'SUBSCRIPTION_WAS_ISSUED_BY_MISTAKE',
  Other = 'OTHER'
}

export enum SubscriptionChangeReason {
  WrongSubscriptionPaid = 'WRONG_SUBSCRIPTION_PAID',
  TrackChange = 'TRACK_CHANGE',
  ProfessionToUnlim = 'PROFESSION_TO_UNLIM',
  FopPaymentUpgrade = 'FOP_PAYMENT_UPGRADE',
  Other = 'OTHER',
  PurchaseToWrongEmail = 'PURCHASE_TO_WRONG_EMAIL',
  GiftToOtherPerson = 'GIFT_TO_OTHER_PERSON',
  StudentDoesntWantToPay = 'STUDENT_DOESNT_WANT_TO_PAY',
  StudentCantPay = 'STUDENT_CANT_PAY',
  UnprocessedRefund = 'UNPROCESSED_REFUND',
  SubscriptionWasIssuedByMistake = 'SUBSCRIPTION_WAS_ISSUED_BY_MISTAKE'
}

export type SubscriptionChangesHistory = {
  __typename?: 'SubscriptionChangesHistory';
  id: Scalars['Int'];
  subscriptionId: Scalars['Int'];
  adminId: Scalars['Int'];
  changeType: SubscriptionChangeType;
  reason: SubscriptionChangeReason;
  previousValue: Scalars['Int'];
  currentValue: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};

export enum SubscriptionDeactivationRequestReason {
  Price = 'PRICE',
  BadTheory = 'BAD_THEORY',
  BadPractice = 'BAD_PRACTICE',
  MissingFunctionality = 'MISSING_FUNCTIONALITY',
  Competitive = 'COMPETITIVE',
  Other = 'OTHER',
  Time = 'TIME',
  MissingHelp = 'MISSING_HELP',
  Difficulty = 'DIFFICULTY',
  DeactivatedViaPaymentProvider = 'DEACTIVATED_VIA_PAYMENT_PROVIDER'
}

export type SubscriptionGrant = {
  __typename?: 'SubscriptionGrant';
  id: Scalars['Int'];
  name: Scalars['String'];
  domain?: Maybe<Domain>;
};

export type SubscriptionPlanFilters = {
  active?: Maybe<Scalars['Boolean']>;
  accent?: Maybe<Scalars['Boolean']>;
  trialActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<SubscriptionType>>;
  period?: Maybe<SubscriptionPeriod>;
};

export enum SubscriptionPeriod {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
  Lifetime = 'lifetime'
}

export enum SubscriptionTrialPeriod {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year'
}

export enum SubscriptionType {
  Pro = 'PRO',
  ProPlus = 'PRO_PLUS',
  Bro = 'BRO',
  FreeTrial = 'FREE_TRIAL',
  EnglishOneMonth = 'ENGLISH_ONE_MONTH',
  EnglishFourMonths = 'ENGLISH_FOUR_MONTHS',
  EnglishThreeMonths = 'ENGLISH_THREE_MONTHS',
  EnglishSixMonths = 'ENGLISH_SIX_MONTHS',
  ProJava = 'PRO_JAVA',
  ProFrontend = 'PRO_FRONTEND',
  ProFrontendTrial = 'PRO_FRONTEND_TRIAL',
  ProQa = 'PRO_QA',
  ProPython = 'PRO_PYTHON',
  ProFullstack = 'PRO_FULLSTACK',
  ProFullstackTrial = 'PRO_FULLSTACK_TRIAL',
  ProDesign = 'PRO_DESIGN',
  ProRecruitment = 'PRO_RECRUITMENT',
  ProPlusJava = 'PRO_PLUS_JAVA',
  ProPlusFrontend = 'PRO_PLUS_FRONTEND',
  ProPlusQa = 'PRO_PLUS_QA',
  ProPlusFullstack = 'PRO_PLUS_FULLSTACK',
  ProPlusDesign = 'PRO_PLUS_DESIGN',
  ProPlusPython = 'PRO_PLUS_PYTHON',
  ProPlusRecruitment = 'PRO_PLUS_RECRUITMENT',
  ProDevops = 'PRO_DEVOPS',
  ProPlusDevops = 'PRO_PLUS_DEVOPS',
  GroupPlaceBooking = 'GROUP_PLACE_BOOKING',
  ProDigitalMarketing = 'PRO_DIGITAL_MARKETING',
  ProPlusDigitalMarketing = 'PRO_PLUS_DIGITAL_MARKETING',
  BasicModulesAccess = 'BASIC_MODULES_ACCESS',
  ProDataAnalytics = 'PRO_DATA_ANALYTICS',
  ProPlusDataAnalytics = 'PRO_PLUS_DATA_ANALYTICS',
  ProSeo = 'PRO_SEO',
  ProPlusSeo = 'PRO_PLUS_SEO',
  PartnerAccess = 'PARTNER_ACCESS',
  /** This subscription is used for Subscription product only */
  SubscriptionProductWeekly = 'SUBSCRIPTION_PRODUCT_WEEKLY',
  /** This subscription is used for Subscription product only */
  SubscriptionProductFourWeeks = 'SUBSCRIPTION_PRODUCT_FOUR_WEEKS',
  /** This subscription is used for Subscription product only */
  SubscriptionProductFourWeeksWithWeeklyIntroOffer = 'SUBSCRIPTION_PRODUCT_FOUR_WEEKS_WITH_WEEKLY_INTRO_OFFER',
  /** This subscription is used for Subscription product only */
  SubscriptionProductTwelveWeeks = 'SUBSCRIPTION_PRODUCT_TWELVE_WEEKS',
  /** This subscription is used for Subscription product only */
  SubscriptionProductMonthly = 'SUBSCRIPTION_PRODUCT_MONTHLY',
  /** This subscription is used for Subscription product only */
  SubscriptionProductQuarterly = 'SUBSCRIPTION_PRODUCT_QUARTERLY',
  /** This subscription is used for Subscription product only */
  SubscriptionProductBiannual = 'SUBSCRIPTION_PRODUCT_BIANNUAL',
  /** This subscription is used for Subscription product only */
  SubscriptionProductAnnual = 'SUBSCRIPTION_PRODUCT_ANNUAL',
  /** This subscription is used for Subscription product only */
  SubscriptionProductAnnualWithDiscount = 'SUBSCRIPTION_PRODUCT_ANNUAL_WITH_DISCOUNT',
  /** This subscription is used for Subscription product only */
  SubscriptionProductUpfrontMonthly = 'SUBSCRIPTION_PRODUCT_UPFRONT_MONTHLY',
  /** This subscription is used for Subscription product only */
  SubscriptionProductUpfrontQuarterly = 'SUBSCRIPTION_PRODUCT_UPFRONT_QUARTERLY',
  /** This subscription is used for Subscription product only */
  SubscriptionProductUpfrontBiannual = 'SUBSCRIPTION_PRODUCT_UPFRONT_BIANNUAL',
  /** This subscription is used for Subscription product only */
  SubscriptionProductFreeTrialPlusMonthly = 'SUBSCRIPTION_PRODUCT_FREE_TRIAL_PLUS_MONTHLY',
  /** This subscription is used for Subscription product only */
  SubscriptionProductFreeTrialPlusAnnual = 'SUBSCRIPTION_PRODUCT_FREE_TRIAL_PLUS_ANNUAL',
  /** This subscription is used for Subscription product only */
  SubscriptionProductFreeTrialProMonthly = 'SUBSCRIPTION_PRODUCT_FREE_TRIAL_PRO_MONTHLY',
  /** This subscription is used for Subscription product only */
  SubscriptionProductFreeTrialProAnnual = 'SUBSCRIPTION_PRODUCT_FREE_TRIAL_PRO_ANNUAL',
  /** This subscription is used for Subscription product only */
  SubscriptionProductAiMonthly = 'SUBSCRIPTION_PRODUCT_AI_MONTHLY',
  /** This subscription is used for Subscription product only */
  SubscriptionProductTwoMonth = 'SUBSCRIPTION_PRODUCT_TWO_MONTH',
  /** This subscription is used for Subscription product only */
  SubscriptionProductAiQuarterly = 'SUBSCRIPTION_PRODUCT_AI_QUARTERLY',
  /** This subscription is used for Subscription product only */
  SubscriptionProductTest = 'SUBSCRIPTION_PRODUCT_TEST',
  /** Subscription product general */
  SubscriptionProduct = 'SUBSCRIPTION_PRODUCT',
  /** Subscription product with AI */
  SubscriptionProductAi = 'SUBSCRIPTION_PRODUCT_AI',
  /** Subscription product basic access */
  SubscriptionProductBasic = 'SUBSCRIPTION_PRODUCT_BASIC',
  /** Subscription product with coding mentors */
  SubscriptionProductMentor = 'SUBSCRIPTION_PRODUCT_MENTOR'
}

export enum SubscriptionPlanPaymentType {
  FreeTrial = 'FREE_TRIAL',
  PaidTrial = 'PAID_TRIAL',
  IntroOffer = 'INTRO_OFFER',
  UpfrontPayment = 'UPFRONT_PAYMENT'
}

export type PaymentSubscriptionPlan = {
  __typename?: 'PaymentSubscriptionPlan';
  id: Scalars['Int'];
  name: Scalars['String'];
  userFriendlyName: Scalars['String'];
  type: SubscriptionType;
  period: SubscriptionPeriod;
  periodDuration: Scalars['Int'];
  /** @deprecated Use SubscriptionPlanPricingOption.salePrice instead */
  salePrice: Scalars['Float'];
  /** @deprecated Use SubscriptionPlanPricingOption.referencePrice instead */
  defaultPrice: Scalars['Float'];
  loanPrice: Scalars['Float'];
  /** @deprecated Use SubscriptionPlanPricingOption.currency instead */
  currency?: Maybe<Currency>;
  hasReferralDiscount?: Maybe<Scalars['Boolean']>;
  bpmSubscriptionPlanId?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  accent?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  technologies?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  defaultRenewal?: Maybe<Scalars['Boolean']>;
  profession?: Maybe<Profession>;
  subscriptionGrant?: Maybe<SubscriptionGrant>;
  /** @deprecated Use SubscriptionPlanPricingOption.paymentType instead */
  trialActive: Scalars['Boolean'];
  /** @deprecated Use SubscriptionPlanPricingOption.trialPeriod instead */
  trialPeriod?: Maybe<SubscriptionTrialPeriod>;
  /** @deprecated Use SubscriptionPlanPricingOption.trialPeriodDuration instead */
  trialPeriodDuration?: Maybe<Scalars['Int']>;
  /** @deprecated Use SubscriptionPlanPricingOption.salePrice instead */
  trialPrice?: Maybe<Scalars['Float']>;
  /** @deprecated Use SubscriptionPlanPricingOption.paymentType instead */
  paymentType: SubscriptionPlanPaymentType;
};

export type SubscriptionPlanLoanDetails = {
  __typename?: 'SubscriptionPlanLoanDetails';
  id: Scalars['Int'];
  code?: Maybe<Scalars['String']>;
};

export type SubscriptionPlanPricingOption = {
  __typename?: 'SubscriptionPlanPricingOption';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  uuid: Scalars['String'];
  subscriptionPlan: PaymentSubscriptionPlan;
  paymentType: PricingOptionPaymentType;
  referencePrice: Scalars['Float'];
  salePrice: Scalars['Float'];
  renewalPrice: Scalars['Float'];
  currency: Currency;
  trialPeriod?: Maybe<PricingOptionTrialPeriod>;
  trialPeriodDuration?: Maybe<Scalars['Int']>;
  trialPrice?: Maybe<Scalars['Float']>;
  loanPrice?: Maybe<Scalars['Float']>;
  isDefault: Scalars['Boolean'];
  providerProductId?: Maybe<Scalars['String']>;
  providerPriceId?: Maybe<Scalars['String']>;
};

export enum PricingOptionPaymentType {
  Upfront = 'UPFRONT',
  IntroOffer = 'INTRO_OFFER',
  Trial = 'TRIAL'
}

export enum PricingOptionTrialPeriod {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR'
}

export enum SupportSocialNames {
  Telegram = 'TELEGRAM',
  Whatsapp = 'WHATSAPP',
  Viber = 'VIBER',
  Facebook = 'FACEBOOK'
}

export type SupportSocial = {
  __typename?: 'SupportSocial';
  id: Scalars['Int'];
  name: SupportSocialNames;
  source: Scalars['String'];
};

export type TaskReviewTime = {
  __typename?: 'TaskReviewTime';
  testTaskId: Scalars['Int'];
  medianReviewTime: Scalars['Int'];
  totalReviews: Scalars['Int'];
};

export type AverageTaskReviewIterations = {
  __typename?: 'AverageTaskReviewIterations';
  testTaskId: Scalars['Int'];
  averageIterationsCount: Scalars['Int'];
};

export enum ReviewResolution {
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export type TasksReviewsWhere = {
  statuses?: Maybe<Array<Maybe<TaskReviewStatus>>>;
};

export type TasksReviewsOrder = {
  by: Scalars['String'];
  direction: Scalars['String'];
};

export type TaskReviewConfig = {
  statuses: Array<TaskReviewStatus>;
  timeRange?: Maybe<TimeRangeConfig>;
  attemptId?: Maybe<Scalars['Int']>;
  reviewedBy?: Maybe<TasksReviewedBy>;
  reviewType?: Maybe<TaskReviewType>;
  reviewTypes?: Maybe<Array<TaskReviewType>>;
  searchOptions?: Maybe<SearchResultOptions>;
  order?: Maybe<TasksReviewsOrder>;
};

export enum TaskReviewStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  MentorReviewAgain = 'MENTOR_REVIEW_AGAIN',
  PendingPeer = 'PENDING_PEER',
  ApprovedPeer = 'APPROVED_PEER',
  RejectedPeer = 'REJECTED_PEER',
  Expire = 'EXPIRE',
  OnReview = 'ON_REVIEW',
  ExpirePeer = 'EXPIRE_PEER',
  OnReviewPeer = 'ON_REVIEW_PEER',
  Canceled = 'CANCELED',
  ExpireAi = 'EXPIRE_AI',
  ApprovedAi = 'APPROVED_AI',
  RejectedAi = 'REJECTED_AI',
  OnReviewAi = 'ON_REVIEW_AI',
  PendingAi = 'PENDING_AI'
}

export enum TaskReviewType {
  Homework = 'Homework',
  Employment = 'Employment',
  TeamProject = 'TeamProject',
  Daily = 'Daily',
  Morning = 'Morning'
}

export enum SearchResultOptions {
  All = 'All',
  Latest = 'Latest'
}

export enum TimeRangeConfig {
  Onehour = 'ONEHOUR',
  Oneday = 'ONEDAY',
  Alltime = 'ALLTIME',
  Tenhoursold = 'TENHOURSOLD'
}

export enum TasksReviewedBy {
  Me = 'Me',
  AllMentors = 'AllMentors'
}

export type TaskReviewsForPagination = {
  __typename?: 'TaskReviewsForPagination';
  totalCount: Scalars['Int'];
  edges: Array<TaskReview>;
};

export type Reviewee = {
  __typename?: 'Reviewee';
  id: Scalars['Int'];
  fullName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  englishLevel?: Maybe<UserEnglishLevel>;
};

export type Reviewer = {
  __typename?: 'Reviewer';
  id: Scalars['Int'];
  fullName?: Maybe<Scalars['String']>;
  avatar?: Maybe<File>;
  userRole?: Maybe<UserRole>;
};

export type TaskReview = {
  __typename?: 'TaskReview';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  testTaskId: Scalars['Int'];
  testTaskAttemptId: Scalars['Int'];
  source: Scalars['String'];
  status: TaskReviewStatus;
  comment?: Maybe<Scalars['String']>;
  testTask?: Maybe<TestTask>;
  /** @deprecated Security issue, any user can access any of the reviewers and probably other not protected user field */
  user?: Maybe<User>;
  reviewee?: Maybe<Reviewee>;
  attemptsCount: Scalars['Int'];
  reviewIterationsCount: Scalars['Int'];
  previousComments: Array<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  /** @deprecated Security issue, any user can access any of the reviewers and probably other not protected user field */
  reviewer?: Maybe<User>;
  taskReviewer?: Maybe<Reviewer>;
  reviewerId?: Maybe<Scalars['Int']>;
  reviewType?: Maybe<TaskReviewType>;
  testTaskAttempt?: Maybe<TestTaskAttempt>;
  lastSentToMentorReviewAt?: Maybe<Scalars['Date']>;
};

export type Teacher = {
  __typename?: 'Teacher';
  id: Scalars['Int'];
  type?: Maybe<TeacherTypes>;
  rate: Scalars['Float'];
  /** @deprecated Use 'teacherRate.currency' instead */
  teacherRate?: Maybe<TeacherRate>;
  /** @deprecated Use 'teacherRate.currency' instead */
  rateCurrencyId?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  userId: Scalars['Int'];
  courseId?: Maybe<Scalars['Int']>;
  course?: Maybe<Course>;
  teacherName: Scalars['String'];
  teacherEmail?: Maybe<Scalars['String']>;
  teacherAvatar?: Maybe<File>;
  workingStats?: Maybe<WorkingStats>;
  workingTimeActivityRatio?: Maybe<Scalars['Float']>;
  ratingRatio?: Maybe<Scalars['Float']>;
  bankAccount?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentType?: Maybe<TeacherPaymentTypes>;
  teacherPeopleforceId?: Maybe<Scalars['Int']>;
};


export type TeacherWorkingStatsArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
};


export type TeacherWorkingTimeActivityRatioArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
};


export type TeacherRatingRatioArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
};

export type WorkingStats = {
  __typename?: 'WorkingStats';
  duration: Scalars['Float'];
  lessonsCount: Scalars['Int'];
  payment: Scalars['Float'];
  paymentLocal: Scalars['Float'];
  paymentUAH: Scalars['Float'];
};

export enum TeacherTypes {
  EnglishTeacher = 'ENGLISH_TEACHER',
  Mentor = 'MENTOR',
  ExternalMentor = 'EXTERNAL_MENTOR',
  ExternalExpert = 'EXTERNAL_EXPERT',
  ExternalCoach = 'EXTERNAL_COACH',
  ExternalOther = 'EXTERNAL_OTHER',
  Instructor = 'INSTRUCTOR',
  ExternalEducation = 'EXTERNAL_EDUCATION'
}

export enum TeacherPaymentTypes {
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY'
}

export enum TeacherOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type TeachersOrder = {
  by: Scalars['String'];
  direction: TeacherOrderDirection;
};

export type TeachersForPagination = {
  __typename?: 'TeachersForPagination';
  totalCount: Scalars['Int'];
  edges: Array<Teacher>;
};

export type PeopleforceEmployeeSalary = {
  peopleforceId: Scalars['Int'];
  peopleforceEmployeeId: Scalars['Int'];
  salary: Scalars['Float'];
  currencyCode: Scalars['String'];
  startingAt: Scalars['Date'];
  paymentType: TeacherPaymentTypes;
};

export type TeacherRate = {
  __typename?: 'TeacherRate';
  id: Scalars['Int'];
  startingAt: Scalars['Date'];
  teacherId: Scalars['Int'];
  value: Scalars['Float'];
  currencyId: Scalars['Int'];
  currency: Currency;
  createdBy?: Maybe<Scalars['Int']>;
  peopleforceId?: Maybe<Scalars['Int']>;
  paymentType?: Maybe<TeacherPaymentTypes>;
};

export enum TeamMemberRole {
  Founder = 'FOUNDER',
  Coach = 'COACH',
  Mentor = 'MENTOR',
  Coordinator = 'COORDINATOR',
  CareerSupport = 'CAREER_SUPPORT',
  Teacher = 'TEACHER',
  SalesTeam = 'SALES_TEAM',
  Interviewers = 'INTERVIEWERS',
  EnglishTeacher = 'ENGLISH_TEACHER',
  Operations = 'OPERATIONS',
  PlTeam = 'PL_TEAM',
  Other = 'OTHER'
}

export type TeamMember = {
  __typename?: 'TeamMember';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  /** @deprecated Use jobTitle instead */
  profession?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  memberDetails?: Maybe<Scalars['String']>;
  role?: Maybe<TeamMemberRole>;
  photo?: Maybe<File>;
  smilePhoto?: Maybe<File>;
  linkedin?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  shortBio?: Maybe<Scalars['String']>;
  courseAuthorSlug?: Maybe<Scalars['String']>;
};

export type TeamProject = {
  __typename?: 'TeamProject';
  id: Scalars['Int'];
  teamUserId: Scalars['Int'];
  chatId: Scalars['Int'];
  courseId: Scalars['Int'];
  generalChat?: Maybe<AbstractChat>;
};

export type JoinTeamProjectResult = {
  __typename?: 'JoinTeamProjectResult';
  teamProject?: Maybe<TeamProject>;
  teamProjectUser: TeamProjectUser;
};

export type TeamProjectUser = {
  __typename?: 'TeamProjectUser';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  teamProjectId?: Maybe<Scalars['Int']>;
  professionId: Scalars['Int'];
};

export type Transcription = {
  __typename?: 'Transcription';
  id?: Maybe<Scalars['String']>;
  results?: Maybe<Array<TranscriptionResult>>;
};

export type TranscriptionResult = {
  __typename?: 'TranscriptionResult';
  id?: Maybe<Scalars['String']>;
  confidence?: Maybe<Scalars['Float']>;
  transcript?: Maybe<Scalars['String']>;
};

export type UpdateTechCheckAnswerCheckphraseValues = {
  isMentioned?: Maybe<Scalars['Boolean']>;
};

export type TechCheckAnswerCheckphrase = {
  __typename?: 'TechCheckAnswerCheckphrase';
  id: Scalars['Int'];
  isMentioned: Scalars['Boolean'];
  techCheckQuestionCheckphraseId: Scalars['Int'];
  userTechCheckAnswerId: Scalars['Int'];
  questionCheckphrase: TechCheckQuestionCheckphrase;
};

export type TechCheckEventDetails = {
  __typename?: 'TechCheckEventDetails';
  id: Scalars['Int'];
  eventId: Scalars['Int'];
  studentsGroupId: Scalars['Int'];
  studentsGroupIds: Array<Scalars['Int']>;
  courseId?: Maybe<Scalars['Int']>;
  roomLink?: Maybe<Scalars['String']>;
  roomLinkId?: Maybe<Scalars['Int']>;
  roomLinkUuid?: Maybe<Scalars['String']>;
  studentsGroup: StudentsGroup;
  studentsGroups: Array<StudentsGroup>;
  course?: Maybe<Course>;
  sessionId?: Maybe<Scalars['Int']>;
  liveCodingTemplateId?: Maybe<Scalars['Int']>;
  liveCodingTemplate?: Maybe<TechCheckTemplate>;
  techCheckTemplateId?: Maybe<Scalars['Int']>;
};

export type FlexTechCheckResults = {
  __typename?: 'FlexTechCheckResults';
  id: Scalars['String'];
  modules: Array<Course>;
  students: Array<FlexTechCheckStudentResults>;
};

export type FlexTechCheckStudentResults = {
  __typename?: 'FlexTechCheckStudentResults';
  id: Scalars['Int'];
  results: Array<FlexTechCheckResult>;
};

export type FlexTechCheckResult = {
  __typename?: 'FlexTechCheckResult';
  id: Scalars['String'];
  scores: Array<FlexTechCheckScore>;
};

export type FlexTechCheckScore = {
  __typename?: 'FlexTechCheckScore';
  id: Scalars['String'];
  score?: Maybe<Scalars['Int']>;
  liveCodingScore?: Maybe<Scalars['Int']>;
};

export type TechCheckEventSkeletonDetails = {
  __typename?: 'TechCheckEventSkeletonDetails';
  id: Scalars['Int'];
  studentsGroupId: Scalars['Int'];
  studentsGroupIds: Array<Scalars['Int']>;
  studentsGroup: StudentsGroup;
  studentsGroups: Array<StudentsGroup>;
  courseId: Scalars['Int'];
  course?: Maybe<Course>;
  techCheckTemplateId?: Maybe<Scalars['Int']>;
  techCheckTemplate?: Maybe<TechCheckTemplate>;
  liveCodingTemplateId?: Maybe<Scalars['Int']>;
  liveCodingTemplate?: Maybe<TechCheckTemplate>;
  paused: Scalars['Boolean'];
};

export type TechCheckLink = {
  __typename?: 'TechCheckLink';
  id: Scalars['Int'];
  uuid: Scalars['String'];
  eventId?: Maybe<Scalars['Int']>;
  participants: Array<TechCheckParticipant>;
  questions: Array<TechCheckQuestion>;
  autoTechCheckAnswers: Array<UserTechCheckAnswer>;
  liveCodingLinkUuid?: Maybe<Scalars['String']>;
  usersStatistics: Array<TechCheckUserStatistics>;
  liveCodingTestTaskLinks: Array<TestTaskLink>;
};

export type TechCheckUserStatistics = {
  __typename?: 'TechCheckUserStatistics';
  id: Scalars['String'];
  techCheckLinkId: Scalars['Int'];
  userId: Scalars['Int'];
  score: Scalars['Int'];
};

export enum TechCheckParticipantRole {
  Admin = 'ADMIN',
  Participant = 'PARTICIPANT'
}

export type TechCheckParticipant = {
  __typename?: 'TechCheckParticipant';
  id: Scalars['Int'];
  techCheckLink: TechCheckLink;
  user: User;
  lastActionTime: Scalars['Date'];
  role: TechCheckParticipantRole;
  isUserParticipant: Scalars['Boolean'];
};

export type TechCheckQuestion = {
  __typename?: 'TechCheckQuestion';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  techCheckTopic?: Maybe<TechCheckTopic>;
  checkphrases: Array<TechCheckQuestionCheckphrase>;
  answerText?: Maybe<Scalars['String']>;
  answerApprovedBy?: Maybe<Scalars['Int']>;
  translates: TranslatesCollection;
  deletedAt?: Maybe<Scalars['Date']>;
};

export type CreateTechCheckQuestionArgs = {
  topicId?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  translates: TranslatesPackInput;
  checkphrases?: Maybe<Array<TechCheckQuestionCheckphraseInput>>;
};

export type UpdateTechCheckQuestionArgs = {
  topicId?: Maybe<Scalars['Int']>;
  translates?: Maybe<TranslatesPackInput>;
  answerText?: Maybe<Scalars['String']>;
};

export type ReorderTechCheckQuestionsRecord = {
  id: Scalars['Int'];
  order: Scalars['Int'];
};

export type TechCheckQuestionsBySearchQueryFilters = {
  searchQuery: Scalars['String'];
  courseId?: Maybe<Scalars['Int']>;
};

export type TechCheckQuestionCheckphrase = {
  __typename?: 'TechCheckQuestionCheckphrase';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  translates: TranslatesCollection;
  deletedAt?: Maybe<Scalars['Date']>;
};

export type CreateTechCheckQuestionCheckphraseArgs = {
  techCheckQuestionId: Scalars['Int'];
  slug: Scalars['String'];
  translates: TranslatesPackInput;
};

export type TechCheckQuestionCheckphraseInput = {
  slug: Scalars['String'];
  translates: TranslatesPackInput;
};

export type UpdateTechCheckQuestionCheckphraseArgs = {
  translates?: Maybe<TranslatesPackInput>;
};

export type TechCheckQuestionKeywordInput = {
  locale: Scalars['String'];
  keyword: Scalars['String'];
  weight: Scalars['Int'];
};

export type CreateTechCheckQuestionKeywordValues = {
  techCheckQuestionId: Scalars['Int'];
  locale: Scalars['String'];
  keyword: Scalars['String'];
  weight: Scalars['Int'];
};

export type UpdateTechCheckQuestionKeywordValues = {
  locale?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

export type TechCheckQuestionsKeyword = {
  __typename?: 'TechCheckQuestionsKeyword';
  id: Scalars['Int'];
  locale: Scalars['String'];
  keyword: Scalars['String'];
  weight: Scalars['Int'];
};

export type TechCheckSession = {
  __typename?: 'TechCheckSession';
  id: Scalars['Int'];
  studentsGroupId: Scalars['Int'];
  studentsGroup: StudentsGroup;
  courseId?: Maybe<Scalars['Int']>;
  templateId?: Maybe<Scalars['Int']>;
  liveCodingTemplateId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  event?: Maybe<TechCheckSessionEvent>;
  sessionEvents: Array<TechCheckEvent>;
  template?: Maybe<TechCheckTemplate>;
  liveCodingTemplate?: Maybe<TechCheckTemplate>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  techCheckLinks: Array<TechCheckLink>;
};

export type CreateTechCheckSessionArgs = {
  title?: Maybe<Scalars['String']>;
  studentsGroupId: Scalars['Int'];
  courseId?: Maybe<Scalars['Int']>;
  questionsIds: Array<Scalars['Int']>;
};

export type TechCheckSessionEventsInfo = {
  __typename?: 'TechCheckSessionEventsInfo';
  numberOfEvents: Scalars['Int'];
  finishedEvents: Scalars['Int'];
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type TechCheckSessionParticipantInfo = {
  userId: Scalars['Int'];
  eventId?: Maybe<Scalars['Int']>;
};

export enum TechCheckSessionEventsGenerationMode {
  None = 'NONE',
  Events = 'EVENTS',
  EventsAndParticipants = 'EVENTS_AND_PARTICIPANTS'
}

export type TechCheckSessionEventDetails = {
  __typename?: 'TechCheckSessionEventDetails';
  id: Scalars['Int'];
  eventId: Scalars['Int'];
  sessionId: Scalars['Int'];
  session: TechCheckSession;
  eventsGenerationMode: TechCheckSessionEventsGenerationMode;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
};

export type TechCheckTemplate = {
  __typename?: 'TechCheckTemplate';
  id: Scalars['Int'];
  name: Scalars['String'];
  type: TechCheckTemplateType;
  questions: Array<TechCheckQuestion>;
  testTasks: Array<TestTask>;
  hashSum?: Maybe<Scalars['String']>;
  questionsIds: Array<Scalars['Int']>;
  coveredTopics: Array<TechCheckTopic>;
  courses: Array<Course>;
  deletedAt?: Maybe<Scalars['Date']>;
};


export type TechCheckTemplateCoursesArgs = {
  domain?: Maybe<Scalars['String']>;
};

export type CreateTechCheckTemplateArgs = {
  type: TechCheckTemplateType;
  name: Scalars['String'];
  questionIds?: Maybe<Array<Scalars['Int']>>;
  testTaskIds?: Maybe<Array<Scalars['Int']>>;
  courseIds?: Maybe<Array<Scalars['Int']>>;
};

export type UpdateTechCheckTemplateArgs = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  questionIds?: Maybe<Array<Scalars['Int']>>;
  testTaskIds?: Maybe<Array<Scalars['Int']>>;
  courseIds?: Maybe<Array<Scalars['Int']>>;
};

export type TechCheckTemplatesOrder = {
  by: Scalars['String'];
  direction: TechCheckTemplatesOrderDirection;
};

export enum TechCheckTemplatesOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum TechCheckTemplateType {
  Standard = 'STANDARD',
  Sessions = 'SESSIONS',
  Auto = 'AUTO',
  LiveCoding = 'LIVE_CODING'
}

export type TechCheckTemplateQuestion = {
  __typename?: 'TechCheckTemplateQuestion';
  id: Scalars['Int'];
  templateId: Scalars['Int'];
  questionId: Scalars['Int'];
  question: TechCheckQuestion;
};

export type TechCheckTopic = {
  __typename?: 'TechCheckTopic';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  questions: Array<TechCheckQuestion>;
  translates: TranslatesCollection;
  courses: Array<Course>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export type CreateTechCheckTopicArgs = {
  slug: Scalars['String'];
  translates: TranslatesPackInput;
  coursesIds?: Maybe<Array<Scalars['Int']>>;
};

export type UpdateTechCheckTopicArgs = {
  translates?: Maybe<TranslatesPackInput>;
  coursesIds?: Maybe<Array<Scalars['Int']>>;
};

export type ReorderTechCheckTopicRecord = {
  id: Scalars['Int'];
  order: Scalars['Int'];
};

export type CreateTelegramUserValues = {
  telegramId: Scalars['String'];
  telegramUsername: Scalars['String'];
  telegramPhone?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

export type TelegramUser = {
  __typename?: 'TelegramUser';
  id: Scalars['Int'];
  telegramId?: Maybe<Scalars['String']>;
  telegramUsername?: Maybe<Scalars['String']>;
  telegramPhone?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type CreateTestTaskAttemptResult = {
  __typename?: 'CreateTestTaskAttemptResult';
  testTaskAttempt: TestTaskAttempt;
  testTaskLink: TestTaskLink;
};

export enum ProgrammingLanguage {
  Javascript = 'javascript',
  Typescript = 'typescript',
  Python = 'python',
  Java = 'java',
  Sql = 'sql',
  Markup = 'markup',
  English = 'english',
  Qa = 'qa',
  Design = 'design',
  Employment = 'employment',
  Recruitment = 'recruitment',
  TeamProject = 'team_project',
  Devops = 'devops',
  DigitalMarketing = 'digital_marketing',
  DataAnalytics = 'data_analytics'
}

export enum TestTaskStatus {
  Passed = 'PASSED',
  Failed = 'FAILED',
  NotChecked = 'NOT_CHECKED',
  MentorReview = 'MENTOR_REVIEW',
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  StudentReview = 'STUDENT_REVIEW',
  StudentReviewFailed = 'STUDENT_REVIEW_FAILED',
  StudentReviewApproved = 'STUDENT_REVIEW_APPROVED',
  MentorReviewDeclined = 'MENTOR_REVIEW_DECLINED',
  AiReview = 'AI_REVIEW',
  AiReviewFailed = 'AI_REVIEW_FAILED',
  AiReviewPassed = 'AI_REVIEW_PASSED'
}

export enum TestTaskType {
  LiveCoding = 'LIVE_CODING',
  HomeCoding = 'HOME_CODING',
  TopicTasks = 'TOPIC_TASKS',
  Exam = 'EXAM',
  Sandbox = 'SANDBOX',
  MorningTask = 'MORNING_TASK',
  DailyTask = 'DAILY_TASK'
}

export enum TestTaskFlow {
  Github = 'GITHUB',
  CodeEditor = 'CODE_EDITOR',
  Markup = 'MARKUP',
  Quiz = 'QUIZ',
  Attachment = 'ATTACHMENT',
  Web = 'WEB'
}

export enum TestTaskTestType {
  Cypress = 'CYPRESS',
  Matcha = 'MATCHA',
  Javascript = 'JAVASCRIPT',
  Typescript = 'TYPESCRIPT',
  Python = 'PYTHON',
  Java = 'JAVA',
  Sql = 'SQL',
  Markup = 'MARKUP',
  Document = 'DOCUMENT'
}

export enum ConfigInheritanceSource {
  Module = 'module',
  Topic = 'topic',
  Task = 'task',
  Default = 'default'
}

export enum TestTaskCreationStatus {
  Draft = 'DRAFT',
  Created = 'CREATED'
}

export type AiTestTaskAttemptReview = {
  __typename?: 'AITestTaskAttemptReview';
  id: Scalars['Int'];
  reviewMessage: Scalars['String'];
};

export type AiReviewContextInput = {
  prefix?: Maybe<Scalars['String']>;
  course?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
};

export type TestTask = {
  __typename?: 'TestTask';
  id: Scalars['Int'];
  slug: Scalars['String'];
  flow: TestTaskFlow;
  complexity?: Maybe<Scalars['Int']>;
  programmingLanguage: Scalars['String'];
  latestReleasedVersion?: Maybe<TestTaskVersion>;
  testTaskVersions: Array<TestTaskVersion>;
  name?: Maybe<Scalars['String']>;
  /** @deprecated Use the personalRating field in the TestTaskAttempt type */
  rating?: Maybe<Rating>;
  solutionUrl?: Maybe<Scalars['String']>;
  externalSolutionUrl?: Maybe<Scalars['String']>;
  courseTopicTask?: Maybe<CourseTopicTask>;
  config?: Maybe<TestTaskConfig>;
  reviews: Array<TaskReview>;
  totalAttempts?: Maybe<Scalars['Int']>;
  totalPassed?: Maybe<Scalars['Int']>;
  courseTopics: Array<CourseTopic>;
  fullConfig?: Maybe<TestTaskConfigWithInheritance>;
  creationStatus?: Maybe<TestTaskCreationStatus>;
  estimatedTimeToSolve?: Maybe<Scalars['Int']>;
  millisToSolve?: Maybe<Scalars['Int']>;
  dailyTask?: Maybe<DailyTask>;
  forumId?: Maybe<Scalars['Int']>;
  showChangeTrackModal: Scalars['Boolean'];
  /** @deprecated Use the CourseTopicTask.deadline instead */
  deadline?: Maybe<Scalars['Date']>;
  xpForSolving?: Maybe<Scalars['Int']>;
  timeToReview?: Maybe<Scalars['Int']>;
  numberOfSupportChatMessagesByTestTask?: Maybe<Scalars['Int']>;
  testTaskChatWithMentor?: Maybe<AbstractChat>;
};


export type TestTaskTestTaskVersionsArgs = {
  orderBy?: Maybe<TestTaskVersionOrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['Int']>;
};


export type TestTaskCourseTopicTaskArgs = {
  courseTopicSlug?: Maybe<Scalars['String']>;
};


export type TestTaskReviewsArgs = {
  filters?: Maybe<TasksReviewsWhere>;
  order?: Maybe<TasksReviewsOrder>;
  limitPerUser?: Maybe<Scalars['Int']>;
};


export type TestTaskDeadlineArgs = {
  studentsGroupId?: Maybe<Scalars['Int']>;
};

export type TaskTestFile = {
  __typename?: 'TaskTestFile';
  id: Scalars['Int'];
  path: Scalars['String'];
  content: Scalars['String'];
};

export type TaskRepo = {
  __typename?: 'TaskRepo';
  name: Scalars['String'];
};

export type TaskReferenceFile = {
  __typename?: 'TaskReferenceFile';
  id: Scalars['Int'];
  path: Scalars['String'];
  content: Scalars['String'];
};

export type QuizAnswerOption = {
  slug: Scalars['String'];
  answerOrder?: Maybe<Scalars['Int']>;
};

export type SelectedOption = {
  questionSlug?: Maybe<Scalars['String']>;
  answerSlugs?: Maybe<Array<Maybe<Scalars['String']>>>;
  answerOptions?: Maybe<Array<Maybe<QuizAnswerOption>>>;
  retryAttempts?: Maybe<Scalars['Int']>;
};

export type TaskStatusData = {
  __typename?: 'TaskStatusData';
  id: Scalars['Int'];
  testTaskAttemptId: Scalars['Int'];
  testTaskLinkId: Scalars['Int'];
  testTaskId: Scalars['Int'];
  status: TestTaskStatus;
};

export type TestTaskTest = {
  __typename?: 'TestTaskTest';
  type: TestTaskTestType;
  async?: Maybe<Scalars['Boolean']>;
};

export type TestTaskConfig = {
  __typename?: 'TestTaskConfig';
  isSampleTestsVisible?: Maybe<Scalars['Boolean']>;
  isLinterEnabled?: Maybe<Scalars['Boolean']>;
  isLinterBlocker?: Maybe<Scalars['Boolean']>;
  hasTests?: Maybe<Scalars['Boolean']>;
  isOptional?: Maybe<Scalars['Boolean']>;
  timeToSolve?: Maybe<Scalars['Int']>;
  isAutocompleteEnabled?: Maybe<Scalars['Boolean']>;
  isOpenExam?: Maybe<Scalars['Boolean']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  notRequiresReview?: Maybe<Scalars['Boolean']>;
  isRefSolutionVisible?: Maybe<Scalars['Boolean']>;
  isFileTreeVisible?: Maybe<Scalars['Boolean']>;
  hoursToPassToShowSolution?: Maybe<Scalars['Int']>;
  tests?: Maybe<Array<TestTaskTest>>;
  peerReviewAvailable?: Maybe<Scalars['Boolean']>;
  hasChecklist?: Maybe<Scalars['Boolean']>;
  shouldShowAttachCheckbox?: Maybe<Scalars['Boolean']>;
  maxReviewIterationsCount?: Maybe<Scalars['Int']>;
  onboardings?: Maybe<Array<CourseOnboarding>>;
  isAIReviewEnabled?: Maybe<Scalars['Boolean']>;
  shouldRenderTasksList?: Maybe<Scalars['Boolean']>;
  isMentorReviewOptional?: Maybe<Scalars['Boolean']>;
};

export type TranslatableValue = {
  en: Scalars['String'];
  uk: Scalars['String'];
  ru: Scalars['String'];
};

export type MainSupportedTranslatableValue = {
  en: Scalars['String'];
  uk?: Maybe<Scalars['String']>;
  ru?: Maybe<Scalars['String']>;
  pl?: Maybe<Scalars['String']>;
  pt?: Maybe<Scalars['String']>;
};

export type QuizAnswerInput = {
  value: MainSupportedTranslatableValue;
  isCorrect: Scalars['Boolean'];
  uploadImageId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  answerOrder?: Maybe<Scalars['Int']>;
};

export type QuizQuestionInput = {
  id: Scalars['String'];
  type: QuizQuestionType;
  question: MainSupportedTranslatableValue;
  description?: Maybe<Scalars['String']>;
  uploadImageId?: Maybe<Scalars['Int']>;
  explanation?: Maybe<MainSupportedTranslatableValue>;
  answers: Array<QuizAnswerInput>;
  timeToSolve: Scalars['Int'];
  answerTemplate?: Maybe<MainSupportedTranslatableValue>;
  instructions?: Maybe<MainSupportedTranslatableValue>;
};

export type QuizConfigInput = {
  shuffleable: Scalars['Boolean'];
  restartable: Scalars['Boolean'];
  showCorrectAnswers: Scalars['Boolean'];
};

export type QuizAnswerToEdit = {
  __typename?: 'QuizAnswerToEdit';
  id: Scalars['Int'];
  value: TranslatesPack;
  titleCode: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  uploadImage?: Maybe<TestTaskQuizImage>;
  answerOrder?: Maybe<Scalars['Int']>;
};

export type EditedQuizAnswerInput = {
  id: Scalars['String'];
  value?: Maybe<MainSupportedTranslatableValue>;
  description?: Maybe<Scalars['String']>;
  quizQuestionId: Scalars['Int'];
  quizQuestionSlug: Scalars['String'];
  deleted?: Maybe<Scalars['Boolean']>;
};

export type QuizQuestionToEdit = {
  __typename?: 'QuizQuestionToEdit';
  id: Scalars['Int'];
  type: QuizQuestionType;
  timeToSolve: Scalars['Int'];
  titleCode: Scalars['String'];
  explanationCode?: Maybe<Scalars['String']>;
  answerTemplateCode?: Maybe<Scalars['String']>;
  instructionsCode?: Maybe<Scalars['String']>;
  question: TranslatesPack;
  description?: Maybe<Scalars['String']>;
  explanation?: Maybe<TranslatesPack>;
  answerTemplate?: Maybe<TranslatesPack>;
  instructions?: Maybe<TranslatesPack>;
  uploadImage?: Maybe<TestTaskQuizImage>;
  answers: Array<QuizAnswerToEdit>;
};

export type EditedQuizQuestionInput = {
  id: Scalars['Int'];
  timeToSolve?: Maybe<Scalars['Int']>;
  titleCode: Scalars['String'];
  explanationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  explanation?: Maybe<MainSupportedTranslatableValue>;
};

export type QuizInfoToEdit = {
  __typename?: 'QuizInfoToEdit';
  id: Scalars['Int'];
  restartable: Scalars['Boolean'];
  shuffleable: Scalars['Boolean'];
  showCorrectAnswers: Scalars['Boolean'];
  correctAnswersCountToComplete?: Maybe<Scalars['Int']>;
  questions: Array<QuizQuestionToEdit>;
};

export type FileToLint = {
  path: Scalars['String'];
  content: Scalars['String'];
  template?: Maybe<Scalars['String']>;
  isReadonly?: Maybe<Scalars['Boolean']>;
};

export type TestTaskContext = {
  testTaskType: TestTaskType;
  courseSlug?: Maybe<Scalars['String']>;
  courseTopicSlug?: Maybe<Scalars['String']>;
  examSlug?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['Int']>;
};

export type DescriptionFile = {
  lang: Scalars['String'];
  content: Scalars['String'];
};

export type TaskConfigInput = {
  isSampleTestsVisible?: Maybe<Scalars['Boolean']>;
  isLinterEnabled?: Maybe<Scalars['Boolean']>;
  isLinterBlocker?: Maybe<Scalars['Boolean']>;
  hasTests?: Maybe<Scalars['Boolean']>;
  isOptional?: Maybe<Scalars['Boolean']>;
  timeToSolve?: Maybe<Scalars['Int']>;
  isAutocompleteEnabled?: Maybe<Scalars['Boolean']>;
  isOpenExam?: Maybe<Scalars['Boolean']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  notRequiresReview?: Maybe<Scalars['Boolean']>;
  isRefSolutionVisible?: Maybe<Scalars['Boolean']>;
  isFileTreeVisible?: Maybe<Scalars['Boolean']>;
  hoursToPassToShowSolution?: Maybe<Scalars['Int']>;
  hasChecklist?: Maybe<Scalars['Boolean']>;
  tests?: Maybe<Array<Maybe<TestTaskTestInput>>>;
  shouldShowAttachCheckbox?: Maybe<Scalars['Boolean']>;
  maxReviewIterationsCount?: Maybe<Scalars['Int']>;
  peerReviewAvailable?: Maybe<Scalars['Boolean']>;
  isAIReviewEnabled?: Maybe<Scalars['Boolean']>;
  isMentorReviewOptional?: Maybe<Scalars['Boolean']>;
};

export type TestTaskTestInput = {
  type: TestTaskTestType;
  async?: Maybe<Scalars['Boolean']>;
};

export type CreateTestTaskValuesInput = {
  flow: TestTaskFlow;
  programmingLanguage: ProgrammingLanguage;
  config: TaskConfigInput;
  name: Scalars['String'];
  descriptionFiles?: Maybe<Array<Maybe<DescriptionFile>>>;
  repoName?: Maybe<Scalars['String']>;
  quizQuestions?: Maybe<Array<QuizQuestionInput>>;
  quizConfig?: Maybe<QuizConfigInput>;
  taskFiles?: Maybe<Array<TestTaskFileToCreate>>;
  creationStatus?: Maybe<TestTaskCreationStatus>;
  estimatedTimeToSolve?: Maybe<Scalars['Int']>;
  daysToSolve?: Maybe<Scalars['Int']>;
  solutionUrl?: Maybe<Scalars['String']>;
  externalSolutionUrl?: Maybe<Scalars['String']>;
};

export type UpdateTestTaskValuesInput = {
  flow?: Maybe<TestTaskFlow>;
  config?: Maybe<TaskConfigInput>;
  name?: Maybe<Scalars['String']>;
  descriptionFiles?: Maybe<Array<Maybe<DescriptionFile>>>;
  repoName?: Maybe<Scalars['String']>;
  taskFiles?: Maybe<Array<Maybe<TestTaskFileToUpdate>>>;
  quizPayload?: Maybe<UpdateQuizPayload>;
  creationStatus?: Maybe<TestTaskCreationStatus>;
  estimatedTimeToSolve?: Maybe<Scalars['Int']>;
  daysToSolve?: Maybe<Scalars['Int']>;
  solutionUrl?: Maybe<Scalars['String']>;
  externalSolutionUrl?: Maybe<Scalars['String']>;
  timeToReview?: Maybe<Scalars['Int']>;
};

export type UpdateQuizPayload = {
  quizQuestionsToCreate?: Maybe<Array<QuizQuestionInput>>;
  quizConfig?: Maybe<QuizConfigInput>;
  updateQuizVersionPayload?: Maybe<UpdateQuizVersionPayload>;
};

export type UpdateQuizVersionPayload = {
  editedTranslates: Array<TranslateInput>;
  editedQuizQuestions: Array<EditedQuizQuestionInput>;
  editedQuizAnswers: Array<EditedQuizAnswerInput>;
  reorderedQuestions?: Maybe<Array<EntityIdWithOrder>>;
};

export type EntityIdWithOrder = {
  id: Scalars['Int'];
  order: Scalars['Int'];
};

export type TestTaskConfigWithInheritance = {
  __typename?: 'TestTaskConfigWithInheritance';
  isSampleTestsVisible?: Maybe<ConfigOptionWithInheritance>;
  isLinterEnabled?: Maybe<ConfigOptionWithInheritance>;
  isLinterBlocker?: Maybe<ConfigOptionWithInheritance>;
  hasTests?: Maybe<ConfigOptionWithInheritance>;
  isOptional?: Maybe<ConfigOptionWithInheritance>;
  isAutocompleteEnabled?: Maybe<ConfigOptionWithInheritance>;
  isOpenExam?: Maybe<ConfigOptionWithInheritance>;
  isOpen?: Maybe<ConfigOptionWithInheritance>;
  notRequiresReview?: Maybe<ConfigOptionWithInheritance>;
  isRefSolutionVisible?: Maybe<ConfigOptionWithInheritance>;
  isFileTreeVisible?: Maybe<ConfigOptionWithInheritance>;
  hasChecklist?: Maybe<ConfigOptionWithInheritance>;
  tests?: Maybe<ConfigTestsOption>;
  maxReviewIterationsCount?: Maybe<NumericalConfigOptionWithInheritance>;
  onboardings?: Maybe<ConfigOnboardingsOption>;
  isAIReviewEnabled?: Maybe<ConfigOptionWithInheritance>;
  isMentorReviewOptional?: Maybe<ConfigOptionWithInheritance>;
  peerReviewAvailable?: Maybe<ConfigOptionWithInheritance>;
};

export type ConfigOptionWithInheritance = {
  __typename?: 'ConfigOptionWithInheritance';
  value: Scalars['Boolean'];
  source: ConfigInheritanceSource;
};

export type ConfigTestsOption = {
  __typename?: 'ConfigTestsOption';
  value: Array<TestTaskTest>;
  source: ConfigInheritanceSource;
};

export type ConfigOnboardingsOption = {
  __typename?: 'ConfigOnboardingsOption';
  value?: Maybe<Array<CourseOnboarding>>;
  source: ConfigInheritanceSource;
};

export type NumericalConfigOptionWithInheritance = {
  __typename?: 'NumericalConfigOptionWithInheritance';
  value: Scalars['Int'];
  source: ConfigInheritanceSource;
};

export type BaseTestTaskfileWithType = {
  id: Scalars['Int'];
  path: Scalars['String'];
  content: Scalars['String'];
  type: TestTaskFileType;
};

export type TestTaskFileToCreate = {
  path: Scalars['String'];
  content: Scalars['String'];
  isReadonly: Scalars['Boolean'];
  type: TestTaskFileType;
  githubPath: Scalars['String'];
};

export type TestTaskFileToUpdate = {
  id: Scalars['Int'];
  path: Scalars['String'];
  content: Scalars['String'];
  isReadonly: Scalars['Boolean'];
  type: TestTaskFileType;
  githubPath: Scalars['String'];
};

export type CreateQuizTaskImageInput = {
  image: Scalars['Upload'];
  imageSize?: Maybe<Scalars['Int']>;
};

export type TestTaskQuizImage = {
  __typename?: 'TestTaskQuizImage';
  id: Scalars['Int'];
  url: Scalars['String'];
};

export type TestTaskTemplateUpdateResult = {
  __typename?: 'TestTaskTemplateUpdateResult';
  testTaskId: Scalars['Int'];
  files: Array<AbstractTestTaskFile>;
  results: TaskResults;
  testTaskVersionId: Scalars['Int'];
};

export type TestTaskDescription = {
  __typename?: 'TestTaskDescription';
  lang: LanguageName;
  content: Scalars['String'];
};

export type PublicUserProfileWithSolvedAt = {
  __typename?: 'PublicUserProfileWithSolvedAt';
  user: PublicUserProfile;
  solvedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
};

export type TestTaskActivity = {
  __typename?: 'TestTaskActivity';
  solvingNow: Array<Maybe<PublicUserProfileWithSolvedAt>>;
  recentlySolvedBy: Array<Maybe<PublicUserProfileWithSolvedAt>>;
};

export enum TestStatus {
  Passed = 'passed',
  Failed = 'failed'
}

export enum TestErrorType {
  MatcherError = 'MATCHER_ERROR',
  SyntaxError = 'SYNTAX_ERROR',
  OutOfMemoryError = 'OUT_OF_MEMORY_ERROR',
  PayloadSizeError = 'PAYLOAD_SIZE_ERROR',
  TimeoutError = 'TIMEOUT_ERROR'
}

export enum TestErrorResultType {
  SqlTable = 'SQL_TABLE',
  Json = 'JSON',
  Primitive = 'PRIMITIVE'
}

export enum QuizQuestionType {
  Select = 'SELECT',
  Open = 'OPEN',
  MultiSelect = 'MULTI_SELECT',
  Order = 'ORDER'
}

export enum AttachmentTaskSubmissionActionName {
  LinkAdded = 'LINK_ADDED',
  LinkUpdated = 'LINK_UPDATED',
  ReviewRequested = 'REVIEW_REQUESTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Commented = 'COMMENTED'
}

export enum LogType {
  Info = 'INFO',
  Warn = 'WARN',
  Error = 'ERROR'
}

export enum GitHubStatus {
  ProfileRequired = 'PROFILE_REQUIRED',
  RepoForked = 'REPO_FORKED',
  PeerReview = 'PEER_REVIEW',
  PeerRejected = 'PEER_REJECTED',
  MentorReview = 'MENTOR_REVIEW',
  MentorRejected = 'MENTOR_REJECTED',
  AutoTestReview = 'AUTO_TEST_REVIEW',
  AutoTestRejected = 'AUTO_TEST_REJECTED',
  Approved = 'APPROVED',
  NotChecked = 'NOT_CHECKED',
  AiReview = 'AI_REVIEW',
  AiRejected = 'AI_REJECTED'
}

export enum CheckRunStatusInSchema {
  Queued = 'QUEUED',
  Completed = 'COMPLETED'
}

export enum CheckRunConclusionInSchema {
  Success = 'SUCCESS',
  Failure = 'FAILURE'
}

export enum TestResultAssetKind {
  Screenshot = 'screenshot'
}

export enum TestResultAssetType {
  Actual = 'actual',
  Expected = 'expected',
  Diff = 'diff'
}

export type TaskCheckRun = {
  __typename?: 'TaskCheckRun';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  detailsUrl?: Maybe<Scalars['String']>;
  conclusion?: Maybe<CheckRunConclusionInSchema>;
  status?: Maybe<CheckRunStatusInSchema>;
};

export type TestsResultsMeta = {
  __typename?: 'TestsResultsMeta';
  count?: Maybe<Scalars['Int']>;
  failed?: Maybe<Scalars['Int']>;
  passed?: Maybe<Scalars['Int']>;
  isSuccess?: Maybe<Scalars['Boolean']>;
  isFail?: Maybe<Scalars['Boolean']>;
  isLinterPassed?: Maybe<Scalars['Boolean']>;
};

export type TestError = {
  __typename?: 'TestError';
  actual?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['Boolean']>;
  type?: Maybe<TestErrorType>;
  resultType: TestErrorResultType;
  details?: Maybe<Scalars['String']>;
};

export type TestResult = {
  __typename?: 'TestResult';
  title?: Maybe<Scalars['String']>;
  fullTitle?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  status?: Maybe<TestStatus>;
  error?: Maybe<TestError>;
  message?: Maybe<Scalars['String']>;
  stack?: Maybe<Scalars['String']>;
  logs?: Maybe<Array<Log>>;
  actual?: Maybe<Scalars['String']>;
  assets?: Maybe<Array<TestResultAsset>>;
};

export type TestResultAsset = {
  __typename?: 'TestResultAsset';
  groupId: Scalars['Int'];
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  type: TestResultAssetType;
  kind: TestResultAssetKind;
};

export type Log = {
  __typename?: 'Log';
  type: LogType;
  data: Scalars['String'];
  timestamp: Scalars['String'];
  uuid: Scalars['String'];
};

export type TaskResults = {
  __typename?: 'TaskResults';
  meta: TestsResultsMeta;
  results: Array<TestResult>;
  linterResults?: Maybe<Array<LinterResult>>;
  buildResult?: Maybe<ErrorDetails>;
};

export enum PeerReviewStatus {
  Unavailable = 'Unavailable',
  Blocked = 'Blocked',
  Pending = 'Pending',
  Reviewing = 'Reviewing',
  Done = 'Done',
  Skipped = 'Skipped'
}

export type PeerReview = {
  __typename?: 'PeerReview';
  id: Scalars['Int'];
  status: PeerReviewStatus;
  testTaskAttemptId?: Maybe<Scalars['Int']>;
  reviewerTestTaskAttemptId?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  canPeerReviewBeSkipped?: Maybe<Scalars['Boolean']>;
  revieweeTestTaskFlow?: Maybe<TestTaskFlow>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type TestTaskAttempt = {
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  testTaskId: Scalars['Int'];
  testTaskLinkId?: Maybe<Scalars['Int']>;
  testTaskVersionId: Scalars['Int'];
  flow?: Maybe<TestTaskFlow>;
  testTask: TestTask;
  hasBeenPassed: Scalars['Boolean'];
  status: TestTaskStatus;
  version: TestTaskVersion;
  createdAt?: Maybe<Scalars['Date']>;
  isPeerReviewAvailable?: Maybe<Scalars['Boolean']>;
  peerReview: PeerReview;
  shouldShowSolution?: Maybe<Scalars['Boolean']>;
  personalRating: Scalars['Int'];
  canSolve: Scalars['Boolean'];
  solvedAt?: Maybe<Scalars['Date']>;
  submittedAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isAiAssistable?: Maybe<Scalars['Boolean']>;
  isAiReviewAvailable?: Maybe<Scalars['Boolean']>;
};

export type QuizAttempt = TestTaskAttempt & {
  __typename?: 'QuizAttempt';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  testTaskId: Scalars['Int'];
  testTaskLinkId: Scalars['Int'];
  testTaskVersionId: Scalars['Int'];
  quiz: QuizInfo;
  flow?: Maybe<TestTaskFlow>;
  testTask: TestTask;
  hasBeenPassed: Scalars['Boolean'];
  meta?: Maybe<QuizMeta>;
  teamProjectQuizMeta?: Maybe<TeamProjectQuizMeta>;
  status: TestTaskStatus;
  version: QuizTestTaskVersion;
  createdAt?: Maybe<Scalars['Date']>;
  isPeerReviewAvailable?: Maybe<Scalars['Boolean']>;
  peerReview: PeerReview;
  shouldShowSolution?: Maybe<Scalars['Boolean']>;
  personalRating: Scalars['Int'];
  canSolve: Scalars['Boolean'];
  solvedAt?: Maybe<Scalars['Date']>;
  submittedAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isAiAssistable?: Maybe<Scalars['Boolean']>;
  isAiReviewAvailable?: Maybe<Scalars['Boolean']>;
};

export type QuizMeta = {
  __typename?: 'QuizMeta';
  passed?: Maybe<Scalars['Int']>;
  failed?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
};

export type TeamProjectQuizMeta = {
  __typename?: 'TeamProjectQuizMeta';
  totalCount: Scalars['Int'];
  passedCount: Scalars['Int'];
};

export type QuizInfo = {
  __typename?: 'QuizInfo';
  id: Scalars['Int'];
  testTaskVersionId: Scalars['Int'];
  slug: Scalars['String'];
  shuffleable?: Maybe<Scalars['Boolean']>;
  restartable?: Maybe<Scalars['Boolean']>;
  showCorrectAnswers?: Maybe<Scalars['Boolean']>;
  correctAnswersCountToComplete?: Maybe<Scalars['Int']>;
  quizQuestions: Array<QuizQuestion>;
  isWrongAnswerBlocker?: Maybe<Scalars['Boolean']>;
};

export type QuizQuestion = {
  __typename?: 'QuizQuestion';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  type: QuizQuestionType;
  quizAnswers: Array<QuizAnswer>;
  description?: Maybe<Scalars['String']>;
  descriptionCode?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  timeToSolve: Scalars['Int'];
  images?: Maybe<Array<File>>;
  answerTemplateCode?: Maybe<Scalars['String']>;
  instructionsCode?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  answerTemplate?: Maybe<Scalars['String']>;
};

export type QuizAnswer = {
  __typename?: 'QuizAnswer';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  quizQuestionId: Scalars['Int'];
  isCorrect: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<File>>;
  answerOrder?: Maybe<Scalars['Int']>;
};

export type AttachAttempt = TestTaskAttempt & {
  __typename?: 'AttachAttempt';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  testTaskId: Scalars['Int'];
  testTaskLinkId: Scalars['Int'];
  testTaskVersionId: Scalars['Int'];
  submissions: Array<AttachmentTaskSubmission>;
  flow?: Maybe<TestTaskFlow>;
  reviews: Array<TaskReview>;
  version: AttachmentTestTaskVersion;
  testTask: TestTask;
  hasBeenPassed: Scalars['Boolean'];
  status: TestTaskStatus;
  createdAt?: Maybe<Scalars['Date']>;
  isPeerReviewAvailable?: Maybe<Scalars['Boolean']>;
  peerReview: PeerReview;
  shouldShowSolution?: Maybe<Scalars['Boolean']>;
  personalRating: Scalars['Int'];
  canSolve: Scalars['Boolean'];
  solutionUrl?: Maybe<Scalars['String']>;
  solvedAt?: Maybe<Scalars['Date']>;
  submittedAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  maxReviewIterationsCount: Scalars['Int'];
  isPeerReviewConducted?: Maybe<Scalars['Boolean']>;
  isAiAssistable?: Maybe<Scalars['Boolean']>;
  isAiReviewAvailable?: Maybe<Scalars['Boolean']>;
};


export type AttachAttemptReviewsArgs = {
  includeWithStatuses?: Maybe<Array<TaskReviewStatus>>;
  shouldFilterPendingAndExpiredPairs?: Maybe<Scalars['Boolean']>;
};

export type AttachmentTaskSubmission = {
  __typename?: 'AttachmentTaskSubmission';
  id: Scalars['Int'];
  testTaskAttemptId: Scalars['Int'];
  asset?: Maybe<Scalars['String']>;
  actions: Array<AttachmentTaskSubmissionAction>;
};

export type AttachmentTaskSubmissionAction = {
  __typename?: 'AttachmentTaskSubmissionAction';
  id: Scalars['Int'];
  submissionId: Scalars['Int'];
  name: AttachmentTaskSubmissionActionName;
  content?: Maybe<Scalars['String']>;
};

export type CodeEditorAttempt = TestTaskAttempt & {
  __typename?: 'CodeEditorAttempt';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  testTaskId: Scalars['Int'];
  testTaskLinkId: Scalars['Int'];
  testTaskVersionId: Scalars['Int'];
  testResults?: Maybe<TaskResults>;
  files: Array<AbstractTestTaskAttemptFile>;
  version: CodeEditorTestTaskVersion;
  flow?: Maybe<TestTaskFlow>;
  testTask: TestTask;
  hasBeenPassed: Scalars['Boolean'];
  status: TestTaskStatus;
  createdAt?: Maybe<Scalars['Date']>;
  isPeerReviewAvailable?: Maybe<Scalars['Boolean']>;
  peerReview: PeerReview;
  shouldShowSolution?: Maybe<Scalars['Boolean']>;
  personalRating: Scalars['Int'];
  canSolve: Scalars['Boolean'];
  solvedAt?: Maybe<Scalars['Date']>;
  submittedAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isAiAssistable?: Maybe<Scalars['Boolean']>;
  isAiReviewAvailable?: Maybe<Scalars['Boolean']>;
};

export type GitHubInfo = {
  __typename?: 'GitHubInfo';
  testTaskAttemptId?: Maybe<Scalars['Int']>;
  githubId?: Maybe<Scalars['String']>;
  githubLogin?: Maybe<Scalars['String']>;
  forkId?: Maybe<Scalars['String']>;
  pullRequestId?: Maybe<Scalars['String']>;
  state?: Maybe<PullRequestState>;
  reviewDecision?: Maybe<PullRequestReviewDecision>;
  reviewerGithubId?: Maybe<Scalars['String']>;
  reviewerUsername?: Maybe<Scalars['String']>;
  checkRun?: Maybe<Array<Maybe<TaskCheckRun>>>;
  status?: Maybe<GitHubStatus>;
  pullRequestNumber?: Maybe<Scalars['Int']>;
};

export type GitHubAttempt = TestTaskAttempt & {
  __typename?: 'GitHubAttempt';
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  testTaskId: Scalars['Int'];
  testTaskLinkId?: Maybe<Scalars['Int']>;
  testTaskVersionId: Scalars['Int'];
  pullRequest?: Maybe<PullRequest>;
  flow?: Maybe<TestTaskFlow>;
  testTask: TestTask;
  hasBeenPassed: Scalars['Boolean'];
  gitHubInfo?: Maybe<GitHubInfo>;
  status: TestTaskStatus;
  version: GithubTestTaskVersion;
  createdAt?: Maybe<Scalars['Date']>;
  isPeerReviewAvailable?: Maybe<Scalars['Boolean']>;
  peerReview: PeerReview;
  shouldShowSolution?: Maybe<Scalars['Boolean']>;
  personalRating: Scalars['Int'];
  canSolve: Scalars['Boolean'];
  solutionUrl?: Maybe<Scalars['String']>;
  solvedAt?: Maybe<Scalars['Date']>;
  submittedAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  maxReviewIterationsCount: Scalars['Int'];
  isPeerReviewConducted?: Maybe<Scalars['Boolean']>;
  isAiAssistable?: Maybe<Scalars['Boolean']>;
  reviews: Array<TaskReview>;
  isAiReviewAvailable?: Maybe<Scalars['Boolean']>;
};


export type GitHubAttemptReviewsArgs = {
  includeWithStatuses?: Maybe<Array<TaskReviewStatus>>;
  shouldFilterPendingAndExpiredPairs?: Maybe<Scalars['Boolean']>;
};

export type CheckQuizResult = TestTaskAttempt & {
  __typename?: 'CheckQuizResult';
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  testTaskId: Scalars['Int'];
  testTaskLinkId?: Maybe<Scalars['Int']>;
  testTaskVersionId: Scalars['Int'];
  rating?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  passed?: Maybe<Scalars['Int']>;
  flow?: Maybe<TestTaskFlow>;
  testTask: TestTask;
  hasBeenPassed: Scalars['Boolean'];
  status: TestTaskStatus;
  version: TestTaskVersion;
  createdAt?: Maybe<Scalars['Date']>;
  isPeerReviewAvailable?: Maybe<Scalars['Boolean']>;
  peerReview: PeerReview;
  shouldShowSolution?: Maybe<Scalars['Boolean']>;
  personalRating: Scalars['Int'];
  canSolve: Scalars['Boolean'];
  solvedAt?: Maybe<Scalars['Date']>;
  submittedAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  teamProjectQuizMeta?: Maybe<TeamProjectQuizMeta>;
  isAiAssistable?: Maybe<Scalars['Boolean']>;
  isAiReviewAvailable?: Maybe<Scalars['Boolean']>;
};

export type AddAttachmentTaskLinkResult = TestTaskAttempt & {
  __typename?: 'AddAttachmentTaskLinkResult';
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  testTaskId: Scalars['Int'];
  testTaskLinkId?: Maybe<Scalars['Int']>;
  testTaskVersionId: Scalars['Int'];
  review: TaskReview;
  flow?: Maybe<TestTaskFlow>;
  testTask: TestTask;
  hasBeenPassed: Scalars['Boolean'];
  status: TestTaskStatus;
  version: TestTaskVersion;
  createdAt?: Maybe<Scalars['Date']>;
  isPeerReviewAvailable?: Maybe<Scalars['Boolean']>;
  peerReview: PeerReview;
  shouldShowSolution?: Maybe<Scalars['Boolean']>;
  personalRating: Scalars['Int'];
  canSolve: Scalars['Boolean'];
  solvedAt?: Maybe<Scalars['Date']>;
  submittedAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isAiAssistable?: Maybe<Scalars['Boolean']>;
  isAiReviewAvailable?: Maybe<Scalars['Boolean']>;
};

export type CodeEditorCheckResult = TestTaskAttempt & {
  __typename?: 'CodeEditorCheckResult';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  testTaskId: Scalars['Int'];
  testTaskLinkId: Scalars['Int'];
  testTaskVersionId: Scalars['Int'];
  version: TestTaskVersion;
  testResults: TaskResults;
  files: Array<Maybe<AbstractTestTaskAttemptFile>>;
  status: TestTaskStatus;
  flow?: Maybe<TestTaskFlow>;
  testTask: TestTask;
  hasBeenPassed: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Date']>;
  isPeerReviewAvailable?: Maybe<Scalars['Boolean']>;
  peerReview: PeerReview;
  shouldShowSolution?: Maybe<Scalars['Boolean']>;
  personalRating: Scalars['Int'];
  canSolve: Scalars['Boolean'];
  solvedAt?: Maybe<Scalars['Date']>;
  submittedAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isAiAssistable?: Maybe<Scalars['Boolean']>;
  isAiReviewAvailable?: Maybe<Scalars['Boolean']>;
};

export type LinterResult = {
  __typename?: 'LinterResult';
  path: Scalars['String'];
  status: TestStatus;
  errors?: Maybe<Array<LinterErrorData>>;
};

export type LinterErrorData = {
  __typename?: 'LinterErrorData';
  name?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['Int']>;
  column?: Maybe<Scalars['Int']>;
  endLine?: Maybe<Scalars['Int']>;
  endColumn?: Maybe<Scalars['Int']>;
  source: Scalars['String'];
  severity?: Maybe<Scalars['Int']>;
};

export type AttemptsByUserIdsPayload = {
  userIds: Array<Scalars['Int']>;
  taskIds: Array<Scalars['Int']>;
};

export type ErrorDetails = {
  __typename?: 'ErrorDetails';
  message: Scalars['String'];
  origin: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  stack?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  startLine?: Maybe<Scalars['Int']>;
  startColumn?: Maybe<Scalars['Int']>;
};

export type TestTaskAttemptBuildResults = {
  __typename?: 'TestTaskAttemptBuildResults';
  id: Scalars['Int'];
  buildResult?: Maybe<ErrorDetails>;
};

export enum TestTaskFileKind {
  Binary = 'Binary',
  Text = 'Text'
}

export type BaseTestTaskfile = {
  id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type AttemptFileToCreate = {
  path: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
  size?: Maybe<Scalars['Int']>;
};

export type AbstractTestTaskAttemptFile = {
  id: Scalars['Int'];
  testTaskAttemptId: Scalars['Int'];
  path: Scalars['String'];
  kind: TestTaskFileKind;
  content?: Maybe<Scalars['String']>;
  isReadonly?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  url?: Maybe<Scalars['String']>;
};

export type TestTaskAttemptFile = AbstractTestTaskAttemptFile & {
  __typename?: 'TestTaskAttemptFile';
  id: Scalars['Int'];
  testTaskAttemptId: Scalars['Int'];
  path: Scalars['String'];
  kind: TestTaskFileKind;
  content?: Maybe<Scalars['String']>;
  isReadonly?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  url?: Maybe<Scalars['String']>;
  isEntry?: Maybe<Scalars['Boolean']>;
};

export type TestTaskAttemptBinaryFile = AbstractTestTaskAttemptFile & {
  __typename?: 'TestTaskAttemptBinaryFile';
  id: Scalars['Int'];
  testTaskAttemptId: Scalars['Int'];
  path: Scalars['String'];
  kind: TestTaskFileKind;
  content?: Maybe<Scalars['String']>;
  isReadonly?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  url?: Maybe<Scalars['String']>;
};

export type BaseTestTaskAttemptFile = {
  __typename?: 'BaseTestTaskAttemptFile';
  id: Scalars['Int'];
  path: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type AbstractTestTaskFile = {
  id: Scalars['Int'];
  testTaskId: Scalars['Int'];
  testTaskVersionId: Scalars['Int'];
  path: Scalars['String'];
  githubPath: Scalars['String'];
  type: TestTaskFileType;
  content: Scalars['String'];
  isReadonly?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type TestTaskFile = AbstractTestTaskFile & {
  __typename?: 'TestTaskFile';
  id: Scalars['Int'];
  testTaskId: Scalars['Int'];
  testTaskVersionId: Scalars['Int'];
  path: Scalars['String'];
  githubPath: Scalars['String'];
  type: TestTaskFileType;
  content: Scalars['String'];
  updatedAt: Scalars['Date'];
  isReadonly?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type TestTaskBinaryFile = AbstractTestTaskFile & {
  __typename?: 'TestTaskBinaryFile';
  id: Scalars['Int'];
  testTaskId: Scalars['Int'];
  testTaskVersionId: Scalars['Int'];
  path: Scalars['String'];
  githubPath: Scalars['String'];
  type: TestTaskFileType;
  content: Scalars['String'];
  updatedAt: Scalars['Date'];
  isReadonly?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export enum TestTaskFileType {
  Code = 'Code',
  Test = 'Test',
  Reference = 'Reference',
  Template = 'Template'
}

export type TestTaskFileToUpload = {
  path: Scalars['String'];
  githubPath: Scalars['String'];
  isReadonly: Scalars['Boolean'];
  type: TestTaskFileType;
  file?: Maybe<Scalars['Upload']>;
  size?: Maybe<Scalars['Int']>;
};

export type TestTaskFileToRename = {
  id: Scalars['Int'];
  path: Scalars['String'];
  newPath: Scalars['String'];
  type: TestTaskFileType;
  githubPath: Scalars['String'];
};

export type TestTaskFileUpdateOptions = {
  isReadonly?: Maybe<Scalars['Boolean']>;
  type?: Maybe<TestTaskFileType>;
};

export type TestTaskFileToDelete = {
  id: Scalars['Int'];
  path: Scalars['String'];
  type: TestTaskFileType;
  testTaskVersionId?: Maybe<Scalars['Int']>;
};

export type TestTaskLinkPatch = {
  ttl?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['String']>;
};

export type TestTaskPreviewLinkUuid = {
  __typename?: 'testTaskPreviewLinkUUID';
  testTaskLinkUUID?: Maybe<Scalars['String']>;
};

export type AttemptUpdate = {
  __typename?: 'AttemptUpdate';
  id: Scalars['Int'];
  testTaskLinkId: Scalars['Int'];
  testTaskId: Scalars['Int'];
  hasBeenPassed: Scalars['Boolean'];
};

export type TestTaskLinkUpdates = {
  __typename?: 'TestTaskLinkUpdates';
  id: Scalars['Int'];
  actualAttempts: Array<TestTaskAttempt>;
  outdatedAttempts: Array<TestTaskAttempt>;
  newTasks: Array<TestTask>;
  removedTasks: Array<TestTask>;
};

export type TestTaskLink = {
  __typename?: 'TestTaskLink';
  id: Scalars['Int'];
  uuid: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
  courseUserId?: Maybe<Scalars['Int']>;
  testTaskIds: Array<Scalars['Int']>;
  ttl?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['String']>;
  tasks: Array<TestTask>;
  /** @deprecated Use tasks instead */
  dailyTasks: Array<DailyTask>;
  task?: Maybe<TestTask>;
  attempts: Array<TestTaskAttempt>;
  attemptByVersion: TestTaskAttempt;
  /** @deprecated Use attempts instead */
  attemptsByTaskVersion: Array<Maybe<TestTaskAttempt>>;
  updates?: Maybe<TestTaskLinkUpdates>;
};


export type TestTaskLinkTaskArgs = {
  id: Scalars['Int'];
};


export type TestTaskLinkAttemptByVersionArgs = {
  testTaskVersionId: Scalars['Int'];
};

export type TestTaskGitHubInfo = {
  __typename?: 'TestTaskGitHubInfo';
  id: Scalars['Int'];
  repoName: Scalars['String'];
  repoOwner: Scalars['String'];
};

export type TestTaskVersion = {
  id: Scalars['Int'];
  testTaskId: Scalars['Int'];
  versionNumber: Scalars['Int'];
  creationStatus?: Maybe<TestTaskVersionCreationStatus>;
};

export type DocumentflowTestTaskVersion = TestTaskVersion & {
  __typename?: 'DocumentflowTestTaskVersion';
  id: Scalars['Int'];
  testTaskId: Scalars['Int'];
  versionNumber: Scalars['Int'];
  creationStatus?: Maybe<TestTaskVersionCreationStatus>;
};

export type QuizTestTaskVersion = TestTaskVersion & {
  __typename?: 'QuizTestTaskVersion';
  id: Scalars['Int'];
  testTaskId: Scalars['Int'];
  versionNumber: Scalars['Int'];
  creationStatus?: Maybe<TestTaskVersionCreationStatus>;
};

export type AttachmentTestTaskVersion = TestTaskVersion & {
  __typename?: 'AttachmentTestTaskVersion';
  id: Scalars['Int'];
  testTaskId: Scalars['Int'];
  versionNumber: Scalars['Int'];
  body: Scalars['String'];
  creationStatus?: Maybe<TestTaskVersionCreationStatus>;
  descriptionFiles: Array<ContentFile>;
};


export type AttachmentTestTaskVersionBodyArgs = {
  locale?: Maybe<LanguageName>;
  withoutFallback?: Maybe<Scalars['Boolean']>;
};

export type CodeEditorTestTaskVersion = TestTaskVersion & {
  __typename?: 'CodeEditorTestTaskVersion';
  id: Scalars['Int'];
  testTaskId: Scalars['Int'];
  versionNumber: Scalars['Int'];
  codeFiles: Array<AbstractTestTaskFile>;
  testFiles: Array<AbstractTestTaskFile>;
  body: Scalars['String'];
  referenceFiles: Array<AbstractTestTaskFile>;
  files: Array<AbstractTestTaskFile>;
  creationStatus?: Maybe<TestTaskVersionCreationStatus>;
  descriptionFiles: Array<ContentFile>;
};


export type CodeEditorTestTaskVersionBodyArgs = {
  locale?: Maybe<LanguageName>;
  withoutFallback?: Maybe<Scalars['Boolean']>;
};

export type WebflowTestTaskVersion = TestTaskVersion & {
  __typename?: 'WebflowTestTaskVersion';
  id: Scalars['Int'];
  testTaskId: Scalars['Int'];
  versionNumber: Scalars['Int'];
  codeFiles: Array<AbstractTestTaskFile>;
  testFiles: Array<AbstractTestTaskFile>;
  body: Scalars['String'];
  referenceFiles: Array<AbstractTestTaskFile>;
  files: Array<AbstractTestTaskFile>;
  creationStatus?: Maybe<TestTaskVersionCreationStatus>;
  descriptionFiles: Array<ContentFile>;
};


export type WebflowTestTaskVersionBodyArgs = {
  locale?: Maybe<LanguageName>;
  withoutFallback?: Maybe<Scalars['Boolean']>;
};

export type GithubTestTaskVersion = TestTaskVersion & {
  __typename?: 'GithubTestTaskVersion';
  id: Scalars['Int'];
  testTaskId: Scalars['Int'];
  versionNumber: Scalars['Int'];
  gitHubInfo?: Maybe<TestTaskGitHubInfo>;
  creationStatus?: Maybe<TestTaskVersionCreationStatus>;
};

export type MarkupTestTaskVersion = TestTaskVersion & {
  __typename?: 'MarkupTestTaskVersion';
  id: Scalars['Int'];
  testTaskId: Scalars['Int'];
  versionNumber: Scalars['Int'];
  codeFiles: Array<AbstractTestTaskFile>;
  testFiles: Array<AbstractTestTaskFile>;
  body: Scalars['String'];
  referenceFiles: Array<AbstractTestTaskFile>;
  files: Array<AbstractTestTaskFile>;
  creationStatus?: Maybe<TestTaskVersionCreationStatus>;
  descriptionFiles: Array<ContentFile>;
};


export type MarkupTestTaskVersionBodyArgs = {
  locale?: Maybe<LanguageName>;
  withoutFallback?: Maybe<Scalars['Boolean']>;
};

export enum TestTaskVersionOrderBy {
  VersionNumber = 'versionNumber',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export enum OrderDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export enum TestTaskVersionCreationStatus {
  Created = 'CREATED',
  Draft = 'DRAFT'
}

export type TestTasksPreview = {
  __typename?: 'TestTasksPreview';
  id: Scalars['Int'];
  courseId: Scalars['Int'];
  testTaskId: Scalars['Int'];
  testTask?: Maybe<TestTask>;
};

export type CreateTestTaskPreviewValues = {
  testTaskId: Scalars['Int'];
  professionSlug: Scalars['String'];
};

export type PostPaidCourseTodo = {
  __typename?: 'PostPaidCourseTodo';
  monthTimestamp: Scalars['String'];
  payment?: Maybe<PostpaidCoursePayment>;
  proofOfRevenue?: Maybe<PostPaidCourseProofOfRevenue>;
  jobSearch?: Maybe<PostPaidCourseJobSearch>;
};

export type PostPaidCourseJobSearch = {
  __typename?: 'PostPaidCourseJobSearch';
  isFirstJobSearch: Scalars['Boolean'];
};

export type PostpaidCoursePayment = {
  __typename?: 'PostpaidCoursePayment';
  deadline: Scalars['Date'];
  collectionStartDate: Scalars['Date'];
  status: PostPaidCoursePaymentStatus;
  isSkippable: Scalars['Boolean'];
  feeData?: Maybe<PostPaidCoursePaymentFeeData>;
  disabledReason?: Maybe<PaymentDisabledReason>;
};

export enum PaymentDisabledReason {
  TooEarlyToPay = 'TooEarlyToPay',
  OverdueProofOfRevenue = 'OverdueProofOfRevenue',
  PendingProofOfRevenue = 'PendingProofOfRevenue',
  IsManuallyRestricted = 'IsManuallyRestricted',
  PreviousPaymentNotCompleted = 'PreviousPaymentNotCompleted'
}

export enum PostPaidCoursePaymentStatus {
  Accepted = 'Accepted',
  Skipped = 'Skipped',
  NotCompleted = 'NotCompleted'
}

export type PostPaidCoursePaymentFeeData = {
  __typename?: 'PostPaidCoursePaymentFeeData';
  extraFeeAmount: Scalars['Float'];
  percentageFeeAmount: Scalars['Float'];
  currencyCode: Scalars['String'];
};

export type PostPaidCourseProofOfRevenue = {
  __typename?: 'PostPaidCourseProofOfRevenue';
  deadline: Scalars['Date'];
  collectionStartDate: Scalars['Date'];
  periodData: ProofOfRevenuePeriodData;
  status: PostPaidCourseProofOfRevenueStatus;
  disabledReason?: Maybe<ProofOfRevenueDisabledReason>;
};

export enum ProofOfRevenueDisabledReason {
  TooEarlyToUpload = 'TooEarlyToUpload',
  PreviousProofOfRevenueRequired = 'PreviousProofOfRevenueRequired',
  PendingProofOfRevenue = 'PendingProofOfRevenue'
}

export enum PostPaidCourseProofOfRevenueStatus {
  Accepted = 'Accepted',
  Uploaded = 'Uploaded',
  NotCompleted = 'NotCompleted',
  OnReview = 'OnReview',
  Failed = 'Failed',
  Declined = 'Declined'
}

export type TooltipTexts = {
  __typename?: 'TooltipTexts';
  id: Scalars['Int'];
  text: Scalars['String'];
};

export type Translate = {
  __typename?: 'Translate';
  code: Scalars['String'];
  page: Scalars['String'];
  value: Scalars['String'];
  language: Scalars['String'];
};

export type TranslateInput = {
  code: Scalars['String'];
  page: Scalars['String'];
  value: Scalars['String'];
  language: Scalars['String'];
};

export type TranslatesCollection = {
  __typename?: 'TranslatesCollection';
  id: Scalars['String'];
  collection: TranslatesPack;
};

export type TranslatesPack = {
  __typename?: 'TranslatesPack';
  uk?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  ru?: Maybe<Scalars['String']>;
  pl?: Maybe<Scalars['String']>;
  pt?: Maybe<Scalars['String']>;
};

export type TranslatesPackInput = {
  uk?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  ru?: Maybe<Scalars['String']>;
  pl?: Maybe<Scalars['String']>;
  pt?: Maybe<Scalars['String']>;
};

export type TranslationResult = {
  __typename?: 'TranslationResult';
  id: Scalars['String'];
  translatedText: Scalars['String'];
  targetLanguage: LanguageName;
  sourceLanguage?: Maybe<LanguageName>;
};

export type TypeformAnswer = {
  __typename?: 'TypeformAnswer';
  questionId: Scalars['String'];
  type: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type TypingSpeedTest = {
  __typename?: 'TypingSpeedTest';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  wpm: Scalars['Float'];
  rawWpm: Scalars['Float'];
  accuracy: Scalars['Float'];
  duration: Scalars['Int'];
  createdAt: Scalars['Date'];
  avgWpm: Scalars['Float'];
  avgRawWpm: Scalars['Float'];
  avgAccuracy: Scalars['Float'];
};

export type CreateTypingSpeedTestOptions = {
  userId: Scalars['Int'];
  wpm: Scalars['Float'];
  rawWpm: Scalars['Float'];
  accuracy: Scalars['Float'];
  duration: Scalars['Int'];
};

export type UnprocessedLocation = {
  __typename?: 'UnprocessedLocation';
  id: Scalars['Int'];
  name: Scalars['String'];
  type: UnprocessedLocationType;
  status?: Maybe<UnprocessedLocationStatus>;
};

export enum UnprocessedLocationType {
  City = 'CITY',
  Country = 'COUNTRY'
}

export enum UnprocessedLocationStatus {
  Resolved = 'RESOLVED',
  Ignored = 'IGNORED'
}

export type UnprocessedLocationsQueryArgs = {
  type?: Maybe<UnprocessedLocationType>;
  status?: Maybe<UnprocessedLocationStatus>;
};

export type UpdateUnprocessedLocationMutationArgs = {
  id: Scalars['Int'];
  status: UnprocessedLocationStatus;
  locationId?: Maybe<Scalars['Int']>;
};

export type UsedPromoCode = {
  __typename?: 'UsedPromoCode';
  id: Scalars['Int'];
  courseUserId?: Maybe<Scalars['Int']>;
  promoCodeId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  promoCode: PromoCode;
  bpmUser?: Maybe<UserWithBpmInfo>;
  bpmCourseUser?: Maybe<CourseUserWithBpmInfo>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type UserWithBpmInfo = {
  __typename?: 'UserWithBpmInfo';
  id: Scalars['Int'];
  bpmContactId?: Maybe<Scalars['String']>;
};

export type CourseUserWithBpmInfo = {
  __typename?: 'CourseUserWithBpmInfo';
  id: Scalars['Int'];
  bpmLeadId?: Maybe<Scalars['String']>;
};

export enum Filled {
  FilledIn = 'FILLED_IN',
  NotFilledIn = 'NOT_FILLED_IN'
}

export enum LeadType {
  FlexLead = 'FLEX_LEAD',
  FtLead = 'FT_LEAD'
}

export enum RegistrationBrand {
  Mate = 'mate',
  Knowely = 'knowely'
}

export enum PreferentialTerm {
  Combatant = 'COMBATANT'
}

export enum StudentStatus {
  New = 'new',
  PreCourse = 'preCourse',
  Studying = 'studying',
  CourseCompleted = 'courseCompleted',
  PreEmployment = 'preEmployment',
  Employment = 'employment',
  Working = 'working',
  UnpaidInternship = 'unpaidInternship',
  JobChange = 'jobChange',
  CareerBreak = 'careerBreak',
  CareerBreakArmedForces = 'careerBreakArmedForces',
  Graduated = 'graduated',
  FUnEmployed = 'fUnEmployed',
  FDidNotStart = 'fDidNotStart',
  FDisappearedWhileStudying = 'fDisappearedWhileStudying',
  FQuitItAfterJobOffer = 'fQuitItAfterJobOffer',
  FStoppedPaying = 'fStoppedPaying',
  FTerminatedContract = 'fTerminatedContract',
  FWithdrawnContract = 'fWithdrawnContract',
  FLegalProcessing = 'fLegalProcessing',
  SubscriptionExpired = 'subscriptionExpired',
  DidNotSignAgreement = 'didNotSignAgreement',
  ChangedFormat = 'changedFormat'
}

export enum TerminationReason {
  TimeCommitmentIssues = 'TIME_COMMITMENT_ISSUES',
  SyllabusOrContentIssues = 'SYLLABUS_OR_CONTENT_ISSUES',
  PersonalOrFamilyIssues = 'PERSONAL_OR_FAMILY_ISSUES',
  StoppedResponding = 'STOPPED_RESPONDING',
  MoralOrDisciplineIssues = 'MORAL_OR_DISCIPLINE_ISSUES',
  LostInterestInStudyField = 'LOST_INTEREST_IN_STUDY_FIELD',
  FailedExams = 'FAILED_EXAMS'
}

export enum EmploymentType {
  Fop_3Group = 'FOP_3_GROUP',
  Fop_3Group_2Percent = 'FOP_3_GROUP_2_PERCENT',
  Other = 'OTHER'
}

export enum UserEnglishLevel {
  Unspecified = 'UNSPECIFIED',
  Beginner = 'BEGINNER',
  LowElementary = 'LOW_ELEMENTARY',
  HighElementary = 'HIGH_ELEMENTARY',
  LowPreIntermediate = 'LOW_PRE_INTERMEDIATE',
  HighPreIntermediate = 'HIGH_PRE_INTERMEDIATE',
  LowIntermediate = 'LOW_INTERMEDIATE',
  HighIntermediate = 'HIGH_INTERMEDIATE',
  UpperIntermediate = 'UPPER_INTERMEDIATE',
  Advanced = 'ADVANCED'
}

export type UserValues = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  bpmContactId?: Maybe<Scalars['String']>;
  agreementMonths?: Maybe<Scalars['Int']>;
  agreementPercent?: Maybe<Scalars['Int']>;
  agreementPaymentDueDate?: Maybe<Scalars['Int']>;
  studentStatus?: Maybe<StudentStatus>;
  taxId?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  studentsGroupId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telegramNickName?: Maybe<Scalars['String']>;
  englishLevel?: Maybe<UserEnglishLevel>;
  googleCityId?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  salesId?: Maybe<Scalars['Int']>;
  githubUrl?: Maybe<Scalars['String']>;
  userRole?: Maybe<UserRole>;
  username?: Maybe<Scalars['String']>;
  newslettersSubscription?: Maybe<Scalars['Boolean']>;
  terminationReason?: Maybe<TerminationReason>;
  linkedin?: Maybe<Scalars['String']>;
  bpmReferrerContactId?: Maybe<Scalars['String']>;
  careerSupportManagerId?: Maybe<Scalars['Int']>;
  preferentialTerm?: Maybe<PreferentialTerm>;
  domainId?: Maybe<Scalars['Int']>;
};

export enum HiringsUserType {
  Candidate = 'CANDIDATE',
  Recruiter = 'RECRUITER'
}

export type EnglishDashboardUsersFilters = {
  emailQuery?: Maybe<Scalars['String']>;
  englishLevel?: Maybe<UserEnglishLevel>;
  studentsGroup?: Maybe<Scalars['String']>;
  recent?: Maybe<Scalars['Boolean']>;
};

export type UpdateProfileValues = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  telegramNickName?: Maybe<Scalars['String']>;
  emergencyPhone?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  emergencyContactPerson?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  djinni?: Maybe<Scalars['String']>;
  behance?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
  regionId?: Maybe<Scalars['Int']>;
  cityId?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  customCity?: Maybe<Scalars['String']>;
  novaPoshtaDep?: Maybe<Scalars['String']>;
  googleCityId?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  telegramUrl?: Maybe<Scalars['String']>;
  pushNotificationsAllowed?: Maybe<Scalars['Boolean']>;
  university?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  isMarried?: Maybe<Scalars['Boolean']>;
  hasChildren?: Maybe<Scalars['Boolean']>;
  profileStatusText?: Maybe<Scalars['String']>;
};

export type EmploymentDeadlineNotificationData = {
  id: Scalars['Int'];
  employmentDeadline: Scalars['Date'];
};

export type UserSubscriptionHash = {
  id: Scalars['Int'];
  entrepreneurSubscriptionHash: Scalars['String'];
};

export type UsersFilter = {
  status?: Maybe<Array<Maybe<StudentStatus>>>;
  email?: Maybe<Scalars['String']>;
  email_eq?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  userRoles?: Maybe<Array<Maybe<UserRole>>>;
};

export type EventHostFilters = {
  userRoles?: Maybe<Array<Maybe<UserRole>>>;
  query?: Maybe<Scalars['String']>;
};

export type StudentsTrackerFilter = {
  statuses?: Maybe<Array<Maybe<StudentStatus>>>;
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  courseType?: Maybe<CourseType>;
};

export type UpdatePersonalChat = {
  userId: Scalars['Int'];
  studentsBPMGroupId: Scalars['String'];
  personalChatId: Scalars['Int'];
};

export type PeopleforceEmployeeProfile = {
  peopleforceId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  workEmail: Scalars['String'];
  contractorType?: Maybe<TeacherTypes>;
  contractorDivision?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  terminationDate?: Maybe<Scalars['Date']>;
};

export type Token = {
  __typename?: 'Token';
  accessToken: Scalars['String'];
};

export type AccessToken = {
  __typename?: 'AccessToken';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type UserConfig = {
  __typename?: 'UserConfig';
  unfamiliarModules?: Maybe<Array<Scalars['String']>>;
};

export type User = {
  __typename?: 'User';
  isAuthUser: Scalars['Boolean'];
  achievements?: Maybe<Array<UserAchievement>>;
  activeSoftDeadline?: Maybe<SoftDeadline>;
  activeSubscription?: Maybe<PaymentSubscription>;
  agreementMonths?: Maybe<Scalars['Int']>;
  agreementPercent?: Maybe<Scalars['Int']>;
  agreementPaymentDueDate?: Maybe<Scalars['Int']>;
  avatar?: Maybe<File>;
  behance?: Maybe<Scalars['String']>;
  bpmContactId?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  githubUrl?: Maybe<Scalars['String']>;
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  confirmEmailToken?: Maybe<Scalars['String']>;
  computedName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['Int']>;
  courseType?: Maybe<CourseType>;
  courseUserByCourseId?: Maybe<CourseUser>;
  courseUserByGroupName?: Maybe<CourseUser>;
  courseUsers: Array<CourseUser>;
  djinni?: Maybe<Scalars['String']>;
  economicActivityCode?: Maybe<Scalars['String']>;
  economicCodes?: Maybe<Array<UserEconomicCode>>;
  email: Scalars['String'];
  emergencyContactPerson?: Maybe<Scalars['String']>;
  emergencyPhone?: Maybe<Scalars['String']>;
  employed?: Maybe<Scalars['Boolean']>;
  entrepreneurSubscriptionHash?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** first visit utm_campaign before registration */
  fvCampaign?: Maybe<Scalars['String']>;
  /** first visit utm_content before registration */
  fvContent?: Maybe<Scalars['String']>;
  /** first visit utm_medium before registration */
  fvMedium?: Maybe<Scalars['String']>;
  /** first visit utm_source before registration */
  fvSource?: Maybe<Scalars['String']>;
  /** first visit utm_term before registration */
  fvTerm?: Maybe<Scalars['String']>;
  /** first visit utm_type before registration */
  fvType?: Maybe<Scalars['String']>;
  /** first visit page before registration */
  fvPage?: Maybe<Scalars['String']>;
  gAgent?: Maybe<Scalars['String']>;
  gClientid?: Maybe<Scalars['String']>;
  gIp?: Maybe<Scalars['String']>;
  gclid?: Maybe<Scalars['String']>;
  utmDevicetype?: Maybe<Scalars['String']>;
  utmPlacement?: Maybe<Scalars['String']>;
  fbp?: Maybe<Scalars['String']>;
  fbc?: Maybe<Scalars['String']>;
  fbclid?: Maybe<Scalars['String']>;
  rdtcid?: Maybe<Scalars['String']>;
  hasAccessToEnglish?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  inactive?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastActionTime?: Maybe<Scalars['Date']>;
  lastName?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  /** last visit utm_campaign before registration */
  lvCampaign?: Maybe<Scalars['String']>;
  /** last visit utm_content before registration */
  lvContent?: Maybe<Scalars['String']>;
  /** last visit utm_medium before registration */
  lvMedium?: Maybe<Scalars['String']>;
  /** last visit utm_source before registration */
  lvSource?: Maybe<Scalars['String']>;
  /** last visit utm_term before registration */
  lvTerm?: Maybe<Scalars['String']>;
  /** last visit utm_type before registration */
  lvType?: Maybe<Scalars['String']>;
  utmTags?: Maybe<UtmTags>;
  newslettersSubscription?: Maybe<Scalars['Boolean']>;
  novaPoshtaDep?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  telegramNickName?: Maybe<Scalars['String']>;
  profileCompleted?: Maybe<Scalars['Boolean']>;
  referralDiscount?: Maybe<Scalars['Float']>;
  referralMultiplier?: Maybe<Scalars['Float']>;
  referrer?: Maybe<User>;
  referrerCodeId?: Maybe<Scalars['Int']>;
  region?: Maybe<Region>;
  regionId?: Maybe<Scalars['Int']>;
  skype?: Maybe<Scalars['String']>;
  studentStatus?: Maybe<StudentStatus>;
  studentsGroup?: Maybe<StudentsGroup>;
  subscriptions?: Maybe<Array<Maybe<PaymentSubscription>>>;
  tasksReviews: Array<TaskReview>;
  taxId?: Maybe<Scalars['String']>;
  testTaskAttempts?: Maybe<Array<Maybe<TestTaskAttempt>>>;
  /** @deprecated use userRoles instead, usage on client prohibited in any way */
  userRole: Scalars['String'];
  rolesIds: Array<Maybe<Scalars['Int']>>;
  userRoles: Array<Scalars['String']>;
  englishLevel?: Maybe<UserEnglishLevel>;
  englishLevelId?: Maybe<Scalars['Int']>;
  username: Scalars['String'];
  vacanciesVisitDate?: Maybe<Scalars['Date']>;
  shouldUpdateEmail?: Maybe<Scalars['Boolean']>;
  customCity?: Maybe<Scalars['String']>;
  referralEvents?: Maybe<Array<Maybe<ReferralEvent>>>;
  referralBonuses?: Maybe<Scalars['Float']>;
  lastCourseMonthPayment?: Maybe<CourseMonthPayment>;
  primaryCourseUser?: Maybe<CourseUser>;
  platformAnalyticsId: Scalars['String'];
  amplitudeId: Scalars['String'];
  type: UserType;
  permissions: Array<Scalars['String']>;
  grantedPermissions: Array<GrantedPermissionWithResources>;
  accessTokens: Array<AccessToken>;
  language?: Maybe<Language>;
  domain?: Maybe<Domain>;
  impersonateLink?: Maybe<Scalars['String']>;
  courseMonthPaymentRestricted: Scalars['Boolean'];
  recommendedEnglishModule?: Maybe<Scalars['String']>;
  recommendedEnglishTestTopic?: Maybe<Scalars['String']>;
  telegramUrl?: Maybe<Scalars['String']>;
  timeFromRegistration?: Maybe<Scalars['Int']>;
  isNewUser?: Maybe<Scalars['Boolean']>;
  employmentType?: Maybe<EmploymentType>;
  employmentTypeName?: Maybe<EmploymentTypeName>;
  pushNotificationsAllowed?: Maybe<Scalars['Boolean']>;
  appOnboarding?: Maybe<UserAppOnboarding>;
  proofsOfRevenue: Array<ProofOfRevenue>;
  isDeviceSubscribed: Scalars['Boolean'];
  userLegalDocuments?: Maybe<UserLegalDocuments>;
  studyingStartedAt?: Maybe<Scalars['Date']>;
  isNPSAvailable: Scalars['Boolean'];
  userDocument?: Maybe<UserDocument>;
  badgeType?: Maybe<UserAvatarBadgeType>;
  learnStatistics: LearnStatistics;
  applyingStatistics: ApplyingStatistics;
  sandboxCTAShown: Scalars['Boolean'];
  notifications: UserNotificationsForPagination;
  unreadNotificationsCount: Scalars['Int'];
  monthUserStreaks: Array<UserStreak>;
  config?: Maybe<UserConfig>;
  terminationReason?: Maybe<TerminationReason>;
  timezoneOffset?: Maybe<Scalars['Int']>;
  university?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  isMarried?: Maybe<Scalars['Boolean']>;
  hasChildren?: Maybe<Scalars['Boolean']>;
  agreementMinimumIncome?: Maybe<Scalars['Int']>;
  agreementMinimumIncomeCurrencyId?: Maybe<Scalars['Int']>;
  agreementMinimumIncomeThreshold?: Maybe<Scalars['Int']>;
  agreementSigningEntity?: Maybe<AgreementSigningEntity>;
  billingAddress?: Maybe<BillingAddress>;
  timezone?: Maybe<Scalars['String']>;
  lastTypingSpeedTest?: Maybe<TypingSpeedTest>;
  teacher?: Maybe<Teacher>;
  personalChat?: Maybe<AbstractChat>;
  profileStatusText?: Maybe<Scalars['String']>;
  shouldPayForInstalmentSubscription?: Maybe<Scalars['Boolean']>;
  peopleforceId?: Maybe<Scalars['Int']>;
  employmentDeadline?: Maybe<Scalars['Date']>;
  userJobCompany?: Maybe<Company>;
  shouldSetPassword?: Maybe<Scalars['Boolean']>;
  registrationBrand?: Maybe<RegistrationBrand>;
  isEmploymentProfileAvailable: Scalars['Boolean'];
  userCourse?: Maybe<Course>;
};


export type UserCourseUserByCourseIdArgs = {
  courseId: Scalars['Int'];
};


export type UserCourseUserByGroupNameArgs = {
  groupName: Scalars['String'];
};


export type UserCourseUsersArgs = {
  courseTypes?: Maybe<Array<Maybe<CourseType>>>;
  courseIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type UserTasksReviewsArgs = {
  where?: Maybe<TasksReviewsWhere>;
  order?: Maybe<TasksReviewsOrder>;
  limitPerTask?: Maybe<Scalars['Int']>;
};


export type UserReferralEventsArgs = {
  types?: Maybe<Array<Maybe<ReferralEventType>>>;
  statuses?: Maybe<Array<Maybe<ReferralEventStatus>>>;
};


export type UserIsDeviceSubscribedArgs = {
  os: Scalars['String'];
  pushToken?: Maybe<Scalars['String']>;
};


export type UserNotificationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type UserMonthUserStreaksArgs = {
  dateOfMonth?: Maybe<Scalars['Date']>;
};

export type AdminProfile = {
  __typename?: 'AdminProfile';
  id: Scalars['Int'];
  avatar?: Maybe<File>;
  username: Scalars['String'];
  city?: Maybe<City>;
  lastActionTime?: Maybe<Scalars['Date']>;
  profileStatusText?: Maybe<Scalars['String']>;
};

export type PrivateUserProfile = {
  __typename?: 'PrivateUserProfile';
  id: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  studentsGroup?: Maybe<UserProfileGroup>;
  username: Scalars['String'];
  avatar?: Maybe<File>;
};

export type PublicUserProfile = {
  __typename?: 'PublicUserProfile';
  id: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  studentsGroup?: Maybe<UserProfileGroup>;
  username: Scalars['String'];
  avatar?: Maybe<File>;
  achievements?: Maybe<Array<UserAchievement>>;
  profileStatusText?: Maybe<Scalars['String']>;
  city?: Maybe<City>;
  leaderboardUser?: Maybe<LeaderboardUser>;
  latestTopic?: Maybe<UserProfileLatestTopic>;
  latestTask?: Maybe<UserProfileLatestTask>;
  lastActionTime?: Maybe<Scalars['Date']>;
  socialLinks?: Maybe<UserProfileSocialLinks>;
};


export type PublicUserProfileAchievementsArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type UserProfileSocialLinks = {
  __typename?: 'UserProfileSocialLinks';
  githubUrl?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  behance?: Maybe<Scalars['String']>;
};

export type UserProfileGroup = {
  __typename?: 'UserProfileGroup';
  id: Scalars['Int'];
  name: Scalars['String'];
  profession?: Maybe<UserProfileGroupProfession>;
};

export type UserProfileGroupProfession = {
  __typename?: 'UserProfileGroupProfession';
  id: Scalars['Int'];
  professionName?: Maybe<Scalars['String']>;
  professionIcon?: Maybe<File>;
};

export type UserProfileCity = {
  __typename?: 'UserProfileCity';
  id: Scalars['Int'];
  googleName: Scalars['String'];
  country: UserProfileCountry;
};

export type UserProfileCountry = {
  __typename?: 'UserProfileCountry';
  id: Scalars['Int'];
  googleName: Scalars['String'];
};

export type UserProfileLatestTopic = {
  __typename?: 'UserProfileLatestTopic';
  id: Scalars['Int'];
  name: Scalars['String'];
  module: UserProfileLatestTopicModule;
};

export type UserProfileLatestTopicModule = {
  __typename?: 'UserProfileLatestTopicModule';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UserProfileLatestTask = {
  __typename?: 'UserProfileLatestTask';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UserProfile = PrivateUserProfile | PublicUserProfile | AdminProfile;

export type LearnStatistics = {
  __typename?: 'LearnStatistics';
  id: Scalars['Int'];
  attendedLessonsCount: Scalars['Int'];
  attendedQnACount: Scalars['Int'];
  attendedEnglishLessonsCount: Scalars['Int'];
  solvedMorningTasksCount: Scalars['Int'];
  groupTechCheckAvgScore?: Maybe<Scalars['Int']>;
  homeworkStatistics?: Maybe<HomeworkStatistics>;
  englishPerformance: UserEnglishPerformance;
  groupLiveCodingAvgScore?: Maybe<Scalars['Int']>;
  groupAutoTechCheckAvgScore?: Maybe<Scalars['Int']>;
  peerReviewsCount: Scalars['Int'];
};

export type ApplyingStatistics = {
  __typename?: 'ApplyingStatistics';
  id: Scalars['Int'];
  passedInterviewsCount: Scalars['Int'];
  completedTestTasksCount: Scalars['Int'];
  appliesCount: Scalars['Int'];
  followUpsCount: Scalars['Int'];
  receivedOffersCount: Scalars['Int'];
  activeStudentsTasksCount: Scalars['Int'];
};

export type HomeworkOverdue = {
  __typename?: 'HomeworkOverdue';
  id: Scalars['String'];
  pending: Scalars['Int'];
  notStarted: Scalars['Int'];
};

export type UserHomeworkPunctuality = {
  __typename?: 'UserHomeworkPunctuality';
  id: Scalars['String'];
  solvedOnTimeCount: Scalars['Int'];
  totalExpectedCount: Scalars['Int'];
};

export type UserSolvedTasksStatistics = {
  __typename?: 'UserSolvedTasksStatistics';
  id: Scalars['String'];
  totalTasksWithPassedDeadlineCount: Scalars['Int'];
  optionalSolvedTasksCount: Scalars['Int'];
  requiredSolvedTasksWithPassedDeadlineCount: Scalars['Int'];
};

export type UserEnglishPerformance = {
  __typename?: 'UserEnglishPerformance';
  id: Scalars['String'];
  attendedLessonsCount: Scalars['Int'];
  expectedToAttendLessonsCount: Scalars['Int'];
};

export type HomeworkStatistics = {
  __typename?: 'HomeworkStatistics';
  id: Scalars['Int'];
  solvedCount: Scalars['Int'];
  punctuality: UserHomeworkPunctuality;
  onMentorReview: Scalars['Int'];
  onPeerReview: Scalars['Int'];
  overdue?: Maybe<HomeworkOverdue>;
  solvedTasksStatistics?: Maybe<UserSolvedTasksStatistics>;
};

export type GrantedPermissionWithResources = {
  __typename?: 'GrantedPermissionWithResources';
  permission: Scalars['String'];
  resources: GrantedPermissionResources;
};

export enum UserAvatarBadgeType {
  Admin = 'Admin',
  Bro = 'Bro',
  Pro = 'Pro',
  Unlim = 'Unlim',
  ProfessionFrontend = 'ProfessionFrontend',
  ProfessionDesign = 'ProfessionDesign',
  ProfessionFullstack = 'ProfessionFullstack',
  ProfessionJava = 'ProfessionJava',
  ProfessionPython = 'ProfessionPython',
  ProfessionQa = 'ProfessionQa',
  ProfessionRecruitment = 'ProfessionRecruitment',
  RoleExternalMentor = 'RoleExternalMentor',
  RoleRecruiter = 'RoleRecruiter',
  RoleSales = 'RoleSales',
  RoleModerator = 'RoleModerator',
  Streak = 'Streak',
  Employed = 'Employed',
  JobChange = 'JobChange'
}

export enum UserType {
  Human = 'Human',
  Service = 'Service',
  Team = 'Team'
}

export enum UserRole {
  Admin = 'admin',
  Student = 'student',
  Moderator = 'moderator',
  Recruiter = 'recruiter',
  Sales = 'sales',
  ExternalMentor = 'external_mentor',
  EnglishTeacher = 'english_teacher',
  Mentor = 'mentor',
  ExternalCoach = 'external_coach',
  ExternalExpert = 'external_expert',
  ExternalOther = 'external_other',
  Instructor = 'instructor'
}

export enum EmailChangeResult {
  RequestSuccess = 'request_success',
  Success = 'success',
  InvalidToken = 'invalid_token',
  EmailTaken = 'email_taken'
}

export enum AccountDeletionReason {
  AccountNotNeeded = 'account_not_needed',
  HasDuplicateAccount = 'has_duplicate_account',
  DoesNotLikeStudyingMaterials = 'does_not_like_studying_materials',
  TooManyNotifications = 'too_many_notifications'
}

export enum AgreementSigningEntity {
  MaUkraine = 'MA_UKRAINE',
  MaPoland = 'MA_POLAND',
  MaCyprus = 'MA_CYPRUS',
  MaBrazil = 'MA_BRAZIL'
}

export type HiringsUserWithUnreadMessages = {
  __typename?: 'HiringsUserWithUnreadMessages';
  email: Scalars['String'];
  userId: Scalars['Int'];
  fullName: Scalars['String'];
  userType: HiringsUserType;
  chatConnectionId: Scalars['Int'];
  chatId: Scalars['Int'];
  lastMessageId: Scalars['Int'];
  lastReadMessageId: Scalars['Int'];
};

export type UserBaseAnalyticsInfo = {
  __typename?: 'UserBaseAnalyticsInfo';
  id: Scalars['Int'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  platformAnalyticsId: Scalars['String'];
  amplitudeId: Scalars['String'];
};

export enum PreSignInStatus {
  ContinuePassword = 'continue_password',
  ContinueLoginCode = 'continue_login_code'
}

export type GroupAchievementOptions = {
  achievementName: AchievementNames;
  groupId: Scalars['Int'];
};

export type UserAchievement = {
  __typename?: 'UserAchievement';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  achievementId: Scalars['Int'];
  unlocked: Scalars['Boolean'];
  amount: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  secondaryAmount?: Maybe<Scalars['Int']>;
  achievement: Achievement;
  unlockedAt?: Maybe<Scalars['Date']>;
  isNew: Scalars['Boolean'];
};

export type CreateAgreementValues = {
  userId: Scalars['Int'];
  agreementSignDeadline: Scalars['Date'];
};

export type UpdateAgreementValues = {
  isAgreementSigned?: Maybe<Scalars['Boolean']>;
  agreementSignedDate?: Maybe<Scalars['Date']>;
  agreementSignDeadline?: Maybe<Scalars['Date']>;
};

export type UserAgreement = {
  __typename?: 'UserAgreement';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  isAgreementSigned: Scalars['Boolean'];
  agreementSignedDate?: Maybe<Scalars['Date']>;
  agreementSignDeadline: Scalars['Date'];
};

export type UserAppOnboardingValues = {
  courseId?: Maybe<Scalars['Int']>;
  whyThisCourse?: Maybe<Scalars['String']>;
  whenToStudy?: Maybe<Scalars['String']>;
  howMuchToStudy?: Maybe<Scalars['String']>;
};

export type UserAppOnboarding = {
  __typename?: 'UserAppOnboarding';
  id: Scalars['Int'];
  course?: Maybe<Course>;
  whyThisCourse?: Maybe<Scalars['String']>;
  whenToStudy?: Maybe<Scalars['String']>;
  howMuchToStudy?: Maybe<Scalars['String']>;
};

export type UserCard = {
  __typename?: 'UserCard';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  token?: Maybe<Scalars['String']>;
  cardProvider?: Maybe<Scalars['String']>;
  cardTailNumbers?: Maybe<Scalars['String']>;
  cardExpMonth?: Maybe<Scalars['String']>;
  cardExpYear?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  paymentProvider?: Maybe<PaymentProvider>;
};

export type UserDailyTasksLink = {
  __typename?: 'UserDailyTasksLink';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  testTaskLinkId: Scalars['Int'];
  courseModuleId: Scalars['Int'];
  createdAt?: Maybe<Scalars['Date']>;
  testTaskLink: TestTaskLink;
};

export enum DocumentStatus {
  Uploaded = 'UPLOADED',
  Draft = 'DRAFT',
  Sent = 'SENT',
  Viewed = 'VIEWED',
  Rejected = 'REJECTED',
  WaitingApproval = 'WAITING_APPROVAL',
  Approved = 'APPROVED',
  WaitingPay = 'WAITING_PAY',
  Completed = 'COMPLETED',
  Voided = 'VOIDED',
  Declined = 'DECLINED'
}

export type CreateUserDocumentValues = {
  userId: Scalars['Int'];
  documentId: Scalars['String'];
  documentStatus: DocumentStatus;
  documentType: DocumentTypes;
  sessionId?: Maybe<Scalars['String']>;
  sessionExpiresAt?: Maybe<Scalars['Date']>;
};

export type UpdateUserDocumentValues = {
  documentStatus?: Maybe<DocumentStatus>;
  sessionId?: Maybe<Scalars['String']>;
  sessionExpiresAt?: Maybe<Scalars['Date']>;
  legalSessionId?: Maybe<Scalars['String']>;
  legalSessionExpiresAt?: Maybe<Scalars['Date']>;
};

export enum DocumentTypes {
  FulltimeAgreement = 'FULLTIME_AGREEMENT',
  FulltimeAgreementPl = 'FULLTIME_AGREEMENT_PL',
  FulltimeAgreementBr = 'FULLTIME_AGREEMENT_BR',
  FulltimeAct = 'FULLTIME_ACT'
}

export type UserDocument = {
  __typename?: 'UserDocument';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  documentId: Scalars['String'];
  documentStatus: DocumentStatus;
  documentType: DocumentTypes;
  sessionId?: Maybe<Scalars['String']>;
  sessionExpiresAt?: Maybe<Scalars['Date']>;
  legalSessionId?: Maybe<Scalars['String']>;
  legalSessionExpiresAt?: Maybe<Scalars['Date']>;
  documentLink?: Maybe<Scalars['String']>;
  legalDocumentLink?: Maybe<Scalars['String']>;
};

export type UserEconomicCode = {
  __typename?: 'UserEconomicCode';
  id: Scalars['Int'];
  economicActivityCode: Scalars['String'];
};

export type CodeInfo = {
  userId: Scalars['Int'];
  economicActivityCode?: Maybe<Scalars['String']>;
};

export type BulkUpdateOptions = {
  codes: Array<Maybe<Scalars['String']>>;
  userId: Scalars['Int'];
};

export type UpdateUserEventValues = {
  bpmEventParticipantId?: Maybe<Scalars['String']>;
  status?: Maybe<UserEventStatuses>;
};

export type ProcessWebinarRegistrationResult = {
  __typename?: 'ProcessWebinarRegistrationResult';
  user?: Maybe<User>;
  userBaseAnalyticsInfo: UserBaseAnalyticsInfo;
  isNewWebinarLead: Scalars['Boolean'];
};

export type AttendedEnglishLessonsCountResult = {
  __typename?: 'AttendedEnglishLessonsCountResult';
  totalAttended: Scalars['Int'];
  attendedThisWeek: Scalars['Int'];
};

export type UpdateViolatedResult = {
  __typename?: 'UpdateViolatedResult';
  lastEventsCount?: Maybe<Scalars['Int']>;
  violatedEventsTotal?: Maybe<Scalars['Int']>;
  violatedEventsBanned?: Maybe<Scalars['Int']>;
};

export type SendEventsRatingNotificationsResult = {
  __typename?: 'SendEventsRatingNotificationsResult';
  lastEventsCount: Scalars['Int'];
  participantsCount: Scalars['Int'];
};

export enum UserEventStatuses {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Violated = 'VIOLATED'
}

export type UserEvent = {
  __typename?: 'UserEvent';
  id: Scalars['Int'];
  eventId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  event?: Maybe<Event>;
  user?: Maybe<User>;
  status: UserEventStatuses;
  updatedAt?: Maybe<Scalars['Date']>;
  bpmEventParticipantId?: Maybe<Scalars['String']>;
  attended?: Maybe<Scalars['Boolean']>;
  testTaskLink?: Maybe<TestTaskLink>;
  createdAt?: Maybe<Scalars['Date']>;
  attendedAt?: Maybe<Scalars['Date']>;
  isNewUserEvent?: Maybe<Scalars['Boolean']>;
  utmTags?: Maybe<UtmTags>;
};

export type UserEventsByFiltersInput = {
  userIds?: Maybe<Array<Scalars['Int']>>;
  eventsIds?: Maybe<Array<Scalars['Int']>>;
  status?: Maybe<UserEventStatuses>;
  eventTypes?: Maybe<Array<Maybe<EventTypes>>>;
  eventStartAtMoreThan?: Maybe<Scalars['Date']>;
  eventStartAtLessThan?: Maybe<Scalars['Date']>;
  isAttended?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserExperimentValues = {
  bpmUserExperimentId?: Maybe<Scalars['String']>;
};

export type UserExperiment = {
  __typename?: 'UserExperiment';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  experimentId: Scalars['Int'];
  experimentVariant: Scalars['String'];
  bpmUserExperimentId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  experiment?: Maybe<Experiment>;
  createdAt?: Maybe<Scalars['Date']>;
};

export enum LeaveJobReasons {
  OfferFromAnotherCompany = 'OFFER_FROM_ANOTHER_COMPANY',
  BadCompany = 'BAD_COMPANY',
  ProbationFailed = 'PROBATION_FAILED',
  Fired = 'FIRED',
  CareerPause = 'CAREER_PAUSE',
  Other = 'OTHER'
}

export enum UserJobTypes {
  Office = 'OFFICE',
  Remote = 'REMOTE'
}

export enum JobFoundPlaces {
  MatePlatform = 'MATE_PLATFORM',
  FoundByMyself = 'FOUND_BY_MYSELF',
  Other = 'OTHER'
}

export type UpdateUserJobValues = {
  bpmJobExperienceId?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Float']>;
  salaryCurrencyId?: Maybe<Scalars['Int']>;
  cityId?: Maybe<Scalars['Int']>;
  firstDay?: Maybe<Scalars['Date']>;
  lastDay?: Maybe<Scalars['Date']>;
  probationPeriodEnd?: Maybe<Scalars['Date']>;
  companyId?: Maybe<Scalars['Int']>;
  currentPosition?: Maybe<Scalars['Boolean']>;
  googleCityId?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  jobType?: Maybe<UserJobTypes>;
  isRecommended?: Maybe<Scalars['Boolean']>;
  leavingJobReason?: Maybe<LeaveJobReasons>;
  feedback?: Maybe<Scalars['String']>;
  jobFoundPlace?: Maybe<JobFoundPlaces>;
};

export type CreateUserJobValues = {
  jobTitle: Scalars['String'];
  salary: Scalars['Float'];
  salaryCurrencyId: Scalars['Int'];
  cityId?: Maybe<Scalars['Int']>;
  firstDay: Scalars['Date'];
  lastDay?: Maybe<Scalars['Date']>;
  probationPeriodEnd?: Maybe<Scalars['Date']>;
  companyId: Scalars['Int'];
  bpmJobExperienceId?: Maybe<Scalars['String']>;
  googleCityId?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  jobType?: Maybe<UserJobTypes>;
  isRecommended?: Maybe<Scalars['Boolean']>;
  leavingJobReason?: Maybe<LeaveJobReasons>;
  feedback?: Maybe<Scalars['String']>;
  jobFoundPlace?: Maybe<JobFoundPlaces>;
};

export type PreviousUserJobValues = {
  id: Scalars['Int'];
  lastDay: Scalars['Date'];
  isRecommended?: Maybe<Scalars['Boolean']>;
  leavingJobReason?: Maybe<LeaveJobReasons>;
  feedback?: Maybe<Scalars['String']>;
};

export type UserJobSalaryInUsd = {
  __typename?: 'UserJobSalaryInUsd';
  net: Scalars['Float'];
  gross: Scalars['Float'];
};

export type UserJob = {
  __typename?: 'UserJob';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  jobTitle: Scalars['String'];
  cityId: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  salary: Scalars['Float'];
  salaryCurrencyId: Scalars['Int'];
  firstDay: Scalars['Date'];
  lastDay?: Maybe<Scalars['Date']>;
  salaryTerm?: Maybe<Scalars['Int']>;
  bpmJobExperienceId?: Maybe<Scalars['String']>;
  probationPeriodEnd?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  city?: Maybe<City>;
  company?: Maybe<Company>;
  salaryCurrency?: Maybe<Currency>;
  salaryInUsd?: Maybe<UserJobSalaryInUsd>;
  user?: Maybe<User>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  jobType?: Maybe<UserJobTypes>;
  isRecommended?: Maybe<Scalars['Boolean']>;
  leavingJobReason?: Maybe<LeaveJobReasons>;
  feedback?: Maybe<Scalars['String']>;
  jobFoundPlace?: Maybe<JobFoundPlaces>;
};

export type EmployeeMeta = {
  __typename?: 'EmployeeMeta';
  userId: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  firstDay: Scalars['Date'];
  employee: Employee;
};

export type Employee = {
  __typename?: 'Employee';
  id: Scalars['Int'];
  fullName: Scalars['String'];
  avatar?: Maybe<File>;
  badgeType?: Maybe<UserAvatarBadgeType>;
};

export type CreateOrUpdateUserLegalDocumentValues = {
  userId: Scalars['Int'];
  taxNumber: Scalars['String'];
  passportSeries?: Maybe<Scalars['String']>;
  passportNumber: Scalars['String'];
  passportIssued?: Maybe<Scalars['String']>;
  passportDateIssued: Scalars['Date'];
  passportDateExpiry?: Maybe<Scalars['Date']>;
  passportAddressCity?: Maybe<Scalars['String']>;
  passportAddressStreet?: Maybe<Scalars['String']>;
  bpmUserDocumentsId?: Maybe<Scalars['String']>;
  citizenship?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  registrationAddress?: Maybe<Scalars['String']>;
};

export type UpdateUserLegalDocumentValues = {
  id?: Maybe<Scalars['Int']>;
  taxNumber?: Maybe<Scalars['String']>;
  passportSeries?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssued?: Maybe<Scalars['String']>;
  passportDateIssued?: Maybe<Scalars['Date']>;
  passportDateExpiry?: Maybe<Scalars['Date']>;
  passportAddressCity?: Maybe<Scalars['String']>;
  passportAddressStreet?: Maybe<Scalars['String']>;
  bpmUserDocumentsId?: Maybe<Scalars['String']>;
  citizenship?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  registrationAddress?: Maybe<Scalars['String']>;
};

export type UserLegalDocuments = {
  __typename?: 'UserLegalDocuments';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  taxNumber: Scalars['String'];
  passportSeries?: Maybe<Scalars['String']>;
  passportNumber: Scalars['String'];
  passportIssued?: Maybe<Scalars['String']>;
  passportDateIssued: Scalars['Date'];
  passportDateExpiry?: Maybe<Scalars['Date']>;
  passportAddressCity?: Maybe<Scalars['String']>;
  passportAddressStreet?: Maybe<Scalars['String']>;
  bpmUserDocumentsId?: Maybe<Scalars['String']>;
  citizenship?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  registrationAddress?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  passportScan?: Maybe<Scalars['String']>;
  identificationScan?: Maybe<Scalars['String']>;
  addressRegScan?: Maybe<Scalars['String']>;
};

export type LegalDocument = {
  file: Scalars['Upload'];
  type: LegalDocumentType;
};

export enum LegalDocumentType {
  Passport = 'passport',
  HomeAddressRegistration = 'home_address_registration',
  TaxIdentificationNumber = 'tax_identification_number'
}

export type UserLoginCode = {
  __typename?: 'UserLoginCode';
  id: Scalars['Int'];
  loginCode?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  expiresOn?: Maybe<Scalars['Date']>;
};

export type LoginCodeLink = {
  __typename?: 'LoginCodeLink';
  loginCodeLink: Scalars['String'];
};

export type AbstractNotification = {
  id: Scalars['Int'];
  status: UserNotificationsStatuses;
  message: Scalars['String'];
  createdAt: Scalars['Date'];
  actionLink?: Maybe<Scalars['String']>;
  actionButtonCode?: Maybe<Scalars['String']>;
  sender?: Maybe<User>;
};


export type AbstractNotificationMessageArgs = {
  lang?: Maybe<Scalars['String']>;
};

export type BasicNotification = AbstractNotification & {
  __typename?: 'BasicNotification';
  id: Scalars['Int'];
  status: UserNotificationsStatuses;
  message: Scalars['String'];
  actionLink?: Maybe<Scalars['String']>;
  actionButtonCode?: Maybe<Scalars['String']>;
  /** @deprecated Use imageType instead */
  imageUrl?: Maybe<Scalars['String']>;
  imageType?: Maybe<UserNotificationsCustomImages>;
  createdAt: Scalars['Date'];
  sender?: Maybe<User>;
};


export type BasicNotificationMessageArgs = {
  lang?: Maybe<Scalars['String']>;
};

export type TaskReviewNotification = AbstractNotification & {
  __typename?: 'TaskReviewNotification';
  id: Scalars['Int'];
  status: UserNotificationsStatuses;
  message: Scalars['String'];
  taskReviewStatus: TaskReviewStatus;
  actionLink?: Maybe<Scalars['String']>;
  actionButtonCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  sender?: Maybe<User>;
};


export type TaskReviewNotificationMessageArgs = {
  lang?: Maybe<Scalars['String']>;
};

export type RatingNotification = AbstractNotification & {
  __typename?: 'RatingNotification';
  id: Scalars['Int'];
  status: UserNotificationsStatuses;
  message: Scalars['String'];
  actionLink?: Maybe<Scalars['String']>;
  actionButtonCode?: Maybe<Scalars['String']>;
  relatedId: Scalars['Int'];
  relatedType: RatingItemType;
  userRating: Scalars['Int'];
  createdAt: Scalars['Date'];
  sender?: Maybe<User>;
};


export type RatingNotificationMessageArgs = {
  lang?: Maybe<Scalars['String']>;
};

export type UserNotificationsForPagination = {
  __typename?: 'UserNotificationsForPagination';
  totalCount: Scalars['Int'];
  unreadCount: Scalars['Int'];
  edges: Array<AbstractNotification>;
};

export type UserNotificationMeta = {
  taskReviewId?: Maybe<Scalars['Int']>;
  taskName?: Maybe<Scalars['String']>;
  taskReviewerFullName?: Maybe<Scalars['String']>;
  eventTitle?: Maybe<Scalars['String']>;
  ratingRelatedId?: Maybe<Scalars['Int']>;
  ratingRelatedType?: Maybe<RatingItemType>;
  mentorFullName?: Maybe<Scalars['String']>;
  xpAmount?: Maybe<Scalars['Int']>;
  percentOfStudentsAhead?: Maybe<Scalars['Int']>;
  numberOfStreakDays?: Maybe<Scalars['Int']>;
  companyName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  senderId?: Maybe<Scalars['Int']>;
  imageType?: Maybe<UserNotificationsCustomImages>;
};

export type CreateUserNotificationFields = {
  userId: Scalars['Int'];
  messageCode: Scalars['String'];
  status?: Maybe<UserNotificationsStatuses>;
  actionLink?: Maybe<Scalars['String']>;
  actionButtonCode?: Maybe<Scalars['String']>;
  meta?: Maybe<UserNotificationMeta>;
};

export enum UserNotificationsStatuses {
  Unread = 'UNREAD',
  Read = 'READ',
  ReadWithAll = 'READ_WITH_ALL'
}

export enum UserNotificationsTypes {
  BasicNotification = 'BasicNotification',
  TaskReviewNotification = 'TaskReviewNotification',
  RatingNotification = 'RatingNotification'
}

export enum UserNotificationsCustomImages {
  GoalEmoji = 'GoalEmoji',
  FlagUk = 'FlagUK'
}

export type UserNotificationsDeviceInput = {
  name: Scalars['String'];
  os: Scalars['String'];
  pushToken: Scalars['String'];
};

export type UserNotificationsDevice = {
  __typename?: 'UserNotificationsDevice';
  id: Scalars['Int'];
  name: Scalars['String'];
  os: Scalars['String'];
  pushToken: Scalars['String'];
};

export enum Onboardings {
  CodeEditor = 'CODE_EDITOR',
  PeerReview = 'PEER_REVIEW',
  Invid = 'INVID',
  UpcomingLinearFlowBanner = 'UPCOMING_LINEAR_FLOW_BANNER',
  AiAssistant = 'AI_ASSISTANT',
  CommunityChat = 'COMMUNITY_CHAT',
  AutoTechChecks = 'AUTO_TECH_CHECKS',
  PersonalChat = 'PERSONAL_CHAT',
  UserSolution = 'USER_SOLUTION',
  XpForReplyInQnaChat = 'XP_FOR_REPLY_IN_QNA_CHAT',
  LinearLearningFlow = 'LINEAR_LEARNING_FLOW',
  AiReviewGithubFlow = 'AI_REVIEW_GITHUB_FLOW',
  TopicPageTheoryOnlyMode = 'TOPIC_PAGE_THEORY_ONLY_MODE',
  AdvancedGithubFlow = 'ADVANCED_GITHUB_FLOW'
}

export type UserOnboarding = {
  __typename?: 'UserOnboarding';
  id: Scalars['Int'];
  page: Onboardings;
};

export type CourseOnboarding = {
  __typename?: 'CourseOnboarding';
  name: Onboardings;
  order: Scalars['Int'];
};

export type NotifyUserReceivedOnboardingPayload = {
  __typename?: 'NotifyUserReceivedOnboardingPayload';
  userId: Scalars['Int'];
  page: Onboardings;
  order: Scalars['Int'];
};

export type ChatOnboardingData = {
  __typename?: 'ChatOnboardingData';
  page: Onboardings;
  mentorsSupportChatId?: Maybe<Scalars['Int']>;
  personalChatId?: Maybe<Scalars['Int']>;
};

export type UserPlatformSettingAbstract = {
  id: Scalars['Int'];
  userId: Scalars['Int'];
  name: UserPlatformSettingNames;
};

export type ChatNotificationsPlatformSetting = UserPlatformSettingAbstract & {
  __typename?: 'ChatNotificationsPlatformSetting';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  name: UserPlatformSettingNames;
  config?: Maybe<ChatNotificationsSettingConfig>;
};

export type UpdatePlatformSettingInput = {
  name: UserPlatformSettingNames;
  chatNotificationsConfig?: Maybe<ChatNotificationSettingConfigInput>;
};

export type ChatNotificationSettingConfigInput = {
  desktop: ChatNotificationSettingValue;
  mobile?: Maybe<ChatNotificationSettingValue>;
  sound?: Maybe<ChatNotificationSettingSoundValue>;
};

export type ChatNotificationsSettingConfig = {
  __typename?: 'ChatNotificationsSettingConfig';
  desktop: ChatNotificationSettingValue;
  mobile?: Maybe<ChatNotificationSettingValue>;
  sound?: Maybe<ChatNotificationSettingSoundValue>;
};

export enum ChatNotificationSettingValue {
  AllNewMessages = 'ALL_NEW_MESSAGES',
  MentionsAndReplies = 'MENTIONS_AND_REPLIES',
  Nothing = 'NOTHING'
}

export enum ChatNotificationSettingSoundValue {
  Allowed = 'ALLOWED',
  NotAllowed = 'NOT_ALLOWED'
}

export type PlatformSettingConfig = ChatNotificationsSettingConfig;

export type UpdateUserPlatformSettingInput = {
  name: UserPlatformSettingNames;
  value: Scalars['String'];
};

export type UserPlatformSetting = {
  __typename?: 'UserPlatformSetting';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  name: UserPlatformSettingNames;
  value?: Maybe<Scalars['String']>;
};

export enum UserPlatformSettingNames {
  AnonymizePersonalData = 'ANONYMIZE_PERSONAL_DATA',
  IsUserProfilePrivate = 'IS_USER_PROFILE_PRIVATE',
  UserTimeZone = 'USER_TIME_ZONE',
  ChatNotificationsPlatformLevel = 'CHAT_NOTIFICATIONS_PLATFORM_LEVEL'
}

export type UserSchedule = {
  __typename?: 'UserSchedule';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  daysOfWeek: UserScheduleDaysOfWeek;
  deletedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  hoursLeftToStudyPerWeek?: Maybe<Scalars['Int']>;
  studyHoursPerWeek?: Maybe<Scalars['Int']>;
};


export type UserScheduleHoursLeftToStudyPerWeekArgs = {
  userId: Scalars['Int'];
};


export type UserScheduleStudyHoursPerWeekArgs = {
  userId: Scalars['Int'];
};

export type UserScheduleDaysOfWeek = {
  __typename?: 'UserScheduleDaysOfWeek';
  Mon?: Maybe<Array<TimeRange>>;
  Tue?: Maybe<Array<TimeRange>>;
  Wed?: Maybe<Array<TimeRange>>;
  Thu?: Maybe<Array<TimeRange>>;
  Fri?: Maybe<Array<TimeRange>>;
  Sat?: Maybe<Array<TimeRange>>;
  Sun?: Maybe<Array<TimeRange>>;
};

export type TimeRange = {
  __typename?: 'TimeRange';
  startAt: Scalars['String'];
  endAt: Scalars['String'];
};

export type UserScheduleDaysOfWeekInput = {
  Mon?: Maybe<Array<TimeRangeInput>>;
  Tue?: Maybe<Array<TimeRangeInput>>;
  Wed?: Maybe<Array<TimeRangeInput>>;
  Thu?: Maybe<Array<TimeRangeInput>>;
  Fri?: Maybe<Array<TimeRangeInput>>;
  Sat?: Maybe<Array<TimeRangeInput>>;
  Sun?: Maybe<Array<TimeRangeInput>>;
};

export type TimeRangeInput = {
  startAt: Scalars['String'];
  endAt: Scalars['String'];
};

export enum UserScheduleDay {
  Mon = 'Mon',
  Tue = 'Tue',
  Wed = 'Wed',
  Thu = 'Thu',
  Fri = 'Fri',
  Sat = 'Sat',
  Sun = 'Sun'
}

export type StudyScheduleDayConfig = {
  __typename?: 'StudyScheduleDayConfig';
  day: UserScheduleDay;
  timeRanges: Array<TimeRange>;
};

export type UserScheduleConfigData = {
  __typename?: 'UserScheduleConfigData';
  scheduleConfigs: Array<StudyScheduleDayConfig>;
  userData: StudyScheduleUserData;
  notificationData: StudyScheduleNotificationData;
};

export type StudyScheduleUserData = {
  __typename?: 'StudyScheduleUserData';
  userId: Scalars['Int'];
  language: LanguageName;
  timeZone: Scalars['String'];
  /** @deprecated not used anymore */
  oneSignalAppId?: Maybe<Scalars['String']>;
  amplitudeId: Scalars['String'];
};

export type StudyScheduleNotificationData = {
  __typename?: 'StudyScheduleNotificationData';
  messageData: SchedulePushNotificationData;
  notificationType: Scalars['String'];
};

export type SchedulePushNotificationData = {
  __typename?: 'SchedulePushNotificationData';
  contents: Scalars['JSON'];
  headings: Scalars['JSON'];
};

export type UserStreak = {
  __typename?: 'UserStreak';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  xp: Scalars['Int'];
  lastActiveDay: Scalars['Date'];
  usedFrozenDays: Array<Scalars['Date']>;
  frozenDaysCount: Scalars['Int'];
  activeDaysCount: Scalars['Int'];
  startedAt: Scalars['Date'];
  finishedAt?: Maybe<Scalars['Date']>;
};

export type UserStreaksForWeeklyNotification = {
  __typename?: 'UserStreaksForWeeklyNotification';
  usersForActiveEmail: Array<UserStreak>;
  usersForInactiveEmail: Array<UserStreak>;
};

export type UpdateTechCheckAnswerValues = {
  status?: Maybe<UserTechCheckAnswerStatus>;
  mentorMark?: Maybe<Scalars['Float']>;
  textAnswerLink?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type UserTechCheckAnswer = {
  __typename?: 'UserTechCheckAnswer';
  id: Scalars['Int'];
  techCheckLinkId: Scalars['Int'];
  techCheckLink: TechCheckLink;
  techCheckQuestionId: Scalars['Int'];
  techCheckQuestion: TechCheckQuestion;
  userId: Scalars['Int'];
  user: User;
  audioAnswerLink?: Maybe<Scalars['String']>;
  textAnswerLink?: Maybe<Scalars['String']>;
  textAnswer?: Maybe<Scalars['String']>;
  mentorMark?: Maybe<Scalars['Float']>;
  mentorComment?: Maybe<Scalars['String']>;
  botMark?: Maybe<Scalars['Float']>;
  botComment?: Maybe<Scalars['String']>;
  status: UserTechCheckAnswerStatus;
  attemptNumber?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  keywords: Array<TechCheckQuestionsKeyword>;
  checkPhrases: Array<TechCheckAnswerCheckphrase>;
};

export enum UserTechCheckAnswerStatus {
  New = 'NEW',
  Answering = 'ANSWERING',
  Transcribing = 'TRANSCRIBING',
  Completed = 'COMPLETED',
  Failed = 'FAILED'
}

export type UserAutoTechCheckAnswerValues = {
  userTechCheckAnswerId: Scalars['Int'];
  rawAnswer: Scalars['String'];
  languageName?: Maybe<Scalars['String']>;
};

export type UserVacanciesFilter = {
  __typename?: 'UserVacanciesFilter';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  vacanciesTracksIds: Array<Scalars['Int']>;
  googleCitiesIds: Array<Scalars['String']>;
  officeCountriesIds: Array<Scalars['Int']>;
  remoteCountriesIds: Array<Scalars['Int']>;
  remote: VacancyRadioFilter;
  officeCountries: Array<Country>;
  remoteCountries: Array<Country>;
  officeCities: Array<City>;
  vacanciesTracks: Array<VacanciesTrack>;
  recommendedFilter: RecommendedUserVacanciesFilter;
};

export type RecommendedUserVacanciesFilter = {
  __typename?: 'RecommendedUserVacanciesFilter';
  id: Scalars['String'];
  remote: VacancyRadioFilter;
  officeCountries: Array<Country>;
  remoteCountries: Array<Country>;
  officeCities: Array<City>;
  vacanciesTracks: Array<VacanciesTrack>;
};

export type UpdateUserVacanciesFilterOptions = {
  id: Scalars['Int'];
  vacanciesTracksIds?: Maybe<Array<Scalars['Int']>>;
  googleCitiesIds?: Maybe<Array<Scalars['String']>>;
  officeCountriesGoogleIds?: Maybe<Array<Scalars['String']>>;
  remoteCountriesGoogleIds?: Maybe<Array<Scalars['String']>>;
  remote?: Maybe<VacancyRadioFilter>;
};

export type UserVacancy = {
  __typename?: 'UserVacancy';
  id: Scalars['Int'];
  vacancyId: Scalars['Int'];
  userId: Scalars['Int'];
  user: User;
  hidden?: Maybe<Scalars['Boolean']>;
  vacancy: Vacancy;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  history?: Maybe<UserVacancyHistory>;
  histories: Array<UserVacancyHistory>;
  favorite?: Maybe<Scalars['Boolean']>;
  commentsCount?: Maybe<Scalars['Int']>;
  contacts: Array<UserVacancyContact>;
  position?: Maybe<Scalars['Float']>;
  status?: Maybe<UserVacancyStatus>;
  interviewType?: Maybe<UserVacancyInterviewType>;
  interviewStartAt?: Maybe<Scalars['Date']>;
  interviewLocation?: Maybe<Scalars['String']>;
  testProgress?: Maybe<Scalars['Int']>;
  testDeadLine?: Maybe<Scalars['Date']>;
  testTask?: Maybe<Scalars['String']>;
  testSolution?: Maybe<Scalars['String']>;
  interviews: Array<InterviewEvent>;
  testTaskHistories: Array<UserVacancyHistory>;
  cvSentAt?: Maybe<Scalars['Date']>;
  followedUpAt?: Maybe<Scalars['Date']>;
  vacancyAddedByUserAt?: Maybe<Scalars['Date']>;
  lastActivityAt: Scalars['Date'];
};

export type EmploymentBackLogInfo = {
  __typename?: 'EmploymentBackLogInfo';
  email: Scalars['String'];
  interviewsBackLog: Scalars['Int'];
  testTaskBackLog: Scalars['Int'];
};

export enum UserVacancyStatus {
  SentCv = 'SENT_CV',
  Contacted = 'CONTACTED',
  TestAssignment = 'TEST_ASSIGNMENT',
  Interview = 'INTERVIEW',
  ReceivedOffer = 'RECEIVED_OFFER',
  Rejected = 'REJECTED',
  Refusal = 'REFUSAL'
}

export enum UserVacancyInterviewType {
  Tech = 'TECH',
  Screen = 'SCREEN',
  SoftSkills = 'SOFT_SKILLS',
  HrOrScreen = 'HR_OR_SCREEN',
  Final = 'FINAL'
}

export type CreateUserVacancyArgs = {
  vacancyId: Scalars['Int'];
  hidden?: Maybe<Scalars['Boolean']>;
  status?: Maybe<UserVacancyStatus>;
  skipReason?: Maybe<SkipVacancyReason>;
};

export type UpdateUserVacancyArgs = {
  id: Scalars['Int'];
  position?: Maybe<Scalars['Float']>;
  hidden?: Maybe<Scalars['Boolean']>;
  status?: Maybe<UserVacancyStatus>;
  interviewType?: Maybe<UserVacancyInterviewType>;
  interviewStartAt?: Maybe<Scalars['Date']>;
  interviewLocation?: Maybe<Scalars['String']>;
  testProgress?: Maybe<Scalars['Int']>;
  testDeadLine?: Maybe<Scalars['Date']>;
  testTask?: Maybe<Scalars['String']>;
  testSolution?: Maybe<Scalars['String']>;
};

export type UserVacanciesFilters = {
  searchQuery?: Maybe<Scalars['String']>;
  skipIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  status?: Maybe<UserVacancyStatus>;
};

export type UpdateUserVacancyResult = {
  __typename?: 'UpdateUserVacancyResult';
  userVacancy: UserVacancy;
  userVacancyHistory?: Maybe<UserVacancyHistory>;
};

export type UserVacancyPaginationArgs = {
  status: UserVacancyStatus;
  cursor: Scalars['Float'];
};

export type UserVacanciesDateFilter = {
  from: Scalars['Date'];
  to?: Maybe<Scalars['Date']>;
};

export type ActualInterviewsResult = {
  __typename?: 'ActualInterviewsResult';
  id: Scalars['Int'];
  user: ActualInterviewsResultUser;
  userId: Scalars['Int'];
  interviewStartAt: Scalars['Date'];
  interviewType: InterviewType;
  vacancy: ActualInterviewsResultVacancy;
};

export type ActualInterviewsResultUser = {
  __typename?: 'ActualInterviewsResultUser';
  id: Scalars['Int'];
  email: Scalars['String'];
};

export type ActualInterviewsResultVacancy = {
  __typename?: 'ActualInterviewsResultVacancy';
  company: Scalars['String'];
};

export type UserVacancyContact = {
  __typename?: 'UserVacancyContact';
  id: Scalars['Int'];
  userVacancyId: Scalars['Int'];
  contact: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CreateUserVacancyContactArgs = {
  userVacancyId: Scalars['Int'];
  contact: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateUserVacancyContactArgs = {
  contact?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserVacancyHistory = {
  __typename?: 'UserVacancyHistory';
  id: Scalars['Int'];
  userVacancyId: Scalars['Int'];
  userId: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  comment?: Maybe<Scalars['String']>;
  status: UserVacancyHistoryStatus;
  testProgress?: Maybe<Scalars['Int']>;
};

export type CreateUserVacancyHistoryArgs = {
  userVacancyId: Scalars['Int'];
  status?: Maybe<UserVacancyHistoryStatus>;
  comment?: Maybe<Scalars['String']>;
};

export type UpdateUserVacancyHistoryArgs = {
  id: Scalars['Int'];
  comment: Scalars['String'];
};

export enum UserVacancyHistoryStatus {
  VacancyAdded = 'VACANCY_ADDED',
  SentCv = 'SENT_CV',
  Contacted = 'CONTACTED',
  TestAssignment = 'TEST_ASSIGNMENT',
  Interview = 'INTERVIEW',
  ReceivedOffer = 'RECEIVED_OFFER',
  Rejected = 'REJECTED',
  Refusal = 'REFUSAL'
}

export type EnsureUsersStudentStatusHistoryInput = {
  id?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  studentStatus: StudentStatus;
  start: Scalars['Date'];
  due?: Maybe<Scalars['Date']>;
  bpmStatusId: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UsersStudentStatusHistory = {
  __typename?: 'UsersStudentStatusHistory';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  studentStatus: StudentStatus;
  start: Scalars['Date'];
  due?: Maybe<Scalars['Date']>;
  bpmStatusId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type UtmTags = {
  __typename?: 'UtmTags';
  id: Scalars['Int'];
  action: UtmTagsAction;
  fvType?: Maybe<Scalars['String']>;
  fvSource?: Maybe<Scalars['String']>;
  fvMedium?: Maybe<Scalars['String']>;
  fvCampaign?: Maybe<Scalars['String']>;
  fvContent?: Maybe<Scalars['String']>;
  fvTerm?: Maybe<Scalars['String']>;
  fvPage?: Maybe<Scalars['String']>;
  lvType?: Maybe<Scalars['String']>;
  lvSource?: Maybe<Scalars['String']>;
  lvMedium?: Maybe<Scalars['String']>;
  lvCampaign?: Maybe<Scalars['String']>;
  lvContent?: Maybe<Scalars['String']>;
  lvTerm?: Maybe<Scalars['String']>;
  lvAccountId?: Maybe<Scalars['String']>;
  lvCampaignId?: Maybe<Scalars['String']>;
  lvAdGroupId?: Maybe<Scalars['String']>;
  lvAdId?: Maybe<Scalars['String']>;
  lvTermId?: Maybe<Scalars['String']>;
  utmDevicetype?: Maybe<Scalars['String']>;
  utmPlacement?: Maybe<Scalars['String']>;
};

export enum UtmTagsAction {
  Registration = 'REGISTRATION',
  FtApplication = 'FT_APPLICATION',
  Consultation = 'CONSULTATION',
  Webinar = 'WEBINAR',
  Marathon = 'MARATHON'
}

export type UtmTagsInput = {
  fvType?: Maybe<Scalars['String']>;
  fvSource?: Maybe<Scalars['String']>;
  fvMedium?: Maybe<Scalars['String']>;
  fvCampaign?: Maybe<Scalars['String']>;
  fvContent?: Maybe<Scalars['String']>;
  fvTerm?: Maybe<Scalars['String']>;
  fvPage?: Maybe<Scalars['String']>;
  lvType?: Maybe<Scalars['String']>;
  lvSource?: Maybe<Scalars['String']>;
  lvMedium?: Maybe<Scalars['String']>;
  lvCampaign?: Maybe<Scalars['String']>;
  lvContent?: Maybe<Scalars['String']>;
  lvTerm?: Maybe<Scalars['String']>;
  lvAccountId?: Maybe<Scalars['String']>;
  lvCampaignId?: Maybe<Scalars['String']>;
  lvAdGroupId?: Maybe<Scalars['String']>;
  lvAdId?: Maybe<Scalars['String']>;
  lvTermId?: Maybe<Scalars['String']>;
  utmDevicetype?: Maybe<Scalars['String']>;
  utmPlacement?: Maybe<Scalars['String']>;
};

export type BackfillConsultationUtmTagsInput = {
  userId: Scalars['Int'];
  courseUserId: Scalars['Int'];
  fvType?: Maybe<Scalars['String']>;
  fvSource?: Maybe<Scalars['String']>;
  fvMedium?: Maybe<Scalars['String']>;
  fvCampaign?: Maybe<Scalars['String']>;
  fvContent?: Maybe<Scalars['String']>;
  fvTerm?: Maybe<Scalars['String']>;
  lvType?: Maybe<Scalars['String']>;
  lvSource?: Maybe<Scalars['String']>;
  lvMedium?: Maybe<Scalars['String']>;
  lvCampaign?: Maybe<Scalars['String']>;
  lvContent?: Maybe<Scalars['String']>;
  lvTerm?: Maybe<Scalars['String']>;
  lvAccountId?: Maybe<Scalars['String']>;
  lvCampaignId?: Maybe<Scalars['String']>;
  lvAdGroupId?: Maybe<Scalars['String']>;
  lvAdId?: Maybe<Scalars['String']>;
  lvTermId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type BackfillFtApplicationUtmTagsInput = {
  userId: Scalars['Int'];
  courseUserId: Scalars['Int'];
  fvType?: Maybe<Scalars['String']>;
  fvSource?: Maybe<Scalars['String']>;
  fvMedium?: Maybe<Scalars['String']>;
  fvCampaign?: Maybe<Scalars['String']>;
  fvContent?: Maybe<Scalars['String']>;
  fvTerm?: Maybe<Scalars['String']>;
  lvType?: Maybe<Scalars['String']>;
  lvSource?: Maybe<Scalars['String']>;
  lvMedium?: Maybe<Scalars['String']>;
  lvCampaign?: Maybe<Scalars['String']>;
  lvContent?: Maybe<Scalars['String']>;
  lvTerm?: Maybe<Scalars['String']>;
  lvAccountId?: Maybe<Scalars['String']>;
  lvCampaignId?: Maybe<Scalars['String']>;
  lvAdGroupId?: Maybe<Scalars['String']>;
  lvAdId?: Maybe<Scalars['String']>;
  lvTermId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type BackfillWebinarUtmTagsInput = {
  userId: Scalars['Int'];
  eventParticipantId: Scalars['Int'];
  fvType?: Maybe<Scalars['String']>;
  fvSource?: Maybe<Scalars['String']>;
  fvMedium?: Maybe<Scalars['String']>;
  fvCampaign?: Maybe<Scalars['String']>;
  fvContent?: Maybe<Scalars['String']>;
  fvTerm?: Maybe<Scalars['String']>;
  lvType?: Maybe<Scalars['String']>;
  lvSource?: Maybe<Scalars['String']>;
  lvMedium?: Maybe<Scalars['String']>;
  lvCampaign?: Maybe<Scalars['String']>;
  lvContent?: Maybe<Scalars['String']>;
  lvTerm?: Maybe<Scalars['String']>;
  lvAccountId?: Maybe<Scalars['String']>;
  lvCampaignId?: Maybe<Scalars['String']>;
  lvAdGroupId?: Maybe<Scalars['String']>;
  lvAdId?: Maybe<Scalars['String']>;
  lvTermId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type VacanciesTrack = {
  __typename?: 'VacanciesTrack';
  id: Scalars['Int'];
  slug: Scalars['String'];
};

export type Vacancy = {
  __typename?: 'Vacancy';
  id: Scalars['Int'];
  /** @deprecated Use vacanciesTrackId instead */
  courseId?: Maybe<Scalars['Int']>;
  /** @deprecated Use vacanciesTrack instead */
  course?: Maybe<Course>;
  title?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  vacancyCompany?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  publishDate?: Maybe<Scalars['Date']>;
  link?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  city?: Maybe<City>;
  cities: Array<City>;
  isRemote?: Maybe<Scalars['Boolean']>;
  hasTestAssignment?: Maybe<Scalars['Boolean']>;
  hiredStudents?: Maybe<Scalars['Int']>;
  testAssignments?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<VacancyStatus>;
  description?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
  country?: Maybe<Country>;
  isInternational?: Maybe<Scalars['Boolean']>;
  creatorId?: Maybe<Scalars['Int']>;
  creatorType?: Maybe<VacancyCreatorType>;
  isBanned: Scalars['Boolean'];
  vacanciesTrackId?: Maybe<Scalars['Int']>;
  vacanciesTrack?: Maybe<VacanciesTrack>;
};

export type CreateVacancyOptions = {
  companyId: Scalars['Int'];
  title: Scalars['String'];
  link: Scalars['String'];
  courseId?: Maybe<Scalars['Int']>;
  vacanciesTrackId?: Maybe<Scalars['Int']>;
  googleCitiesId: Array<Maybe<Scalars['String']>>;
  isRemote: Scalars['Boolean'];
  countryName: Scalars['String'];
  isInternational?: Maybe<Scalars['Boolean']>;
};

export type VacanciesResult = {
  __typename?: 'VacanciesResult';
  rows?: Maybe<Array<Maybe<Vacancy>>>;
  count?: Maybe<Scalars['Int']>;
  actualVacanciesCount?: Maybe<Scalars['Int']>;
};

export enum VacancyStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export type UpdateManyVacanciesOptions = {
  status?: Maybe<VacancyStatus>;
};

export type VacancyFilters = {
  courseIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  searchQuery?: Maybe<Scalars['String']>;
  googleCityIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  remoteCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  officeCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  remote?: Maybe<VacancyRadioFilter>;
  skipIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export enum VacancyRadioFilter {
  Include = 'Include',
  Exclude = 'Exclude',
  Only = 'Only'
}

export enum VacancyCreatorType {
  Admin = 'ADMIN',
  Student = 'STUDENT',
  Parser = 'PARSER'
}

export enum SkipVacancyReason {
  NotSuitableFields = 'not_suitable_fields',
  Unavailable = 'unavailable',
  NotSuitableWorkingCondition = 'not_suitable_working_condition',
  DuplicatedVacancies = 'duplicated_vacancies'
}

export type VacanciesListFilters = {
  status?: Maybe<VacancyStatus>;
  vacanciesTrackId?: Maybe<Scalars['Int']>;
  countryIds?: Maybe<Array<Scalars['Int']>>;
  isInternational?: Maybe<Scalars['Boolean']>;
};

export type VacanciesListResult = {
  __typename?: 'VacanciesListResult';
  count: Scalars['Int'];
  rows: Array<Vacancy>;
};

export type HideIrrelevantVacancyMutationOptions = {
  id: Scalars['Int'];
  reason: Scalars['String'];
};

export type HideIrrelevantVacancyMutationResult = {
  __typename?: 'HideIrrelevantVacancyMutationResult';
  vacancy: Vacancy;
  ratings: Array<RatingFull>;
};

export type UpdateVacancyOptions = {
  vacancyId: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type VacancyAutoApproveResult = {
  __typename?: 'VacancyAutoApproveResult';
  id: Scalars['Int'];
  modelName: Scalars['String'];
  score: Scalars['Float'];
  status: VacancyAutoApproveStatus;
  vacancyId: Scalars['Int'];
};

export enum VacancyAutoApproveStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  NotSure = 'NOT_SURE'
}

export type CreateVacancyAutoApproveResultsArgs = {
  modelName: Scalars['String'];
  score: Scalars['Float'];
  status: VacancyAutoApproveStatus;
  vacancyId: Scalars['Int'];
};

export type VacancyCitiesMap = {
  __typename?: 'VacancyCitiesMap';
  id: Scalars['Int'];
  cityId?: Maybe<Scalars['Int']>;
  cityName?: Maybe<Scalars['String']>;
  processedBy?: Maybe<ProcessedBy>;
};

export enum ProcessedBy {
  Admin = 'ADMIN',
  Google = 'GOOGLE',
  GoogleAndChatGpt = 'GOOGLE_AND_CHAT_GPT'
}

export type VacancyCity = {
  __typename?: 'VacancyCity';
  id: Scalars['Int'];
  vacancyId: Scalars['Int'];
  cityId: Scalars['Int'];
};

export type VacancyCountriesMap = {
  __typename?: 'VacancyCountriesMap';
  id: Scalars['Int'];
  countryId?: Maybe<Scalars['Int']>;
  countryName?: Maybe<Scalars['String']>;
};

export type WebinarLink = {
  __typename?: 'WebinarLink';
  id: Scalars['Int'];
  link: Scalars['String'];
  webinarId: Scalars['Int'];
  webinar?: Maybe<WebinarEvent>;
};

export enum ButtonInteraction {
  Seen = 'SEEN',
  Unseen = 'UNSEEN',
  Clicked = 'CLICKED',
  Unrecognized = 'UNRECOGNIZED'
}

export type WebinarStarsVisitor = {
  __typename?: 'WebinarStarsVisitor';
  id: Scalars['Int'];
  email: Scalars['String'];
  phone: Scalars['String'];
  joinedAt: Scalars['Date'];
  leftAt: Scalars['Date'];
  buttonInteraction?: Maybe<ButtonInteraction>;
};

export type WorkingTime = {
  __typename?: 'WorkingTime';
  id: Scalars['Int'];
  startedAt: Scalars['Date'];
  finishedAt?: Maybe<Scalars['Date']>;
  teacherId: Scalars['Int'];
  teacher: Teacher;
  teacherRateId: Scalars['Int'];
  teacherRate: TeacherRate;
  activities: Array<WorkingTimeActivity>;
  recentlyActive: Scalars['Boolean'];
  isActivityChecked?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export type SendPossibleIdleTimeNotificationInput = {
  userId: Scalars['Int'];
  workingTimeId: Scalars['Int'];
};

export type DescribeWorkingTimeInput = {
  workingTime: WorkingTimeInput;
  teacher: TeacherInput;
};

export type WorkingTimeInput = {
  id: Scalars['Int'];
  startedAt: Scalars['Date'];
  finishedAt: Scalars['Date'];
  teacherId: Scalars['Int'];
};

export type TeacherInput = {
  id: Scalars['Int'];
  userId: Scalars['Int'];
};

export type TeacherWorkingTimesResult = {
  __typename?: 'TeacherWorkingTimesResult';
  rows: Array<WorkingTime>;
  dailyStats: Scalars['Int'];
  weeklyStats: Scalars['Int'];
  monthlyStats: Scalars['Int'];
};

export type StatsByDomain = {
  __typename?: 'StatsByDomain';
  domain: Scalars['String'];
  totalActiveTime: Scalars['String'];
  totalIdleTime: Scalars['String'];
};

export type TotalStats = {
  __typename?: 'TotalStats';
  totalTrackedTime: Scalars['String'];
  totalActiveTime: Scalars['String'];
  totalIdleTime: Scalars['String'];
  ratio: Scalars['String'];
};

export type CombinedWeeklyStats = {
  __typename?: 'CombinedWeeklyStats';
  totalStats: TotalStats;
  statsByDomain: Array<StatsByDomain>;
};

export type WorkingTimeActivity = {
  __typename?: 'WorkingTimeActivity';
  id: Scalars['Int'];
  teacherId: Scalars['Int'];
  workingTimeId: Scalars['Int'];
  type: WorkingTimeActivityType;
  comment?: Maybe<Scalars['String']>;
  start: Scalars['Date'];
  items: Array<WorkingTimeActivityItem>;
  end: Scalars['Date'];
};

export enum WorkingTimeActivityType {
  Active = 'ACTIVE',
  Idle = 'IDLE'
}

export type WorkingTimeActivityItem = {
  __typename?: 'WorkingTimeActivityItem';
  id: Scalars['Int'];
  workingTimeActivityId: Scalars['Int'];
  type: WorkingTimeActivityItemType;
  relatedIds: Array<Scalars['Int']>;
  start: Scalars['Date'];
  end?: Maybe<Scalars['Date']>;
};

export enum WorkingTimeActivityItemType {
  Event = 'EVENT',
  ChatMessage = 'CHAT_MESSAGE',
  HomeworkReview = 'HOMEWORK_REVIEW'
}

export type EventsSidebar = {
  __typename?: 'EventsSidebar';
  open: Scalars['Boolean'];
};

export type SidebarState = {
  __typename?: 'SidebarState';
  sidebarOpen?: Maybe<Scalars['Boolean']>;
};

export type UserVacancySidebar = {
  __typename?: 'UserVacancySidebar';
  open: Scalars['Boolean'];
};

export type VacancyEditor = {
  __typename?: 'VacancyEditor';
  open: Scalars['Boolean'];
  vacancyId: Scalars['Int'];
};
