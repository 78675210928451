import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconSoftStar: FCIcon = (props) => (
  <BaseIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M8.00132 0.666504C8.25507 0.666504 8.48681 0.810552 8.59913 1.03809L10.5042 4.89748L14.7644 5.52018C15.0154 5.55687 15.2239 5.73288 15.3022 5.97422C15.3804 6.21555 15.3149 6.48039 15.1331 6.65741L12.051 9.65941L12.7784 13.9005C12.8213 14.1506 12.7185 14.4034 12.5131 14.5525C12.3078 14.7017 12.0356 14.7213 11.811 14.6032L8.00132 12.5997L4.19162 14.6032C3.96701 14.7213 3.69482 14.7017 3.4895 14.5525C3.28418 14.4034 3.18135 14.1506 3.22425 13.9005L3.95164 9.65942L0.8695 6.65741C0.687754 6.48039 0.622239 6.21555 0.700491 5.97422C0.778743 5.73288 0.987196 5.55687 1.23824 5.52018L5.49848 4.89748L7.40352 1.03809C7.51583 0.810552 7.74758 0.666504 8.00132 0.666504Z" fill="currentColor" />
  </BaseIcon>
);
