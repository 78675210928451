import React, { FC, memo } from 'react';
import { cn } from '@/lib';
import { IconBubblePhraseAngle } from '@/components/ui/icons/IconBubblePhraseAngle';
import styles from './BubblePhrase.module.scss';

export enum PhraseAnglePosition {
  topLeft = 'top-left',
  topRight = 'top-right',
  bottomLeft = 'bottom-left',
  bottomRight = 'bottom-right',
}

export enum BackgroundColorMode {
  White = 'white',
  Gray = 'gray',
}

const phraseAnglePositionClass = {
  [PhraseAnglePosition.topLeft]: styles.angleTopLeft,
  [PhraseAnglePosition.topRight]: styles.angleTopRight,
  [PhraseAnglePosition.bottomLeft]: styles.angleBottomLeft,
  [PhraseAnglePosition.bottomRight]: styles.angleBottomRight,
};

interface Props {
  text: string;
  title?: string;
  className?: string;
  angleClassName: string;
  anglePosition?: PhraseAnglePosition;
  backgroundColorMode?: BackgroundColorMode;
}

const BubblePhraseTemplate: FC<Props> = memo((props) => {
  const {
    text,
    title,
    className,
    angleClassName,
    anglePosition = PhraseAnglePosition.topRight,
    backgroundColorMode = BackgroundColorMode.White,
  } = props;

  return (
    <div className={cn(
      styles.bubblePhrase,
      {
        [styles.gray]: backgroundColorMode === BackgroundColorMode.Gray,
      },
      className,
    )}
    >
      {title && (
        <div className='flex-container flex-dir-column gap-4 align-middle'>
          <p className={styles.title}>{title}</p>
        </div>
      )}
      {text}

      <IconBubblePhraseAngle className={cn(
        styles.angle,
        {
          [styles.gray]: backgroundColorMode === BackgroundColorMode.Gray,
        },
        angleClassName,
        phraseAnglePositionClass[anglePosition],
      )}
      />
    </div>
  );
});

export const BubblePhrase = Object.assign(BubblePhraseTemplate, {
  PhraseAnglePosition,
});
