import React, { FC } from 'react';
import { LandingModal } from '@/components/ui/LandingModal';
import { useIsMinimalWidth } from '@/hooks/useIsMinimalWidth';
import { MINIMAL_TABLET_WIDTH } from '@/constants/breakpoints';
import { LandingSwipeModal } from '@/components/ui/LandingSwipeModal';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export const LandingPopup: FC<Props> = (props) => {
  const {
    children,
    isOpen,
    handleClose,
  } = props;

  const isSmallScreen = useIsMinimalWidth(MINIMAL_TABLET_WIDTH);

  if (!isOpen) {
    return null;
  }

  if (isSmallScreen) {
    return (
      <LandingSwipeModal
        close={handleClose}
      >
        {children}
      </LandingSwipeModal>
    );
  }

  return (
    <LandingModal
      isOpen={isOpen}
      close={handleClose}
    >
      {children}
    </LandingModal>
  );
};
