import getConfig from 'next/config';
import {
  DEFAULT_LOCALE,
  DEFAULT_SUB_DOMAIN,
} from '@/middleware/i18n/i18n.config';
import { CourseType, LanguageName } from '@/controllers/graphql/generated';
import getClientLink from '@/lib/helpers/getClientLink';
import { i18nCreateLanguageSubpath } from '@/middleware/i18n/i18n.utils';
import { isHiddenDomain } from '@/middleware/i18n/i18n.utils/i18n.isHiddenDomain';
import { isSubscriptionProductDomain } from '@/controllers/subDomain/subDomain.utils/isSubscriptionProductDomain';

const { publicRuntimeConfig = {} } = getConfig() || {};

const domainLocaleMap: Record<string, string[]> = (
  publicRuntimeConfig.DYNAMIC_I18N_CONFIG?.domainLocaleMap ?? {}
);

const activeDomains = (
  publicRuntimeConfig.DYNAMIC_I18N_CONFIG?.activeDomains ?? [
    DEFAULT_SUB_DOMAIN,
  ]
)
  .filter((domain: string) => !isHiddenDomain(domain));

const supportedCourses: Record<
  string, { id: number; slug: string; type: CourseType }[]
> = (
  publicRuntimeConfig.DYNAMIC_I18N_CONFIG?.supportedCourses ?? []
);

const getHreflangs = (options: {
  language: string;
  domain: string;
}) => {
  const { language, domain } = options;

  if (language === DEFAULT_LOCALE && domain === DEFAULT_SUB_DOMAIN) {
    return [
      language, // uk
      `${language}-${DEFAULT_SUB_DOMAIN}`, // uk-ua
    ];
  }

  if (language === LanguageName.Ru && domain === DEFAULT_SUB_DOMAIN) {
    return [
      language, // ru
      `${language}-${DEFAULT_SUB_DOMAIN}`, // ru-ua
    ];
  }

  if (language === LanguageName.En && domain === DEFAULT_SUB_DOMAIN) {
    return [
      `${language}-${DEFAULT_SUB_DOMAIN}`, // en-ua
    ];
  }

  if (language === LanguageName.En && domain === 'eu') {
    return [
      language, // en
    ];
  }

  if (language === LanguageName.En && domain === 'usa') {
    return [
      'us',
      'en-us',
    ];
  }

  if (language === domain) {
    return [
      language, // pl
      `${language}-${domain}`, // pl-pl
    ];
  }

  return [
    i18nCreateLanguageSubpath({
      language,
      domain,
    }),
  ];
};

const findDomainRelatedCourse = (options: {
  currentCourseSlug: string;
  currentCourseType: CourseType;
  domain: string;
}) => {
  const { currentCourseSlug, currentCourseType, domain } = options;

  const courseBaseSlug = currentCourseSlug.split('-')[0] ?? '';
  const courseType = currentCourseType;

  return supportedCourses[domain]?.find(
    (course) => (
      course.type === courseType
      && course.slug.startsWith(courseBaseSlug)
    ),
  );
};

const buildSubfolderLinks = (options: {
  path: string;
  currentDomain: string;
  currentLanguage: string;
  supportedDomains?: string[];
  courseSlug?: string;
}) => {
  const {
    currentDomain,
    path,
    supportedDomains = activeDomains,
  } = options;

  const currentCourse = options.courseSlug
    ? supportedCourses[currentDomain]?.find(
      (course) => course.slug === options.courseSlug,
    )
    : undefined;

  return Object.entries(domainLocaleMap).flatMap(([domain, languages]) => {
    const isUnsupportedDomain = supportedDomains
      && !supportedDomains.includes(domain);

    if (isUnsupportedDomain || isSubscriptionProductDomain(domain)) {
      return [];
    }

    const values = languages.flatMap((language) => {
      const hrefLangs = getHreflangs({ language, domain });

      if (!currentCourse) {
        const href = getClientLink(path, {
          domain,
          language,
        });

        return hrefLangs.map((hrefLang) => ({
          hrefLang,
          href,
        }));
      }

      const relatedCourse = findDomainRelatedCourse({
        currentCourseSlug: currentCourse.slug,
        currentCourseType: currentCourse.type,
        domain,
      });

      if (!relatedCourse) {
        return [];
      }

      const href = getClientLink(
        path.replace(`/${currentCourse.slug}`, `/${relatedCourse.slug}`),
        {
          domain,
          language,
        },
      );

      return hrefLangs.map((hrefLang) => ({
        hrefLang,
        href,
      }));
    });

    if (!currentCourse) {
      values.push({
        hrefLang: 'x-default',
        href: getClientLink(path, {
          domain: DEFAULT_SUB_DOMAIN,
          language: LanguageName.En,
        }),
      });

      return values;
    }

    const relatedCourse = findDomainRelatedCourse({
      currentCourseSlug: currentCourse.slug,
      currentCourseType: currentCourse.type,
      domain: DEFAULT_SUB_DOMAIN,
    });

    if (relatedCourse) {
      values.push({
        hrefLang: 'x-default',
        href: getClientLink(
          path.replace(`/${currentCourse.slug}`, `/${relatedCourse.slug}`),
          {
            domain: DEFAULT_SUB_DOMAIN,
            language: LanguageName.En,
          },
        ),
      });
    }

    return values;
  });
};

export const buildAlternateLinks = (options: {
  path: string;
  currentDomain: string;
  currentLanguage: string;
  supportedDomains?: string[];
  courseSlug?: string;
}) => buildSubfolderLinks(options);
