import React, { memo } from 'react';
import dynamic from 'next/dynamic';
import { PromoBannerBaseProps } from '@/components/landing/Promotions/components/PromoBanner/PromoBanner.typedefs';
import { useIsNewYearThemeEnabled } from './hooks/useIsNewYearThemeEnabled';
import { useIsPlNewYearThemeEnabled } from './hooks/useIsPlNewYearThemeEnabled';

const PromoBannerDeprecated = dynamic(
  () => import('./PromoBannerDeprecated')
    .then((mod) => mod.PromoBannerDeprecated),
);

const PromoBannerRedesigned = dynamic(
  () => import('./PromoBannerRedesigned')
    .then((mod) => mod.PromoBannerRedesigned),
);

const NewYearPromoBanner = dynamic(
  () => import('./NewYearPromoBanner')
    .then((mod) => mod.NewYearPromoBanner),
);

type Props = PromoBannerBaseProps & {
  shouldShowRedesigned?: boolean;
};

export const PromoBanner = memo<Props>((props) => {
  const { shouldShowRedesigned, ...rest } = props;

  const isUaNewYearThemeEnabled = useIsNewYearThemeEnabled(rest.promoName);
  const isPlNewYearThemeEnabled = useIsPlNewYearThemeEnabled(rest.promoName);

  const shouldShowNewYearBanner = isUaNewYearThemeEnabled
    || isPlNewYearThemeEnabled;

  if (shouldShowNewYearBanner && shouldShowRedesigned) {
    return <NewYearPromoBanner {...rest} />;
  }

  if (shouldShowRedesigned) {
    return <PromoBannerRedesigned {...rest} />;
  }

  return <PromoBannerDeprecated {...rest} />;
});
