export enum PageType {
  COURSE = 'course',
  COURSES = 'courses',
  HOME = 'home',
}

export enum SpecialPromoSlug {
  NEW_YEAR_UA_PROMO = 'new-year-2025-promo-ua',
  NEW_YEAR_BR_PROMO = 'new-year-2025-promo-br',
  NEW_YEAR_PL_PROMO = 'new-year-2025-promo-pl',
}
