import React, { FC, SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;
export const ChubbsLandingMessage: FC<Props> = ({ children, ...props }) => (
  <svg
    width="428"
    height="200"
    viewBox="0 0 428 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path fillRule="evenodd" clipRule="evenodd" d="M168.558 95.7497C168.767 95.3893 174.894 92.1836 175.729 98.8607C176.108 101.915 172.826 106.733 172.143 106.012C172.143 106.012 168.008 96.6223 168.558 95.7497Z" fill="#E77062" />
    <path d="M168.558 95.7497C168.767 95.3893 174.894 92.1836 175.729 98.8607C176.108 101.915 172.826 106.733 172.143 106.012C172.143 106.012 168.008 96.6223 168.558 95.7497Z" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M231.823 113.448C222.756 122.667 197.109 147.383 195.156 149.337C195.156 149.337 185.026 130.994 185.026 130.179C185.026 128.149 183.907 129.496 186.316 120.391C186.917 118.393 186.896 116.259 186.255 114.274C185.615 112.289 184.386 110.545 182.731 109.275C177.685 104.836 172.772 106.998 175.143 112.955C176.075 114.688 177.25 116.279 178.634 117.678V122.951C141.966 111.323 156.459 119.973 159.095 128.908C162.889 141.579 172.544 161.477 175.428 168.875C179.867 180.257 190.072 179.479 204.583 171.455C211.943 167.377 210.274 167.661 219.664 160.624C229.357 153.283 234.839 131.184 232.031 119.366C231.899 118.93 231.823 113.448 231.823 113.448Z" fill="#E77062" />
    <path d="M231.823 113.448C222.756 122.667 197.109 147.383 195.156 149.337C195.156 149.337 185.026 130.994 185.026 130.179C185.026 128.149 183.907 129.496 186.316 120.391C186.917 118.393 186.896 116.259 186.255 114.274C185.615 112.289 184.386 110.545 182.731 109.275C177.685 104.836 172.772 106.998 175.143 112.955C176.075 114.688 177.25 116.279 178.634 117.678V122.951C141.966 111.323 156.459 119.973 159.095 128.908C162.889 141.579 172.544 161.477 175.428 168.875C179.867 180.257 190.072 179.479 204.583 171.455C211.943 167.377 210.274 167.661 219.664 160.624C229.357 153.283 234.839 131.184 232.031 119.366C231.899 118.93 231.823 113.448 231.823 113.448Z" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M162.6 91.6566L148.977 97.4307C146.517 98.4732 145.369 101.312 146.411 103.772L159.055 133.602C160.097 136.062 162.936 137.211 165.396 136.168L179.019 130.394C181.478 129.351 182.627 126.512 181.585 124.053L168.941 94.2225C167.898 91.7628 165.059 90.6141 162.6 91.6566Z" fill="black" />
    <path d="M162.6 91.6566L148.977 97.4307C146.517 98.4732 145.369 101.312 146.411 103.772L159.055 133.602C160.097 136.062 162.936 137.211 165.396 136.168L179.019 130.394C181.478 129.351 182.627 126.512 181.585 124.053L168.941 94.2225C167.898 91.7628 165.059 90.6141 162.6 91.6566Z" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M195.211 149.319L199.138 158.557C198.03 155.676 197.557 152.589 197.753 149.509C197.753 149.509 198.284 147.137 197.886 147.194L195.211 149.319Z" fill="black" />
    <path d="M195.211 149.319L199.138 158.557C198.03 155.676 197.557 152.589 197.753 149.509C197.753 149.509 198.284 147.137 197.886 147.194L195.211 149.319Z" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M231.844 114.623L225.452 120.617C221.658 138.202 215.967 148.521 213.008 165.764C216.802 162.615 219.552 160.073 223.346 156.848L231.844 114.623Z" fill="black" />
    <path d="M231.844 114.623L225.452 120.617C221.658 138.202 215.967 148.521 213.008 165.764C216.802 162.615 219.552 160.073 223.346 156.848L231.844 114.623Z" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M232.509 57.431C232.509 73.6686 232.509 73.384 232.509 89.6785C232.509 132.549 213.008 141.597 211.794 185.7C211.567 193.762 218.775 192.756 220.008 203.588C229.113 205.807 253.469 196.911 262.594 199.13C271.661 196.911 295.638 214.931 304.724 212.712C320.677 142.64 287.007 129.001 287.064 92.5238V57.4879C287.064 21.5036 232.547 22.7366 232.509 57.431Z" fill="#E77062" />
    <path d="M232.509 57.431C232.509 73.6686 232.509 73.384 232.509 89.6785C232.509 132.549 213.008 141.597 211.794 185.7C211.567 193.762 218.775 192.756 220.008 203.588C229.113 205.807 253.469 196.911 262.594 199.13C271.661 196.911 295.638 214.931 304.724 212.712C320.677 142.64 287.007 129.001 287.064 92.5238V57.4879C287.064 21.5036 232.547 22.7366 232.509 57.431Z" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M232.508 57.432C232.508 73.6696 232.508 73.385 232.508 89.6795C232.522 94.4459 232.256 99.209 231.711 103.944H288.182C287.443 100.195 287.068 96.3838 287.063 92.5627V57.5269C287.063 21.5046 232.546 22.7376 232.508 57.432Z" fill="#E77062" />
    <path d="M232.508 57.432C232.508 73.6696 232.508 73.385 232.508 89.6795C232.522 94.4459 232.256 99.209 231.711 103.944H288.182C287.443 100.195 287.068 96.3838 287.063 92.5627V57.5269C287.063 21.5046 232.546 22.7376 232.508 57.432Z" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M286.93 54.5673C284.236 22.5664 235.524 23.5908 232.641 54.5673H286.93Z" fill="#E77062" />
    <path d="M286.93 54.5673C284.236 22.5664 235.524 23.5908 232.641 54.5673H286.93Z" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M308.406 184.393C308.406 184.791 308.406 185.189 308.406 185.569C308.766 203.912 302.848 214.022 302.885 230.772C302.999 267.117 303.986 360.501 303.872 362.379H219.061C219.061 362.379 218.321 267.344 218.834 231.72C219.118 212.751 213.617 207.307 211.568 185.777C211.54 185.316 211.54 184.854 211.568 184.393H308.406Z" fill="#E77062" />
    <path d="M308.406 184.393C308.406 184.791 308.406 185.189 308.406 185.569C308.766 203.912 302.848 214.022 302.885 230.772C302.999 267.117 303.986 360.501 303.872 362.379H219.061C219.061 362.379 218.321 267.344 218.834 231.72C219.118 212.751 213.617 207.307 211.568 185.777C211.54 185.316 211.54 184.854 211.568 184.393H308.406Z" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M234.918 76.2681C235.55 76.2718 236.169 76.0876 236.695 75.7389C237.222 75.3902 237.634 74.8927 237.877 74.3096C238.121 73.7265 238.186 73.0842 238.063 72.4642C237.941 71.8443 237.637 71.2747 237.19 70.8279C236.743 70.3811 236.174 70.0772 235.554 69.9548C234.934 69.8325 234.292 69.8972 233.709 70.1407C233.125 70.3843 232.628 70.7957 232.279 71.3226C231.93 71.8496 231.746 72.4684 231.75 73.1002C231.75 73.9404 232.084 74.7462 232.678 75.3402C233.272 75.9343 234.078 76.2681 234.918 76.2681Z" fill="#FEFEFE" />
    <path d="M234.918 76.2681C235.55 76.2718 236.169 76.0876 236.695 75.7389C237.222 75.3902 237.634 74.8927 237.877 74.3096C238.121 73.7265 238.186 73.0842 238.063 72.4642C237.941 71.8443 237.637 71.2747 237.19 70.8279C236.743 70.3811 236.174 70.0772 235.554 69.9548C234.934 69.8325 234.292 69.8972 233.709 70.1407C233.125 70.3843 232.628 70.7957 232.279 71.3226C231.93 71.8496 231.746 72.4684 231.75 73.1002C231.75 73.9404 232.084 74.7462 232.678 75.3402C233.272 75.9343 234.078 76.2681 234.918 76.2681Z" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M261.526 69.9883C260.895 69.9846 260.277 70.1683 259.75 70.5162C259.224 70.864 258.812 71.3604 258.568 71.9424C258.324 72.5244 258.258 73.1657 258.379 73.7852C258.5 74.4046 258.802 74.9742 259.247 75.4218C259.692 75.8693 260.26 76.1747 260.878 76.2993C261.497 76.4238 262.139 76.3618 262.722 76.1211C263.306 75.8805 263.804 75.4721 264.156 74.9476C264.507 74.4231 264.694 73.8062 264.694 73.1751C264.696 72.7575 264.616 72.3436 264.458 71.957C264.3 71.5705 264.067 71.219 263.773 70.9229C263.478 70.6267 263.128 70.3917 262.743 70.2313C262.357 70.0709 261.944 69.9883 261.526 69.9883Z" fill="#FEFEFE" />
    <path d="M261.526 69.9883C260.895 69.9846 260.277 70.1683 259.75 70.5162C259.224 70.864 258.812 71.3604 258.568 71.9424C258.324 72.5244 258.258 73.1657 258.379 73.7852C258.5 74.4046 258.802 74.9742 259.247 75.4218C259.692 75.8693 260.26 76.1747 260.878 76.2993C261.497 76.4238 262.139 76.3618 262.722 76.1211C263.306 75.8805 263.804 75.4721 264.156 74.9476C264.507 74.4231 264.694 73.8062 264.694 73.1751C264.696 72.7575 264.616 72.3436 264.458 71.957C264.3 71.5705 264.067 71.219 263.773 70.9229C263.478 70.6267 263.128 70.3917 262.743 70.2313C262.357 70.0709 261.944 69.9883 261.526 69.9883V69.9883Z" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M236.114 60.8658C234.864 60.6923 233.591 60.8902 232.453 61.4349" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M260.18 60.8657C261.429 60.6887 262.704 60.8868 263.841 61.4348" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M286.552 99.0312H232.49C231.471 99.0313 230.494 99.436 229.774 100.156C229.053 100.877 228.648 101.854 228.648 102.872C228.648 103.891 229.053 104.868 229.774 105.589C230.494 106.309 231.471 106.714 232.49 106.714H286.552C287.57 106.714 288.547 106.309 289.268 105.589C289.988 104.868 290.393 103.891 290.393 102.872C290.393 101.854 289.988 100.877 289.268 100.156C288.547 99.436 287.57 99.0312 286.552 99.0312Z" fill="#E77062" />
    <path d="M286.552 99.0312H232.49C231.471 99.0313 230.494 99.436 229.774 100.156C229.053 100.877 228.648 101.854 228.648 102.872C228.648 103.891 229.053 104.868 229.774 105.589C230.494 106.309 231.471 106.714 232.49 106.714H286.552C287.57 106.714 288.547 106.309 289.268 105.589C289.988 104.868 290.393 103.891 290.393 102.872C290.393 101.854 289.988 100.877 289.268 100.156C288.547 99.436 287.57 99.0312 286.552 99.0312V99.0312Z" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M287.062 54.2832H203.883" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M231.73 106.467L230.914 109.805C241.455 108.138 252.082 107.074 262.744 106.619L231.73 106.467Z" fill="black" />
    <path d="M231.73 106.467L230.914 109.805C241.455 108.138 252.082 107.074 262.744 106.619L231.73 106.467Z" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M233.339 54.1504L232.523 57.5079C243.045 55.8303 253.653 54.7536 264.297 54.2832L233.339 54.1504Z" fill="black" />
    <path d="M233.339 54.1504L232.523 57.5079C243.045 55.8303 253.653 54.7536 264.297 54.2832L233.339 54.1504Z" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M233.509 73.4791C233.461 73.4763 233.413 73.4838 233.368 73.5009C233.323 73.518 233.282 73.5445 233.248 73.5785C233.214 73.6126 233.188 73.6534 233.171 73.6984C233.154 73.7434 233.146 73.7914 233.149 73.8395C233.149 73.9351 233.187 74.0268 233.255 74.0943C233.322 74.1619 233.414 74.1999 233.509 74.1999C233.604 74.1953 233.693 74.1559 233.759 74.0892C233.826 74.0226 233.865 73.9336 233.87 73.8395C233.87 73.7439 233.832 73.6522 233.764 73.5846C233.697 73.517 233.605 73.4791 233.509 73.4791Z" fill="black" />
    <path d="M233.509 73.4791C233.461 73.4763 233.413 73.4838 233.368 73.5009C233.323 73.518 233.282 73.5445 233.248 73.5785C233.214 73.6126 233.188 73.6534 233.171 73.6984C233.154 73.7434 233.146 73.7914 233.149 73.8395C233.149 73.9351 233.187 74.0268 233.255 74.0943C233.322 74.1619 233.414 74.1999 233.509 74.1999C233.604 74.1953 233.693 74.1559 233.759 74.0892C233.826 74.0226 233.865 73.9336 233.87 73.8395C233.87 73.7439 233.832 73.6522 233.764 73.5846C233.697 73.517 233.605 73.4791 233.509 73.4791V73.4791Z" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M259.743 74.2188C259.791 74.2216 259.839 74.2142 259.884 74.197C259.929 74.1799 259.97 74.1534 260.004 74.1194C260.038 74.0854 260.065 74.0445 260.082 73.9996C260.099 73.9546 260.106 73.9065 260.104 73.8585C260.104 73.7629 260.066 73.6712 259.998 73.6036C259.93 73.536 259.839 73.498 259.743 73.498C259.649 73.5026 259.56 73.5421 259.493 73.6087C259.427 73.6753 259.387 73.7644 259.383 73.8585C259.383 73.954 259.421 74.0457 259.488 74.1133C259.556 74.1809 259.648 74.2188 259.743 74.2188Z" fill="black" />
    <path d="M259.743 74.2188C259.791 74.2216 259.839 74.2142 259.884 74.197C259.929 74.1799 259.97 74.1534 260.004 74.1194C260.038 74.0854 260.065 74.0445 260.082 73.9996C260.099 73.9546 260.106 73.9065 260.104 73.8585C260.104 73.7629 260.066 73.6712 259.998 73.6036C259.93 73.536 259.839 73.498 259.743 73.498C259.649 73.5026 259.56 73.5421 259.493 73.6087C259.427 73.6753 259.387 73.7644 259.383 73.8585C259.383 73.954 259.421 74.0457 259.488 74.1133C259.556 74.1809 259.648 74.2188 259.743 74.2188V74.2188Z" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M243.297 65.9688H287.04" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M249.844 65.9688H270.179V68.7762C270.179 71.4728 269.107 74.0589 267.201 75.9656C265.294 77.8724 262.708 78.9436 260.011 78.9436C257.315 78.9436 254.728 77.8724 252.822 75.9656C250.915 74.0589 249.844 71.4728 249.844 68.7762V65.9688Z" fill="black" />
    <path d="M249.844 65.9688H270.179V68.7762C270.179 71.4728 269.107 74.0589 267.201 75.9656C265.294 77.8724 262.708 78.9436 260.011 78.9436C257.315 78.9436 254.728 77.8724 252.822 75.9656C250.915 74.0589 249.844 71.4728 249.844 68.7762V65.9688Z" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M225.773 65.9688H246.108V68.7762C246.108 71.4728 245.037 74.0589 243.13 75.9656C241.224 77.8724 238.637 78.9436 235.941 78.9436C233.244 78.9436 230.658 77.8724 228.751 75.9656C226.845 74.0589 225.773 71.4728 225.773 68.7762V65.9688Z" fill="black" />
    <path d="M225.773 65.9688H246.108V68.7762C246.108 71.4728 245.037 74.0589 243.13 75.9656C241.224 77.8724 238.637 78.9436 235.941 78.9436C233.244 78.9436 230.658 77.8724 228.751 75.9656C226.845 74.0589 225.773 71.4728 225.773 68.7762V65.9688Z" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M264.812 86.7002C265.434 86.3777 265.986 85.935 266.435 85.3977C266.885 84.8604 267.223 84.2393 267.43 83.5703" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M265.57 82.3184C266.137 82.7875 266.798 83.1288 267.508 83.3187C268.219 83.5085 268.962 83.5426 269.687 83.4186" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M186.031 132.607C194.169 136.78 202.705 139.474 211.696 143.533C218.163 146.541 224.954 148.794 231.936 150.248C238.898 151.462 243.317 151.974 248.799 150.021C256.691 147.213 286.434 134.068 288.426 130.52C293.775 121.036 287.705 110.944 276.627 110.812L240.112 127.884L197.166 111.779C191.76 101.346 183.887 106.961 183.508 106.468L173.663 102.56C170.818 101.422 164.349 99.5819 163.515 104.229C162.851 107.871 173.359 110.963 173.359 110.963C166.341 112.519 171.463 118.551 171.463 118.551C167.877 121.814 169.566 124.849 174.687 127.713C177.988 129.61 184.305 131.715 186.069 132.645" fill="#E77062" />
    <path d="M276.627 110.812L240.112 127.884L197.166 111.779C191.759 101.346 183.887 106.961 183.508 106.468L173.663 102.56C170.818 101.422 164.349 99.5819 163.515 104.229C162.851 107.871 173.359 110.963 173.359 110.963C166.341 112.519 171.463 118.551 171.463 118.551C167.877 121.814 169.566 124.849 174.687 127.713C177.988 129.61 184.305 131.716 186.069 132.645C194.207 136.818 202.743 139.512 211.734 143.571C218.19 146.563 224.969 148.803 231.936 150.248C238.898 151.462 244.247 151.652 249.691 149.698C257.563 146.891 286.434 134.068 288.426 130.52" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M172.203 110.584L178.406 112.671" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M183.242 106.297L187.681 107.928" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M171.391 118.512L177.442 120.409" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M148.587 108.971C148.739 109.37 161.126 109.996 157.465 115.459C157.857 115.526 158.243 115.621 158.622 115.743C159.237 115.964 159.766 116.375 160.133 116.915C160.5 117.456 160.687 118.099 160.666 118.752C160.645 119.406 160.417 120.035 160.016 120.551C159.614 121.067 159.06 121.443 158.432 121.624C158.432 121.624 162.397 121.149 162.226 124.317C162.112 127.997 154.505 127.637 152.039 126.916C151.233 126.768 150.501 126.352 149.961 125.736C149.42 125.119 149.103 124.339 149.061 123.521C148.91 121.927 150.029 123.521 147.051 121.111C144.072 118.702 146.633 115.554 145.78 114.757C143.674 112.86 141.986 108.364 147.07 107.074C147.183 107.055 148.587 108.971 148.587 108.971Z" fill="#E77062" />
    <path d="M148.587 108.971C148.739 109.37 161.126 109.996 157.465 115.459C157.857 115.526 158.243 115.621 158.622 115.743C159.237 115.964 159.766 116.375 160.133 116.915C160.5 117.456 160.687 118.099 160.666 118.752C160.645 119.406 160.417 120.035 160.016 120.551C159.614 121.067 159.06 121.443 158.432 121.624C158.432 121.624 162.397 121.149 162.226 124.317C162.112 127.997 154.505 127.637 152.039 126.916C151.233 126.768 150.501 126.352 149.961 125.736C149.42 125.119 149.103 124.339 149.061 123.521C148.91 121.927 150.029 123.521 147.051 121.111C144.072 118.702 146.633 115.554 145.78 114.757C143.674 112.86 141.986 108.364 147.07 107.074C147.183 107.055 148.587 108.971 148.587 108.971Z" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M145.781 114.699C146.437 115.036 147.131 115.291 147.849 115.458C150.884 116.187 154.039 116.264 157.106 115.686H157.333" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M148.586 122.173C151.887 122.173 156.174 122.666 159.171 121.338C159.246 121.311 159.316 121.273 159.379 121.225" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M218.771 147.193L217.766 150.115C224.936 152.012 233.586 153.719 241.022 152.96C246.951 152.138 252.657 150.146 257.809 147.099C260.12 145.712 262.501 144.446 264.942 143.305C264.619 143.305 250.146 151.423 239.296 151.423C235.955 151.392 232.635 150.894 229.432 149.944L218.771 147.193Z" fill="black" />
    <path d="M218.771 147.193L217.766 150.115C224.936 152.012 233.586 153.719 241.022 152.96C246.951 152.138 252.657 150.146 257.809 147.099C260.12 145.712 262.501 144.446 264.942 143.305C264.619 143.305 250.146 151.423 239.296 151.423C235.955 151.392 232.635 150.894 229.432 149.944L218.771 147.193Z" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M85.1562 27.498L137.094 49.0469L140.963 36.2238L85.1562 27.498Z" fill="black" />
    <path d="M85.1562 27.498L137.094 49.0469L140.963 36.2238L85.1562 27.498Z" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M96.5781 31.5391L136.072 39.6009L137.381 49.1803L96.5781 31.5391Z" fill="#E77062" />
    <path d="M96.5781 31.5391L136.072 39.6009L137.381 49.1803L96.5781 31.5391Z" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M119.284 49.4075L136.072 39.6005L75.4844 25.9238L119.284 49.4075Z" fill="#E77062" />
    <path d="M119.284 49.4075L136.072 39.6005L75.4844 25.9238L119.284 49.4075Z" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M140.51 36.4896L157.317 26.6636L75.4844 25.9238L140.51 36.4896Z" fill="#E77062" />
    <path d="M140.51 36.4896L157.317 26.6636L75.4844 25.9238L140.51 36.4896Z" stroke="black" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M146.215 39.3164C151.373 41.4482 155.816 45.0085 159.019 49.5787C163.762 56.3506 165.393 72.8348 156.136 76.4958C149.516 79.1325 141.359 69.4203 144.906 66.4042C149.383 62.6104 155.984 74.2954 155.472 87.5168" stroke="black" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
  </svg>

);
