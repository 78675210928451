import React, { memo, useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { cn } from '@/lib';
import { ConsultationFormFields, SelectorProps } from '@/components/courseLanding/CourseConsultationForm/ConsultationForm.typedefs';
import { useEnteredUserValues } from '@/controllers/consultation/useEnteredUserValues';
import { useProcessConsultationRequest } from '@/controllers/user/user.hooks/useProcessConsultationRequest';
import { useIsOpenStateWithBlockPageScroll } from '@/hooks/useIsOpenStateWithBlockPageScroll';
import { useShouldRenderTelegramPopup } from '@/components/platform/RegistrationForm/TelegramPopup/TelegramPopup.hooks/useShouldRenderTelegramPopup';
import { TelegramPopupModal } from '@/components/platform/RegistrationForm/TelegramPopup';
import { ConsultationFormMessage } from '@/components/courseLanding/CourseConsultationForm/components/ConsultationFormMessage/ConsultationFormMessage';
import { CourseType } from '@/controllers/graphql/generated';
import { ProfessionConsultationForm } from './ProfessionConsultationForm';
import styles from './ProfessionConsultationFormSection.module.scss';

interface Props {
  consultationSlug?: string;
  eventSource?: string;
  courseTypes?: CourseType[];
  selectorProps?: SelectorProps;
}

export const ProfessionConsultationFormSection = memo<Props>((props) => {
  const {
    consultationSlug,
    eventSource = 'consultation_section',
    courseTypes = [
      CourseType.Postpaid,
      CourseType.Prepaid,
      CourseType.Consultation,
    ],
    selectorProps,
  } = props;

  const [
    shouldShowSuccessMessage,
    setShouldShowSuccessMessage,
  ] = useState(false);

  const [processConsultationRequest] = useProcessConsultationRequest();

  const {
    isOpen: isTelegramModalOpened,
    close: closeTelegramModal,
    open: openTelegramModal,
  } = useIsOpenStateWithBlockPageScroll({ initialState: false });

  const shouldRenderTelegramPopup = useShouldRenderTelegramPopup();

  const {
    setEnteredUserEmail,
    setEnteredUserPhone,
    setEnteredUserName,
  } = useEnteredUserValues();

  const [
    consultationRequestError,
    setConsultationRequestError,
  ] = useState<Error | null>(null);

  const submitCallback = useCallback(
    async (data: ConsultationFormFields): Promise<void> => {
      const selectedSlug = data.courseSlug?.value || consultationSlug || '';
      const courseId = data.courseSlug?.courseId || 0;
      const {
        name = '',
        phone = '',
        email = '',
        newslettersSubscription,
        age,
      } = data;

      setEnteredUserName(name);
      setEnteredUserPhone(phone);
      setEnteredUserEmail(email);

      try {
        const { user } = await processConsultationRequest({
          name,
          phone,
          email,
          age,
          courseId,
          selectedSlug,
          source: eventSource,
          newslettersSubscription,
        });

        setShouldShowSuccessMessage(true);

        if (shouldRenderTelegramPopup(user)) {
          openTelegramModal();
        }
      } catch (error) {
        setConsultationRequestError(error as Error);
      }
    },
    [
      setEnteredUserName,
      setEnteredUserPhone,
      setEnteredUserEmail,
      shouldRenderTelegramPopup,
      openTelegramModal,
      consultationSlug,
      processConsultationRequest,
      eventSource,
    ],
  );

  return (
    <>
      <div className={cn(styles.formWrapper, 'cell xlarge-5')}>
        <Form
          onSubmit={submitCallback}
          subscription={{ submitting: true, pristine: true }}
          render={(formRenderProps) => (
            <ProfessionConsultationForm
              {...formRenderProps}
              slug={consultationSlug}
              types={courseTypes}
              consultationRequestError={consultationRequestError}
              selectorProps={selectorProps}
            />
          )}
        />

        {shouldShowSuccessMessage && (
          <ConsultationFormMessage
            className={styles.consultationMessage}
            courseSlug={consultationSlug}
          />
        )}
      </div>

      <TelegramPopupModal
        isOpen={isTelegramModalOpened}
        closePopup={closeTelegramModal}
      />
    </>
  );
});
