import React, { memo } from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { Button } from '@/components/ui/Button';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { FCImage } from '@/components/ui/IconEmojies/typedefs';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { Decorations } from '../Decorations';
import { Card } from './GiftCard.typedefs';
import { GiftCardsSectionHelpers } from '../../GiftCardsSection.helpers';
import styles from './GiftCard.module.scss';

interface Props {
  card: Card;
  index: number;
  onClick: () => void;
  promoCodeSlug: string;
  PreviewEmojiIcon?: FCImage;
}

export const GiftCard = memo<Props>((props) => {
  const {
    card,
    index,
    onClick,
    promoCodeSlug,
    PreviewEmojiIcon,
  } = props;

  const {
    Icon,
    text,
    subtitle,
    title,
    buttonText,
    isBig,
    isMain,
    isFlipped,
    isEnlarged,
  } = card;

  const { t } = useTranslation(I18N_CODES.course);

  const href = GiftCardsSectionHelpers.getPromoCardLink({
    promoCodeSlug,
  });

  const { subDomain } = useSubDomainContext();

  return (
    <div
      className={cn(styles.scene, {
        [styles.isBig]: isBig,
        [styles.enlarged]: isEnlarged,
      })}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyPress={onClick}
    >
      <div
        className={cn(styles.card, {
          [styles.isFlipped]: isFlipped,
        })}
      >
        <div className={cn(styles.cardFace, styles.cardFaceFront)}>
          <Decorations index={index} />

          {PreviewEmojiIcon && <PreviewEmojiIcon size={40} />}
        </div>

        <div
          className={cn(styles.cardFace, styles.cardFaceBack, {
            [styles.hidden]: isMain,
            [styles.visible]: isBig,
          })}
        >
          {Icon
            ? (
              <>
                <Icon size={24} />
                {text && (
                  <p className={cn(typography.landingTextMain, styles.text, 'mt-12')}>
                    {t(text, {
                      context: subDomain,
                    })}
                  </p>
                )}
              </>
            )
            : (
              <>
                {subtitle && (
                  <p className={styles.subtitle}>
                    {t(subtitle, {
                      context: subDomain,
                    })}
                  </p>
                )}

                {title && (
                  <h3 className={styles.title}>
                    {t(title, {
                      context: subDomain,
                    })}
                  </h3>
                )}

                {text && (
                  <p
                    className={cn(
                      typography.landingTextMain,
                      styles.mainText,
                      'mb-32 small-mb-24',
                    )}
                  >
                    {t(text, {
                      context: subDomain,
                    })}
                  </p>
                )}

                {buttonText && (
                  <Button
                    size={Button.size.Large}
                    mode={Button.mode.Secondary}
                    text={t(buttonText, {
                      context: subDomain,
                    })}
                    href={href}
                  />
                )}
              </>
            )}
        </div>
      </div>
    </div>
  );
});
