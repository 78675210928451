import {
  useCallback, useRef,
} from 'react';
import { ValidationRuleFn } from '@/controllers/forms/forms.typedefs';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';

type UsePhoneValidationOutput = [
  ValidationRuleFn,
  string,
  (value: string) => void,
];

const countryCodesForStrictValidation = new Set([
  'ua',
  'pl',
]);

export const usePhoneValidation = (
  countryCode: string,
): UsePhoneValidationOutput => {
  const features = useFeatures();
  const shouldUseStrictValidation = features
    .isEnabled(Features.StrictPhoneNumbersValidation);
  const format = useRef<string>('');

  const setFormat = useCallback((value: string) => {
    format.current = value;
  }, []);

  const validator: ValidationRuleFn = useCallback((value) => {
    const numberLength = String(value).match(/[0-9]/g) || [];
    const validNumbersCount = (format.current.match(/\./g) || []).length;

    if (shouldUseStrictValidation
      && countryCodesForStrictValidation.has(countryCode)) {
      return validNumbersCount === numberLength.length;
    }

    return validNumbersCount <= numberLength.length
      || numberLength.length >= 11;
  }, [countryCode, format, shouldUseStrictValidation]);

  return [
    validator,
    format.current,
    setFormat,
  ];
};
