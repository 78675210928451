import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconTelegramColor: FCIcon = (props) => (
  <BaseIcon
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_314_35499)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3702 5.90662C26.6997 5.76794 27.0603 5.72011 27.4146 5.76811C27.7688 5.81611 28.1037 5.95818 28.3844 6.17953C28.6651 6.40089 28.8814 6.69344 29.0107 7.02673C29.1399 7.36002 29.1775 7.72187 29.1195 8.07462L26.0955 26.4173C25.8022 28.1866 23.8608 29.2013 22.2382 28.32C20.8808 27.5826 18.8648 26.4466 17.0515 25.2613C16.1448 24.668 13.3675 22.768 13.7088 21.416C14.0022 20.26 18.6688 15.916 21.3355 13.3333C22.3822 12.3186 21.9048 11.7333 20.6688 12.6666C17.5995 14.984 12.6715 18.508 11.0422 19.5C9.60484 20.3746 8.85551 20.524 7.95951 20.3746C6.32484 20.1026 4.80884 19.6813 3.57151 19.168C1.89951 18.4746 1.98084 16.176 3.57017 15.5066L26.3702 5.90662Z"
        fill="#24A1DE"
      />
    </g>
    <defs>
      <clipPath id="clip0_314_35499">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </BaseIcon>
);
