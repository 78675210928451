import { EnteredUserInfoField } from '@/controllers/consultation/consultation.typedefs';
import { useEnteredUserValue } from './useEnteredUserValue';

export const useEnteredUserValues = () => {
  const [
    enteredUserEmail,
    setEnteredUserEmail,
  ] = useEnteredUserValue(EnteredUserInfoField.Email);

  const [
    enteredUserPhone,
    setEnteredUserPhone,
  ] = useEnteredUserValue(EnteredUserInfoField.Phone);

  const [
    enteredUserName,
    setEnteredUserName,
  ] = useEnteredUserValue(EnteredUserInfoField.Name);

  return {
    enteredUserEmail,
    setEnteredUserEmail,

    enteredUserName,
    setEnteredUserName,

    enteredUserPhone,
    setEnteredUserPhone,
  };
};
