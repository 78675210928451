import douLogo from '@/images/generated/professionLanding.logos.douLogo.s3Key';
import ePravdaLogo from '@/images/generated/professionLanding.logos.ePravdaLogo.s3Key';
import forbesLogo from '@/images/generated/professionLanding.logos.forbesLogo.s3Key';

import { I18N_CODES } from '@/lib/constants/general';
import {
  QuoteAboutMate,
} from '@/components/homepageLanding/QuotesSection/QuotesSection.typedefs';

export const QUOTES_ABOUT_MATE: QuoteAboutMate[] = [
  {
    id: 1,
    imageData: douLogo,
    textCode: `${I18N_CODES.home}:quote_about_mate_dou`,
  },
  {
    id: 2,
    imageData: ePravdaLogo,
    textCode: `${I18N_CODES.home}:quote_about_mate_epravda`,
  },
  {
    id: 3,
    imageData: forbesLogo,
    textCode: `${I18N_CODES.home}:quote_about_mate_forbes`,
  },
];
