import React, { FC } from 'react';
import { SubDomains } from '@/constants/subdomains';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { useGetPrivaciesDocs } from '@/controllers/landing/landing.helpers';
import { useDefaultDomain } from '@/controllers/subDomain/subDomain.hooks/useDefaultDomain';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useTranslateByDomain } from '@/hooks/useTranslateByDomain';
import { I18N_CODES } from '@/lib/constants/general';
import { FooterLinksDesktop } from './FooterLinksDesktop';
import { FooterLinksMobile } from './FooterLinksMobile';
import { useOtherTracks } from '../../hooks/useOtherTracks';

export const FooterLinks: FC = () => {
  const features = useFeatures();
  const isDefaultDomain = useDefaultDomain();
  const {
    termsOfUse,
    privacyPolicy,
    cookiesPolicy,
  } = useGetPrivaciesDocs();

  const { subDomain } = useSubDomainContext();

  const hireFormLink = useTranslateByDomain(
    `${I18N_CODES.common}:hire_form_link`,
  );
  const isPlDomain = subDomain === SubDomains.pl;

  const shouldShowReviewsPageLink = isDefaultDomain
    && features.isEnabled(features.ReviewsLanding);

  const shouldShowBlogLink = isDefaultDomain
    && features.isEnabled(features.BlogLanding);

  const shouldShowFreeForCombatantsLink = isDefaultDomain
    && features.isEnabled(features.FreeStudyingForCombatants);

  const shouldShowPolishMergeAnnouncement = isPlDomain
    && features.isEnabled(features.PolishEntityMergeAnnouncement);

  const tracks = useOtherTracks();

  const props = {
    termsOfUse,
    privacyPolicy,
    cookiesPolicy,
    hireFormLink,
    shouldShowReviewsPageLink,
    shouldShowBlogLink,
    shouldShowFreeForCombatantsLink,
    shouldShowPolishMergeAnnouncement,
    tracks,
  };

  return (
    <>
      <FooterLinksDesktop {...props} />

      <FooterLinksMobile {...props} />
    </>
  );
};
