import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { QUOTES_ABOUT_MATE } from '@/components/homepageLanding/QuotesSection/QuotesSection.content';
import { IconImage } from '@/components/ui/Image/IconImage';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { ContentContainer } from '@/components/landing/BaseSectionWrappers/ContentContainer';
import { SectionBase, SectionBaseType } from '@/components/landing/BaseSectionWrappers/SectionBase';
import styles from './QuotesSection.module.scss';

export const QuotesSection: FC = () => {
  const { t } = useTranslation([I18N_CODES.home]);

  return (
    <SectionBase type={SectionBaseType.Default}>
      <ContentContainer>
        <Swiper
          className='overflow-visible'
          slidesPerView="auto"
          breakpoints={{
            1024: {
              slidesPerView: 3,
            },
            640: {
              spaceBetween: 56,
            },
            320: {
              spaceBetween: 24,
            },
          }}
          grabCursor
        >
          {QUOTES_ABOUT_MATE.map(({ id, imageData, textCode }) => (
            <SwiperSlide key={id} className={styles.slide}>
              <div className={styles.icon}>
                <IconImage
                  imageData={imageData}
                  width={imageData.fallback.width / 4}
                  height={imageData.fallback.height / 4}
                />
              </div>

              <p className={cn(styles.text, typography.newLandingTextMain, 'text-center')}>
                {t(textCode)}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
      </ContentContainer>
    </SectionBase>
  );
};
