import React, { memo, PropsWithChildren } from 'react';
import { cn } from '@/lib/classNames';
import { section } from '@/components/ui/section';
import styles from './SectionBase.module.scss';

export enum SectionBaseType {
  Default = 'DEFAULT',
  Wide = 'WIDE',
  WideWithLightBackground = 'WIDE_WITH_LIGHT_BACKGROUND',
}

const sectionBaseTypeClass: Record<SectionBaseType, string> = {
  [SectionBaseType.Default]: styles.default,
  [SectionBaseType.Wide]: styles.wide,
  [SectionBaseType.WideWithLightBackground]: styles.wideWithLightBackground,
};

interface Props {
  type: SectionBaseType;

  id?: string;
  className?: string;
  hideFixedCtaButton?: boolean;
  hidePromoBanner?: boolean;
}

export const SectionBase = memo((props: PropsWithChildren<Props>) => {
  const {
    id,
    children,
    className,
    type = SectionBaseType.Default,
    hideFixedCtaButton = false,
    hidePromoBanner = false,
  } = props;

  const wrapperClass = cn(
    sectionBaseTypeClass[type],
    className,
    {
      [section.scrollSection]: Boolean(id),
    },
  );

  return (
    <section
      id={id}
      className={wrapperClass}
      data-hide-fixed-cta-button={String(hideFixedCtaButton)}
      data-hide-promo-banner={String(hidePromoBanner)}
    >
      {children}
    </section>
  );
});
