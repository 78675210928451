import React, {
  useCallback, useEffect, useState, memo,
} from 'react';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { section } from '@/components/ui/section';
import { cn } from '@/lib';
import { useHandleFormInteraction } from '@/components/common/ExitPopup/hooks/useHandleFormInteraction.hook';
import { exists } from '@/lib/helpers/functional';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { Card } from './components/GiftCard/GiftCard.typedefs';
import { GiftCard } from './components/GiftCard/GiftCard';
import { commonCards, initialVisibleCards, mainCard } from './data';
import styles from './GiftCardsSection.module.scss';

const CARD_CLICK_ANIMATION_TIMEOUT = 400;
const CARD_FLIP_ANIMATION_TIMEOUT = 1400;

interface Props {
  promoCodeSlug: string;
  id?: string;
}

export const GiftCardsSection = memo<Props>((props) => {
  const {
    id,
    promoCodeSlug,
  } = props;

  const { t } = useTranslation(I18N_CODES.course);

  const [wasAnimationStarted, setWasAnimationStarted] = useState(false);
  const [
    visibleCards,
    setVisibleCards,
  ] = useState<Card[]>(initialVisibleCards);
  const [wasCardOpened, setWasCardOpened] = useLocalStorage(
    LOCAL_STORAGE_KEYS.newYearPromoCardOpened(),
    false,
  );

  const handleFormInteraction = useHandleFormInteraction();

  const handleCardClick = useCallback((index: number) => {
    if (wasAnimationStarted || wasCardOpened) {
      return;
    }

    handleFormInteraction();

    const newVisibleCards: Card[] = [];

    let commonCardIndex = 0;

    for (let i = 0; i < visibleCards.length; i += 1) {
      if (i === index) {
        newVisibleCards.push({
          ...mainCard,
          PreviewEmojiIcon: visibleCards[i]?.PreviewEmojiIcon,
        });
      } else {
        const card = commonCards[commonCardIndex];

        if (exists(card)) {
          newVisibleCards.push({
            ...card,
            PreviewEmojiIcon: initialVisibleCards[i]?.PreviewEmojiIcon,
          });
          commonCardIndex += 1;
        }
      }
    }

    setVisibleCards(newVisibleCards);
  }, [visibleCards, wasAnimationStarted, wasCardOpened, handleFormInteraction]);

  useEffect(() => {
    const timeouts: number[] = [];

    const shouldStartAnimation = visibleCards[0]
      && visibleCards[0].text
      && !wasAnimationStarted
      && !wasCardOpened;

    if (shouldStartAnimation) {
      setWasAnimationStarted(true);
      setWasCardOpened(true);

      // Need to wrap in setTimeout to avoid batched state updates
      const cardClickAnimationStartTimeoutId = window.setTimeout(() => {
        setVisibleCards((prevVisibleCards) => prevVisibleCards.map(
          (card) => {
            if (card.isMain) {
              return {
                ...card,
                isEnlarged: true,
              };
            }

            return card;
          },
        ));
      }, 0);

      const cardClickAnimationEndTimeoutId = window.setTimeout(() => {
        setVisibleCards((prevVisibleCards) => prevVisibleCards.map(
          (card) => {
            if (card.isMain) {
              return {
                ...card,
                isEnlarged: false,
              };
            }

            return card;
          },
        ));
      }, CARD_CLICK_ANIMATION_TIMEOUT);

      timeouts.push(
        cardClickAnimationStartTimeoutId,
        cardClickAnimationEndTimeoutId,
      );

      let timeoutMultiplier = 0;

      const startAnimation = () => {
        for (let i = 0; i < visibleCards.length; i += 1) {
          if (!visibleCards[i]!.isMain) {
            const timeoutId = window.setTimeout(() => {
              setVisibleCards((prevVisibleCards) => (
                prevVisibleCards.map((card, index) => {
                  if (index === i) {
                    return {
                      ...card,
                      isFlipped: true,
                    };
                  }

                  return card;
                })
              ));
            }, ((timeoutMultiplier
                * CARD_FLIP_ANIMATION_TIMEOUT)
              + CARD_CLICK_ANIMATION_TIMEOUT));

            timeoutMultiplier += 1;

            timeouts.push(timeoutId);
          }
        }

        const timeoutId = window.setTimeout(() => {
          setVisibleCards((prevVisibleCards) => (
            prevVisibleCards.map((card) => {
              if (card.isMain) {
                return {
                  ...card,
                  isFlipped: true,
                  isBig: true,
                };
              }

              return card;
            })
          ));
        }, ((timeoutMultiplier
            * CARD_FLIP_ANIMATION_TIMEOUT)
          + CARD_CLICK_ANIMATION_TIMEOUT));

        timeouts.push(timeoutId);
      };

      startAnimation();
    }

    return () => {
      if (wasAnimationStarted) {
        timeouts.forEach((timeoutId) => clearTimeout(timeoutId));
      }
    };
  }, [visibleCards, wasAnimationStarted, wasCardOpened, setWasCardOpened]);

  useEffect(() => {
    if (wasCardOpened && !wasAnimationStarted) {
      setVisibleCards([
        ...commonCards,
        {
          ...mainCard,
          isFlipped: true,
          isBig: true,
        },
      ]);
    }
  }, [wasCardOpened, wasAnimationStarted]);

  const { subDomain } = useSubDomainContext();

  return (
    <section
      id={id}
      className={cn({
        [section.scrollSection]: Boolean(id),
        'is-opened': wasCardOpened,
      })}
    >
      <div className="grid-container">
        <div className="grid-x grid-margin-x align-center">
          <div className={cn(styles.container, 'cell large-8')}>
            <h2 className={styles.title}>
              {t(`${I18N_CODES.course}:new_year_section_title`, {
                context: subDomain,
              })}
            </h2>

            <div className={styles.cards}>
              {visibleCards.map((card, index) => (
                <GiftCard
                  key={card.id}
                  card={card}
                  promoCodeSlug={promoCodeSlug}
                  PreviewEmojiIcon={card.PreviewEmojiIcon}
                  onClick={() => handleCardClick(index)}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});
