// TODO: update everything inside analytics instance
import { GoogleAnalyticsClient } from '@/controllers/analytics/analytics.client/GoogleAnalytics.client';
import {
  setAmplitudeUserId,
  setAmplitudeUserProperties,
} from '@/controllers/amplitude/amplitude.helpers';
import { errorHandler } from '@/core/ErrorHandler';
import { logger } from '@/core/Logger';
import {
  GrowthBookClient,
} from '@/components/services/GrowthBook/GrowthBook.client';
import { subDomainVar } from '@/controllers/subDomain/withSubDomainApp';
import { UserBaseAnalyticsInfoFragment } from '@/controllers/user/graphql/generated/UserBaseAnalyticsInfo.fragment.generated';

export const updateUserAnalyticsData = (
  userAnalyticsInfo: Omit<UserBaseAnalyticsInfoFragment, '__typename'>,
) => {
  GoogleAnalyticsClient.getInstance().setUser(userAnalyticsInfo);

  errorHandler.setUser(userAnalyticsInfo);

  logger.setUserId(userAnalyticsInfo.id);

  const utmData = GoogleAnalyticsClient.getInstance().getUserSourceData();

  setAmplitudeUserProperties({
    ...utmData,
    WebsiteDomain: subDomainVar(),
    Email: userAnalyticsInfo.email,
  });
  setAmplitudeUserId(userAnalyticsInfo.amplitudeId);

  // not passing auth user because it takes values from google analytics client
  GrowthBookClient.getInstance().updateUserAttributes();
};
