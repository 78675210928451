import React, { FC, SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const NavyEllipse: FC<Props> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="328" height="328" viewBox="0 0 328 328" fill="none">
    <g filter="url(#filter0_f_28471_21028)">
      <circle cx="164" cy="164" r="100" fill="url(#paint0_linear_28471_21028)" />
    </g>
    <defs>
      <filter id="filter0_f_28471_21028" x="0" y="0" width="328" height="328" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="32" result="effect1_foregroundBlur_28471_21028" />
      </filter>
      <linearGradient id="paint0_linear_28471_21028" x1="206.857" y1="391.679" x2="78" y2="54" gradientUnits="userSpaceOnUse">
        <stop stopColor="#225FFC" />
        <stop offset="1" stopColor="#225FFC" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
