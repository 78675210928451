import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconStarCelebrate: FCIcon = (props) => (
  <BaseIcon
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M23.4921 17.2058C21.1852 16.5059 19.3881 14.7088 18.6868 12.3991C18.501 11.7914 17.493 11.7914 17.3087 12.3991C16.606 14.7088 14.8088 16.5059 12.5019 17.2058C12.1995 17.2979 11.9922 17.5773 11.9922 17.8955C11.9922 18.2109 12.1995 18.4917 12.5019 18.5838C14.8088 19.2851 16.606 21.0822 17.3087 23.3906C17.4009 23.6944 17.6802 23.9003 17.997 23.9003C18.3138 23.9003 18.5932 23.6944 18.6868 23.3906C19.3881 21.0822 21.1852 19.2851 23.4921 18.5838C23.7945 18.4917 24.0018 18.2109 24.0018 17.8955C24.0018 17.5773 23.7945 17.2979 23.4921 17.2058Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.8263 13.5514C22.5498 13.5514 22.2719 13.4463 22.0616 13.2331C21.6397 12.8098 21.6412 12.1272 22.0645 11.7067L29.5007 4.29938C29.924 3.8789 30.608 3.8789 31.0285 4.30226C31.449 4.72562 31.4476 5.40962 31.0256 5.8301L23.588 13.236C23.3778 13.4463 23.1013 13.5514 22.8263 13.5514Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.29 8.98391C21.1287 8.98391 20.9646 8.94791 20.8105 8.87159C20.2763 8.60519 20.0588 7.95719 20.3238 7.42295L21.8847 4.27943C22.1497 3.74519 22.8006 3.53063 23.3319 3.79415C23.8662 4.05911 24.0836 4.70711 23.8187 5.24135L22.2577 8.38487C22.0691 8.76503 21.6875 8.98391 21.29 8.98391Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M27.9085 14.4399C27.4793 14.4399 27.0718 14.1821 26.9048 13.7573C26.6845 13.2044 26.9552 12.5765 27.5096 12.3562L30.5609 11.1452C31.1125 10.9249 31.7432 11.1941 31.9635 11.7514C32.1838 12.3058 31.9117 12.9322 31.3587 13.1525L28.3073 14.3636C28.1763 14.4154 28.0409 14.4399 27.9085 14.4399Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.173 13.5514C12.8979 13.5514 12.6214 13.4463 12.4112 13.236L4.97359 5.8301C4.55167 5.40962 4.55023 4.72562 4.97071 4.30226C5.39263 3.8789 6.07519 3.8789 6.49855 4.29938L13.9347 11.7067C14.3581 12.1272 14.3595 12.8098 13.9376 13.2331C13.7274 13.4463 13.4494 13.5514 13.173 13.5514Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.7118 8.98328C14.3144 8.98328 13.9328 8.76439 13.7441 8.38423L12.1832 5.24072C11.9182 4.70648 12.1356 4.05848 12.6699 3.79352C13.2027 3.53144 13.8521 3.74456 14.1171 4.2788L15.6781 7.42232C15.943 7.95656 15.7256 8.60456 15.1913 8.87095C15.0372 8.94728 14.8731 8.98328 14.7118 8.98328Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.09246 14.4399C7.95998 14.4399 7.82462 14.4154 7.69358 14.3636L4.64222 13.1525C4.08926 12.9322 3.8171 12.3058 4.03742 11.7514C4.25774 11.1941 4.88558 10.9249 5.43998 11.1452L8.49134 12.3562C9.04574 12.5765 9.31646 13.2044 9.09614 13.7573C8.9291 14.1821 8.52158 14.4399 8.09246 14.4399Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.2628 31.8072C29.9878 31.8072 29.7113 31.7021 29.501 31.4919L22.0634 24.086C21.6415 23.6655 21.6401 22.9815 22.0606 22.5581C22.481 22.1348 23.165 22.1348 23.5884 22.5552L31.0246 29.9626C31.4479 30.3831 31.4494 31.0656 31.0274 31.489C30.8172 31.7021 30.5393 31.8072 30.2628 31.8072Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.8524 32.1093C22.455 32.1093 22.0734 31.8904 21.8847 31.5103L20.3238 28.3682C20.0588 27.8339 20.2763 27.1859 20.8105 26.9195C21.3433 26.6589 21.9913 26.872 22.2577 27.4063L23.8187 30.5483C24.0836 31.0826 23.8662 31.7306 23.3319 31.997C23.1779 32.0733 23.0137 32.1093 22.8524 32.1093Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.9596 24.7212C30.8272 24.7212 30.6918 24.6967 30.5608 24.6448L27.5094 23.4338C26.9564 23.2135 26.6843 22.5871 26.9046 22.0327C27.1249 21.4754 27.7513 21.2061 28.3072 21.4264L31.3585 22.6375C31.9129 22.8578 32.1836 23.4856 31.9633 24.0386C31.7963 24.4634 31.3888 24.7212 30.9596 24.7212Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.73643 31.8072C5.45995 31.8072 5.18203 31.7021 4.97179 31.489C4.54987 31.0656 4.55131 30.3831 4.97467 29.9626L12.4108 22.5552C12.8342 22.1348 13.5168 22.1348 13.9387 22.5581C14.3592 22.9815 14.3577 23.6655 13.9358 24.086L6.49819 31.4919C6.28795 31.7021 6.01147 31.8072 5.73643 31.8072Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.1494 32.1099C12.9881 32.1099 12.824 32.0739 12.6699 31.9976C12.1356 31.7312 11.9182 31.0832 12.1832 30.549L13.7441 27.4069C14.0091 26.8727 14.66 26.6581 15.1913 26.9202C15.7256 27.1866 15.943 27.8346 15.6781 28.3688L14.1171 31.5109C13.9284 31.8911 13.5468 32.1099 13.1494 32.1099Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.04128 24.7212C4.61216 24.7212 4.20464 24.4634 4.0376 24.0386C3.81728 23.4856 4.088 22.8578 4.6424 22.6375L7.69376 21.4264C8.24672 21.2061 8.876 21.4754 9.09632 22.0327C9.31664 22.5871 9.04448 23.2135 8.49152 23.4338L5.44016 24.6448C5.30912 24.6967 5.17376 24.7212 5.04128 24.7212Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M17.998 6.60326C17.4018 6.60326 16.918 6.11942 16.918 5.52326C16.918 4.9271 17.4018 4.4375 17.998 4.4375C18.5941 4.4375 19.078 4.91414 19.078 5.5103V5.52326C19.078 6.11942 18.5941 6.60326 17.998 6.60326Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M29.9277 18.9895C29.3315 18.9895 28.8477 18.5056 28.8477 17.9095C28.8477 17.3133 29.3315 16.8223 29.9277 16.8223C30.5238 16.8223 31.0077 17.2989 31.0077 17.8951V17.9095C31.0077 18.5056 30.5238 18.9895 29.9277 18.9895Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M17.998 31.3547C17.4018 31.3547 16.918 30.8766 16.918 30.2805V30.2675C16.918 29.6713 17.4018 29.1875 17.998 29.1875C18.5941 29.1875 19.078 29.6713 19.078 30.2675C19.078 30.8637 18.5941 31.3547 17.998 31.3547Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.07219 18.9895C5.47603 18.9895 4.99219 18.5056 4.99219 17.9095C4.99219 17.3133 5.47603 16.8223 6.07219 16.8223C6.66835 16.8223 7.15219 17.2989 7.15219 17.8951V17.9095C7.15219 18.5056 6.66835 18.9895 6.07219 18.9895Z" fill="white" />
  </BaseIcon>
);
