import React from 'react';
import { cn } from '@/lib/classNames';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import styles from '@/components/ui/icons/product/Mate/IconMateLogoSquare.module.scss';

export const IconMateLogoSquareNewYear: FCIcon = ({ className, ...rest }) => (
  <BaseIcon
    className={cn(className, styles.logoIcon)}
    width="69"
    height="56"
    viewBox="0 0 69 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g id="mate-logo-square-fill">
      <path d="M34.1998 30.7143C34.1998 32.5 35.3983 33.619 37.0522 33.619C38.7061 33.619 39.9046 32.5 39.9046 30.7143C39.9046 28.9286 38.7061 27.8095 37.0522 27.8095C35.3983 27.8095 34.1998 28.9286 34.1998 30.7143Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.30792 6.55211C0 9.11905 0 12.4794 0 19.2V36.8C0 43.5206 0 46.8809 1.30792 49.4479C2.4584 51.7058 4.29417 53.5416 6.55211 54.6921C9.11905 56 12.4794 56 19.2 56H36.8C43.5206 56 46.8809 56 49.4479 54.6921C51.7058 53.5416 53.5416 51.7058 54.6921 49.4479C56 46.8809 56 43.5206 56 36.8V19.2C56 12.4794 56 9.11905 54.6921 6.55211C53.5416 4.29417 51.7058 2.4584 49.4479 1.30792C46.8809 0 43.5206 0 36.8 0H19.2C12.4794 0 9.11905 0 6.55211 1.30792C4.29417 2.4584 2.4584 4.29417 1.30792 6.55211ZM25.4435 20H29.2786V36.6667H25.4435V26.9762L21.105 34.0476H20.6736L16.3351 26.9762V36.6667H12.5V20H16.3351L20.8893 27.4048L25.4435 20ZM39.9046 25.881V24.7619H43.5V36.6667H39.9046V35.5476C39.1136 36.4524 37.9391 37 36.3331 37C33.1931 37 30.6044 34.2619 30.6044 30.7143C30.6044 27.1667 33.1931 24.4286 36.3331 24.4286C37.9391 24.4286 39.1136 24.9762 39.9046 25.881Z" />
    </g>

    <g clipPath="url(#clip0_22905_1585)">
      <path d="M43.8852 46.7427H54.1974V56.6012H43.8852V46.7427Z" fill="#C96928" />
      <path d="M43.8853 48.0147H47.2332V56.6012H43.8853V48.0147Z" fill="#9B4912" />
      <path d="M66.6717 45.1845L62.0616 38.8856C63.6623 37.9088 64.293 35.6668 63.0397 33.9546L57.6214 26.5509C58.6899 25.429 58.9702 23.6132 57.9157 22.1719L51.7922 13.8053C50.4304 11.9446 47.6521 11.9446 46.2903 13.8053L40.1667 22.1719C39.1122 23.6132 39.3922 25.429 40.4611 26.5509L35.0428 33.9546C33.7894 35.6668 34.4201 37.9088 36.0205 38.8856L31.4107 45.1845C29.7624 47.4367 31.371 50.6068 34.1619 50.6068H63.9206C66.7114 50.6068 68.32 47.4367 66.6717 45.1845Z" fill="#66A549" />
      <path d="M49.0408 12.4102C48.5782 12.4102 48.115 12.8745 47.8102 13.805L45.0689 22.1719C44.5968 23.6129 44.7219 25.429 45.2007 26.5506L42.7759 33.9543C42.2148 35.6665 42.4969 37.9092 43.2133 38.8856L41.1507 45.1848C40.4118 47.4367 41.1323 50.6068 42.3813 50.6068H34.1616C31.3707 50.6068 29.7625 47.4367 31.4108 45.1848L36.0209 38.8856C34.4195 37.9088 33.7888 35.6665 35.0425 33.9543L40.4608 26.5506C39.3926 25.429 39.1116 23.6125 40.1665 22.1719L46.29 13.805C46.971 12.8745 48.0059 12.4102 49.0408 12.4102Z" fill="#477C27" />
      <path d="M39.4174 50.2089C39.4174 50.8767 38.8763 51.4177 38.2089 51.4177C37.541 51.4177 37 50.8767 37 50.2089C37 49.5414 37.541 49 38.2089 49C38.8763 49 39.4174 49.5414 39.4174 50.2089Z" fill="#DAF3FE" />
      <path d="M56.9713 42.7725C56.9713 43.44 56.4299 43.981 55.7624 43.981C55.0946 43.981 54.5535 43.44 54.5535 42.7725C54.5535 42.1047 55.0946 41.5636 55.7624 41.5636C56.4299 41.5636 56.9713 42.1047 56.9713 42.7725Z" fill="#DAF3FE" />
      <path d="M62.2506 15.8699C62.2506 16.5378 61.7096 17.0788 61.0421 17.0788C60.3743 17.0788 59.8333 16.5378 59.8333 15.8699C59.8333 15.2025 60.3743 14.6614 61.0421 14.6614C61.7096 14.6614 62.2506 15.2025 62.2506 15.8699Z" fill="#9EC2CC" />
      <path d="M34.6014 17.8214C34.4294 17.8214 34.2578 17.7558 34.1266 17.6246C33.8642 17.3626 33.8642 16.9374 34.1266 16.675L35.6461 15.1556C35.9084 14.8932 36.3336 14.8932 36.5956 15.1556C36.858 15.4179 36.858 15.8431 36.5956 16.1051L35.0761 17.6246C34.9453 17.7558 34.7733 17.8214 34.6014 17.8214Z" fill="#DAF3FE" />
      <path d="M36.1209 17.8214C35.9489 17.8214 35.7773 17.7558 35.6461 17.6246L34.1266 16.1051C33.8642 15.8431 33.8642 15.4179 34.1266 15.1556C34.389 14.8932 34.8141 14.8932 35.0761 15.1556L36.5956 16.675C36.858 16.9374 36.858 17.3626 36.5956 17.6246C36.4644 17.7558 36.2928 17.8214 36.1209 17.8214Z" fill="#DAF3FE" />
      <path d="M50.6716 33.8627C50.4996 33.8627 50.3276 33.7971 50.1968 33.6659C49.9344 33.4035 49.9344 32.9784 50.1968 32.716L51.7159 31.1965C51.9783 30.9345 52.4035 30.9345 52.6658 31.1965C52.9278 31.4589 52.9278 31.884 52.6658 32.1464L51.1463 33.6659C51.0151 33.7971 50.8432 33.8627 50.6716 33.8627Z" fill="#DAF3FE" />
      <path d="M52.191 33.8627C52.0191 33.8627 51.8471 33.7971 51.7159 33.6659L50.1968 32.1464C49.9344 31.884 49.9344 31.4589 50.1968 31.1965C50.4588 30.9345 50.8843 30.9345 51.1463 31.1965L52.6658 32.716C52.9278 32.9784 52.9278 33.4035 52.6658 33.6659C52.5346 33.7971 52.3627 33.8627 52.191 33.8627Z" fill="#DAF3FE" />
      <path d="M64.0592 27.9402C63.8876 27.9402 63.7157 27.8746 63.5845 27.7434C63.3221 27.4811 63.3221 27.0559 63.5845 26.7939L65.104 25.2744C65.3663 25.012 65.7915 25.012 66.0535 25.2744C66.3159 25.5364 66.3159 25.9616 66.0535 26.224L64.534 27.7434C64.4032 27.8743 64.2312 27.9402 64.0592 27.9402Z" fill="#9EC2CC" />
      <path d="M65.5787 27.9402C65.4068 27.9402 65.2351 27.8746 65.104 27.7434L63.5845 26.224C63.3221 25.9616 63.3221 25.5364 63.5845 25.2744C63.8468 25.012 64.272 25.012 64.534 25.2744L66.0535 26.7939C66.3159 27.0559 66.3159 27.4811 66.0535 27.7434C65.9227 27.8743 65.7507 27.9402 65.5787 27.9402Z" fill="#9EC2CC" />
    </g>

    <g id='mate-logo-square-text'>
      <path d="M29.2786 20H25.4435L20.8893 27.4048L16.3351 20H12.5V36.6667H16.3351V26.9762L20.6736 34.0476H21.105L25.4435 26.9762V36.6667H29.2786V20Z" />
      <path d="M39.9046 24.7619V25.881C39.1136 24.9762 37.9391 24.4286 36.3331 24.4286C33.1931 24.4286 30.6044 27.1667 30.6044 30.7143C30.6044 34.2619 33.1931 37 36.3331 37C37.9391 37 39.1136 36.4524 39.9046 35.5476V36.6667H43.5V24.7619H39.9046ZM37.0522 33.619C35.3983 33.619 34.1998 32.5 34.1998 30.7143C34.1998 28.9286 35.3983 27.8095 37.0522 27.8095C38.7061 27.8095 39.9046 28.9286 39.9046 30.7143C39.9046 32.5 38.7061 33.619 37.0522 33.619Z" />
    </g>
    <defs>
      <clipPath id="clip0_22905_1585">
        <rect width="42" height="44.5455" fill="white" transform="translate(26.1818 11.4545)" />
      </clipPath>
    </defs>

  </BaseIcon>
);
