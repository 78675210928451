import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconNotebookDuotone: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 16.1448V4.99881C2 3.90081 2.886 3.00881 3.982 3.07581C4.959 3.13581 6.113 3.25481 7 3.48881C8.05 3.76481 9.296 4.35481 10.282 4.87681C10.8112 5.15428 11.4027 5.29164 12 5.27581V20.4758C11.4322 20.4761 10.8731 20.3367 10.372 20.0698C9.372 19.5378 8.082 18.9198 7 18.6348C6.123 18.4028 4.984 18.2848 4.015 18.2238C2.906 18.1538 2 17.2568 2 16.1448Z"
      fill="#939BAE"
    />
    <path
      d="M22 16.1427V4.93275C22 3.85975 21.154 2.97975 20.082 3.01675C18.953 3.05675 17.547 3.17275 16.5 3.48675C15.592 3.75775 14.535 4.30275 13.674 4.80175C13.1646 5.09743 12.5888 5.25978 12 5.27375V20.4738C12.56 20.4738 13.121 20.3378 13.628 20.0678C14.628 19.5358 15.918 18.9178 17 18.6328C17.877 18.4008 19.016 18.2828 19.985 18.2218C21.094 18.1518 22 17.2547 22 16.1427Z"
      fill="#939BAE"
    />
  </BaseIcon>
);
