import React, { memo } from 'react';
import { cn } from '@/lib';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { AnchorElement } from '@/components/ui/Button/AnchorElement';
import { FactBlockIcon } from '../../FactBlock/FactBlockIcon';
import styles from '../ReviewsSection.module.scss';

interface Props {
  href: string;
  Icon: FCIcon;
  className: string;
  description: string;
}

export const ReviewsCard = memo<Props>((props) => {
  const {
    href,
    Icon,
    className,
    description,
  } = props;

  return (
    <AnchorElement
      href={href}
      target='_blank'
    >
      <FactBlockIcon
        Icon={Icon}
        className={cn(styles.purple, className)}
        description={description}
      />
    </AnchorElement>
  );
});
