import React, { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import { cn } from '@/lib/classNames';
import FormStyles from '@/components/ui/FormElements/FormInputs/FormInputs.module.scss';
import { withFinalFormController } from '@/controllers/forms/forms.hocs/withFinalFormController';
import { makeValidator } from '@/controllers/forms/forms.validator';
import { isTelegramProfileNickname } from '@/controllers/forms/forms.validator/validationRules';

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const InputTelegramUI: FC<Props> = (
  props,
) => {
  const { className, ...rest } = props;

  return (
    <input
      className={cn(className, FormStyles.input)}
      type="text"
      {...rest}
    />
  );
};

export const InputTelegram = withFinalFormController<string>(
  {
    validate: makeValidator(isTelegramProfileNickname, 'validate_telegram'),
  },
)<Props>(InputTelegramUI);
