import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconErrorForm: FCIcon = (props) => (
  <BaseIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.00001 1.33337C4.32001 1.33337 1.33334 4.32004 1.33334 8.00004C1.33334 11.68 4.32001 14.6667 8.00001 14.6667C11.68 14.6667 14.6667 11.68 14.6667 8.00004C14.6667 4.32004 11.68 1.33337 8.00001 1.33337ZM8.66668 11.3334H7.33334V10H8.66668V11.3334ZM8.66668 8.66671H7.33334V4.66671H8.66668V8.66671Z"
      fill="currentColor"
    />
  </BaseIcon>

);
