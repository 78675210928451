import { useEffect, useMemo, useState } from 'react';
import CodesMap from 'react-phone-input-2/lang/es.json';
import { DEFAULT_SUB_DOMAIN } from '@/middleware/i18n/i18n.config';
import { useUserCountryByIpQuery } from '@/controllers/user/graphql/generated/userCountryByIp.query.generated';

type UseCountryCodeOutput = [
  string,
  (newCountryCode: string) => void,
];

export const useCountryCodeByIp = (): UseCountryCodeOutput => {
  const supportedCodes = useMemo(() => Object.keys(CodesMap), []);
  const [countryCode, setCountryCode] = useState<string>(DEFAULT_SUB_DOMAIN);
  const { data } = useUserCountryByIpQuery();

  useEffect(() => {
    const countryOnLowerCase = (data?.userCountryByIp || DEFAULT_SUB_DOMAIN)
      .toLowerCase();
    const hasInputSupportForCountry = supportedCodes
      .includes(countryOnLowerCase);

    if (hasInputSupportForCountry) {
      setCountryCode(countryOnLowerCase);
    }
  }, [data?.userCountryByIp, setCountryCode, supportedCodes]);

  return [countryCode, setCountryCode];
};
