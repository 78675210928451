import React, { FC, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { FormField } from '@/components/ui/FormElements/FormField';
import { makeValidator } from '@/controllers/forms/forms.validator';
import { isNotEmpty } from '@/controllers/forms/forms.validator/validationRules';
import { InputText } from '@/components/ui/FormElements/FormInputs/InputText';
import { ConsultationFormFields } from '@/components/courseLanding/CourseConsultationForm/ConsultationForm.typedefs';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { getTranslateCodeByDomain } from '@/lib/helpers/getTranslateCodeByDomain';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { useEnteredUserValue } from '@/controllers/consultation/useEnteredUserValue';
import { EnteredUserInfoField } from '@/controllers/consultation/consultation.typedefs';

export const ConsultationName: FC = () => {
  const fieldName = 'name';
  const { t } = useTranslation([I18N_CODES.courseConsultation]);
  const { submitting } = useFormState();
  const { subDomain } = useSubDomainContext();
  const [authUser] = useAuthUser({ ssr: false });
  const form = useForm<ConsultationFormFields>();

  const [savedUserName] = useEnteredUserValue(EnteredUserInfoField.Name);

  useEffect(() => {
    const userName = authUser?.firstName || savedUserName;

    if (userName) {
      form.change(fieldName, userName);
    }
  }, [authUser?.firstName, form, savedUserName]);

  const placeholderNameCodeByDomain = getTranslateCodeByDomain(
    `${I18N_CODES.courseConsultation}:consultation_form_first_name_placeholder`,
    subDomain,
  );

  return (
    <FormField
      label={{
        for: 'consultation-name',
        text: t(`${I18N_CODES.courseConsultation}:consultation_form_first_name`),
      }}
      renderInput={(inputProps) => (
        <InputText
          config={{
            validate: makeValidator(
              isNotEmpty,
              t(`${I18N_CODES.courseConsultation}:consultation_form_required_first_name`),
            ),
          }}
          {...inputProps}
          placeholder={t(placeholderNameCodeByDomain)}
          required
          autoComplete="name"
          data-qa="consultation-name"
        />
      )}
      disabled={submitting}
      name={fieldName}
    />
  );
};
