import React, {
  FC, useCallback, useEffect, useState, memo,
} from 'react';
import { useForm } from 'react-final-form';
import { PromoCodeStatus } from '@/controllers/graphql/generated';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { Button } from '@/components/ui/Button';
import { IconPriceTag } from '@/components/ui/icons/IconPriceTag';
import { FormField } from '@/components/ui/FormElements/FormField';
import {
  RequestStatus,
  InputStep,
} from '@/components/courseLanding/CourseConsultationForm/components/ConsultationPromoCode/ConsultationPromoCode.typedefs';
import {
  ConsultationFormFields,
} from '@/components/courseLanding/CourseConsultationForm/ConsultationForm.typedefs';
import { PromoCodeLandingFragment } from '@/controllers/promoCode/generated/promoCodeLanding.fragment.generated';
import { useLazyFindPromoCodeBySlug } from '@/controllers/promoCode/promoCode.hooks/useLazyFindPromoCodeBySlug';
import {
  AnimationDelayMS,
} from '@/components/courseLanding/CourseConsultationForm/components/ConsultationPromoCode/ConsultationPromoCode.constants';
import {
  PromoCodeInputSubmitted,
} from '@/components/courseLanding/CourseConsultationForm/components/ConsultationPromoCode/PromoCodeComponents/PromoCodeInputSubmitted';
import {
  PromoCodeInputOpened,
} from '@/components/courseLanding/CourseConsultationForm/components/ConsultationPromoCode/PromoCodeComponents/PromoCodeInputOpened';
import { useRouterQuery } from '@/hooks/useRouterQuery';
import { RegistrationQuery } from '@/components/consultation/consultation.typedefs';
import styles from './ConsultationPromoCode.module.scss';

export interface PromoCodeProps {
  setPromoCode: (promoCode: PromoCodeLandingFragment | null) => void;
  setShouldShowTopBlock: (shouldShowTopBlock: boolean) => void;
}

export const ConsultationPromoCode: FC<PromoCodeProps> = memo((props) => {
  const {
    setPromoCode: setFormPromoCode,
    setShouldShowTopBlock,
  } = props;

  const {
    promoCode: queryPromoCodeSlugDeprecated,
    promo_code: queryPromoCodeSlug = queryPromoCodeSlugDeprecated || '',
  } = useRouterQuery<RegistrationQuery>();

  const fieldName = 'promoCode';

  const { t } = useTranslation([I18N_CODES.registration]);
  const form = useForm<ConsultationFormFields>();

  const [inputStep, setInputStep] = useState<InputStep>(InputStep.Closed);
  const [requestStatus, setRequestStatus] = useState<
    RequestStatus | null
  >(null);
  const [message, setMessage] = useState(null);

  const { fetchPromoCode, loading } = useLazyFindPromoCodeBySlug();

  const hidePromoBlock = useCallback(() => {
    setShouldShowTopBlock(false);
    setTimeout(() => {
      setFormPromoCode(null);
    }, AnimationDelayMS);
  }, [setShouldShowTopBlock, setFormPromoCode]);

  const clearInputStatus = useCallback(() => {
    setMessage(null);
    setRequestStatus(null);
  }, [setMessage, setRequestStatus]);

  const handleOnInputChange = useCallback(() => {
    setInputStep(InputStep.Opened);
    clearInputStatus();
    hidePromoBlock();
  }, [clearInputStatus, hidePromoBlock]);

  const handleSubmit = useCallback(async (promoCodeSlug?: string) => {
    if (!promoCodeSlug) {
      setMessage(t(`${I18N_CODES.registration}:promo_code_empty`));
      setRequestStatus(RequestStatus.Error);
      hidePromoBlock();

      return;
    }

    const { promoCode } = await fetchPromoCode(promoCodeSlug);

    setInputStep(InputStep.Submitted);

    if (!promoCode) {
      setMessage(t(`${I18N_CODES.registration}:promo_code_wrong`));
      setRequestStatus(RequestStatus.Error);
      hidePromoBlock();

      return;
    }

    if (promoCode.status === PromoCodeStatus.Expired) {
      setMessage(t(`${I18N_CODES.registration}:promo_code_expired`));
      setRequestStatus(RequestStatus.Error);
      hidePromoBlock();

      return;
    }

    if (promoCode.status === PromoCodeStatus.Inactive) {
      setMessage(t(`${I18N_CODES.registration}:promo_code_inactive`));
      setRequestStatus(RequestStatus.Error);
      hidePromoBlock();

      return;
    }

    setMessage(t(`${I18N_CODES.registration}:promo_code_active`));
    setRequestStatus(RequestStatus.Success);

    setShouldShowTopBlock(true);
    setFormPromoCode(promoCode);
  }, [
    hidePromoBlock,
    setShouldShowTopBlock,
    fetchPromoCode,
    setMessage,
    setFormPromoCode,
    t,
  ]);

  const handleInputOpen = useCallback(() => {
    setInputStep(InputStep.Opened);
  }, []);

  const handleClear = useCallback(() => {
    setInputStep(InputStep.Opened);

    form.change(fieldName, '');
    clearInputStatus();
    hidePromoBlock();
  }, [
    clearInputStatus,
    hidePromoBlock,
    form,
  ]);

  const [isAutoSubmitted, setIsAutoSubmitted] = useState(false);

  useEffect(() => {
    if (queryPromoCodeSlug && !isAutoSubmitted) {
      setInputStep(InputStep.Opened);
      form.change(fieldName, queryPromoCodeSlug as string);
      setIsAutoSubmitted(true);

      handleSubmit(queryPromoCodeSlug as string);
    }
  }, [queryPromoCodeSlug, isAutoSubmitted, form, handleSubmit]);

  if (inputStep === InputStep.Closed) {
    return (
      <Button
        data-qa='consultation-promo-code-button'
        text={t(`${I18N_CODES.registration}:have_a_promo_code`)}
        renderLeftIcon={() => <IconPriceTag className={styles.tagIcon} />}
        onClick={handleInputOpen}
        className={styles.enterPromoButton}
      />
    );
  }

  const inputDataQa = 'consultation-promo-code-input';

  return (
    <FormField
      name={fieldName}
      label={{
        for: fieldName,
        text: t(`${I18N_CODES.registration}:promo_code_input`),
      }}
      renderInput={(inputProps) => (
        (inputStep === InputStep.Submitted)
          ? (
            <PromoCodeInputSubmitted
              inputProps={inputProps}
              requestStatus={requestStatus}
              handleOnInputChange={handleOnInputChange}
              handleClear={handleClear}
              message={message}
              dataQa={inputDataQa}
            />
          )
          : (
            <PromoCodeInputOpened
              inputProps={{ ...inputProps }}
              requestStatus={requestStatus}
              handleOnInputChange={handleOnInputChange}
              handleSubmit={() => {
                handleSubmit(form.getFieldState(fieldName)?.value);
              }}
              loading={loading}
              message={message}
              dataQa={inputDataQa}
            />
          )
      )}
    />
  );
});
