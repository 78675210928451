import React, { memo } from 'react';
import { SwiperSlide } from 'swiper/react';
import { cn } from '@/lib';
import { I18N_CODES } from '@/lib/constants/general';
import { typography } from '@/components/ui/typography';
import { useTranslation } from '@/middleware/i18n';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useCtaContext } from '@/controllers/cta/Cta.context';
import { IconHeartList } from '@/components/ui/icons/IconHeartList';
import { SubDomains } from '@/constants/subdomains';
import { Review } from '@/components/professionLanding/ProfessionLanding.typedefs';
import { useIsMinimalWidth } from '@/hooks/useIsMinimalWidth';
import { MINIMAL_TABLET_WIDTH } from '@/constants/breakpoints';
import { SectionBase, SectionBaseType } from '@/components/landing/BaseSectionWrappers/SectionBase';
import { ContentContainer } from '@/components/landing/BaseSectionWrappers/ContentContainer';
import { COURSE_BLOCKS } from '@/components/courseLanding/CoursePage/CoursePageContent.constants';
import { ReviewCard } from './components/ReviewCard';
import { CardsSlider } from '../CardsSlider/CardsSlider';
import { ReviewsCard } from './components/ReviewsCard';
import styles from './ReviewsSection.module.scss';

interface Props {
  reviews: Review[];
}

export const ReviewsSection = memo<Props>((props) => {
  const {
    reviews,
  } = props;

  const { t } = useTranslation([I18N_CODES.course]);

  const { buildHref } = useCtaContext();
  const { subDomain } = useSubDomainContext();

  const isSmallScreen = useIsMinimalWidth(MINIMAL_TABLET_WIDTH);
  const href = buildHref({ source: 'graduates_reviews' });
  const isPlDomain = subDomain === SubDomains.pl;
  const isUsaDomain = subDomain === SubDomains.usa;

  return (
    <SectionBase
      id={COURSE_BLOCKS.reviews}
      type={SectionBaseType.Default}
    >
      <ContentContainer>
        <div className={cn(styles.headingWrapper, 'mb-56')}>
          <div className={styles.titleWrapper}>
            <p className={cn(typography.landingSectionHeading, 'mb-16 small-mb-12')}>
              {t(`${I18N_CODES.course}:profession_reviews_heading`)}
            </p>

            <h2 className={typography.newLandingH2}>
              {t(`${I18N_CODES.course}:profession_reviews_title`)}
            </h2>
          </div>

          <Button
            className={styles.ctaButton}
            href={href}
            text={t(`${I18N_CODES.cta}:profession_cover_cta_button`)}
            mode={ButtonMode.BrandPrimary}
            size={ButtonSize.Large}
          />
        </div>

        {isSmallScreen
          ? (
            <CardsSlider className='mb-56'>
              {reviews.map((review) => (
                <SwiperSlide key={review.id} className={styles.slide}>
                  <ReviewCard
                    author={t(review.author)}
                    review={t(review.text)}
                    reviewType={review.reviewType}
                    reviewLink={review.reviewLink
                      ? t(review.reviewLink)
                      : undefined}
                    imageData={review.imageData}
                  />
                </SwiperSlide>
              ))}
            </CardsSlider>
          )
          : (
            <div className={cn(styles.reviews, 'cell gap-16 mb-56')}>
              <ReviewsCard
                href={isPlDomain || isUsaDomain
                  ? t(`${I18N_CODES.course}:profession_reviews_google_maps_link`)
                  : t(`${I18N_CODES.course}:profession_reviews_dou_link`)}
                Icon={IconHeartList}
                className={styles.heartIcon}
                description={t(`${I18N_CODES.course}:profession_reviews_count`, {
                  context: subDomain,
                })}
              />

              {reviews.map((review) => (
                <ReviewCard
                  key={review.id}
                  author={t(review.author)}
                  review={t(review.text)}
                  reviewType={review.reviewType}
                  reviewLink={review.reviewLink
                    ? t(review.reviewLink)
                    : undefined}
                  imageData={review.imageData}
                />
              ))}
            </div>
          )}
      </ContentContainer>
    </SectionBase>
  );
});
