import { useCallback } from 'react';
import { logger } from '@/core/Logger';
import { isBrowser } from '@/lib/helpers/isBrowser';
import {
  GoogleAnalyticsClient,
} from '@/controllers/analytics/analytics.client/GoogleAnalytics.client';
import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { getAnalyticsParams } from '@/controllers/analytics/analytics.utils/getAnalyticsParams';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { getCookieValueByKey } from '@/controllers/analytics/analytics.utils/getCookieValueByKey';
import { FB_FORMATTED_CLICK_ID_COOKIE_NAME } from '@/controllers/analytics/analytics.constants';

export const useCollectSignUpData = () => {
  const { subDomain, language, productName } = useSubDomainContext();
  const [localStorageReferralCode] = useLocalStorage(LOCAL_STORAGE_KEYS.referralCode, '');

  return useCallback(() => {
    const userUtm = GoogleAnalyticsClient
      .getInstance(productName)
      .getUserSourceData();
    const analyticsParams = getAnalyticsParams();

    const storedFbc = getCookieValueByKey(FB_FORMATTED_CLICK_ID_COOKIE_NAME);

    if (window.sbjs && storedFbc?.includes('https://')) {
      const userEntrancePoint = window.sbjs.get.first_add.ep;

      logger.child('analyticsParams').warn({
        message: '[FB Analytics]: fbc parameter contains first visit URL',
        fbc: storedFbc,
        userEntrancePoint,
      });
    }

    const referralCode = isBrowser
      ? localStorageReferralCode
      : '';

    return {
      ...userUtm,
      ...analyticsParams,
      referralCode,
      locale: language,
      domain: subDomain,
    };
  }, [language, subDomain, localStorageReferralCode, productName]);
};
