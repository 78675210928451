import React, { FC } from 'react';
import { SectionBase, SectionBaseType } from '@/components/landing/BaseSectionWrappers/SectionBase';
import { I18N_CODES } from '@/lib/constants/general';
import { ContentContainer } from '@/components/landing/BaseSectionWrappers/ContentContainer';
import { useTranslation } from '@/middleware/i18n';
import { IconImage } from '@/components/ui/Image/IconImage';
import imageData from '@/images/generated/professionLanding.freeForCombatants.s3Key';
import ceoImageData from '@/images/generated/professionLanding.romaBw.s3Key';
import { Button } from '@/components/ui/Button';
import { cn } from '@/lib';
import styles from './FreeForCombatantsSection.module.scss';

export const FreeForCombatantsSection: FC = () => {
  const { t } = useTranslation([I18N_CODES.home, I18N_CODES.common]);

  return (
    <SectionBase type={SectionBaseType.Default}>
      <ContentContainer className={styles.container}>
        <div>
          <h2 className={cn(styles.title, 'mb-24 small-mb-16')}>
            {t(`${I18N_CODES.home}:free_for_combatants.title`)}
          </h2>

          <p className={cn(styles.description, 'mb-24 small-mb-16')}>
            {t(`${I18N_CODES.home}:free_for_combatants.description`)}
          </p>

          <div className={cn(styles.ceoContentWrapper, 'mb-24')}>
            <IconImage
              imageData={ceoImageData}
              width={48}
              height={48}
            />

            <div className={styles.ceoTextWrapper}>
              <span className={styles.ceoName}>
                {t(`${I18N_CODES.common}:ceo_name`)}
              </span>

              <span className={styles.ceoTitle}>
                {t(`${I18N_CODES.common}:ceo_title`)}
              </span>
            </div>
          </div>

          <Button
            mode={Button.mode.Neutral}
            size={Button.size.Large}
            className={styles.button}
            target="_blank"
            href={t(`${I18N_CODES.common}:free_courses_for_combatants_article_link`)}
            text={t(`${I18N_CODES.home}:free_for_combatants.button`)}
          />
        </div>

        <div className={styles.imageWrapper}>
          <IconImage
            imageData={imageData}
            width={441}
            height={276}
          />
        </div>
      </ContentContainer>
    </SectionBase>
  );
};
