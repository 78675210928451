import { EnteredUserInfoField } from '@/controllers/consultation/consultation.typedefs';
import { useSessionStorage } from '@/hooks/useLocalStorage';
import { SESSION_STORAGE_KEYS } from '@/constants/sessionStorage';

interface UseEnteredUserValue {
  (field: EnteredUserInfoField,): readonly [
    string | null,
    (value: string | null) => void,
  ];
}

export const useEnteredUserValue: UseEnteredUserValue = (
  field,
) => {
  const [
    enteredUserValue,
    setEnteredUserValue,
  ] = useSessionStorage<string | null>(
    SESSION_STORAGE_KEYS.enteredUserInfo(field),
    null,
  );

  return [
    enteredUserValue,
    setEnteredUserValue,
  ] as const;
};
