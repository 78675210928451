import React from 'react';
import { cn } from '@/lib';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { typography } from '@/components/ui/typography';

export const FAQTitle = () => {
  const { t } = useTranslation([I18N_CODES.course]);

  return (
    <div className={cn(
      'cell large-5 medium-12',
      'medium-pb-56',
      'text-center-medium',
    )}
    >
      <p className={cn(typography.landingSectionHeading, 'mb-16 small-mb-12')}>
        {t(`${I18N_CODES.course}:profession_faq_section_heading`)}
      </p>

      <h2 className={typography.newLandingH2}>
        {t(`${I18N_CODES.course}:profession_faq_section_title`)}
      </h2>
    </div>
  );
};
