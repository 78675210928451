import React, { FC } from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { CheckmarkListItem } from '../../ProfessionTechnologiesSection/components/CheckmarkListItem';
import styles from './ProfessionConsultationTitle.module.scss';

interface Props {
  title: string;
  heading: string;
  facts: string[];
}

export const ProfessionConsultationTitle: FC<Props> = (props) => {
  const {
    title,
    heading,
    facts,
  } = props;

  return (
    <div className={cn(styles.consultationHeader, 'cell large-6 flex-container flex-dir-column align-center')}>
      <p className={cn(typography.landingSectionHeading, 'mb-16 small-mb-12')}>
        {heading}
      </p>

      <h2 className={cn(typography.newLandingH2, 'mb-24')}>
        {title}
      </h2>

      <ul className={styles.consultationList}>
        {facts.map((fact) => (
          <CheckmarkListItem
            key={fact}
            text={fact}
            iconClassName={styles.iconBackground}
          />
        ))}
      </ul>
    </div>
  );
};
