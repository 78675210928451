import React, { memo } from 'react';
import { ImageData } from '@/components/ui/Image/typedefs';
import { IconImage } from '@/components/ui/Image/IconImage';

interface Props {
  imageData: ImageData;
}

export const CoverImage = memo<Props>((props) => {
  const { imageData } = props;

  return (
    <IconImage
      imageData={imageData}
      width={656}
      height={656}
      priority
    />
  );
});
