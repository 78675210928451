import { I18N_CODES } from '@/lib/constants/general';
import maleHappySanta from '@/images/generated/icons.emoji.maleHappySanta.s3Key';
import maleWinkSanta from '@/images/generated/icons.emoji.maleWinkSanta.s3Key';
import maleHeartEyeSanta from '@/images/generated/icons.emoji.maleHeartEyeSanta.s3Key';
import sleigh from '@/images/generated/professionLanding.sleigh.s3Key';
import gift from '@/images/generated/professionLanding.gift.s3Key';
import gift2 from '@/images/generated/professionLanding.gift2.s3Key';
import gift3 from '@/images/generated/professionLanding.gift3.s3Key';
import gift4 from '@/images/generated/professionLanding.gift4.s3Key';
import { IconEmojiBriefCase } from '@/components/ui/IconEmojies/IconEmojiBriefCase';
import { IconEmojiMoneyWithWings } from '@/components/ui/IconEmojies/IconEmojiMoneyWithWings';
import { IconEmojiFlagUK } from '@/components/ui/IconEmojies/IconEmojiFlagUK';
import { IconEmojiChristmasTree } from '@/components/ui/IconEmojies/IconEmojiChristmasTree';
import { IconEmojiCookie } from '@/components/ui/IconEmojies/IconEmojiCookie';
import { IconEmojiSnowflake } from '@/components/ui/IconEmojies/IconEmojiSnowflake';
import { IconEmojiSnowman } from '@/components/ui/IconEmojies/IconEmojiSnowman';
import { Card } from './components/GiftCard/GiftCard.typedefs';

export const initialVisibleCards: Card[] = [
  {
    id: 1,
    isFlipped: false,
    previewIcon: gift,
    PreviewEmojiIcon: IconEmojiChristmasTree,
  },
  {
    id: 2,
    isFlipped: false,
    previewIcon: gift2,
    PreviewEmojiIcon: IconEmojiCookie,
  },
  {
    id: 3,
    isFlipped: false,
    previewIcon: gift3,
    PreviewEmojiIcon: IconEmojiSnowflake,
  },
  {
    id: 4,
    isFlipped: false,
    previewIcon: gift4,
    PreviewEmojiIcon: IconEmojiSnowman,
  },
];

export const commonCards: Card[] = [
  {
    id: 5,
    Icon: IconEmojiBriefCase,
    image: maleWinkSanta,
    text: `${I18N_CODES.course}:new_year_card_bonus_1`,
    previewIcon: gift2,
    isFlipped: false,
    PreviewEmojiIcon: IconEmojiCookie,
  },
  {
    id: 6,
    Icon: IconEmojiMoneyWithWings,
    image: maleHeartEyeSanta,
    text: `${I18N_CODES.course}:new_year_card_bonus_2`,
    previewIcon: gift3,
    isFlipped: false,
    PreviewEmojiIcon: IconEmojiSnowflake,
  },
  {
    id: 7,
    Icon: IconEmojiFlagUK,
    image: maleHappySanta,
    text: `${I18N_CODES.course}:new_year_card_bonus_3`,
    previewIcon: gift4,
    isFlipped: false,
    PreviewEmojiIcon: IconEmojiSnowman,
  },
];

export const mainCard: Card = {
  id: 8,
  isFlipped: false,
  isBig: false,
  isMain: true,
  isEnlarged: false,
  title: `${I18N_CODES.course}:new_year_main_card_title`,
  text: `${I18N_CODES.course}:new_year_main_card_bonus`,
  buttonText: `${I18N_CODES.course}:new_year_main_card_cta`,
  mainCardImage: sleigh,
};
