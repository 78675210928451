import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconVisaNoBackground: FCIcon = (props) => (
  <BaseIcon
    width="31"
    height="10"
    viewBox="0 0 31 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4497_29603)">
      <path
        d="M15.9514 3.18406C15.9339 4.57153 17.188 5.34574 18.1327 5.80611C19.1033 6.27839 19.4293 6.58129 19.4254 7.0037C19.4182 7.65008 18.6512 7.93538 17.9335 7.94647C16.6813 7.96586 15.9533 7.60839 15.3745 7.33803L14.9234 9.44876C15.5041 9.71635 16.5794 9.94972 17.6945 9.95996C20.3119 9.95996 22.0243 8.66791 22.0336 6.66466C22.0439 4.12225 18.517 3.98153 18.5411 2.84514C18.5494 2.50056 18.8782 2.13285 19.5987 2.03936C19.9553 1.99213 20.9398 1.95599 22.0559 2.46996L22.494 0.427793C21.8937 0.209238 21.1223 -3.91006e-05 20.1618 -3.91006e-05C17.6982 -3.91006e-05 15.9654 1.3096 15.9514 3.18406ZM26.7034 0.175864C26.2254 0.175864 25.8226 0.454659 25.6429 0.88249L21.904 9.80996H24.5195L25.04 8.37153H28.2361L28.5381 9.80996H30.8434L28.8317 0.175864H26.7034ZM27.0693 2.77839L27.8241 6.39611H25.7569L27.0693 2.77839ZM12.7802 0.175984L10.7186 9.80984H13.211L15.2717 0.175744L12.7802 0.175984ZM9.09313 0.175984L6.49892 6.73321L5.44952 1.15767C5.32639 0.535262 4.84012 0.175864 4.30012 0.175864H0.0593976L0 0.455624C0.870602 0.644539 1.85976 0.949238 2.45904 1.27526C2.82578 1.47442 2.93036 1.64851 3.05084 2.12177L5.03843 9.80996H7.67229L11.7104 0.175864L9.09313 0.175984Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4497_29603">
        <rect width="30.8434" height="10" fill="white" />
      </clipPath>
    </defs>
  </BaseIcon>
);
