import React, { FC, memo } from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { section } from '@/components/ui/section';
import styles from './BackgroundSection.module.scss';

enum BackgroundHeaderTextPosition {
  CENTER = 'text-center',
  LEFT = 'text-left',
}

const backgroundHeaderTextPositionClass = {
  [BackgroundHeaderTextPosition.CENTER]: styles.headerCenter,
  [BackgroundHeaderTextPosition.LEFT]: styles.headerLeft,
};

interface Props {
  sectionTitle: string;
  sectionHeading?: string;
  headerClassName?: string;
  headerPosition?: BackgroundHeaderTextPosition;
  headerWrapperClassName?: string;
  backgroundClassName?: string;
  renderContent?: () => void;
  id?: string;
}

const BackgroundSectionTemplate: FC<Props> = memo((props) => {
  const {
    children,
    sectionTitle,
    sectionHeading,
    headerClassName,
    headerWrapperClassName = styles.headerWrapper,
    backgroundClassName,
    headerPosition = BackgroundHeaderTextPosition.LEFT,
    renderContent,
    id,
  } = props;

  const headerClasses = cn(
    'cell',
    styles.header,
    headerClassName,
  );

  const wrapperClasses = cn(
    styles.background,
    backgroundClassName,
  );

  return (
    <section className={wrapperClasses}>
      <div className='content-container grid-x'>
        <div
          className={cn(
            headerWrapperClassName,
            backgroundHeaderTextPositionClass[headerPosition],
            { [styles.headerCtaContainer]: Boolean(renderContent) },
            { [section.scrollSection]: Boolean(id) },
          )}
          id={id}
        >
          <div className={headerClasses}>
            {sectionHeading && (
              <p className={cn(typography.landingSectionHeading, 'mb-16 small-mb-12')}>
                {sectionHeading}
              </p>
            )}

            <p className={typography.newLandingH2}>
              {sectionTitle}
            </p>
          </div>

          {renderContent && renderContent()}
        </div>

        {children}
      </div>
    </section>
  );
});

export const BackgroundSection = Object.assign(BackgroundSectionTemplate, {
  headerTextPosition: BackgroundHeaderTextPosition,
});
