import {
  PracticeTabs,
} from '@/components/platform/Learn/pages/CourseTopic/components/MobilePractice/MobilePracticeTabs/PracticeTabs.constants';

export const practiceTabURLToPracticeTabs = (
  practiceTab: string,
): PracticeTabs => {
  switch (practiceTab) {
    case PracticeTabs.Tests:
      return PracticeTabs.Tests;
    case PracticeTabs.Browser:
      return PracticeTabs.Browser;
    case PracticeTabs.Editor:
      return PracticeTabs.Editor;
    case PracticeTabs.TaskSolution:
      return PracticeTabs.TaskSolution;
    case PracticeTabs.TaskDescription:
    default:
      return PracticeTabs.TaskDescription;
  }
};
