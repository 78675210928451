import React, { memo } from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import styles from './FactBlock.module.scss';

interface Props {
  description: string;
  Icon: FCIcon;
  className?: string;
  titleClassname?: string;
  numberClassName?: string;
}

export const FactBlockIcon = memo<Props>((props) => {
  const {
    description,
    Icon,
    className,
    titleClassname,
    numberClassName,
  } = props;

  const [number, text] = description.split('|');

  return (
    <div className={cn(styles.factIconBlock, className)}>
      <div className={cn(styles.factIconTitle, titleClassname)}>
        <Icon className={styles.factIcon} />
      </div>

      <p className={cn(styles.factNumber, numberClassName, 'mb-8')}>{number}</p>

      <p className={cn(typography.newLandingH5, styles.factDescription)}>
        {text}
      </p>
    </div>
  );
});
