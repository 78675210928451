import { useCallback } from 'react';
import {
  useDefaultDomain,
} from '@/controllers/subDomain/subDomain.hooks/useDefaultDomain';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { AuthUserFragment } from '@/controllers/user/graphql/generated/AuthUser.fragment.generated';

export const useShouldRenderTelegramPopup = () => {
  const isDefaultSubdomain = useDefaultDomain();
  const [authUser] = useAuthUser({ ssr: false });

  return useCallback((
    user: Pick<AuthUserFragment, 'telegramNickName'> | null | undefined,
  ) => {
    const ensuredUser = user || authUser;

    return Boolean(
      isDefaultSubdomain
      && ensuredUser
      && !ensuredUser.telegramNickName,
    );
  }, [isDefaultSubdomain, authUser]);
};
