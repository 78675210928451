import React, { memo } from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { cn } from '@/lib';
import { NativeAppHelpers } from '@/components/nativeApp/nativeApp.helpers';
import { AnchorElement } from '@/components/ui/Button/AnchorElement';
import { COURSE_BLOCKS } from '@/components/courseLanding/CoursePage/CoursePageContent.constants';
import { typography } from '@/components/ui/typography';
import { FooterLink } from './FooterLink';
import { FooterLinkMobile } from '../FooterLinkMobile/FooterLinkMobile';
import { DownloadButton } from '../DownloadButton';
import styles from './FooterLinks.module.scss';

interface Props {
  termsOfUse: string;
  privacyPolicy: string;
  cookiesPolicy: string;
}

export const FooterLinksMobileSinglePage = memo<Props>((props) => {
  const {
    termsOfUse,
    privacyPolicy,
    cookiesPolicy,
  } = props;

  const { t } = useTranslation(`${I18N_CODES.common}`);

  const appStoreLink = NativeAppHelpers.getIOSLink('landing-footer');
  const googlePlayLink = NativeAppHelpers.getAndroidLink('landing-footer');

  return (
    <div className={cn(styles.navLinksMobile, 'show-for-small-only')}>
      <FooterLinkMobile
        title="About"
      >
        <AnchorElement href={`#${COURSE_BLOCKS.aboutPlatform}`}>
          <li
            className={cn(
              typography.landingTextMain,
              styles.navListItem,
            )}
          >
            Why Mate academy
          </li>
        </AnchorElement>

        <AnchorElement href={`#${COURSE_BLOCKS.program}`}>
          <li
            className={cn(
              typography.landingTextMain,
              styles.navListItem,
            )}
          >
            Course roadmap
          </li>
        </AnchorElement>

        <AnchorElement href={`#${COURSE_BLOCKS.price}`}>
          <li
            className={cn(
              typography.landingTextMain,
              styles.navListItem,
            )}
          >
            Price
          </li>
        </AnchorElement>

        <AnchorElement href={`#${COURSE_BLOCKS.reviews}`}>
          <li
            className={cn(
              typography.landingTextMain,
              styles.navListItem,
            )}
          >
            Reviews
          </li>
        </AnchorElement>

        <AnchorElement href={`#${COURSE_BLOCKS.faq}`}>
          <li
            className={cn(
              typography.landingTextMain,
              styles.navListItem,
            )}
          >
            FAQ
          </li>
        </AnchorElement>
      </FooterLinkMobile>

      <FooterLinkMobile
        title={t(`${I18N_CODES.common}:profession_footer_download`)}
      >
        <DownloadButton
          href={appStoreLink}
          provider='apple'
          source='landing-footer'
          title={t(`${I18N_CODES.common}:profession_footer_download_ios`)}
        />

        <DownloadButton
          href={googlePlayLink}
          provider='google'
          source='landing-footer'
          title={t(`${I18N_CODES.common}:profession_footer_download_android`)}
        />
      </FooterLinkMobile>

      <FooterLinkMobile
        title={t(`${I18N_CODES.common}:profession_footer_legal`)}
      >
        <FooterLink
          route={termsOfUse}
          title={t(`${I18N_CODES.common}:profession_footer_terms_of_use`)}
          isExternal
        />

        <FooterLink
          route={privacyPolicy}
          title={t(`${I18N_CODES.common}:profession_footer_privacy_policy`)}
          isExternal
        />

        <FooterLink
          route={cookiesPolicy}
          title={t(`${I18N_CODES.common}:profession_footer_cookies_policy`)}
          isExternal
        />
      </FooterLinkMobile>
    </div>
  );
});
