import { CompanyFragment } from '@/controllers/companies/graphql/generated/Company.fragment.generated';

export const getFirstHalfCompanies = (
  companies: CompanyFragment[],
) => (
  companies.slice(0, Math.ceil(companies.length / 2)));

export const getSecondHalfCompanies = (
  companies: CompanyFragment[],
) => (
  companies.slice(Math.ceil(companies.length / 2), companies.length));

export const getFirstFewCompanies = (
  companies: CompanyFragment[],
  count: number,
) => (
  companies.slice(0, count));

export const getSecondFewCompanies = (
  companies: CompanyFragment[],
  count: number,
) => {
  const startIndex = Math.ceil(companies.length / 2);

  return companies.slice(startIndex, startIndex + count);
};
