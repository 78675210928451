import React, { memo } from 'react';

import { cn } from '@/lib';
import { IconImage } from '@/components/ui/Image/IconImage';
import { useAllCompanies } from '@/controllers/companies/companies.hooks/useAllCompanies';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import sectionImage from '@/images/generated/professionLanding.graduatesStatisticsMaleBig.s3Key';
import { FactsBlock } from '@/components/professionLanding/FactsBlock/FactsBlock';
import { SectionBase, SectionBaseType } from '@/components/landing/BaseSectionWrappers/SectionBase';
import { AnimatedCompaniesLogos } from '@/components/professionLanding/AnimatedCompaniesLogos/AnimatedCompaniesLogos';
import styles from './EmploymentGuaranteeSection.module.scss';

export const EmploymentGuaranteeSection = memo(() => {
  const companies = useAllCompanies();

  const { t } = useTranslation([I18N_CODES.home]);

  return (
    <SectionBase type={SectionBaseType.Default}>
      <div className={cn(styles.sectionWrapper, 'mb-16')}>
        <div className={cn(styles.heading)}>
          <h3 className={styles.title}>
            {t(`${I18N_CODES.home}:profession_graduates_heading`)}
          </h3>

          <p className={styles.description}>
            {t(`${I18N_CODES.home}:profession_graduates_fulltime_description`)}
          </p>

          <p className={styles.description}>
            {t(`${I18N_CODES.home}:profession_graduates_flex_description`)}
          </p>
        </div>

        {companies.length > 0 && (
          <div className={styles.companiesList}>
            <AnimatedCompaniesLogos companies={companies} />
          </div>
        )}

        <div className={styles.imageWrapper}>
          <div className={styles.image}>
            <IconImage
              imageData={sectionImage}
              height={sectionImage.fallback.height / 4}
              width={sectionImage.fallback.width / 4}
            />
          </div>
        </div>
      </div>

      <FactsBlock />
    </SectionBase>
  );
});
