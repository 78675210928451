import React, { memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { cn } from '@/lib';
import { useStateRef } from '@/hooks/useStateRef';
import { AnchorElement } from '@/components/ui/Button/AnchorElement';
import { Button } from '@/components/ui/Button';
import { IconImage } from '@/components/ui/Image/IconImage';
import { IconArrowRightLong } from '@/components/ui/icons/IconArrowRightLong';
import { IconArrowLeftLong } from '@/components/ui/icons/IconArrowLeftLong';
import { Interview } from '@/components/landing/GraduatesInterviewsSection/GraduatesInterviewsSection.typedefs';
import { IconYoutubePlay } from '@/components/ui/icons/IconYoutubePlay';
import styles from '../InterviewsSection.module.scss';

interface Props {
  interviews: Interview[];
}

export const InterviewsSlider = memo<Props>((props) => {
  const { interviews } = props;

  const [prevButton, setPrevButtonRef] = useStateRef<HTMLButtonElement>();
  const [nextButton, setNextButtonRef] = useStateRef<HTMLButtonElement>();

  return (
    <>
      <div className="cell mb-40">
        <Swiper
          className={cn(styles.container, 'overflow-visible')}
          modules={[Navigation, Pagination]}
          pagination={{
            el: `.${styles.bulletsContainer}`,
            horizontalClass: `${styles.pagination}`,
            dynamicBullets: true,
            dynamicMainBullets: 3,
            enabled: true,
            renderBullet: (_, className) => (
              `<span class="${className} ${styles.bullet}"></span>`
            ),
          }}
          navigation={{
            prevEl: prevButton,
            nextEl: nextButton,
          }}
          breakpoints={
            {
              1230: {
                slidesPerGroup: 2,
              },
              1024: {
                slidesPerGroup: 2,
              },
              320: {
                slidesPerGroup: 1,
              },
            }
          }
          spaceBetween={16}
          slidesPerView='auto'
          slidesPerGroup={3}
          speed={500}
          grabCursor
          autoplay={{
            disableOnInteraction: true,
          }}
        >
          {interviews.map((interview) => (
            <SwiperSlide key={interview.id} className={styles.slide}>
              <AnchorElement
                href={interview.url}
                target='_blank'
                className={styles.youtubeLink}
              >
                <IconImage
                  className={styles.video}
                  imageData={interview.image}
                  alt={interview.alt}
                  width={interview.image.fallback.width}
                  height={interview.image.fallback.height}
                />
                <IconYoutubePlay className={styles.playButton} />
              </AnchorElement>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className={cn(styles.controls, 'cell flex-container align-center')}>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            LeftIcon={IconArrowLeftLong}
            mode={Button.mode.Secondary}
            size={Button.size.Large}
            ref={setPrevButtonRef}
          />

          <div className={styles.bulletsContainer} />

          <Button
            className={styles.button}
            LeftIcon={IconArrowRightLong}
            mode={Button.mode.Secondary}
            size={Button.size.Large}
            ref={setNextButtonRef}
          />
        </div>
      </div>
    </>
  );
});
