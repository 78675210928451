import React, { FC } from 'react';
import { cn } from '@/lib';
import styles from './TextTag.module.scss';

export enum TextTagMode {
  dark = 'dark',
  light = 'light',
}

interface Props {
  text: string;
  tagMode: TextTagMode;
}

const textTagModeClass = {
  [TextTagMode.dark]: styles.tagDark,
  [TextTagMode.light]: styles.tagLight,
};

const TextTagTemplate: FC<Props> = (props) => {
  const {
    text,
    tagMode,
  } = props;

  return (
    <span className={cn(styles.tag, textTagModeClass[tagMode])}>
      {text}
    </span>
  );
};

export const TextTag = Object.assign(TextTagTemplate, {
  TextTagMode,
});
