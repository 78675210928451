import React, { FC } from 'react';
import { PageExitPopup } from '@/components/landing/PageExitPopup';
import {
  useRedesignedProfessionsListData,
} from '@/components/coursesParttimeLanding/ProfessionsListSectionRedesigned/hooks/useRedesignedProfessionsListData.hook';
import {
  ProfessionsListSectionRedesigned,
} from '@/components/coursesParttimeLanding/ProfessionsListSectionRedesigned/ProfessionsListSectionRedesigned';
import { FAQSection } from '@/components/professionLanding/FAQSection/FAQSection';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { ReviewsSection } from '@/components/professionLanding/ReviewsSection/ReviewsSection';
import {
  ConsultationFormSection,
} from '@/components/professionLanding/ConsultationFormSection/ConsultationFormSection';
import { I18N_CODES } from '@/lib/constants/general';
import { CourseType } from '@/controllers/graphql/generated';
import { useTranslation } from '@/middleware/i18n';
import { ProfessionFooter } from '@/components/professionLanding/ProfessionFooter/ProfessionFooter';
import { InterviewsSection } from '@/components/professionLanding/InterviewsSection/InterviewsSection';
import {
  HomePageCoverSectionRedesign,
} from '@/components/homepageLanding/HomePageCoverSectionRedesign/HomePageCoverSectionRedesign';
import { QuotesSection } from '@/components/homepageLanding/QuotesSection/QuotesSection';
import {
  StudyingFormatsSectionRedesign,
} from '@/components/homepageLanding/StudyingFormatsSectionRedesign/StudyingFormatsSectionRedesign';
import {
  EmploymentGuaranteeSection,
} from '@/components/homepageLanding/EmploymentGuaranteeSection/EmploymentGuaranteeSection';
import { OurGraduatesSection } from '@/components/homepageLanding/OurGraduatesSection/OurGraduatesSection';
import { CompanyValuesSection } from '@/components/homepageLanding/CompanyValuesSection/CompanyValuesSection';
import { HowDoWeTeachSection } from '@/components/homepageLanding/HowDoWeTeachSection/HowDoWeTeachSection';
import {
  ChoosingProfessionSection,
} from '@/components/homepageLanding/ChoosingProfessionSection/ChoosingProfessionSection';
import {
  FreeForCombatantsSection,
} from '@/components/homepageLanding/FreeForCombatantsSection/FreeForCombatantsSection';
import { MoreAboutUsSection } from '@/components/homepageLanding/MoreAboutUsSection/MoreAboutUsSection';
import { SocialMediasSection } from '@/components/homepageLanding/SocialMediasSection/SocialMediasSection';
import { HOME_PAGE_FAQS, HOME_PAGE_REVIEWS } from '@/components/homepageLanding/HomepageLanding.content';
import { GRADUATES_INTERVIEWS_DATA } from '@/components/landing/GraduatesInterviewsSection/graduatesInterviewsData';
import styles from './HomePageContentRedesign.module.scss';

export const HomePageContentRedesign: FC = () => {
  const { subDomain } = useSubDomainContext();
  const { t } = useTranslation([I18N_CODES.home]);

  const professions = useRedesignedProfessionsListData();
  const faqsByDomain = HOME_PAGE_FAQS[subDomain];
  const reviewsList = HOME_PAGE_REVIEWS[subDomain];
  const interviewsByDomain = GRADUATES_INTERVIEWS_DATA[subDomain];

  return (
    <div className={styles.page}>
      <div className="mb-120 medium-mb-80 small-mb-56">
        <HomePageCoverSectionRedesign />
      </div>

      <div className="mb-120 medium-mb-80 small-mb-56">
        <QuotesSection />
      </div>

      {professions && professions.length > 0 && (
        <div className="mb-120 medium-mb-80 small-mb-56">
          <ProfessionsListSectionRedesigned professions={professions} />
        </div>
      )}

      <div className="mb-120 medium-mb-80 small-mb-56">
        <StudyingFormatsSectionRedesign />
      </div>

      <div className="mb-120 medium-mb-80 small-mb-56">
        <EmploymentGuaranteeSection />
      </div>

      <div className="mb-120 medium-mb-80 small-mb-56">
        <OurGraduatesSection />
      </div>

      <div className="mb-120 medium-mb-80 small-mb-56">
        <CompanyValuesSection />
      </div>

      <div className="mb-120 medium-mb-80 small-mb-56">
        <HowDoWeTeachSection />
      </div>

      <div className="mb-120 medium-mb-80 small-mb-56">
        <ChoosingProfessionSection />
      </div>

      {reviewsList && (
        <div className="mb-120 medium-mb-80 small-mb-56">
          <ReviewsSection reviews={reviewsList} />
        </div>
      )}

      {interviewsByDomain && (
        <div className="mb-120 medium-mb-80 small-mb-56">
          <InterviewsSection interviews={interviewsByDomain} />
        </div>
      )}

      <div className="mb-120 medium-mb-80 small-mb-56">
        <FreeForCombatantsSection />
      </div>

      <div className="mb-120 medium-mb-80 small-mb-56">
        <MoreAboutUsSection />
      </div>

      <div className="mb-120 medium-mb-80 small-mb-56">
        <ConsultationFormSection
          title={t(`${I18N_CODES.home}:consultation_form_title`)}
          heading={t(`${I18N_CODES.home}:consultation_form_heading`)}
          eventSource='home_page_consultation_section'
          courseTypes={[CourseType.Consultation]}
        />
      </div>

      <div className="mb-120 medium-mb-80 small-mb-56">
        <SocialMediasSection />
      </div>

      {faqsByDomain && (
        <div className="mb-120 medium-mb-80 small-mb-56">
          <FAQSection
            faqs={faqsByDomain}
          />
        </div>
      )}

      <div className="pb-16 medium-pb-24 small-pb-0">
        <ProfessionFooter />
      </div>

      <PageExitPopup source='home_page_exit_popup' />
    </div>
  );
};
