import React, { FC } from 'react';
import { Button } from '@/components/ui/Button';
import { InputText } from '@/components/ui/FormElements/FormInputs/InputText';
import {
  RequestStatus,
} from '@/components/courseLanding/CourseConsultationForm/components/ConsultationPromoCode/ConsultationPromoCode.typedefs';
import { cn } from '@/lib';
import {
  PromoCodeMessage,
} from '@/components/courseLanding/CourseConsultationForm/components/ConsultationPromoCode/PromoCodeComponents/PromoCodeMessage';
import { RenderInputProps } from '@/components/ui/FormElements/FormField';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import styles from '../ConsultationPromoCode.module.scss';

interface Props {
  handleOnInputChange: () => void;
  handleSubmit: () => void;
  loading: boolean;
  requestStatus: RequestStatus | null;
  message: string | null;
  inputProps: RenderInputProps;
  dataQa?: string;
}

export const PromoCodeInputOpened: FC<Props> = (props) => {
  const {
    requestStatus,
    handleOnInputChange,
    handleSubmit,
    loading,
    message,
    inputProps,
    dataQa,
  } = props;

  const { t } = useTranslation([I18N_CODES.registration]);

  return (
    <>
      <div className={styles.promoCodeInput}>
        <InputText
          {...inputProps}
          placeholder={t(`${I18N_CODES.registration}:promo_code_input_placeholder`)}
          className={cn({
            [styles.inputError]: requestStatus === RequestStatus.Error,
          })}
          onInput={handleOnInputChange}
          dataQa={dataQa}
        />

        <Button
          dataQa={`${dataQa}-button-submit`}
          text={t(`${I18N_CODES.registration}:promo_code_apply`)}
          mode={Button.mode.BrandSecondary}
          size={Button.size.Large}
          onClick={handleSubmit}
          className={styles.applyButton}
          isLoading={loading}
        />
      </div>

      {message && (
        <PromoCodeMessage
          requestStatus={requestStatus}
          message={message}
        />
      )}
    </>
  );
};
