import React, { FC } from 'react';
import { FormRenderProps } from 'react-final-form';
import { Loader } from '@/components/ui/Loader';
import { Button } from '@/components/ui/Button';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { CourseType } from '@/controllers/graphql/generated';
import { Alert } from '@/components/ui/Alert';
import {
  SignUpNewsletterSubscriptionCheckbox,
} from '@/components/authentication/SignUp/SignUpElements/SignUpNewsletterSubscriptionCheckbox';
import { SignUpConsentNote } from '@/components/authentication/SignUp/SignUpElements/SignUpConsentNote';
import { ButtonProps, SelectorProps } from '@/components/courseLanding/CourseConsultationForm/ConsultationForm.typedefs';
import { useHandleFormInteraction } from '@/components/common/ExitPopup/hooks/useHandleFormInteraction.hook';
import { ConsultationAge } from '@/components/courseLanding/CourseConsultationForm/components/ConsultationAge';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { useDefaultDomain } from '@/controllers/subDomain/subDomain.hooks/useDefaultDomain';
import { cn } from '@/lib';
import {
  ConsultationCourseSelector,
} from '@/components/courseLanding/CourseConsultationForm/components/ConsultationCourseSelector';
import { useRouterQuery } from '@/hooks/useRouterQuery';
import { RegistrationQuery } from '@/components/consultation/consultation.typedefs';
import {
  useCourseOptions,
} from '@/components/courseLanding/CourseConsultationForm/CourseConsultationForm.hooks/useCourseOptions.hook';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { ConsultationCourses } from '@/components/courseLanding/CourseLanding.constants';
import { ConsultationName } from '@/components/courseLanding/CourseConsultationForm/components/ConsultaitonName';
import { ConsultationPhoneNumber } from '@/components/courseLanding/CourseConsultationForm/components/ConsultationPhoneNumber/ConsultationPhoneNumber';
import { ConsultationEmail } from '@/components/courseLanding/CourseConsultationForm/components/ConsultationEmail';
import styles from './ProfessionConsultationForm.module.scss';

interface Props extends FormRenderProps {
  types: CourseType[];
  consultationRequestError: Error | null;
  slug?: string;
  courseId?: number;
  selectorProps?: SelectorProps;
  buttonProps?: ButtonProps;
  dataQa?: string;
  customInput?: React.ReactNode;
}

export const ProfessionConsultationForm: FC<Props> = (props) => {
  const {
    handleSubmit,
    submitting,
    slug,
    courseId,
    types,
    consultationRequestError,
    selectorProps,
    buttonProps,
    children,
    dataQa = 'consultation-form',
    customInput,
  } = props;

  const isDefaultSubdomain = useDefaultDomain();
  const [authUser] = useAuthUser({ ssr: false });

  const { t } = useTranslation([
    I18N_CODES.courseConsultation,
    I18N_CODES.validates,
  ]);

  const features = useFeatures();

  const isConsultationAgeInputEnabled = (
    features.isEnabled(Features.ConsultationAgeInput)
  );

  const buttonLabel = t(`${I18N_CODES.courseConsultation}:profession_consultation_form_submit_button`);
  const handlePayOutFormInteraction = useHandleFormInteraction();

  const { courseSlug: courseSlugFromUrl } = useRouterQuery<RegistrationQuery>();

  const courseSlug = (
    slug
    || courseSlugFromUrl
    || ConsultationCourses.GeneralConsultation
  );

  const courseSelectorFieldName = 'courseSlug';

  const {
    courseOptions,
    initiallySelectedCourse,
  } = useCourseOptions({
    types,
    courseId,
    courseSlug,
    fieldName: courseSelectorFieldName,
  });

  const shouldHideSelector = (
    initiallySelectedCourse
    && !selectorProps?.shouldShowCourseSelector
  );

  return (
    <form
      onFocus={handlePayOutFormInteraction}
      onSubmit={handleSubmit}
      method='post'
      data-qa={dataQa}
      className={styles.form}
    >
      <div className='scrolable-content-container'>
        {children}

        {!shouldHideSelector && (
          <div className="mb-16">
            <ConsultationCourseSelector
              courseOptions={courseOptions}
              initiallySelectedCourse={initiallySelectedCourse}
              fieldName={courseSelectorFieldName}
              {...selectorProps}
            />
          </div>
        )}

        <div className="mb-16">
          <ConsultationName />
        </div>

        <div className="mb-16">
          <ConsultationPhoneNumber />
        </div>

        <div className="mb-16">
          <ConsultationEmail />
        </div>

        {isConsultationAgeInputEnabled && (
          <div className="mb-16">
            <ConsultationAge />
          </div>
        )}

        {customInput && (
          <div className="mb-16">
            {customInput}
          </div>
        )}

        <div className={cn('mb-24', {
          hide: isDefaultSubdomain,
        })}
        >
          <SignUpNewsletterSubscriptionCheckbox
            className={styles.newsletterCheckbox}
          />
        </div>

        <div className='overflow-button-container mb-16'>
          <Button
            type="submit"
            mode={Button.mode.BrandPrimary}
            size={Button.size.Large}
            text={buttonLabel}
            disabled={submitting}
            data-qa='consultation-submit-button'
            hasFullWidth
            {...buttonProps}
          />
        </div>

        {!authUser && (
          <div className={cn({ 'mb-16': consultationRequestError })}>
            <SignUpConsentNote
              buttonLabel={buttonProps?.text || buttonLabel}
              className={styles.termsLinks}
            />
          </div>
        )}

        {consultationRequestError && (
          <div>
            <Alert mode={Alert.mode.Error}>
              {t(`${I18N_CODES.validates}:${consultationRequestError.message}`)}
            </Alert>
          </div>
        )}
      </div>

      <Loader
        className={styles.loader}
        loading={submitting}
        size={32}
      />
    </form>
  );
};
