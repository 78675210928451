import React, { memo } from 'react';
import { cn } from '@/lib';
import {
  FactsBlockMain,
} from '@/components/homepageLanding/HomePageCoverStatistics/components/FactsBlockMain/FactsBlockMain';
import {
  FactsBlockSecondary,
} from '@/components/homepageLanding/HomePageCoverStatistics/components/FactsBlockSecondary/FactsBlockSecondary';
import styles from './HomePageCoverStatistics.module.scss';

export const HomePageCoverStatistics = memo(() => (
  <div className={cn(styles.sectionWrapper)}>
    <FactsBlockMain />

    <FactsBlockSecondary />
  </div>
));
