import React, { memo } from 'react';
import {
  TableColumnsView,
} from '@/components/homepageLanding/GeneralFormatComparison/Tables/TableColumnsView/TableColumnsView';
import { TableFeedView } from '@/components/homepageLanding/GeneralFormatComparison/Tables/TableFeedView/TableFeedView';
import styles from './GeneralComparisonTable.module.scss';
import { GeneralComparisonTableData } from './GeneralComparisonTable.typedefs';

interface Props {
  data: GeneralComparisonTableData;
}

export const GeneralComparisonTable = memo<Props>((props) => {
  const {
    data,
  } = props;

  return (
    <>
      <div className={styles.largeScreenTable}>
        <TableColumnsView data={data} />
      </div>

      <div className={styles.smallScreenTable}>
        <TableFeedView data={data} />
      </div>
    </>
  );
});
