import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { Loader } from '@/components/ui/Loader';
import { Button } from '@/components/ui/Button';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { Alert } from '@/components/ui/Alert';
import { ConsultationTelegramNickName } from '@/components/courseLanding/CourseConsultationForm/components/ConsultationTelegramNickName';
import styles from './ConsultationTelegramForm.module.scss';

interface Props extends FormRenderProps {
  requestError: Error | null;
  closeForm: () => void;
  isCloseButtonVisible?: boolean;
}

export const ConsultationTelegramForm: React.FC<Props> = (props) => {
  const {
    handleSubmit,
    submitting,
    requestError,
    pristine,
    closeForm,
    isCloseButtonVisible = false,
  } = props;

  const { t } = useTranslation([
    I18N_CODES.courseConsultation,
    I18N_CODES.validates,
  ]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-16">
        <ConsultationTelegramNickName />
      </div>

      {requestError && (
        <div className="mb-24">
          <Alert mode={Alert.mode.Error}>
            {t(`${I18N_CODES.validates}:${requestError.message}`)}
          </Alert>
        </div>
      )}

      <div className={styles.buttonsWrapper}>
        {isCloseButtonVisible && (
          <Button
            type="submit"
            mode={Button.mode.BrandSecondary}
            size={Button.size.Large}
            text={t(`${I18N_CODES.courseConsultation}:telegram_form_close_button`)}
            data-qa='telegram-consultation-submit-button'
            isLoading={submitting}
            onClick={closeForm}
            hasFullWidth
          />
        )}

        <Button
          type="submit"
          mode={Button.mode.BrandPrimary}
          size={Button.size.Large}
          text={t(`${I18N_CODES.courseConsultation}:telegram_form_submit_button`)}
          disabled={submitting || pristine}
          data-qa='telegram-consultation-submit-button'
          isLoading={submitting}
          hasFullWidth
        />
      </div>

      <Loader
        loading={submitting}
        size={32}
      />
    </form>
  );
};
