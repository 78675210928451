import React, { FC } from 'react';
import { IconImage } from '@/components/ui/Image/IconImage';
import employedGraduates3 from '@/images/generated/professionLanding.employedGraduates3.s3Key';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { useEmployedStudentsCount } from '@/controllers/landing/hooks/useEmployedStudentsCount';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import styles from './FactsBlockSecondary.module.scss';

export const FactsBlockSecondary: FC = () => {
  const { t } = useTranslation([I18N_CODES.home]);

  const employedCount = useEmployedStudentsCount();

  return (
    <div className={styles.blockWrapper}>
      <p className={styles.topContent}>
        <span className={styles.image}>
          <IconImage
            imageData={employedGraduates3}
            width={224}
            height={96}
          />
        </span>

        <span className={cn(
          typography.newLandingTextMain,
          styles.imageDescription,
        )}
        >
          {t(`${I18N_CODES.home}:fact_employed_graduates_image_description`, {
            employedCount,
          })}
        </span>
      </p>

      <p className={styles.bottomContent}>
        <span className={styles.value}>
          {t(`${I18N_CODES.home}:students_without_experience_value`)}
        </span>

        <span className={typography.newLandingTextMain}>
          {t(`${I18N_CODES.home}:students_without_experience_description`)}
        </span>
      </p>
    </div>
  );
};
