import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconGroupUsers: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M15.0391 11.306C15.5117 11.5691 16.0461 11.7177 16.624 11.7177C18.436 11.7177 19.9064 10.2463 19.9064 8.43431C19.9064 6.63154 18.436 5.16016 16.624 5.16016C16.2649 5.16016 15.9234 5.222 15.5994 5.32631C16.072 6.08877 16.3434 6.98969 16.3434 7.94416C16.3434 9.24016 15.8615 10.4134 15.0391 11.306Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.99432 11.7177C6.57217 11.7177 7.10571 11.5691 7.57925 11.306C6.75586 10.4134 6.27401 9.24016 6.27401 7.94416C6.27401 6.99062 6.5454 6.08877 7.01894 5.32723C6.69494 5.222 6.3534 5.16016 5.99432 5.16016C4.18232 5.16016 2.71094 6.63154 2.71094 8.43431C2.71094 10.2472 4.18232 11.7177 5.99432 11.7177Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.3096 12.1096C13.6044 12.1096 15.4709 10.2431 15.4709 7.94839C15.4709 5.65455 13.6044 3.78809 11.3096 3.78809C9.0149 3.78809 7.14844 5.65455 7.14844 7.94839C7.14844 10.2431 9.0149 12.1096 11.3096 12.1096Z" fill="currentColor" />
    <g opacity="0.4">
      <path fillRule="evenodd" clipRule="evenodd" d="M21.5495 16.2173C20.8756 14.1071 19.0895 12.8997 16.6387 12.8997H16.6212C15.6224 12.8905 14.7298 13.0917 13.9766 13.4766C16.0784 14.1514 17.6366 15.6486 18.3538 17.7671C19.4919 17.6093 20.4815 17.2594 21.3566 16.7249L21.6455 16.5413L21.5495 16.2173Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.59429 13.4597C7.8586 13.0923 6.99183 12.8994 6.01152 12.8994H5.97644C3.52567 12.8994 1.73952 14.1077 1.07398 16.217L0.96875 16.541L1.25767 16.7246C2.08937 17.2333 3.0706 17.5739 4.16444 17.7493C4.8909 15.6225 6.47583 14.116 8.59429 13.4597Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2777 13.9414C11.2629 13.9414 11.249 13.9414 11.2352 13.9414C8.08382 13.9414 5.78628 15.4903 4.93151 18.194L4.82812 18.5208L5.12074 18.6999C6.78966 19.7171 8.78351 20.2119 11.2158 20.2119H11.2943C13.7284 20.2119 15.7232 19.7171 17.3921 18.6999L17.6857 18.5208L17.5823 18.194C16.7266 15.4903 14.43 13.9414 11.2777 13.9414Z" fill="currentColor" />
    </g>
  </BaseIcon>
);
