export enum InputStep {
  Closed = 'CLOSED',
  Opened = 'OPENED',
  Submitted = 'SUBMITTED',
}

export enum RequestStatus {
  Success = 'SUCCESS',
  Error = 'ERROR',
}
