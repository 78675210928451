import React, { memo } from 'react';
import {
  IconMateLogoFull,
  IconMateLogoFullNewYear,
} from '@/components/ui/icons/product/Mate';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { useShouldShowNewYearLogo } from './hooks/useShouldShowNewYearLogo';

/**
 * Mate logo full icon
 * @deprecated Use LogoFull instead
 * @private This component is not for direct use
 */
export const MateLogoFull: FCIcon = memo((props) => {
  const shouldShowNewYearLogo = useShouldShowNewYearLogo();

  return shouldShowNewYearLogo
    ? (
      <IconMateLogoFullNewYear {...props} />
    )
    : (
      <IconMateLogoFull {...props} />
    );
});
