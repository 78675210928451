import React from 'react';
import styles from './BackgroundCircles.module.scss';

export const BackgroundCircles = () => (
  <div className={styles.background}>
    <div className={styles.circle1}>
      <div className={styles.circle2}>
        <div className={styles.circle3}>
          <div className={styles.circle4} />
        </div>
      </div>
    </div>
  </div>
);
