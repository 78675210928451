import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconBubblePhraseAngle: FCIcon = (props) => (
  <BaseIcon
    width="20"
    height="8"
    viewBox="0 0 20 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.9412 8C12.9412 2.88 17.6471 0.533333 20 0C7.29412 0 1.37255 5.33333 0 8H12.9412Z" fill="currentColor" />
  </BaseIcon>
);
