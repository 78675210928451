import React, {
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { Button } from '@/components/ui/Button';
import { useCtaContext } from '@/controllers/cta/Cta.context';
import { truthy } from '@/lib/helpers/functional';
import { ItemActionButton } from '../../CourseProgram/components/ItemActionButton/ItemActionButton';
import styles from './FAQListItem.module.scss';

interface Props {
  title: string;
  content: string;
  shouldShowCta?: boolean;
  ctaText?: string;
}

export const FAQListItem = memo<Props>((props) => {
  const {
    title,
    content,
    shouldShowCta,
    ctaText,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const ctaContext = useCtaContext();

  const handleCtaClick = useCallback(() => {
    ctaContext.callback({
      source: 'faq-answer-cta',
    });
  }, [ctaContext]);

  const handleOpenContent = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const contentBlocks = useMemo(() => content
    .split('\n\n')
    .map((blockContent, index) => {
      const trimmedBlockContent = blockContent.trim();

      if (!trimmedBlockContent.length) {
        return null;
      }

      return (
        // eslint-disable-next-line react/no-array-index-key
        <p key={index} className={styles.contentBlock}>
          {trimmedBlockContent}
        </p>
      );
    })
    .filter(truthy), [content]);

  const ref = useRef<HTMLParagraphElement>(null);

  const height = isOpen
    ? ref.current?.offsetHeight
    : 0;

  return (
    <li className='color-dark-blue'>
      <div className={styles.moduleListItem}>
        <h3>{title}</h3>

        <ItemActionButton
          onClick={handleOpenContent}
          isOpen={isOpen}
        />
      </div>

      <div className={styles.contentWrapper} style={{ height }}>
        <p
          className={cn(
            typography.landingTextMain,
            styles.content,
            'pb-24',
          )}
          ref={ref}
        >
          {contentBlocks}

          {shouldShowCta && (
            <Button
              size={Button.size.Large}
              mode={Button.mode.BrandPrimary}
              text={ctaText || ctaContext.text}
              href={ctaContext.href}
              className={styles.registrationButton}
              data-role="desktop-cta"
              onClick={handleCtaClick}
            />
          )}
        </p>
      </div>
    </li>
  );
});
