import React, { FC } from 'react';
import { useFormState } from 'react-final-form';
import { FormField } from '@/components/ui/FormElements/FormField';
import { I18N_CODES } from '@/lib/constants/general';
import { Select } from '@/components/ui/FormElements/FormInputs/Select';
import { isNotEmpty } from '@/controllers/forms/forms.validator/validationRules';
import { InputPlaceholder } from '@/components/ui/FormElements/FormInputs/InputPlaceholder';
import { useTranslation } from '@/middleware/i18n';
import { SelectorProps } from '@/components/courseLanding/CourseConsultationForm/ConsultationForm.typedefs';
import { SelectOptionInterface } from '@/controllers/forms/forms.typedefs';

interface Props extends SelectorProps {
  fieldName: string;
  courseOptions: SelectOptionInterface<string>[];
  initiallySelectedCourse?: SelectOptionInterface<string> | null;
}

export const ConsultationCourseSelector: FC<Props> = (props) => {
  const {
    fieldName,
    courseOptions,
    initiallySelectedCourse,
    placeholder,
    fieldLabel,
  } = props;

  const { t } = useTranslation([
    I18N_CODES.profile,
    I18N_CODES.validates,
  ]);
  const { submitting } = useFormState();

  return (
    <FormField
      label={{
        for: 'course-slug',
        text: fieldLabel || t(`${I18N_CODES.profile}:survey_question_course`),
      }}
      renderInput={(inputProps) => (courseOptions.length > 0
        ? (
          <div data-qa='consultation-course-selector'>
            <Select
              config={{
                validate: (option) => {
                  if (!isNotEmpty(option?.value)) {
                    return t(`${I18N_CODES.validates}:required_course`);
                  }

                  const isValid = !option?.value || courseOptions
                    .some((course) => (course.value === option.value));

                  if (!isValid) {
                    return t(`${I18N_CODES.validates}:validate_course_applied`);
                  }

                  return undefined;
                },
                initialValue: initiallySelectedCourse,
              }}
              placeholder={placeholder || t(`${I18N_CODES.profile}:survey_question_course`)}
              options={courseOptions}
              required
              {...inputProps}
              id="course-slug"
            />
          </div>
        )
        : (
          <InputPlaceholder />
        ))}
      disabled={submitting}
      name={fieldName}
    />
  );
};
