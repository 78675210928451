import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconX: FCIcon = (props) => (
  <BaseIcon
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4557_22377)">
      <mask id="mask0_4557_22377" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="21">
        <path d="M0 0.482422H20V20.4824H0V0.482422Z" fill="white" />
      </mask>
      <g mask="url(#mask0_4557_22377)">
        <path d="M14.2298 3.81641H16.4859L11.5575 9.46349L17.3559 17.1491H12.8164L9.25836 12.4888L5.19171 17.1491H2.93351L8.20439 11.107L2.64453 3.81746H7.29964L10.5109 8.07641L14.2298 3.81641ZM13.4364 15.7957H14.6869L6.61661 5.09945H5.27577L13.4364 15.7957Z" fill="#B3B8C7" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4557_22377">
        <rect width="20" height="20" fill="white" transform="translate(0 0.482422)" />
      </clipPath>
    </defs>
  </BaseIcon>
);
