import { useCallback } from 'react';
import {
  LeadScoreType,
  StudentSource,
} from '@/controllers/graphql/generated';
import {
  updateUserAnalyticsData,
} from '@/controllers/analytics/analytics.utils/updateUserAnalyticsData';
import {
  useCollectSignUpData,
} from '@/controllers/auth/auth.hooks.ts/useCollectSignUpData';
import { getBirthDateFromAge } from '@/controllers/user/user.utils';
import { analyticsSDK } from '@/controllers/analytics';
import {
  setAmplitudeUserProperties,
} from '@/controllers/amplitude/amplitude.helpers';
import {
  CourseType,
  LandingConsultationFormSubmitted,
} from '@/controllers/analytics/generated';
import {
  PromoCodeLandingFragment,
} from '@/controllers/promoCode/generated/promoCodeLanding.fragment.generated';
import {
  processLeadScoreTypeEvent,
} from '@/controllers/courseUsers/courseUsers.helpers/courseUser.topLeadUtils';
import { AuthUserDocument } from '@/controllers/user/graphql/generated/authUser.query.generated';
import {
  ProcessConsultationRequestMutation,
  useProcessConsultationRequestMutation,
} from '@/controllers/courseUsers/graphql/generated/processConsultationRequest.query.generated';

export interface ProcessConsultationRequestOptions {
  name?: string;
  email: string;
  phone: string;
  courseId: number;
  selectedSlug: string;
  source: string;
  newslettersSubscription?: boolean;
  age?: number;
  studentSource?: StudentSource | string;
  promoCode?: PromoCodeLandingFragment | null;
}

type HookResult = [
  (options: ProcessConsultationRequestOptions) => Promise<
    ProcessConsultationRequestMutation['processConsultationRequest']
  >,
];

export const useProcessConsultationRequest = (): HookResult => {
  const collectSignUpData = useCollectSignUpData();

  const [processConsultationRequest] = useProcessConsultationRequestMutation(
    {
      update(cache, { data }) {
        const user = data?.processConsultationRequest.user;

        if (user) {
          cache.writeQuery({
            query: AuthUserDocument,
            data: {
              authUser: user,
            },
          });
        }
      },
    },
  );

  const processConsultationCallback = useCallback(
    async (options: ProcessConsultationRequestOptions) => {
      const signUpData = collectSignUpData();

      const {
        name,
        phone,
        email,
        age,
        selectedSlug,
        courseId,
        source,
        newslettersSubscription,
        studentSource,
        promoCode,
      } = options;

      const birthDate = getBirthDateFromAge(age);

      const { data } = await processConsultationRequest({
        variables: {
          ...signUpData,
          courseId,
          name,
          email,
          phone,
          source,
          newslettersSubscription,
          birthDate,
          studentSource,
          promoCodeSlug: promoCode?.slug,
        },
      });

      const user = data?.processConsultationRequest.user;
      const courseUser = data?.processConsultationRequest.courseUser;
      const conversionValue = data?.processConsultationRequest.conversionValue;

      setAmplitudeUserProperties({
        CourseType: CourseType.Prepaid,
        Course: selectedSlug,
      });

      if (user) {
        updateUserAnalyticsData(user);
      }

      const eventOptions: LandingConsultationFormSubmitted = {
        course: selectedSlug,
        source,
        userPhone: user?.phone ?? undefined,
        promoCodeSlug: promoCode?.slug,
      };

      analyticsSDK.landing.sendConsultationFormSubmittedEvent(eventOptions);

      analyticsSDK.global.sendFxLeadCreatedEvent({
        course: selectedSlug,
        email,
        phone,
        source,
        promoCodeSlug: promoCode?.slug || '',
        conversionValue: conversionValue ?? undefined,
        currencyCode: 'EUR',
      });

      processLeadScoreTypeEvent(
        LeadScoreType.Blank,
        {
          course: selectedSlug,
          email,
          phone,
          source,
          promoCodeSlug: promoCode?.slug || '',
        },
      );

      if (promoCode) {
        analyticsSDK.landing.sendPromoCodeAppliedEvent({
          slug: promoCode.slug,
          source,
          status: promoCode.status,
          type: promoCode.type,
        });
      }

      if (user?.isNewUser) {
        analyticsSDK.landing.sendSignUpFormSubmittedEvent({
          course: selectedSlug,
          courseType: CourseType.Prepaid,
          source: `${source}_consultation`,
          newslettersSubscribed: Boolean(newslettersSubscription),
          email,
          phone: phone || '',
        });
      }

      return {
        user,
        courseUser,
      };
    },
    [
      collectSignUpData,
      processConsultationRequest,
    ],
  );

  return [processConsultationCallback];
};
