import React, { FC, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { FormField } from '@/components/ui/FormElements/FormField';
import { isNotEmpty } from '@/controllers/forms/forms.validator/validationRules';
import { InputEmail } from '@/components/ui/FormElements/FormInputs/InputEmail';
import { makeValidator } from '@/controllers/forms/forms.validator';
import { ConsultationFormFields } from '@/components/courseLanding/CourseConsultationForm/ConsultationForm.typedefs';
import { useEnteredUserValue } from '@/controllers/consultation/useEnteredUserValue';
import { EnteredUserInfoField } from '@/controllers/consultation/consultation.typedefs';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';

export const ConsultationEmail: FC = () => {
  const fieldName = 'email';
  const { t } = useTranslation([I18N_CODES.courseConsultation]);
  const { submitting } = useFormState();
  const [authUser] = useAuthUser({ ssr: false });

  const [savedUserEmail] = useEnteredUserValue(EnteredUserInfoField.Email);

  const form = useForm<ConsultationFormFields>();

  useEffect(() => {
    const email = authUser?.email || savedUserEmail;

    if (email) {
      form.change(fieldName, email);
    }
  }, [authUser?.email, form, savedUserEmail]);

  return (
    <FormField
      label={{
        for: 'consultation-email',
        text: t(`${I18N_CODES.courseConsultation}:consultation_form_email`),
      }}
      renderInput={(inputProps) => (
        <InputEmail
          config={{
            validate: makeValidator(
              isNotEmpty,
              t(`${I18N_CODES.courseConsultation}:consultation_form_required_email`),
            ),
          }}
          {...inputProps}
          placeholder='email@example.com'
          required
          data-qa='consultation-email'
        />
      )}
      disabled={submitting}
      name={fieldName}
    />
  );
};
