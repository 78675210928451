import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconTikTokColor: FCIcon = (props) => (
  <BaseIcon
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_314_35483)">
      <path
        d="M18.5273 10.082C20.3513 11.3705 22.5859 12.1286 24.9993 12.1286V7.53952C24.5425 7.53969 24.087 7.49258 23.6401 7.39894V11.0112C21.2269 11.0112 18.9927 10.2532 17.1682 8.96482V18.3299C17.1682 23.0149 13.3249 26.8125 8.58425 26.8125C6.8154 26.8125 5.17126 26.2841 3.80554 25.3777C5.36433 26.9528 7.53816 27.9298 9.94304 27.9298C14.6841 27.9298 18.5275 24.1322 18.5275 19.4471V10.082H18.5273ZM20.2041 5.45217C19.2718 4.44581 18.6597 3.14526 18.5273 1.70741V1.11719H17.2393C17.5636 2.94462 18.6695 4.50586 20.2041 5.45217ZM6.80398 21.7827C6.28312 21.108 6.00158 20.2824 6.00291 19.4336C6.00291 17.291 7.76082 15.5536 9.92966 15.5536C10.3338 15.5534 10.7355 15.6147 11.1208 15.7354V11.0437C10.6706 10.9827 10.2163 10.9568 9.76218 10.9663V14.6181C9.37675 14.4974 8.9748 14.4361 8.57048 14.4364C6.40173 14.4364 4.64392 16.1736 4.64392 18.3165C4.64392 19.8318 5.52253 21.1437 6.80398 21.7827Z"
        fill="#FF004F"
      />
      <path
        d="M17.1683 8.96472C18.9928 10.2531 21.2269 11.0111 23.6402 11.0111V7.39884C22.2931 7.11527 21.1006 6.41971 20.2041 5.45217C18.6694 4.50577 17.5636 2.94453 17.2394 1.11719H13.8563V19.4469C13.8486 21.5837 12.0937 23.3139 9.92954 23.3139C8.65435 23.3139 7.52134 22.7133 6.80386 21.7826C5.52261 21.1437 4.6439 19.8317 4.6439 18.3166C4.6439 16.1738 6.40171 14.4365 8.57046 14.4365C8.98599 14.4365 9.38647 14.5004 9.76216 14.6182V10.9664C5.10474 11.0615 1.35913 14.822 1.35913 19.447C1.35913 21.7557 2.29185 23.8486 3.80571 25.3779C5.17144 26.2841 6.81548 26.8127 8.58442 26.8127C13.3251 26.8127 17.1684 23.0148 17.1684 18.3299L17.1683 8.96472Z"
        fill="black"
      />
      <path
        d="M23.6401 7.39876V6.42223C22.4254 6.42398 21.2347 6.08782 20.204 5.45218C21.1163 6.43914 22.3176 7.11975 23.6401 7.39895M17.2393 1.1171C17.2084 0.942497 17.1847 0.766725 17.1682 0.590221V0H12.4969V18.3299C12.4895 20.4666 10.7346 22.1968 8.57031 22.1968C7.95676 22.1977 7.3516 22.0559 6.80371 21.7828C7.52119 22.7133 8.6542 23.3139 9.92939 23.3139C12.0935 23.3139 13.8485 21.5838 13.8562 19.447V1.1172L17.2393 1.1171ZM9.76231 10.9663V9.92658C9.37196 9.87389 8.97844 9.8475 8.58447 9.8476C3.84326 9.8476 0 13.6454 0 18.3299C0 21.2669 1.51045 23.8552 3.80576 25.3778C2.29189 23.8486 1.35918 21.7555 1.35918 19.4469C1.35918 14.822 5.10469 11.0614 9.76231 10.9663Z"
        fill="#00F2EA"
      />
    </g>
    <defs>
      <clipPath id="clip0_314_35483">
        <rect width="25" height="28" fill="white" />
      </clipPath>
    </defs>
  </BaseIcon>
);
