import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconHeartList: FCIcon = (props) => (
  <BaseIcon
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M3.375 9C3.375 8.70163 3.49353 8.41548 3.7045 8.2045C3.91548 7.99353 4.20163 7.875 4.5 7.875H30C30.2984 7.875 30.5845 7.99353 30.7955 8.2045C31.0065 8.41548 31.125 8.70163 31.125 9C31.125 9.29837 31.0065 9.58452 30.7955 9.7955C30.5845 10.0065 30.2984 10.125 30 10.125H4.5C4.20163 10.125 3.91548 10.0065 3.7045 9.7955C3.49353 9.58452 3.375 9.29837 3.375 9ZM3.375 16.5C3.375 16.2016 3.49353 15.9155 3.7045 15.7045C3.91548 15.4935 4.20163 15.375 4.5 15.375H13.5C13.7984 15.375 14.0845 15.4935 14.2955 15.7045C14.5065 15.9155 14.625 16.2016 14.625 16.5C14.625 16.7984 14.5065 17.0845 14.2955 17.2955C14.0845 17.5065 13.7984 17.625 13.5 17.625H4.5C4.20163 17.625 3.91548 17.5065 3.7045 17.2955C3.49353 17.0845 3.375 16.7984 3.375 16.5ZM3.375 24C3.375 23.7016 3.49353 23.4155 3.7045 23.2045C3.91548 22.9935 4.20163 22.875 4.5 22.875H15C15.2984 22.875 15.5845 22.9935 15.7955 23.2045C16.0065 23.4155 16.125 23.7016 16.125 24C16.125 24.2984 16.0065 24.5845 15.7955 24.7955C15.5845 25.0065 15.2984 25.125 15 25.125H4.5C4.20163 25.125 3.91548 25.0065 3.7045 24.7955C3.49353 24.5845 3.375 24.2984 3.375 24Z" fill="currentColor" />
    <path d="M19.5 17.5724C19.5 20.2004 22.7445 22.9949 24.735 24.4619C25.416 24.9644 25.7565 25.2149 26.25 25.2149C26.7435 25.2149 27.084 24.9644 27.765 24.4619C29.7555 22.9949 33 20.2019 33 17.5724C33 13.5569 29.2875 12.0569 26.25 15.1589C23.2125 12.0569 19.5 13.5569 19.5 17.5724Z" fill="currentColor" />
  </BaseIcon>
);
