import React, { memo } from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { IconCheckSoft } from '@/components/ui/icons/IconCheckSoft';
import styles from '../ProfessionTechnologiesSection.module.scss';

interface Props {
  text: string;
  iconClassName?: string;
  itemClassName?: string;
}

export const CheckmarkListItem = memo<Props>((props) => {
  const {
    text,
    iconClassName,
    itemClassName,
  } = props;

  return (
    <li
      className={cn(
        typography.newLandingH6,
        styles.skillItem,
        itemClassName,
      )}
    >
      <span className={cn(styles.skillIcon, iconClassName)}>
        <IconCheckSoft />
      </span>
      {text}
    </li>
  );
});
