import React, { memo } from 'react';
import { FAQTitle } from '@/components/professionLanding/FAQTitle/FAQTitle';
import { FaqItem } from '@/components/professionLanding/ProfessionLanding.typedefs';
import { SectionBase, SectionBaseType } from '@/components/landing/BaseSectionWrappers/SectionBase';
import { ContentContainer } from '@/components/landing/BaseSectionWrappers/ContentContainer';
import { COURSE_BLOCKS } from '@/components/courseLanding/CoursePage/CoursePageContent.constants';
import { FAQList } from '../FAQList/FAQList';

interface Props {
  faqs: FaqItem[];
  trackSlug?: string;
}

export const FAQSection = memo<Props>((props) => {
  const {
    faqs,
    trackSlug,
  } = props;

  return (
    <SectionBase
      type={SectionBaseType.Default}
      id={COURSE_BLOCKS.faq}
    >
      <ContentContainer className='grid-x'>
        <FAQTitle />

        <div className='cell large-6 large-offset-1'>
          {faqs && (
            <FAQList
              faqs={faqs}
              trackSlug={trackSlug}
            />
          )}
        </div>
      </ContentContainer>
    </SectionBase>
  );
});
