import React, { memo } from 'react';
import { cn } from '@/lib';
import { Button } from '@/components/ui/Button';
import { HeadingLabel } from '@/components/homepageLanding/HomePageCoverStatistics/HomePageCoverStatistics.typedefs';
import { CoverHeadingLabel } from '@/components/homepageLanding/HomePageCoverStatistics/components/CoverHeadingLabel/CoverHeadingLabel';
import styles from '../../HomePageCoverHeading.module.scss';

interface Props {
  heading: string;
  ctaText: string;
  ctaHref: string;
  subHeading?: string;
  headingLabels?: HeadingLabel[];
}

export const HomeCoverSmall = memo<Props>((props) => {
  const {
    heading,
    ctaText,
    ctaHref,
    subHeading,
    headingLabels,
  } = props;

  return (
    <div className="grid-x align-center">
      <div className={cn(styles.contentWrapper, 'color-dark-blue')}>
        <div className={styles.textsWrapper}>
          {headingLabels && (
            <div className={styles.labelsWrapper}>
              {headingLabels.map(({ id, textCode, imageData }) => (
                <CoverHeadingLabel
                  key={id}
                  imageData={imageData}
                  textCode={textCode}
                />
              ))}
            </div>
          )}

          <h1 className={styles.heading}>
            {heading}
          </h1>

          <p className={styles.subHeading}>
            {subHeading}
          </p>
        </div>

        <Button
          type="submit"
          mode={Button.mode.BrandPrimary}
          size={Button.size.Large}
          className={styles.coverButton}
          text={ctaText}
          href={ctaHref}
        />
      </div>
    </div>
  );
});
