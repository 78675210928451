import React, { FC } from 'react';
import { cn } from '@/lib/classNames';
import { typography } from '@/components/ui/typography';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import { IconEmojiWhiteCheckMark } from '@/components/ui/IconEmojies/IconEmojiWhiteCheckMark';
import {
  ConsultationFormMessageHarness,
} from '@/components/courseLanding/CourseConsultationForm/components/ConsultationFormMessage/ConsultationFormMessage.harness';
import {
  useFormMessageContent,
} from '@/components/courseLanding/CourseConsultationForm/components/ConsultationFormMessage/ConsultationFormMessage.hooks/useFormMessageContent.hook';
import { getSuccessRedirectLink } from '@/components/consultation/consultation.helpers';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import styles from './ConsultationFormMessage.module.scss';

const harness = new ConsultationFormMessageHarness();

interface Props {
  courseSlug?: string;
  className?: string;
  shouldShowTrialContent?: boolean;
}

export const ConsultationFormMessage: FC<Props> = (props) => {
  const { className, courseSlug, shouldShowTrialContent } = props;
  const features = useFeatures();
  const isStudyRouteEnabled = features.isEnabled(Features.StudyRoute);

  const buttonLink = getSuccessRedirectLink({
    courseSlug,
    shouldRedirectToTrialIntro: shouldShowTrialContent,
    isStudyRouteEnabled,
  });
  const pageContent = useFormMessageContent({ shouldShowTrialContent });

  return (
    <div
      className={cn(styles.successMessage, className)}
      data-qa={harness.dataQa}
    >
      <div className={cn(
        styles.successMessageContent,
        'text-center',
      )}
      >
        <div className={cn(styles.successIconWrapper)}>
          <IconEmojiWhiteCheckMark size={32} />
        </div>

        <h3 className={typography.landingH3}>
          {pageContent.title}
        </h3>

        <p
          className={cn(typography.landingTextMain)}
          dangerouslySetInnerHTML={{
            __html: pageContent.subtitle,
          }}
          data-qa={pageContent.dataQa}
        />
      </div>

      <Button
        data-qa={harness.buttonDataQa}
        href={buttonLink}
        mode={ButtonMode.BrandPrimary}
        size={ButtonSize.Large}
        text={pageContent.buttonText}
      />
    </div>
  );
};
