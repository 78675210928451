import { useCallback } from 'react';
import {
  initExitPopupOpenedStorage,
} from '@/components/common/ExitPopup/ExitPopup.constants';
import { useRouterPathWithoutParams } from '@/components/common/ExitPopup/hooks/useRouterPathWithoutParams.hook';

type Output = () => void;

export const useHandleFormInteraction = (): Output => {
  const pathname = useRouterPathWithoutParams();
  const exitPopupOpenedStorage = (
    initExitPopupOpenedStorage(pathname)
  );

  return useCallback(() => {
    exitPopupOpenedStorage.writeToStorage(true);
  }, [exitPopupOpenedStorage]);
};
