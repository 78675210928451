import React, { FC } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { FormField } from '@/components/ui/FormElements/FormField';
import { isNotEmpty } from '@/controllers/forms/forms.validator/validationRules';
import { makeValidator } from '@/controllers/forms/forms.validator';
import { InputTelegram } from '@/components/ui/FormElements/FormInputs/InputTelegram';

export const ConsultationTelegramNickName: FC = () => {
  const fieldName = 'telegramNickName';
  const { t } = useTranslation([
    I18N_CODES.courseConsultation,
    I18N_CODES.validates,
  ]);
  const { submitting } = useFormState();
  const { mutators } = useForm();

  return (
    <FormField
      label={{
        for: 'consultation-telegram',
        text: t(`${I18N_CODES.courseConsultation}:consultation_form_telegram`),
      }}
      validateOnChange
      onChange={(value) => {
        if (value) {
          mutators.trimInputValue?.(fieldName, value);
        }
      }}
      renderInput={(inputProps) => (
        <InputTelegram
          config={{
            validate: makeValidator(
              isNotEmpty,
              t(`${I18N_CODES.validates}:consultation_form_required_telegram`),
            ),
          }}
          {...inputProps}
          placeholder='@mynickname'
          required
          data-qa='consultation-telegram'
        />
      )}
      disabled={submitting}
      name={fieldName}
    />
  );
};
