import React from 'react';
import moneyWithWings from '@/images/generated/icons.emoji.moneyWithWings.s3Key';
import { IconEmojiBase } from '@/components/ui/IconEmojies/IconEmojiBase/IconEmojiBase';
import { FCImage } from '@/components/ui/IconEmojies/typedefs';
import { IconImage } from '@/components/ui/Image/IconImage';

export const IconEmojiMoneyWithWings: FCImage = ({
  size = 56,
  className,
  alt = 'Money mouth face',
}) => (
  <IconEmojiBase className={className}>
    <IconImage
      imageData={moneyWithWings}
      width={size}
      height={size}
      alt={alt}
    />
  </IconEmojiBase>
);
