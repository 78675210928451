import React, { FC, SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const OrangeEllipse: FC<Props> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="280" height="280" viewBox="0 0 280 280" fill="none">
    <g filter="url(#filter0_f_28465_16329)">
      <circle cx="164" cy="116" r="100" fill="url(#paint0_linear_28465_16329)" />
    </g>
    <defs>
      <filter id="filter0_f_28465_16329" x="0" y="-48" width="328" height="328" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="32" result="effect1_foregroundBlur_28465_16329" />
      </filter>
      <linearGradient id="paint0_linear_28465_16329" x1="206.857" y1="343.679" x2="78" y2="6" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7246" />
        <stop offset="1" stopColor="#FF7246" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
