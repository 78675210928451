import React, { memo } from 'react';
import { Image } from '@/components/ui/Image';
import { CompanyFragment } from '@/controllers/companies/graphql/generated/Company.fragment.generated';
import styles from './AnimatedCompaniesLogos.module.scss';

interface Props {
  companies: CompanyFragment[];
}

export const CompanyListItems = memo<Props>((props) => {
  const {
    companies,
  } = props;

  return (
    <>
      {companies.map((company) => (
        <li key={company.id} className={styles.company}>
          <Image
            src={company.logo?.url || ''}
            layout="fill"
            objectFit="contain"
            priority
            alt={company.name}
          />
        </li>
      ))}
    </>
  );
});
