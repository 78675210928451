import React from 'react';
import { FCImage } from '@/components/ui/IconEmojies/typedefs';
import { IconEmojiBase } from '@/components/ui/IconEmojies/IconEmojiBase/IconEmojiBase';
import { IconImage } from '@/components/ui/Image/IconImage';
import christmasTree from '@/images/generated/icons.emoji.christmasTree.s3Key';

export const IconEmojiChristmasTree: FCImage = ({
  size = 56,
  className,
  alt = 'Christmas tree',
}) => (
  <IconEmojiBase className={className}>
    <IconImage
      imageData={christmasTree}
      width={size}
      height={size}
      alt={alt}
    />
  </IconEmojiBase>
);
