import React, { memo } from 'react';
import { cn } from '@/lib';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { CourseType } from '@/controllers/graphql/generated';
import { SectionBase, SectionBaseType } from '@/components/landing/BaseSectionWrappers/SectionBase';
import { ProfessionConsultationTitle } from './components/ProfessionConsultationTitle';
import { ProfessionConsultationFormSection } from './components/ProfessionConsultationFormSection';
import styles from './ConsultationFormSection.module.scss';

interface Props {
  title: string;
  heading: string;
  consultationSlug?: string;
  eventSource?: string;
  courseTypes?: CourseType[];
}

export const ConsultationFormSection = memo<Props>((props) => {
  const {
    title,
    heading,
    consultationSlug,
    eventSource = 'consultation_section',
    courseTypes = [
      CourseType.Postpaid,
      CourseType.Prepaid,
      CourseType.Consultation,
    ],
  } = props;

  const { t } = useTranslation([I18N_CODES.course]);

  const facts = t(`${I18N_CODES.course}:profession_consultation_facts`)
    .split('|');

  return (
    <SectionBase
      type={SectionBaseType.Wide}
      hideFixedCtaButton
    >
      <div className={cn('content-container grid-x width-100 medium-gap-32', styles.consultation)}>
        <ProfessionConsultationTitle
          title={title}
          heading={heading}
          facts={facts}
        />

        <ProfessionConsultationFormSection
          consultationSlug={consultationSlug}
          courseTypes={courseTypes}
          eventSource={eventSource}
        />
      </div>
    </SectionBase>
  );
});
