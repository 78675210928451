import React from 'react';
import { cn } from '@/lib/classNames';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import styles from '@/components/ui/icons/product/Mate/IconMateLogoFull.module.scss';

export const IconMateLogoFullNewYear: FCIcon = ({ className, ...rest }) => (
  <BaseIcon
    {...rest}
    className={cn(className, styles.logoIcon)}
    width="169"
    height="56"
    viewBox="0 0 169 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="mate-logo-full-title">
      <path d="M86.1032 8.16666H82.9038L78.3976 15.6038L73.8689 8.16666H70.6695V23.8947H73.7788V13.7164L78.2174 20.9962H78.5779L83.0165 13.6939V23.8947H86.1032V8.16666Z" />
      <path d="M97.0179 12.6604V13.986C96.2068 12.9749 94.9901 12.3458 93.3454 12.3458C90.3487 12.3458 87.8703 14.9297 87.8703 18.2775C87.8703 21.6254 90.3487 24.2093 93.3454 24.2093C94.9901 24.2093 96.2068 23.5801 97.0179 22.569V23.8947H99.9244V12.6604H97.0179ZM93.8861 21.4456C92.1062 21.4456 90.7768 20.1649 90.7768 18.2775C90.7768 16.3902 92.1062 15.1095 93.8861 15.1095C95.6886 15.1095 97.0179 16.3902 97.0179 18.2775C97.0179 20.1649 95.6886 21.4456 93.8861 21.4456Z" />
      <path d="M108.553 15.4465V12.6604H106.007V9.51478L103.101 10.3911V12.6604H101.141V15.4465H103.101V20.12C103.101 23.1532 104.475 24.3441 108.553 23.8947V21.2659C106.886 21.3557 106.007 21.3333 106.007 20.12V15.4465H108.553Z" />
      <path d="M112.256 19.4684H120.75C120.818 19.0864 120.863 18.7044 120.863 18.2775C120.863 14.9747 118.497 12.3458 115.162 12.3458C111.625 12.3458 109.214 14.9297 109.214 18.2775C109.214 21.6254 111.602 24.2093 115.388 24.2093C117.551 24.2093 119.24 23.333 120.299 21.8051L117.956 20.457C117.46 21.1086 116.559 21.5804 115.433 21.5804C113.901 21.5804 112.661 20.9513 112.256 19.4684ZM112.211 17.2215C112.549 15.7835 113.608 14.9522 115.162 14.9522C116.379 14.9522 117.596 15.6038 117.956 17.2215H112.211Z" />
      <path d="M79.0961 34.4269V35.7525C78.285 34.7414 77.0683 34.1123 75.4235 34.1123C72.4269 34.1123 69.9485 36.6962 69.9485 40.044C69.9485 43.3918 72.4269 45.9757 75.4235 45.9757C77.0683 45.9757 78.285 45.3466 79.0961 44.3355V45.6612H82.0026V34.4269H79.0961ZM75.9643 43.2121C74.1843 43.2121 72.855 41.9314 72.855 40.044C72.855 38.1567 74.1843 36.8759 75.9643 36.8759C77.7667 36.8759 79.0961 38.1567 79.0961 40.044C79.0961 41.9314 77.7667 43.2121 75.9643 43.2121Z" />
      <path d="M89.5499 45.9757C91.758 45.9757 93.6731 44.8074 94.642 43.0548L92.1185 41.6168C91.6679 42.538 90.699 43.0998 89.5274 43.0998C87.7925 43.0998 86.5083 41.819 86.5083 40.044C86.5083 38.2465 87.7925 36.9658 89.5274 36.9658C90.6765 36.9658 91.6453 37.55 92.0959 38.4712L94.5969 37.0108C93.6731 35.2807 91.758 34.1123 89.5499 34.1123C86.1252 34.1123 83.6018 36.6962 83.6018 40.044C83.6018 43.3918 86.1252 45.9757 89.5499 45.9757Z" />
      <path d="M104.114 34.4269V35.7525C103.303 34.7414 102.087 34.1123 100.442 34.1123C97.4451 34.1123 94.9667 36.6962 94.9667 40.044C94.9667 43.3918 97.4451 45.9757 100.442 45.9757C102.087 45.9757 103.303 45.3466 104.114 44.3355V45.6612H107.021V34.4269H104.114ZM100.982 43.2121C99.2025 43.2121 97.8732 41.9314 97.8732 40.044C97.8732 38.1567 99.2025 36.8759 100.982 36.8759C102.785 36.8759 104.114 38.1567 104.114 40.044C104.114 41.9314 102.785 43.2121 100.982 43.2121Z" />
      <path d="M117.745 29.9331V35.7525C116.934 34.719 115.74 34.1123 114.095 34.1123C111.076 34.1123 108.597 36.6962 108.597 40.044C108.597 43.3918 111.076 45.9757 114.095 45.9757C115.74 45.9757 116.934 45.3691 117.745 44.3355V45.6612H120.652V29.9331H117.745ZM114.636 43.2121C112.833 43.2121 111.504 41.9314 111.504 40.044C111.504 38.1567 112.833 36.8759 114.636 36.8759C116.416 36.8759 117.745 38.1567 117.745 40.044C117.745 41.9314 116.416 43.2121 114.636 43.2121Z" />
      <path d="M125.315 41.2349H133.809C133.877 40.8529 133.922 40.4709 133.922 40.044C133.922 36.7411 131.556 34.1123 128.221 34.1123C124.684 34.1123 122.273 36.6962 122.273 40.044C122.273 43.3918 124.662 45.9757 128.447 45.9757C130.61 45.9757 132.3 45.0995 133.359 43.5716L131.015 42.2235C130.52 42.8751 129.618 43.3469 128.492 43.3469C126.96 43.3469 125.72 42.7178 125.315 41.2349ZM125.27 38.988C125.608 37.55 126.667 36.7187 128.221 36.7187C129.438 36.7187 130.655 37.3702 131.015 38.988H125.27Z" />
      <path d="M147.96 34.1123C146.428 34.1123 145.301 34.719 144.603 35.73C143.972 34.6965 142.935 34.1123 141.538 34.1123C140.097 34.1123 139.038 34.674 138.407 35.6177V34.4269H135.5V45.6612H138.407V39.3475C138.407 37.6623 139.285 36.8085 140.525 36.8085C141.741 36.8085 142.417 37.6174 142.417 38.9431V45.6612H145.324V39.3475C145.324 37.6623 146.135 36.8085 147.419 36.8085C148.636 36.8085 149.312 37.6174 149.312 38.9431V45.6612H152.218V38.7408C152.218 35.9323 150.528 34.1123 147.96 34.1123Z" />
      <path d="M161.339 34.4269L158.838 41.9763L155.909 34.4269H152.732L157.351 45.3017C156.81 46.8071 155.999 47.4811 154.4 47.4587V50.1549C157.261 50.3122 159.176 48.8967 160.348 45.6612L164.448 34.4269H161.339Z" />
    </g>

    <g id="mate-logo-full-fill">
      <path d="M34.3817 30.7143C34.3817 32.5 35.5801 33.619 37.234 33.619C38.8879 33.619 40.0864 32.5 40.0864 30.7143C40.0864 28.9286 38.8879 27.8095 37.234 27.8095C35.5801 27.8095 34.3817 28.9286 34.3817 30.7143Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.48975 6.55211C0.181824 9.11905 0.181824 12.4794 0.181824 19.2V36.8C0.181824 43.5206 0.181824 46.8809 1.48975 49.4479C2.64023 51.7058 4.47599 53.5416 6.73394 54.6921C9.30088 56 12.6612 56 19.3818 56H36.9818C43.7025 56 47.0628 56 49.6297 54.6921C51.8877 53.5416 53.7234 51.7058 54.8739 49.4479C56.1818 46.8809 56.1818 43.5206 56.1818 36.8V19.2C56.1818 12.4794 56.1818 9.11905 54.8739 6.55211C53.7234 4.29417 51.8877 2.4584 49.6297 1.30792C47.0628 0 43.7025 0 36.9818 0H19.3818C12.6612 0 9.30088 0 6.73394 1.30792C4.47599 2.4584 2.64023 4.29417 1.48975 6.55211ZM25.6253 20H29.4604V36.6667H25.6253V26.9762L21.2869 34.0476H20.8554L16.5169 26.9762V36.6667H12.6818V20H16.5169L21.0711 27.4048L25.6253 20ZM40.0864 25.881V24.7619H43.6818V36.6667H40.0864V35.5476C39.2954 36.4524 38.1209 37 36.515 37C33.375 37 30.7863 34.2619 30.7863 30.7143C30.7863 27.1667 33.375 24.4286 36.515 24.4286C38.1209 24.4286 39.2954 24.9762 40.0864 25.881Z" />
    </g>

    <g clipPath="url(#clip0_22905_1615)">
      <path d="M43.8852 46.7427H54.1974V56.6012H43.8852V46.7427Z" fill="#C96928" />
      <path d="M43.8853 48.0147H47.2332V56.6012H43.8853V48.0147Z" fill="#9B4912" />
      <path d="M66.6717 45.1845L62.0616 38.8856C63.6623 37.9088 64.293 35.6668 63.0397 33.9546L57.6214 26.5509C58.6899 25.429 58.9702 23.6132 57.9157 22.1719L51.7922 13.8053C50.4304 11.9446 47.6521 11.9446 46.2903 13.8053L40.1667 22.1719C39.1122 23.6132 39.3922 25.429 40.4611 26.5509L35.0428 33.9546C33.7894 35.6668 34.4201 37.9088 36.0205 38.8856L31.4107 45.1845C29.7624 47.4367 31.371 50.6068 34.1619 50.6068H63.9206C66.7114 50.6068 68.32 47.4367 66.6717 45.1845Z" fill="#66A549" />
      <path d="M49.0408 12.4102C48.5782 12.4102 48.115 12.8745 47.8102 13.805L45.0689 22.1719C44.5968 23.6129 44.7219 25.429 45.2007 26.5506L42.7759 33.9543C42.2148 35.6665 42.4969 37.9092 43.2133 38.8856L41.1507 45.1848C40.4118 47.4367 41.1323 50.6068 42.3813 50.6068H34.1616C31.3707 50.6068 29.7625 47.4367 31.4108 45.1848L36.0209 38.8856C34.4195 37.9088 33.7888 35.6665 35.0425 33.9543L40.4608 26.5506C39.3926 25.429 39.1116 23.6125 40.1665 22.1719L46.29 13.805C46.971 12.8745 48.0059 12.4102 49.0408 12.4102Z" fill="#477C27" />
      <path d="M39.4174 51.2089C39.4174 51.8767 38.8763 52.4177 38.2089 52.4177C37.541 52.4177 37 51.8767 37 51.2089C37 50.5414 37.541 50 38.2089 50C38.8763 50 39.4174 50.5414 39.4174 51.2089Z" fill="#DAF3FE" />
      <path d="M56.9713 42.7725C56.9713 43.44 56.4299 43.981 55.7624 43.981C55.0946 43.981 54.5535 43.44 54.5535 42.7725C54.5535 42.1047 55.0946 41.5636 55.7624 41.5636C56.4299 41.5636 56.9713 42.1047 56.9713 42.7725Z" fill="#DAF3FE" />
      <path d="M62.2506 15.8699C62.2506 16.5378 61.7096 17.0788 61.0421 17.0788C60.3743 17.0788 59.8333 16.5378 59.8333 15.8699C59.8333 15.2025 60.3743 14.6614 61.0421 14.6614C61.7096 14.6614 62.2506 15.2025 62.2506 15.8699Z" fill="#9EC2CC" />
      <path d="M34.6014 17.8214C34.4294 17.8214 34.2578 17.7558 34.1266 17.6246C33.8642 17.3626 33.8642 16.9374 34.1266 16.675L35.6461 15.1556C35.9084 14.8932 36.3336 14.8932 36.5956 15.1556C36.858 15.4179 36.858 15.8431 36.5956 16.1051L35.0761 17.6246C34.9453 17.7558 34.7733 17.8214 34.6014 17.8214Z" fill="#DAF3FE" />
      <path d="M36.1209 17.8214C35.9489 17.8214 35.7773 17.7558 35.6461 17.6246L34.1266 16.1051C33.8642 15.8431 33.8642 15.4179 34.1266 15.1556C34.389 14.8932 34.8141 14.8932 35.0761 15.1556L36.5956 16.675C36.858 16.9374 36.858 17.3626 36.5956 17.6246C36.4644 17.7558 36.2928 17.8214 36.1209 17.8214Z" fill="#DAF3FE" />
      <path d="M50.6716 33.8627C50.4996 33.8627 50.3276 33.7971 50.1968 33.6659C49.9344 33.4035 49.9344 32.9784 50.1968 32.716L51.7159 31.1965C51.9783 30.9345 52.4035 30.9345 52.6658 31.1965C52.9278 31.4589 52.9278 31.884 52.6658 32.1464L51.1463 33.6659C51.0151 33.7971 50.8432 33.8627 50.6716 33.8627Z" fill="#DAF3FE" />
      <path d="M52.191 33.8627C52.0191 33.8627 51.8471 33.7971 51.7159 33.6659L50.1968 32.1464C49.9344 31.884 49.9344 31.4589 50.1968 31.1965C50.4588 30.9345 50.8843 30.9345 51.1463 31.1965L52.6658 32.716C52.9278 32.9784 52.9278 33.4035 52.6658 33.6659C52.5346 33.7971 52.3627 33.8627 52.191 33.8627Z" fill="#DAF3FE" />
      <path d="M64.0592 27.9402C63.8876 27.9402 63.7157 27.8746 63.5845 27.7434C63.3221 27.4811 63.3221 27.0559 63.5845 26.7939L65.104 25.2744C65.3663 25.012 65.7915 25.012 66.0535 25.2744C66.3159 25.5364 66.3159 25.9616 66.0535 26.224L64.534 27.7434C64.4032 27.8743 64.2312 27.9402 64.0592 27.9402Z" fill="#9EC2CC" />
      <path d="M65.5787 27.9402C65.4068 27.9402 65.2351 27.8746 65.104 27.7434L63.5845 26.224C63.3221 25.9616 63.3221 25.5364 63.5845 25.2744C63.8468 25.012 64.272 25.012 64.534 25.2744L66.0535 26.7939C66.3159 27.0559 66.3159 27.4811 66.0535 27.7434C65.9227 27.8743 65.7507 27.9402 65.5787 27.9402Z" fill="#9EC2CC" />
    </g>

    <g id='mate-logo-full-text'>
      <path d="M29.4604 20H25.6253L21.0711 27.4048L16.5169 20H12.6818V36.6667H16.5169V26.9762L20.8553 34.0476H21.2868L25.6253 26.9762V36.6667H29.4604V20Z" />
      <path d="M40.0863 24.7619V25.881C39.2953 24.9762 38.1208 24.4286 36.5149 24.4286C33.3749 24.4286 30.7862 27.1667 30.7862 30.7143C30.7862 34.2619 33.3749 37 36.5149 37C38.1208 37 39.2953 36.4524 40.0863 35.5476V36.6667H43.6818V24.7619H40.0863ZM37.234 33.619C35.5801 33.619 34.3816 32.5 34.3816 30.7143C34.3816 28.9286 35.5801 27.8095 37.234 27.8095C38.8879 27.8095 40.0863 28.9286 40.0863 30.7143C40.0863 32.5 38.8879 33.619 37.234 33.619Z" />
    </g>
    <defs>
      <clipPath id="clip0_22905_1615">
        <rect width="42" height="44.5455" fill="white" transform="translate(26.1818 11.4545)" />
      </clipPath>
    </defs>
  </BaseIcon>
);
