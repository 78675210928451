import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconBuildingOffice: FCIcon = (props) => (
  <BaseIcon
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M22.718 12.6836C22.718 12.6797 22.7164 12.676 22.7137 12.6732C22.711 12.6704 22.7094 12.6667 22.7094 12.6628V6.6684C22.7094 5.21544 21.5271 4.0332 20.0727 4.0332H15.2674C13.8131 4.0332 12.6294 5.21544 12.6294 6.6684V7.7772C12.6294 8.0158 12.436 8.2092 12.1974 8.2092H7.19914C5.74474 8.2092 4.5625 9.39144 4.5625 10.8444V28.758C4.5625 30.211 5.74474 31.3932 7.19914 31.3932H22.286C22.5246 31.3932 22.718 31.1998 22.718 30.9612V12.6836Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M28.7126 12.6738H25.0218C24.7832 12.6738 24.5898 12.8672 24.5898 13.1058V30.9619C24.5898 31.2005 24.7832 31.3939 25.0218 31.3939H28.7126C30.167 31.3939 31.3506 30.2116 31.3506 28.7587V15.3091C31.3506 13.8561 30.167 12.6738 28.7126 12.6738Z" fill="white" />
    <path d="M16.5286 15.4944C16.5962 15.4944 16.6639 15.4886 16.7316 15.4757C17.3177 15.3634 17.7022 14.7989 17.5913 14.2128L17.5884 14.1984C17.4761 13.6109 16.9044 13.2336 16.327 13.3459C15.7409 13.4583 15.3564 14.0314 15.4687 14.616C15.5666 15.1344 16.0202 15.4944 16.5286 15.4944Z" fill="white" />
    <path d="M16.3161 18.621C15.7314 18.7376 15.3527 19.3136 15.4708 19.8983C15.573 20.4109 16.0237 20.7666 16.5277 20.7666C16.5983 20.7666 16.6703 20.7594 16.7409 20.745C17.3255 20.6284 17.7057 20.0596 17.5876 19.4749L17.5847 19.4605C17.4681 18.8759 16.9036 18.5044 16.3161 18.621Z" fill="white" />
    <path d="M16.5286 25.935C16.5962 25.935 16.6639 25.9293 16.7316 25.9163C17.3177 25.804 17.7022 25.2395 17.5913 24.6534L17.5884 24.639C17.4761 24.0529 16.9044 23.6699 16.327 23.7865C15.7409 23.8989 15.3564 24.472 15.4687 25.0566C15.5666 25.575 16.0202 25.935 16.5286 25.935Z" fill="white" />
    <path d="M10.7629 15.4944C10.8306 15.4944 10.8983 15.4886 10.966 15.4757C11.552 15.3634 11.9365 14.7989 11.8256 14.2128L11.8228 14.1984C11.7104 13.6109 11.1388 13.2336 10.5613 13.3459C9.97525 13.4583 9.59077 14.0314 9.70309 14.616C9.80101 15.1344 10.2546 15.4944 10.7629 15.4944Z" fill="white" />
    <path d="M10.5543 18.621C9.9697 18.7376 9.59098 19.3136 9.70906 19.8983C9.8113 20.4109 10.262 20.7666 10.766 20.7666C10.8366 20.7666 10.9086 20.7594 10.9791 20.745C11.5638 20.6284 11.9439 20.0596 11.8259 19.4749L11.823 19.4605C11.7063 18.8759 11.1375 18.5044 10.5543 18.621Z" fill="white" />
    <path d="M10.7629 25.935C10.8306 25.935 10.8983 25.9293 10.966 25.9163C11.552 25.804 11.9365 25.2395 11.8256 24.6534L11.8228 24.639C11.7104 24.0529 11.1388 23.6699 10.5613 23.7865C9.97525 23.8989 9.59077 24.472 9.70309 25.0566C9.80101 25.575 10.2546 25.935 10.7629 25.935Z" fill="white" />
  </BaseIcon>
);
