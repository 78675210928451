import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconXColor: FCIcon = (props) => (
  <BaseIcon
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_314_35461)">
      <mask
        id="mask0_314_35461"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <path d="M0 0H32V32H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_314_35461)">
        <path
          d="M22.7668 5.33398H26.3766L18.4913 14.3693L27.7687 26.6664H20.5055L14.8126 19.2098L8.30595 26.6664H4.69283L13.1262 16.9989L4.23047 5.33567H11.6786L16.8167 12.15L22.7668 5.33398ZM21.4975 24.5008H23.4982L10.5858 7.38686H8.44045L21.4975 24.5008Z"
          fill="#7A8399"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_314_35461">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </BaseIcon>
);
