import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconYoutubeColor: FCIcon = (props) => (
  <BaseIcon
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M30.4816 9.21724C30.1143 7.69123 28.8093 6.56539 27.2383 6.39744C23.5182 6.00024 19.7528 5.99817 16.0048 6.00024C12.2562 5.99817 8.49017 6.00024 4.77001 6.39744C3.19995 6.56539 1.8959 7.69123 1.52862 9.21724C1.00588 11.3904 1 13.7627 1 16.0001C1 18.2376 1 20.6096 1.52274 22.7825C1.88939 24.3082 3.19375 25.434 4.76475 25.6023C8.48459 25.9998 12.2503 26.0018 15.9989 25.9998C19.7482 26.0018 23.5129 25.9998 27.2325 25.6023C28.8025 25.4343 30.1078 24.3085 30.4751 22.7825C30.9981 20.6093 31 18.2373 31 16.0001C31 13.7627 31.0043 11.3904 30.4816 9.21724ZM12.1286 20.4538C12.1286 17.2797 12.1286 14.1388 12.1286 10.9645C15.308 12.5512 18.4664 14.1273 21.666 15.7238C18.4763 17.3061 15.3164 18.8727 12.1286 20.4538Z"
      fill="#FF0000"
    />
  </BaseIcon>
);
