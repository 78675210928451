import { SpecialPromoSlug } from '@/components/landing/Promotions/promotions.typedefs';
import { PromoCodeHelpers } from '@/controllers/promoCode/promoCode.helpers';
import { PromoSectionType } from '@/controllers/promoCode/promoCode.typedefs';

interface Options {
  courseSlug?: string;
  professionSlug?: string;
  promoCodeSlug?: string;
}

export class GiftCardsSectionHelpers {
  static getPromoCardLink(options: Options): string {
    const {
      courseSlug,
      professionSlug,
      promoCodeSlug = SpecialPromoSlug.NEW_YEAR_UA_PROMO,
    } = options;

    const eventSource = PromoCodeHelpers.getPromoEventSource({
      promoCodeSlug,
      promoSection: PromoSectionType.CARD,
    });

    const href = PromoCodeHelpers.getConsultationLink({
      promoCodeSlug,
      eventSource,
      courseSlug,
      professionSlug,
    });

    return href;
  }
}
