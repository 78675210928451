import React, { memo } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { Interview } from '@/components/landing/GraduatesInterviewsSection/GraduatesInterviewsSection.typedefs';
import { BackgroundSection } from '../BackgroundSection/BackgroundSection';
import { InterviewsSlider } from './components/InterviewsSlider';

interface Props {
  interviews: Interview[];
}

export const InterviewsSection = memo<Props>((props) => {
  const { interviews } = props;

  const { t } = useTranslation([I18N_CODES.course]);

  return (
    <BackgroundSection
      headerPosition={BackgroundSection.headerTextPosition.CENTER}
      sectionHeading={t(`${I18N_CODES.course}:profession_interviews_heading`)}
      sectionTitle={t(`${I18N_CODES.course}:profession_interviews_title`)}
    >
      <InterviewsSlider
        interviews={interviews}
      />
    </BackgroundSection>
  );
});
