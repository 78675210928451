import React, { memo, useMemo } from 'react';
import { cn } from '@/lib';
import { CompanyFragment } from '@/controllers/companies/graphql/generated/Company.fragment.generated';
import styles from './AnimatedCompaniesLogos.module.scss';
import { CompanyListItems } from './CompanyListItems';
import {
  getFirstFewCompanies,
  getFirstHalfCompanies,
  getSecondFewCompanies,
  getSecondHalfCompanies,
} from './helpers';

interface Props {
  companies: CompanyFragment[];
}

export const AnimatedCompaniesLogos = memo<Props>((props) => {
  const { companies } = props;

  const [
    firstHalfCompanies,
    secondHalfCompanies,
    firstFewCompanies,
    secondFewCompanies,
  ] = useMemo(() => ([
    getFirstHalfCompanies(companies),
    getSecondHalfCompanies(companies),
    getFirstFewCompanies(companies, 3),
    getSecondFewCompanies(companies, 3),
  ]), [companies]);

  return (
    <div>
      <div className='flex-container flex-direction-column gap-16 pr-16 hide-for-small-only'>
        <ul className={cn(styles.companies, styles.companiesDown)}>
          <CompanyListItems
            companies={secondHalfCompanies}
          />
          <CompanyListItems
            companies={secondHalfCompanies}
          />
          <CompanyListItems
            companies={secondFewCompanies}
          />
        </ul>

        <ul className={cn(styles.companies, styles.companiesUp)}>
          <CompanyListItems
            companies={firstHalfCompanies}
          />
          <CompanyListItems
            companies={firstHalfCompanies}
          />
          <CompanyListItems
            companies={firstFewCompanies}
          />
        </ul>
      </div>

      <ul className={cn(styles.companies, styles.companiesHorizontal, 'show-for-small hide-for-medium')}>
        <CompanyListItems
          companies={companies}
        />
        <CompanyListItems
          companies={companies}
        />
        <CompanyListItems
          companies={firstFewCompanies}
        />
      </ul>
    </div>
  );
});
