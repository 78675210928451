import React, { FC } from 'react';
import { cn } from '@/lib';
import {
  GeneralComparisonTableData,
} from '@/components/homepageLanding/GeneralFormatComparison/GeneralComparisonTable.typedefs';
import styles from './TableColumnsView.module.scss';

interface Props {
  data: GeneralComparisonTableData;
}

export const TableColumnsView: FC<Props> = (props) => {
  const {
    data: {
      rows,
      columns,
    },
  } = props;

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.tableHeader}>
        <div className={styles.row}>
          <div className={styles.cell} />

          {columns.map((column) => (
            <div
              key={column.id}
              className={cn(styles.cell, styles.highlightedCell)}
            >
              {column.title}
            </div>
          ))}
        </div>
      </div>

      <div className={cn(styles.tableBody)}>
        {rows.map((row) => (
          <div key={row.id} className={styles.row}>
            <div className={cn(styles.cell, styles.rowTitleCell)}>
              {row.title}
            </div>

            <div className={cn(styles.cell, styles.tableCell)}>
              {row.leftColumn}
            </div>

            <div className={cn(styles.cell, styles.tableCell)}>
              {row.rightColumn}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
