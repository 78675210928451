import React, { FC } from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { AnchorElement } from '@/components/ui/Button/AnchorElement';
import { ActiveLink } from '@/components/common/ActiveLink';
import { TextTag, TextTagMode } from '../TextTag/TextTag';
import styles from './FooterLinks.module.scss';

interface Props {
  route: string;
  title: string;
  isExternal?: boolean;
  textTag?: string;
  className?: string;
}

export const FooterLink: FC<Props> = (props) => {
  const {
    route,
    title,
    isExternal,
    textTag,
    className,
  } = props;

  if (isExternal) {
    return (
      <AnchorElement
        href={route}
        target='_blank'
      >
        <li
          className={cn(
            typography.landingTextMain,
            styles.navListItem,
            className,
          )}
        >
          {title}
          {textTag && (
            <TextTag
              tagMode={TextTagMode.dark}
              text={textTag}
            />
          )}
        </li>
      </AnchorElement>
    );
  }

  return (
    <ActiveLink
      href={route}
    >
      <li
        className={cn(
          typography.landingTextMain,
          styles.navListItem,
          className,
        )}
      >
        {title}
      </li>
    </ActiveLink>
  );
};
