import annaKachurets from '@/images/generated/landing.ourGraduates.annaKachurets.s3Key';
import orestBurmey from '@/images/generated/landing.ourGraduates.orestBurmey.s3Key';
import yevhenMinikh from '@/images/generated/landing.ourGraduates.yevhenMinikh.s3Key';
import viktoriiaMiziukanova from '@/images/generated/landing.ourGraduates.viktoriiaMiziukanova.s3Key';
import ruslanTron from '@/images/generated/landing.ourGraduates.ruslanTron.s3Key';
import oleksandrTuliuliuk from '@/images/generated/landing.ourGraduates.oleksandrTuliuliuk.s3Key';
import { OurGraduateData } from '@/components/homepageLanding/OurGraduatesSection/OurGraduatesSection.typedefs';
import { I18N_CODES } from '@/lib/constants/general';

export const graduatesRawData: OurGraduateData[] = [
  {
    fullName: `${I18N_CODES.home}:ourGraduates.annaKachurets.fullName`,
    employedAt: `${I18N_CODES.home}:ourGraduates.annaKachurets.employedAt`,
    previousProfession: `${I18N_CODES.home}:ourGraduates.annaKachurets.previousProfession`,
    currentProfession: `${I18N_CODES.home}:ourGraduates.annaKachurets.currentProfession`,
    articleLink: 'https://happymonday.ua/opanuvala-novu-profesiyu-za-3-5-misyatsya',
    imageData: annaKachurets,
  },
  {
    fullName: `${I18N_CODES.home}:ourGraduates.orestBurmey.fullName`,
    employedAt: `${I18N_CODES.home}:ourGraduates.orestBurmey.employedAt`,
    previousProfession: `${I18N_CODES.home}:ourGraduates.orestBurmey.previousProfession`,
    currentProfession: `${I18N_CODES.home}:ourGraduates.orestBurmey.currentProfession`,
    articleLink: 'https://dev.ua/news/orest-1724734630',
    imageData: orestBurmey,
  },
  {
    fullName: `${I18N_CODES.home}:ourGraduates.yevhenMinikh.fullName`,
    employedAt: `${I18N_CODES.home}:ourGraduates.yevhenMinikh.employedAt`,
    previousProfession: `${I18N_CODES.home}:ourGraduates.yevhenMinikh.previousProfession`,
    currentProfession: `${I18N_CODES.home}:ourGraduates.yevhenMinikh.currentProfession`,
    articleLink: 'https://dou.ua/lenta/interviews/former-lawyer-junior-qa-story/',
    imageData: yevhenMinikh,
  },
  {
    fullName: `${I18N_CODES.home}:ourGraduates.viktoriiaMiziukanova.fullName`,
    employedAt: `${I18N_CODES.home}:ourGraduates.viktoriiaMiziukanova.employedAt`,
    previousProfession: `${I18N_CODES.home}:ourGraduates.viktoriiaMiziukanova.previousProfession`,
    currentProfession: `${I18N_CODES.home}:ourGraduates.viktoriiaMiziukanova.currentProfession`,
    articleLink: 'https://speka.media/yak-stati-frontend-developerkoyu-pid-cas-vtorgnennya-svitcerka-pro-posuk-roboti-ta-pidtrimku-blizkix-pk5e5p',
    imageData: viktoriiaMiziukanova,
  },
  {
    fullName: `${I18N_CODES.home}:ourGraduates.ruslanTron.fullName`,
    employedAt: `${I18N_CODES.home}:ourGraduates.ruslanTron.employedAt`,
    previousProfession: `${I18N_CODES.home}:ourGraduates.ruslanTron.previousProfession`,
    currentProfession: `${I18N_CODES.home}:ourGraduates.ruslanTron.currentProfession`,
    articleLink: 'https://dou.ua/forums/topic/42147/',
    imageData: ruslanTron,
  },
  {
    fullName: `${I18N_CODES.home}:ourGraduates.oleksandrTuliuliuk.fullName`,
    employedAt: `${I18N_CODES.home}:ourGraduates.oleksandrTuliuliuk.employedAt`,
    previousProfession: `${I18N_CODES.home}:ourGraduates.oleksandrTuliuliuk.previousProfession`,
    currentProfession: `${I18N_CODES.home}:ourGraduates.oleksandrTuliuliuk.currentProfession`,
    articleLink: 'https://dev.ua/news/tuliuliuk-1731962557',
    imageData: oleksandrTuliuliuk,
  },
]
  .map((data, index) => ({ ...data, id: index }));
