import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SectionBase, SectionBaseType } from '@/components/landing/BaseSectionWrappers/SectionBase';
import { ContentContainer } from '@/components/landing/BaseSectionWrappers/ContentContainer';
import { BaseSectionHeader } from '@/components/landing/BaseSectionWrappers/BaseSectionHeader';
import maleWink from '@/images/generated/icons.emoji.maleWink.s3Key';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { BackgroundColorMode } from '@/components/professionLanding/BubblePhrase/BubblePhrase';
import { AnchorElement } from '@/components/ui/Button/AnchorElement';
import { SOCIAL_MEDIA_LINKS } from '@/components/homepageLanding/HomepageLanding.content';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import styles from './SocialMediasSection.module.scss';

export const SocialMediasSection: FC = () => {
  const { t } = useTranslation([I18N_CODES.home]);
  const { subDomain } = useSubDomainContext();

  const socialMedias = SOCIAL_MEDIA_LINKS[subDomain];

  return (
    <SectionBase type={SectionBaseType.Default}>
      <ContentContainer>
        <div className="mb-56 medium-mb-32 small-mb-8">
          <BaseSectionHeader
            title={t(`${I18N_CODES.home}:social_networks_title`)}
            personImageData={maleWink}
            quote={t(`${I18N_CODES.home}:social_networks_phrase`)}
            phraseBackgroundColorMode={BackgroundColorMode.Gray}
          />
        </div>

        <Swiper
          className="overflow-visible width-100"
          spaceBetween={16}
          slidesPerView="auto"
          grabCursor
        >
          {socialMedias && socialMedias.map(({ id, Icon, link }) => (
            <SwiperSlide key={id} className={styles.slide}>
              <AnchorElement
                key={id}
                href={link}
                target='_blank'
                rel="nofollow noreferrer noopener"
                className={styles.linkElement}
              >
                <Icon className={styles.icon} />
              </AnchorElement>
            </SwiperSlide>
          ))}
        </Swiper>
      </ContentContainer>
    </SectionBase>
  );
};
