import { useMemo } from 'react';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { excludeEntitiesFromList } from '@/controllers/courses/courses.utils/excludeEntitiesFromList';
import { ProfessionListInfoFragment } from '@/controllers/profession/generated/professionListInfo.fragment.generated';
import {
  ActiveProfessionsListInfoQueryHookResult,
  useActiveProfessionsListInfoQuery,
} from '@/controllers/profession/generated/activeProfessionsListInfo.query.generated';

type Output = [
  ProfessionListInfoFragment[],
  ActiveProfessionsListInfoQueryHookResult,
];

type HookArgs = {
  skip?: boolean;
};

export const useActiveProfessionsListInfo = (args: HookArgs): Output => {
  const { skip } = args;
  const { subDomain, language } = useSubDomainContext();

  const queryResult = useActiveProfessionsListInfoQuery({
    variables: {
      domain: subDomain,
      lang: language,
    },
    skip,
  });

  const content = useMemo(
    () => {
      const result = queryResult.data?.professionsListWithCourses ?? [];

      return excludeEntitiesFromList(result);
    },
    [queryResult.data?.professionsListWithCourses],
  );

  return [content, queryResult];
};
