import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconLinkedinColor: FCIcon = (props) => (
  <BaseIcon
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M27.994 28V27.999H28V19.197C28 14.891 27.073 11.574 22.039 11.574C19.619 11.574 17.995 12.902 17.332 14.161H17.262V11.976H12.489V27.999H17.459V20.065C17.459 17.976 17.855 15.956 20.442 15.956C22.991 15.956 23.029 18.34 23.029 20.199V28H27.994Z"
      fill="#0077B5"
    />
    <path d="M4.396 11.977H9.372V28H4.396V11.977Z" fill="#0077B5" />
    <path
      d="M6.882 4C5.291 4 4 5.291 4 6.882C4 8.473 5.291 9.791 6.882 9.791C8.473 9.791 9.764 8.473 9.764 6.882C9.763 5.291 8.472 4 6.882 4Z"
      fill="#0077B5"
    />
  </BaseIcon>
);
