import React, { FC } from 'react';
import { cn } from '@/lib';
import {
  PurpleEllipse,
} from '@/components/professionLanding/GiftCardsSection/static/PurpleEllipse';
import {
  PinkEllipse,
} from '@/components/professionLanding/GiftCardsSection/static/PinkEllipse';
import {
  OrangeEllipse,
} from '@/components/professionLanding/GiftCardsSection/static/OrangeEllipse';
import {
  NavyEllipse,
} from '@/components/professionLanding/GiftCardsSection/static/NavyEllipse';
import {
  GreenEllipse,
} from '@/components/professionLanding/GiftCardsSection/static/GreenEllipse';
import {
  BlueEllipse,
} from '@/components/professionLanding/GiftCardsSection/static/BlueEllipse';
import {
  RedEllipse,
} from '@/components/professionLanding/GiftCardsSection/static/RedEllipse';
import styles from './Decorations.module.scss';

interface Props {
  index: number;
}

export const Decorations: FC<Props> = ({ index }) => {
  switch (index) {
    case 0:
      return (
        <>
          <PurpleEllipse
            className={cn(styles.ellipse, styles.card1PurpleEllipse)}
          />
          <PinkEllipse
            className={cn(styles.ellipse, styles.card1PinkEllipse)}
          />
          <OrangeEllipse
            className={cn(styles.ellipse, styles.card1OrangeEllipse)}
          />
        </>
      );
    case 1:
      return (
        <>
          <PurpleEllipse
            className={cn(styles.ellipse, styles.card2PurpleEllipse)}
          />
          <NavyEllipse
            className={cn(styles.ellipse, styles.card2NavyEllipse)}
          />
          <GreenEllipse
            className={cn(styles.ellipse, styles.card2GreenEllipse)}
          />
        </>
      );
    case 2:
      return (
        <>
          <PurpleEllipse
            className={cn(styles.ellipse, styles.card3PurpleEllipse)}
          />
          <NavyEllipse
            className={cn(styles.ellipse, styles.card3NavyEllipse)}
          />
          <BlueEllipse
            className={cn(styles.ellipse, styles.card3BlueEllipse)}
          />
        </>
      );
    case 3:
      return (
        <>
          <PurpleEllipse
            className={cn(styles.ellipse, styles.card4PurpleEllipse)}
          />
          <PinkEllipse
            className={cn(styles.ellipse, styles.card4PinkEllipse)}
          />
          <RedEllipse
            className={cn(styles.ellipse, styles.card4RedEllipse)}
          />
        </>
      );
    default:
      return null;
  }
};
