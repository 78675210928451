import React, { memo } from 'react';
import { PromoBanner } from '@/components/landing/Promotions/components/PromoBanner';

import { ELLIPSE_COLOR_BLUE, ELLIPSE_COLOR_PINK, ELLIPSE_COLOR_PURPLE } from '@/components/landing/Promotions/promotions.constants';
import { PromoCodeHelpers } from '@/controllers/promoCode/promoCode.helpers';
import { PromoLandingBanner, PromoSectionType } from '@/controllers/promoCode/promoCode.typedefs';
import { COURSE_BLOCKS } from '@/components/courseLanding/CoursePage/CoursePageContent.constants';
import { IconEmojiChristmasTree } from '@/components/ui/IconEmojies/IconEmojiChristmasTree';
import { useCtaContext } from '@/controllers/cta/Cta.context';
import { PageType } from '@/components/landing/Promotions/promotions.typedefs';
import { useIsNewYearThemeEnabled } from '../components/PromoBanner/hooks/useIsNewYearThemeEnabled';

interface Props extends PromoLandingBanner {
  courseSlug?: string;
  professionSlug?: string;
  shouldShowRedesigned?: boolean;
}

export const UniversalPromoBanner = memo<Props>((props) => {
  const {
    courseSlug,
    shouldShowRedesigned,
    slug: promoCodeSlug,
    titleText,
    buttonText,
    timeSettings,
    professionSlug,
  } = props;

  const isNewYearThemeEnabled = useIsNewYearThemeEnabled(promoCodeSlug);

  const eventSource = PromoCodeHelpers.getPromoEventSource({
    promoCodeSlug,
    promoSection: PromoSectionType.BANNER,
  });

  const { pageType } = useCtaContext();

  const href = isNewYearThemeEnabled && pageType !== PageType.COURSES
    ? `#${COURSE_BLOCKS.newYearCards}`
    : PromoCodeHelpers.getConsultationLink({
      promoCodeSlug,
      eventSource,
      courseSlug,
      professionSlug,
    });

  const promoCodeExpireAt = PromoCodeHelpers.getTimerExpiredAtTime(
    timeSettings,
  );

  if (!promoCodeExpireAt) {
    return null;
  }

  return (
    <PromoBanner
      promoName={promoCodeSlug}
      source={eventSource}
      TitleIcon={IconEmojiChristmasTree}
      tagText={titleText}
      DecorIcon={isNewYearThemeEnabled
        ? IconEmojiChristmasTree
        : undefined}
      timerEndAt={promoCodeExpireAt}
      buttonText={buttonText}
      ellipseColorLeft={isNewYearThemeEnabled
        ? ELLIPSE_COLOR_BLUE
        : ELLIPSE_COLOR_PURPLE}
      ellipseColorRight={isNewYearThemeEnabled
        ? ELLIPSE_COLOR_BLUE
        : ELLIPSE_COLOR_PINK}
      href={href}
      shouldShowRedesigned={shouldShowRedesigned}
      shouldShowMobileTitleIcon={isNewYearThemeEnabled}
    />
  );
});
