import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconDollarSend: FCIcon = (props) => (
  <BaseIcon
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M26.0294 13.4958C21.5443 13.4958 17.9078 9.8593 17.9078 5.37418C17.9078 4.99218 17.9375 4.61796 17.9902 4.25049C18.0202 4.0415 17.8851 3.84112 17.6767 3.80722C17.0449 3.70442 16.3978 3.64746 15.7382 3.64746C9.03221 3.64746 3.57812 9.10282 3.57812 15.8074C3.57812 22.5121 9.03221 27.9674 15.7382 27.9674C22.4428 27.9674 27.8982 22.5121 27.8982 15.8074C27.8982 15.0918 27.8351 14.3907 27.7153 13.7089C27.6788 13.5011 27.4765 13.369 27.268 13.401C26.8641 13.463 26.4508 13.4958 26.0294 13.4958Z" fill="#C1C1FF" />
    <path fillRule="evenodd" clipRule="evenodd" d="M28.3453 3.50475C28.248 3.27051 28.0611 3.08363 27.8269 2.98635C27.7117 2.93899 27.5901 2.91467 27.4672 2.91467L27.4595 2.91211H23.7565C23.2266 2.91211 22.7965 3.34219 22.7965 3.87211C22.7965 4.40203 23.2266 4.83211 23.7565 4.83211H25.1427L21.7802 8.19851C21.4064 8.57355 21.4064 9.18283 21.7814 9.55659C21.9683 9.74347 22.2141 9.83691 22.4598 9.83691C22.7056 9.83691 22.9514 9.74347 23.1395 9.55659L26.4995 6.19275V7.58539C26.4995 8.11531 26.9296 8.54539 27.4595 8.54539C27.9894 8.54539 28.4195 8.11531 28.4195 7.58539V3.87211C28.4195 3.74667 28.3939 3.62251 28.3453 3.50475Z" fill="#5955F4" />
    <path d="M16.0168 20.6615H15.9426V21.2247C15.9426 21.7546 15.5125 22.1847 14.9826 22.1847C14.4527 22.1847 14.0226 21.7546 14.0226 21.2247V20.6615H12.6568C12.1269 20.6615 11.6968 20.2314 11.6968 19.7015C11.6968 19.1716 12.1269 18.7415 12.6568 18.7415H16.0168C16.4853 18.7415 16.8668 18.3626 16.8668 17.898C16.8668 17.432 16.4853 17.0532 16.0168 17.0532H13.9496C12.4213 17.0532 11.1797 15.8064 11.1797 14.2756C11.1797 12.7498 12.4213 11.5108 13.9496 11.5108H14.0226V10.9463C14.0226 10.4164 14.4527 9.98633 14.9826 9.98633C15.5125 9.98633 15.9426 10.4164 15.9426 10.9463V11.5108H17.3096C17.8396 11.5108 18.2696 11.9409 18.2696 12.4708C18.2696 13.0007 17.8396 13.4308 17.3096 13.4308H13.9496C13.4812 13.4308 13.0997 13.8084 13.0997 14.2756C13.0997 14.7479 13.4812 15.1332 13.9496 15.1332H16.0168C17.5439 15.1332 18.7868 16.3722 18.7868 17.898C18.7868 19.4212 17.5439 20.6615 16.0168 20.6615Z" fill="#5955F4" />
  </BaseIcon>
);
