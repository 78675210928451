import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconHeadphonesDuotone: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12V14.793C21.8631 14.753 21.9842 14.7407 22.103 14.7572C22.2219 14.7737 22.335 14.8184 22.433 14.8877C22.5309 14.957 22.6109 15.0487 22.666 15.1553C22.7212 15.2618 22.75 15.38 22.75 15.5V17.5C22.75 17.6989 22.671 17.8897 22.5303 18.0303C22.3897 18.171 22.1989 18.25 22 18.25C21.8011 18.25 21.6103 18.171 21.4697 18.0303C21.329 17.8897 21.25 17.6989 21.25 17.5V17H20.25V12C20.25 9.81196 19.3808 7.71354 17.8336 6.16637C16.2865 4.61919 14.188 3.75 12 3.75C9.81196 3.75 7.71354 4.61919 6.16637 6.16637C4.61919 7.71354 3.75 9.81196 3.75 12V17H2.75V17.5C2.75 17.6989 2.67098 17.8897 2.53033 18.0303C2.38968 18.171 2.19891 18.25 2 18.25C1.80109 18.25 1.61032 18.171 1.46967 18.0303C1.32902 17.8897 1.25 17.6989 1.25 17.5V15.5C1.25002 15.38 1.27882 15.2618 1.33398 15.1553C1.38915 15.0487 1.46907 14.957 1.56703 14.8877C1.665 14.8184 1.77815 14.7737 1.89699 14.7572C2.01583 14.7407 2.13689 14.753 2.25 14.793V12Z"
      fill="#939BAE"
    />
    <path
      d="M8 14.0499C8 13.1999 8 12.7759 7.79 12.4799C7.68284 12.3309 7.54292 12.2084 7.381 12.1219C7.056 11.9479 6.618 11.9879 5.741 12.0689C4.261 12.2029 3.523 12.2709 2.993 12.6399C2.72672 12.8252 2.50166 13.0635 2.332 13.3399C2 13.8849 2 14.5999 2 16.0299V17.7699C2 19.1869 2 19.8959 2.338 20.4429C2.465 20.6479 2.624 20.8329 2.809 20.9899C3.304 21.4129 4.026 21.5449 5.471 21.8079C6.487 21.9939 6.996 22.0869 7.371 21.8909C7.51033 21.8176 7.63033 21.7219 7.731 21.6039C8 21.2849 8 20.7859 8 19.7889V14.0499ZM16 14.0499C16 13.1999 16 12.7759 16.21 12.4799C16.315 12.3319 16.455 12.2089 16.619 12.1219C16.944 11.9479 17.382 11.9879 18.259 12.0689C19.739 12.2029 20.477 12.2709 21.007 12.6399C21.275 12.8279 21.5 13.0659 21.668 13.3399C22 13.8839 22 14.5999 22 16.0299V17.7699C22 19.1869 22 19.8959 21.662 20.4429C21.535 20.6479 21.376 20.8329 21.191 20.9899C20.696 21.4129 19.974 21.5449 18.529 21.8079C17.513 21.9939 17.004 22.0869 16.629 21.8909C16.4916 21.8194 16.3694 21.7219 16.269 21.6039C16 21.2849 16 20.7859 16 19.7889V14.0499Z"
      fill="#939BAE"
    />
  </BaseIcon>
);
