import React, { FC, SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const GreenEllipse: FC<Props> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="280" height="328" viewBox="0 0 280 328" fill="none">
    <g filter="url(#filter0_f_28471_21019)">
      <circle cx="116" cy="164" r="100" fill="url(#paint0_linear_28471_21019)" />
    </g>
    <defs>
      <filter id="filter0_f_28471_21019" x="-48" y="0" width="328" height="328" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="32" result="effect1_foregroundBlur_28471_21019" />
      </filter>
      <linearGradient id="paint0_linear_28471_21019" x1="-9" y1="248.167" x2="229.333" y2="88.1667" gradientUnits="userSpaceOnUse">
        <stop stopColor="#27C069" />
        <stop offset="1" stopColor="#27C069" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
