import React, { memo } from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { FaqItem } from '@/components/professionLanding/ProfessionLanding.typedefs';
import { FAQListItem } from './components/FAQListItem';
import styles from './FAQList.module.scss';

interface Props {
  faqs: FaqItem[];
  trackSlug?: string;
}

export const FAQList = memo<Props>((props) => {
  const {
    faqs,
    trackSlug,
  } = props;

  const { t } = useTranslation([I18N_CODES.course, I18N_CODES.home]);

  return (
    <ul className={styles.moduleList}>
      {faqs.map((faq) => (
        <FAQListItem
          key={faq.id}
          title={t(faq.titleCode)}
          content={t(faq.contentCode, { context: trackSlug })}
          shouldShowCta={faq?.shouldShowCta}
          ctaText={faq?.ctaTextCode && t(faq.ctaTextCode)}
        />
      ))}
    </ul>
  );
});
