import React, { FC, SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const PinkEllipse: FC<Props> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="328" height="280" viewBox="0 0 328 280" fill="none">
    <g filter="url(#filter0_f_28465_16330)">
      <circle cx="164" cy="116" r="100" fill="url(#paint0_linear_28465_16330)" fillOpacity="0.5" />
    </g>
    <defs>
      <filter id="filter0_f_28465_16330" x="0" y="-48" width="328" height="328" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="32" result="effect1_foregroundBlur_28465_16330" />
      </filter>
      <linearGradient id="paint0_linear_28465_16330" x1="219.949" y1="397.875" x2="-45.8125" y2="290.225" gradientUnits="userSpaceOnUse">
        <stop stopColor="#ED19C2" />
        <stop offset="1" stopColor="#ED19C2" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
