import React, { FC } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { IconImage } from '@/components/ui/Image/IconImage';
import { MAIN_BLOCK_FACTS } from '@/components/homepageLanding/HomePageCoverStatistics/HomePageCoverStatistics.content';
import { typography } from '@/components/ui/typography';
import { cn } from '@/lib';
import styles from './FactsBlockMain.module.scss';

export const FactsBlockMain: FC = () => {
  const { t } = useTranslation([I18N_CODES.home]);

  return (
    <div className={styles.blockWrapper}>
      {MAIN_BLOCK_FACTS.map(({ id, imageData, textCode }) => (
        <div
          key={id}
          className={styles.fact}
        >
          <div className={styles.icon}>
            <IconImage
              imageData={imageData}
              width={32}
              height={32}
            />
          </div>

          <p className={cn(typography.newLandingTextSecondary, styles.text)}>
            {t(textCode)}
          </p>
        </div>
      ))}
    </div>
  );
};
