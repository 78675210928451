import React, {
  FC,
  useCallback,
  useRef,
  useState,
} from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import {
  ItemActionButton,
} from '@/components/professionLanding/CourseProgram/components/ItemActionButton/ItemActionButton';
import styles from './FooterLinkMobile.module.scss';

interface Props {
  title: string;
  children: React.ReactNode;
}

export const FooterLinkMobile: FC<Props> = ((props) => {
  const {
    title,
    children,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenContent = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const ref = useRef<HTMLUListElement>(null);

  const height = isOpen
    ? ref.current?.offsetHeight
    : 0;

  return (
    <div
      className={cn(typography.newLandingH5, styles.moduleList)}
    >
      <div className={styles.moduleListItem}>
        <p>{title}</p>

        <ItemActionButton
          onClick={handleOpenContent}
          isOpen={isOpen}
          mode={ItemActionButton.mode.LIGHT}
        />
      </div>

      <div className={styles.contentWrapper} style={{ height }}>
        <ul className={styles.content} ref={ref}>
          {children}
        </ul>
      </div>
    </div>
  );
});
