import React, { FC, memo } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { cn } from '@/lib';
import styles from './CardLabel.module.scss';

export const CardLabelNew: FC = memo(() => {
  const { t } = useTranslation([I18N_CODES.course]);

  return (
    <span className={cn(styles.label, styles.black)}>
      {t(`${I18N_CODES.course}:profession_card_label_new`)}
    </span>
  );
});
