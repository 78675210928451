import cometIcon from '@/images/generated/professionLanding.cometIcon.s3Key';
import flatColorGoogleIcon from '@/images/generated/professionLanding.flatColorGoogleIcon.s3Key';
import globusDuotonIcon from '@/images/generated/professionLanding.globusDuotonIcon.s3Key';
import academyCapDuotonIcon from '@/images/generated/professionLanding.academyCapDuotonIcon.s3Key';
import goldStar from '@/images/generated/icons.general.goldStar.s3Key';
import peopleLogo from '@/images/generated/icons.general.peopleLogo.s3Key';
import {
  BlockFact,
  HeadingLabel,
} from '@/components/homepageLanding/HomePageCoverStatistics/HomePageCoverStatistics.typedefs';
import { I18N_CODES } from '@/lib/constants/general';

export const MAIN_BLOCK_FACTS: BlockFact[] = [
  {
    id: 1,
    imageData: cometIcon,
    textCode: `${I18N_CODES.home}:main_fact_our_mission`,
  },
  {
    id: 2,
    imageData: flatColorGoogleIcon,
    textCode: `${I18N_CODES.home}:main_fact_google_award`,
  },
  {
    id: 3,
    imageData: globusDuotonIcon,
    textCode: `${I18N_CODES.home}:main_fact_worldwide`,
  },
  {
    id: 4,
    imageData: academyCapDuotonIcon,
    textCode: `${I18N_CODES.home}:main_fact_experience`,
  },
];

export const HEADING_LABELS: HeadingLabel[] = [
  {
    id: 1,
    imageData: peopleLogo,
    textCode: `${I18N_CODES.home}:home_cover_label_people`,
  },
  {
    id: 2,
    imageData: goldStar,
    textCode: `${I18N_CODES.home}:home_cover_label_gold_star`,
  },
];
