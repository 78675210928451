import React, { FC } from 'react';
import {
  GeneralComparisonTableData,
} from '@/components/homepageLanding/GeneralFormatComparison/GeneralComparisonTable.typedefs';
import styles from './TableFeedView.module.scss';

interface Props {
  data: GeneralComparisonTableData;
}

export const TableFeedView: FC<Props> = (props) => {
  const {
    data: {
      rows,
      columns,
    },
  } = props;

  return (
    <div className={styles.tableWrapper}>
      {/* Feed View for Left Column */}
      <div className={styles.columnWrapper}>
        <div className={styles.tableHeader}>
          {columns[0]?.title}
        </div>

        <div className={styles.tableBody}>
          {rows.map((row) => (
            <div key={row.id} className={styles.row}>
              <div className={styles.rowTitle}>
                {row.title}
              </div>

              <div className={styles.rowContent}>
                {row.leftColumn}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Feed View for Right Column */}
      <div className={styles.columnWrapper}>
        <div className={styles.tableHeader}>
          {columns[1]?.title}
        </div>

        <div className={styles.tableBody}>
          {rows.map((row) => (
            <div key={row.id} className={styles.row}>
              <div className={styles.rowTitle}>
                {row.title}
              </div>

              <div className={styles.rowContent}>
                {row.rightColumn}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
