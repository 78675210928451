import { SUB_DOMAINS } from '@/middleware/i18n/i18n.config';
import { i18n } from '@/middleware/i18n';

export const getTranslateCodeByDomain = (
  placeholderNameCode: string,
  subDomain: SUB_DOMAINS,
): string => {
  const withSubdomain = `${placeholderNameCode}|${subDomain}`;

  if (i18n.exists(withSubdomain)) {
    return withSubdomain;
  }

  return placeholderNameCode;
};
