import React, {
  FC,
} from 'react';
import {
  RequestStatus,
} from '@/components/courseLanding/CourseConsultationForm/components/ConsultationPromoCode/ConsultationPromoCode.typedefs';
import { cn } from '@/lib';
import { IconErrorForm } from '@/components/ui/icons/IconErrorForm';
import { IconSuccessCheck } from '@/components/ui/icons/IconSuccessCheck';
import styles from '../ConsultationPromoCode.module.scss';

interface Props {
  requestStatus: RequestStatus | null;
  message: string;
}

export const PromoCodeMessage: FC<Props> = (props) => {
  const {
    requestStatus,
    message,
  } = props;

  return (
    <p
      data-qa='promo-code-input-message'
      className={cn(styles.message, 'mt-4')}
    >
      {requestStatus === RequestStatus.Error && (
        <IconErrorForm className={styles.iconError} />
      )}

      {requestStatus === RequestStatus.Success && (
        <IconSuccessCheck className={styles.iconSuccess} />
      )}

      <span>
        {message}
      </span>
    </p>
  );
};
