import React, { FC, memo } from 'react';
import { Navigation } from 'swiper';
import { Swiper } from 'swiper/react';
import { cn } from '@/lib';
import styles from './CardsSlider.module.scss';

interface Props {
  className?: string;
}

export const CardsSlider: FC<Props> = memo((props) => {
  const { children, className } = props;

  return (
    <div className='width-100 flex-container'>
      <Swiper
        className={cn(styles.container, className)}
        modules={[Navigation]}
        spaceBetween={16}
        slidesPerView={5}
        speed={500}
        grabCursor
        autoplay={{
          disableOnInteraction: true,
        }}
        breakpoints={{
          1200: {
            slidesPerView: 5,
          },
          320: {
            slidesPerView: 'auto',
          },
        }}
      >
        {children}
      </Swiper>
    </div>
  );
});
