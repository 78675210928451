import { LandingPromoInfoFragment } from '@/controllers/promoCode/generated/landingPromoInfo.fragment.generated';
import {
  PromoLandingElements,
  NormalizedLandingPromo,
  PromoElementType,
  PromoElementTypeMapper,
  PromoTimeSettings,
  Priorities,
} from '@/controllers/promoCode/promoCode.typedefs';
import { MinimumArray } from '@/lib/helpers/utility-types';
import { checkArrayLength } from '@/lib/helpers/checkArrayLength';
import { DAY } from '@/constants';
import { EMPTY_PROMO_LANDING_ELEMENTS } from '@/controllers/promoCode/promoCode.constant';
import { ConsultationCourses } from '@/components/courseLanding/CourseLanding.constants';
import { ROUTES } from '@/controllers/router/router.contants';
import { createQueryString } from '@/controllers/router/router.utils/createQueryString';

export class PromoCodeHelpers {
  private static normalizeSinglePromo(
    promo: LandingPromoInfoFragment,
  ): NormalizedLandingPromo {
    const {
      id,
      slug,
      pagePattern = null,
      pageElements,
      startedAt,
      expiredAt = null,
      timerConfig,
      bannerTitleText,
      bannerButtonText,
      pageSectionTitleText,
      pageSectionDescriptionText,
      pageSectionButtonText,
    } = promo;

    return {
      id,
      slug,
      pagePattern,
      pageElements: pageElements.map(({ elementType }) => elementType),

      startedAt,
      expiredAt,
      timerConfig: {
        repeatDaysSteps: timerConfig?.repeatDaysSteps || null,
        hideDays: timerConfig?.hideDays || null,
      },

      bannerTitleText,
      bannerButtonText,
      pageSectionTitleText,
      pageSectionDescriptionText,
      pageSectionButtonText,
    };
  }

  static normalizeActiveLandingPromosResponse(
    promos: LandingPromoInfoFragment[],
  ): NormalizedLandingPromo[] {
    return promos.map((promo) => this.normalizeSinglePromo(promo));
  }

  private static preparePromoLandingElements(
    promo: NormalizedLandingPromo,
  ): PromoLandingElements {
    const {
      id,
      slug,
      bannerTitleText,
      bannerButtonText,
      pageSectionTitleText,
      pageSectionDescriptionText,
      pageSectionButtonText,
      startedAt,
      expiredAt,
      timerConfig,
    } = promo;

    return promo.pageElements.reduce<PromoLandingElements>(
      (acc, element) => {
        const key = PromoElementTypeMapper[element];

        return {
          ...acc,
          [key]: {
            id,
            slug,
            titleText:
              key === PromoElementType.BANNER
                ? bannerTitleText
                : pageSectionTitleText,
            descriptionText:
              key === PromoElementType.BANNER
                ? null
                : pageSectionDescriptionText,
            buttonText:
              key === PromoElementType.BANNER
                ? bannerButtonText
                : pageSectionButtonText,
            timeSettings: {
              startedAt,
              expiredAt: expiredAt ?? undefined,
              repeatDaysSteps: timerConfig?.repeatDaysSteps ?? undefined,
            },
          },
        };
      },
      {
        [PromoElementType.BANNER]: null,
        [PromoElementType.SECTION]: null,
      },
    );
  }

  private static filterValidPromos(
    promos: NormalizedLandingPromo[],
  ): NormalizedLandingPromo[] {
    const date = new Date();
    const now = date.getTime();
    const todayDayNumber = date.getDay();

    return promos.filter(({ startedAt, timerConfig }) => {
      const hideDays = timerConfig?.hideDays || [];

      return Number(startedAt) <= now && !hideDays.includes(todayDayNumber);
    });
  }

  private static resolvePromoPriority(options: {
    pathname: string;
    pagePattern: string | null;
  }) {
    const { pathname, pagePattern } = options;

    if (pagePattern && new RegExp(pagePattern).test(pathname)) {
      return Priorities.FIRST; // Specific pattern that matches the page
    }

    if (!pagePattern) {
      return Priorities.SECOND; // No pattern (matches all)
    }

    return Priorities.THIRD; // General pattern that doesn't match the page
  }

  private static findActualPromo(
    promos: NormalizedLandingPromo[],
    pathname: string,
  ): NormalizedLandingPromo | null {
    return promos.reduce<{
      promo: NormalizedLandingPromo | null;
      priority: number;
      startedAt: number;
    } | null>((priorityPromo, promo) => {
      const { pagePattern, startedAt } = promo;

      const priority = this.resolvePromoPriority({
        pathname,
        pagePattern,
      });

      const noPriorityPromo = !priorityPromo;
      const hasHigherPriority = (
        priority < (priorityPromo?.priority ?? Priorities.THIRD)
      );
      const hasEqualPriorityAndLaterStart = (
        priority === priorityPromo?.priority
        && Number(startedAt) > (priorityPromo?.startedAt || 0)
      );

      const shouldUpdatePriorityPromo = noPriorityPromo
        || hasHigherPriority
        || hasEqualPriorityAndLaterStart;

      if (shouldUpdatePriorityPromo) {
        return {
          promo,
          priority,
          startedAt: Number(startedAt),
        };
      }

      return priorityPromo;
    }, null)?.promo || null;
  }

  static getLandingPromoElements(
    promos: NormalizedLandingPromo[],
    pathname: string,
  ): PromoLandingElements {
    const validPromos = this.filterValidPromos(promos);

    const actualPromo = this.findActualPromo(
      validPromos,
      pathname,
    );

    if (!actualPromo) {
      return EMPTY_PROMO_LANDING_ELEMENTS;
    }

    return this.preparePromoLandingElements(actualPromo);
  }

  static getTimerExpiredAtTime(options: PromoTimeSettings): number {
    const {
      startedAt,
      expiredAt,
      repeatDaysSteps = [],
    } = options;

    if (expiredAt && Number(expiredAt) <= Date.now()) {
      return 0;
    }

    if (expiredAt && !repeatDaysSteps.length) {
      return Number(expiredAt);
    }

    const repeatDaysStepsConfig: MinimumArray<1, number> = (
      checkArrayLength(repeatDaysSteps, 1)
        ? repeatDaysSteps
        : [3]
    );

    const nextExpirationTime = this.getNextExpirationTime(
      Number(startedAt),
      repeatDaysStepsConfig,
    );

    if (expiredAt && nextExpirationTime > Number(expiredAt)) {
      return Number(expiredAt);
    }

    return nextExpirationTime;
  }

  static getNextExpirationTime(
    startedAtTime: number,
    repeatDaysSteps: MinimumArray<1, number>,
  ) {
    // Calculate the total elapsed time in days since the start
    const elapsedTimeInDays = Math.floor((Date.now() - startedAtTime) / DAY);

    // Sum the intervals and determine the next interval based on the start time
    let totalDays = 0;
    let intervalIndex = 0;

    while (totalDays <= elapsedTimeInDays) {
      const currentInterval = (
        repeatDaysSteps[intervalIndex]
        || repeatDaysSteps[0]
      );

      totalDays += currentInterval;

      intervalIndex = (intervalIndex + 1) % repeatDaysSteps.length;
    }

    // Calculate the next expiration time based on the startedAtTime
    return startedAtTime + totalDays * DAY;
  }

  static getConsultationLink(options: {
    promoCodeSlug: string;
    eventSource: string;
    courseSlug?: string;
    professionSlug?: string;
  }): string {
    const {
      promoCodeSlug,
      eventSource,
      courseSlug = ConsultationCourses.GeneralConsultation,
      professionSlug,
    } = options;
    const queryParams = createQueryString({
      source: eventSource,
      promo_code: promoCodeSlug,
      course_slug: courseSlug,
      profession_slug: professionSlug,
    });

    return `${ROUTES.consultation.index}${queryParams}`;
  }

  static getPromoEventSource(options: {
    promoCodeSlug: string;
    promoSection: string;
  }): string {
    const {
      promoCodeSlug,
      promoSection,
    } = options;

    return `${promoCodeSlug}_${promoSection}`;
  }
}
