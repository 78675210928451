import { useActiveLandingPromos } from '@/controllers/promoCode/promoCode.hooks/useActiveLandingPromos';
import { PromoCodeHelpers } from '@/controllers/promoCode/promoCode.helpers';
import { useRouter } from '@/middleware/i18n';
import { PromoLandingElements } from '@/controllers/promoCode/promoCode.typedefs';
import { EMPTY_PROMO_LANDING_ELEMENTS } from '@/controllers/promoCode/promoCode.constant';

export const usePromoLandingElements = (): PromoLandingElements => {
  const { landingPromos } = useActiveLandingPromos();
  const { asPath } = useRouter();

  if (!landingPromos.length) {
    return EMPTY_PROMO_LANDING_ELEMENTS;
  }

  return PromoCodeHelpers.getLandingPromoElements(landingPromos, asPath);
};
