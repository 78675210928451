import { useActiveProfessionsListInfo } from '@/controllers/profession/profession.hooks/useActiveProfessionsListInfo';
import { ProfessionsListData } from '@/components/coursesParttimeLanding/CoursesParttime.typedefs';
import { ProfessionListInfoFragment } from '@/controllers/profession/generated/professionListInfo.fragment.generated';
import { ProfessionLandingHelpers } from '@/components/professionLanding/ProfessionLanding.helpers';
import {
  getProfessionsListCardInfo,
} from '@/components/coursesParttimeLanding/CoursesParttime.content';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

const normalizeProfessionsListData = (
  professions: ProfessionListInfoFragment[],
  subDomain: string,
): ProfessionsListData[] => (
  professions.map((profession) => {
    const {
      id,
      slug,
      name,
      minimalCourseDuration,
    } = profession;

    const trackSlug = ProfessionLandingHelpers
      .getTrackSlugByProfessionSlug(slug);

    const {
      description,
      imageData,
      labels,
    } = getProfessionsListCardInfo(trackSlug, subDomain);

    const duration = minimalCourseDuration || 3;

    return {
      id,
      slug,
      name,
      description,
      imageData,
      duration,
      labels,
    };
  })
);

export const useRedesignedProfessionsListData = (): ProfessionsListData[] => {
  const [professions] = useActiveProfessionsListInfo({});
  const { subDomain } = useSubDomainContext();

  return normalizeProfessionsListData(professions, subDomain);
};
