import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconPriceTag: FCIcon = (props) => (
  <BaseIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.26933 1.4L13.8693 2.34267L14.812 8.94267L8.68399 15.0707C8.62208 15.1327 8.54855 15.1818 8.46762 15.2154C8.38669 15.2489 8.29994 15.2662 8.21233 15.2662C8.12471 15.2662 8.03796 15.2489 7.95703 15.2154C7.8761 15.1818 7.80257 15.1327 7.74066 15.0707L1.14066 8.47067C1.01588 8.34568 0.945801 8.17628 0.945801 7.99967C0.945801 7.82306 1.01588 7.65366 1.14066 7.52867L7.26933 1.4ZM9.15533 7.05733C9.27916 7.18113 9.42617 7.27932 9.58795 7.3463C9.74974 7.41328 9.92313 7.44773 10.0982 7.4477C10.2733 7.44767 10.4467 7.41315 10.6085 7.34612C10.7702 7.27908 10.9172 7.18084 11.041 7.057C11.1648 6.93317 11.263 6.78616 11.33 6.62437C11.3969 6.46259 11.4314 6.2892 11.4314 6.1141C11.4313 5.939 11.3968 5.76562 11.3298 5.60386C11.2627 5.4421 11.1645 5.29513 11.0407 5.17133C10.7906 4.92132 10.4514 4.78091 10.0978 4.78097C9.74413 4.78103 9.405 4.92157 9.15499 5.17167C8.90498 5.42177 8.76456 5.76094 8.76462 6.11457C8.76469 6.4682 8.90523 6.80732 9.15533 7.05733Z"
      fill="currentColor"
    />
  </BaseIcon>
);
