import React, { FC } from 'react';
import { HeadingLabel } from '@/components/homepageLanding/HomePageCoverStatistics/HomePageCoverStatistics.typedefs';
import { IconImage } from '@/components/ui/Image/IconImage';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import styles from './CoverHeadingLabel.module.scss';

type Props = Omit<HeadingLabel, 'id'>;

export const CoverHeadingLabel: FC<Props> = (props) => {
  const { textCode, imageData } = props;

  const { t } = useTranslation([I18N_CODES.home]);

  return (
    <div className={styles.labelWrapper}>
      <IconImage
        imageData={imageData}
        width={20}
        height={20}
      />

      <p className={styles.text}>
        {t(textCode)}
      </p>
    </div>
  );
};
