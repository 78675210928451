import React from 'react';
import { BaseIcon, IconSize } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconArrowLeftLong: FCIcon = (props) => (
  <BaseIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={IconSize.Small}
    {...props}
  >
    <path
      d="M3.828 9.00032L9.192 14.3643L7.778 15.7783L0 8.00032L7.778 0.222321L9.192 1.63632L3.828 7.00032L16 7.00032V9.00032L3.828 9.00032Z"
      fill="currentColor"
    />
  </BaseIcon>
);
