import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconFacebookColor: FCIcon = (props) => (
  <BaseIcon
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M22.6667 4.66674C22.6667 4.48993 22.5964 4.32036 22.4714 4.19533C22.3464 4.07031 22.1768 4.00007 22 4.00007H18.6667C16.9882 3.91646 15.3448 4.50059 14.0956 5.62484C12.8465 6.7491 12.093 8.32207 12 10.0001V13.6001H8.66667C8.48986 13.6001 8.32029 13.6703 8.19526 13.7953C8.07024 13.9204 8 14.0899 8 14.2667V17.7334C8 17.9102 8.07024 18.0798 8.19526 18.2048C8.32029 18.3298 8.48986 18.4001 8.66667 18.4001H12V27.3334C12 27.5102 12.0702 27.6798 12.1953 27.8048C12.3203 27.9298 12.4899 28.0001 12.6667 28.0001H16.6667C16.8435 28.0001 17.013 27.9298 17.1381 27.8048C17.2631 27.6798 17.3333 27.5102 17.3333 27.3334V18.4001H20.8267C20.9749 18.4022 21.1197 18.3548 21.238 18.2655C21.3563 18.1762 21.4415 18.0499 21.48 17.9067L22.44 14.4401C22.4665 14.3416 22.4701 14.2383 22.4504 14.1382C22.4308 14.0381 22.3884 13.9438 22.3266 13.8627C22.2647 13.7816 22.1851 13.7157 22.0938 13.6701C22.0025 13.6246 21.902 13.6006 21.8 13.6001H17.3333V10.0001C17.3665 9.67004 17.5215 9.36422 17.768 9.14233C18.0146 8.92044 18.335 8.79841 18.6667 8.80007H22C22.1768 8.80007 22.3464 8.72983 22.4714 8.60481C22.5964 8.47978 22.6667 8.31022 22.6667 8.1334V4.66674Z"
      fill="#1877F2"
    />
  </BaseIcon>
);
