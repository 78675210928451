import React, { FC, memo } from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { UltraHover } from '@/components/landing/UltraHover';
import { Benefit } from '@/components/landing/BenefitsList/BenefitsList.typedefs';
import { ComponentWrapper } from '@/components/ui/helpers';
import { IconImage } from '@/components/ui/Image/IconImage';
import styles from './BenefitsList.module.scss';

type Props = {
  items: Benefit[];
  isAnimated?: boolean;
  withHoverEffect?: boolean;
  collideOnSmallScreen?: boolean;
};

export const BenefitsList: FC<Props> = memo((props) => {
  const {
    items,
    isAnimated,
    withHoverEffect,
    collideOnSmallScreen,
  } = props;

  return (
    <div className={styles.container}>
      <ul className={cn(styles.content, {
        [styles.collideOnSmallScreen]: collideOnSmallScreen,
      })}
      >
        {items.map((item, i) => (
          <li
            key={item.id}
            className={cn(styles.listItem, {
              [styles.animated]: isAnimated,
              [styles.collided]: collideOnSmallScreen,
            })}
            style={{ '--delay': `${i * 0.1}s` } as React.CSSProperties}
          >
            <ComponentWrapper
              shouldWrapChildren={Boolean(withHoverEffect)}
              wrapper={(children) => (
                <UltraHover className={styles.item}>
                  {children}
                </UltraHover>
              )}
            >
              <div className={cn(styles.itemContent, {
                [styles.collided]: collideOnSmallScreen,
              })}
              >
                <div className={styles.iconWrapper}>
                  <IconImage
                    imageData={item.icon}
                    objectFit="contain"
                    width={32}
                    height={32}
                    className={cn(
                      styles.icon,
                      { [styles.animated]: isAnimated },
                    )}
                    alt={item.title}
                    loading="lazy"
                    style={{ '--delay': `${0.2 + i * 0.1}s` } as React.CSSProperties}
                  />
                </div>

                <p className={cn(typography.landingH6, styles.title, {
                  [styles.collided]: collideOnSmallScreen,
                })}
                >
                  {item.title}
                </p>
              </div>
            </ComponentWrapper>
          </li>
        ))}
      </ul>
    </div>
  );
});
