import React, { FC, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import {
  InputPhoneNumber,
} from '@/components/ui/FormElements/FormInputs/InputPhoneNumber';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { FormField } from '@/components/ui/FormElements/FormField';
import { isNotEmpty } from '@/controllers/forms/forms.validator/validationRules';
import { makeValidator } from '@/controllers/forms/forms.validator';
import {
  ConsultationFormFields,
} from '@/components/courseLanding/CourseConsultationForm/ConsultationForm.typedefs';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { getTranslateCodeByDomain } from '@/lib/helpers/getTranslateCodeByDomain';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { useEnteredUserValue } from '@/controllers/consultation/useEnteredUserValue';
import { EnteredUserInfoField } from '@/controllers/consultation/consultation.typedefs';

interface Props {
  /**
   * @description If true, phone number will be formatted automatically.
   *   False is mostly used to facilitate testing.
   * @default true
   * */
  autoFormat?: boolean;
}

export const ConsultationPhoneNumber: FC<Props> = (props) => {
  const {
    autoFormat,
  } = props;

  const fieldName = 'phone';
  const { t } = useTranslation([I18N_CODES.courseConsultation]);
  const { submitting } = useFormState();
  const { subDomain } = useSubDomainContext();

  const [authUser] = useAuthUser({ ssr: false });
  const form = useForm<ConsultationFormFields>();

  const [savedUserPhone] = useEnteredUserValue(EnteredUserInfoField.Phone);

  useEffect(() => {
    const userPhone = authUser?.phone || savedUserPhone;

    if (userPhone) {
      // NOTE: remove + to avoid differences when we pre-fill the data and when the user enters it manually
      const userPhoneWithoutPlus = userPhone.replace('+', '');

      form.change(fieldName, userPhoneWithoutPlus);
    }
  }, [authUser?.phone, form, savedUserPhone]);

  const placeholderPhoneCodeByDomain = getTranslateCodeByDomain(
    `${I18N_CODES.courseConsultation}:consultation_form_required_phone_placeholder`,
    subDomain,
  );

  return (
    <FormField
      label={{
        for: 'consultation-phone',
        text: t(`${I18N_CODES.courseConsultation}:consultation_form_phone`),
      }}
      renderInput={(inputProps) => (
        <InputPhoneNumber
          autoFormat={autoFormat}
          config={{
            validate: makeValidator(
              isNotEmpty,
              t(`${I18N_CODES.courseConsultation}:consultation_form_required_phone`),
            ),
          }}
          {...inputProps}
          placeholder={t(placeholderPhoneCodeByDomain)}
          required
          data-qa='consultation-phone'
        />
      )}
      disabled={submitting}
      name={fieldName}
    />
  );
};
