import React, { FC, memo, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import {
  SectionBase,
  SectionBaseType,
} from '@/components/landing/BaseSectionWrappers/SectionBase';
import { BaseSectionHeader } from '@/components/landing/BaseSectionWrappers/BaseSectionHeader';
import { ContentContainer } from '@/components/landing/BaseSectionWrappers/ContentContainer';
import maleHugging from '@/images/generated/icons.emoji.maleHugging.s3Key';
import { useStateRef } from '@/hooks/useStateRef';
import { cn } from '@/lib';
import { AnchorElement } from '@/components/ui/Button/AnchorElement';
import { IconImage } from '@/components/ui/Image/IconImage';
import { typography } from '@/components/ui/typography';
import { IconArrowLeftLongRounded } from '@/components/ui/icons/IconArrowLeftLongRounded';
import { Button } from '@/components/ui/Button';
import { IconArrowLeftLong } from '@/components/ui/icons/IconArrowLeftLong';
import { IconArrowRightLong } from '@/components/ui/icons/IconArrowRightLong';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { graduatesRawData } from '@/components/homepageLanding/OurGraduatesSection/OurGraduatesSection.data';
import styles from './OurGraduatesSection.module.scss';

export const OurGraduatesSection: FC = memo(() => {
  const { t } = useTranslation([I18N_CODES.home]);

  const [prevButton, setPrevButtonRef] = useStateRef<HTMLButtonElement>();
  const [nextButton, setNextButtonRef] = useStateRef<HTMLButtonElement>();

  const graduatesData = useMemo(() => graduatesRawData.map((item) => ({
    ...item,
    fullName: t(item.fullName),
    employedAt: t(item.employedAt),
    previousProfession: t(item.previousProfession),
    currentProfession: t(item.currentProfession),
  })), [t]);

  return (
    <SectionBase
      type={SectionBaseType.WideWithLightBackground}
      className='overflow-hidden'
    >
      <ContentContainer>
        <BaseSectionHeader
          title={t(`${I18N_CODES.home}:ourGraduates.title`)}
          heading={t(`${I18N_CODES.home}:ourGraduates.heading`)}
          personImageData={maleHugging}
          quote={t(`${I18N_CODES.home}:ourGraduates.quote`)}
          className="mb-56"
        />

        <Swiper
          className='overflow-visible'
          modules={[Navigation, Pagination]}
          navigation={{
            prevEl: prevButton,
            nextEl: nextButton,
          }}
          pagination={{
            el: `.${styles.bulletsContainer}`,
            horizontalClass: `${styles.pagination}`,
            dynamicBullets: true,
            dynamicMainBullets: 2,
            enabled: true,
            renderBullet: (_, className) => (
              `<span class="${className} ${styles.bullet}"></span>`
            ),
          }}
          breakpoints={
            {
              640: {
                slidesPerGroup: 2,
              },
              320: {
                slidesPerGroup: 1,
              },
            }
          }
          spaceBetween={16}
          slidesPerView='auto'
          slidesPerGroup={1}
          speed={500}
          grabCursor
        >
          {graduatesData.map((graduate) => (
            <SwiperSlide key={graduate.id} className={styles.slide}>
              <div className={cn(styles.slideHeader, 'mb-24')}>
                <p className={cn(styles.fullname, typography.newLandingH5)}>
                  {graduate.fullName}
                </p>

                <p className={cn(
                  styles.employedAt,
                  typography.newLandingTextMain,
                )}
                >
                  {graduate.employedAt}
                </p>

                <AnchorElement
                  className={styles.link}
                  href={graduate.articleLink}
                  target="_blank"
                >
                  <IconArrowLeftLongRounded />
                </AnchorElement>
              </div>

              <div className='mb-24'>
                <IconImage
                  className={cn(styles.image, '')}
                  width={304}
                  height={200}
                  imageData={graduate.imageData}
                  alt={graduate.fullName}
                />
              </div>

              <div className={cn(
                typography.newLandingTextSecondary,
                styles.slideFooter,
              )}
              >
                <p className='mb-4'>
                  <span className={styles.professionLabel}>До</span>
                  {graduate.previousProfession}
                </p>

                <p>
                  <span className={styles.professionLabel}>Після</span>
                  {graduate.currentProfession}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className={cn(styles.controls, 'flex-container align-center mt-56')}>
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              LeftIcon={IconArrowLeftLong}
              mode={Button.mode.Secondary}
              size={Button.size.Large}
              ref={setPrevButtonRef}
            />

            <div className={styles.bulletsContainer} />

            <Button
              className={styles.button}
              LeftIcon={IconArrowRightLong}
              mode={Button.mode.Secondary}
              size={Button.size.Large}
              ref={setNextButtonRef}
            />
          </div>
        </div>
      </ContentContainer>
    </SectionBase>
  );
});
