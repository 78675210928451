import React, { memo } from 'react';
import { ROUTES } from '@/controllers/router/router.contants';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import {
  LearningTracksShortInfo,
} from '@/components/courseLanding/OtherLearningTracksBlock/OtherLearingTracksBlock.typedefs';
import { NativeAppHelpers } from '@/components/nativeApp/nativeApp.helpers';
import { FooterLink } from './FooterLink';
import { DownloadButton } from '../DownloadButton';
import styles from './FooterLinks.module.scss';

interface Props {
  termsOfUse: string;
  privacyPolicy: string;
  cookiesPolicy: string;
  hireFormLink: string;
  shouldShowReviewsPageLink: boolean;
  shouldShowBlogLink: boolean;
  shouldShowFreeForCombatantsLink: boolean;
  shouldShowPolishMergeAnnouncement: boolean;
  tracks: LearningTracksShortInfo[];
}

export const FooterLinksDesktop = memo<Props>((props) => {
  const {
    termsOfUse,
    privacyPolicy,
    cookiesPolicy,
    hireFormLink,
    shouldShowReviewsPageLink,
    shouldShowBlogLink,
    shouldShowFreeForCombatantsLink,
    shouldShowPolishMergeAnnouncement,
    tracks,
  } = props;

  const { t } = useTranslation(`${I18N_CODES.common}`);

  const appStoreLink = NativeAppHelpers.getIOSLink('landing-footer');
  const googlePlayLink = NativeAppHelpers.getAndroidLink('landing-footer');

  return (
    <div className={cn(styles.navLinks, 'hide-for-small-only  align-center')}>
      <ul className={styles.navList}>
        <li className={cn(typography.landingTextMain, styles.navListHeader)}>
          {t(`${I18N_CODES.common}:profession_footer_courses`)}
        </li>
        {tracks.map((track) => (
          <FooterLink
            key={track.id}
            route={ROUTES.courses(track.slug)}
            title={track.name}
          />
        ))}
      </ul>

      <div className='flex-container flex-dir-column gap-32'>
        <ul className={styles.navList}>
          <li className={cn(typography.landingTextMain, styles.navListHeader)}>
            {t(`${I18N_CODES.common}:profession_footer_explore`)}
          </li>

          <FooterLink
            title={t(`${I18N_CODES.common}:profession_footer_pricing`)}
            route={ROUTES.price}
          />

          {shouldShowReviewsPageLink && (
            <FooterLink
              title={t(`${I18N_CODES.common}:profession_footer_reviews`)}
              route={ROUTES.reviews}
            />
          )}

          <FooterLink
            title={t(`${I18N_CODES.common}:profession_footer_about_us`)}
            route={ROUTES.team}
          />

          {shouldShowBlogLink && (
            <FooterLink
              route={ROUTES.blog}
              title={t(`${I18N_CODES.common}:profession_footer_blog`)}
              isExternal
            />
          )}

          {shouldShowPolishMergeAnnouncement && (
            <FooterLink
              route='https://mate.academy/lp/announcement/elg-100'
              title={t(`${I18N_CODES.common}:announcement_page_link`)}
              isExternal
            />
          )}

          <FooterLink
            route='https://career.mate.academy/'
            title={t(`${I18N_CODES.common}:profession_footer_career`)}
            isExternal
            textTag={t(`${I18N_CODES.common}:profession_footer_hiring_tag`)}
          />
        </ul>

        <ul className={styles.navList}>
          <li className={cn(typography.landingTextMain, styles.navListHeader)}>
            {t(`${I18N_CODES.common}:profession_footer_offers`)}
          </li>

          {shouldShowFreeForCombatantsLink && (
            <FooterLink
              route={t(`${I18N_CODES.common}:free_courses_for_combatants_article_link`)}
              title={t(`${I18N_CODES.common}:profession_footer_combatants`)}
              isExternal
              textTag={t(`${I18N_CODES.common}:profession_footer_free_tag`)}
              className={styles.freeForCombatants}
            />
          )}

          {hireFormLink && (
            <FooterLink
              route={hireFormLink}
              title={t(`${I18N_CODES.common}:profession_footer_hire_a_graduate`)}
              isExternal
            />
          )}
        </ul>
      </div>

      <div className='flex-container flex-dir-column gap-32'>
        <ul className={styles.navList}>
          <li className={cn(typography.landingTextMain, styles.navListHeader)}>
            {t(`${I18N_CODES.common}:profession_footer_download`)}
          </li>

          <DownloadButton
            href={appStoreLink}
            provider='apple'
            source='landing-footer'
            title={t(`${I18N_CODES.common}:profession_footer_download_ios`)}
          />

          <DownloadButton
            href={googlePlayLink}
            provider='google'
            source='landing-footer'
            title={t(`${I18N_CODES.common}:profession_footer_download_android`)}
          />
        </ul>

        <ul className={styles.navList}>
          <li className={cn(typography.landingTextMain, styles.navListHeader)}>
            {t(`${I18N_CODES.common}:profession_footer_legal`)}
          </li>

          <FooterLink
            route={termsOfUse}
            title={t(`${I18N_CODES.common}:profession_footer_terms_of_use`)}
            isExternal
          />

          <FooterLink
            route={privacyPolicy}
            title={t(`${I18N_CODES.common}:profession_footer_privacy_policy`)}
            isExternal
          />

          <FooterLink
            route={cookiesPolicy}
            title={t(`${I18N_CODES.common}:profession_footer_cookies_policy`)}
            isExternal
          />
        </ul>
      </div>
    </div>
  );
});
