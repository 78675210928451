import React, { memo } from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { IconSoftStar } from '@/components/ui/icons/IconSoftStar';
import { ImageData } from '@/components/ui/Image/typedefs';
import { ReviewButton, ReviewButtonType } from './ReviewButton';
import styles from './ReviewCard.module.scss';

interface Props {
  review: string;
  author: string;
  reviewLink?: string;
  reviewType: ReviewButtonType;
  imageData?: ImageData;
}

export const ReviewCard = memo<Props>((props) => {
  const {
    review,
    author,
    reviewLink,
    reviewType,
    imageData,
  } = props;

  const [name, position] = author.split('|');

  return (
    <div className={styles.reviewCard}>
      <div>
        <div className={styles.stars}>
          <IconSoftStar className={styles.star} />
          <IconSoftStar className={styles.star} />
          <IconSoftStar className={styles.star} />
          <IconSoftStar className={styles.star} />
          <IconSoftStar className={styles.star} />
        </div>

        <p className={cn(typography.newLandingH6, 'color-gray-65')}>
          {review}
        </p>
      </div>

      <div className={styles.reviewBottom}>
        <div>
          <p className={cn(typography.landingTextMain, styles.name, 'mb-4')}>
            {name}
          </p>

          {position && (
            <p className={styles.position}>
              {position}
            </p>
          )}
        </div>

        <ReviewButton
          link={reviewLink}
          type={reviewType}
          imageData={imageData}
        />
      </div>
    </div>
  );
});
