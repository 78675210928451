import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export class ConsultationFormMessageHarness extends ComponentHarness {
  dataQa = 'consultation-success-message';

  dayOffMessageDataQa = 'consultation-success-day-off-message';

  buttonDataQa = 'consultation-success-request-button';

  get dayOffMessage() {
    return this.getNestedElement(this.dayOffMessageDataQa);
  }

  get button() {
    return this.getNestedElement(this.buttonDataQa);
  }

  assertDayOffMessageExists() {
    this.dayOffMessage.should('exist');
  }

  assertDayOffMessageNotExists() {
    this.dayOffMessage.should('not.exist');
  }

  assertButtonExists() {
    this.button.should('exist');
  }

  assertButtonUrl(url: string) {
    this.button.should('have.attr', 'href', url);
  }

  assertButtonUrlContains(url: string) {
    this.button
      .should('have.attr', 'href')
      .and('include', url);
  }
}
