import React, { Children, FC } from 'react';
import { cn } from '@/lib/classNames';
import styles from './FloatIconsRedesigned.module.scss';

export const FloatIconsRedesigned: FC = ({ children }) => (
  <div className={styles.wrapper}>
    {Children.map(children, (child) => (
      <div className={cn(styles.iconWrapper)}>
        {child}
      </div>
    ))}
  </div>
);
