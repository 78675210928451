import React, { FC, useMemo } from 'react';
import { cn } from '@/lib/classNames';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { SupportSocialNames } from '@/controllers/graphql/generated';
import { analyticsSDK } from '@/controllers/analytics';
import { iconComponents } from './SupportLinkRedesigned.constants';
import styles from './SupportLinkRedesigned.module.scss';

interface Props {
  href: string;
  providerName: SupportSocialNames;
}

type SupportLinkType = FC<Props> & {
  providerName: typeof SupportSocialNames;
};

const providerIconClassname = {
  [SupportSocialNames.Telegram]: styles.telegram,
  [SupportSocialNames.Whatsapp]: styles.whatsapp,
  [SupportSocialNames.Viber]: styles.viber,
  [SupportSocialNames.Facebook]: styles.facebook,
};

export const SupportLinkRedesigned: SupportLinkType = (props) => {
  const { t } = useTranslation([I18N_CODES.common]);
  const { href, providerName } = props;

  const sendAnalytics = () => {
    analyticsSDK.global.sendSupportIconClickedEvent({
      providerName,
    });
  };

  const { Icon, content } = useMemo(() => ({
    Icon: iconComponents[providerName],
    content: t(`${I18N_CODES.common}:${providerName.toLowerCase()}_icon_content`),
  }), [providerName, t]);

  return (
    <a
      href={href}
      rel="nofollow noopener noreferrer"
      target="_blank"
      onClick={sendAnalytics}
      title={content}
      data-qa='support-link-button'
    >
      <span
        className={cn(
          styles.container,
          providerIconClassname[providerName],
        )}
      >
        <Icon className={styles.icon} color='#fff' />
      </span>
    </a>
  );
};

SupportLinkRedesigned.providerName = SupportSocialNames;
