import React, { memo } from 'react';
import { cn } from '@/lib';
import {
  IconMateLogoSquare,
  IconMateLogoSquareNewYear,
} from '@/components/ui/icons/product/Mate';
import { FCIcon } from '@/components/ui/icons/typedefs';
import styles from './MateLogo.module.scss';
import { useShouldShowNewYearLogo } from './hooks/useShouldShowNewYearLogo';

/**
 * Mate logo square icon
 * @deprecated Use LogoSquare instead
 * @private This component is not for direct use
 */
export const MateLogoSquare: FCIcon<{
  withHoverEffect?: boolean;
}> = memo(({
  className,
  customProps,
  ...props
}) => {
  const shouldShowNewYearLogo = useShouldShowNewYearLogo();

  return shouldShowNewYearLogo
    ? (
      <IconMateLogoSquareNewYear
        className={cn(className, {
          [styles.withHoverEffect]: customProps?.withHoverEffect,
        })}
        {...props}
      />
    )
    : (
      <IconMateLogoSquare
        className={cn(className, {
          [styles.withHoverEffect]: customProps?.withHoverEffect,
        })}
        {...props}
      />
    );
});
