import React, { FC } from 'react';
import { ImageData } from '@/components/ui/Image/typedefs';
import { useIsMinimalWidth } from '@/hooks/useIsMinimalWidth';
import { MINIMAL_TABLET_WIDTH } from '@/constants/breakpoints';
import { HomeCoverLarge } from '@/components/homepageLanding/HomePageCoverHeading/components/CoverSizes/HomeCoverLarge';
import { HomeCoverSmall } from '@/components/homepageLanding/HomePageCoverHeading/components/CoverSizes/HomeCoverSmall';
import { HeadingLabel } from '@/components/homepageLanding/HomePageCoverStatistics/HomePageCoverStatistics.typedefs';

interface Props {
  heading: string;
  ctaText: string;
  href: string;
  imageData: ImageData;
  subHeading: string;
  headingLabels?: HeadingLabel[];
}

export const HomePageCoverHeading: FC<Props> = (props) => {
  const {
    heading,
    ctaText,
    href,
    imageData,
    subHeading,
    headingLabels,
  } = props;

  const isSmallScreen = useIsMinimalWidth(MINIMAL_TABLET_WIDTH);

  if (isSmallScreen) {
    return (
      <HomeCoverSmall
        heading={heading}
        ctaText={ctaText}
        ctaHref={href}
        subHeading={subHeading}
        headingLabels={headingLabels}
      />
    );
  }

  return (
    <HomeCoverLarge
      heading={heading}
      ctaText={ctaText}
      ctaHref={href}
      imageData={imageData}
      subHeading={subHeading}
      headingLabels={headingLabels}
      shouldHidePromoBanner
    />
  );
};
