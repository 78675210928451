import React, { FC, memo } from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { IconImage } from '@/components/ui/Image/IconImage';
import {
  BackgroundColorMode,
  BubblePhrase,
  PhraseAnglePosition,
} from '@/components/professionLanding/BubblePhrase/BubblePhrase';
import { ImageData } from '@/components/ui/Image/typedefs';
import styles from './SectionBase.module.scss';

type BaseProps = {
  title: string;
  heading?: string;
  className?: string;

  personImageData?: never;
  quote?: never;
  bubblePhraseAnglePosition?: never;
  phraseBackgroundColorMode?: never;
};

type WithQuoteProps = {
  personImageData: ImageData;
  quote: string;
  bubblePhraseAnglePosition?: PhraseAnglePosition;
  phraseBackgroundColorMode?: BackgroundColorMode;
};

type Props = Pick<BaseProps, 'title' | 'heading' | 'className'> & (
  | BaseProps
  | WithQuoteProps
);

export const BaseSectionHeader: FC<Props> = memo((props) => {
  const {
    title,
    heading,
    className,
    personImageData,
    quote,
    bubblePhraseAnglePosition = PhraseAnglePosition.bottomLeft,
    phraseBackgroundColorMode = BackgroundColorMode.White,
  } = props;

  // INFO: TS doesn't work properly with this condition if we use Boolean function
  const shouldRenderQuote = !!(quote && personImageData);
  // TODO: Add withCtaButton;
  const shouldRenderSideContent = shouldRenderQuote;

  return (
    <div className={cn(className, {
      [styles.withSideContent]: shouldRenderSideContent,
      [styles.withoutSideContent]: !shouldRenderSideContent,
    })}
    >
      <div className={cn(styles.headerText, {
        [styles.fullWidth]: !shouldRenderSideContent,
      })}
      >
        {heading && (
          <p className={cn(typography.landingSectionHeading, 'mb-20')}>
            {heading}
          </p>
        )}

        <h2 className={cn(typography.newLandingH2, styles.title)}>
          {title}
        </h2>
      </div>

      {shouldRenderQuote && (
        <div className={cn(
          styles.phraseBlock,
          'flex-container align-middle',
        )}
        >
          <div className={styles.phraseImage}>
            <IconImage
              imageData={personImageData}
              width={140}
              height={140}
            />
          </div>

          <BubblePhrase
            text={quote}
            className={styles.bubblePhrase}
            angleClassName={styles.bubblePhraseAngle}
            anglePosition={bubblePhraseAnglePosition}
            backgroundColorMode={phraseBackgroundColorMode}
          />
        </div>
      )}
    </div>
  );
});
