import React, { memo } from 'react';
import { cn } from '@/lib';
import { Button } from '@/components/ui/Button';
import { CoverImage } from '@/components/professionLanding/CoverSectionHeading/components/CoverImage/CoverImage';
import { ImageData } from '@/components/ui/Image/typedefs';
import { HeadingLabel } from '@/components/homepageLanding/HomePageCoverStatistics/HomePageCoverStatistics.typedefs';
import {
  CoverHeadingLabel,
} from '@/components/homepageLanding/HomePageCoverStatistics/components/CoverHeadingLabel/CoverHeadingLabel';
import styles from '../../HomePageCoverHeading.module.scss';

interface Props {
  heading: string;
  ctaText: string;
  ctaHref: string;
  imageData: ImageData;
  subHeading?: string;
  headingLabels?: HeadingLabel[];
  shouldHidePromoBanner: boolean;
}

export const HomeCoverLarge = memo<Props>((props) => {
  const {
    heading,
    ctaText,
    ctaHref,
    imageData: coverImageData,
    subHeading,
    headingLabels,
    shouldHidePromoBanner,
  } = props;

  return (
    <div
      className={cn(
        'grid-x align-center',
        styles.coverContainer,
      )}
      data-hide-promo-banner={shouldHidePromoBanner}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.textsWrapper}>
          {headingLabels && (
            <div className={styles.labelsWrapper}>
              {headingLabels.map(({ id, textCode, imageData }) => (
                <CoverHeadingLabel
                  key={id}
                  imageData={imageData}
                  textCode={textCode}
                />
              ))}
            </div>
          )}

          <h1 className={styles.heading}>
            {heading}
          </h1>

          <p className={styles.subHeading}>
            {subHeading}
          </p>
        </div>

        <Button
          type="submit"
          mode={Button.mode.BrandPrimary}
          size={Button.size.Large}
          className={styles.coverButton}
          text={ctaText}
          href={ctaHref}
        />
      </div>

      <div className={cn(styles.coverImageWrapper, 'xlarge-7 large-7 medium-9 text-center')}>
        <CoverImage
          imageData={coverImageData}
        />
      </div>
    </div>
  );
});
