import { useIsNewYearThemeEnabled } from '@/components/landing/Promotions/components/PromoBanner/hooks/useIsNewYearThemeEnabled';
import { PromoCodeHelpers } from '@/controllers/promoCode/promoCode.helpers';
import { useActiveLandingPromos } from '@/controllers/promoCode/promoCode.hooks/useActiveLandingPromos';

export const useShouldShowNewYearLogo = (): boolean => {
  const { landingPromos } = useActiveLandingPromos();

  const { promoBannerProps } = PromoCodeHelpers.getLandingPromoElements(landingPromos, '/');

  return useIsNewYearThemeEnabled(promoBannerProps?.slug);
};
