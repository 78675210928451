import { useMemo } from 'react';
import { useForm } from 'react-final-form';
import { useCoursesList } from '@/controllers/courses/courses.hooks/useCoursesList';
import { CourseStatus, CourseType } from '@/controllers/graphql/generated';
import { SelectOptionInterface } from '@/controllers/forms/forms.typedefs';
import { ConsultationFormFields } from '@/components/courseLanding/CourseConsultationForm/ConsultationForm.typedefs';

interface Props {
  types: CourseType[];
  fieldName: keyof ConsultationFormFields;
  courseId?: number;
  courseSlug?: string;
}

interface HookOutput {
  courseOptions: SelectOptionInterface<string>[];
  initiallySelectedCourse?: SelectOptionInterface<string> | null;
}

export const useCourseOptions = (props: Props): HookOutput => {
  const {
    types,
    fieldName,
    courseId,
    courseSlug,
  } = props;

  const form = useForm<ConsultationFormFields>();

  const [courses] = useCoursesList({
    statuses: CourseStatus.Active,
    types,
  });

  const courseOptions = useMemo(() => {
    const sortedCourses = [...courses].sort(
      (a, b) => (
        a.type.localeCompare(b.type)
        || a.slug.localeCompare(b.slug)
      ),
    );

    return sortedCourses.map((course): SelectOptionInterface<string> => ({
      value: course.slug,
      label: course.nameShort,
      courseId: course.id,
      relatedCourseSlug: course.relatedCourseSlug,
    }));
  }, [courses]);

  const initiallySelectedCourse = useMemo(() => {
    if (courseOptions.length === 1) {
      return courseOptions[0];
    }

    if (courseId) {
      return courseOptions.find(({ courseId: id }) => id === courseId);
    }

    if (courseSlug) {
      return courseOptions.find(({ value, relatedCourseSlug }) => (
        [value, relatedCourseSlug].includes(courseSlug)
      ));
    }

    return null;
  }, [courseSlug, courseId, courseOptions]);

  if (initiallySelectedCourse) {
    form.change(fieldName, initiallySelectedCourse);
  }

  return {
    courseOptions,
    initiallySelectedCourse,
  };
};
