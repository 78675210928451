import { IconFacebook } from '@/components/ui/icons/IconFacebook';
import { IconInstagram } from '@/components/ui/icons/IconInstagram';
import { IconLinkedIn } from '@/components/ui/icons/IconLinkedIn';
import { IconTelegram } from '@/components/ui/icons/IconTelegram';
import { IconTikTok } from '@/components/ui/icons/IconTikTok';
import { IconX } from '@/components/ui/icons/IconX';
import { IconYoutube } from '@/components/ui/icons/IconYoutube';
import { SubDomains } from '@/constants/subdomains';
import { SocialMediaLink } from '../ProfessionFooter.typedefs';

export const socialMediaLinks: { [key: string]: SocialMediaLink[] } = {
  [SubDomains.ua]: [
    {
      id: 1,
      title: 'Facebook',
      link: 'https://www.facebook.com/mateacademy/',
      Icon: IconFacebook,
    },
    {
      id: 2,
      title: 'Instagram',
      link: 'https://instagram.com/mate_academy',
      Icon: IconInstagram,
    },
    {
      id: 3,
      title: 'Telegram',
      link: 'https://t.me/mate_academy_events',
      Icon: IconTelegram,
    },
    {
      id: 4,
      title: 'Youtube',
      link: 'https://www.youtube.com/mateacademy?sub_confirmation=1',
      Icon: IconYoutube,
    },
    {
      id: 5,
      title: 'TikTok',
      link: 'https://www.tiktok.com/@mate.academy?_t=8cltJwaFely&_r=1',
      Icon: IconTikTok,
    },
    {
      id: 6,
      title: 'LinkedIn',
      link: 'https://www.linkedin.com/school/mateacademy/',
      Icon: IconLinkedIn,
    },
    {
      id: 7,
      title: 'X',
      link: 'https://x.com/MateAcademy',
      Icon: IconX,
    },
  ],
  [SubDomains.pl]: [
    {
      id: 1,
      title: 'Facebook',
      link: 'https://www.facebook.com/mateacademy.polska',
      Icon: IconFacebook,
    },
    {
      id: 2,
      title: 'Instagram',
      link: 'https://www.instagram.com/mateacademy.pl',
      Icon: IconInstagram,
    },
    {
      id: 3,
      title: 'Youtube',
      link: 'https://www.youtube.com/@Mateacademy_Polska',
      Icon: IconYoutube,
    },
    {
      id: 4,
      title: 'TikTok',
      link: 'https://www.tiktok.com/@mate_academy_pl',
      Icon: IconTikTok,
    },
    {
      id: 5,
      title: 'LinkedIn',
      link: 'https://www.linkedin.com/company/mate-academy-polska/',
      Icon: IconLinkedIn,
    },
  ],
  default: [
    {
      id: 1,
      title: 'Facebook',
      link: 'https://www.facebook.com/mate.academy.int',
      Icon: IconFacebook,
    },
    {
      id: 2,
      title: 'Instagram',
      link: 'https://www.instagram.com/mate.academy_int/',
      Icon: IconInstagram,
    },
    {
      id: 3,
      title: 'LinkedIn',
      link: 'https://www.linkedin.com/school/mateacademy/',
      Icon: IconLinkedIn,
    },
  ],
};
