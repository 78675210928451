import React, {
  FC, useCallback, MouseEvent, KeyboardEvent,
} from 'react';
import ReactModal from 'react-modal';
import { cn } from '@/lib/classNames';
import { Selectors } from '@/lib/selectors';
import styles from '@/components/ui/Modal/Modal.module.scss';
import { ComponentWrapper } from '@/components/ui/helpers';
import { NoSSR } from '@/components/common/NoSSR';
import { Loader } from '@/components/ui/Loader';
import { Button } from '@/components/ui/Button';
import { IconClose } from '@/components/ui/icons/IconClose';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { IconInfo } from '@/components/ui/icons/IconInfo';
import { colorTokens } from '@/components/ui/colorTokens';
import { IconSuccess } from '@/components/ui/icons/IconSuccess';
import { IconWarningTriangle } from '@/components/ui/icons/IconWarningTriangle';
import { ErrorIcon } from '@/components/ui/icons/ErrorIcon';

export interface Props extends ReactModal.Props {
  overflowVisible?: boolean;
  renderCloseButton?: boolean;
  renderTitle?: () => JSX.Element;
  renderTitleIcon?: () => JSX.Element;
  autoWidth?: boolean;
  paddingless?: boolean;
  videoModal?: boolean;
  onRequestClose?(event: React.MouseEvent | React.KeyboardEvent): void;
  loading?: boolean;
  closeIconClassName?: string;
  stretch?: boolean;
  verticalPositioning?: VerticalPositionMode;
  type?: PopUpTypeMode;
  titleClassNames?: string;
  scrollDisabled?: boolean;
  dataQa?: string;
  overlayDisabled?: boolean;
  borderless?: boolean;
  scrollAreaClassName?: string;
  isFullScreen?: boolean;
}

export enum VerticalPositionMode {
  Top = 'Top',
  Center = 'Center',
  Bottom = 'Bottom',
}

const verticalPositionClasses: Record<VerticalPositionMode, string> = {
  [VerticalPositionMode.Top]: styles.verticalTopPositioning,
  [VerticalPositionMode.Bottom]: styles.verticalBottomPositioning,
  [VerticalPositionMode.Center]: '',
};

export enum PopUpTypeMode {
  Website = 'website',
  Paywall = 'paywall',
  Default = 'default',
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Info = 'info',
  System = 'system',
}

const modalIconByType: Record<PopUpTypeMode, FCIcon | null> = {
  [PopUpTypeMode.Info]: () => <IconInfo color={colorTokens['fill-info-bold']} />,
  [PopUpTypeMode.Error]: () => <ErrorIcon color={colorTokens['fill-danger-bold']} />,
  [PopUpTypeMode.Warning]: () => <IconWarningTriangle color={colorTokens['fill-warning-bold']} />,
  [PopUpTypeMode.Success]: () => <IconSuccess color={colorTokens['fill-success-bold']} />,
  [PopUpTypeMode.System]: () => <IconInfo color={colorTokens['icon-secondary']} />,
  [PopUpTypeMode.Paywall]: null,
  [PopUpTypeMode.Default]: null,
  [PopUpTypeMode.Website]: null,
};

export interface ModalStaticInterface {
  type: typeof PopUpTypeMode;
}

export const Modal: FC<Props> & ModalStaticInterface = (props) => {
  const {
    className,
    overlayClassName,
    ...rest
  } = props;

  const {
    children,
    overflowVisible,
    autoWidth,
    paddingless,
    videoModal,
    onRequestClose,
    renderCloseButton = true,
    renderTitle,
    renderTitleIcon,
    loading,
    closeIconClassName = '',
    stretch = false,
    verticalPositioning = VerticalPositionMode.Center,
    type = PopUpTypeMode.Website,
    titleClassNames,
    scrollDisabled = false,
    dataQa = 'modal',
    overlayDisabled = false,
    borderless = false,
    scrollAreaClassName,
    isFullScreen = false,
  } = rest;

  const onClose = useCallback((
    event: MouseEvent | KeyboardEvent,
  ) => {
    onRequestClose?.(event);
  }, [onRequestClose]);

  const ModalIcon = modalIconByType[type];

  const modalIcon: JSX.Element | null = renderTitleIcon
    ? renderTitleIcon()
    : ModalIcon && <ModalIcon />;

  return (
    <NoSSR>
      <ReactModal
        className={
          cn(
            {
              [styles.maModal]: !loading,
              [styles.maModalLoading]: loading,
              [styles.autoWidth]: autoWidth,
              [styles.videoModal]: videoModal,
              [styles.stretch]: stretch,
              [styles.unbounded]: overflowVisible,
              [styles.fullScreen]: isFullScreen,
            },
            'position-relative',
            styles.modalContainer,
            className,
            verticalPositionClasses[verticalPositioning],
          )
        }
        overlayClassName={cn(
          styles.maModalOverlay,
          overlayClassName,
          { [styles.maModalOverlayDisabled]: overlayDisabled },
        )}
        ariaHideApp={false}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        closeTimeoutMS={350}
        {...rest}
      >
        {
          loading
            ? (
              <Loader
                loading
                size={48}
                className={Selectors.ColorTransparent}
              />
            )
            : (
              <div
                className={cn(
                  {
                    [styles.unbounded]: overflowVisible,
                    [styles.paddingless]: paddingless,
                  },
                )}
                data-qa={dataQa}
              >
                <>
                  {renderTitle && (
                    <div
                      data-qa='modal-title'
                      className={cn(
                        styles.titleContainer,
                        'small-pt-0',
                        titleClassNames,
                      )}
                    >
                      {renderCloseButton && (
                        <Button
                          dataQa='close-button'
                          onClick={onClose}
                          className={cn(
                            styles.closeButton,
                            closeIconClassName,
                          )}
                          RightIcon={IconClose}
                          mode={Button.mode.TransparentLight}
                          size={Button.size.Small}
                        />
                      )}

                      <ComponentWrapper
                        shouldWrapChildren={!!modalIcon}
                        wrapper={(child) => (
                          <div
                            className={cn(
                              {
                                [styles.iconAndTitleContainer]: !!modalIcon,
                              },
                            )}
                          >
                            {modalIcon}
                            {child}
                          </div>
                        )}
                      >
                        {renderTitle?.()}
                      </ComponentWrapper>
                    </div>
                  )}

                  <div className={cn(
                    styles.content,
                    scrollAreaClassName,
                    {
                      [styles.scrollArea]: !scrollDisabled,
                      [styles.paddingless]: paddingless,
                      [styles.unbounded]: overflowVisible,
                      [styles.borderless]: borderless,
                      [styles.fullScreenScrollArea]: isFullScreen,
                    },
                  )}
                  >
                    {children}
                  </div>
                </>
              </div>
            )
        }
      </ReactModal>
    </NoSSR>
  );
};

Modal.type = PopUpTypeMode;
