export const getAgeFromBirthDate = (birthDate: string) => {
  const today = new Date();
  const birth = new Date(birthDate);
  const userAge = today.getFullYear() - birth.getFullYear();

  // For users who already had invalid birthDate values
  if (userAge > 100) {
    return undefined;
  }

  return userAge;
};
