import React, {
  DetailedHTMLProps, FC, InputHTMLAttributes, memo,
} from 'react';
import { cn } from '@/lib/classNames';
import FormStyles from '@/components/ui/FormElements/FormInputs/FormInputs.module.scss';
import { withFinalFormController } from '@/controllers/forms/forms.hocs/withFinalFormController';

interface Props extends DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> {
  ref?: any;
  positive?: boolean;
  min?: number;
}

export const InputNumberUI: FC<Props> = memo((
  props,
) => {
  const {
    className,
    positive,
    min,
    ...rest
  } = props;

  return (
    <input
      className={cn(className, FormStyles.input)}
      inputMode="numeric"
      type="number"
      {...(positive
        ? { min: min || 0 }
        : {})}
      {...rest}
    />
  );
});

export const InputNumber = withFinalFormController<string>()<Props>(
  InputNumberUI,
);
