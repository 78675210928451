import React, { FC, SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const BlueEllipse: FC<Props> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="280" height="280" viewBox="0 0 280 280" fill="none">
    <g filter="url(#filter0_f_28471_21037)">
      <circle cx="100" cy="100" r="100" transform="matrix(-1 0 0 1 216 64)" fill="url(#paint0_linear_28471_21037)" fillOpacity="0.5" />
    </g>
    <defs>
      <filter id="filter0_f_28471_21037" x="-48" y="0" width="328" height="328" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="32" result="effect1_foregroundBlur_28471_21037" />
      </filter>
      <linearGradient id="paint0_linear_28471_21037" x1="155.949" y1="381.875" x2="-109.813" y2="274.225" gradientUnits="userSpaceOnUse">
        <stop stopColor="#22B0FC" />
        <stop offset="1" stopColor="#22B0FC" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
