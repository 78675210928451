import React, { FC } from 'react';
import { Button } from '@/components/ui/Button';
import { InputText } from '@/components/ui/FormElements/FormInputs/InputText';
import {
  RequestStatus,
} from '@/components/courseLanding/CourseConsultationForm/components/ConsultationPromoCode/ConsultationPromoCode.typedefs';
import { IconErrorAlternate } from '@/components/ui/icons/IconErrorAlternate';
import { cn } from '@/lib';
import {
  PromoCodeMessage,
} from '@/components/courseLanding/CourseConsultationForm/components/ConsultationPromoCode/PromoCodeComponents/PromoCodeMessage';
import { RenderInputProps } from '@/components/ui/FormElements/FormField';
import styles from '../ConsultationPromoCode.module.scss';

interface Props {
  handleOnInputChange: () => void;
  handleClear: () => void;
  requestStatus: RequestStatus | null;
  message: string | null;
  inputProps: RenderInputProps;
  dataQa?: string;
}

export const PromoCodeInputSubmitted: FC<Props> = (props) => {
  const {
    requestStatus,
    handleOnInputChange,
    handleClear,
    message,
    inputProps,
    dataQa,
  } = props;

  return (
    <>
      <div className={styles.promoCodeInput}>
        <InputText
          {...inputProps}
          className={cn(styles.submittedInput, {
            [styles.inputError]: requestStatus === RequestStatus.Error,
            [styles.inputSuccess]: requestStatus === RequestStatus.Success,
          })}
          onInput={handleOnInputChange}
          dataQa={dataQa}
        />

        <Button
          data-qa={`${dataQa}-button-clear`}
          onClick={handleClear}
          LeftIcon={IconErrorAlternate}
          className={styles.cancelButton}
        />
      </div>

      {message && (
        <PromoCodeMessage
          requestStatus={requestStatus}
          message={message}
        />
      )}
    </>
  );
};
