import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconChartCircle: FCIcon = (props) => (
  <BaseIcon
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M16.9009 17.167C16.8062 17.167 16.7172 17.1985 16.645 17.2595C14.1985 19.3295 9.23727 23.5233 7.91675 24.6395C7.7379 24.7907 7.72958 25.0602 7.90081 25.2199C10.1649 27.3326 13.2012 28.6283 16.5344 28.6283C22.9616 28.6283 28.2824 23.8146 29.0917 17.6046C29.1222 17.3707 28.937 17.167 28.7012 17.167H16.9009Z" fill="#507D81" />
    <path fillRule="evenodd" clipRule="evenodd" d="M17.3867 14.7657C17.3867 14.9865 17.5658 15.1657 17.7867 15.1657H28.7343C28.965 15.1657 29.1485 14.9703 29.1265 14.7407C28.5511 8.75093 23.7947 3.97609 17.8135 3.37211C17.5833 3.34885 17.3867 3.53253 17.3867 3.76399V14.7657Z" fill="#CCE7E9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.2478 15.8203C15.3376 15.7443 15.3872 15.6344 15.3872 15.5167V3.78936C15.3872 3.55422 15.1847 3.36932 14.9514 3.39856C8.71212 4.18068 3.86719 9.51294 3.86719 15.9609C3.86719 18.5856 4.67973 21.0188 6.05424 23.0424C6.18481 23.2347 6.44941 23.2693 6.62673 23.1191C7.907 22.0349 12.6594 18.0105 15.2478 15.8203Z" fill="#CCE7E9" />
  </BaseIcon>
);
