import React, { FC } from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import maleStarEye from '@/images/generated/icons.emoji.maleStarEye.s3Key';
import {
  useFormatComparisonRowsData,
} from '@/components/homepageLanding/StudyingFormatsSectionRedesign/hooks/useFormatComparisonRowsData';
import { GeneralComparisonTable } from '@/components/homepageLanding/GeneralFormatComparison/GeneralComparisonTable';
import { SectionBase, SectionBaseType } from '@/components/landing/BaseSectionWrappers/SectionBase';
import { ContentContainer } from '@/components/landing/BaseSectionWrappers/ContentContainer';
import { BaseSectionHeader } from '@/components/landing/BaseSectionWrappers/BaseSectionHeader';
import { BackgroundColorMode } from '@/components/professionLanding/BubblePhrase/BubblePhrase';

export const StudyingFormatsSectionRedesign: FC = () => {
  const { t } = useTranslation([I18N_CODES.home]);

  const data = useFormatComparisonRowsData();

  return (
    <SectionBase type={SectionBaseType.Default}>
      <ContentContainer>
        <div className="mb-56 medium-mb-32 small-mb-8">
          <BaseSectionHeader
            title={t(`${I18N_CODES.home}:home_studying_formats_title`)}
            personImageData={maleStarEye}
            quote={t(`${I18N_CODES.home}:home_studying_formats_phrase`)}
            phraseBackgroundColorMode={BackgroundColorMode.Gray}
          />
        </div>

        <GeneralComparisonTable data={data} />
      </ContentContainer>
    </SectionBase>
  );
};
