import React, { FC, useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { ConsultationForm } from '@/components/courseLanding/CourseConsultationForm/ConsultationForm';
import { CourseType } from '@/controllers/graphql/generated';
import { LandingPopup } from '@/components/common/Popup/LandingPopup';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { cn } from '@/lib';
import { ConsultationFormFields } from '@/components/courseLanding/CourseConsultationForm/ConsultationForm.typedefs';
import { useProcessConsultationRequest } from '@/controllers/user/user.hooks/useProcessConsultationRequest';
import { useEnteredUserValues } from '@/controllers/consultation/useEnteredUserValues';
import {
  ConsultationFormMessage,
} from '@/components/courseLanding/CourseConsultationForm/components/ConsultationFormMessage/ConsultationFormMessage';
import styles from './ConsultationFormPopup.module.scss';

interface Props {
  source: string;
  isOpen: boolean;
  handleClosePopup: () => void;
}

export const ConsultationFormPopup: FC<Props> = (props) => {
  const {
    isOpen,
    handleClosePopup,
    source,
  } = props;

  const { t } = useTranslation([I18N_CODES.common]);

  const [
    shouldShowSuccessMessage,
    setShouldShowSuccessMessage,
  ] = useState(false);
  const [requestError, setRequestError] = useState<Error | null>(null);
  const [processConsultationRequest] = useProcessConsultationRequest();

  const {
    setEnteredUserEmail,
    setEnteredUserPhone,
    setEnteredUserName,
  } = useEnteredUserValues();

  const submitCallback = useCallback(async (
    data: ConsultationFormFields,
  ): Promise<void> => {
    const {
      courseSlug,
      name = '',
      phone = '',
      email = '',
      newslettersSubscription,
      age,
    } = data;

    setEnteredUserName(name);
    setEnteredUserPhone(phone);
    setEnteredUserEmail(email);

    const selectedSlug = courseSlug?.value || '';
    const courseId = courseSlug?.courseId || 0;

    try {
      await processConsultationRequest({
        name,
        phone,
        email,
        age,
        courseId,
        selectedSlug,
        source: source as string,
        newslettersSubscription,
      });

      setShouldShowSuccessMessage(true);
    } catch (error) {
      setRequestError(error as Error);
    }
  }, [
    setEnteredUserName,
    setEnteredUserPhone,
    setEnteredUserEmail,
    setShouldShowSuccessMessage,
    processConsultationRequest,
    source,
  ]);

  return (
    <LandingPopup
      isOpen={isOpen}
      handleClose={handleClosePopup}
    >
      <p className={cn(styles.title, 'mb-16')}>
        {t(`${I18N_CODES.common}:consultation_form_popup.title`)}
      </p>

      <Form
        onSubmit={submitCallback}
        subscription={{ submitting: true, pristine: true }}
        render={(formRenderProps) => (
          <ConsultationForm
            {...formRenderProps}
            types={[CourseType.Consultation]}
            buttonProps={{
              text: t(`${I18N_CODES.common}:consultation_form_popup.button`),
            }}
            consultationRequestError={requestError}
          />
        )}
      />

      {shouldShowSuccessMessage && (
        <ConsultationFormMessage className={styles.successScreen} />
      )}
    </LandingPopup>
  );
};
