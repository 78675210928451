import React, { FC, memo } from 'react';
import { cn } from '@/lib';
import { Button } from '@/components/ui/Button';
import { IconPlusCustom } from './IconPlusCustom';
import styles from './ItemActionButton.module.scss';

enum ActionButtonMode {
  LIGHT = 'light',
  DARK = 'dark',
}

const actionButtonModeClass = {
  [ActionButtonMode.LIGHT]: styles.light,
  [ActionButtonMode.DARK]: styles.dark,
};

interface Props {
  onClick: () => void;
  isOpen: boolean;
  mode?: ActionButtonMode;
}

const ItemActionButtonTemplate: FC<Props> = memo((props) => {
  const {
    onClick,
    isOpen,
    mode = ActionButtonMode.DARK,
  } = props;

  return (
    <Button
      onClick={onClick}
      renderLeftIcon={() => (
        <IconPlusCustom className={actionButtonModeClass[mode]} />
      )}
      mode={Button.mode.TransparentLight}
      className={cn(
        styles.actionButton,
        {
          [styles.itemOpened]: isOpen,
        },
      )}
    />
  );
});

export const ItemActionButton = Object.assign(ItemActionButtonTemplate, {
  mode: ActionButtonMode,
});
