import { IconFacebookColor } from '@/components/homepageLanding/SocialMediasSection/static/IconFacebookColor';
import { IconInstagramColor } from '@/components/homepageLanding/SocialMediasSection/static/IconInstagramColor';
import { IconTelegramColor } from '@/components/homepageLanding/SocialMediasSection/static/IconTelegramColor';
import { IconYoutubeColor } from '@/components/homepageLanding/SocialMediasSection/static/IconYoutubeColor';
import { IconTikTokColor } from '@/components/homepageLanding/SocialMediasSection/static/IconTikTokColor';
import { IconLinkedinColor } from '@/components/homepageLanding/SocialMediasSection/static/IconLinkedinColor';
import { IconXColor } from '@/components/homepageLanding/SocialMediasSection/static/IconXColor';
import { IconPodcastColor } from '@/components/homepageLanding/SocialMediasSection/static/IconPodcastColor';
import { SubDomains } from '@/constants/subdomains';
import { SocialMediaLink } from '@/components/professionLanding/ProfessionFooter/ProfessionFooter.typedefs';
import { I18N_CODES } from '@/lib/constants/general';
import {
  FaqItem,
  Review,
} from '@/components/professionLanding/ProfessionLanding.typedefs';

import { ReviewButtonType } from '@/components/professionLanding/ReviewsSection/components/ReviewButton';
import { ArticleAboutUs } from '@/components/homepageLanding/HomepageLanding.typedefs';
import { IconNotebookDuotone } from '@/components/ui/icons/IconNotebookDuotone';
import { IconHeadphonesDuotone } from '@/components/ui/icons/IconHeadphonesDuotone';
import deloUaLogoColor from '@/images/generated/professionLanding.logos.deloUaLogoColor.s3Key';
import ePravdaLogoColor from '@/images/generated/professionLanding.logos.ePravdaLogoColor.s3Key';
import douLogo from '@/images/generated/professionLanding.logos.douLogo.s3Key';

export const HOME_PAGE_FAQS: { [key: string]: FaqItem[] } = {
  [SubDomains.ua]: [
    {
      id: 0,
      titleCode: `${I18N_CODES.home}:profession_faq_how_to_start_question`,
      contentCode: `${I18N_CODES.home}:profession_faq_how_to_start_answer`,
    },
    {
      id: 1,
      titleCode: `${I18N_CODES.course}:profession_faq_tech_background_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_tech_background_answer`,
    },
    {
      id: 2,
      titleCode: `${I18N_CODES.course}:profession_faq_age_limits_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_age_limits_answer`,
    },
    {
      id: 3,
      titleCode: `${I18N_CODES.home}:profession_faq_how_to_choose_question`,
      contentCode: `${I18N_CODES.home}:profession_faq_how_to_choose_answer`,
      shouldShowCta: true,
      ctaTextCode: `${I18N_CODES.home}:profession_faq_how_to_choose_cta`,
    },
    {
      id: 4,
      titleCode: `${I18N_CODES.home}:profession_faq_certificate_question`,
      contentCode: `${I18N_CODES.home}:profession_faq_certificate_answer`,
    },
    {
      id: 5,
      titleCode: `${I18N_CODES.course}:profession_faq_find_job_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_find_job_answer`,
    },
    {
      id: 6,
      titleCode: `${I18N_CODES.course}:profession_faq_money_back_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_money_back_answer`,
    },
  ],
};

export const HOME_PAGE_REVIEWS: Record<string, Review[]> = {
  [SubDomains.ua]: [
    {
      id: 0,
      text: `${I18N_CODES.course}:profession_review_1_courses_parttime`,
      author: `${I18N_CODES.course}:profession_review_author_1_courses_parttime`,
      reviewLink: `${I18N_CODES.course}:profession_review_link_1_courses_parttime`,
      reviewType: ReviewButtonType.Google,
    },
    {
      id: 1,
      text: `${I18N_CODES.course}:profession_review_2_courses_parttime`,
      author: `${I18N_CODES.course}:profession_review_author_2_courses_parttime`,
      reviewLink: `${I18N_CODES.course}:profession_review_link_2_courses_parttime`,
      reviewType: ReviewButtonType.Google,
    },
    {
      id: 2,
      text: `${I18N_CODES.course}:profession_review_3_courses_parttime`,
      author: `${I18N_CODES.course}:profession_review_author_3_courses_parttime`,
      reviewLink: `${I18N_CODES.course}:profession_review_link_3_courses_parttime`,
      reviewType: ReviewButtonType.Dou,
    },
    {
      id: 3,
      text: `${I18N_CODES.course}:profession_review_4_courses_parttime`,
      author: `${I18N_CODES.course}:profession_review_author_4_courses_parttime`,
      reviewLink: `${I18N_CODES.course}:profession_review_link_4_courses_parttime`,
      reviewType: ReviewButtonType.Dou,
    },
    {
      id: 4,
      text: `${I18N_CODES.course}:profession_review_5_courses_parttime`,
      author: `${I18N_CODES.course}:profession_review_author_5_courses_parttime`,
      reviewLink: `${I18N_CODES.course}:profession_review_link_5_courses_parttime`,
      reviewType: ReviewButtonType.Dou,
    },
  ],
};

export const SOCIAL_MEDIA_LINKS: { [key: string]: SocialMediaLink[] } = {
  [SubDomains.ua]: [
    {
      id: 1,
      title: 'Facebook',
      Icon: IconFacebookColor,
      link: 'https://www.facebook.com/mateacademy/',
    },
    {
      id: 2,
      title: 'Instagram',
      Icon: IconInstagramColor,
      link: 'https://instagram.com/mate_academy',
    },
    {
      id: 3,
      title: 'Telegram',
      Icon: IconTelegramColor,
      link: 'https://t.me/mate_academy_events',
    },
    {
      id: 4,
      title: 'Youtube',
      Icon: IconYoutubeColor,
      link: 'https://www.youtube.com/mateacademy?sub_confirmation=1',
    },
    {
      id: 5,
      title: 'TikTok',
      Icon: IconTikTokColor,
      link: 'https://www.tiktok.com/@mate.academy?_t=8cltJwaFely&_r=1',
    },
    {
      id: 6,
      title: 'LinkedIn',
      Icon: IconLinkedinColor,
      link: 'https://www.linkedin.com/school/mateacademy/',
    },
    {
      id: 7,
      title: 'X',
      Icon: IconXColor,
      link: 'https://x.com/MateAcademy',
    },
    {
      id: 8,
      title: 'SocialPodcast',
      Icon: IconPodcastColor,
      link: 'https://www.youtube.com/playlist?list=PL7FuXFaDeEX1fOSM3CabkYUlJX3PXZNIN',
    },
  ],
};

export const ARTICLES_ABOUT_US: { [key: string]: ArticleAboutUs[] } = {
  [SubDomains.ua]: [
    {
      id: 1,
      Icon: IconNotebookDuotone,
      heading: `${I18N_CODES.home}:article_about_us_heading_type_article`,
      logoImageData: deloUaLogoColor,
      logoSize: {
        width: 56,
        height: 28,
      },
      text: `${I18N_CODES.home}:article_about_us_text_1`,
      link: 'https://www.epravda.com.ua/publications/2023/06/28/701651/',
    },
    {
      id: 2,
      Icon: IconHeadphonesDuotone,
      heading: `${I18N_CODES.home}:article_about_us_heading_type_podcast`,
      logoImageData: douLogo,
      logoSize: {
        width: 49,
        height: 18,
      },
      text: `${I18N_CODES.home}:article_about_us_text_2`,
      link: 'https://dou.ua/lenta/articles/dou-podcast-45/',
    },
    {
      id: 3,
      Icon: IconNotebookDuotone,
      heading: `${I18N_CODES.home}:article_about_us_heading_type_article`,
      logoImageData: ePravdaLogoColor,
      logoSize: {
        width: 190,
        height: 24,
      },
      text: `${I18N_CODES.home}:article_about_us_text_3`,
      link: 'https://delo.ua/robota-i-osvita/mi-pracevlastuvali-4-tisyaci-lyudei-u-sferi-it-shho-stanovit-priblizno-2-vid-zagalnoyi-kilkosti-ukrayinskix-aitivciv-mate-academy-435307/',
    },
  ],
};
