import React, { FC } from 'react';
import {
  SectionBase,
  SectionBaseType,
} from '@/components/landing/BaseSectionWrappers/SectionBase';

export const HowDoWeTeachSection: FC = () => (
  <SectionBase
    type={SectionBaseType.WideWithLightBackground}
  >
    How do we teach section
  </SectionBase>
);
