import {
  ConsultationRedirectLinkProps,
  RegistrationQuery, SuccessPageProps,
} from '@/components/consultation/consultation.typedefs';
import { ROUTES } from '@/controllers/router/router.contants';
import { allRoutes, renderRoute } from '@/controllers/router/allRoutes';

export const getSuccessRedirectLink = (
  props: ConsultationRedirectLinkProps,
) => {
  const {
    courseSlug,
    shouldRedirectToTrialIntro,
    subscriptionPlanUuid,
    shouldRedirectToIntroductionTopic,
    moduleSlug,
    topicSlug,
    redirectUrl,
    isStudyRouteEnabled,
  } = props;

  if (redirectUrl) {
    return redirectUrl;
  }

  if (!courseSlug) {
    return isStudyRouteEnabled
      ? renderRoute(allRoutes.study.autoRedirect)
      : renderRoute(allRoutes.learn, {});
  }

  if (subscriptionPlanUuid) {
    return `${ROUTES.subscription.subscriptionPay}/${subscriptionPlanUuid}`;
  }

  if (shouldRedirectToTrialIntro) {
    return isStudyRouteEnabled
      ? renderRoute(allRoutes.study.profession.courseTopic, {
        professionSlug: 'test-drive',
        moduleSlug: 'test-drive',
        courseTopicSlug: 'welcome-to-test-drive',
      })
      : renderRoute(allRoutes.learn.courseTopic, {
        moduleSlug: 'test-drive',
        courseTopicSlug: 'welcome-to-test-drive',
      });
  }

  if (
    shouldRedirectToIntroductionTopic
    && moduleSlug
    && topicSlug
  ) {
    return isStudyRouteEnabled
      ? renderRoute(allRoutes.study.profession.courseTopic, {
        professionSlug: courseSlug,
        moduleSlug,
        courseTopicSlug: topicSlug,
      })
      : renderRoute(allRoutes.learn.courseTopic, {
        moduleSlug,
        courseTopicSlug: topicSlug,
      });
  }

  if (courseSlug) {
    return isStudyRouteEnabled
      ? renderRoute(allRoutes.study.profession, {
        professionSlug: courseSlug,
      })
      : renderRoute(allRoutes.learn, {
        course: courseSlug,
      });
  }

  return isStudyRouteEnabled
    ? renderRoute(allRoutes.study.autoRedirect)
    : renderRoute(allRoutes.learn, {});
};

export const successPageData = (props: SuccessPageProps) => {
  const {
    courseSlug,
    shouldRedirectToTrialIntro,
    shouldRedirectToIntroductionTopic,
    moduleSlug,
    topicSlug,
    redirectUrl,
    isStudyRouteEnabled,
  } = props;

  return ({
    redirectLink: getSuccessRedirectLink({
      courseSlug,
      shouldRedirectToTrialIntro,
      shouldRedirectToIntroductionTopic,
      moduleSlug,
      topicSlug,
      redirectUrl,
      isStudyRouteEnabled,
    }),
    text: 'success_page_pt_text',
    buttonName: 'success_page_pt_submit',
  });
};

export const buildQuery = (query: RegistrationQuery) => {
  const queryString = Object.entries(query)
    .map(([key, value]) => (
      `${key}=${value}`
    ))
    .join('&');

  return queryString.length > 0
    ? `?${queryString}`
    : '';
};
