import React, { FC } from 'react';
import { FloatIconsRedesigned } from '@/components/landing/FloatIconsRedesigned/FloatIconsRedesigned';
import { useIsOpenState } from '@/hooks/useIsOpenState';
import { useHandleClickOutside } from '@/hooks/useHandleClickOutside';
import floatIconStyles from '@/components/landing/FloatIconsRedesigned/FloatIconsRedesigned.module.scss';
import { useSupportSocials } from '@/hooks/useSupportSocials';
import { SupportLinkGenericRedesigned, SupportLinkRedesigned } from '../SupportLinkRedesigned';

export const LandingSupportButtonRedesigned: FC = () => {
  const {
    isOpen: isSupportOpen,
    close: closeSupport,
    open: openSupport,
  } = useIsOpenState();

  useHandleClickOutside(() => {
    if (isSupportOpen) {
      closeSupport();
    }
  }, floatIconStyles.wrapper);

  const supportSocials = useSupportSocials();

  return (
    isSupportOpen
      ? (
        <FloatIconsRedesigned>
          {supportSocials.map((getSupportSocial) => (
            <SupportLinkRedesigned
              key={getSupportSocial.source}
              providerName={getSupportSocial.name}
              href={getSupportSocial.source}
            />
          ))}
        </FloatIconsRedesigned>
      )
      : (
        <FloatIconsRedesigned>
          <SupportLinkGenericRedesigned
            onClick={openSupport}
          />
        </FloatIconsRedesigned>
      )
  );
};
