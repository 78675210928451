import React, { FC } from 'react';
import ReactModal from 'react-modal';
import { NoSSR } from '@/components/common/NoSSR';
import { Button } from '@/components/ui/Button';
import { IconClose } from '@/components/ui/icons/IconClose';
import styles from './LandingModal.module.scss';

export interface Props extends ReactModal.Props {
  isOpen: boolean;
  close: () => void;
}

export const LandingModal: FC<Props> = (props) => {
  const {
    isOpen,
    close,
    children,
  } = props;

  return (
    <NoSSR>
      <ReactModal
        className={styles.modalWrapper}
        overlayClassName={styles.modalOverlay}
        ariaHideApp={false}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={close}
        closeTimeoutMS={350}
        isOpen={isOpen}
      >
        <Button
          dataQa='close-button'
          onClick={close}
          className={styles.closeButton}
          RightIcon={IconClose}
          mode={Button.mode.TransparentLight}
          size={Button.size.Small}
        />

        {children}
      </ReactModal>
    </NoSSR>
  );
};
