import { FCIcon } from '@/components/ui/icons/typedefs';
import { LogoIconWhatsapp } from '@/components/ui/icons/logos/LogoIconWhatsapp';
import { IconViber } from '@/components/ui/icons/IconViber';
import { IconFacebookChat } from '@/components/ui/icons/IconFacebookChat';
import { LogoIconTelegram } from '@/components/ui/icons/logos/LogoIconTelegram';
import { SupportSocialNames } from '@/controllers/graphql/generated';

export const iconComponents: Record<SupportSocialNames, FCIcon> = {
  [SupportSocialNames.Telegram]: LogoIconTelegram,
  [SupportSocialNames.Whatsapp]: LogoIconWhatsapp,
  [SupportSocialNames.Viber]: IconViber,
  [SupportSocialNames.Facebook]: IconFacebookChat,
};
