import React, { FC } from 'react';
import careerTestImageEN from '@/images/generated/professionLanding.choosingProfession.careerTestImageEN.s3Key';
import consultationRequestImageEN from '@/images/generated/professionLanding.choosingProfession.consultationRequestImageEN.s3Key';
import { SectionBase, SectionBaseType } from '@/components/landing/BaseSectionWrappers/SectionBase';
import { ContentContainer } from '@/components/landing/BaseSectionWrappers/ContentContainer';
import { BaseSectionHeader } from '@/components/landing/BaseSectionWrappers/BaseSectionHeader';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { Button } from '@/components/ui/Button';
import { IconImage } from '@/components/ui/Image/IconImage';
import { ConsultationFormPopup } from '@/components/popUps/ConsultationFormPopup/ConsultationFormPopup';
import { useIsOpenStateWithBlockPageScroll } from '@/hooks/useIsOpenStateWithBlockPageScroll';
import { ROUTES } from '@/controllers/router/router.contants';
import styles from './ChoosingProfessionSection.module.scss';

export const ChoosingProfessionSection: FC = () => {
  const { t } = useTranslation([I18N_CODES.home]);

  const source = 'choosing_profession_section';
  const hrefQuiz = ROUTES.consultation.signUpSurvey({
    form_id_modifier: 'merged',
    source,
  });

  const {
    isOpen,
    close,
    open,
  } = useIsOpenStateWithBlockPageScroll();

  return (
    <SectionBase type={SectionBaseType.Default}>
      <ContentContainer>
        <div className="mb-56 medium-mb-32">
          <BaseSectionHeader
            title={t(`${I18N_CODES.home}:profession_choosing_section_title`)}
          />
        </div>

        <div className={styles.cardsContainer}>
          <div className={styles.card}>
            <p className={styles.cardTitle}>
              {t(`${I18N_CODES.home}:choosing_profession_card_title_1`)}
            </p>

            <div className={styles.imageContainer}>
              <IconImage
                imageData={careerTestImageEN}
                width={368}
                height={224}
              />
            </div>

            <p className={styles.cardDescription}>
              {t(`${I18N_CODES.home}:choosing_profession_card_description_1`)}
            </p>

            <Button
              href={hrefQuiz}
              text={t(`${I18N_CODES.home}:choosing_profession_card_button_1`)}
              size={Button.size.Large}
              mode={Button.mode.Neutral}
              className={styles.button}
            />
          </div>

          <div className={styles.card}>
            <p className={styles.cardTitle}>
              {t(`${I18N_CODES.home}:choosing_profession_card_title_2`)}
            </p>

            <div className={styles.imageContainer}>
              <IconImage
                imageData={consultationRequestImageEN}
                width={350}
                height={224}
              />
            </div>

            <p className={styles.cardDescription}>
              {t(`${I18N_CODES.home}:choosing_profession_card_description_2`)}
            </p>

            <Button
              onClick={open}
              text={t(`${I18N_CODES.home}:choosing_profession_card_button_2`)}
              size={Button.size.Large}
              mode={Button.mode.Neutral}
              className={styles.button}
            />
          </div>
        </div>
      </ContentContainer>

      <ConsultationFormPopup
        isOpen={isOpen}
        handleClosePopup={close}
        source={source}
      />
    </SectionBase>
  );
};
