import { LeadScoreType } from '@/controllers/graphql/generated';
import { analyticsSDK } from '@/controllers/analytics';
import { FxTopLeadCreated } from '@/controllers/analytics/generated';

export const processLeadScoreTypeEvent = async (
  leadScoreType: LeadScoreType | undefined,
  eventData: FxTopLeadCreated,
): Promise<void> => {
  if (!leadScoreType) {
    return;
  }

  const isTopLead = (
    [LeadScoreType.Hot, LeadScoreType.Blank].includes(leadScoreType)
  );

  if (isTopLead) {
    await analyticsSDK.global.sendFxTopLeadCreatedEvent(eventData);
  }
};
