import React, { FC } from 'react';
import { Button } from '@/components/ui/Button';
import { ROUTES } from '@/controllers/router/router.contants';
import { I18N_CODES } from '@/lib/constants/general';
import { cn } from '@/lib';
import { IconUser } from '@/components/ui/icons/IconUser';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { useTranslation } from '@/middleware/i18n';
import { useCtaContext } from '@/controllers/cta/Cta.context';
import { useLearningRootForRedirect } from '@/components/platform/Study/pages/urlTools';
import styles from './FooterSignInButtons.module.scss';

export const FooterSignInButtons: FC = () => {
  const [authUser, { loading }] = useAuthUser({ ssr: false });

  const { t } = useTranslation([I18N_CODES.common]);
  const learningRoot = useLearningRootForRedirect();

  const shouldShowAuthenticatedButton = Boolean(authUser);

  const ctaContext = useCtaContext();

  const href = ctaContext.buildHref({
    source: 'landing_footer',
  });

  if (shouldShowAuthenticatedButton) {
    return (
      <Button
        data-qa="footer-go-to-platform-button"
        href={learningRoot}
        size={Button.size.Large}
        mode={Button.mode.Secondary}
        hasFullWidth
        text={t(`${I18N_CODES.common}:go_to_platform`)}
      />
    );
  }

  return (
    <>
      <Button
        data-qa="footer-sign-up-page-link"
        href={href}
        size={Button.size.Large}
        mode={Button.mode.Secondary}
        hasFullWidth
        className={cn(styles.signUpButton, 'mb-16', { [styles.loading]: loading })}
        text={t(`${I18N_CODES.common}:sign_up_page_link`)}
      />

      <Button
        data-qa="footer-sign-in-page-link"
        href={ROUTES.auth.signIn}
        size={Button.size.Large}
        mode={Button.mode.TransparentDark}
        LeftIcon={IconUser}
        hasFullWidth
        className={cn({
          [styles.loadingBlack]: loading,
        })}
        text={t(`${I18N_CODES.common}:sign_in_page_link`)}
      />
    </>
  );
};
