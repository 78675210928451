import React, { memo } from 'react';
import { BackgroundCircles } from '@/components/professionLanding/BackgroundCircles/BackgroundCircles';
import { I18N_CODES } from '@/lib/constants/general';
import imageData from '@/images/generated/professionLanding.coverPersonas.professionsListCoverPeople.s3Key';
import { useTranslation } from '@/middleware/i18n';
import { COURSE_BLOCKS } from '@/components/courseLanding/CoursePage/CoursePageContent.constants';
import { HomePageCoverHeading } from '@/components/homepageLanding/HomePageCoverHeading/HomePageCoverHeading';
import { HomePageCoverStatistics } from '@/components/homepageLanding/HomePageCoverStatistics/HomePageCoverStatistics';
import { HEADING_LABELS } from '@/components/homepageLanding/HomePageCoverStatistics/HomePageCoverStatistics.content';
import { ContentContainer } from '@/components/landing/BaseSectionWrappers/ContentContainer';
import { SectionBase, SectionBaseType } from '@/components/landing/BaseSectionWrappers/SectionBase';
import styles from './HomePageCoverSectionRedesign.module.scss';

export const HomePageCoverSectionRedesign = memo(() => {
  const { t } = useTranslation([I18N_CODES.home, I18N_CODES.cta]);

  const href = `#${COURSE_BLOCKS.allCourses}`;

  return (
    <div className={styles.sectionWrapper}>
      <SectionBase
        type={SectionBaseType.Default}
        className={styles.contentWrapper}
      >
        <BackgroundCircles />

        <ContentContainer>
          <HomePageCoverHeading
            heading={t(`${I18N_CODES.home}:home_cover_heading`)}
            ctaText={t(`${I18N_CODES.home}:home_cover_cta_text`)}
            subHeading={t(`${I18N_CODES.home}:home_cover_subheading`)}
            href={href}
            imageData={imageData}
            headingLabels={HEADING_LABELS}
          />
        </ContentContainer>
      </SectionBase>

      <SectionBase
        type={SectionBaseType.Default}
        className={styles.statisticsWrapper}
      >
        <HomePageCoverStatistics />
      </SectionBase>
    </div>
  );
});
