const {
  DEFAULT_SUB_DOMAIN, DEFAULT_LOCALE, FALLBACK_LOCALE, REGION_DOMAIN,
} = require('../i18n.config');

const parseLanguage = (language) => {
  const domain = ['ru', DEFAULT_LOCALE, FALLBACK_LOCALE].includes(language)
    ? DEFAULT_SUB_DOMAIN
    : language;

  if (language === 'usa') {
    return {
      language: 'en',
      domain,
    }
  }

  return {
    language,
    domain,
  };
};

const parseLanguageRegion = ({ language, region }) => {
  const domain = REGION_DOMAIN[region] ?? region;

  return {
    language,
    domain,
  };
};

const i18nParseLanguageSubpath = (subpath = '') => {
  if (subpath === '') {
    return parseLanguage(DEFAULT_LOCALE);
  }

  const parts = subpath.split('-');

  if (parts.length === 1) {
    return parseLanguage(parts[0]);
  }

  if (parts.length === 2) {
    return parseLanguageRegion({
      language: parts[0],
      region: parts[1],
    });
  }

  return parseLanguage(DEFAULT_LOCALE);
};

module.exports = {
  i18nParseLanguageSubpath,
};
