import React, { FC, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { FormField } from '@/components/ui/FormElements/FormField';
import { isNotEmpty, isValidUserAge } from '@/controllers/forms/forms.validator/validationRules';
import { makeValidator } from '@/controllers/forms/forms.validator';
import { ConsultationFormFields } from '@/components/courseLanding/CourseConsultationForm/ConsultationForm.typedefs';
import { getAgeFromBirthDate } from '@/components/courseLanding/CourseConsultationForm/ConsultationForm.utils';
import { composeValidators } from '@/controllers/forms/forms.helpers';
import { InputNumber } from '@/components/ui/FormElements/FormInputs/InputNumber';
import { useAuthUserQuery } from '@/controllers/user/graphql/generated/authUser.query.generated';

export const ConsultationAge: FC = () => {
  const fieldName = 'age';
  const { t } = useTranslation([I18N_CODES.courseConsultation]);
  const { submitting } = useFormState();
  const authUserQuery = useAuthUserQuery({
    ssr: false,
  });

  const form = useForm<ConsultationFormFields>();

  useEffect(() => {
    const { age } = form.getState().values;

    if (authUserQuery.data?.authUser?.birthDate && !age) {
      const userAge = getAgeFromBirthDate(
        authUserQuery.data?.authUser?.birthDate,
      );

      form.change(fieldName, userAge);
    }
  }, [authUserQuery.data?.authUser?.birthDate, form]);

  return (
    <FormField
      label={{
        for: 'consultation-age',
        text: t(`${I18N_CODES.courseConsultation}:consultation_form_age`),
      }}
      renderInput={(inputProps) => (
        <InputNumber
          config={{
            validate: composeValidators(
              makeValidator(isNotEmpty, t(`${I18N_CODES.courseConsultation}:consultation_form_required_age`)),
              makeValidator(isValidUserAge, t(`${I18N_CODES.courseConsultation}:consultation_form_invalid_age`)),
            ),
          }}
          {...inputProps}
          positive
          placeholder='21'
          required
          data-qa='consultation-age'
        />
      )}
      disabled={submitting}
      name={fieldName}
    />
  );
};
