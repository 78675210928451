import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconDouColor: FCIcon = (props) => (
  <BaseIcon
    width="48"
    height="48"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="48" height="48" rx="4" fill="black" />
    <path
      d="M7 18.1989H7.23349C7.85057 18.1989 9.91863 18.1492 10.6855 18.1492C15.3551 18.1492 17.2397 20.8178 17.2397 23.9005C17.2397 27.1493 15.0051 29.8342 10.5521 29.8342C9.93503 29.8342 8.40067 29.8011 7.28352 29.8011H7V18.1989ZM9.66846 20.6849V27.3148C10.0187 27.3479 10.2522 27.3479 10.6355 27.3479C12.9371 27.3479 14.4047 26.1051 14.4047 23.9005C14.4047 21.7626 12.8203 20.6355 10.6525 20.6355C10.3689 20.6355 10.002 20.6355 9.66846 20.6852V20.6849Z"
      fill="white"
    />
    <path
      d="M24.0498 18C27.6019 18 30.2537 20.304 30.2537 24C30.2537 27.5137 27.6019 30 24.0498 30C20.4977 30 17.8459 27.6963 17.8459 24C17.8459 20.6352 20.3306 18 24.0498 18ZM24.0498 27.5137C26.268 27.5137 27.4185 25.9556 27.4185 24C27.4185 21.8784 25.9341 20.486 24.0498 20.486C22.1152 20.486 20.6812 21.8784 20.6812 24C20.6812 25.989 22.1819 27.5137 24.0498 27.5137Z"
      fill="white"
    />
    <path
      d="M40.7775 25.0111C40.7775 28.5579 38.5096 30 36.0079 30C33.0058 30 30.9214 28.2927 30.9214 24.8951V18.1989H33.5898V24.3315C33.5898 26.1382 34.1399 27.514 36.0079 27.514C37.6256 27.514 38.1093 26.3371 38.1093 24.481V18.1989H40.7777L40.7775 25.0111Z"
      fill="white"
    />
  </BaseIcon>
);
