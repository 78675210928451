import {
  createRoutes,
} from 'typesafe-routes';
import { renderRoute } from '@/controllers/router/type-safe-routes/renderRoute';
import { replaceRouteParams } from '@/controllers/router/type-safe-routes/replaceRouteParams';
import { study } from '@/controllers/router/type-safe-routes/study.routes';
import { learn } from '@/controllers/router/type-safe-routes/learn.routes';

const routesConfig = {
  study,
  learn,
};

export const allRoutes = createRoutes(routesConfig);

export type AllRoutes = typeof allRoutes;

export {
  renderRoute,
  replaceRouteParams,
};
