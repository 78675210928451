import React, { FC, memo } from 'react';
import { IconChatWithDots } from '@/components/ui/icons/IconChatWithDots';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import style from './SupportLinkRedesigned.module.scss';

interface Props {
  onClick: () => void;
}

export const SupportLinkGenericRedesigned: FC<Props> = memo((props) => {
  const { t } = useTranslation([I18N_CODES.common]);
  const { onClick } = props;

  const tooltipText = t(`${I18N_CODES.common}:support_icon_content`);

  return (
    <button
      className={style.containerGeneric}
      onClick={onClick}
      title={tooltipText}
      data-qa='show-supports-button'
    >
      <span className={style.container}>
        <IconChatWithDots className={style.icon} />
      </span>
    </button>
  );
});
