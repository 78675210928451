import React, { FC, memo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ProfessionsListData } from '@/components/coursesParttimeLanding/CoursesParttime.typedefs';
import { IconImage } from '@/components/ui/Image/IconImage';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { AnchorElement } from '@/components/ui/Button/AnchorElement';
import { useCtaContext } from '@/controllers/cta/Cta.context';
import { ProfessionLandingHelpers } from '@/components/professionLanding/ProfessionLanding.helpers';
import { cn } from '@/lib';
import styles from './ProfessionCardRedesigned.module.scss';

type Props = Omit<ProfessionsListData, 'id'>;

export const ProfessionCardRedesigned: FC<Props> = memo((props) => {
  const {
    slug,
    name,
    description,
    duration,
    labels,
    imageData,
  } = props;

  const { t } = useTranslation([I18N_CODES.home, I18N_CODES.course]);

  const { buildHref, pageType } = useCtaContext();

  const cardLinkAdditionalProps = ProfessionLandingHelpers
    .getAdditionalCardLinkProps({
      professionSlug: slug,
      pageType,
    });

  const href = buildHref({
    profession_slug: slug,
    source: 'profession_card',
    ...cardLinkAdditionalProps,
  });

  return (
    <AnchorElement href={href} className={styles.cardWrapper}>
      <div className={styles.head}>
        <div className={styles.labels}>
          {labels.map((CardLabel) => (
            <CardLabel key={uuidv4()} />
          ))}
        </div>

        <div className={styles.image}>
          <IconImage
            imageData={imageData}
            width={120}
            height={120}
            alt={name}
          />
        </div>
      </div>

      <div className={styles.content}>
        <p className={cn(styles.text, styles.duration)}>
          {t(`${I18N_CODES.home}:home_course_duration`, {
            duration,
            count: duration,
          })}
        </p>

        <h3 className={styles.title}>
          {name}
        </h3>

        <p className={cn(styles.text, styles.description)}>
          {t(description)}
        </p>
      </div>
    </AnchorElement>
  );
});
