import React, { memo } from 'react';
import { cn } from '@/lib';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { SubDomains } from '@/constants/subdomains';
import { ROUTES } from '@/controllers/router/router.contants';
import { Link, useTranslation } from '@/middleware/i18n';
import { IconVisaNoBackground } from '@/components/ui/icons/IconVisaNoBackground';
import { I18N_CODES } from '@/lib/constants/general';
import { LogoFull } from '@/components/common/Logo';
import { typography } from '@/components/ui/typography';
import { IconMasterCardWithText } from '@/components/ui/icons/IconMasterCardWithText';
import { ContentContainer } from '@/components/landing/BaseSectionWrappers/ContentContainer';
import { useShouldShowSinglePageForUsaDomain } from '@/hooks/useShouldShowSinglePageForUsaDomain';
import {
  FooterLinksSinglePage,
} from '@/components/professionLanding/ProfessionFooter/components/FooterLinks/FooterLinksSinglePage';
import { FooterSocialLinks } from './components/FooterSocialLinks';
import { FooterAuthButtons } from './components/FooterAuthButtons/FooterAuthButtons';
import { FooterLinks } from './components/FooterLinks/FooterLinks';
import styles from './ProfessionFooter.module.scss';

export const ProfessionFooter = memo(() => {
  const { t } = useTranslation([I18N_CODES.common]);

  const { subDomain } = useSubDomainContext();
  const isPlDomain = subDomain === SubDomains.pl;

  const isSinglePage = useShouldShowSinglePageForUsaDomain();

  return (
    <footer
      className={styles.background}
      data-hide-promo-banner="true"
      data-hide-fixed-cta-button="true"
    >
      <ContentContainer className={cn(
        'grid-x cell',
        styles.footerTop,
        { 'gap-56': isPlDomain },
      )}
      >
        <div className={styles.logoWrapper}>
          <Link href={ROUTES.home}>
            <a className={cn(styles.logoLink, 'mb-16')}>
              <LogoFull className={styles.logo} />
            </a>
          </Link>

          <p className={cn(typography.landingTextMain, styles.address)}>
            {t(`${I18N_CODES.common}:contact_us`, {
              context: subDomain,
            })}
          </p>
        </div>

        {isSinglePage
          ? <FooterLinksSinglePage />
          : <FooterLinks />}

        <div className={styles.authButtons}>
          <FooterAuthButtons />
        </div>
      </ContentContainer>

      <ContentContainer className={cn('cell', styles.footerBottom)}>
        <div className='flex-container align-center-middle gap-8'>
          <p>{t(`${I18N_CODES.common}:profession_footer_copyright`)}</p>

          <IconVisaNoBackground className={styles.iconVisa} />

          <IconMasterCardWithText className={styles.iconMasterCard} />
        </div>

        <FooterSocialLinks />
      </ContentContainer>
    </footer>
  );
});
