import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Form } from 'react-final-form';
import { LogLevels } from '@mate-academy/logger';
import { cn } from '@/lib/classNames';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { typography } from '@/components/ui/typography';
import { ChubbsLandingMessage } from '@/components/ui/landing/Chubbs/ChubbsLandingMessage';
import { ConsultationTelegramForm } from '@/components/courseLanding/CourseConsultationForm/ConsultationTelegramForm';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { TelegramFormFields } from '@/components/courseLanding/CourseConsultationForm/ConsultationForm.typedefs';
import { errorHandler } from '@/core/ErrorHandler';
import { Loader } from '@/components/ui/Loader';
import { logger } from '@/core/Logger';
import { analyticsSDK } from '@/controllers/analytics';
import { PopupAction } from '@/controllers/analytics/generated';
import { useUpdateUserMutation } from '@/controllers/user/graphql/generated/updateUser.mutation.generated';
import styles from './TelegramForm.module.scss';

interface Props {
  closePopup: () => void;
  isCloseButtonVisible?: boolean;
}

export const TelegramForm: FC<Props> = ({
  closePopup,
  isCloseButtonVisible,
}) => {
  const [authUser, { loading }] = useAuthUser({ ssr: false });
  const [updateUser] = useUpdateUserMutation();

  const [
    telegramRequestError,
    setTelegramRequestError,
  ] = useState<Error | null>(null);

  const { t } = useTranslation([
    I18N_CODES.courseConsultation,
  ]);

  const handleShowingPopup = useCallback(() => {
    analyticsSDK.landing.sendTelegramPopupEvent({
      action: PopupAction.Opened,
    });
  }, []);

  const handleConfirmingPopup = useCallback(
    async (data: TelegramFormFields) => {
      const { telegramNickName } = data;

      if (!authUser) {
        errorHandler.captureMessage('authUser not found, close telegram popup', {
          logger: logger.child('TelegramForm'),
          logLevel: LogLevels.Warning,
        });

        closePopup();

        return;
      }

      try {
        await updateUser({
          variables: {
            id: authUser.id,
            values: {
              telegramNickName,
            },
          },
        });

        analyticsSDK.landing.sendTelegramPopupEvent({
          action: PopupAction.Confirmed,
        });

        closePopup();
      } catch (error) {
        setTelegramRequestError(error as Error);

        closePopup();
      }
    },
    [authUser, updateUser, closePopup],
  );

  useEffect(() => {
    handleShowingPopup();
  }, [handleShowingPopup]);

  return (
    <>
      <Loader
        loading={loading}
        size={32}
      />

      <div className={cn(styles.imageWrapper, 'mb-16')}>
        <ChubbsLandingMessage />
      </div>

      <p className={cn(typography.landingTextMain, 'mb-16')}>
        {t(`${I18N_CODES.courseConsultation}:telegram_consultation_faster`)}
      </p>

      <Form
        onSubmit={handleConfirmingPopup}
        subscription={{ submitting: true, pristine: true }}
        mutators={{
          trimInputValue: ([name], state, { changeValue }) => {
            changeValue(state, name, (value) => value.trim());
          },
        }}
        render={(formRenderProps) => (
          <ConsultationTelegramForm
            {...formRenderProps}
            requestError={telegramRequestError}
            closeForm={closePopup}
            isCloseButtonVisible={isCloseButtonVisible}
          />
        )}
      />
    </>
  );
};
