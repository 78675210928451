import React, { memo } from 'react';
import { cn } from '@/lib';
import styles from './ItemActionButton.module.scss';

interface Props {
  className: string;
}

export const IconPlusCustom = memo<Props>((props) => {
  const { className } = props;

  return (
    <div className={cn('flex-container', className)}>
      <span className={styles.horizontalLine} />
      <span className={styles.verticalLine} />
    </div>
  );
});
