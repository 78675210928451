import { FC } from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { SubDomains } from '@/constants/subdomains';
import {
  FaqItem,
  BenefitCardProps,
  ProfessionSlugs,
  Review,
} from '@/components/professionLanding/ProfessionLanding.typedefs';

import maleVictory from '@/images/generated/icons.emoji.maleVictory.s3Key';
import maleShushing from '@/images/generated/icons.emoji.maleShushing.s3Key';
import maleHappy from '@/images/generated/icons.emoji.maleHappy.s3Key';
import maleLike from '@/images/generated/icons.emoji.maleLike.s3Key';
import maleStarEye from '@/images/generated/icons.emoji.maleStarEye.s3Key';
import cardImageFullstack from '@/images/generated/professionLanding.professionsList.CardFullStack.s3Key';
import cardImageFrontend from '@/images/generated/professionLanding.professionsList.CardFrontEnd.s3Key';
import cardImageDevOps from '@/images/generated/professionLanding.professionsList.CardDevOps.s3Key';
import cardImageJava from '@/images/generated/professionLanding.professionsList.CardJava.s3Key';
import cardImageRecruiter from '@/images/generated/professionLanding.professionsList.CardRecruiter.s3Key';
import cardImageDigitalMarketer from '@/images/generated/professionLanding.professionsList.CardDigitalMarketer.s3Key';
import cardImageQA from '@/images/generated/professionLanding.professionsList.CardQA.s3Key';
import cardImagePython from '@/images/generated/professionLanding.professionsList.CardPython.s3Key';
import cardImageDesigner from '@/images/generated/professionLanding.professionsList.CardDesigner.s3Key';
import cardImageDataAnalyst from '@/images/generated/professionLanding.professionsList.CardDataAnalyst.s3Key';

import styles from '@/components/professionLanding/BenefitCard/BenefitCard.module.scss';
import { ProfessionsListData } from '@/components/coursesParttimeLanding/CoursesParttime.typedefs';
import {
  CardLabelCourse,
} from '@/components/coursesParttimeLanding/ProfessionCardRedesigned/components/ProfessionCardLabels/CardLabelCourse';
import {
  CardLabelNoCode,
} from '@/components/coursesParttimeLanding/ProfessionCardRedesigned/components/ProfessionCardLabels/CardLabelNoCode';
import {
  CardLabelNew,
} from '@/components/coursesParttimeLanding/ProfessionCardRedesigned/components/ProfessionCardLabels/CardLabelNew';
import {
  CardLabelPopular,
} from '@/components/coursesParttimeLanding/ProfessionCardRedesigned/components/ProfessionCardLabels/CardLabelPopular';
import { ReviewButtonType } from '@/components/professionLanding/ReviewsSection/components/ReviewButton';

export const coursesParttimeFAQs: { [key: string]: FaqItem[] } = {
  [SubDomains.ua]: [
    {
      id: 0,
      titleCode: `${I18N_CODES.course}:profession_faq_format_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_format_answer`,
    },
    {
      id: 1,
      titleCode: `${I18N_CODES.course}:profession_faq_combine_activities_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_combine_activities_answer`,
    },
    {
      id: 2,
      titleCode: `${I18N_CODES.course}:profession_faq_tech_background_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_tech_background_answer`,
    },
    {
      id: 3,
      titleCode: `${I18N_CODES.course}:profession_faq_enroll_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_enroll_answer`,
    },
    {
      id: 4,
      titleCode: `${I18N_CODES.course}:profession_faq_age_limits_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_age_limits_answer`,
    },
    {
      id: 5,
      titleCode: `${I18N_CODES.course}:profession_faq_find_job_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_find_job_answer`,
    },
    {
      id: 6,
      titleCode: `${I18N_CODES.course}:profession_faq_money_back_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_money_back_answer`,
    },
  ],
  [SubDomains.pl]: [
    {
      id: 0,
      titleCode: `${I18N_CODES.course}:profession_faq_format_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_format_answer`,
    },
    {
      id: 1,
      titleCode: `${I18N_CODES.course}:profession_faq_combine_activities_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_combine_activities_answer`,
    },
    {
      id: 2,
      titleCode: `${I18N_CODES.course}:profession_faq_tech_background_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_tech_background_answer`,
    },
    {
      id: 3,
      titleCode: `${I18N_CODES.course}:profession_faq_enroll_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_enroll_answer`,
    },
    {
      id: 4,
      titleCode: `${I18N_CODES.course}:profession_faq_age_limits_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_age_limits_answer`,
    },
    {
      id: 5,
      titleCode: `${I18N_CODES.course}:profession_faq_find_job_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_find_job_answer`,
    },
    {
      id: 6,
      titleCode: `${I18N_CODES.course}:profession_faq_money_back_question`,
      contentCode: `${I18N_CODES.course}:profession_faq_money_back_answer`,
    },
  ],
};

export const coursesParttimeReviews: Record<string, Review[]> = {
  [SubDomains.ua]: [
    {
      id: 0,
      text: `${I18N_CODES.course}:profession_review_1_courses_parttime`,
      author: `${I18N_CODES.course}:profession_review_author_1_courses_parttime`,
      reviewLink: `${I18N_CODES.course}:profession_review_link_1_courses_parttime`,
      reviewType: ReviewButtonType.Google,
    },
    {
      id: 1,
      text: `${I18N_CODES.course}:profession_review_2_courses_parttime`,
      author: `${I18N_CODES.course}:profession_review_author_2_courses_parttime`,
      reviewLink: `${I18N_CODES.course}:profession_review_link_2_courses_parttime`,
      reviewType: ReviewButtonType.Google,
    },
    {
      id: 2,
      text: `${I18N_CODES.course}:profession_review_3_courses_parttime`,
      author: `${I18N_CODES.course}:profession_review_author_3_courses_parttime`,
      reviewLink: `${I18N_CODES.course}:profession_review_link_3_courses_parttime`,
      reviewType: ReviewButtonType.Dou,
    },
    {
      id: 3,
      text: `${I18N_CODES.course}:profession_review_4_courses_parttime`,
      author: `${I18N_CODES.course}:profession_review_author_4_courses_parttime`,
      reviewLink: `${I18N_CODES.course}:profession_review_link_4_courses_parttime`,
      reviewType: ReviewButtonType.Dou,
    },
    {
      id: 4,
      text: `${I18N_CODES.course}:profession_review_5_courses_parttime`,
      author: `${I18N_CODES.course}:profession_review_author_5_courses_parttime`,
      reviewLink: `${I18N_CODES.course}:profession_review_link_5_courses_parttime`,
      reviewType: ReviewButtonType.Dou,
    },
  ],
  [SubDomains.pl]: [
    {
      id: 0,
      text: `${I18N_CODES.course}:profession_review_1_general`,
      author: `${I18N_CODES.course}:profession_review_author_1_general`,
      reviewLink: `${I18N_CODES.course}:profession_review_link_1_general`,
      reviewType: ReviewButtonType.Google,
    },
    {
      id: 1,
      text: `${I18N_CODES.course}:profession_review_2_general`,
      author: `${I18N_CODES.course}:profession_review_author_2_general`,
      reviewLink: `${I18N_CODES.course}:profession_review_link_2_general`,
      reviewType: ReviewButtonType.Google,
    },
    {
      id: 2,
      text: `${I18N_CODES.course}:profession_review_3_general`,
      author: `${I18N_CODES.course}:profession_review_author_3_general`,
      reviewLink: `${I18N_CODES.course}:profession_review_link_3_general`,
      reviewType: ReviewButtonType.Google,
    },
    {
      id: 3,
      text: `${I18N_CODES.course}:profession_review_4_general`,
      author: `${I18N_CODES.course}:profession_review_author_4_general`,
      reviewLink: `${I18N_CODES.course}:profession_review_link_4_general`,
      reviewType: ReviewButtonType.Google,
    },
    {
      id: 4,
      text: `${I18N_CODES.course}:profession_review_5_general`,
      author: `${I18N_CODES.course}:profession_review_author_5_general`,
      reviewLink: `${I18N_CODES.course}:profession_review_link_5_general`,
      reviewType: ReviewButtonType.Google,
    },
  ],
};

export const professionsListBenefits: BenefitCardProps[] = [
  {
    id: 0,
    title: `${I18N_CODES.course}:profession_employment_partner_jobs`,
    description: `${I18N_CODES.course}:profession_employment_partner_jobs_description`,
    shouldShowStar: true,
    image: maleShushing,
    className: styles.cardPurple,
  },
  {
    id: 1,
    title: `${I18N_CODES.course}:professions_list_employment_english`,
    description: `${I18N_CODES.course}:profession_employment_english_description`,
    shouldShowStar: true,
    image: maleVictory,
    className: styles.cardCyan,
  },
  {
    id: 2,
    title: `${I18N_CODES.course}:profession_employment_job_search`,
    description: `${I18N_CODES.course}:profession_employment_job_search_description`,
    shouldShowStar: false,
    image: maleStarEye,
  },
  {
    id: 3,
    title: `${I18N_CODES.course}:profession_employment_job_tracker`,
    description: `${I18N_CODES.course}:profession_employment_job_tracker_description`,
    shouldShowStar: false,
    image: maleLike,
  },
  {
    id: 4,
    title: `${I18N_CODES.course}:profession_employment_interviews`,
    description: `${I18N_CODES.course}:profession_employment_interviews_description`,
    shouldShowStar: false,
    image: maleHappy,
  },
];

export const professionsListBenefitsNotes = [
  `${I18N_CODES.course}:professions_list_employment_english_note`,
];

export const availableTracksCoverLabels: { [key: string]: string[] } = {
  [SubDomains.ua]: [
    `${I18N_CODES.course}:available_track_1`,
    `${I18N_CODES.course}:available_track_2`,
    `${I18N_CODES.course}:available_track_3`,
    `${I18N_CODES.course}:available_track_4`,
    'counter',
    'counter',
    'counter',
    'counter',
    'counter',
    'counter',
  ],
  [SubDomains.pl]: [
    `${I18N_CODES.course}:available_track_1`,
    `${I18N_CODES.course}:available_track_2`,
    'counter',
    'counter',
    'counter',
  ],
};

const getQAProfessionLabels = (domainSlug: string): FC[] => {
  switch (domainSlug) {
    case SubDomains.ua:
      return [CardLabelPopular];
    case SubDomains.pl:
      return [CardLabelNew];
    default:
      return [];
  }
};

export const getProfessionsListCardInfo = (
  trackSlug: string,
  domainSlug: string,
): Pick<ProfessionsListData, 'description' | 'imageData' | 'labels'> => {
  switch (trackSlug) {
    case ProfessionSlugs.Fullstack:
      return {
        description: `${I18N_CODES.course}:profession_list_description_${ProfessionSlugs.Fullstack}`,
        imageData: cardImageFullstack,
        labels: [CardLabelCourse],
      };

    case ProfessionSlugs.Frontend:
      return {
        description: `${I18N_CODES.course}:profession_list_description_${ProfessionSlugs.Frontend}`,
        imageData: cardImageFrontend,
        labels: [CardLabelCourse],
      };

    case ProfessionSlugs.DevOps:
      return {
        description: `${I18N_CODES.course}:profession_list_description_${ProfessionSlugs.DevOps}`,
        imageData: cardImageDevOps,
        labels: [CardLabelCourse],
      };

    case ProfessionSlugs.Java:
      return {
        description: `${I18N_CODES.course}:profession_list_description_${ProfessionSlugs.Java}`,
        imageData: cardImageJava,
        labels: [CardLabelCourse],
      };

    case ProfessionSlugs.Recruitment:
      return {
        description: `${I18N_CODES.course}:profession_list_description_${ProfessionSlugs.Recruitment}`,
        imageData: cardImageRecruiter,
        labels: [CardLabelNoCode],
      };

    case ProfessionSlugs.DigitalMarketing:
      return {
        description: `${I18N_CODES.course}:profession_list_description_${ProfessionSlugs.DigitalMarketing}`,
        imageData: cardImageDigitalMarketer,
        labels: [CardLabelNoCode],
      };

    case ProfessionSlugs.Qa:
      return {
        description: `${I18N_CODES.course}:profession_list_description_${ProfessionSlugs.Qa}`,
        imageData: cardImageQA,
        labels: getQAProfessionLabels(domainSlug),
      };

    case ProfessionSlugs.Python:
      return {
        description: `${I18N_CODES.course}:profession_list_description_${ProfessionSlugs.Python}`,
        imageData: cardImagePython,
        labels: [CardLabelCourse],
      };

    case ProfessionSlugs.UiUx:
      return {
        description: `${I18N_CODES.course}:profession_list_description_${ProfessionSlugs.UiUx}`,
        imageData: cardImageDesigner,
        labels: [CardLabelPopular, CardLabelNoCode],
      };

    case ProfessionSlugs.DataAnalytics:
      return {
        description: `${I18N_CODES.course}:profession_list_description_${ProfessionSlugs.DataAnalytics}`,
        imageData: cardImageDataAnalyst,
        labels: [CardLabelNew, CardLabelNoCode],
      };

    default:
      return {
        description: '',
        imageData: cardImageFullstack,
        labels: [],
      };
  }
};

export const visibleCoverLabelsCount: { [key: string]: number } = {
  [SubDomains.ua]: 4,
  [SubDomains.pl]: 2,
};
