import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FactBlockIcon } from '@/components/professionLanding/FactBlock/FactBlockIcon';
import { I18N_CODES } from '@/lib/constants/general';
import { IconStarCelebrate } from '@/components/ui/icons/IconStarCelebrate';
import { IconBuildingOffice } from '@/components/ui/icons/IconBuildingOffice';
import { IconGroupUsers } from '@/components/ui/icons/IconGroupUsers';
import { useEmployedStudentsCount } from '@/controllers/landing/hooks/useEmployedStudentsCount';
import { useTranslation } from '@/middleware/i18n';
import { cn } from '@/lib';
import styles from './FactsBlock.module.scss';

export const FactsBlock: FC = () => {
  const employedCount = useEmployedStudentsCount();

  const { t } = useTranslation([I18N_CODES.course]);

  return (
    <>
      <div className={cn(styles.facts, 'hide-for-small-only')}>
        <FactBlockIcon
          className={styles.primaryFact}
          description={t(`${I18N_CODES.course}:profession_graduates_employed_count`, {
            employedCount,
          })}
          Icon={IconStarCelebrate}
        />

        <div className={styles.secondaryFactsWrapper}>
          <FactBlockIcon
            className={styles.secondaryFact}
            description={t(`${I18N_CODES.course}:profession_graduates_hire_companies`)}
            Icon={IconBuildingOffice}
          />

          <FactBlockIcon
            className={styles.secondaryFact}
            description={t(`${I18N_CODES.course}:profession_graduates_hired_first_half`)}
            Icon={IconGroupUsers}
          />
        </div>
      </div>

      <Swiper
        className="overflow-visible show-for-small-only width-100"
        spaceBetween={16}
        slidesPerView="auto"
        grabCursor
      >
        <SwiperSlide key={1} className={styles.slide}>
          <FactBlockIcon
            className={styles.primaryFact}
            description={t(`${I18N_CODES.course}:profession_graduates_employed_count`, {
              employedCount,
            })}
            Icon={IconStarCelebrate}
          />
        </SwiperSlide>

        <SwiperSlide key={2} className={styles.slide}>
          <FactBlockIcon
            className={styles.secondaryFact}
            description={t(`${I18N_CODES.course}:profession_graduates_hire_companies`)}
            Icon={IconBuildingOffice}
          />
        </SwiperSlide>

        <SwiperSlide key={3} className={styles.slide}>
          <FactBlockIcon
            className={styles.secondaryFact}
            description={t(`${I18N_CODES.course}:profession_graduates_hired_first_half`)}
            Icon={IconGroupUsers}
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
};
