import React, { FC } from 'react';
import { NativeAppEvents } from '@/components/nativeApp/nativeApp.events';
import { AnchorElement } from '@/components/ui/Button/AnchorElement';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import styles from './FooterLinks/FooterLinks.module.scss';

interface Props {
  href: string;
  source: string;
  title: string;
  provider: string;
}

export const DownloadButton: FC<Props> = (props) => {
  const {
    href,
    source,
    title,
    provider,
  } = props;

  return (
    <AnchorElement
      onClick={() => NativeAppEvents.sendEvent(
        NativeAppEvents.events.appStoreButtonClicked,
        {
          source,
          provider,
        },
      )}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
    >
      <li
        className={cn(typography.landingTextMain, styles.navListItem)}
      >
        {title}
      </li>
    </AnchorElement>
  );
};
